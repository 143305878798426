import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {DataItem} from 'types/model/dataItems/DataItem';

interface Props {
    dataItem: DataItem;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {dataItem} = this.props;
        const {id} = dataItem;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={`/administration/data-items/${id}/edit`}
                />
            </div>
        );
    }
}

export default RowActions;
