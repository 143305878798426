import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import RowActions from './RowActions';
import {DataItem} from 'types/model/dataItems/DataItem';
import {LoaderWrapper} from 'layout/components';

export interface DataItemRow extends DataItem {
    actions: ReactNode;
}

interface OuterProps {
    dataItems: DataItem[] | null;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

class DataItemsTable extends Component<Props> {
    render(): React.ReactNode {
        const {dataItems} = this.props;

        return (
            <LoaderWrapper showLoader={!dataItems}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData()}
                />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<DataItemRow>[] {
        return [
            {
                accessor: 'name',
                header: 'Název',
                type: ColumnType.Node,
            },
            {
                accessor: 'numOfDecimalPlaces',
                header: 'Desetinná místa',
                type: ColumnType.Node,
            },
            {
                accessor: 'minimum',
                header: 'Minimum',
                type: ColumnType.Node,
            },
            {
                accessor: 'maximum',
                header: 'Maximum',
                type: ColumnType.Node,
            },
            {
                accessor: 'measurementUnit',
                header: 'Jednotka',
                type: ColumnType.Node,
            },
            {
                accessor: 'dilution',
                header: 'Ředění',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getData(): Row<DataItemRow>[] {
        const {dataItems} = this.props;
        if (!dataItems) {
            return [];
        }

        return dataItems.map(dataItem => ({
            ...dataItem,
            actions: <RowActions
                dataItem={dataItem}
            />,
        }));
    }
}

export default DataItemsTable;
