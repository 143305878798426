import {SagaIterator} from 'redux-saga';
import {extractFormErrorsFromResponse, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {startSubmitF, stopSubmitF} from 'utils/formHelpers';

import {PickupLineApi} from '../api';
import {ValidatePickupLineNameAction} from '../actions';

function* execute ({payload: {data, formName}}: ValidatePickupLineNameAction): SagaIterator {
    yield* put(startSubmitF(formName));

    const response = yield* call(PickupLineApi.validatePickupLineName, data);

    if (response.isSuccess) {
        yield* put(stopSubmitF(formName, {}));
    } else {
        yield* put(stopSubmitF(formName, extractFormErrorsFromResponse(response)));
    }
}

export function* validatePickupLineNameSaga(): SagaIterator {
    yield takeLatestF('pickupLine/VALIDATE_PICKUP_LINE_NAME', execute);
}
