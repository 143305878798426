import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {MeasurementImportResultSchema} from 'types/model/measurements/MeasurementImportResult';

const optionalPart = t.partial({
});

const requiredPart = t.interface({
    sampleId: IntegerSchema,
    results: t.array(MeasurementImportResultSchema),
});

export const MeasurementImportSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementImport extends t.TypeOf<typeof MeasurementImportSchema> {}
