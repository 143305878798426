import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/measurements/newDataItem"
        component={Screens.NewDataItem}
        key="new"
    />,
    <Route
        exact
        path="/measurements/newDevice"
        component={Screens.NewDevice}
        key="new"
    />,
    <Route
        exact
        path="/measurements/newSample"
        component={Screens.NewSample}
        key="new"
    />,
    <Route
        exact
        path="/measurements/import"
        component={Screens.Import}
        key="new"
    />,
    <Route
        exact
        path="/measurements"
        component={Screens.List}
        key="list"
    />,
    <Route
        exact
        path="/measurements/:id"
        component={Screens.Detail}
        key="detail"
    />,
    <Route
        exact
        path="/measurements/:id/edit"
        component={Screens.Edit}
        key="edit"
    />,
];

export default routes;
