import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Opt} from 'ts-opt';
import {flow} from 'lodash/fp';

import {Components as Layout} from 'layout';
import {StoreState} from 'app/types/StoreState';
import {DateString} from 'types/generic/DateString';
import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import Label from 'forms/components/Label';
import BasicDateTime from 'forms/components/BasicDateTime';
import {Group, RightIconButton} from 'buttons/components';
import {withUser} from 'user/components';

import {pickupLineAction} from '../actions';
import {PickupLinePlanningForm} from '../components/PickupLinePlanningForm';
import {PickupLinePlanningFormValues} from '../components/PickupLinePlanningForm/pickupLinePlanningFormValues';
import {getPlanningFormInitialValues} from '../utils/getPlanningFormInitialValues';
import {PickupLineLoading} from '../types/PickupLineLoading';
import {isFutureYearMonth} from '../utils/isFutureYearMonth';
import {getNextYearMonth} from '../utils/getNextYearMonth';

const renderToolbar = (
    yearMonth: DateString | undefined,
    disabled: boolean,
    onYearMonthChange: (value: DateString) => void,
    updatePickupLinesOptions: () => void,
) =>
    <Group>
        <RightIconButton
            label="Uložit plány"
            icon="ADD"
            onClick={updatePickupLinesOptions}
            disabled={disabled}
        />
        <Label
            htmlFor="yearMonth"
            className="pl-md-2"
        >
            Měsíc:
        </Label>
        <BasicDateTime
            mode="YEARMONTH"
            name="yearMonth"
            value={yearMonth}
            onChange={onYearMonthChange}
            className="col-2"
            noPadding
            includeFuture
        />
    </Group>
;

interface StateProps {
    loading: PickupLineLoading[];
    pickupLinesOptions: Opt<PickupLineOptions[]>;
    planningDate: Opt<DateString>;
}

interface DispatchProps {
    getPickupLinesOptions(yearMonth: DateString): void;
    setPlanningDate(yearMonth: DateString): void;
    setPickupLinePlanningFormValues(values: PickupLinePlanningFormValues): void;
    updatePickupLinesOptions(yearMonth: DateString): void;
    approvePickupLinePlan(yearMonth: DateString, pickupLineId: number): void;
}

type Props = StateProps & DispatchProps;

const PlanningBase: FunctionComponent<Props> = props => {
    const {
        loading,
        pickupLinesOptions,
        planningDate,
        getPickupLinesOptions,
        setPlanningDate,
        setPickupLinePlanningFormValues,
        updatePickupLinesOptions,
        approvePickupLinePlan,
    } = props;

    useEffect(() => {
        if (planningDate.isEmpty) {
            setPlanningDate(getNextYearMonth());
        }
    }, []);

    useEffect(() => {
        planningDate.onSome(getPickupLinesOptions);
    }, [planningDate, getPickupLinesOptions]);

    useEffect(() => {
        setPickupLinePlanningFormValues(getPlanningFormInitialValues(pickupLinesOptions));
    }, [pickupLinesOptions, setPickupLinePlanningFormValues]);

    const onYearMonthChange = useCallback((value: DateString) => {
        setPlanningDate(value);
    }, [setPlanningDate]);

    const onPlanningTableUpdate = useCallback(() => {
        planningDate.onSome(updatePickupLinesOptions);
    }, [planningDate, updatePickupLinesOptions]);

    const onPickupLinePlanApprove = useCallback((pickupLineid: number) => {
        if (planningDate.nonEmpty) {
            approvePickupLinePlan(planningDate.orCrash('planningDate not available'), pickupLineid);
        }
    }, [planningDate, approvePickupLinePlan]);

    const isEditable = planningDate.map(isFutureYearMonth).orFalse();
    const allIsApproved = pickupLinesOptions.map(x => x.every(y => y.published)).orFalse();
    const noData = !planningDate || pickupLinesOptions.map(x => x.length === 0).orTrue();
    const disabled = !isEditable || allIsApproved;

    return (
        <Layout.ListPage
            title="Plánování svozových linek"
            toolbar={renderToolbar(planningDate.orUndef(), disabled, onYearMonthChange, onPlanningTableUpdate)}
            fluid
        >
            {disabled && !noData &&
                <div className="alert alert-warning">
                    {allIsApproved && 'Plán pro zvolený měsíc byl již schválen, není již možné jej editovat.'}
                    {!allIsApproved && !isEditable && 'Aktuální ani předcházející měsíce není možné zpětně upravit.'}
                </div>
            }
            <PickupLinePlanningForm
                loading={loading}
                pickupLinesOptions={pickupLinesOptions}
                planningDate={planningDate.orNull()}
                disabled={disabled}
                onPickupLinePlanApprove={onPickupLinePlanApprove}
            />
        </Layout.ListPage>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getPickupLinesOptions: (yearMonth: DateString) => dispatch(pickupLineAction.getPickupLinesOptions(yearMonth)),
    setPlanningDate: (yearMonth: DateString) => dispatch(pickupLineAction.setPlanningDate(yearMonth)),
    setPickupLinePlanningFormValues: (values: PickupLinePlanningFormValues) =>
        dispatch(pickupLineAction.setPickupLinePlanningFormValues(values)),
    updatePickupLinesOptions: (yearMonth: DateString) => dispatch(pickupLineAction.updatePickupLinesOptions(yearMonth)),
    approvePickupLinePlan: (yearMonth: DateString, pickupLineId: number) =>
        dispatch(pickupLineAction.approvePickupLinePlan(yearMonth, pickupLineId)),
});

const mapStateToProps = (state: StoreState): StateProps => ({
    loading: state.pickupLine.loading,
    pickupLinesOptions: state.pickupLine.pickupLinesOptions,
    planningDate: state.pickupLine.planningDate,
});

export const Planning = flow(
    connect(mapStateToProps, mapDispatchToProps),
    withUser,
)(PlanningBase);
