import React, {FunctionComponent, ReactNode} from 'react';

interface Props {
    children: ReactNode;
    htmlFor: string;
    className?: string;
}

const Label: FunctionComponent<Props> = props => {
    const {children, htmlFor, className} = props;

    if (!children) { return null; }

    return (
        <label
            htmlFor={htmlFor}
            className={className}
        >
            {children}
        </label>
    );
};

export default Label;
