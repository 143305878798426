import {Dispatch} from 'redux';

import {Config} from 'app/Config';
import {MilkRoomValidation} from 'types/model/milkRooms/MilkRoomValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {CreateMilkRoomFormProps} from '.';
import {validateMilkRoom} from '../../actions';
import {CreateMilkRoomFormValues} from '../../types/CreateMilkRoomFormValues';

type Values = CreateMilkRoomFormValues;

export const validate = (values: Values, props: CreateMilkRoomFormProps): Errors<Values> => {
    const cfg = Config.forms;
    const vetIdLabel = 'Veterinární číslo';

    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název firmy/družstva');
    validator.nonEmpty('code', 'Kód');
    validator.milkRoomCode('code');
    validator.nonEmpty('veterinaryId', vetIdLabel);
    validator.maxStringLength('veterinaryId', cfg.veterinaryIdMaxLen, vetIdLabel);
    validator.veterinaryId('veterinaryId', vetIdLabel);

    if (props.existingMilkRooms) {
        validator.uniqueCustomError('name', props.existingMilkRooms.map(x => x.name),
            'Dodavatel již má mléčnici s daným názvem');
        validator.uniqueCustomError('code', props.existingMilkRooms.map(x => x.code),
            'Již existuje mléčnice s daným kódem');
    }

    return validator.generateErrorsObject();
};

export const asyncValidate = (values: Values, dispatch: Dispatch, props: CreateMilkRoomFormProps): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
        const {name, code} = values;
        const {supplierId} = props;
        const actionValues: MilkRoomValidation = {name, code};

        if (!supplierId) {
            return resolve(false);
        }

        dispatch(validateMilkRoom({
            values: actionValues,
            resolve,
            reject,
            supplierId,
        }));
    });
