import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    AcceptOrderAction,
    acceptOrderError,
    acceptOrderSuccess,
} from '../actions';
import Api from '../api';

function* execute(action: AcceptOrderAction): SagaIterator {
    const title = 'Přijetí objednávky';
    const response = yield* call(Api.acceptOrder, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        return yield* put(acceptOrderError(error));
    }

    yield* put(acceptOrderSuccess());
    yield* put(showSuccess(title, 'Objednávka úspěšně přijata.'));
    yield* put(routerActions.push('/orders'));
}

export function* acceptOrderSaga(): SagaIterator {
    yield takeLatestF('order/ACCEPT_ORDER', execute);
}
