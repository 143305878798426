import * as t from 'io-ts';

import {UpdateOrderBaseSchema} from './UpdateOrderBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('UpdateOrderByCustomer'),
});

export const UpdateOrderByCustomerSchema = t.exact(t.intersection([
    UpdateOrderBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateOrderByCustomer extends t.TypeOf<typeof UpdateOrderByCustomerSchema> {}
