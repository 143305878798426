import React from 'react';
import {Route} from 'react-router-dom';

import {Config} from 'app/Config';

import * as Screens from './screens';

const pickupLinesDevelopmentRoutes = [
    <Route
        exact
        path="/pickup-lines/administration"
        component={Screens.PickupLines}
        key="pickup-lines-administration"
    />,
    <Route
        exact
        path="/pickup-lines/planning"
        component={Screens.Planning}
        key="pickup-lines-plan"
    />,
    <Route
        exact
        path="/pickup-lines/overview"
        component={Screens.Overview}
        key="pickup-lines-overview"
    />,
    <Route
        exact
        path="/pickup-lines/dairy-plan"
        component={Screens.DairyPlan}
        key="pickup-lines-fill-in"
    />,
];

export const pickupLinesRoutes = !Config.isProduction ? pickupLinesDevelopmentRoutes : [];
