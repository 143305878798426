import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {downloadFileFromResponse, takeLatestF} from 'favorlogic-utils';

import {isLoading} from 'layout/actions';
import {handleResponseError} from 'utils/handleResponseError';

import Api from '../api';
import {DownloadInvoiceAction} from '../actions';

const title = 'Stažení faktury';

function* execute(action: DownloadInvoiceAction): SagaIterator {
    yield* put(isLoading(true));

    const response = yield* call(Api.downloadInvoice, action.payload);

    yield* response.isSuccess
        ? call(downloadFileFromResponse, response)
        : call(handleResponseError, response, title);
    yield* put(isLoading(false));
}

export function* downloadInvoiceSaga(): SagaIterator {
    yield takeLatestF('order/DOWNLOAD_INVOICE', execute);
}
