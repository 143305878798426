import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {showSuccess, ErrorsFromBE, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    DeleteMilkRoomAction,
    deleteMilkRoomError,
    deleteMilkRoomSuccess,
    loadSupplier,
    loadSuppliersMilkRooms,
} from '../actions';
import Api from '../api';

const title = 'Smazání mléčnice';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(deleteMilkRoomError(response.data as ErrorsFromBE));
}

function* handleSuccessResponse(supplierId: number, isLast: boolean): SagaIterator {
    yield* put(showSuccess(title, 'Mléčnice byla úspěšně smazána'));
    yield* put(deleteMilkRoomSuccess());
    if (isLast) {
        yield* put(routerActions.goBack());
    } else {
        yield* put(loadSuppliersMilkRooms(supplierId));
        yield* put(loadSupplier(supplierId));
    }
}

function* execute(action: DeleteMilkRoomAction): SagaIterator {
    const {milkRoomId, supplierId, isLast} = action.payload;
    const response = yield* call(Api.deleteMilkRoom, milkRoomId);

    yield* response.isSuccess
        ? call(handleSuccessResponse, supplierId, isLast)
        : call(handleErrorResponse, response);
}

export function* deleteMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/DELETE_MILK_ROOM', execute);
}
