import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ImportSampleAction, importSampleError, importSampleSuccess, selectSampleCreationType} from '../actions';
import Api from '../api';

export function* executeImportSample({payload}: ImportSampleAction): SagaIterator {
    const errorTitle = 'Import vzorků';
    const response = yield* call(Api.parseSample, payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(importSampleError(error));
    }

    yield* put(selectSampleCreationType('IMPORTED'));
    yield* put(importSampleSuccess(response.data));
}

export function* importSampleSaga(): SagaIterator {
    yield takeLatestF('order/IMPORT_SAMPLE', executeImportSample);
}
