import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, ErrorsFromBE, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    DeleteSupplyChainAction,
    deleteSupplyChainError,
    deleteSupplyChainSuccess,
    loadSupplyChains,
} from '../actions';
import Api from '../api';

const title = 'Smazání linky';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(deleteSupplyChainError(response.data as ErrorsFromBE));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Linka byla úspěšně smazána'));
    yield* put(deleteSupplyChainSuccess());
    yield* put(loadSupplyChains());
}

function* execute(action: DeleteSupplyChainAction): SagaIterator {
    const response = yield* call(Api.deleteSupplyChain, action.payload);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* deleteSupplyChainSaga(): SagaIterator {
    yield takeLatestF('supplyChain/DELETE_SUPPLY_CHAIN', execute);
}
