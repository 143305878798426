import * as t from 'io-ts';

import {UpdateCustomerBaseSchema} from './UpdateCustomerBase';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    tag: t.literal('CreateCustomer'),
    username: t.string,
    password: t.string,
});

export const CreateCustomerSchema = t.exact(t.intersection([
    UpdateCustomerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface CreateCustomer extends t.TypeOf<typeof CreateCustomerSchema> {}
