import React, {FunctionComponent, ReactNode} from 'react';

import Message from '../Message';
import Page from '../Page';

interface Props {
    children: ReactNode;
    narrow?: boolean;
}

const EmptyPage: FunctionComponent<Props> = props => {
    const {children, narrow} = props;

    return (
        <Page>
            <Message narrow={narrow}>
                {children}
            </Message>
        </Page>
    );
};

export default EmptyPage;
