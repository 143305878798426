import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {SupplyChainFormValues} from './types/SupplyChainFormValues';

type SupplyChainState = GenericFormState<SupplyChainFormValues>;

const formReducer: CFormReducer = {
    supplyChain: (state: SupplyChainState, action: Action): SupplyChainState => {
        switch (action.type) {
            case 'supplyChain/PREFILL_SUPPLY_CHAIN_FORM': {
                return {...state, values: action.payload};
            }

            default: { return state; }
        }
    },
};

export default formReducer;
