import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

interface Props {
    operatingProcedure: OperatingProcedure;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {operatingProcedure} = this.props;
        const {id} = operatingProcedure;
        const editUrl = `/administration/operating-procedures/${id}/edit`;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={editUrl}
                />
            </div>
        );
    }
}

export default RowActions;
