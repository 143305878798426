exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-forms-components-ShowField-___styles__label___2BZK1 {\n  font-size: 85%; }\n\n.src-modules-forms-components-ShowField-___styles__dangerous___25ZS5 {\n  color: #dc3545; }\n", ""]);

// exports
exports.locals = {
	"label": "src-modules-forms-components-ShowField-___styles__label___2BZK1",
	"dangerous": "src-modules-forms-components-ShowField-___styles__dangerous___25ZS5"
};