import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {showSuccess, takeLatestF, ErrorsFromBE, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    ApproveProtocolAction,
    approveProtocolError,
    approveProtocolSuccess,
} from '../actions';
import Api from '../api';

const title = 'Schválení protokolu';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(approveProtocolError(response.data as ErrorsFromBE));
}

function* handleSuccess(orderId: number, isEdit: boolean): SagaIterator {
    yield* put(approveProtocolSuccess());
    yield* put(showSuccess(title, 'Protokol schválen.'));
    yield* isEdit
        ? put(routerActions.push(`/orders/${orderId}`))
        : put(routerActions.go(0));
}

function* execute({payload: {orderId, isEdit}}: ApproveProtocolAction): SagaIterator {
    const response = yield* call(Api.approveProtocol, orderId);

    yield* response.isSuccess
        ? call(handleSuccess, orderId, isEdit)
        : call(handleErrorResponse, response);
}

export function* approveProtocolSaga(): SagaIterator {
    yield takeLatestF('order/APPROVE_PROTOCOL', execute);
}
