import {MeasurementsFlagParams, MeasurementsFlagValue, MeasurementsFilter} from '../type/MeasurementsParams';

const noFlags: MeasurementsFlagParams = {
    qualitative: false,
    cistern: false,
    control: false,
    canceled: false,
};

function toFlags(flags: MeasurementsFlagValue[]): MeasurementsFlagParams {
    const result: MeasurementsFlagParams = {};

    flags.forEach((flag) => {
        if (flag !== 'noFlags') {
            result[flag] = true;
        }
    });

    return result;
}

function buildFlagsParams(flags: MeasurementsFlagValue | MeasurementsFlagValue[]): MeasurementsFlagParams {
    if (!Array.isArray(flags)) {
        return flags === 'noFlags'
            ? noFlags
            : {[flags]: true};
    }

    return flags.includes('noFlags')
        ? noFlags
        : toFlags(flags);
}

export function buildFilterParams(params: MeasurementsFilter): MeasurementsFilter {
    const {flags, ...otherParams} = params;

    return {
        ...otherParams,
        ...flags && buildFlagsParams(flags),
    };
}
