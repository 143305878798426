import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {UpdateMilkRoomFormValues} from '../../types/UpdateMilkRoomFormValues';
import {asyncValidate, validate} from './validations';

interface OwnProps {
    milkRoomId: number;
    supplierId: number;
}

export type UpdateMilkRoomFormProps = PropsForForm<UpdateMilkRoomFormValues, OwnProps>;

class UpdateMilkRoomForm extends Component<UpdateMilkRoomFormProps> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;
        return (
            <Layout.Panel>
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />

                    <Fields.Input
                        className="mt-2"
                        name="name"
                        type="text"
                        label="Název firmy/družstva*"
                    />

                    <Fields.Input
                        className="mt-2"
                        name="code"
                        type="text"
                        label="Kód*"
                    />

                    <Fields.Input
                        className="mt-2"
                        name="veterinaryId"
                        type="text"
                        label="Veterinární číslo*"
                        // eslint-disable-next-line max-len
                        placeholder="Veterinární číslo* (v případě slovenských dodavatelů zadejte schvalovací číslo bez speciálních znaků)"
                    />

                    <div className="d-flex justify-content-end mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Uložit
                        </Buttons.Button>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateMilkRoomFormValues, OwnProps, UpdateMilkRoomFormProps>(UpdateMilkRoomForm, {
    form: 'updateMilkRoom',
    asyncBlurFields: ['name', 'code'],
    validate,
    asyncValidate,
    initialValues: {
        veterinaryId: '',
        name: '',
        code: '',
    },
});
