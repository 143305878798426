import * as t from 'io-ts';

import {PageSchema} from '../common/Page';
import {CustomerViewSchema} from './CustomerView';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    content: t.array(CustomerViewSchema),
});

export const CustomersPageSchema = t.exact(t.intersection([PageSchema.type, optionalPart, requiredPart]));

export interface CustomersPage extends t.TypeOf<typeof CustomersPageSchema> {}
