import * as t from 'io-ts';

import {UserDetailsForLaborerSchema} from './UserDetailsForLaborer';
import {UserDetailsForCustomerSchema} from './UserDetailsForCustomer';

export const UserDetailsSchema = t.taggedUnion('tag', [
    UserDetailsForLaborerSchema,
    UserDetailsForCustomerSchema,
]);

export type UserDetails = t.TypeOf<typeof UserDetailsSchema>;
