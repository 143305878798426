import {AFormValues} from 'test/components/AForm/AFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

type Values = AFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('text', 'Text');
    validator.nonEmpty('textarea', 'Textarea');
    validator.nonEmpty('date', 'Date');
    validator.nonEmpty('time', 'Time');
    // validator.nonEmpty('select', 'Select');
    // validator.nonEmpty('multiSelect', 'MultiSelect');

    return validator.generateErrorsObject();
};
