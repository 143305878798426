import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {loadMilkRoomsError, loadMilkRoomsSuccess} from 'supplier/actions';
import {handleResponseError} from 'utils/handleResponseError';

import Api from '../api';

function* execute(): SagaIterator {
    const title = 'Načtení mléčnic';
    const response = yield* call(Api.getMilkRooms);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        return yield* put(loadMilkRoomsError(error));
    }

    yield* put(loadMilkRoomsSuccess(response.data));
}

export function* loadMilkRoomsSaga(): SagaIterator {
    yield takeLatestF('supplier/LOAD_MILK_ROOMS', execute);
}
