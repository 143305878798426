import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    analysesCount: IntegerSchema,
    samplesCount: IntegerSchema,
});

export const OrderStatsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OrderStats extends t.TypeOf<typeof OrderStatsSchema> {}
