import * as t from 'io-ts';

import {UpdateOrderByLaborerSchema} from './UpdateOrderByLaborer';
import {UpdateOrderByCustomerSchema} from './UpdateOrderByCustomer';

export const UpdateOrderSchema = t.taggedUnion('tag', [
    UpdateOrderByLaborerSchema,
    UpdateOrderByCustomerSchema,
]);

export type UpdateOrder = t.TypeOf<typeof UpdateOrderSchema>;
