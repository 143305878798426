import {last} from 'lodash/fp';

import {SelectOptions} from 'forms/components/BasicSelect';

import {MeasurementsFlag, MeasurementsFlagValue} from '../type/MeasurementsParams';

export interface FlagDefinition {
    key: MeasurementsFlag;
    label: string;
    shortcut: string;
}

export const flags: FlagDefinition[] = [
    {key: 'qualitative', label: 'Platný', shortcut: 'P'},
    {key: 'cistern', label: 'Cisterna', shortcut: 'C'},
    {key: 'control', label: 'Kontrolní', shortcut: 'K'},
    {key: 'canceled', label: 'Zrušený', shortcut: 'Z'},
];

const buildFlagLabel = (definition: FlagDefinition): string =>
    `(${definition.shortcut}) ${definition.label}`;

export const flagsTooltip: string = flags.map(buildFlagLabel).join('\n');

const buildFlagOption = (definition: FlagDefinition) => ({
    label: definition.shortcut,
    value: definition.key,
});

export const updateFlagValues = (values: string[]): string[] => {
    if (last(values) === 'noFlags') {
        return ['noFlags'];
    } else {
        return values.filter(v => v !== 'noFlags');
    }
};

export const flagsOptions: SelectOptions<MeasurementsFlagValue> = [
    ...flags.map(buildFlagOption),
    {label: 'Bez příznaku', value: 'noFlags'},
];
