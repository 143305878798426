import {Opt} from 'ts-opt';
import {UnsafeBeFormErrors, ErrorsFromBE} from 'favorlogic-utils';

import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {CustomerValidation} from 'types/model/customers/CustomerValidation';
import {UserDetails} from 'types/model/users/UserDetails';
import {RegisterFormValues} from 'user/types/RegisterFormValues';
import {UpdateCustomerFormValues} from './types/UpdateCustomerFormValues';
import {Ares} from 'types/model/ares/Ares';
import {CustomersParams} from 'user/types/CustomersParams';
import {CustomersPage} from 'types/model/customers/CustomerPage';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {Customer} from 'types/model/customers/Customer';
import {UserDetailsForCustomer} from 'types/model/users/UserDetailsForCustomer';

export interface LoadCurrentAction {
    type: 'user/LOAD_CURRENT';
}

export const loadCurrent = (): LoadCurrentAction => ({
    type: 'user/LOAD_CURRENT',
});

export interface LoadCurrentSuccessAction {
    type: 'user/LOAD_CURRENT_SUCCESS';
    payload: {
        user: Opt<UserDetails>,
        customer: Opt<CustomerDetails>,
    };
}

export const loadCurrentSuccess =
    (user: Opt<UserDetails>, customer: Opt<CustomerDetails>): LoadCurrentSuccessAction => ({
        type: 'user/LOAD_CURRENT_SUCCESS',
        payload: {user, customer},
    });

export interface LoadCurrentErrorAction {
    type: 'user/LOAD_CURRENT_ERROR';
    payload: ErrorsFromBE;
}

export const loadCurrentError = (err: ErrorsFromBE): LoadCurrentErrorAction => ({
    type: 'user/LOAD_CURRENT_ERROR',
    payload: err,
});

// ---

export interface LoginAction {
    type: 'user/LOGIN';
}

export const login = (): LoginAction => ({
    type: 'user/LOGIN',
});

export interface LoginSuccessAction {
    type: 'user/LOGIN_SUCCESS';
}

export const loginSuccess = (): LoginSuccessAction => ({
    type: 'user/LOGIN_SUCCESS',
});

export interface LoginErrorAction {
    type: 'user/LOGIN_ERROR';
    payload: ErrorsFromBE;
}

export const loginError = (err: ErrorsFromBE): LoginErrorAction => ({
    type: 'user/LOGIN_ERROR',
    payload: err,
});

// ---

export interface ResetPasswordAction {
    type: 'user/RESET_PASSWORD';
    payload: {
        uuid: string,
    };
}

export const resetPassword = (uuid: string): ResetPasswordAction => ({
    type: 'user/RESET_PASSWORD',
    payload: {
        uuid,
    },
});

export interface ResetPasswordEmailAction {
    type: 'user/RESET_PASSWORD_EMAIL';
}

export const resetPasswordEmail = (): ResetPasswordEmailAction => ({
    type: 'user/RESET_PASSWORD_EMAIL',
});

export interface ValidateResetPasswordEmailAction {
    type: 'user/VALIDATE_RESET_PASSWORD';
    payload: {
        uuid: string,
    };
}

export const validateResetPassword = (uuid: string): ValidateResetPasswordEmailAction => ({
    type: 'user/VALIDATE_RESET_PASSWORD',
    payload: {
        uuid,
    },
});
// ---

export interface LogoutAction {
    type: 'user/LOGOUT';
}

export const logout = (): LogoutAction => ({
    type: 'user/LOGOUT',
});

// ---

export interface RegisterAction {
    type: 'user/REGISTER';
}

export const register = (): RegisterAction => ({
    type: 'user/REGISTER',
});

export interface RegisterSuccessAction {
    type: 'user/REGISTER_SUCCESS';
}

export const registerSuccess = (): RegisterSuccessAction => ({
    type: 'user/REGISTER_SUCCESS',
});

export interface RegisterErrorAction {
    type: 'user/REGISTER_ERROR';
    payload: ErrorsFromBE;
}

export const registerError = (err: ErrorsFromBE): RegisterErrorAction => ({
    type: 'user/REGISTER_ERROR',
    payload: err,
});

// ---

export interface UpdateActionArgs {
    byLaborer: boolean;
    customerId: Opt<number>;
}

export interface UpdateAction {
    type: 'user/UPDATE';
    payload: UpdateActionArgs;
}

export const update = (payload: UpdateActionArgs): UpdateAction => ({
    type: 'user/UPDATE',
    payload,
});

export interface UpdateSuccessAction {
    type: 'user/UPDATE_SUCCESS';
}

export const updateSuccess = (): UpdateSuccessAction => ({
    type: 'user/UPDATE_SUCCESS',
});

export interface UpdateErrorAction {
    type: 'user/UPDATE_ERROR';
    payload: ErrorsFromBE;
}

export const updateError = (err: ErrorsFromBE): UpdateErrorAction => ({
    type: 'user/UPDATE_ERROR',
    payload: err,
});

// ---

export interface PrefillUpdateCustomerFormAction {
    type: 'user/PREFILL_UPDATE_CUSTOMER_FORM';
    payload: UpdateCustomerFormValues;
}

export const prefillUpdateCustomerForm = (payload: UpdateCustomerFormValues): PrefillUpdateCustomerFormAction => ({
    type: 'user/PREFILL_UPDATE_CUSTOMER_FORM',
    payload,
});

// ---

export interface FillUpdateCustomerFormFromAresAction {
    type: 'user/FILL_UPDATE_CUSTOMER_FORM_FROM_ARES';
    payload: string;
}

export const fillUpdateCustomerFormFromAres = (ico: string): FillUpdateCustomerFormFromAresAction => ({
    type: 'user/FILL_UPDATE_CUSTOMER_FORM_FROM_ARES',
    payload: ico,
});

// ---

export interface ValidateCustomerActionArgs {
    values: CustomerValidation;
    isCreate: boolean;
    isAdminOrLaborer: boolean;

    reject(_: UnsafeBeFormErrors): void;
    resolve(_: boolean): void;
}

export interface ValidateCustomerAction {
    type: 'user/VALIDATE_CUSTOMER';
    payload: ValidateCustomerActionArgs;
}

export const validateCustomer = (payload: ValidateCustomerActionArgs): ValidateCustomerAction => ({
    type: 'user/VALIDATE_CUSTOMER',
    payload,
});

// ---

export interface LoadCustomersAction {
    type: 'user/LOAD_CUSTOMERS';
    payload: CustomersParams;
}

export const loadCustomers = (params: CustomersParams): LoadCustomersAction => ({
    type: 'user/LOAD_CUSTOMERS',
    payload: params,
});

export interface LoadCustomersSuccessAction {
    type: 'user/LOAD_CUSTOMERS_SUCCESS';
    payload: CustomersPage;
}

export const loadCustomersSuccess = (payload: CustomersPage): LoadCustomersSuccessAction => ({
    type: 'user/LOAD_CUSTOMERS_SUCCESS',
    payload,
});

export interface LoadCustomersErrorAction {
    type: 'user/LOAD_CUSTOMERS_ERROR';
    payload: ErrorsFromBE;
}

export const loadCustomersError = (err: ErrorsFromBE): LoadCustomersErrorAction => ({
    type: 'user/LOAD_CUSTOMERS_ERROR',
    payload: err,
});

// ---

export interface LoadCustomerActionArgs {
    id: number;
}

export interface LoadCustomerAction {
    type: 'user/LOAD_CUSTOMER';
    payload: LoadCustomerActionArgs;
}

export const loadCustomer = (id: number): LoadCustomerAction => ({
    type: 'user/LOAD_CUSTOMER',
    payload: {id},
});

export interface LoadCustomerSuccessAction {
    type: 'user/LOAD_CUSTOMER_SUCCESS';
    payload: CustomerDetailsForLaborer;
}

export const loadCustomerSuccess = (payload: CustomerDetailsForLaborer): LoadCustomerSuccessAction => ({
    type: 'user/LOAD_CUSTOMER_SUCCESS',
    payload,
});

export interface LoadCustomerErrorAction {
    type: 'user/LOAD_CUSTOMER_ERROR';
    payload: ErrorsFromBE;
}

export const loadCustomerError = (err: ErrorsFromBE): LoadCustomerErrorAction => ({
    type: 'user/LOAD_CUSTOMER_ERROR',
    payload: err,
});

// ---

export interface LoadLaborersAction {
    type: 'user/LOAD_LABORERS';
}

export const loadLaborers = (): LoadLaborersAction => ({
    type: 'user/LOAD_LABORERS',
});

export interface LoadLaborersSuccessAction {
    type: 'user/LOAD_LABORERS_SUCCESS';
    payload: UserDetails[];
}

export const loadLaborersSuccess = (payload: UserDetails[]): LoadLaborersSuccessAction => ({
    type: 'user/LOAD_LABORERS_SUCCESS',
    payload,
});

export interface LoadLaborersErrorAction {
    type: 'user/LOAD_LABORERS_ERROR';
    payload: ErrorsFromBE;
}

export const loadLaborersError = (err: ErrorsFromBE): LoadLaborersErrorAction => ({
    type: 'user/LOAD_LABORERS_ERROR',
    payload: err,
});

// ---

export interface LoadCustomerMilkRoomsActionArgs {
    id: number;
}

export interface LoadCustomerMilkRoomsAction {
    type: 'user/LOAD_CUSTOMER_MILK_ROOMS';
    payload: LoadCustomerMilkRoomsActionArgs;
}

export const loadCustomerMilkRooms = (id: number): LoadCustomerMilkRoomsAction => ({
    type: 'user/LOAD_CUSTOMER_MILK_ROOMS',
    payload: {id},
});

export interface LoadCustomerMilkRoomsSuccessAction {
    type: 'user/LOAD_CUSTOMER_MILK_ROOMS_SUCCESS';
    payload: MilkRoom[];
}

export const loadCustomerMilkRoomsSuccess =
    (payload: MilkRoom[]): LoadCustomerMilkRoomsSuccessAction => ({
        type: 'user/LOAD_CUSTOMER_MILK_ROOMS_SUCCESS',
        payload,
    });

export interface LoadCustomerMilkRoomsErrorAction {
    type: 'user/LOAD_CUSTOMER_MILK_ROOMS_ERROR';
    payload: ErrorsFromBE;
}

export const loadCustomerMilkRoomsError = (err: ErrorsFromBE): LoadCustomerMilkRoomsErrorAction => ({
    type: 'user/LOAD_CUSTOMER_MILK_ROOMS_ERROR',
    payload: err,
});

export interface ResetCustomerMilkRoomsAction {
    type: 'user/RESET_CUSTOMER_ROOMS';
}

export const resetCustomerMilkRooms = (): ResetCustomerMilkRoomsAction => ({
    type: 'user/RESET_CUSTOMER_ROOMS',
});

// ---

export interface LoadAresActionArgs {
    ico: string;
}

export interface LoadAresAction {
    type: 'user/LOAD_ARES';
    payload: LoadAresActionArgs;
}

export const loadAres = (ico: string): LoadAresAction => ({
    type: 'user/LOAD_ARES',
    payload: {ico},
});

export interface LoadAresSuccessAction {
    type: 'user/LOAD_ARES_SUCCESS';
    payload: Ares;
}

export const loadAresSuccess = (payload: Ares): LoadAresSuccessAction => ({
    type: 'user/LOAD_ARES_SUCCESS',
    payload,
});

export interface LoadAresErrorAction {
    type: 'user/LOAD_ARES_ERROR';
    payload: ErrorsFromBE;
}

export const isAresLoading = (value: boolean): IsAresLoadingAction => ({
    type: 'user/IS_ARES_LOADING',
    payload: {value},
});

export interface IsAresLoadingAction {
    type: 'user/IS_ARES_LOADING';
    payload: {
        value: boolean,
    };
}

export const loadAresError = (err: ErrorsFromBE): LoadAresErrorAction => ({
    type: 'user/LOAD_ARES_ERROR',
    payload: err,
});

export interface ResetAresValuesAction {
    type: 'user/RESET_ARES_VALUES';
}

export const resetAresValues = (): ResetAresValuesAction => ({
    type: 'user/RESET_ARES_VALUES',
});

// ---

export interface PrefillRegisterFormAction {
    type: 'user/PREFILL_REGISTER_FORM';
    payload: RegisterFormValues;
}

export const prefillRegisterForm = (payload: RegisterFormValues): PrefillRegisterFormAction => ({
    type: 'user/PREFILL_REGISTER_FORM',
    payload,
});

// ---

export const createUser = () => ({
    type: 'user/CREATE_USER',
}) as const;
export type CreateUserAction = ReturnType<typeof createUser>;

// ---

export interface LoadUsersAction {
    type: 'user/LOAD_USERS';
}

export const loadUsers = (): LoadUsersAction => ({
    type: 'user/LOAD_USERS',
});

export interface LoadUsersSuccessAction {
    type: 'user/LOAD_USERS_SUCCESS';
    payload: {
        users: UserDetails[],
    };
}

export const loadUsersSuccess = (users: UserDetails[]): LoadUsersSuccessAction => ({
    type: 'user/LOAD_USERS_SUCCESS',
    payload: {users},

});

export interface LoadUsersErrorAction {
    type: 'user/LOAD_USERS_ERROR';
    payload: ErrorsFromBE;
}

export const loadUsersError = (error: ErrorsFromBE): LoadUsersErrorAction => ({
    type: 'user/LOAD_USERS_ERROR',
    payload: error,
});

// ---

export const deleteUser = (userId: number) => ({
    type: 'user/DELETE_USER',
    payload: {userId},
}) as const;
export type DeleteUserAction = ReturnType<typeof deleteUser>;

// ---

export const loadCustomerList = () => ({
    type: 'user/LOAD_CUSTOMER_LIST',
}) as const;
export type LoadCustomerListAction = ReturnType<typeof loadCustomerList>;

export const loadCustomerListSuccess = (customers: Customer[]) => ({
    type: 'user/LOAD_CUSTOMER_LIST_SUCCESS',
    payload: customers,
}) as const;
export type LoadCustomerListSuccessAction = ReturnType<typeof loadCustomerListSuccess>;

// ---

export const changeCustomerPassword = (customerId: number) => ({
    type: 'user/CHANGE_CUSTOMER_PASSWORD',
    payload: {customerId},
}) as const;
export type ChangeCustomerPasswordAction = ReturnType<typeof changeCustomerPassword>;

// ---

export const changeUserPassword = () => ({
    type: 'user/CHANGE_USER_PASSWORD',
}) as const;
export type ChangeUserPasswordAction = ReturnType<typeof changeUserPassword>;

// ---

export const loadUser = (userId: number) => ({
    type: 'user/LOAD_USER',
    payload: {userId},
}) as const;
export type LoadUserAction = ReturnType<typeof loadUser>;

export const loadUserSuccess = (user: UserDetailsForCustomer) => ({
    type: 'user/LOAD_USER_SUCCESS',
    payload: {user},
}) as const;
export type LoadUserSuccessAction = ReturnType<typeof loadUserSuccess>;

export const loadUserError = (error: ErrorsFromBE) => ({
    type: 'user/LOAD_USER_ERROR',
    payload: {error},
}) as const;
export type LoadUserErrorAction = ReturnType<typeof loadUserError>;

// ---

export const updateUser = () => ({
    type: 'user/UPDATE_USER',
}) as const;
export type UpdateUserAction = ReturnType<typeof updateUser>;

export type UserAction =
    LoadCurrentAction | LoadCurrentSuccessAction | LoadCurrentErrorAction |
    LoginAction | LoginSuccessAction | LoginErrorAction |
    LogoutAction |
    RegisterAction | RegisterSuccessAction | RegisterErrorAction |
    UpdateAction | UpdateSuccessAction | UpdateErrorAction |
    PrefillUpdateCustomerFormAction | ValidateCustomerAction |
    LoadCustomersAction | LoadCustomersSuccessAction | LoadCustomersErrorAction |
    LoadCustomerAction | LoadCustomerSuccessAction | LoadCustomerErrorAction |
    LoadLaborersAction | LoadLaborersSuccessAction | LoadLaborersErrorAction |
    LoadCustomerMilkRoomsAction | LoadCustomerMilkRoomsSuccessAction | LoadCustomerMilkRoomsErrorAction |
    ResetCustomerMilkRoomsAction |
    LoadAresAction | LoadAresSuccessAction | LoadAresErrorAction |
    IsAresLoadingAction | PrefillRegisterFormAction | ResetAresValuesAction |
    FillUpdateCustomerFormFromAresAction |
    CreateUserAction | DeleteUserAction | UpdateUserAction |
    LoadUsersAction | LoadUsersSuccessAction | LoadUsersErrorAction |
    LoadCustomerListAction | LoadCustomerListSuccessAction | ResetPasswordAction | ResetPasswordEmailAction |
    ValidateResetPasswordEmailAction |
    ChangeCustomerPasswordAction | ChangeUserPasswordAction |
    LoadUserAction | LoadUserSuccessAction | LoadUserErrorAction
    ;
