import {flatten, isNumber, take} from 'lodash/fp';
import React, {ReactNode, Fragment, useState, useEffect, ReactElement} from 'react';

import {renderCell} from 'tables/components/Table/renderCell';
import {SortDir} from 'tables/components/withSort';
import {classNames} from 'favorlogic-utils';
import {Column} from '../../types/Column';
import {Filter} from '../../types/Filter';
import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {Row} from '../../types/Row';
import Filters from '../Filters';
import Pagination from '../Pagination';

import styles from './styles.sass';
import sharedStyles from '../styles.sass';

interface Props<T> {
    children?: ReactNode;
    columns: Column<T>[];
    hasHeader?: boolean;
    hasFooter?: boolean;
    rows: Row<T>[];
    total?: number;
    page?: number;
    pageSize?: number;
    filter?: Filter;
    sortBy?: string;
    sortDir?: SortDir;
    emptyMessage?: ReactNode;
    rowSelect?: boolean;
    hasTwoMainColumns?: boolean;
    handleFilterChange?: FilterChangeHandler;

    handlePageChange?(page: number): void;
    handlePageSizeChange?(size: number): void;
    handleSortChange?(accessor: string): void;
    handleFilterSubmit?(): void;
    handleFilterClear?(): void;
    handleSelectRow?(id: number): void;
}

const Table = <T extends {}>(props: Props<T>): ReactElement => {
    const {
        children,
        columns,
        hasHeader: rawHasHeader,
        hasFooter,
        rows,
        total,
        filter,
        sortBy,
        sortDir,
        emptyMessage,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
        handleFilterSubmit,
        handleFilterClear,
        rowSelect,
        handleSelectRow,
        page: rawPage,
        pageSize: rawPageSize,
        hasTwoMainColumns,
    } = props;

    const page = rawPage || 0;
    const limitStep = 20;
    const pageSize = rawPageSize || 20;
    const hasHeader = rawHasHeader === undefined ? true : rawHasHeader;
    const [limit, setLimit] = useState(limitStep);
    const [topOffset, setTopOffset] = useState(0);

    const onFilterChange: FilterChangeHandler = (accessor, type, values) => {
        handleFilterChange?.(accessor, type, values);
        setLimit(limitStep);
    };

    const onFilterSubmit = () => {
        handleFilterSubmit?.();
        setLimit(limitStep);
    };

    const onFilterClear = () => {
        handleFilterClear?.();
        setLimit(limitStep);
    };

    const onHighlightRow = (id: number) => {
        if (rowSelect) {
            handleSelectRow?.(id);
        }
    };

    const renderColumnHeader = (column: Column<T>, isLast: boolean) => {
        const {accessor, header, tooltip, sortable, subHeader} = column;
        const colSpan = isLast ? 2 : 1;
        const headerText = tooltip ? <span title={tooltip}>{header}</span> : header;
        if (!sortable) {
            return (
                <th
                    scope="col"
                    key={accessor}
                    className={classNames(styles['text-lighter'], styles.th)}
                    colSpan={colSpan}
                >
                    {headerText}
                    {subHeader && <span className={styles.subHeader}><br />{subHeader}</span>}
                </th>
            );
        }

        let styleName = '';

        if (column.sortable && column.accessor === sortBy) {
            styleName = sortDir === 'desc' ? 'ordered-desc' : 'ordered-asc';
        }

        const onSortChange = () => {
            handleSortChange?.(column.accessor);
            setLimit(limitStep);
        };

        return (
            <th
                scope="col"
                key={accessor}
                className={classNames(styles.th, styles.sortable, styles[styleName])}
                colSpan={colSpan}
                onClick={onSortChange}
            >
                <div className={classNames(styles.sortableInner)}>
                    {headerText}
                    {subHeader && <Fragment>
                        <br /><span>{subHeader}</span>
                    </Fragment>}
                    <span
                        className={`${styles.orderCaret} ${styles.desc}`}
                    >
                    ▼
                    </span>
                    <span
                        className={`${styles.orderCaret} ${styles.asc}`}
                    >
                    ▲
                    </span>
                </div>
            </th>
        );
    };

    const genStyleNameOfRow = (row: Row<T>): string => flatten([
        rowSelect ? ['row-select'] : [],
        row.selected ? ['selected-row'] : [],
        row.completed ? ['completed-row'] : [],
        row.lowlighted ? ['lowlighted-row'] : [],
        row.warninged ? ['warninged-row'] : [],
        row.dangered ? ['dangered-row'] : [],
        row.successed ? ['successed-row'] : [],
        row.informed ? ['informed-row'] : [],
    ]).map(x => styles[x]).join(' ');

    const headers = columns
        .map((column, index) => renderColumnHeader(column, index === columns.length - 1));

    const ref = React.createRef<HTMLDivElement>();

    const loadDataOnScroll = (offsetTop: number) => {
        const element = ref.current;
        if (!element) {
            return;
        }

        if (limit >= rows.length) {
            return;
        }
        const pageYOffset = -1 * element.getBoundingClientRect().top + offsetTop;
        const elementBottom = offsetTop + element.clientHeight;
        const windowBottom = pageYOffset + window.innerHeight;
        const offset = -20;
        const scrollEnd = windowBottom >= elementBottom + offset;
        if (scrollEnd) {
            setLimit(limit + limitStep);
        }
    };

    const mainWrapper = document.getElementById('mainWrapper');
    const onPageChange = (id: number) => {
        setLimit(20);
        handlePageChange?.(id);
        if (topOffset && mainWrapper) {
            mainWrapper.scrollTo(0, topOffset);
        }
    };

    useEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        if (!topOffset) {
            setTopOffset(element.getBoundingClientRect().top);
        }

        if (!mainWrapper) {
            return;
        }

        const loadData = () => loadDataOnScroll(topOffset);
        mainWrapper.addEventListener('scroll', loadData);
        return () => {
            mainWrapper.removeEventListener('scroll', loadData);
        };
    });

    const onPageSizeChange = (size: number) => {
        handlePageSizeChange?.(size);
        setLimit(limit + limitStep);
    };

    const classes = classNames(
        'table-responsive',
        styles['table-custom'],
        hasTwoMainColumns && styles.twoMainColumns,
    );

    return (
        <div
            ref={ref}
            className={classes}
        >
            <table className={`table ${styles.table}`}>
                {hasHeader &&
                 <thead>
                     <tr>{headers}</tr>

                     {filter &&
                      <Filters
                          columns={columns}
                          filter={filter}
                          onChange={onFilterChange}
                          onSubmit={onFilterSubmit}
                          onClear={onFilterClear}
                          hasTwoMainColumns={hasTwoMainColumns}
                      />
                     }
                 </thead>
                }

                {children && children}

                {!children &&
                 <tbody>
                     {(!rows || rows.length === 0) &&
                      <tr className={styles['empty-row']}>
                          <td>
                              {emptyMessage}
                          </td>
                      </tr>
                     }

                     {take(limit, rows).map((row: Row<T>) =>
                         <tr
                             key={row.id}
                             onClick={() => onHighlightRow(row.id)}
                             className={genStyleNameOfRow(row)}
                         >
                             {columns.map((column, index) =>
                                 <td
                                     key={`${column.accessor}-${row.id}`}
                                     colSpan={index === columns.length - 1 ? 2 : 1}
                                     className={classNames(column.size !== undefined && sharedStyles[column.size])}
                                 >
                                     {renderCell(row, column)}
                                 </td>,
                             )}
                         </tr>,
                     )}
                 </tbody>
                }

                {hasFooter && isNumber(total) && total > 0 &&
                 <tfoot className={styles.tfoot}>
                     <tr>
                         <td
                             colSpan={columns.length + 1}
                         >
                             <Pagination
                                 currentPage={page + 1}
                                 totalPages={Math.ceil(total / pageSize)}
                                 pageSize={pageSize}
                                 pagePadding={4}
                                 className="justify-content-center pagination-sm"
                                 onPageChange={onPageChange}
                                 onPageSizeChange={onPageSizeChange}
                             />
                         </td>
                     </tr>
                 </tfoot>
                }
            </table>
        </div>
    );
};

export default Table;
