import {none, Opt} from 'ts-opt';

import {PickupLine} from 'types/model/pickupLine/PickupLine';
import {Customer} from 'types/model/pickupLine/Customer';
import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import {DateString} from 'types/generic/DateString';
import {PickupLineRequests} from 'types/model/pickupLine/PickupLineRequests';
import {PickupLineDairyPlan} from 'types/model/pickupLine/PickupLineDairyPlan';
import {PickupLineLoading} from 'pickupLine/types/PickupLineLoading';

export interface PickupLineState {
    loading: PickupLineLoading[];
    pickupLines: Opt<PickupLine[]>;
    unassignedDairies: Opt<Customer[]>;
    pickupLineIdInEdit: Opt<number>;
    pickupLinesOptions: Opt<PickupLineOptions[]>;
    overviewDate: Opt<DateString>;
    planningDate: Opt<DateString>;
    displayedPickupLine: Opt<number>;
    pickupLineRequests: Opt<PickupLineRequests>;
    pickupLineDairyPlan: Opt<PickupLineDairyPlan>;
}

export const initialPickupLineState: PickupLineState = {
    loading: [],
    pickupLines: none,
    unassignedDairies: none,
    pickupLineIdInEdit: none,
    pickupLinesOptions: none,
    overviewDate: none,
    planningDate: none,
    displayedPickupLine: none,
    pickupLineRequests: none,
    pickupLineDairyPlan: none,
};
