import React, {Component, Fragment, ReactNode} from 'react';

import {Components as Icons} from 'icons';

import LinkButton from '../LinkButton';

import update from './update.svg';

interface Props {
    to: string;
    label: string | ReactNode;
    disabled?: boolean;
    title?: string;
}

class EditButton extends Component<Props> {
    render(): ReactNode {
        const {
            to,
            label,
            disabled,
            title,
        } = this.props;
        const passedLabel = <Fragment>
            <Icons.SvgIcon icon={update} /> {label}
        </Fragment>;

        return (
            <LinkButton
                label={passedLabel}
                to={to}
                disabled={disabled}
                title={title}
            />
        );
    }
}

export default EditButton;
