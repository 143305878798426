import React from 'react';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {Components as Layout} from 'layout';
import ResetPasswordEmailForm from 'user/components/ResetPasswordEmailForm';

import {resetPasswordEmail} from '../actions';

interface DispatchProps {
    handleResetPasswordEmail(): void;
}

type Props = DispatchProps;

const ResetPasswordEmail = (props: Props) => {
    const {handleResetPasswordEmail} = props;
    return (
        <Layout.Page>
            <h1 className="text-center">Zapomenuté heslo</h1>
            <ResetPasswordEmailForm onSubmit={handleResetPasswordEmail} />
        </Layout.Page>
    );
};

const mapStateToProps = (): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleResetPasswordEmail: () => dispatch(resetPasswordEmail()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(ResetPasswordEmail) as typeof ResetPasswordEmail;
