import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {downloadFileFromResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {GeneratePreviewProtocolAction, generatePreviewProtocolFinished} from '../actions';
import Api from '../api';

export function* execute(_: GeneratePreviewProtocolAction): SagaIterator {
    const response = yield* call(Api.generatePreviewProtocol);

    yield* response.isSuccess
        ? call(downloadFileFromResponse, response)
        : call(handleResponseError, response, 'Generování předběžného protokolu');
    yield* put(generatePreviewProtocolFinished());
}

function* generatePreviewProtocolSaga(): SagaIterator {
    yield takeLatestF('administration/GENERATE_PREVIEW_PROTOCOL', execute);
}

export default generatePreviewProtocolSaga;
