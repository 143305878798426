import React, {ComponentClass, PureComponent, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {flow, isEmpty} from 'lodash/fp';
import {routerActions} from 'connected-react-router';
import {opt} from 'ts-opt';
import {showError} from 'favorlogic-utils';

import withUser from 'user/components/withUser';
import {formValuesF} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';
import {OrderSearch} from 'types/model/orders/OrderSearch';
import {Components as Layout} from 'layout';

import AcceptOrderSearchTable from '../components/AcceptOrderSearchTable';
import BarcodeSearchForm from '../components/BarcodeSearchForm';
import {BarcodeSearchFormValues} from '../types/BarcodeSearchFormValues';
import {searchOrderSamplesBarcode, setOrderSearches} from '../actions';

interface OuterProps {}

interface InnerProps {
    orderSearches: OrderSearch[] | null;
    barcodeSearchFormValues?: BarcodeSearchFormValues;
    isSubmitting: boolean;

    handleResetOrderSearches(): void;

    handleSearchOrderSamplesBarcode(barcode: string): void;

    handleGoToAcceptOrderSample(orderId: number, sampleId: number): void;

    handleShowError(title: string, message: string): void;
}

type Props = InnerProps & OuterProps;

class AcceptOrder extends PureComponent<Props> {
    componentDidMount(): void {
        const {handleResetOrderSearches} = this.props;
        handleResetOrderSearches();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {orderSearches, handleShowError} = this.props;

        if (orderSearches !== null && prevProps.orderSearches !== orderSearches) {
            if (isEmpty(orderSearches)) {
                handleShowError('Vyhledání čárového kōdu', 'Čárový kód nebyl nalezen v žádné objednávce');
            } else if (orderSearches.length === 1) {
                this.handleSelectSample(0);
            }
        }
    }

    render(): ReactNode {
        const {
            orderSearches,
            barcodeSearchFormValues,
            isSubmitting,
        } = this.props;

        return (
            <Layout.Page>
                <h1 className="text-center">Příjem objednávek</h1>
                <div className="w-100 position-relative">
                    <div className="mb-3">
                        <BarcodeSearchForm
                            onChangeBarcode={this.handleBarcodeSearch}
                            formValues={barcodeSearchFormValues}
                        />
                    </div>
                    <Layout.Loader show={isSubmitting} />

                    {orderSearches && orderSearches.length > 1 &&
                        <React.Fragment>
                            <h3 className="text-center mb-3">
                                Čárový kód se vyskytuje ve více objednávkách
                            </h3>
                            <AcceptOrderSearchTable
                                orderSearches={orderSearches}
                                handleSelect={this.handleSelectSample}
                            />
                        </React.Fragment>
                    }
                </div>
            </Layout.Page>
        );
    }

    private handleBarcodeSearch = (barcode: string) => {
        const {handleSearchOrderSamplesBarcode} = this.props;
        if (barcode !== '') {
            handleSearchOrderSamplesBarcode(barcode);
        }
    };

    private handleSelectSample = (rowId: number) => {
        const {orderSearches, handleGoToAcceptOrderSample} = this.props;
        opt(orderSearches)
            .chainToOpt(x => x[rowId])
            .onSome(({orderId, sampleId}) => handleGoToAcceptOrderSample(orderId, sampleId));
    };
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    orderSearches: state.order.orderSearches,
    barcodeSearchFormValues: formValuesF('barcodeSearchForm')(state).orUndef(),
    isSubmitting: state.layout.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleResetOrderSearches: () => dispatch(setOrderSearches(null)),
    handleSearchOrderSamplesBarcode: (barcode: string) => dispatch(searchOrderSamplesBarcode(barcode)),
    handleShowError: (title: string, message: string) => dispatch(showError(title, message)),
    handleGoToAcceptOrderSample: (orderId: number, sampleId: number) => {
        dispatch(routerActions.push('/orders'));
        dispatch(routerActions.push(`/orders/${orderId}`));
        dispatch(routerActions.push({
            pathname: `/orders/${orderId}/acceptSamples`,
            state: {sampleId},
        }));
    },
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(AcceptOrder) as ComponentClass<OuterProps>;
