import * as t from 'io-ts';
import {chain} from 'fp-ts/Either';
import {pipe} from 'fp-ts/function';
import {flow, initial, isString, last, map, multiply, parseInt, range, split, spread, sum} from 'lodash/fp';
import {opt} from 'ts-opt';
import {zip} from 'favorlogic-utils';

const icoRegExp = /^\d{8}$/;

export const isIcoValid = (str: string): boolean => {
    if (!isString(str)) { return false; }
    if (!icoRegExp.test(str)) { return false; }
    const [numsWithoutCheck, checkNum] = flow(
        split(''),
        map(parseInt(10)),
        (xs): [number[], number] => [initial(xs), opt(last(xs)).orElse(NaN)],
    )(str);
    const weights = range(8, 2 - 1);
    const weightedSum = flow<[number[]], [number, number][], number[], number>(
        zip(weights),
        map(spread(multiply)),
        sum,
    )(numsWithoutCheck);
    const computedCheckNum = (11 - weightedSum % 11) % 10;
    return checkNum === computedCheckNum;
};

export const IcoSchema = new t.Type<Ico>(
    'Ico',
    (m: unknown): m is string => typeof m === 'string',
    (m, c) =>
        pipe(
            t.string.validate(m, c),
            chain(s => isIcoValid(s) ? t.success(s) : t.failure(s, c)),
        ),
    a => a,
);

export type Ico = string;
