import {find, flow, join} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {opt} from 'ts-opt';
import {limitStringArrayWithEllipses} from 'favorlogic-utils';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {Analysis} from 'types/model/analyses/Analysis';
import {DataItem} from 'types/model/dataItems/DataItem';
import {LoaderWrapper} from 'layout/components';

import RowActions from './RowActions';
import {availabilityOptions} from '../../types/AvailabilityOptions';

const MaxDataItems = 3;

export interface AnalysisRow extends Analysis {
    dataItems: ReactNode;
    actions: ReactNode;
}

export interface Props {
    analyses: Analysis[] | null;
    dataItems: DataItem[] | null;
}

class AnalysesTable extends Component<Props> {
    private static genColumns(): Column<AnalysisRow>[] {
        return [
            {
                accessor: 'name',
                header: 'Název',
                type: ColumnType.Node,
            },
            {
                accessor: 'index',
                header: 'Pořadí',
                type: ColumnType.Node,
            },
            {
                accessor: 'abbr',
                header: 'Zkratka',
                type: ColumnType.Node,
            },
            {
                accessor: 'dataItems',
                header: 'Datové položky',
                type: ColumnType.Node,
            },
            {
                accessor: 'price',
                header: 'Cena',
                type: ColumnType.Currency,
            },
            {
                accessor: 'availability',
                header: 'Dostupnost',
                type: ColumnType.SelectOption,
                selectOptions: availabilityOptions,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    render(): React.ReactNode {
        const {analyses} = this.props;

        return (
            <LoaderWrapper showLoader={!analyses}>
                <Tables.Table
                    columns={AnalysesTable.genColumns()}
                    rows={this.genData()}
                />
            </LoaderWrapper>
        );
    }

    private createDataItemsElement(dataItemIds: number[]): ReactNode {
        const dataItemNames = dataItemIds.map(id => this.getDataItemName(id));
        const dataItemsShort = flow(
            limitStringArrayWithEllipses(MaxDataItems),
            join(', '),
        )(dataItemNames);
        const dataItemsLong = join(', ')(dataItemNames);

        return <span title={dataItemsLong}>{dataItemsShort}</span>;
    }

    private getDataItemName(dataItemId: number): string {
        const {dataItems} = this.props;

        return opt(find(x => x.id === dataItemId, dataItems))
            .map(x => x.name).orElse('?');
    }

    private genData(): Row<AnalysisRow>[] {
        const {analyses} = this.props;
        if (!analyses) {
            return [];
        }

        return analyses.map(analysis => ({
            ...analysis,
            dataItems: this.createDataItemsElement(analysis.dataItemIds),
            actions: <RowActions
                analysis={analysis}
            />,
        }));
    }
}

export default AnalysesTable;
