import {SagaIterator} from 'redux-saga';
import {call, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {isEmpty} from 'lodash/fp';
import {
    emptyStringToUndefined,
    extractFormErrorsFromResponse,
    UnsafeBeFormErrors,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {isDicValid} from 'types/generic/DicSchema';
import {isIcoValid} from 'types/generic/IcoSchema';
import {CustomerValidation} from 'types/model/customers/CustomerValidation';
import {isEmailValid} from 'types/generic/EmailSchema';

import {ValidateCustomerAction} from '../actions';
import Api from '../api';

export const prepareValues = (values: CustomerValidation, customerId?: number): CustomerValidation => ({
    username: emptyStringToUndefined(values.username),
    email: opt(values.email).filter(isEmailValid).orUndef(),
    ico: opt(values.ico).filter(isIcoValid).orUndef(),
    dic: opt(values.dic).filter(isDicValid).orUndef(),
    code: emptyStringToUndefined(values.code),
    customerId,
});

function getCurrentUserId(state: StoreState): number {
    return state.user.currentUser
        .map(user => user.id)
        .orCrash('missing user');
}

function getLaborerId(state: StoreState): number {
    return state.user.customerForLaborer
        .map(customer => customer.id)
        .orCrash('missing customer for laborer');
}

function handleErrorResponse(response: ErrorResponse, reject: (_: UnsafeBeFormErrors) => void): void {
    const errors = extractFormErrorsFromResponse(response);

    if (!isEmpty(errors)) {
        reject(errors);
    }
}

function* execute({payload}: ValidateCustomerAction): SagaIterator {
    const {reject, resolve, values, isCreate, isAdminOrLaborer} = payload;
    const getCustomerId = isAdminOrLaborer ? getLaborerId : getCurrentUserId;
    const customerId = isCreate ? undefined : yield* select(getCustomerId);
    const response = yield* call(Api.validateCustomer, prepareValues(values, customerId));

    yield* response.isSuccess
        ? call(resolve, false)
        : call(handleErrorResponse, response, reject);
}

export function* validateCustomerSaga(): SagaIterator {
    yield takeLatestF('user/VALIDATE_CUSTOMER', execute);
}
