import {Nullable} from 'favorlogic-utils';

import {Config} from 'app/Config';
import {ADMIN, BREEDER, DAIRY, DAIRY_EMPLOYEE, LABORER, PICKUP_LINE_ADMIN} from 'types/model/enumeration/Role';
import {UserDetails} from 'types/model/users/UserDetails';

import {MenuItem} from '../../types/MenuItem';

export const menuItems = (user: Nullable<UserDetails>): MenuItem[] => [
    {
        label: 'Zákazníci',
        path: '/customers',
        permissions: [ADMIN, LABORER],
    },
    {
        label: 'Dodavatelé',
        path: '/suppliers',
        permissions: [DAIRY, BREEDER],
    },
    {
        label: 'Linky',
        path: '/supply-chains',
        permissions: [DAIRY],
    },
    {
        label: 'Objednávky',
        path: '/orders',
        permissions: [ADMIN, LABORER, BREEDER, DAIRY, DAIRY_EMPLOYEE],
    },
    {
        label: 'Vzorky',
        path: '/samples',
        permissions: [ADMIN, LABORER]},
    {
        label: 'Měření',
        path: '/measurements',
        permissions: [ADMIN, LABORER, BREEDER, DAIRY, DAIRY_EMPLOYEE],
    },
    {
        label: 'Svozové linky',
        path: '/pickup-lines',
        permissions: [PICKUP_LINE_ADMIN],
        hidden: Config.isProduction,
        subItems: [
            {path: '/planning', label: 'Plánování', permissions: [PICKUP_LINE_ADMIN]},
            {path: '/overview', label: 'Přehled', permissions: [PICKUP_LINE_ADMIN]},
            {path: '/administration', label: 'Administrace', permissions: [PICKUP_LINE_ADMIN]},
        ],
    },
    {
        label: 'Svozové linky',
        path: '/pickup-lines/dairy-plan',
        permissions: [DAIRY, DAIRY_EMPLOYEE],
        hidden: Config.isProduction || user !== null &&
            (user.tag !== 'UserDetailsForCustomer' || !user.pickupLineAccess),
    },
    {
        label: 'Administrace',
        path: '/administration',
        permissions: [ADMIN],
        subItems: [
            {path: '/data-items', label: 'Datové položky', permissions: [ADMIN]},
            {path: '/operating-procedures', label: 'Operační postupy', permissions: [ADMIN]},
            {path: '/devices', label: 'Přístroje', permissions: [ADMIN]},
            {path: '/analyses', label: 'Rozbory', permissions: [ADMIN]},
            {path: '/others', label: 'Ostatní', permissions: [ADMIN]},
        ],
    },
];
