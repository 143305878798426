import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {parseSearch} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {loadCustomers} from '../actions';
import CustomersTable, {definedFilters} from '../components/CustomersTable';
import {CustomersPage} from 'types/model/customers/CustomerPage';
import {CustomersParams} from 'user/types/CustomersParams';
import {withSort, withPagination, withFilter} from 'tables/components';
import {SortProps} from 'tables/components/withSort';
import {Components as Layout} from 'layout';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';

interface OuterProps {}

interface StateProps {
    customersPage: CustomersPage | null;
}

interface DispatchProps {
    loadCustomers: (params: CustomersParams) => void;
}

type RouterProps = Pick<RouteComponentProps, 'location'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type Props = OuterProps & StateProps & DispatchProps & ListProps & RouterProps;

class CustomersList extends Component<Props> {
    componentDidMount(): void {
        const {location: {search}} = this.props;

        if (search) { this.loadCustomersByQuery(search); }
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}} = prevProps;
        const {location: {search}} = this.props;

        if (prevSearch !== search) {
            this.loadCustomersByQuery(search);
        }
    }

    render(): ReactNode {
        const {
            customersPage,
            filter,
            sortBy,
            sortDir,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
        } = this.props;

        if (customersPage && isEmpty(customersPage.content) && isEmpty(filter)) {
            return this.renderNoCustomers();
        }

        return (
            <Layout.ListPage
                title="Zákazníci"
                fluid
            >
                <CustomersTable
                    customersPage={customersPage}
                    sort={{property: sortBy, direction: sortDir}}
                    filter={filter}
                    changeSort={handleSortChange}
                    changePage={handlePageChange}
                    changePageSize={handlePageSizeChange}
                    changeFilter={handleFilterChange}
                    resetFilter={handleFilterClear}
                    filterCustomers={handleFilterSubmit}
                />
            </Layout.ListPage>
        );
    }

    private renderNoCustomers(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="text-center h5">
                    Zatím nejsou žádní zákazníci
                </div>
            </Layout.EmptyPage>
        );
    }

    private loadCustomersByQuery(search: string): void {
        const {loadCustomers} = this.props;
        const params = parseSearch(search);
        loadCustomers(params);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    customersPage: state.user.customersPage.orNull(),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadCustomers: (params: CustomersParams) => dispatch(loadCustomers(params)),
});

export default flow([
    withUser,
    withSort(),
    withPagination,
    withFilter(definedFilters),
    connect(mapStateToProps, mapDispatchToProps),
])(CustomersList) as ComponentClass<OuterProps>;
