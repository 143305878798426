import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {ApproveSamplesByFilterAction} from '../actions';
import {handleErrorResponse, handleSuccess} from './utils/approveSamplesSagaUtils';
import Api from '../api';

function* execute({payload: {filter}}: ApproveSamplesByFilterAction): SagaIterator {
    const response = yield* call(Api.approveSamplesByFilter, filter);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* approveSamplesByFilterSaga(): SagaIterator {
    yield takeLatestF('measurement/APPROVE_SAMPLES_BY_FILTER', execute);
}
