import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {ResetPasswordEmailFormValues} from 'user/types/ResetPasswordEmailFormValues';

type Values = ResetPasswordEmailFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('email', 'Email');
    validator.email('email', 'Email');

    return validator.generateErrorsObject();
};
