exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-wizard-components-Step-___styles__step___3SSQN > div:first-child {\n  box-shadow: none; }\n", ""]);

// exports
exports.locals = {
	"step": "src-modules-wizard-components-Step-___styles__step___3SSQN"
};