import React, {FC, useState, Fragment} from 'react';
import {Opt} from 'ts-opt';
import {validateKeyGen} from 'favorlogic-utils';

import {Fields} from 'forms';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {canSetSampleForSubsidy} from 'sample/utils/canSetSampleForSubsidy';
import {SelectOptions} from 'forms/components/BasicSelect';

const validateKey = validateKeyGen<CreateSampleFormValues>();

interface Props {
    formValues?: CreateSampleFormValues;
    showDairyTraits: boolean;
    allowQCZ: boolean;
    showTraitNoteSupplyChain?: boolean;
    supplyChainsOptions?: SelectOptions<number>;

    change<N extends keyof CreateSampleFormValues>(field: N, value: CreateSampleFormValues[N]): void;
}

const Traits: FC<Props> = props => {
    const {
        formValues,
        change,
        allowQCZ,
        showDairyTraits,
        showTraitNoteSupplyChain,
        supplyChainsOptions,
    } = props;
    const [milkRoomId, setMilkRoomId] = useState<number | null>(null);
    const isCisternOrControl = Boolean(formValues?.cistern || formValues?.control);
    const canSetSubsidy = formValues && canSetSampleForSubsidy(formValues);

    const onCisternChange = (value: Opt<boolean>): void => {
        const isChecked = value.orFalse();

        if (isChecked) {
            setMilkRoomId(formValues?.milkRoomId || null);
            change('milkRoomId', null);
        } else {
            change('milkRoomId', milkRoomId);

            if (showTraitNoteSupplyChain) {
                change('supplyChainId', null);
            }
        }

        if (!formValues?.control) {
            if (isChecked) {
                change('subsidy', false);
            } else {
                change('traitNote', '');
            }
        }
    };

    const onControlChange = (value: Opt<boolean>): void => {
        const isChecked = value.orFalse();

        if (!formValues?.cistern) {
            if (isChecked) {
                change('subsidy', false);
            } else {
                change('traitNote', '');
            }
        }
    };

    return (
        <Fragment>
            <div className="row mt-2">
                <div className="col-12 pb-1 pt-2">Příznaky</div>
                {showDairyTraits && <Fragment>
                    <div className="col-md-3">
                        <Fields.Checkbox
                            name={validateKey('qualitative')}
                            label="(P) Platný"
                        />
                    </div>
                    <div className="col-md-3">
                        <Fields.Checkbox
                            name={validateKey('cistern')}
                            label="(C) Cisterna"
                            onFieldChange={onCisternChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <Fields.Checkbox
                            name={validateKey('control')}
                            label="(K) Kontrolní"
                            onFieldChange={onControlChange}
                        />
                    </div>
                </Fragment>}
                {allowQCZ && <div className="col-md-3">
                    <Fields.Checkbox
                        name={validateKey('subsidy')}
                        label="Q CZ"
                        disabled={!canSetSubsidy}
                    />
                </div>}
            </div>

            {showDairyTraits && isCisternOrControl && <div className="row mt-2">
                {showTraitNoteSupplyChain
                    ? <Fragment>
                        <div className="col-md-6">
                            <Fields.Input
                                name={validateKey('traitNote')}
                                type="text"
                                label="Poznámka k příznakům"
                                tooltip={`Poznámka k příznakům "Cisterna" / "Kontrolní"`}
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Select
                                name={validateKey('supplyChainId')}
                                label="Linka"
                                options={supplyChainsOptions || []}
                            />
                        </div>
                    </Fragment>
                    : <div className="col-12">
                        <Fields.Input
                            name={validateKey('traitNote')}
                            type="text"
                            label="Poznámka k příznakům"
                            tooltip={`Poznámka k příznakům "Cisterna" / "Kontrolní"`}
                        />
                    </div>
                }
            </div>}
        </Fragment>
    );
};
export default Traits;
