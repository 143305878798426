import {SelectOptions} from 'forms/components/BasicSelect';
import {LimitAction} from 'types/model/enumeration/LimitAction';

export const limitActionsOptions: SelectOptions<LimitAction> = [
    {
        value: 'KEEP_VALUES',
        label: 'Zachovat hodnoty',
    },
    {
        value: 'REPLACE_MIN',
        label: 'Nahradit mimimum',
    },
    {
        value: 'REPLACE_MAX',
        label: 'Nahradit maximum',
    },
    {
        value: 'REPLACE_BOTH',
        label: 'Nahradit mimimum i maximum',
    },
];
