import {OwnProps} from './index';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';

type Values = CreateSampleFormValues;

export const validate = (values: Values, props: OwnProps): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('samplingDate', 'Datum odběru');
    validator.nonEmpty('type', 'Typ vzorku');
    validator.nonEmpty('harvestType', 'Způsob odběru');
    validator.nonEmpty('analysisIds', 'Požadované rozbory');
    validator.nonEmpty('barcode', 'Kód');

    validator.uniqueCustomError(
        'barcode',
        props.sampleBarcodeIds,
        'V objednávce nemůže být více vzorků se stejným čárovým kódem.'
    );

    validator.barcodeCustomError('barcode', 'Čárový kód musí být 1 až 10 místné číslo');
    validator.positiveNumber('sampleSourceAmount', 'Množství');
    validator.temperature('samplingTemperature', 'Teplota');
    validator.maxStringLength('traitNote', 24, 'Poznámka k příznakům');

    return validator.generateErrorsObject();
};
