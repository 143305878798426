import {flow, sortBy} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {opt, Opt} from 'ts-opt';
import {todayDate} from 'favorlogic-utils';

import {Components as Layout} from 'layout';
import withUser, {WithUserProps} from 'user/components/withUser';
import {Device} from 'types/model/devices/Device';
import {SelectOptions} from 'forms/components/BasicSelect';
import {StoreState} from 'app/types/StoreState';
import {UserDetails} from 'types/model/users/UserDetails';
import {loadLaborers} from 'user/actions';
import {loadDevices} from 'device/actions';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {DataItem} from 'types/model/dataItems/DataItem';
import {loadDataItems} from 'dataItem/actions';
import {formValuesF, initializeF} from 'utils/formHelpers';

import {createDeviceMeasurements, resetSearchSamples, searchSamples} from '../actions';
import DeviceMeasurementsForm, {deviceMeasurementsInitialValues} from '../components/DeviceMeasurementsForm';
import {DeviceMeasurementsFormValues} from '../type/DeviceMeasurementsFormValues';
import {buildUserOptions} from '../utils/buildUserOptions';

interface OuterProps {}

interface StateProps {
    laborers: Opt<UserDetails[]>;
    devices: Device[] | null;
    dataItems: DataItem[] | null;
    samples: Opt<SampleSearch[]>;
    selectedDeviceId?: number;
}

interface DispatchProps {
    loadLaborers(): void;
    loadDevices(): void;
    searchSamples(deviceId: number): void;
    resetSearchSamples(): void;
    loadDataItems(): void;
    createDeviceMeasurements(deviceDataItems: DataItem[]): void;
    initializeForm(values: DeviceMeasurementsFormValues): void;
}

type UserProps = Pick<WithUserProps, 'currentUser'>;
type Props = OuterProps & StateProps & DispatchProps & UserProps;

class NewDevice extends Component<Props> {
    componentDidMount(): void {
        const {loadLaborers, loadDevices, loadDataItems, initializeForm, currentUser} = this.props;
        initializeForm({
            ...deviceMeasurementsInitialValues,
            measured: todayDate(),
            measuredById: currentUser.orCrash('missing user').id,
        });
        loadLaborers();
        loadDevices();
        loadDataItems();
    }

    render(): ReactNode {
        const {
            samples,
        } = this.props;

        return (
            <Layout.ItemPage
                title="Nové měření z přístroje"
                backLabel="Měření"
            >
                <DeviceMeasurementsForm
                    selectedDevice={this.getSelectedDevice()}
                    onSubmit={this.handleSubmitForm}
                    samples={samples.orNull()}
                    dataItems={this.getAvailableDataItems()}
                    userOptions={this.buildUserOptions()}
                    deviceOptions={this.buildDeviceOptions()}
                    onDeviceChange={this.selectDevice}
                />
            </Layout.ItemPage>
        );
    }

    private getAvailableDataItems = (): DataItem[] => {
        const {dataItems} = this.props;

        const selectedDevice = this.getSelectedDevice();
        if (!selectedDevice) {
            return [];
        }

        return opt(dataItems)
            .map(sortBy(x => x.columnIndex))
            .orElse([])
            .filter(dataItem => selectedDevice.dataItems
                .map(i => i.dataItemId)
                .includes(dataItem.id));
    };

    private getSelectedDevice = (): Device | undefined => {
        const {devices, selectedDeviceId} = this.props;

        if (!selectedDeviceId || !devices) {
            return undefined;
        }

        return devices.find(d => d.id === selectedDeviceId);
    };

    private selectDevice = (deviceId: number | null) => {
        const {searchSamples, resetSearchSamples} = this.props;

        resetSearchSamples();

        if (deviceId) {
            searchSamples(deviceId);
        }
    };

    private buildUserOptions(): SelectOptions<number> {
        const {laborers} = this.props;

        return buildUserOptions(laborers);
    }

    private buildDeviceOptions(): SelectOptions<number> {
        const {devices} = this.props;

        return opt(devices).orElse([]).map(device => ({
            label: device.name,
            value: device.id,
        }));
    }

    private readonly handleSubmitForm = () => {
        const {createDeviceMeasurements} = this.props;
        createDeviceMeasurements(this.getAvailableDataItems());
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    laborers: state.user.laborers,
    devices: state.device.devices,
    samples: state.measurement.samplesSearch,
    dataItems: state.dataItem.dataItems,
    selectedDeviceId: formValuesF('deviceMeasurements')(state)
        .chainToOpt(x => x.deviceId).orUndef(),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadLaborers: () => dispatch(loadLaborers()),
    loadDevices: () => dispatch(loadDevices()),
    loadDataItems: () => dispatch(loadDataItems()),
    createDeviceMeasurements: (deviceDataItems: DataItem[]) => dispatch(createDeviceMeasurements(deviceDataItems)),
    searchSamples: (deviceId: number) => dispatch(searchSamples({deviceId})),
    resetSearchSamples: () => dispatch(resetSearchSamples()),
    initializeForm: (values: DeviceMeasurementsFormValues) => dispatch(initializeF('deviceMeasurements', values)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
    withUser,
])(NewDevice) as ComponentClass<OuterProps>;
