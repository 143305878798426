import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

import {loadOperatingProceduresError, loadOperatingProceduresSuccess} from '../actions';
import Api from '../api';

const title = 'Načtení operačních postupů';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadOperatingProceduresError(error));
}

function* handleSuccessResponse(response: SuccessResponse<OperatingProcedure[]>): SagaIterator {
    yield* put(loadOperatingProceduresSuccess(response.data));
}

function* execute(): SagaIterator {
    const response = yield* call(Api.getOperatingProcedures);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadOperatingProceduresSaga(): SagaIterator {
    yield takeLatestF('operatingProcedure/LOAD_OPERATING_PROCEDURES', execute);
}
