import React, {Component, ReactNode} from 'react';
import {WrappedFieldArrayProps} from 'redux-form';
import {random} from 'lodash/fp';

import {DeviceDataItemFormValues} from 'device/types/DeviceDataItemValues';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {limitActionsOptions} from 'device/types/LimitActionOptions';
import {Fields} from 'forms';
import {LimitAction} from 'types/model/enumeration/LimitAction';

import {DeviceFormMode} from './index';

const getRandomInteger = () => random(1, 10000);

export interface DeviceDataItemsProps {
    name: 'dataItems';
    FormField: unknown;
    label: string;
    dataItemsOptions: SelectOptions<number>;
    mode: DeviceFormMode;
}

type Props = WrappedFieldArrayProps<DeviceDataItemFormValues> & DeviceDataItemsProps;

class DeviceDataItems extends Component<Props> {
    render(): ReactNode {
        const {fields, label, mode} = this.props;

        return <div>
            <div className="d-flex justify-content-between pb-2">
                <span className="align-self-center">{label}</span>
                {mode === 'CREATE' &&  <Buttons.Button
                    onClick={() => fields.push({id: getRandomInteger()})}
                    className="ml-4 align-self-center"
                    type="button"
                    importance="secondary"
                >
                    Přidat položku
                </Buttons.Button>}
            </div>
            {fields.length === 0 && <small>Žádné datové pložky</small>}
            {fields.map((field, index) => this.renderDataItem(field, index))}
        </div>;
    }

    private getSelectedDataItemsIds(): number[] {
        const {fields} = this.props;
        const dataItems = fields.getAll();
        const dataItemsIds: number[] = [];

        dataItems.forEach(dataItem => {
            if (dataItem.dataItemId) {
                dataItemsIds.push(dataItem.dataItemId);
            }
        });

        return dataItemsIds;
    }

    private getAvaiableDataItemsOptions(index: number): SelectOptions<number> {
        const {dataItemsOptions} = this.props;
        const selectedDataItemsIds = this.getSelectedDataItemsIds();
        const selectedDataItemId = this.getSelectedDataItemId(index);

        return dataItemsOptions.filter(option =>
            !selectedDataItemsIds.includes(option.value) || option.value === selectedDataItemId,
        );
    }

    private getSelectedDataItemId(index: number): number | undefined {
        const {fields} = this.props;

        return fields.get(index).dataItemId;
    }

    private renderDataItem(field: string, index: number): ReactNode {
        const {fields, mode} = this.props;
        const values = fields.get(index);

        return <Layout.Panel key={values.id}>
            <div className="row mt-2">
                <div className="col-md-8">
                    <Fields.Select<number>
                        name={`${field}.dataItemId`}
                        label="Datová položka"
                        options={this.getAvaiableDataItemsOptions(index)}
                    />
                </div>
                {mode === 'CREATE' && <div className="col-md-4 d-flex align-items-center">
                    <Buttons.Button
                        type="button"
                        onClick={() => fields.remove(index)}
                        importance="secondary"
                    >
                        Odebrat položku
                    </Buttons.Button>
                </div>}
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <Fields.Input
                        name={`${field}.minimum`}
                        type="number"
                        label="Minimum"
                    />
                </div>
                <div className="col-md-4">
                    <Fields.Input
                        name={`${field}.maximum`}
                        type="number"
                        label="Maximum"
                    />
                </div>
                <div className="col-md-4">
                    <Fields.Select<LimitAction>
                        name={`${field}.action`}
                        label="Akce"
                        options={limitActionsOptions}
                    />
                </div>
            </div>
        </Layout.Panel>;
    }
}

export default DeviceDataItems;
