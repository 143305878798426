import React, {Component, ReactNode, ComponentType} from 'react';
import {RouteComponentProps} from 'react-router';
import {isString, isEqual} from 'lodash/fp';
import {opt} from 'ts-opt';
import {parseSearch, mapStateToSearch, ParsedQuery, StateData} from 'favorlogic-utils';

import {
    DEFAULT_PAGE,
    DEFAULT_PAGE_SIZE,
} from '../../constants';

export interface PaginationProps {
    page: number;
    size: number;

    handlePageChange(page: number): void;
    handlePageSizeChange(size: number): void;
}

type Props = RouteComponentProps;

interface State {
    page: number;
    size: number;
}

const withPagination = (WrappedComponent: ComponentType<PaginationProps>): ReactNode => {
    class WithPagination extends Component<Props, State> {
        constructor(props: Props) {
            super(props);

            const parsedSearch = this.getParsedQuery();

            this.state = this.getStateFromSearch(parsedSearch);
            this.replaceQuery({...parsedSearch, ...this.state});
        }

        componentDidUpdate(props: Props, prevState: State): void {
            const {history} = props;

            // if state is same then possibly props (location) have changed and we need to reflect that in state
            if (isEqual(this.state)(prevState)) {
                const search = parseSearch(history.location.search);
                const searchState = this.getStateFromSearch(search);

                if (!isEqual(searchState)(this.state)) {
                    this.setState(searchState);
                }
            }
        }

        private getParsedQuery(): ParsedQuery {
            const {history: {location}} = this.props;

            return parseSearch(location.search);
        }

        private setQuery(query: StateData): void {
            const {history} = this.props;

            history.push(`?${mapStateToSearch(query)}`);
        }

        private replaceQuery(query: StateData): void {
            const {history} = this.props;

            history.replace(`?${mapStateToSearch(query)}`);
        }

        private getStateFromSearch(search: ParsedQuery): State {
            const {page, size} = search;

            return {
                page: opt(page)
                    .map((x) => isString(x) ? parseInt(x, 10) : DEFAULT_PAGE)
                    .orElse(DEFAULT_PAGE),
                size: opt(size)
                    .map((x) => isString(x) ? parseInt(x, 10) : DEFAULT_PAGE_SIZE)
                    .orElse(DEFAULT_PAGE_SIZE),
            };
        }

        private handlePageChange = (page: number): void => {
            const parsedSearch = this.getParsedQuery();
            const newState: State = {...this.state, page};

            this.setState(newState);
            this.setQuery({...parsedSearch, ...newState});
        }

        private handlePageSizeChange = (size: number): void => {
            const parsedSearch = this.getParsedQuery();
            const newState: State = {page: DEFAULT_PAGE, size};

            this.setState(newState);
            this.setQuery({...parsedSearch, ...newState});
        }

        render(): ReactNode {
            const {page, size} = this.state;

            return (
                <WrappedComponent
                    handlePageChange={this.handlePageChange}
                    handlePageSizeChange={this.handlePageSizeChange}
                    page={page}
                    size={size}
                    {...this.props} // eslint-disable-line react/jsx-props-no-spreading
                />
            );
        }
    }
    return WithPagination;
};

export default withPagination;
