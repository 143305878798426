import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {downloadFileFromResponse, showSuccess, takeLatestF, ErrorsFromBE} from 'favorlogic-utils';

import {isLoading} from 'layout/actions';
import {StoreState} from 'app/types/StoreState';
import {CreateProtocol} from 'types/model/protocols/CreateProtocol';
import {handleResponseError} from 'utils/handleResponseError';
import {resetF} from 'utils/formHelpers';

import {
    GenerateProtocolAction,
    generateProtocolError,
    generateProtocolSuccess,
} from '../actions';
import Api from '../api';

function* execute({payload: {orderId}}: GenerateProtocolAction): SagaIterator {
    const title = 'Generace protokolu';
    const formValues = yield* select((x: StoreState) => x.form.generateProtocol.values);
    if (!formValues || !formValues.processedFrom || !formValues.processedTo) { throw new Error('Missing order data'); }

    const values: CreateProtocol = {
        processedFrom: formValues.processedFrom,
        processedTo: formValues.processedTo,
    };

    yield* put(isLoading(true));

    const response = yield* call(Api.createProtocol, values, orderId);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        yield* put(isLoading(false));
        return yield* put(generateProtocolError(error));
    }
    yield* put(showSuccess(title, 'Úspěšně vygenerován.'));
    yield* put(generateProtocolSuccess());
    yield* put(resetF('generateProtocol'));
    yield* call(downloadFileFromResponse, response);
    yield* put(isLoading(false));
    yield* put(routerActions.goBack());
}

export function* generateProtocolSaga(): SagaIterator {
    yield takeLatestF('order/GENERATE_PROTOCOL', execute);
}
