import React, {ReactNode, FunctionComponent} from 'react';

import Page from '../Page';

interface Props {
    title: string;
    toolbar?: ReactNode;
    fluid?: boolean;
}

const ListPage: FunctionComponent<Props> = props => {
    const {title, toolbar, fluid, children} = props;

    return (
        <Page fluid={fluid}>
            <div className="w-100 d-flex flex-wrap justify-content-between align-items-center mb-2">
                <h1 className="text-nowrap pr-3 pt-2">{title}</h1>
                {toolbar}
            </div>
            {children}
        </Page>
    );
};

export default ListPage;
