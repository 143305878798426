import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {createOperatingProcedure} from '../actions';
import OperatingProcedureForm from '../components/OperatingProcedureForm';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface InnerProps {
    handleCreateOperatingProcedure(): void;
}

type Props = InnerProps & OuterProps;

class OperatingProceduresNew extends Component<Props> {
    render(): ReactNode {
        const {handleCreateOperatingProcedure} = this.props;

        return (
            <Layout.ItemPage
                title="Nový operační postup"
                backLabel="Operační postupy"
            >
                <OperatingProcedureForm
                    mode="NEW"
                    onSubmit={handleCreateOperatingProcedure}
                />
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleCreateOperatingProcedure: () => dispatch(createOperatingProcedure()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(OperatingProceduresNew) as ComponentClass<OuterProps>;
