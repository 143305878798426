import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {
    downloadFileFromResponse,
    extractFormErrorsFromResponse,
    SuccessResponse,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {formValuesF, startSubmitF, stopSubmitF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';
import {setModalVisibility} from 'layout/actions';

import {ExportMeasurementsFillingAction} from '../actions';
import Api from '../api';

const formName = 'exportMeasurementsFilling';
const title = 'Export podkladů měření';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF(formName, extractFormErrorsFromResponse(response)));
}

function* handleSuccessResponse(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* call(downloadFileFromResponse, response);
    yield* put(stopSubmitF(formName));
    yield* put(setModalVisibility('exportMeasurementsFilling', false));
}

function* execute(_: ExportMeasurementsFillingAction): SagaIterator {
    yield* put(startSubmitF(formName));

    const formValues = (yield* select(formValuesF(formName))).orCrash('missing form values');
    const dataItemId = opt(formValues.dataItemId).orCrash('missing dataItemId');
    const operatingProcedureId = opt(formValues.operatingProcedureId).orCrash('missing operatingProcedureId');
    const response = yield* call(Api.exportMeasurementsFilling, dataItemId, operatingProcedureId);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* exportMeasurementsFillingSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_MEASUREMENT_FILLING', execute);
}
