import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {flow} from 'lodash/fp';
import {Dispatch} from 'redux';
import {Modal} from 'layout/components';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {confirm, cancel} from '../../actions';

interface OuterProps {
}

interface InnerProps {
    isOpen: boolean;
    title: string;
    text: string;

    handleConfirm(): void;

    handleCancel(): void;
}

type Props = InnerProps & OuterProps;

class ConfirmDialog extends Component<Props> {
    render(): ReactNode {
        const {
            isOpen,
            handleConfirm,
            handleCancel,
            title,
            text,
        } = this.props;
        if (!isOpen) { return null; }
        return (
            <Modal.Container
                show={isOpen}
                onClickAway={handleCancel}
            >
                <div>
                    <h2>{title}</h2>
                    <div className="mt-4 mb-2">
                        {text}
                    </div>

                    <Buttons.Button
                        onClick={handleConfirm}
                        className="w-100 mt-3"
                        importance="primary"
                        type="submit"
                    >
                        Potvrdit
                    </Buttons.Button>
                </div>
            </Modal.Container>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isOpen: state.confirmDialog.opened,
    title: state.confirmDialog.title,
    text: state.confirmDialog.text,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleConfirm: () => dispatch(confirm()),
    handleCancel: () => dispatch(cancel()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(ConfirmDialog) as ComponentClass<OuterProps>;
