import * as t from 'io-ts';

const requiredPart = t.interface({
    id: t.number,
    name: t.string,
});

export const CustomerSchema = t.exact(requiredPart);

export interface Customer extends t.TypeOf<typeof CustomerSchema> {}
