exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-pickupLine-components-DaysColumn-___styles__daysColumn___2_wjq {\n  position: sticky;\n  left: 0;\n  z-index: 1;\n  background-color: #ffffff;\n  vertical-align: top; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__col___24cQ1, .src-modules-pickupLine-components-DaysColumn-___styles__daysCol___2ZJhc {\n  margin: 0 !important;\n  border-bottom: 1px solid #d6d6d6;\n  padding: 0.4rem 0.4rem;\n  height: 50px;\n  align-items: center; }\n  .src-modules-pickupLine-components-DaysColumn-___styles__col___24cQ1 .src-modules-pickupLine-components-DaysColumn-___styles__content___3S1CL, .src-modules-pickupLine-components-DaysColumn-___styles__daysCol___2ZJhc .src-modules-pickupLine-components-DaysColumn-___styles__content___3S1CL {\n    margin-left: auto;\n    margin-right: auto;\n    text-align: center; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__daysCol___2ZJhc {\n  width: 100px;\n  display: flex;\n  padding: 0; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__header___3KgWb, .src-modules-pickupLine-components-DaysColumn-___styles__footerPadding___8RzzY {\n  font-weight: 500;\n  color: #636363;\n  font-size: .9rem;\n  display: flex; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__freeDay___3ea6a {\n  background-color: #e9ecef; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__publishedDay___176AE {\n  background-color: #dbefdc; }\n\n.src-modules-pickupLine-components-DaysColumn-___styles__footerPadding___8RzzY {\n  height: 130px; }\n", ""]);

// exports
exports.locals = {
	"daysColumn": "src-modules-pickupLine-components-DaysColumn-___styles__daysColumn___2_wjq",
	"col": "src-modules-pickupLine-components-DaysColumn-___styles__col___24cQ1",
	"daysCol": "src-modules-pickupLine-components-DaysColumn-___styles__daysCol___2ZJhc",
	"content": "src-modules-pickupLine-components-DaysColumn-___styles__content___3S1CL",
	"header": "src-modules-pickupLine-components-DaysColumn-___styles__header___3KgWb",
	"footerPadding": "src-modules-pickupLine-components-DaysColumn-___styles__footerPadding___8RzzY",
	"freeDay": "src-modules-pickupLine-components-DaysColumn-___styles__freeDay___3ea6a",
	"publishedDay": "src-modules-pickupLine-components-DaysColumn-___styles__publishedDay___176AE"
};