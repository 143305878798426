import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {downloadFileFromResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ExportTranscriptAction} from '../actions';
import Api from '../api';

function* execute({payload: {params}}: ExportTranscriptAction): SagaIterator {
    const response = yield* call(Api.exportTranscripts, params);

    yield* response.isSuccess
        ? call(downloadFileFromResponse, response)
        : call(handleResponseError, response, 'Stažení opisů');
}

export function* exportTranscriptsSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_TRANSCRIPTS', execute);
}
