import {Opt, opt} from 'ts-opt';

import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {OrderDetailsForLaborer} from 'types/model/orders/OrderDetailsForLaborer';
import {SampleDetails} from 'types/model/samples/SampleDetails';

export const isOrderDetailsForLaborer = (order: OrderDetails): order is OrderDetailsForLaborer =>
    order.tag === 'OrderDetailsForLaborer';

export const getOrderCustomer = (
    currentCustomer: Opt<CustomerDetails>,
    customerForOrder: Opt<CustomerDetails>,
    isAdminOrLaborer: boolean,
): Opt<CustomerDetails> =>
    isAdminOrLaborer ? customerForOrder : currentCustomer;

export const getOrderCustomerId = (order: Opt<OrderDetails>): number | null =>
    order.narrow(isOrderDetailsForLaborer)
        .map(x => x.customerDetails.id)
        .orNull();

export const getPriceClassForCustomer = (customer: Opt<CustomerDetails>): PriceClass | undefined =>
    customer.map(c => c.priceClass).orUndef();

export const getPriceClassForOrder = (order: OrderDetails): PriceClass | undefined =>
    opt(order).narrow(isOrderDetailsForLaborer)
        .map(x => x.priceClass)
        .orUndef();

const enableShowResults = (priceClass: PriceClass | undefined): boolean => priceClass === 'CONTRACTUAL';

export const enableShowResultsByCustomer = (customer: Opt<CustomerDetails>): boolean =>
    enableShowResults(getPriceClassForCustomer(customer));

export const enableShowResultsByOrder = (order: OrderDetails): boolean =>
    enableShowResults(getPriceClassForOrder(order));

export const enableShowResultsBySample = (sample: SampleDetails | null): boolean =>
    enableShowResults(sample?.priceClass);

const enableQCZ = (priceClass: PriceClass | undefined): boolean => priceClass === 'NON_CONTRACTUAL';

export const enableQCZByCustomer = (customer: Opt<CustomerDetails>): boolean =>
    enableQCZ(getPriceClassForCustomer(customer));

export const enableQCZByOrder = (order: OrderDetails): boolean =>
    enableQCZ(getPriceClassForOrder(order));

export const enableQCZBySample = (sample: SampleDetails | null): boolean =>
    enableQCZ(sample?.priceClass);

const canLaborerGenerateProtocol = (order: OrderDetailsForLaborer): boolean =>
    order.state !== 'COMPLETED' &&
    order.state !== 'CANCELED' &&
    order.hasMeasurements;

export const canGenerateProtocol = (order: OrderDetails | null): boolean =>
    opt(order).narrow(isOrderDetailsForLaborer)
        .map(canLaborerGenerateProtocol)
        .orFalse()
;

