import React, {PureComponent, ReactNode} from 'react';
import {Field} from 'redux-form';

import {withGenericFormFieldElements} from 'forms/components/Field/withGenericFormFieldElements';
import DateTime, {DateTimeInnerProps, DateTimeOuterProps} from 'forms/components/DateTime';

class DateTimeField extends PureComponent<DateTimeInnerProps> {
    render(): ReactNode {
        return withGenericFormFieldElements(DateTime, this.props);
    }
}

class DateTimeFieldOuter extends PureComponent<DateTimeOuterProps> {
    render(): ReactNode {
        const {
            name,
            label,
            className,
            disabled,
            helpText,
            onAction,
            tooltip,
            wrapperClassName,
            minDate,
            maxDate,
            placeholderText,
            mode,
            includeFuture,
            onFieldBlur,
            onFieldChange,
            fieldIsLoading,
            noPadding,
        } = this.props;

        return <Field
            component={DateTimeField}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholderText}
            mode={mode}
            includeFuture={includeFuture}
            name={name}
            label={label}
            className={className}
            disabled={disabled}
            helpText={helpText}
            onAction={onAction}
            tooltip={tooltip}
            wrapperClassName={wrapperClassName}
            onFieldChange={onFieldChange}
            onFieldBlur={onFieldBlur}
            fieldIsLoading={fieldIsLoading}
            noPadding={noPadding}
        />;
    }
}

export default DateTimeFieldOuter;
