import React, {Component, Fragment, ReactNode} from 'react';
import {Form} from 'redux-form';
import {opt, Opt} from 'ts-opt';
import {isArray} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {Address} from 'types/model/common/Address';
import {SelectedValue} from 'forms/components/BasicSelect';

import {UpdateCustomerFormValues} from '../../types/UpdateCustomerFormValues';
import {priceClassOptions} from '../../types/PriceClassOptions';

import {asyncValidate, validate} from './validations';

const showResultsOnWebTooltip = 'Výsledky rozborů budou automaticky odeslány do aplikace https://data.cmsch.cz';

export const createEmptyAddress = (): Address => ({street: '', city: '', zip: ''});

export interface OwnProps {
    hasSameBillingAddress?: boolean;
    isAdminOrLaborer: boolean;
    isDairy: boolean;
    isAresLoading: boolean;
    hasNoIco: boolean;
    priceClass?: PriceClass;
    formValues?: UpdateCustomerFormValues;

    onIcoSearch(): void;
}

type Props = PropsForForm<UpdateCustomerFormValues, OwnProps>;

class UpdateForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            hasSameBillingAddress,
            isAdminOrLaborer,
            hasNoIco,
            onIcoSearch,
            autofill,
            autofillUnsafe,
            isDairy,
            isAresLoading,
            priceClass,
            formValues,
        } = this.props;
        const onHasNoIcoChange = () => {
            autofillUnsafe('invoiceDetails.ico', '');
            autofillUnsafe('invoiceDetails.dic', '');
        };
        const onHasSameBillingAddressChange = () => {
            autofill('billingAddress', createEmptyAddress());
        };
        const isContractual = priceClass === 'CONTRACTUAL';
        const onPriceClassChanged = (value: Opt<SelectedValue<PriceClass>>) => {
            const priceClass = value.orNull();

            if (isArray(priceClass)) { return; }

            if (priceClass !== 'CONTRACTUAL') {
                autofill('code', undefined);
                autofill('showResultsOnWeb', false);
                autofill('showResultsToBreeders', false);
            }
        };

        const onShowResultsOnWebChanged = (value: Opt<boolean>) => {
            if (!value.orFalse()) {
                autofill('showResultsToBreeders', false);
            }
        };

        const displayShowResults = isDairy || isAdminOrLaborer && isContractual;
        const resultsOnWebChecked = opt(formValues).chainToOpt(v => v.showResultsOnWeb).orFalse();

        return (
            <Layout.Panel>
                <Form onSubmit={handleSubmit}>
                    <Layout.Loader show={submitting} />
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Základní údaje</strong>
                        </div>
                    </div>

                    {isAdminOrLaborer && isContractual && <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="code"
                                type="text"
                                label="Kód"
                            />
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="firstName"
                                type="text"
                                label="Křestní jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="lastName"
                                type="text"
                                label="Příjmení*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.email"
                                type="text"
                                label="E-mail*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.phone"
                                type="text"
                                label="Telefonní číslo"
                            />
                        </div>
                    </div>

                    {isAdminOrLaborer && <div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Select<PriceClass>
                                    className="mt-2"
                                    name="priceClass"
                                    label="Cenová třída"
                                    options={priceClassOptions}
                                    onFieldChange={onPriceClassChanged}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="note"
                                    type="text"
                                    label="Poznámka"
                                />
                            </div>
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.ico"
                                type="text"
                                label="IČO"
                                onAction={onIcoSearch}
                                onFieldBlur={onIcoSearch}
                                disabled={hasNoIco || isAresLoading}
                                fieldIsLoading={isAresLoading}
                            />
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasNoIco"
                                onFieldChange={onHasNoIcoChange}
                                label="Nemám ičo"
                                disabled={isAresLoading}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.dic"
                                type="text"
                                label="DIČ"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="name"
                                type="text"
                                label="Objednatel*"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Adresa</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.street"
                                type="text"
                                label="Ulice*"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.city"
                                type="text"
                                label="Město*"
                                disabled={!hasNoIco}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.zip"
                                type="text"
                                label="Směrovací číslo*"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasSameBillingAddress"
                                label="Fakturační adresa je stejná"
                                onFieldChange={onHasSameBillingAddressChange}
                            />
                        </div>
                    </div>

                    {!hasSameBillingAddress && <Fragment>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <strong className="mt-3 d-block">Fakturační adresa</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="billingAddress.street"
                                    type="text"
                                    label="Ulice*"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="billingAddress.city"
                                    type="text"
                                    label="Město*"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="billingAddress.zip"
                                    type="text"
                                    label="Směrovací číslo*"
                                />
                            </div>
                        </div>
                    </Fragment>}

                    {displayShowResults && <Fragment>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <strong className="mt-3 d-block">Zasílání výsledků</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Checkbox
                                    className="mt-2"
                                    name="showResultsOnWeb"
                                    label="Zasílat výsledky na web*"
                                    tooltip={showResultsOnWebTooltip}
                                    onFieldChange={onShowResultsOnWebChanged}
                                />
                                <Fields.Checkbox
                                    className="mt-2"
                                    name="showResultsToBreeders"
                                    label="Zasílat výsledky chovatelům"
                                    disabled={!resultsOnWebChecked}
                                    tooltip={!resultsOnWebChecked
                                        ? 'Není možné odeslat výsledky chovateli, pokud nejsou zaslány na web'
                                        : undefined
                                    }
                                />
                            </div>
                        </div>
                    </Fragment>}

                    <div className="row">
                        <div className="col-12 mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || isAresLoading || submitting}
                                type="submit"
                            >
                                 Uložit
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateCustomerFormValues, OwnProps, Props>(UpdateForm, {
    form: 'updateCustomer',
    validate,
    asyncValidate,
    asyncBlurFields: [
        'code',
        'invoiceDetails.email',
        'invoiceDetails.ico',
        'invoiceDetails.dic',
    ],
    initialValues: {
        hasSameBillingAddress: false,
        lastName: '',
        firstName: '',
        address: createEmptyAddress(),
        billingAddress: createEmptyAddress(),
        invoiceDetails: {email: '', tag: 'CustomerInvoiceDetails'},
        showResultsOnWeb: false,
        showResultsToBreeders: false,
        name: '',
        hasNoIco: false,
    },
});
