import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {showSuccess, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {CreateMilkRoomAction, createMilkRoomError, createMilkRoomSuccess} from '../actions';
import Api from '../api';
import {formValuesF} from 'utils/formHelpers';

function* execute({payload: {supplierId}}: CreateMilkRoomAction): SagaIterator {
    const title = 'Nová mléčnice';
    const values = (yield* select(formValuesF('createMilkRoom')))
        .orCrash('missing form values');
    const response = yield* call(Api.createMilkRoom, supplierId, values);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        return yield* put(createMilkRoomError(error));
    }

    yield* put(createMilkRoomSuccess());
    yield* put(showSuccess(title, 'Úspěšně vytvořena.'));
    yield* put(routerActions.goBack());
}

export function* createMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/CREATE_MILK_ROOM', execute);
}
