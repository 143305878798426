import * as t from 'io-ts';
import {CustomerDetailsForCustomerSchema} from './CustomerDetailsForCustomer';
import {CustomerDetailsForLaborerSchema} from './CustomerDetailsForLaborer';

export const CustomerDetailsSchema = t.taggedUnion('tag', [
    CustomerDetailsForLaborerSchema,
    CustomerDetailsForCustomerSchema,
]);

export type CustomerDetails = t.TypeOf<typeof CustomerDetailsSchema>;
