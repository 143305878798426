import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import React, {ReactNode} from 'react';
import {opt} from 'ts-opt';

import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import BasicSelect, {LoadOptionsCallback, SelectedValue, SelectOptions, RefSelect} from '../BasicSelect';

type FieldValue<OptionT> = SelectedValue<OptionT>;

type OwnProps<OptionT> = {
    async?: boolean,
    debounceTime?: number,
    asyncMinLength?: number,
    options: Readonly<SelectOptions<OptionT>>,
    multi?: boolean,
    autoFocus?: boolean,
    selectRef?: RefSelect<OptionT>,
    clearable?: boolean,
    noMargin?: boolean,

    loadOptions?(filter: string, cb: LoadOptionsCallback<OptionT>): void,
    onEnter?(): void,
}

export type SelectOuterProps<OptionT> = OwnProps<OptionT> & OuterFormFieldProps<FieldValue<OptionT>>;

export type SelectInnerProps<OptionT> = OwnProps<OptionT> & InnerFormFieldProps<FieldValue<OptionT>> & ChildProps;

class Select<OptionT> extends ComponentForWrapping<SelectInnerProps<OptionT>, FieldValue<OptionT>> {
    render(): ReactNode {
        const {
            className,
            label,
            helpText,
            input,
            options,
            meta,
            disabled,
            multi,
            debounceTime,
            asyncMinLength,
            loadOptions,
            async,
            autoFocus,
            onEnter,
            selectRef,
            clearable,
            noMargin,
        } = this.props;
        const {asyncValidating, submitting} = meta;
        const {name, value} = input;

        const handleChange = (value: SelectedValue<OptionT>): void => {
            this.handleChange(opt(value));
        };

        const handleBlur = (value: SelectedValue<OptionT>) => {
            this.handleBlur(opt(value));
        };

        const handleFocus = (value: SelectedValue<OptionT>) => {
            this.handleFocus(opt(value));
        };

        return <BasicSelect<OptionT>
            name={name}
            className={className}
            helpText={helpText}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            options={options}
            label={label}
            disabled={asyncValidating || submitting || disabled}
            multi={multi}
            debounceTime={debounceTime}
            asyncMinLength={asyncMinLength}
            async={async}
            loadOptions={loadOptions}
            inForm
            autoFocus={autoFocus}
            onEnter={onEnter}
            selectRef={selectRef}
            clearable={clearable}
            noMargin={noMargin}
        >
        </BasicSelect>;
    }
}

export default Select;
