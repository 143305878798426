import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, Fragment, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {renderFormError} from 'forms/components/withForm';
import {UpdateMilkRoom} from 'types/model/milkRooms/UpdateMilkRoom';
import withUser from 'user/components/withUser';
import {formState, formValuesF, isValidF, isFieldValidUnsafe, isSubmitingF} from 'utils/formHelpers';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';

import {
    RemoveMilkRoomInSupplierCreationArguments,
    addMilkRoomInSupplierCreation,
    createSupplier,
    removeMilkRoomInSupplierCreation,
    setAddingMilkRoomInProgress,
    fillCreateSupplierFormFromAres,
    loadMilkRooms,
} from '../actions';
import CreateMilkRoomForm from '../components/CreateMilkRoomForm';
import CreateSupplierForm from '../components/CreateSupplierForm';
import MilkRoomsTable from '../components/MilkRoomsTable';
import {CreateSupplierFormValues} from '../types/CreateSupplierFormValues';

interface OuterProps {}

interface InnerProps {
    milkRooms: UpdateMilkRoom[];
    supplierFormValid: boolean;
    supplierFormSubmitting: boolean;
    milkRoomsError?: string;
    milkRoomsTouched?: boolean;
    addingMilkRoomInProgress: boolean;
    formValues: Opt<CreateSupplierFormValues>;
    isIcoValid: boolean;
    customerMilkRooms: MilkRoom[];

    handleCreateSupplier(): void;
    handleCreateMilkRoom(): void;
    handleRemoveMilkRoom(args: RemoveMilkRoomInSupplierCreationArguments): void;
    handleSetAddingMilkRoomInProgress(value: boolean): void;
    fillFormFromAres(ico: string): void;
    handleLoadMilkRooms(): void;
}

type Props = InnerProps & OuterProps;

class New extends Component<Props> {
    componentDidMount() {
        const {handleLoadMilkRooms} = this.props;

        handleLoadMilkRooms();
    }

    componentWillUnmount(): void {
        const {handleSetAddingMilkRoomInProgress} = this.props;

        handleSetAddingMilkRoomInProgress(false);
    }

    render(): ReactNode {
        const {
            handleCreateSupplier,
            milkRooms,
            handleCreateMilkRoom,
            supplierFormValid,
            handleRemoveMilkRoom,
            milkRoomsError,
            milkRoomsTouched,
            addingMilkRoomInProgress,
            handleSetAddingMilkRoomInProgress,
            formValues,
            fillFormFromAres,
            isIcoValid,
            supplierFormSubmitting,
            customerMilkRooms,
        } = this.props;

        const onMilkRoomDelete = (_: unknown, id: number) => {
            handleRemoveMilkRoom({id});
        };

        const handleStartAddingMilkRoom = () => {
            handleSetAddingMilkRoomInProgress(true);
        };

        const handleBackInMilkRoomCreation = () => {
            handleSetAddingMilkRoomInProgress(false);
        };

        const prefilled = formValues.map(x => x.prefilled).orFalse();
        const hasNoIco = formValues.map(x => x.hasNoIco).orFalse();
        const ico = formValues.map(x => x.invoiceDetails.ico).orUndef();
        const onIcoSearch = () => {
            if (ico && isIcoValid) {
                fillFormFromAres(ico);
            }
        };

        const newMilkRoomPart = <Fragment>
            <div
                style={{display: addingMilkRoomInProgress ? 'block' : 'none'}}
            >
                <h2>Nová mléčnice</h2>
                <CreateMilkRoomForm
                    onSubmit={handleCreateMilkRoom}
                    inSupplierCreation
                    onBack={handleBackInMilkRoomCreation}
                    existingMilkRooms={[...customerMilkRooms, ...milkRooms]}
                />
            </div>
        </Fragment>;

        const mainFormPart =
            <div
                style={{display: !addingMilkRoomInProgress ? 'block' : 'none'}}
            >
                <CreateSupplierForm
                    hasNoIco={hasNoIco}
                    filledFromAres={prefilled}
                    onIcoSearch={onIcoSearch}
                    isIcoValid={isIcoValid}
                />

                <div>
                    <h3 className="d-flex justify-content-between mt-4">
                        Seznam mléčnic*
                        <Buttons.RightIconButton
                            label="Přidat"
                            onClick={handleStartAddingMilkRoom}
                            icon="ADD"
                        />
                    </h3>
                    {!isEmpty(milkRooms) &&
                     <MilkRoomsTable
                         milkRooms={milkRooms}
                         mode="CREATE"
                         handleDelete={onMilkRoomDelete}
                     />
                    }
                    {isEmpty(milkRooms) && 'Žádné mléčnice nebyly vyplněny.'}
                    {milkRoomsError && milkRoomsTouched &&
                     <div className="mt-2">
                         {renderFormError(milkRoomsError)}
                     </div>
                    }
                </div>

                <div className="row mt-4">
                    <div className="col-12 mt-2">
                        <Buttons.Button
                            fullWidth
                            importance="primary"
                            disabled={!supplierFormValid || supplierFormSubmitting}
                            type="button"
                            onClick={handleCreateSupplier}
                        >
                            Vytvořit dodavatele
                        </Buttons.Button>
                    </div>
                </div>
            </div>;

        return (
            <Layout.ItemPage
                title="Nový dodavatel"
                backLabel="Dodavatelé"
            >
                {mainFormPart}
                {newMilkRoomPart}
            </Layout.ItemPage>

        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    milkRooms: formValuesF('createSupplier')(state).chainToOpt(x => x.milkRooms).orElse([]),
    supplierFormValid: isValidF('createSupplier')(state),
    supplierFormSubmitting: isSubmitingF('createSupplier')(state),
    milkRoomsError:
        formState('createSupplier')(state).chainToOpt(x => x.syncErrors).chainToOpt(x => x.milkRooms).orUndef(),
    milkRoomsTouched: formState('createSupplier')(state)
        .chainToOpt(x => x.fields).chainToOpt(x => x.milkRooms).chainToOpt(x => x.touched).orFalse(),
    addingMilkRoomInProgress: state.supplier.addingMilkRoomInProgress,
    formValues: formValuesF('createSupplier')(state),
    isIcoValid: isFieldValidUnsafe('createSupplier', 'invoiceDetails.ico')(state),
    customerMilkRooms: state.supplier.milkRooms || [],
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleCreateSupplier: () => dispatch(createSupplier()),
    handleCreateMilkRoom: () => dispatch(addMilkRoomInSupplierCreation()),
    handleRemoveMilkRoom: (args: RemoveMilkRoomInSupplierCreationArguments) => dispatch(showConfirmDialog({
        title: 'Smazat mléčnici',
        text: 'Opravdu chcete mléčnici smazat?',
        confirmAction: removeMilkRoomInSupplierCreation(args),
    })),
    handleSetAddingMilkRoomInProgress: (value: boolean) => dispatch(setAddingMilkRoomInProgress(value)),
    fillFormFromAres: (ico: string) => dispatch(fillCreateSupplierFormFromAres(ico)),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(New) as ComponentClass<OuterProps>;
