exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-measurement-components-DataItemMeasurementsForm-___styles__resultPanelLoading___1qeIn {\n  min-height: 150px; }\n", ""]);

// exports
exports.locals = {
	"resultPanelLoading": "src-modules-measurement-components-DataItemMeasurementsForm-___styles__resultPanelLoading___1qeIn"
};