import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {AnalysisAvailabilitySchema} from '../enumeration/AnalysisAvailability';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    name: t.string,
    abbr: t.string,
    index: IntegerSchema,
    price: IntegerSchema,
    availability: AnalysisAvailabilitySchema,
});

export const UpdateAnalysisSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateAnalysis extends t.TypeOf<typeof UpdateAnalysisSchema> {}
