import React, {ReactNode} from 'react';

import logger from 'app/model/Logger';

interface Props {
    props: ReactNode;
}

export class ErrorHandler extends React.Component<Props> {
    componentDidCatch(error: Error): void {
        logger.logError(error);
    }

    render(): ReactNode {
        const {children} = this.props;

        return children;
    }
}
