import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {ErrorsFromBE, ErrorResponse, SuccessResponse, takeLatestF} from 'favorlogic-utils';

import {Device} from 'types/model/devices/Device';
import {handleResponseError} from 'utils/handleResponseError';

import {
    LoadDeviceAction,
    loadDeviceError,
    loadDeviceSuccess,
    prefillDeviceForm,
} from '../actions';
import Api from '../api';
import {DeviceFormValues} from '../types/DeviceFormValues';

const title = 'Načtení přístroje';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadDeviceError(error));
}

export function prepareDeviceFormData(device: Device): DeviceFormValues {
    return {
        name: device.name,
        dataItems: device.dataItems.map(dataItem => ({
            id: dataItem.id,
            dataItemId: dataItem.dataItemId,
            minimum: opt(dataItem.minimum).map(x => x.toString()).orUndef(),
            maximum: opt(dataItem.maximum).map(x => x.toString()).orUndef(),
            action: dataItem.action,
        })),
    };
}

function* handleSuccessResponse({data: device}: SuccessResponse<Device>): SagaIterator {
    const formValues = prepareDeviceFormData(device);

    yield* put(prefillDeviceForm(formValues));
    yield* put(loadDeviceSuccess(device));
}

function* execute(action: LoadDeviceAction): SagaIterator {
    const response = yield* call(Api.getDevice, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadDeviceSaga(): SagaIterator {
    yield takeLatestF('device/LOAD_DEVICE', execute);
}
