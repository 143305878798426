import * as t from 'io-ts';

import {OrderDetailsForCustomerSchema} from './OrderDetailsForCustomer';
import {OrderDetailsForLaborerSchema} from './OrderDetailsForLaborer';

export const OrderDetailsSchema = t.taggedUnion('tag', [
    OrderDetailsForLaborerSchema,
    OrderDetailsForCustomerSchema,
]);

export type OrderDetails = t.TypeOf<typeof OrderDetailsSchema>;
