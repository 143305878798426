import {call, put, select} from 'typed-redux-saga';
import {SagaIterator} from 'redux-saga';
import {ErrorResponse, showError, SuccessResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {Ares} from 'types/model/ares/Ares';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';
import {Address} from 'types/model/common/Address';
import {StoreState} from 'app/types/StoreState';
import {SupplierInvoiceDetails} from 'types/model/common/SupplierInvoiceDetails';

import {prefillUpdateSupplierForm, FillUpdateSupplierFormFromAresAction, isAresLoading} from '../actions';
import {UpdateSupplierFormValues} from '../types/UpdateSupplierFormValues';
import Api from 'user/api';

const errorTitle = 'Načtení dodavatele z ARES';
const formName = 'updateSupplier';

export function getFilledFormValues(ares: Ares, currentValues: UpdateSupplierFormValues): UpdateSupplierFormValues {
    const address: Address = {
        street: ares.street || '',
        city: ares.city || '',
        zip: ares.zip || '',
    };
    const invoiceDetails: SupplierInvoiceDetails = {
        ...currentValues.invoiceDetails,
        dic: ares.dic || '',
    };

    return {
        ...currentValues,
        invoiceDetails,
        address,
        name: ares.companyName || '',
    };
}

function* getCurrentCreateSupplierFormValues(): SagaIterator<UpdateSupplierFormValues | undefined> {
    return yield* select((state: StoreState) => formValuesF(formName)(state).orUndef());
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const {status} = response;

    if (status !== 401) {
        yield* call(handleResponseError, response, errorTitle);
    }
}

function* handleSuccessResponse({data}: SuccessResponse<Ares | null>): SagaIterator {
    const formValues = yield* call(getCurrentCreateSupplierFormValues);

    if (!formValues) {
        return;
    }

    if (data) {
        yield* put(prefillUpdateSupplierForm(getFilledFormValues(data, formValues)));
    } else {
        yield* put(showError(errorTitle, 'Žádné výsledky nebyly nalezeny.'));
        yield* put(stopSubmitF(formName, {
            invoiceDetails: {
                ico: 'IČ nebylo nalezeno v ARES.',
            },
        }));
    }
}

function* execute(action: FillUpdateSupplierFormFromAresAction): SagaIterator {
    yield* put(isAresLoading(true));

    const response = yield* call(Api.getAres, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
    yield* put(isAresLoading(false));
}

export function* fillUpdateSupplierFormFromAresSaga(): SagaIterator {
    yield takeLatestF('supplier/FILL_UPDATE_SUPPLIER_FORM_FROM_ARES', execute);
}
