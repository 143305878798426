import React, {FC, Fragment, useState} from 'react';
import {Opt} from 'ts-opt';
import {validateKeyGen, classNames} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Fields} from 'forms';
import {Preservative} from 'types/model/enumeration/Preservative';

import {preservativeOptions} from '../../enums/PreservativeEnum';
import {UpdateSampleFormValues} from '../../types/UpdateSampleFormValues';
import {canSetSampleForSubsidy} from '../../utils/canSetSampleForSubsidy';

import styles from './styles.sass';

interface GloballySetButtonProps {
    hasGloballyValues?: boolean;

    setGlobally?(): void;
    cancelSetGlobally?(): void;
}

const GloballySetButton: FC<GloballySetButtonProps> = (
    {
        hasGloballyValues,
        cancelSetGlobally,
        setGlobally,
    }
) =>
    hasGloballyValues
        ? <Fragment>
            <span className="pr-3 text-secondary">Nastaveno pro všechny vzorky!</span>
            <Buttons.Button
                importance="primary"
                type="button"
                onClick={cancelSetGlobally}
            >
                Zrušit
            </Buttons.Button>
        </Fragment>
        : <Buttons.Button
            importance="primary"
            type="button"
            onClick={setGlobally}
        >
            Vyplnit pro všechny vzorky
        </Buttons.Button>
;

const validateKey = validateKeyGen<UpdateSampleFormValues>();

interface Props {
    hasGloballyValues?: boolean;
    showDairyTraits?: boolean;
    formValues?: UpdateSampleFormValues;
    allowQCZ: boolean;

    setGlobally?(): void;
    cancelSetGlobally?(): void;
    change<N extends keyof UpdateSampleFormValues>(field: N, value: UpdateSampleFormValues[N]): void;
}

export const AdditionalInfo: FC<Props> = props => {
    const {
        showDairyTraits,
        formValues,
        hasGloballyValues,
        cancelSetGlobally,
        setGlobally,
        change,
        allowQCZ,
    } = props;
    const [milkRoomId, setMilkRoomId] = useState<number | null>(null);
    const isCisternOrControl = Boolean(formValues?.cistern || formValues?.control);
    const canSetSubsidy = formValues && canSetSampleForSubsidy(formValues);

    const onCisternChange = (value: Opt<boolean>): void => {
        const isChecked = value.orFalse();

        if (isChecked) {
            setMilkRoomId(formValues?.milkRoomId || null);
            change('milkRoomId', null);
        } else {
            change('milkRoomId', milkRoomId);
        }

        if (!formValues?.control) {
            if (isChecked) {
                change('subsidy', false);
            } else {
                change('traitNote', null);
            }
        }
    };

    const onControlChange = (value: Opt<boolean>): void => {
        const isChecked = value.orFalse();

        if (!formValues?.cistern) {
            if (isChecked) {
                change('subsidy', false);
            } else {
                change('traitNote', null);
            }
        }
    };

    return (
        <div className={classNames('p-4 mt-4', styles.addInfo)}>
            <div className="row">
                <div className="col-md-6">
                    <strong>Doplňující údaje</strong>
                </div>
                <div className="col-md-6">
                    <div className="text-right">
                        <GloballySetButton
                            hasGloballyValues={hasGloballyValues}
                            setGlobally={setGlobally}
                            cancelSetGlobally={cancelSetGlobally}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="w-100">
                        <Fields.Select<Preservative>
                            name={validateKey('preservatives')}
                            label="Konzervační činidla"
                            options={preservativeOptions}
                            multi
                        />
                    </div>
                    <div className="w-100">
                        <Fields.Input
                            name={validateKey('deliveryTemperature')}
                            type="text"
                            label="Teplota při příjmu"
                        />
                    </div>
                    <div className="w-100">
                        <Fields.Textarea
                            name={validateKey('note')}
                            label="Poznámka"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="pb-1 pt-2">Příznaky</div>
                    {showDairyTraits !== false && <Fragment>
                        <div>
                            <Fields.Checkbox
                                name={validateKey('qualitative')}
                                label="(P) Platný"
                            />
                        </div>
                        <div>
                            <Fields.Checkbox
                                name={validateKey('cistern')}
                                label="(C) Cisterna"
                                onFieldChange={onCisternChange}
                            />
                        </div>
                        <div>
                            <Fields.Checkbox
                                name={validateKey('control')}
                                label="(K) Kontrolní"
                                onFieldChange={onControlChange}
                            />
                        </div>
                    </Fragment>}
                    <div>
                        <Fields.Checkbox
                            name={validateKey('disabled')}
                            label="(N) Nepoužitelný"
                        />
                    </div>
                    <div>
                        <Fields.Checkbox
                            name={validateKey('canceled')}
                            label="(Z) Zrušený"
                        />
                    </div>
                    {allowQCZ && <div>
                        <Fields.Checkbox
                            name={validateKey('subsidy')}
                            label="Q CZ"
                            disabled={!canSetSubsidy}
                        />
                    </div>}
                    {showDairyTraits !== false && isCisternOrControl &&
                        <div className="pt-2">
                            <Fields.Input
                                name={validateKey('traitNote')}
                                type="text"
                                label="Poznámka k příznakům"
                                tooltip={`Poznámka k příznakům "Cisterna" / "Kontrolní"`}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
