import React, {PureComponent, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';

interface Props {
    id: number;
    onSelect(id: number): void;
}

class RowActions extends PureComponent<Props> {
    render(): ReactNode {
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    title="Přijmout vzorek"
                    onClick={this.handleSelect}
                />
            </div>
        );
    }

    private readonly handleSelect = () => {
        const {id, onSelect} = this.props;
        onSelect(id);
    }
}

export default RowActions;
