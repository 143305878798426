import React, {Fragment, FunctionComponent, useState, memo} from 'react';
import ReactDatePicker from 'react-datepicker';
import {opt} from 'ts-opt';
import {
    classNames,
    formatDateObject,
    createDateObject,
    DateTimeFormat,
} from 'favorlogic-utils';
import cs from 'date-fns/locale/cs';

import HelpText from '../HelpText';

import 'react-datepicker/dist/react-datepicker.css';
import inputStyles from '../Input/styles.sass';
import styles from './styles.sass';

const datePickerDateFormat = 'd. M. yyyy';
const datePickerDatetimeFormat = 'd. M. yyyy H:mm';
const datePickerTimeFormat = 'H:mm';
const datePickerYearMonthFormat = 'M. yyyy';

interface Props {
    name?: string;
    mode: DateTimeMode;
    className?: string;
    minDate?: Date;
    maxDate?: Date;
    value?: string;
    label?: string;
    includeFuture?: boolean;
    helpText?: string;
    disabled?: boolean;
    placeholder?: string;
    inForm?: boolean;
    clearable?: boolean;
    adjustDateOnChange?: boolean;
    noPadding?: boolean;

    onChange?(newValue: string | null): void;
    onBlur?(newValue: string | null): void;
    onFocus?(newValue: string | null): void;
}

export type DateTimeMode = 'DATE' | 'TIME' | 'DATETIME' | 'YEARMONTH';

const formatMap: Record<DateTimeMode, DateTimeFormat> = {
    DATE: 'date',
    TIME: 'time',
    DATETIME: 'datetime',
    YEARMONTH: 'yearmonth',
};

const getDateFormat = (mode: DateTimeMode): string | undefined => {
    if (mode === 'YEARMONTH') {
        return datePickerYearMonthFormat;
    } else if (mode === 'DATETIME') {
        return datePickerDatetimeFormat;
    } else if (mode === 'TIME') {
        return datePickerTimeFormat;
    } else {
        return datePickerDateFormat;
    }
};

const BasicDateTime: FunctionComponent<Props> = props => {
    const {
        name,
        value,
        className,
        mode,
        onChange,
        onBlur,
        onFocus,
        label,
        helpText,
        minDate,
        maxDate,
        disabled,
        includeFuture,
        placeholder,
        inForm,
        clearable,
        adjustDateOnChange,
        noPadding,
    } = props;

    const [focused, setFocused] = useState<boolean>();
    const nonEmptyClass = value || focused ? `${inputStyles['non-empty']}` : '';
    const inFormClass = inForm ? `w-100 form-group ${styles.inForm}` : '';
    const classes = classNames(className, nonEmptyClass, inFormClass, noPadding && 'p-0');
    const inputClases = classNames('form-control w-100', inForm && 'form-custom');

    const handleChange = (date: Date, _event: React.SyntheticEvent | undefined): void => {
        onChange?.(formatDateObject(formatMap[mode])(date).orNull());
    };

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>): void => {
        // workaround blur event being sent BEFORE change is made
        setTimeout(() => {
            const {value} = props;

            onBlur?.(opt(value).orNull());
            setFocused(false);
        });
    };

    const handleFocus = (_event: React.FocusEvent<HTMLInputElement>): void => {
        const {value} = props;

        onFocus?.(opt(value).orNull());
        setFocused(true);
    };

    const handleFilterDate = (date: Date): boolean =>
        !includeFuture ? new Date() >= date : true;

    return (
        <div className={classes}>
            <HelpText>
                {helpText}
            </HelpText>
            <ReactDatePicker
                id={name}
                showTimeSelect={mode === 'DATETIME' || mode === 'TIME'}
                showTimeSelectOnly={mode === 'TIME'}
                showMonthYearPicker={mode === 'YEARMONTH'}
                className={inputClases}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                minDate={minDate}
                maxDate={maxDate}
                timeFormat={datePickerTimeFormat}
                dateFormat={getDateFormat(mode)}
                selected={createDateObject(formatMap[mode])(value).orUndef()}
                timeIntervals={15}
                title={label}
                adjustDateOnChange={adjustDateOnChange ?? true}
                filterDate={handleFilterDate}
                autoComplete="off"
                placeholderText={placeholder}
                isClearable={clearable}
                strictParsing
                locale={cs}
            />
            {inForm &&
                <Fragment>
                    <div className={inputStyles['animated-underline']} />
                    <div className={inputStyles['form-placeholder']}>{label}</div>
                </Fragment>
            }
        </div>
    );
};

export default memo(BasicDateTime);
