exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-app-components-PageContent-___styles__contentWrapper___3yVtT {\n  height: calc(100% - 70px);\n  overflow: visible; }\n\n.src-modules-app-components-PageContent-___styles__loginWrapper___2PSKX {\n  height: calc(100% - 10rem); }\n", ""]);

// exports
exports.locals = {
	"contentWrapper": "src-modules-app-components-PageContent-___styles__contentWrapper___3yVtT",
	"loginWrapper": "src-modules-app-components-PageContent-___styles__loginWrapper___2PSKX"
};