import {AdministrationAction} from './actions';

export interface AdministrationState {
    generateCustomersQCzProtocolInProgress: boolean;
    massGenerateProtocolsInProgress: boolean;
    generatePreviewProtocolInProgress: boolean;
}

export const initialAdministrationState: AdministrationState = {
    generateCustomersQCzProtocolInProgress: false,
    massGenerateProtocolsInProgress: false,
    generatePreviewProtocolInProgress: false,
};

const reducer = (
    state: AdministrationState | undefined = initialAdministrationState,
    action: AdministrationAction
): AdministrationState => {
    switch (action.type) {
        case 'administration/GENERATE_CUSTOMERS_Q_CZ_PROTOCOL': {
            return {...state, generateCustomersQCzProtocolInProgress: true};
        }

        case 'administration/MASS_GENERATE_PROTOCOLS': {
            return {...state, massGenerateProtocolsInProgress: true};
        }

        case 'administration/MASS_GENERATE_PROTOCOLS_FINISHED': {
            return {...state, massGenerateProtocolsInProgress: false};
        }

        case 'administration/GENERATE_PREVIEW_PROTOCOL': {
            return {...state, generatePreviewProtocolInProgress: true};
        }

        case 'administration/GENERATE_PREVIEW_PROTOCOL_FINISHED': {
            return {...state, generatePreviewProtocolInProgress: false};
        }

        default:
            return state;
    }
};

export default reducer;
