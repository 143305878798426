import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {
    convertStringToFloat,
    extractFormErrorsFromResponse,
    showSuccess,
    emptyStringToUndefined,
    ErrorsFromBE,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {UpdateMeasurement} from 'types/model/measurements/UpdateMeasurement';
import {handleResponseError} from 'utils/handleResponseError';
import {UpdateMeasurementFormValues} from 'measurement/type/UpdateMeasurementFormValues';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {
    UpdateMeasurementAction,
    updateMeasurementError,
    updateMeasurementSuccess,
} from '../actions';
import Api from '../api';

const title = 'Upravení měření';
const updateMeasurementForm = 'updateMeasurement';

export function prepareUpdateMeasurementData(formValues: UpdateMeasurementFormValues): UpdateMeasurement {
    return {
        dilution: opt(formValues.dilution).chain(convertStringToFloat).orCrash('invalid dilution'),
        result: opt(formValues.result).chain(convertStringToFloat).orCrash('invalid result'),
        measured: opt(formValues.measured).chainToOpt(emptyStringToUndefined).orCrash('missing measured date'),
        procedureId: opt(formValues.procedureId).orCrash('missing procedure'),
        reason: opt(formValues.reason).orUndef(),
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateMeasurementError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF(updateMeasurementForm, extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně upraveno.'));
    yield* put(updateMeasurementSuccess());
}

function* execute(action: UpdateMeasurementAction): SagaIterator {
    const formValues = (yield* select(formValuesF(updateMeasurementForm))).orCrash('Missing form values.');
    const response = yield* call(Api.updateMeasurement, action.payload, prepareUpdateMeasurementData(formValues));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/UPDATE_MEASUREMENT', execute);
}
