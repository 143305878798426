import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {resetSampleIdForDisable} from 'order/actions';
import {handleResponseError} from 'utils/handleResponseError';
import {resetF} from 'utils/formHelpers';
import {setModalVisibility} from 'layout/actions';

import Api from '../api';
import {DisableSampleAction, loadSamplesByOrder, resetSamplesByOrder} from '../actions';
import {DisableSampleParams} from '../types/DisableSampleParams';

const title = 'Označení vzorku jako nepoužitelného';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

function* handleSuccess({orderId, orderSamplesParams}: DisableSampleParams): SagaIterator {
    yield* put(showSuccess(title, 'Vzorek byl úspěšně označen jako nepoužitelný'));
    yield* put(resetSampleIdForDisable());
    yield* put(setModalVisibility('sampleDisable', false));
    yield* put(resetF('disableSampleForm'));

    if (orderId) {
        yield* put(resetSamplesByOrder());
        yield* put(loadSamplesByOrder(orderId, orderSamplesParams));
    }
}

function* execute(action: DisableSampleAction): SagaIterator {
    const {sampleId, note} = action.payload;
    const response = yield* call(Api.disableSample, sampleId, note);

    yield* response.isSuccess
        ? call(handleSuccess, action.payload)
        : call(handleErrorResponse, response);
}

export function* disableSampleSaga(): SagaIterator {
    yield takeLatestF('sample/DISABLE_SAMPLE', execute);
}
