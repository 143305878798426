import React, {Component, ReactNode, ComponentType} from 'react';
import {RouteComponentProps} from 'react-router';
import {omit, omitBy, isEmpty} from 'lodash/fp';
import {mapStateToSearch, toArray, parseSearch, ParsedQuery} from 'favorlogic-utils';

import {Filter} from '../../types/Filter';
import {FilterDefinition} from '../../types/FilterDefinition';

export interface FilterProps {
    filter: Filter;
    filterParams: object;

    handleFilterChange(accessor: string, type: string, values: string[]): void;
    handleFilterClear(): void;
    handleFilterSubmit(): void;
}

type Props = RouteComponentProps;

interface State {
    filter: Filter;
}

export const buildFilter = <T extends string>(
    query: ParsedQuery,
    definedFilters: FilterDefinition<T>[],
    defaultValues?: Filter
): Filter => {
    const filter: Filter = {};

    definedFilters.forEach(({key, type}) => {
        if (key in query) {
            filter[key] = {type, values: toArray(query[key])};
        }
    });

    if (!defaultValues) {
        return filter;
    }

    for (const key in defaultValues) {
        filter[key] = defaultValues[key];
    }

    return filter;
};

const withFilter = <T extends string>(definedFilters: FilterDefinition<T>[], defaultValues?: Filter) =>
    (WrappedComponent: ComponentType<FilterProps>): ReactNode => {
        class WithFilter extends Component<Props, State> {
            constructor(props: Props) {
                super(props);

                const query = this.getParsedQuery();

                this.state = {
                    filter: buildFilter(query, definedFilters, defaultValues),
                };

                if (!isEmpty(defaultValues)) {
                    this.replaceQuery(this.applyFilterToQuery(query));
                }
            }

            private getParsedQuery(): ParsedQuery {
                const {history: {location}} = this.props;

                return parseSearch(location.search);
            }

            private setQuery(query: ParsedQuery): void {
                const {history} = this.props;

                history.push(`?${mapStateToSearch(query)}`);
            }

            private replaceQuery(query: ParsedQuery): void {
                const {history} = this.props;

                history.replace(`?${mapStateToSearch(query)}`);
            }

            private applyFilterToQuery(query: ParsedQuery): ParsedQuery {
                const {filter} = this.state;
                const nextQuery = {...query};

                const setNextValues = (filterType: string) => {
                    const nextValues = filter[filterType].values;
                    if (nextValues && nextValues.length === 1) {
                        nextQuery[filterType] = nextValues.toString();
                    } else {
                        nextQuery[filterType] = nextValues;
                    }
                };

                Object.keys(filter).forEach(setNextValues);

                return nextQuery;
            }

            private removeFilters(query: ParsedQuery): ParsedQuery {
                const {filter} = this.state;

                return omit(Object.keys(filter), query);
            }

            private removeEmptyFilters(filter: Filter): Filter {
                return omitBy((filterPart) => isEmpty(filterPart.values), filter);
            }

            private getFilterParams(): ParsedQuery {
                return this.applyFilterToQuery({});
            }

            private handleFilterChange = (accessor: string, type: string, values: string[]): void => {
                const {filter} = this.state;
                const nextFilter = {...filter, [accessor]: {type, values}};

                this.setState({...this.state, filter: nextFilter});
            }

            private handleFilterClear = (): void => {
                const nextQuery = this.removeFilters(this.getParsedQuery());
                const {page} =  nextQuery;

                this.setState({...this.state, filter: {}});
                this.setQuery({...nextQuery, ...page && {page: '0'}});
            }

            private handleFilterSubmit = (): void => {
                const {filter} = this.state;
                const nextFilter = this.removeEmptyFilters(filter);
                const nextQuery = this.applyFilterToQuery(this.getParsedQuery());
                const {page} =  nextQuery;

                this.setState({...this.state, filter: nextFilter});
                this.setQuery({...nextQuery, ...page && {page: '0'}});
            }

            render(): ReactNode {
                const {filter} = this.state;

                return (
                    <WrappedComponent
                        handleFilterChange={this.handleFilterChange}
                        handleFilterClear={this.handleFilterClear}
                        handleFilterSubmit={this.handleFilterSubmit}
                        filter={filter}
                        filterParams={this.getFilterParams()}
                        {...this.props} // eslint-disable-line react/jsx-props-no-spreading
                    />
                );
            }
        }

        return WithFilter;
    }
;

export default withFilter;
