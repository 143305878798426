import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {MeasurementImportSchema} from 'types/model/measurements/MeasurementImport';

const optionalPart = t.partial({
});

const requiredPart = t.interface({
    measured: DateStringSchema,
    measuredById: IntegerSchema,
    measuredUsingId: IntegerSchema,
    measurements: t.array(MeasurementImportSchema),
});

export const MeasurementsImportSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementsImport extends t.TypeOf<typeof MeasurementsImportSchema> {}
