import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/login"
        component={Screens.Login}
        key="login"
    />,
    <Route
        exact
        path="/logout"
        component={Screens.Logout}
        key="logout"
    />,
    <Route
        exact
        path="/register"
        component={Screens.Register}
        key="register"
    />,
    <Route
        exact
        path="/reset-password-email"
        component={Screens.ResetPasswordEmail}
        key="reset-password-email"
    />,
    <Route
        exact
        path="/account/password-reset/:uuid"
        component={Screens.ResetPassword}
        key="reset-password"
    />,
    <Route
        exact
        path="/update-account"
        component={Screens.Update}
        key="update"
    />,
    <Route
        exact
        path="/customers"
        component={Screens.CustomersList}
        key="customers-list"
    />,
    <Route
        exact
        path="/customers/:id/edit"
        component={Screens.EditCustomer}
        key="customer-edit"
    />,
    <Route
        exact
        path="/laborers"
        component={Screens.LaborersList}
        key="laborers-list"
    />,
    <Route
        exact
        path="/users"
        component={Screens.UsersList}
        key="users-list"
    />,
    <Route
        exact
        path="/users/new"
        component={Screens.NewUser}
        key="new-user"
    />,
    <Route
        exact
        path="/users/:userId"
        component={Screens.UpdateUser}
        key="update-user"
    />,
];

export default routes;
