import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {AFormValues} from 'test/components/AForm/AFormValues';
import withUser from 'user/components/withUser';
import {formValuesF} from 'utils/formHelpers';
import AForm from '../components/AForm';

interface OuterProps {}

interface InnerProps {
    formValue?: AFormValues;
}

type Props = InnerProps & OuterProps & RouteComponentProps<{}>;

class TestForms extends Component<Props> {
    render(): ReactNode {
        const {formValue} = this.props;

        return (
            <div className="container d-flex mt-2 pt-1 flex-column">
                <h1>Test forms</h1>
                <div className="row">
                    <div className="col-6">
                        <AForm onSubmit={x => window.alert(`onSubmit: ${JSON.stringify(x, null, 2)}`)} />
                    </div>
                    <div className="col-6 mt-1">
                        <pre>Form values:
                            {JSON.stringify(formValue, null, 2)}
                        </pre>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    formValue: formValuesF('testAForm')(state).orUndef(),
});

const mapDispatchToProps = (_dispatch: Dispatch): Partial<Props> => ({});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(TestForms) as ComponentClass<OuterProps>;
