import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {OperatingProcedureFormValues} from './types/OperatingProcedureFormValues';

type OperatingProcedureFormState = GenericFormState<OperatingProcedureFormValues>;

const formReducer: CFormReducer = {
    operatingProcedure: (state: OperatingProcedureFormState, action: Action): OperatingProcedureFormState => {
        switch (action.type) {
            case 'operatingProcedure/PREFILL_OPERATING_PROCEDURE_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
