exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-buttons-components-IconButton-___styles__iconButton___2lVZV {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  border: none;\n  padding: 0.3571rem;\n  background: transparent;\n  outline: none;\n  -webkit-appearance: none;\n          appearance: none; }\n\n.src-modules-buttons-components-IconButton-___styles__iconButton___2lVZV:focus {\n  outline: none; }\n", ""]);

// exports
exports.locals = {
	"iconButton": "src-modules-buttons-components-IconButton-___styles__iconButton___2lVZV"
};