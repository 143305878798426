import * as t from 'io-ts';

import {UpdateSampleByCustomerSchema} from './UpdateSampleByCustomer';
import {UpdateSampleByLaborerSchema} from './UpdateSampleByLaborer';

export const UpdateSampleSchema = t.taggedUnion('tag', [
    UpdateSampleByLaborerSchema,
    UpdateSampleByCustomerSchema,
]);

export type UpdateSample = t.TypeOf<typeof UpdateSampleSchema>;
