type Environment = 'development' | 'production' | 'test' | 'staging';
declare const __API__: string;
declare const __ENVIRONMENT__: Environment;
interface FormsConfig {
    passwordMinLen: number;
    usernameMinLen: number;
    icoMinLen: number;
    icoMaxLen: number;
    dicMinLen: number;
    dicMaxLen: number;
    veterinaryIdMaxLen: number;
    milkRoomCodeMinLen: number;
    milkRoomCodeMaxLen: number;
}

export class Config {
    static readonly forms: FormsConfig = {
        passwordMinLen: 8,
        usernameMinLen: 3,
        icoMinLen: 8,
        icoMaxLen: 8,
        dicMinLen: 10,
        dicMaxLen: 12,
        veterinaryIdMaxLen: 10,
        milkRoomCodeMinLen: 4,
        milkRoomCodeMaxLen: 8,
    };

    static readonly apiUrl: string =  __API__;

    static readonly environment: Environment = __ENVIRONMENT__;

    static readonly isDevelopment: boolean = Config.environment === 'development';

    static readonly isProduction: boolean = Config.environment === 'production';

    static readonly isTest: boolean = Config.environment === 'test';

    static readonly isStaging: boolean = Config.environment === 'staging';
}
