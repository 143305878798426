import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {AddressSchema} from '../common/Address';
import {CustomerInvoiceDetailsSchema} from '../common/CustomerInvoiceDetails';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    firstName: t.string,
    lastName: t.string,
    customerName: t.string,
    invoiceDetails: CustomerInvoiceDetailsSchema,
    address: AddressSchema,
    billingAddress: AddressSchema,
});

export const CustomerSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Customer extends t.TypeOf<typeof CustomerSchema> {}
