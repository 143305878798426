import {union, filter} from 'lodash/fp';

import {WizardAction} from './actions';

export const initialWizardState: WizardState = {
    currentStep: 1,
    isComplete: false,
    stepsSize: 1,
    skippedSteps: [],
};

export interface WizardState {
    currentStep: number;
    isComplete: boolean;
    stepsSize: number;
    skippedSteps: number[];
}

const reducer = (state: WizardState | undefined = initialWizardState, action: WizardAction): WizardState => {
    switch (action.type) {
        case 'wizard/NEXT_STEP': {
            return {...state,
                currentStep: state.currentStep + 1,
                skippedSteps: filter(x => x !== state.currentStep, state.skippedSteps),
            };
        }
        case 'wizard/RESET_WIZARD': {
            return initialWizardState;
        }
        case 'wizard/COMPLETE_WIZARD': {
            return {...state, isComplete: true};
        }
        case 'wizard/SET_STEPS_SIZE': {
            return {...state, stepsSize: action.payload};
        }
        case 'wizard/SET_STEP': {
            return {...state, currentStep: action.payload};
        }
        case 'wizard/SKIP_STEP': {
            return {...state, skippedSteps: union([action.payload], state.skippedSteps)};
        }
        default:
            return state;
    }
};

export default reducer;
