import {opt} from 'ts-opt';
import {convertStringToFloat, emptyStringToUndefined} from 'favorlogic-utils';

import {UpdateSampleFormValues} from 'sample/types/UpdateSampleFormValues';
import {UpdateSample} from 'types/model/samples/UpdateSample';
import {SampleBase} from 'types/model/samples/SampleBase';

export function prepareUpdateSample(values: UpdateSampleFormValues, isLaborer: boolean): UpdateSample {
    const samplingTemperature = opt(values.samplingTemperature)
        .chainToOpt(emptyStringToUndefined)
        .map(t => convertStringToFloat(t).orCrash('invalid sampling temperature'))
        .orUndef();
    const sampleBase: SampleBase = {
        analysisIds: values.analysisIds,
        barcode: values.barcode,
        type: opt(values.type).orCrash('missing type'),
        harvestType: opt(values.harvestType).orCrash('missing harvestType'),
        samplingDate: values.samplingDate,
        samplingTime: opt(values.samplingTime).map(emptyStringToUndefined).orUndef(),
        samplingTemperature,
        sampleSourceAmount: opt(values.sampleSourceAmount).chainToOpt(parseInt).orUndef(),
        qualitative: values.qualitative,
        cistern: values.cistern,
        control: values.control,
        subsidy: values.subsidy,
        showResultsOnWeb: values.showResultsOnWeb,
        showResultsToBreeders: values.showResultsToBreeders,
        customerNote: opt(values.customerNote).chainToOpt(emptyStringToUndefined).orUndef(),
        traitNote: opt(values.traitNote).orUndef(),
        milkRoomId: opt(values.milkRoomId).orUndef(),
    };

    return isLaborer
        ? {
            ...sampleBase,
            canceled: values.canceled,
            disabled: values.disabled,
            note: emptyStringToUndefined(values.note),
            preservatives: values.preservatives,
            deliveryTemperature: convertStringToFloat(values.deliveryTemperature).orUndef(),
            tag: 'UpdateSampleByLaborer',
        }
        : {
            ...sampleBase,
            tag: 'UpdateSampleByCustomer',
        };
}
