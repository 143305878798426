import * as t from 'io-ts';

import {EmailSchema} from 'types/generic/EmailSchema';
import {InvoiceDetailsSchema} from './InvoiceDetails';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('CustomerInvoiceDetails'),
    email: EmailSchema,
});

export const CustomerInvoiceDetailsSchema = t.exact(t.intersection([
    InvoiceDetailsSchema.type,
    optionalPart,
    requiredPart,
]));

export interface CustomerInvoiceDetails extends t.TypeOf<typeof CustomerInvoiceDetailsSchema> {}
