import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';

interface Props {
    id: number;
    orderId?: number;
    showEdit: boolean;
    showDisabled:  boolean;
    canDelete: boolean;
    canDisable: boolean;
    editUrl: string;
    canEdit: boolean;

    handleDisable(id: number): void;
    handleDelete(id: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            id,
            handleDelete,
            handleDisable,
            showEdit,
            showDisabled,
            canDelete,
            canDisable,
            editUrl,
            canEdit,
        } = this.props;

        return <div>
            {showEdit && <Buttons.RowActionButton
                type="UPDATE"
                to={editUrl}
                disabled={!canEdit}
            />}
            {showDisabled && <Buttons.RowActionButton
                type="CANCEL"
                title="Označit jako nepoužitelný"
                onClick={() => handleDisable(id)}
                disabled={!canDisable}
            />}
            <Buttons.RowActionButton
                type="DELETE"
                onClick={() => handleDelete(id)}
                disabled={!canDelete}
            />
        </div>;
    }
}

export default RowActions;
