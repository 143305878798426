import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select} from 'typed-redux-saga';

import {formValuesF} from 'utils/formHelpers';

interface QCzProtocolData {
    preview: boolean;
    yearMonth: string;
}

export function* getQCzProtocolData(): SagaIterator<QCzProtocolData> {
    const values = (yield* select(formValuesF('generateQCzProtocol')))
        .orCrash('missing form data');

    return {
        preview: values.preview,
        yearMonth: opt(values.yearMonth).orCrash('missing yearMonth'),
    };
}
