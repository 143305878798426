import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {CustomerView} from 'types/model/customers/CustomerView';

interface Props {
    customer: CustomerView;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {customer: {id}} = this.props;

        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={`/customers/${id}/edit`}
                />
            </div>
        );
    }
}

export default RowActions;
