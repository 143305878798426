import {flow, isArray} from 'lodash/fp';
import React, {Component, ComponentClass, ReactElement, ReactNode, cloneElement} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {setStepsSize, resetWizard} from 'wizard/actions';

interface OuterProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactElement<any>[];
}

interface InnerProps {
    currentStep: number;
    stepsSize: number;

    handleStepsSize(size: number): void;

    handleReset(): void;
}

type Props = InnerProps & OuterProps;


class Steps extends Component<Props> {
    componentDidUpdate(prevProps: Props): void {
        const {stepsSize: prevStepSize} = prevProps;
        const {handleStepsSize, children, handleReset, stepsSize} = this.props;
        const childrenSize = isArray(children) ? children.length : 1;

        if (stepsSize < prevStepSize) {
            handleReset();
        }

        handleStepsSize(childrenSize);
    }

    render(): ReactNode {
        const {children, stepsSize} = this.props;

        if (isArray(children)) {
            return children.map((child, i) =>
                /* eslint-disable-next-line react/no-array-index-key */
                <div key={i}>
                    {cloneElement(child, {
                        step: i + 1,
                        stepsSize,
                        key: i,
                    })}
                </div>,
            );
        }
        return children;
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    currentStep: state.wizard.currentStep,
    stepsSize: state.wizard.stepsSize,
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleStepsSize: (size: number) => {
        dispatch(setStepsSize(size));
    },
    handleReset: () => {
        dispatch(resetWizard());
    },
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Steps) as ComponentClass<OuterProps>;
