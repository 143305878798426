import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {loadDataItems} from 'dataItem/actions';
import {Components as Layout} from 'layout';
import {Analysis} from 'types/model/analyses/Analysis';
import {DataItem} from 'types/model/dataItems/DataItem';
import withUser from 'user/components/withUser';

import {loadAnalyses} from '../actions';
import AnalysisTable from '../components/AnalysesTable';

interface OuterProps {}

interface StateProps {
    analyses: Analysis[] | null;
    dataItems: DataItem[] | null;
}

interface DispatchProps {
    handleLoadAnalyses(): void;
    handleLoadDataItems(): void;
}

type Props = OuterProps & StateProps & DispatchProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {handleLoadAnalyses, handleLoadDataItems} = this.props;

        handleLoadDataItems();
        handleLoadAnalyses();
    }

    render(): ReactNode {
        const {analyses, dataItems} = this.props;

        if (analyses && isEmpty(analyses)) {
            return this.renderNoAnalyses();
        }

        return (
            <Layout.ListPage title="Rozbory">
                <AnalysisTable
                    analyses={analyses}
                    dataItems={dataItems}
                />
            </Layout.ListPage>
        );
    }

    private renderNoAnalyses(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="text-center h5">
                    Zatím nejsou žádné rozbory.
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    analyses: state.analysis.analyses,
    dataItems: state.dataItem.dataItems,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleLoadDataItems: () => dispatch(loadDataItems()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
