import {call, put, select} from 'typed-redux-saga';
import {SagaIterator} from 'redux-saga';
import {ErrorResponse, SuccessResponse, showError, takeLatestF} from 'favorlogic-utils';

import {isLoading} from 'layout/actions';
import {handleResponseError} from 'utils/handleResponseError';
import {Ares} from 'types/model/ares/Ares';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';
import {Address} from 'types/model/common/Address';
import {StoreState} from 'app/types/StoreState';
import {SupplierInvoiceDetails} from 'types/model/common/SupplierInvoiceDetails';
import Api from 'user/api';

import {CreateSupplierFormValues} from '../types/CreateSupplierFormValues';
import {prefillCreateSupplierForm, FillCreateSupplierFormFromAresAction} from '../actions';

const errorTitle = 'Načtení dodavatele z ARES';

export function getFilledFormValues(ares: Ares, currentValues: CreateSupplierFormValues): CreateSupplierFormValues {
    const address: Address = {
        street: ares.street || '',
        city: ares.city || '',
        zip: ares.zip || '',
    };
    const invoiceDetails: SupplierInvoiceDetails = {
        ...currentValues.invoiceDetails,
        dic: ares.dic || '',
    };

    return {
        ...currentValues,
        invoiceDetails,
        address,
        name: ares.companyName || '',
        prefilled: true,
    };
}

function* getCurrentCreateSupplierFormValues(): SagaIterator<CreateSupplierFormValues | undefined> {
    return yield* select((state: StoreState) => formValuesF('createSupplier')(state).orUndef());
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    if (!response.isUnauthorized) {
        yield* call(handleResponseError, response, errorTitle);
    }
}

function* handleSuccessResponse({data}: SuccessResponse<Ares | null>): SagaIterator {
    const formValues = yield* call(getCurrentCreateSupplierFormValues);

    if (!formValues) {
        return;
    }

    if (data) {
        yield* put(prefillCreateSupplierForm(getFilledFormValues(data, formValues)));
    } else {
        yield* put(showError(errorTitle, 'Žádné výsledky nebyly nalezeny.'));
        yield* put(stopSubmitF('createSupplier', {
            invoiceDetails: {
                ico: 'IČO nebylo nalezeno v ARES.',
            },
        }));
    }
}

function* execute(action: FillCreateSupplierFormFromAresAction): SagaIterator {
    yield* put(isLoading(true));

    const response = yield* call(Api.getAres, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
    yield* put(isLoading(false));
}

export function* fillCreateSupplierFormFromAresSaga(): SagaIterator {
    yield takeLatestF('supplier/FILL_CREATE_SUPPLIER_FORM_FROM_ARES', execute);
}
