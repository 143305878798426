import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {opt} from 'ts-opt';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {DataItem} from 'types/model/dataItems/DataItem';
import {AnalysisAvailability} from 'types/model/enumeration/AnalysisAvailability';

import {AnalysisFormValues} from '../../types/AnalysisFormValues';
import {availabilityOptions} from '../../types/AvailabilityOptions';
import {getDataItemOptions} from '../../utils/getDataItemOptions';
import {validate} from './validations';

interface OwnProps {
    dataItems: DataItem[] | null;
}

export type Props = PropsForForm<AnalysisFormValues, OwnProps>;

class AnalysisForm extends Component<Props> {
    render(): ReactNode {
        const {handleSubmit, valid, submitting, dataItems, pristine} = this.props;

        return (
            <Layout.Panel>
                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || pristine} />
                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('name')}
                            type="text"
                            label="Název*"
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('index')}
                            type="number"
                            label="Pořadí*"
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('abbr')}
                            type="text"
                            label="Zkratka*"
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Select<number>
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('dataItemIds')}
                            label="Datové položky"
                            options={getDataItemOptions(opt(dataItems).orElse([]))}
                            multi
                            disabled
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('price')}
                            type="text"
                            label="Cena*"
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Select<AnalysisAvailability>
                            className="mt-2"
                            name={validateKey<AnalysisFormValues>('availability')}
                            label="Dostupnost"
                            options={availabilityOptions}
                        />
                    </div>

                    <div className="col-12">
                        <div className="d-flex justify-content-end mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Uložit
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<AnalysisFormValues, OwnProps, Props>(AnalysisForm, {
    form: 'analysis',
    validate,
    initialValues: {
        index: '',
        name: '',
        abbr: '',
        dataItemIds: [],
        price: '',
        availability: 'ALL',
    },
});
