import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {SampleBaseSchema} from './SampleBase';

const optionalPart = t.partial({
    supplyChainId: IntegerSchema,
});
const requiredPart = t.interface({
    sampleOrder: IntegerSchema,
    tag: t.literal('CreateSample'),
});


export const CreateSampleSchema = t.exact(t.intersection([
    SampleBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface CreateSample extends t.TypeOf<typeof CreateSampleSchema> {}
