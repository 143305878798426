import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {
    downloadFileFromResponse,
    takeLatestF,
    SuccessResponse,
    ErrorResponse,
} from 'favorlogic-utils';

import {stopSubmitF, resetF, startSubmitF} from 'utils/formHelpers';
import {hideModal} from 'layout/actions';
import {handleResponseError} from 'utils/handleResponseError';

import {GenerateCustomersQCZProtocolAction} from '../actions';
import Api from '../api';
import {getQCzProtocolData} from './utils/getQCzProtocolData';

function* stopSubmittingAndCloseModal(): SagaIterator {
    yield* put(stopSubmitF('generateQCzProtocol'));
    yield* put(hideModal('generateCustomersQCzProtocol'));
    yield* put(resetF('generateQCzProtocol'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, 'Generování Q CZ protokolu pro jednotlivé mlékarny');
    yield* call(stopSubmittingAndCloseModal);
}

function* handleSuccessResponse(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* call(downloadFileFromResponse, response);
    yield* call(stopSubmittingAndCloseModal);
}

export function* execute(_: GenerateCustomersQCZProtocolAction): SagaIterator {
    yield* put(startSubmitF('generateQCzProtocol'));

    const {yearMonth, preview} = yield* call(getQCzProtocolData);
    const response = yield* call(
        preview ? Api.generatePreviewQCZProtocolByCustomers : Api.generateQCZProtocolByCustomers,
        yearMonth,
    );

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

function* generateCustomersQCzProtocolSaga(): SagaIterator {
    yield takeLatestF('administration/GENERATE_CUSTOMERS_Q_CZ_PROTOCOL', execute);
}

export default generateCustomersQCzProtocolSaga;
