import React, {FunctionComponent, Fragment, useCallback, useEffect} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Opt} from 'ts-opt';
import {flow, map} from 'lodash/fp';

import {StoreState} from 'app/types/StoreState';
import {DateString} from 'types/generic/DateString';
import {PickupLineRequests} from 'types/model/pickupLine/PickupLineRequests';
import {ListPage, LoaderWrapper, NoData} from 'layout/components';
import {Group, RightIconButton} from 'buttons/components';
import Label from 'forms/components/Label';
import BasicDateTime from 'forms/components/BasicDateTime';
import {PickupLine} from 'types/model/pickupLine/PickupLine';
import BasicSelect, {SelectOptions} from 'forms/components/BasicSelect';
import {withUser} from 'user/components';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';
import {Legend} from 'tables/components';

import {pickupLineAction} from '../actions';
import {OverviewTable} from '../components/OverviewTable';
import {PickupLineLoading} from '../types/PickupLineLoading';
import {getNextYearMonth} from '../utils/getNextYearMonth';

const getPickupLinesOptions = (pickupLines: Opt<PickupLine[]>): SelectOptions<number> =>
    pickupLines.map(map(x => ({value: x.id, label: x.name}))).orElse([]);

const renderToolbar = (
    yearMonth: DateString | undefined,
    pickupLine: number | undefined,
    pickupLinesOptions: SelectOptions<number>,
    disabledExport: boolean,
    onYearMonthChange: (value: DateString) => void,
    onPickupLineChange: (value: number) => void,
    onExport: () => void,
) =>
    <Fragment>
        <Group>
            <RightIconButton
                label="Exportovat"
                icon="DOWNLOAD"
                onClick={onExport}
                disabled={disabledExport}
            />
            <Label
                htmlFor="pickupLine"
                className="pl-md-2"
            >
                Svozová linka:
            </Label>
            <BasicSelect
                name="pickupLine"
                value={pickupLine}
                options={pickupLinesOptions}
                onChange={onPickupLineChange}
                searchable={false}
                className="col-2"
                clearable={false}
                disabled={pickupLinesOptions.length === 0}
                label={pickupLinesOptions.length === 0 ? 'Žádné linky' : undefined}
            />
            <Label
                htmlFor="yearMonth"
                className="pl-md-2"
            >
                Měsíc:
            </Label>
            <BasicDateTime
                mode="YEARMONTH"
                name="yearMonth"
                value={yearMonth}
                onChange={onYearMonthChange}
                className="col-2"
                noPadding
                includeFuture
                disabled={pickupLinesOptions.length === 0}
            />
        </Group>

    </Fragment>
;

const legendAnnotation: ColorAnnotation[] = [
    {color: 'other', label: 'Den v týdnu'},
    {color: 'disabled', label: 'Svátek nebo víkend'},
];

interface StateProps {
    loading: PickupLineLoading[];
    pickupLineRequests: Opt<PickupLineRequests>;
    overviewDate: Opt<DateString>;
    overviewPickupLine: Opt<number>;
    pickupLines: Opt<PickupLine[]>;
}

interface DispatchProps {
    getPickupLineRequests(yearMonth: DateString, pickupLineId: number): void;
    getPickupLineRequestsExport(yearMonth: DateString, pickupLineId: number): void;
    setOverviewDate(yearMonth: DateString): void;
    getPickupLines(): void;
    setDisplayedPickupLine(pickupLineId: number): void;
}

type Props = StateProps & DispatchProps;

const OverviewBase: FunctionComponent<Props> = props => {
    const {
        loading,
        pickupLineRequests,
        overviewDate,
        overviewPickupLine,
        pickupLines,
        getPickupLineRequests,
        getPickupLineRequestsExport,
        setOverviewDate,
        getPickupLines,
        setDisplayedPickupLine,
    } = props;

    useEffect(() => {
        getPickupLines();
    }, [getPickupLines]);

    useEffect(() => {
        if (overviewDate.isEmpty) {
            setOverviewDate(getNextYearMonth());
        }
    }, [overviewDate, setOverviewDate]);

    useEffect(() => {
        if (overviewDate.nonEmpty && overviewPickupLine.nonEmpty) {
            overviewDate.zip(overviewPickupLine).onSome(([od, opl]) => getPickupLineRequests(od, opl));
        }
    }, [overviewDate, overviewPickupLine, getPickupLineRequests]);

    useEffect(() => {
        const indexOfFirst = pickupLines.orElse([]).find(x => x !== undefined)?.id;
        if (overviewPickupLine.isEmpty && indexOfFirst) {
            setDisplayedPickupLine(indexOfFirst);
        }
    }, [overviewPickupLine, pickupLines, setDisplayedPickupLine]);

    const onExport = useCallback(() => {
        if (overviewDate.nonEmpty && overviewPickupLine.nonEmpty) {
            getPickupLineRequestsExport(
                overviewDate.orCrash('overviewDate not available'),
                overviewPickupLine.orCrash('overviewPickupLine not available'),
            );
        }
    }, [overviewDate, overviewPickupLine, getPickupLineRequestsExport]);

    const overviewDatePresent = overviewDate.orNull();
    const analysisPresent = pickupLineRequests.orNull()?.analysis || [];
    const haveNoData = !overviewDatePresent || analysisPresent.length === 0;

    const getNoDataMessage = () => getPickupLinesOptions(pickupLines).length > 0
        ? 'Zkuste zvolit jiný měsíc nebo svozovou linku.'
        : 'Neexistuje žádná svozová linka. Přidejte novou v sekci "Svozové linky" -> "Administrace".';

    return (
        <ListPage
            title="Přehled svoz. linek"
            toolbar={
                renderToolbar(
                    overviewDate.orUndef(),
                    overviewPickupLine.orUndef(),
                    getPickupLinesOptions(pickupLines),
                    haveNoData,
                    setOverviewDate,
                    setDisplayedPickupLine,
                    onExport
                )}
        >
            <LoaderWrapper showLoader={loading.includes('overview')}>
                {haveNoData
                    ? <NoData
                        title="Data nejsou k dispozici."
                        text={getNoDataMessage()}
                    />
                    : <Fragment>
                        <Legend annotations={legendAnnotation} />
                        <OverviewTable
                            loading={loading}
                            overviewDate={overviewDatePresent}
                            analysis={analysisPresent}
                        />
                    </Fragment>
                }
            </LoaderWrapper>
        </ListPage>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getPickupLineRequests: (yearMonth: DateString, pickupLineId: number) =>
        dispatch(pickupLineAction.getPickupLineRequests(yearMonth, pickupLineId)),
    getPickupLineRequestsExport: (yearMonth: DateString, pickupLineId: number) =>
        dispatch(pickupLineAction.getPickupLineRequestsExport(yearMonth, pickupLineId)),
    setOverviewDate: (yearMonth: DateString) => dispatch(pickupLineAction.setOverviewDate(yearMonth)),
    getPickupLines: () => dispatch(pickupLineAction.getPickupLines()),
    setDisplayedPickupLine: (pickupLineId: number) => dispatch(pickupLineAction.setDisplayedPickupLine(pickupLineId)),
});

const mapStateToProps = (state: StoreState): StateProps => ({
    loading: state.pickupLine.loading,
    pickupLineRequests: state.pickupLine.pickupLineRequests,
    overviewDate: state.pickupLine.overviewDate,
    overviewPickupLine: state.pickupLine.displayedPickupLine,
    pickupLines: state.pickupLine.pickupLines,
});

export const Overview = flow(
    connect(mapStateToProps, mapDispatchToProps),
    withUser,
)(OverviewBase);
