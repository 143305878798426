import {PriceClass} from 'types/model/enumeration/PriceClass';
import {SelectOptions} from 'forms/components/BasicSelect';

export const priceClassOptions: SelectOptions<PriceClass> = [
    {
        label: 'Smluvní',
        value: 'CONTRACTUAL',
    },
    {
        label: 'Nesmluvní',
        value: 'NON_CONTRACTUAL',
    },
];
