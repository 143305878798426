import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';

const optionalPart = t.partial({
    sequenceNumber: IntegerSchema,
    accepted: DateStringSchema,
    milkRoomName: t.string,
    milkRoomCode: t.string,
    supplyChainCode: t.string,
    supplyChainName: t.string,
    note: t.string,
    customerNote: t.string,
});
const requiredPart = t.interface({
    sampleId: IntegerSchema,
    orderId: IntegerSchema,
    samplingDate: DateStringSchema,
    barcode: BarcodeSchema,
    canceled: t.boolean,
    disabled: t.boolean,
    hasMeasurements: t.boolean,
    hasAllMeasurements: t.boolean,
    canDelete: t.boolean,
    canUpdate: t.boolean,
    analysisIds: t.array(IntegerSchema),
});

export const SampleViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SampleView extends t.TypeOf<typeof SampleViewSchema> {}
