import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {DilutionSchema} from 'types/generic/DilutionSchema';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    numOfDecimalPlaces: IntegerSchema,
    checkErrors: t.boolean,
    minimum: IntegerSchema,
    maximum: IntegerSchema,
    measurementUnit: t.string,
    dilution: DilutionSchema,
});

export const UpdateDataItemSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateDataItem extends t.TypeOf<typeof UpdateDataItemSchema> {}
