exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-layout-components-Message-___styles__narrow___3drTn {\n  max-width: 720px;\n  margin: auto; }\n", ""]);

// exports
exports.locals = {
	"narrow": "src-modules-layout-components-Message-___styles__narrow___3drTn"
};