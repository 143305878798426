import {Config} from 'app/Config';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {CreateUserFormValues} from '../../types/CreateUserFormValues';

const config = Config.forms;

export const validate = (values: CreateUserFormValues): Errors<CreateUserFormValues> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('firstName', 'Jméno');
    validator.nonEmpty('lastName', 'Příjmení');
    validator.nonEmpty('username', 'Uživatelské jméno');
    validator.minStringLength('username', config.usernameMinLen, 'Uživatelské jméno');
    validator.nonEmpty('password', 'Heslo');
    validator.minStringLength('password', config.passwordMinLen, 'Heslo');


    return validator.generateErrorsObject();
};
