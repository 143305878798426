import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {AFormValues} from 'test/components/AForm/AFormValues';
import {validate} from './validations';
import {SelectOptions} from 'forms/components/BasicSelect';

export interface OwnProps {
}

export type Props = PropsForForm<AFormValues, OwnProps>;

class AForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        const options: SelectOptions<number> = [
            {label: 'One', value: 1},
            {label: 'Two', value: 2},
        ];

        const strOptions: SelectOptions<string> = [
            {label: 'Miles', value: 'MILES'},
            {label: 'Keiko', value: 'KEIKO'},
        ];

        return (
            <Layout.Panel caption="">
                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />
                    <div className="col-12">
                        {/*
                         <Field
                         className="mt-2"
                         name="email"
                         type="text"
                         component={FormField}
                         label="E-mail*"
                         />
                         */}

                        <div className="col-12">
                            <Fields.Input
                                label="A text"
                                name="text"
                                type="text"
                                className="mt-2"
                            />
                        </div>

                        <div className="col-12">
                            <Fields.Input
                                label="Some number"
                                name="num"
                                type="number"
                                onAction={(x) => window.alert(`Action: ${x}`)}
                                className="mt-2"
                            />
                        </div>

                        <div className="row mx-1">
                            <div className="col-6">
                                <Fields.Checkbox
                                    label="Yes?"
                                    name="bool"
                                    className="mt-2"
                                />
                            </div>
                            <div className="col-6">
                                <Fields.Checkbox
                                    label="Def. on"
                                    name="boolOn"
                                    className="mt-2"
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <Fields.Textarea
                                label="Wall of text"
                                name="textarea"
                                className="mt-2"
                                rows={4}
                            />
                        </div>

                        <div className="col-12">
                            <Fields.Radio
                                radioValue="tom"
                                label="Tom"
                                name="radio"
                                className="mt-2"
                            />
                            <Fields.Radio
                                radioValue="harry"
                                label="Harry"
                                name="radio"
                                className="mt-2"
                            />
                        </div>

                        <div className="col-12">
                            <Fields.DateTime
                                label="Date"
                                name="date"
                                className="mt-2"
                                mode="DATE"
                                onAction={(x) => window.alert(`Action: ${x}`)}
                            />
                            <Fields.DateTime
                                label="Time"
                                name="time"
                                className="mt-2"
                                mode="TIME"
                                onAction={(x) => window.alert(`Action: ${x}`)}
                            />
                        </div>

                        <div className="col-12">
                            <Fields.Select<number>
                                label="Pick one"
                                name="select"
                                className="mt-2"
                                options={options}
                                onAction={(x) => window.alert(`Action: ${x}`)}
                            />
                        </div>

                        <div className="col-12">
                            <Fields.Select<number>
                                label="Pick more"
                                name="multiSelect"
                                className="mt-2"
                                options={options}
                                multi
                                onAction={(x) => window.alert(`Action: ${x}`)}
                            />
                        </div>

                        <div className="col-12">
                            <Fields.Select<string>
                                label="🚀"
                                name="multiSelectString"
                                className="mt-2"
                                options={strOptions}
                                multi
                                onAction={(x) => window.alert(`Action: ${x}`)}
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-end mt-4">
                                <Buttons.Button
                                    className="w-100"
                                    importance="primary"
                                    disabled={!valid || submitting}
                                    type="submit"
                                >Odeslat</Buttons.Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

const initialValues: AFormValues = {
    text: '',
    num: '',
    bool: false,
    boolOn: true,
    textarea: '',
    radio: 'harry',
    date: null,
    time: null,
    multiSelect: [],
    select: null,
    multiSelectString: ['MILES'],
};

export default Forms.withForm<AFormValues, OwnProps, Props>(AForm, {
    form: 'testAForm',
    validate,
    initialValues,
});
