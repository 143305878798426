import * as t from 'io-ts';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    street: t.string,
    city: t.string,
    zip: t.string,
});

export const AddressSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Address extends t.TypeOf<typeof AddressSchema> {}
