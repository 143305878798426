import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {SelectOptions} from 'forms/components/BasicSelect';
import {ImportMeasurementFormValues} from 'measurement/type/ImportMeasurementFormValues';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {validate} from './validations';

interface OwnProps {
    operatorIdOptions: SelectOptions<number>;
}

export type Props = PropsForForm<ImportMeasurementFormValues, OwnProps>;

class ImportMeasurementForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
            operatorIdOptions,
        } = this.props;
        return (
            <Form
                className="position-relative row"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />
                <div className="col-12">
                    <Fields.Select<number>
                        className="mt-2"
                        name="operatorId"
                        label="Pracovník*"
                        options={operatorIdOptions}
                    />
                </div>
            </Form>
        );
    }
}

export default Forms.withForm<ImportMeasurementFormValues, OwnProps, Props>(ImportMeasurementForm, {
    form: 'importMeasurement',
    validate,
    initialValues: {
        operatorId: null,
    },
});
