import React, {Component, ReactNode, Fragment} from 'react';
import {find, map, flow, sortBy} from 'lodash/fp';
import {opt} from 'ts-opt';
import {
    convertStringToLimitedLengthElement,
    intercalateElem,
} from 'favorlogic-utils';

import {sampleHarvestTypeOptions} from 'sample/enums/SampleHarvestTypeEnum';
import {sampleTypeOptions} from 'sample/enums/SampleTypeEnum';
import {Components as Tables} from 'tables';
import {Components as Icons} from 'icons';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {Analysis} from 'types/model/analyses/Analysis';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {CreateSample} from 'types/model/samples/CreateSample';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import RowActions from './RowActions';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {SortDir} from 'tables/components/withSort';
import {Filter} from 'tables/types/Filter';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {SampleType} from 'types/model/enumeration/SampleType';
import {SampleTemperature} from 'types/generic/SampleTemperatureSchema';
import {SelectOptions} from 'forms/components/BasicSelect';

import noteIcon from 'buttons/components/RowActionButton/img/update.svg';
import {LoaderWrapper} from 'layout/components';

const TABLE_ANNOTATIONS: ColorAnnotation[] = [
    {color: 'warning', label: 'Nahrána měření'},
    {color: 'info', label: 'Nahrána všechna měření'},
    {color: 'lowlight', label: 'Nepoužitelné a zrušené vzorky'},
];


const limitMilkRoom = convertStringToLimitedLengthElement(15);

export type SampleRow = {
    sampleOrder: number,
    barcode: string,
    samplingTemperature?: SampleTemperature,
    samplingDate?: string,
    type: SampleType,
    harvestType: string,
    milkRoomName: ReactNode,
    notes: ReactNode,
    analysisIds: ReactNode,
    supplyChainCode: ReactNode,
    accepted: boolean,
    actions: ReactNode,
};

export const definedFilters: FilterDefinition<keyof SampleRow>[] = [
    {key: 'supplyChainCode', type: 'equals'},
    {key: 'barcode', type: 'equals'},
    {key: 'type', type: 'select'},
    {key: 'harvestType', type: 'select'},
    {key: 'milkRoomName', type: 'equals'},
    {key: 'analysisIds', type: 'multiselect'},
    {key: 'accepted', type: 'select'},
];

export type SampleTableMode = 'CREATE' | 'VIEW' | 'EDIT' | 'DETAIL';

export type AnySample = CreateSample | OrderSampleView;

interface OuterProps {
    samples: AnySample[] | null;
    showDisabled?: boolean;
    orderId?: number;
    mode: SampleTableMode;
    milkRooms: MilkRoom[] | null;
    analyses: Analysis[];
    isRoleAdminOrLaborer?: boolean;
    supplyChains?: SupplyChain[];
    showSupplyChainCode: boolean;
    showNote?: boolean;
    sortDir?: SortDir;
    sortBy?: string;
    sortable?: boolean;
    filter?: Filter;
    canEdit?: boolean;
    disableActions?: boolean;

    handleSortChange?(property: string): void;

    handleFilterChange?(accessor: string, type: string, values: string[]): void;

    handleFilterClear?(): void;

    handleFilterSubmit?(): void;

    handleDelete?(id: number): void;

    handleDisable?(id: number): void;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

export const showActions = (mode: SampleTableMode, disableActions?: boolean, canEdit?: boolean): boolean => {
    if (disableActions === true) {
        return false;
    }

    if (mode === 'CREATE' || mode === 'EDIT') {
        return true;
    }

    if (mode === 'DETAIL' && canEdit) {
        return true;
    }

    return false;
};

class SamplesTable extends Component<Props> {
    render(): React.ReactNode {
        const {
            showNote,
            mode,
            sortBy,
            sortDir,
            filter,
            handleSortChange,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            samples,
        } = this.props;

        return (
            <Fragment>
                <LoaderWrapper showLoader={!samples}>
                    <Tables.Table
                        columns={this.genColumns()}
                        rows={this.genData(samples ?? [])}
                        hasTwoMainColumns={showNote}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        filter={filter}
                        handleSortChange={handleSortChange}
                        handleFilterChange={handleFilterChange}
                        handleFilterClear={handleFilterClear}
                        handleFilterSubmit={handleFilterSubmit}
                    />
                    {(mode === 'DETAIL' || mode === 'EDIT') &&
                        <Tables.Legend annotations={TABLE_ANNOTATIONS} />
                    }
                </LoaderWrapper>
            </Fragment>
        );
    }

    private genColumns(): Column<SampleRow>[] {
        const {showSupplyChainCode, showNote, filter, sortable, mode, disableActions, canEdit} = this.props;
        const filterable = Boolean(filter);
        const supplyChainCode: Column<SampleRow> = {
            accessor: 'supplyChainCode',
            header: 'Kód linky',
            type: ColumnType.Node,
            filterable,
            filterType: 'equals',
            sortable,
        };
        const notes: Column<SampleRow> = {
            accessor: 'notes',
            header: '',
            type: ColumnType.Node,
        };
        const actions: Column<SampleRow> = {
            accessor: 'actions',
            header: 'Akce',
            type: ColumnType.Node,
        };

        return [
            ...showNote ? [notes] : [],
            {
                accessor: 'sampleOrder',
                header: 'Pořadí',
                type: ColumnType.Node,
                sortable,
            },
            {
                accessor: 'barcode',
                header: 'Čárový kód',
                tooltip: 'Unikátní identifikace vzorku',
                type: ColumnType.Node,
                filterable,
                filterType: 'equals',
                sortable,
            },
            {
                accessor: 'samplingTemperature',
                header: 'Teplota',
                type: ColumnType.Temperature,
                tooltip: 'Teplota při odběru',
                sortable,
            },
            {
                accessor: 'samplingDate',
                header: 'Datum odběru',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable,
            },
            {
                accessor: 'type',
                header: 'Typ vzorku',
                selectOptions: sampleTypeOptions,
                type: ColumnType.SelectOption,
                lengthLimit: 20,
                filterable,
                filterType: 'select',
            },
            {
                accessor: 'harvestType',
                header: 'Způsob odběru',
                selectOptions: sampleHarvestTypeOptions,
                type: ColumnType.SelectOption,
                filterable,
                filterType: 'select',
            },
            {
                accessor: 'milkRoomName',
                header: 'Mléčnice',
                type: ColumnType.Node,
                filterable,
                filterType: 'equals',
                sortable,
            },
            ...showSupplyChainCode ? [supplyChainCode] : [],
            {
                accessor: 'analysisIds',
                header: 'Rozbory',
                type: ColumnType.MultiSelectOption,
                selectOptions: this.getAnalysesOptions(),
                filterable,
                filterType: 'multiselect',
            },
            {
                accessor: 'accepted',
                header: 'Přijatý',
                type: ColumnType.Bool,
                filterable,
                filterType: 'select',
            },
            ...showActions(mode, disableActions, canEdit) ? [actions] : [],
        ];
    }

    private getSupplyChainCode(sample: AnySample): ReactNode {
        const {supplyChains} = this.props;

        if ('supplyChainCode' in sample) {
            return <span title={sample.supplyChainName}>{sample.supplyChainCode}</span>;
        }

        if (supplyChains && 'supplyChainId' in sample) {
            const supplyChain = supplyChains.find(s => s.id === sample.supplyChainId);
            return supplyChain ? <span title={supplyChain.name}>{supplyChain.code}</span> : '';
        }

        return '';
    }

    private getMilkRoomName(sample: AnySample): string {
        const {milkRooms} = this.props;
        if ('milkRoomName' in sample) {
            return opt(sample.milkRoomName)
                .map(() => `${sample.milkRoomName} (${sample.veterinaryId})`)
                .orElse('');
        }

        if ('milkRoomId' in sample) {
            const matchMilkRoom = (m: MilkRoom) => m.id === sample.milkRoomId;
            const milkRoom = find(matchMilkRoom, milkRooms);

            return opt(milkRoom).map(m => `${m.name} (${m.veterinaryId})`).orElse('');
        }
        return '';
    }

    private getAnalysisForSample(sample: AnySample): Analysis[] {
        const {analyses} = this.props;

        return analyses.filter(a => sample.analysisIds.includes(a.id));
    }

    private getAnalysisNames(sample: AnySample) {
        const renderAllAnalysis = (analysis: Analysis[]) => flow([
            sortBy((a: Analysis) => a.id),
            map((a: Analysis) =>  <span title={a.name}>{a.abbr}</span>),
            intercalateElem(<span> | </span>),
        ])(analysis);

        return (
            <span>
                {renderAllAnalysis(this.getAnalysisForSample(sample))}
            </span>
        );
    }

    private getAnalysesOptions = (): SelectOptions<string> => {
        const {analyses} = this.props;
        return analyses.map(a => ({
            label: a.abbr,
            value: String(a.id),
            title: a.name,
        }));
    };

    private genData(samples: AnySample[]): Row<SampleRow>[] {
        const {mode, handleDelete, handleDisable, orderId, showDisabled, isRoleAdminOrLaborer} = this.props;
        if (!samples) {
            return [];
        }

        const onDelete = (id: number) => {
            if (handleDelete) { handleDelete(id); }
        };
        const onDisable = (id: number) => {
            if (handleDisable) { handleDisable(id); }
        };

        const canUpdate = (s: AnySample): boolean => {
            if (mode === 'CREATE') {
                return true;
            }
            return 'canUpdate' in s ? s.canUpdate : false;
        };

        const renderNotes = (s: AnySample): ReactNode =>
            <Fragment>
                {'note' in s && s.note &&
                <Icons.RowIcon
                    icon={noteIcon}
                    tooltip={`Poznámka laboratoře: ${s.note}`}
                    fillColor="gray"
                />
                }
                {s.customerNote &&
                <Icons.RowIcon
                    icon={noteIcon}
                    tooltip={`Poznámka zákazníka: ${s.customerNote}`}
                />
                }
            </Fragment>;

        const getId = (s: AnySample, idx: number) => {
            if ('sampleId' in s) {
                return s.sampleId;
            }

            return idx;
        };

        return samples.map((s, idx) => {
            const id = getId(s, idx);
            const canDelete = 'canDelete' in s ? s.canDelete : true;
            const canDisable = 'disabled' in s ? !s.disabled : false;
            const accepted = 'accepted' in s && s.accepted;
            const canEdit = isRoleAdminOrLaborer || !accepted;
            const editUrl = mode === 'CREATE' ? `/orders/new/editSample/${id}` : `/orders/${orderId}/editSample/${id}`;
            const lowlighted = 'disabled' in s && s.disabled || 'canceled' in s && s.canceled;
            const isFullMeasured = 'hasAllMeasurements' in s && s.hasAllMeasurements;
            const isPartMeasured = 'hasMeasurements' in s && s.hasMeasurements && !isFullMeasured;

            return {
                ...s,
                id,
                analysisIds: this.getAnalysisNames(s),
                milkRoomName: limitMilkRoom(this.getMilkRoomName(s)),
                supplyChainCode: this.getSupplyChainCode(s),
                notes: renderNotes(s),
                lowlighted,
                warninged: isPartMeasured && !lowlighted,
                informed: isFullMeasured && !lowlighted,
                accepted,
                actions:
                    <RowActions
                        id={id}
                        handleDelete={onDelete}
                        handleDisable={onDisable}
                        showEdit={canUpdate(s)}
                        editUrl={editUrl}
                        showDisabled={Boolean(showDisabled)}
                        canDelete={canDelete}
                        canDisable={canDisable}
                        canEdit={canEdit}
                    />
                ,
            };
        });
    }
}

export default SamplesTable;
