import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {uniqBy} from 'lodash/fp';
import {formatFloat, takeLatestF} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {AddImportedSamplesInOrderCreationAction, setLastCreatedSample} from 'order/actions';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {ParsedSample} from 'types/model/samples/ParsedSample';
import {formValuesF, blurF, touchF, resetF} from 'utils/formHelpers';

import {prepareSample, getNewSampleOrder} from './addSampleUtils';

function isValidImportedSample(sample: ParsedSample): boolean {
    return Boolean(sample.samplingDate);
}

export function createSampleValues(
    sample: ParsedSample,
    samplePresets: CreateSampleFormValues
): CreateSampleFormValues {
    return {
        analysisIds: samplePresets.analysisIds,
        barcode: sample.barcode,
        sampleSourceAmount: opt(sample.sampleSourceAmount).map(x => x.toString()).orElse(''),
        samplingTemperature: opt(sample.samplingTemperature).map(x => formatFloat(x)).orElse(''),
        samplingTime: samplePresets.samplingTime || '',
        type: samplePresets.type,
        harvestType: samplePresets.harvestType,
        samplingDate: sample.samplingDate || '',
        supplyChainId: opt(sample.supplyChainId).orNull(),
        supplyChainCode: null,
        milkRoomId: opt(sample.milkRoomId).orNull(),
        showResultsOnWeb: samplePresets.showResultsOnWeb,
        showResultsToBreeders: samplePresets.showResultsToBreeders,
        customerNote: samplePresets.customerNote,
        qualitative: samplePresets.qualitative,
        cistern: Boolean(sample.traitNote) || samplePresets.cistern,
        control: Boolean(sample.traitNote) || samplePresets.control,
        subsidy: samplePresets.subsidy,
        traitNote: sample.traitNote || samplePresets.traitNote,
    };
}

function* execute(_action: AddImportedSamplesInOrderCreationAction): SagaIterator {
    const createOrder = yield* select(formValuesF('createOrder'));
    const oldSamples = createOrder.map(x => x.samples).orElse([]);
    const samplePresets = (yield* select(formValuesF('createSample'))).orCrash('missing sample');
    const importedSamples = opt(yield* select((x: StoreState) => x.order.importedSamples)).orElse([]);
    const newSamples = importedSamples.filter(isValidImportedSample)
        .map(x => createSampleValues(x, samplePresets))
        .map((x, i) => prepareSample(x, getNewSampleOrder(oldSamples) + i))
        .reverse();
    const samples = uniqBy(x => x.barcode, [...newSamples, ...oldSamples]);

    if (oldSamples.length === samples.length) { return; }

    yield* put(blurF('createOrder', 'samples', samples));
    yield* put(touchF('createOrder', 'samples'));
    yield* put(resetF('createSample'));
    yield* put(setLastCreatedSample(samplePresets));
}

export function* addImportedSamplesInOrderCreationSaga(): SagaIterator {
    yield takeLatestF('order/ADD_IMPORTED_SAMPLES_IN_ORDER_CREATION', execute);
}
