import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, ErrorResponse, SuccessResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {CustomersPage} from 'types/model/customers/CustomerPage';

import {loadCustomersError, loadCustomersSuccess, LoadCustomersAction} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const errorTitle = 'Načtení zákazníků';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, errorTitle);
    yield* put(loadCustomersError(error));
}

function* handleSuccessResponse(response: SuccessResponse<CustomersPage>): SagaIterator {
    yield* put(loadCustomersSuccess(response.data));
}

function* execute(action: LoadCustomersAction): SagaIterator {
    const response = yield* call(Api.getCustomers, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadCustomersSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CUSTOMERS', execute);
}
