import {flow, find} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode, Fragment} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';

import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {SelectOptions} from 'forms/components/BasicSelect';
import {deleteMeasurement, loadMeasurement} from 'measurement/actions';
import {StoreState} from 'app/types/StoreState';
import {DataItem} from 'types/model/dataItems/DataItem';
import {Device} from 'types/model/devices/Device';
import {MeasurementDetails} from 'types/model/measurements/MeasurementDetails';
import {UserDetails} from 'types/model/users/UserDetails';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {loadLaborers} from 'user/actions';
import withUser, {WithUserProps} from 'user/components/withUser';
import {loadDataItems} from 'dataItem/actions';
import {loadDevices} from 'device/actions';
import {loadOperatingProcedures} from 'operatingProcedure/actions';
import {measurementUnitNP} from 'measurement/constants';
import {MeasurementHistory} from 'types/model/measurements/MeasurementHistory';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {Loader} from 'layout/components';

import {npUnitOptions} from '../type/npUnits';
import {buildProcedureOptions} from '../utils/buildProcedureOptions';

const cantDeleteMsg = 'Není možné smazat měření z vyfakturovaných nebo dokončených objednávek.';

interface OuterProps {
}

interface InnerProps {
    match: Match<{ id: string }>;
    measurement: Opt<MeasurementDetails>;
    dataItems: Opt<DataItem[]>;
    devices: Opt<Device[]>;
    procedures: Opt<OperatingProcedure[]>;
    laborers: Opt<UserDetails[]>;

    handleLoadMeasurement(id: number): void;
    handleLoadLaborers(): void;
    handleLoadDataItems(): void;
    handleLoadDevices(): void;
    handleLoadProcedures(): void;
    handleDeleteMeasurement(measurementId: number): void;
}

type UserProps = Pick<WithUserProps, 'isRoleAdmin' | 'isRoleLaborer'>;
type Props = InnerProps & OuterProps & UserProps;

class Detail extends Component<Props> {
    private get id(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    private get isNP(): boolean {
        return this.getDataItem()
            .chainToOpt(x => x.measurementUnit === measurementUnitNP)
            .orFalse();
    }

    private get canEdit(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    componentDidMount(): void {
        const {
            handleLoadMeasurement,
            handleLoadLaborers,
            handleLoadDataItems,
            handleLoadDevices,
            handleLoadProcedures,
        } = this.props;
        handleLoadDataItems();
        handleLoadDevices();
        handleLoadProcedures();
        handleLoadLaborers();
        handleLoadMeasurement(this.id);
    }

    render(): ReactNode {
        const {measurement, handleDeleteMeasurement} = this.props;
        const canDelete = measurement.map(m => m.canDelete).orFalse();

        return (
            <Layout.ItemPage
                title="Detail měření"
                backLabel="Měření"
                editAction={{to: `/measurements/${this.id}/edit`}}
            >
                <Layout.Panel>
                    <Loader show={measurement.isEmpty} />
                    <div className="row mb-4">
                        <ShowField
                            className="col-md-4"
                            label="Datová položka"
                            value={this.getDataItemName()}
                            type={ShowFieldType.String}
                        />
                        <ShowField
                            className="col-md-4"
                            label="Přístroj"
                            value={this.getDeviceName()}
                            type={ShowFieldType.String}
                        />
                        <ShowField
                            className="col-md-4"
                            label="Operátor"
                            value={this.getLaborerName()}
                            type={ShowFieldType.String}
                        />
                    </div>

                    {this.renderMeasurementResult()}
                </Layout.Panel>
                <div>
                    <Buttons.Button
                        type="button"
                        onClick={() => handleDeleteMeasurement(this.id)}
                        importance="secondary"
                        disabled={!canDelete}
                        title={canDelete ? undefined : cantDeleteMsg}
                    >
                        Smazat měření
                    </Buttons.Button>
                </div>
            </Layout.ItemPage>
        );
    }

    private renderMeasurementResult() {
        const {measurement} = this.props;
        const measured = measurement.map((m) => m.measured).orElse('');
        const result = measurement.map((m) => m.result.toString()).orElse('');
        const dilution = measurement.map((m) => m.dilution.toString()).orElse('');
        const procedureId = measurement.map((m) => m.procedureId).orNull();
        const history = measurement.map((m) => m.history).orNull();

        return (
            <Fragment>
                <div className="row mb-4">
                    <ShowField
                        className="col-md-4"
                        label="Změřeno"
                        value={measured}
                        type={ShowFieldType.Date}
                    />
                    {this.isNP
                        ? <ShowField
                            className="col-md-4"
                            label="Výsledek"
                            value={result}
                            type={ShowFieldType.SelectOption}
                            selectOptions={npUnitOptions}
                        />
                        : <ShowField
                            className="col-md-4"
                            label="Výsledek"
                            value={result}
                            type={ShowFieldType.String}
                        />
                    }
                    <ShowField
                        className="col-md-4"
                        label="Ředění"
                        value={dilution}
                        type={ShowFieldType.String}
                    />
                </div>
                <div className="row mb-4">
                    <ShowField
                        className="col-md-6"
                        label="Operační postup"
                        value={procedureId}
                        type={ShowFieldType.SelectOption}
                        selectOptions={this.getProcedureOptions()}
                    />
                </div>

                {history &&
                    <div className="row mt-5">
                        <div className="col-12">
                            <hr />
                        </div>
                        {this.renderHistory(history)}
                    </div>
                }
            </Fragment>
        );
    }

    private renderHistory(history: MeasurementHistory): React.ReactNode {
        const {reason, changed, changedBy} = history;

        return (
            <Fragment>
                <ShowField
                    className="col-md-4"
                    label="Změnil"
                    value={changedBy}
                    type={ShowFieldType.String}
                />
                <ShowField
                    className="col-md-4"
                    label="Změněno"
                    value={changed}
                    type={ShowFieldType.Date}
                />
                <ShowField
                    className="col-md-4"
                    label="Důvod úpravy"
                    value={reason}
                    type={ShowFieldType.String}
                />
            </Fragment>
        );
    }

    private getLaborerName(): string {
        const {laborers, measurement} = this.props;
        const measuredById = measurement.chainToOpt(x => x.measuredById).orUndef();
        const laborer = opt(laborers.orElse([]).find(l => l.id === measuredById));

        return laborer.chainToOpt(x => `${x.firstName} ${x.lastName}`).orElse('?');
    }

    private getDeviceName(): string {
        const {measurement, devices} = this.props;
        const deviceId = measurement.chainToOpt(x => x.deviceId).orUndef();
        const device = find(x => x.id === deviceId, devices.orUndef());

        return opt(device).map(x => x.name).orElse('-');
    }

    private getDataItemName(): string {
        return this.getDataItem().chainToOpt(x => x.name).orElse('?');
    }

    private getDataItem(): Opt<DataItem> {
        const {measurement, dataItems} = this.props;
        const dataItemId = measurement.chainToOpt(x => x.dataItemId).orUndef();

        return opt(find(x => x.id === dataItemId, dataItems.orUndef()));
    }

    private getProcedureOptions(): SelectOptions<number> {
        const {procedures} = this.props;

        return buildProcedureOptions(procedures.orNull(), this.getDataItem());
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    measurement: state.measurement.current,
    laborers: state.user.laborers,
    dataItems: opt(state.dataItem.dataItems),
    devices: opt(state.device.devices),
    procedures: opt(state.operatingProcedure.operatingProcedures),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadLaborers: () => dispatch(loadLaborers()),
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleLoadDevices: () => dispatch(loadDevices()),
    handleLoadProcedures: () => dispatch(loadOperatingProcedures()),
    handleLoadMeasurement: (id: number) => dispatch(loadMeasurement(id)),
    handleDeleteMeasurement: (id: number) => dispatch(showConfirmDialog({
        title: 'Smazat měření',
        text: `Opravdu chcete smazat měření?`,
        confirmAction: deleteMeasurement(id),
    })),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Detail) as ComponentClass<OuterProps>;
