import * as t from 'io-ts';

import {InvoiceDetailsSchema} from './InvoiceDetails';
import {EmailSchema} from 'types/generic/EmailSchema';

const optionalPart = t.partial({
    email: EmailSchema,
});
const requiredPart = t.interface({
    tag: t.literal('SupplierInvoiceDetails'),
});

export const SupplierInvoiceDetailsSchema = t.exact(t.intersection([
    InvoiceDetailsSchema.type,
    optionalPart,
    requiredPart,
]));

export interface SupplierInvoiceDetails extends t.TypeOf<typeof SupplierInvoiceDetailsSchema> {}
