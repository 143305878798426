import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {DataItemFormValues} from 'dataItem/types/DataItemFormValues';
import {PropsForForm} from 'forms/components/withForm';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {Components as Layout} from 'layout';

import {validate} from './validations';

interface OwnProps {
    initialValues?: DataItemFormValues;
    operatingProceduresOptions: SelectOptions<number>;
}

export type Props = PropsForForm<DataItemFormValues, OwnProps>;

const initialDataItemFormValues: DataItemFormValues = {
    name: '',
    numOfDecimalPlaces: '',
    minimum: '',
    maximum: '',
    measurementUnit: '',
    dilution: '',
    checkErrors: false,
    procedureIds: [],
};

class DataItemForm extends Component<Props> {
    render(): ReactNode {
        const {handleSubmit, submitting, operatingProceduresOptions, valid, pristine} = this.props;

        return <Layout.Panel>
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting || pristine} />
                <div className="row">
                    <div className="col-md-4">
                        <Fields.Input
                            name="name"
                            type="text"
                            label="Název"
                            disabled
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name="numOfDecimalPlaces"
                            type="number"
                            label="Desetinná místa*"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name="measurementUnit"
                            type="text"
                            label="Jednotka*"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Fields.Input
                            name="minimum"
                            type="number"
                            label="Minimum*"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name="maximum"
                            type="number"
                            label="Maximum*"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name="dilution"
                            type="number"
                            label="Ředění*"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Fields.Select<number>
                            name="procedureIds"
                            label="Operační postup"
                            options={operatingProceduresOptions}
                            multi
                            disabled
                        />
                    </div>
                    <div className="col-md-6 pt-3">
                        <Fields.Checkbox
                            name="checkErrors"
                            label="Kontrola chyb"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Buttons.Button
                            className="w-100 mt-4"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Uložit
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        </Layout.Panel>;
    }
}

export default Forms.withForm<DataItemFormValues, OwnProps, Props>(DataItemForm, {
    form: 'dataItem',
    validate,
    initialValues: initialDataItemFormValues,
});
