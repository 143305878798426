import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {
    extractFormErrorsFromResponse,
    showSuccess,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, startSubmitF, stopSubmitF, resetF} from 'utils/formHelpers';

import Api from '../api';
import {CreateUserAction} from '../actions';

const title = 'Vytvoření uživatele';

function* handleSuccessResponse(): SagaIterator {
    yield* put(showSuccess(title, 'Uživatel úspěšně vytvořen'));
    yield* put(stopSubmitF('createUser'));
    yield* put(routerActions.push('/users'));
    yield* put(resetF('createUser'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('createUser', extractFormErrorsFromResponse(response)));
}

export function* execute(_: CreateUserAction): SagaIterator {
    yield* put(startSubmitF('createUser'));

    const values = (yield* select(formValuesF('createUser'))).orCrash('missing form data');
    const response = yield* call(Api.createUser, values);

    yield* response.isSuccess
        ? call(handleSuccessResponse)
        : call(handleErrorResponse, response);
}

export function* createUserSaga(): SagaIterator {
    yield takeLatestF('user/CREATE_USER', execute);
}
