import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {routerActions} from 'connected-react-router';
import {convertStringToFloat, emptyStringToUndefined, takeLatestF} from 'favorlogic-utils';

import {UpdateSampleInOrderCreationAction} from 'order/actions';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {CreateSample} from 'types/model/samples/CreateSample';
import {formValuesF, changeF} from 'utils/formHelpers';

const orderForm = 'createOrder';
const sampleForm = 'createSample';
const samplesFieldName = 'samples';

export function prepareSample(sampleFormValues: CreateSampleFormValues, sampleOrder: number): CreateSample {
    if (!sampleFormValues.type || !sampleFormValues.harvestType) {
        throw new Error('Missing sample data.');
    }

    const samplingTemperature = sampleFormValues.samplingTemperature
        ? convertStringToFloat(sampleFormValues.samplingTemperature).orCrash('invalid sampling temperature')
        : undefined;
    const sampleSourceAmount = opt(sampleFormValues.sampleSourceAmount).chainToOpt(parseInt).orUndef();

    return {
        analysisIds: sampleFormValues.analysisIds,
        barcode: sampleFormValues.barcode,
        type: sampleFormValues.type,
        harvestType: sampleFormValues.harvestType,
        supplyChainId: opt(sampleFormValues.supplyChainId).orUndef(),
        milkRoomId: opt(sampleFormValues.milkRoomId).orUndef(),
        samplingDate: sampleFormValues.samplingDate,
        samplingTime: opt(sampleFormValues.samplingTime).map(emptyStringToUndefined).orUndef(),
        samplingTemperature,
        sampleSourceAmount,
        showResultsOnWeb: sampleFormValues.showResultsOnWeb,
        showResultsToBreeders: sampleFormValues.showResultsToBreeders,
        sampleOrder,
        qualitative: sampleFormValues.qualitative,
        cistern: sampleFormValues.cistern,
        control: sampleFormValues.control,
        subsidy: sampleFormValues.subsidy,
        customerNote: opt(sampleFormValues.customerNote).orUndef(),
        traitNote: opt(sampleFormValues.traitNote).orUndef(),
        tag: 'CreateSample',
    };
}

function* execute(action: UpdateSampleInOrderCreationAction): SagaIterator {
    const samples = (yield* select(formValuesF(orderForm)))
        .map(order => order.samples).orElse([]);
    const sampleIdx = action.payload;
    const sampleFormValues = (yield* select(formValuesF(sampleForm)))
        .orCrash('Missing sample data.');

    samples[sampleIdx] = prepareSample(sampleFormValues, samples[sampleIdx].sampleOrder);
    yield* put(changeF(orderForm, samplesFieldName, samples));
    yield* put(routerActions.goBack());
}

export function* updateSampleInOrderCreationSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_SAMPLE_IN_ORDER_CREATION', execute);
}
