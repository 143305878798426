import {NullableKeys} from 'favorlogic-utils';

import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';

export const CREATE_PICKUP_FORM_NAME = 'createPickupLineForm';
export const EDIT_PICKUP_FORM_NAME = 'editPickupLineForm';

export type WritePickupLineFormValues = NullableKeys<WritePickupLine>;

export const initialWritePickupLineFormValues: WritePickupLineFormValues = {
    name: null,
    customerIds: [],
    color: null,
};
