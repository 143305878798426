import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {UpdateOrderFormValues} from '../../types/UpdateOrderFormValues';

type Values = UpdateOrderFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('customerId', 'Zákazník');
    validator.nonEmpty('deliveryType', 'Typ odběru');
    validator.nonEmpty('customerDetails', 'Zákazník');
    validator.orderCustomerDetails('customerDetails');

    return validator.generateErrorsObject();
};
