import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {UserDetails} from 'types/model/users/UserDetails';

interface Props {
    user: UserDetails;

    deleteUser(userId: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {user} = this.props;

        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={`/users/${user.id}`}
                />
                <Buttons.RowActionButton
                    type="DELETE"
                    onClick={this.delete}
                />
            </div>
        );
    }

    private delete = () => {
        const {user, deleteUser} = this.props;

        deleteUser(user.id);
    };
}

export default RowActions;
