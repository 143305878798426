import {UnsafeBeFormErrors} from 'favorlogic-utils';

export function correctCustomerErrors(errors: UnsafeBeFormErrors): UnsafeBeFormErrors {
    const {customerName, ...otherErrors} = errors;

    return {
        ...otherErrors,
        ...customerName && {name: customerName},
    };
}
