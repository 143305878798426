import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
});

const requiredPart = t.interface({
    dataItemId: IntegerSchema,
    result: t.number,
});

export const MeasurementImportResultSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementImportResult extends t.TypeOf<typeof MeasurementImportResultSchema> {}
