import {Config} from 'app/Config';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {LoginFormValues} from '../../types/LoginFormValues';

type Values = LoginFormValues;

export const validate = (values: Values): Errors<Values> => {
    const cfg = Config.forms;
    const validator = new DairyValidator(values);

    validator.nonEmpty('username', 'Uživatelské jméno');
    validator.minStringLength('username', cfg.usernameMinLen, 'Uživatelské jméno');
    validator.nonEmpty('password', 'Heslo');
    validator.minStringLength('password', cfg.passwordMinLen, 'Heslo');

    return validator.generateErrorsObject();
};
