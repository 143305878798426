import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import withUser, {WithUserProps} from 'user/components/withUser';
import CreateOrderForm from '../components/CreateOrderForm';
import {isValidF, destroyF, resetF} from 'utils/formHelpers';
import LeavePage from 'layout/components/LeavePage';
import {selectSampleCreationType, setLastCreatedSample} from 'order/actions';
import {LEAVE_CREATING_ORDER_MESSAGE} from '../constants';

interface OuterProps {}

interface InnerProps {
    isOrderFormValid: boolean;

    handleResetCreateOrderForm(): void;
}

type UserProps = Pick<WithUserProps, 'isRoleAdmin' | 'isRoleLaborer'>;
type Props = InnerProps & OuterProps & UserProps;

class New extends Component<Props> {
    render(): ReactNode {
        const {
            isOrderFormValid,
            handleResetCreateOrderForm,
        } = this.props;

        return (
            <Layout.ItemPage
                title="Nová objednávka"
                backLabel="Zpět"
            >
                <CreateOrderForm
                    hideSubmitButton
                />

                <div className="w-100 d-flex justify-content-end mt-4">
                    <Buttons.RightIconButton
                        label="Potvrzení objednávky"
                        disabled={!isOrderFormValid}
                        to="summary"
                        icon="NEXT"
                    />
                </div>
                <LeavePage
                    canLeave={false}
                    except={['/orders/new', '/orders/new/summary']}
                    afterLeave={handleResetCreateOrderForm}
                    message={LEAVE_CREATING_ORDER_MESSAGE}
                />
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isOrderFormValid: isValidF('createOrder')(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleResetCreateOrderForm: () => {
        dispatch(destroyF('createOrder'));
        dispatch(resetF('orderCustomer'));
        dispatch(selectSampleCreationType());
        dispatch(setLastCreatedSample(null));
    },
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(New) as ComponentClass<OuterProps>;
