import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import AnalysisApi from 'analysis/api';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {handleResponseError} from 'utils/handleResponseError';
import {loadAnalysesSuccess} from 'analysis/actions';

import {genHasSameBillingAddress} from '../utils/genHasSameBillingAddress';
import {LoadCustomerAction, loadCustomerError, loadCustomerSuccess, prefillUpdateCustomerForm} from '../actions';
import Api from '../api';
import {UpdateCustomerFormValues} from '../types/UpdateCustomerFormValues';

export function prepareUpdateCustomerFormValues(cd: CustomerDetailsForLaborer): UpdateCustomerFormValues {
    return {
        code: cd.priceClass === 'CONTRACTUAL' ? cd.code : undefined,
        note: cd.note,
        priceClass: cd.priceClass,
        firstName: cd.firstName,
        lastName: cd.lastName,
        invoiceDetails: cd.invoiceDetails,
        address: cd.address,
        billingAddress: cd.billingAddress,
        hasSameBillingAddress: genHasSameBillingAddress(cd),
        showResultsOnWeb: cd.showResultsOnWeb,
        showResultsToBreeders: cd.showResultsToBreeders,
        name: cd.name,
        hasNoIco: !cd.invoiceDetails.ico,
    };
}

function* execute(action: LoadCustomerAction): SagaIterator {
    const errorTitle = 'Načtení zákazníka';
    const customerResponse = yield* call(Api.getCustomerForLaborer, action.payload.id);

    if (!customerResponse.isSuccess) {
        const error = customerResponse.data as ErrorsFromBE;

        yield* call(handleResponseError, customerResponse, errorTitle);
        return yield* put(loadCustomerError(error));
    }

    const analysesResponse = yield* call(AnalysisApi.getAnalyses);

    if (!analysesResponse.isSuccess) {
        const error = analysesResponse.data as ErrorsFromBE;

        yield* call(handleResponseError, analysesResponse, 'Načtení rozborů');
        return yield* put(loadCustomerError(error));
    }

    yield* put(loadCustomerSuccess(customerResponse.data));
    yield* put(loadAnalysesSuccess(analysesResponse.data));
    yield* put(prefillUpdateCustomerForm(prepareUpdateCustomerFormValues(customerResponse.data)));
}

export function* loadCustomerSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CUSTOMER', execute);
}
