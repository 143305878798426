import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {
    downloadFileFromResponse,
    SuccessResponse,
    takeLatestF,
    ErrorsFromBE,
    ErrorResponse,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';

import Api from '../api';
import {downloadShippingTicketError, downloadShippingTicketSuccess, DownloadShippingTicketAction} from '../actions';

const title = 'Stažení přepravního lístku';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(downloadShippingTicketError(error));
    yield* put(isLoading(false));
}

function* handleSuccessResponse(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* put(downloadShippingTicketSuccess());
    yield* call(downloadFileFromResponse, response);
    yield* put(isLoading(false));
    yield* put(routerActions.push('/orders'));
}

function* execute(action: DownloadShippingTicketAction): SagaIterator {
    yield* put(isLoading(true));

    const response = yield* call(Api.getShippingTicket, action.payload);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* downloadShippingTicketSaga(): SagaIterator {
    yield takeLatestF('order/DOWNLOAD_SHIPPING_TICKET', execute);
}
