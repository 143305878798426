import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {EmailSchema} from 'types/generic/EmailSchema';
import {PriceClassSchema} from '../enumeration/PriceClass';

const optionalPart = t.partial({
    code: t.string,
    phone: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    firstName: t.string,
    lastName: t.string,
    name: t.string,
    email: EmailSchema,
    priceClass: PriceClassSchema,
});

export const CustomerViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CustomerView extends t.TypeOf<typeof CustomerViewSchema> {}
