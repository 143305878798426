import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {UpdateSamplesFormValues} from 'sample/types/UpdateSamplesFormValues';
import {validate} from './validations';

interface OwnProps {}

type Props = PropsForForm<UpdateSamplesFormValues, OwnProps>;

class UpdateSamplesForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />
                    <div className="col-12 col-md-4">
                        <Fields.DateTime
                            className="mt-2"
                            name="samplingDate"
                            mode="DATE"
                            label="Datum odběru"
                        />
                    </div>

                    <div className="col-12 col-md-4">
                        <Fields.DateTime
                            className="mt-2"
                            name="samplingTime"
                            mode="TIME"
                            label="Čas odběru"
                        />
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="d-flex justify-content-end mt-4">
                            <Buttons.Button
                                fullWidth
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Odeslat pro všechny
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateSamplesFormValues, OwnProps, Props>(UpdateSamplesForm, {
    form: 'updateSamples',
    validate,
    initialValues: {
        samplingDate: '',
        samplingTime: '',
    },
});
