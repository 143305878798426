import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {UpdateSupplierFormValues} from '../../types/UpdateSupplierFormValues';
import {asyncValidate, validate} from '../SupplierForm/validations';

interface OwnProps {
    hasNoIco: boolean;
    isAresLoading: boolean;

    onIcoSearch(): void;
}

type Props = PropsForForm<UpdateSupplierFormValues, OwnProps>;

class UpdateSupplierForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            onIcoSearch,
            hasNoIco,
            autofillUnsafe,
            isAresLoading,
            pristine,
        } = this.props;
        const onHasNoIcoChange = () => {
            autofillUnsafe('invoiceDetails.ico', '');
            autofillUnsafe('invoiceDetails.dic', '');
        };

        return (
            <Layout.Panel>
                <Form onSubmit={handleSubmit}>
                    <Layout.Loader show={submitting || pristine} />
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Základní údaje</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.email"
                                type="text"
                                label="E-mail"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.phone"
                                type="text"
                                label="Telefon"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="note"
                                type="text"
                                label="Poznámka"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.ico"
                                type="text"
                                label="IČO"
                                onAction={onIcoSearch}
                                onFieldBlur={onIcoSearch}
                                disabled={hasNoIco || isAresLoading}
                                fieldIsLoading={isAresLoading}
                            />
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasNoIco"
                                onFieldChange={onHasNoIcoChange}
                                label="Nemám ičo"
                                disabled={isAresLoading}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.dic"
                                type="text"
                                label="DIČ"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="name"
                                type="text"
                                label="Název firmy/družstva*"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Adresa</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.street"
                                type="text"
                                label="Ulice"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.city"
                                type="text"
                                label="Město"
                                disabled={!hasNoIco}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="address.zip"
                                type="text"
                                label="Směrovací číslo"
                                disabled={!hasNoIco}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Buttons.Button
                                className="w-100 mt-4"
                                importance="primary"
                                disabled={!valid || isAresLoading || submitting}
                                type="submit"
                            >
                                    Uložit
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateSupplierFormValues, OwnProps, Props>(UpdateSupplierForm, {
    form: 'updateSupplier',
    validate: validate({isCreate: false}),
    asyncValidate: asyncValidate({isCreate: false}),
    asyncBlurFields: [
        'name',
        'invoiceDetails.email',
        'invoiceDetails.ico',
        'invoiceDetails.dic',
    ],
    initialValues: {
        invoiceDetails: {email: '', tag: 'SupplierInvoiceDetails'},
        address: {city: '', street: '', zip: ''},
        name: '',
        hasNoIco: false,
    },
});
