import * as t from 'io-ts';
import {PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {Ares, AresSchema} from 'types/model/ares/Ares';
import {CreateCustomer, CreateCustomerSchema} from 'types/model/customers/CreateCustomer';
import {CustomerDetails, CustomerDetailsSchema} from 'types/model/customers/CustomerDetails';
import {
    CustomerDetailsForLaborer,
    CustomerDetailsForLaborerSchema,
} from 'types/model/customers/CustomerDetailsForLaborer';
import {CustomerValidation, CustomerValidationSchema} from 'types/model/customers/CustomerValidation';
import {UpdateCustomer, UpdateCustomerSchema} from 'types/model/customers/UpdateCustomer';
import {CustomersParams} from 'user/types/CustomersParams';
import {UserDetails, UserDetailsSchema} from 'types/model/users/UserDetails';
import {UserLoginDetails, UserLoginDetailsSchema} from 'types/model/users/UserLoginDetails';
import {CustomersPage, CustomersPageSchema} from 'types/model/customers/CustomerPage';
import {MilkRoomSchema, MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {CreateUser, CreateUserSchema} from 'types/model/users/CreateUser';
import {Customer, CustomerSchema} from 'types/model/customers/Customer';
import {PasswordReset, PasswordResetSchema} from 'types/model/customers/PasswordReset';
import {UuidDetailsSchema} from 'types/model/users/UuidDetail';
import {EmailDetailsSchema} from 'types/model/users/EmailDetails';
import {Email} from 'types/generic/EmailSchema';
import {Uuid} from 'types/model/common/Uuid';
import {UpdatePasswordSchema} from 'types/model/customers/UpdatePassword';
import {UpdateUser, UpdateUserSchema} from 'types/model/users/UpdateUser';
import {UserDetailsForCustomer, UserDetailsForCustomerSchema} from 'types/model/users/UserDetailsForCustomer';
import {apiRequester} from 'app/api';

class Api {
    getCurrent(): PResponse<UserDetails> {
        return apiRequester.makeRequest({
            url: '/user',
            requestSchema: t.void,
            responseSchema: UserDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    signUp(data: CreateCustomer): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/account/sign-up',
            method: 'post',
            data,
            requestSchema: CreateCustomerSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    login(data: UserLoginDetails): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/account/login',
            method: 'post',
            data,
            requestSchema: UserLoginDetailsSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    logout(): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/account/logout',
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    updateCustomer(data: UpdateCustomer, customerId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/customers/${customerId}`,
            method: 'put',
            data,
            requestSchema: UpdateCustomerSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getCustomer(customerId: number): PResponse<CustomerDetails> {
        return apiRequester.makeRequest({
            url: `/customers/${customerId}`,
            requestSchema: t.void,
            responseSchema: CustomerDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    getCustomerForLaborer(customerId: number): PResponse<CustomerDetailsForLaborer> {
        return apiRequester.makeRequest({
            url: `/customers/${customerId}`,
            requestSchema: t.void,
            responseSchema: CustomerDetailsForLaborerSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    validateCustomer(data: CustomerValidation): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/customers/validate',
            method: 'post',
            data,
            requestSchema: CustomerValidationSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getCustomers(params: CustomersParams): PResponse<CustomersPage> {
        return apiRequester.makeRequest({
            url: '/customers',
            requestSchema: t.void,
            responseSchema: CustomersPageSchema,
            params,
            expectedStatus: StatusCodes.OK,
        });
    }

    getCustomerList(): PResponse<Customer[]> {
        return apiRequester.makeRequest({
            url: '/customers-list',
            requestSchema: t.void,
            responseSchema: t.array(CustomerSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getLaborers(): PResponse<UserDetails[]> {
        return apiRequester.makeRequest({
            url: '/laborers',
            requestSchema: t.void,
            responseSchema: t.array(UserDetailsSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getCustomerMilkrooms(customerId: number): PResponse<MilkRoom[]> {
        return apiRequester.makeRequest({
            url: `/customers/${customerId}/milk-rooms`,
            requestSchema: t.void,
            responseSchema: t.array(MilkRoomSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getAres(ico: string): PResponse<Ares | null> {
        return apiRequester.makeRequest({
            url: `/public/ares/${ico}`,
            requestSchema: t.void,
            responseSchema: t.union([AresSchema, t.null]),
            expectedStatus: StatusCodes.OK,
        });
    }

    createUser(data: CreateUser): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/users',
            method: 'post',
            data,
            requestSchema: CreateUserSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    getUsers(): PResponse<UserDetails[]> {
        return apiRequester.makeRequest({
            url: '/users',
            method: 'get',
            requestSchema: t.void,
            responseSchema: t.array(UserDetailsSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    deleteUser(userId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/users/${userId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    resetPasswordEmail(email: Email): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/account/password-reset-email`,
            method: 'post',
            data: {
                email,
            },
            requestSchema: EmailDetailsSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    validateResetPassword(uuid: Uuid): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/account/password-reset/validate`,
            method: 'post',
            data: {
                uuid,
            },
            requestSchema: UuidDetailsSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    resetPassword(passwordReset: PasswordReset): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/account/password-reset`,
            method: 'post',
            data: {
                ...passwordReset,
            },
            requestSchema: PasswordResetSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    changeCustomerPassword(customerId: number, password: string): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/customers/${customerId}/password`,
            method: 'put',
            data: {password},
            requestSchema: UpdatePasswordSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getUser(userId: number): PResponse<UserDetailsForCustomer> {
        return apiRequester.makeRequest({
            url: `/users/${userId}`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: UserDetailsForCustomerSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateUser(userId: number, user: UpdateUser): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/users/${userId}`,
            method: 'put',
            data: user,
            requestSchema: UpdateUserSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
