import * as t from 'io-ts';
import {DateStringSchema} from 'favorlogic-utils';

const requiredPart = t.interface({
    pickupDate: DateStringSchema,
    count: t.number,
});

export const PickupLineAnalysisDaySchema = t.exact(requiredPart);

export interface PickupLineAnalysisDay extends t.TypeOf<typeof PickupLineAnalysisDaySchema> {}
