import {SelectOptions} from 'forms/components/BasicSelect';
import {AnalysisAvailability} from 'types/model/enumeration/AnalysisAvailability';

export const availabilityOptions: SelectOptions<AnalysisAvailability> = [
    {
        label: 'Všichni',
        value: 'ALL',
    },
    {
        label: 'Smluvní',
        value: 'CONTRACTUAL',
    },
    {
        label: 'Nesmluvní',
        value: 'NON_CONTRACTUAL',
    },
];
