import {opt} from 'ts-opt';
import {formatDate} from 'favorlogic-utils';

import {SampleSearch} from 'types/model/samples/SampleSearch';
import {SelectOptions} from 'forms/components/BasicSelect';

export const genSampleOptions = (samples: SampleSearch[] | null): SelectOptions<number> =>
    opt(samples).orElse([]).map((sample) => ({
        label: `${sample.sequenceNumber} / ${sample.barcode} / ${formatDate(sample.accepted).orElse('-')}`,
        value: sample.id,
    }));

export const genSampleBarcodeOptions = (samples: SampleSearch[] | null): SelectOptions<string> =>
    opt(samples).orElse([]).map((sample) => ({
        label: sample.barcode,
        value: sample.barcode,
    }));
