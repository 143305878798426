import * as t from 'io-ts';

import {PasswordSchema} from 'types/model/common/Password';

const optionalPart = t.partial({});

const requiredPart = t.interface({
    password: PasswordSchema,
});

export const UpdatePasswordSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdatePassword extends t.TypeOf<typeof UpdatePasswordSchema> {}
