import {GenerateProtocolFormValues} from 'order/types/GenerateProtocolFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

type Values = GenerateProtocolFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('processedFrom', 'Zpracovávání od');
    validator.nonEmpty('processedTo', 'Zpracovávání do');

    return validator.generateErrorsObject();
};
