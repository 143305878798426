import React, {FunctionComponent, memo, ReactNode, useCallback} from 'react';

import {Table} from 'tables/components';
import {Column, ColumnType} from 'tables/types/Column';
import {RowActionButton} from 'buttons/components';
import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';
import {PickupLine} from 'types/model/pickupLine/PickupLine';
import {getShortenedTextWithTooltip} from 'utils/getShortenedTextWithTooltip';

import {RowActions} from './RowActions';

interface Props {
    pickupLine: PickupLine;
    toggleAddCustomerModalVisibility(pickupLineId: number): void;
    updatePickupLine(pickupLineId: number, data: WritePickupLine): void;
}

interface PickupLineRow {
    name: ReactNode;
    actions: ReactNode;
}

const PickupLinesTableBase: FunctionComponent<Props> = props => {
    const {pickupLine, toggleAddCustomerModalVisibility, updatePickupLine} = props;
    const {id, name, customers, color} = pickupLine;

    const onAddCustomerButtonClick = useCallback(() => {
        toggleAddCustomerModalVisibility(id);
    }, [id, toggleAddCustomerModalVisibility]);

    const onCustomerDelete = useCallback((customerId: number) => {
        updatePickupLine(id, {
            name,
            customerIds: customers.filter(x => x.id !== customerId).map(x => x.id),
            color});
    }, [id, name, customers, color, updatePickupLine]);

    const columns: Column<PickupLineRow>[] = [
        {
            accessor: 'name',
            header: 'Zákazník',
            type: ColumnType.Node,
            size: 'medium',
        },
        {
            accessor: 'actions',
            header:
            <RowActionButton
                type="SELECT"
                title="Přidat zákazníka"
                onClick={onAddCustomerButtonClick}
            />,
            type: ColumnType.Node,
            size: 'narrow',
        },
    ];

    return (
        <Table
            columns={columns}
            rows={customers.map(x => ({
                id: x.id,
                name: getShortenedTextWithTooltip(x.name, 20),
                actions:
                    <RowActions
                        customer={x}
                        onCustomerDelete={onCustomerDelete}
                        disabled={customers.length < 2}
                    />,
            }))}
            emptyMessage="Nebyly vytvořeny žádné svozové linky."
        />
    );
};

export const PickupLineTable = memo(PickupLinesTableBase);
