import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';

import {SearchOrderSamplesBarcodeAction, setOrderSearches} from '../actions';
import Api from '../api';

export function* execute({payload: {barcode}}: SearchOrderSamplesBarcodeAction): SagaIterator {
    const errorTitle = 'Vyhledání čárového kódu vzorku';

    yield* put(isLoading(true));

    const response = yield* call(Api.searchOrders, barcode);

    yield* put(isLoading(false));

    if (!response.isSuccess) {
        return yield* call(handleResponseError, response, errorTitle);
    }

    yield* put(setOrderSearches(response.data));
}

export function* searchOrderSamplesBarcodeSaga(): SagaIterator {
    yield takeLatestF('order/SEARCH_ORDER_SAMPLES_BARCODE', execute);
}
