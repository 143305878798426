import React, {Component, ReactNode, SyntheticEvent} from 'react';

import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

interface Props {
    label?: string;
    id: string;
    inactive?: boolean;
    disabled?: boolean;

    onUpload(file: File): void;
}

class UploadButton extends Component<Props> {
    onFileChange = (evt: SyntheticEvent<HTMLInputElement>): void => {
        const {onUpload} = this.props;

        const elem = evt.currentTarget;
        if (!elem.files || !elem.files[0]) { throw new Error('Missing file on input.'); }
        const file = elem.files.item(0);
        if (file) {
            onUpload(file);
            elem.value = '';
        }
    };

    render(): ReactNode {
        const {label, id, inactive, disabled} = this.props;
        const labelId = disabled ? '' : id;
        const finalClassName = classNames(
            'mb-0 text-nowrap',
            inactive ? styles.inactive : styles.uploadButton,
            styles.uploadButton,
            disabled ? styles.disabled : '',
        );

        return <span className="d-inline-block">
            <label
                htmlFor={labelId}
                className={finalClassName}
            >
                {label || 'Nahrát'}
            </label>
            <input
                type="file"
                className="d-none"
                id={id}
                onChange={this.onFileChange}
            />
        </span>;
    }
}

export default UploadButton;
