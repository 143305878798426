import React, {ChangeEvent, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';
import {none, opt} from 'ts-opt';
import {ColorResult, SketchPicker} from 'react-color';

import {SvgIcon} from 'icons/components';

import {ComponentForWrapping} from '../Field/ComponentForWrapping';
import HelpText from '../HelpText';
import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';

import inputStyles from '../Input/styles.sass';
import styles from './styles.sass';
import deleteSvg from './delete.svg';

type FieldValue = string;

interface OwnProps {}

export type ColorPickerOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type ColorPickerInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

interface State {
    colorPickerVisible: boolean;
}

class ColorPicker extends ComponentForWrapping<ColorPickerInnerProps, FieldValue, State> {
    state: State = {
        colorPickerVisible: false,
    };

    render(): ReactNode {
        const {
            className,
            label,
            helpText,
            input,
            meta,
            disabled,
        } = this.props;
        const {value, name} = input;
        const {asyncValidating, submitting} = meta;
        const nonEmpty = Boolean(input?.value);
        const classes = classNames('form-group', 'w-100', 'position-relative', className);
        const inputClasses = classNames('form-control', 'form-custom', nonEmpty && inputStyles['non-empty']);
        const {colorPickerVisible} = this.state;

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>
                <div className={inputStyles['helper-pt15']} />
                <input
                    className={inputClasses}
                    id={name}
                    value={this.getFormValue().orElse('')}
                    disabled={asyncValidating || submitting || disabled}
                    onClick={this.handleOpenColorPicker}
                    onChange={this.handleInputChange}
                />
                {value &&
                    <div
                        onClick={this.handleDeleteClick}
                        className={styles.withDeleteButton}
                    >
                        <SvgIcon icon={deleteSvg} />
                    </div>
                }
                {colorPickerVisible &&
                    <div className={styles.popover}>
                        <div
                            className={styles.cover}
                            onClick={this.handleCloseColorPicker}
                        />
                        <SketchPicker
                            color={this.getFormValue().orElse('')}
                            onChange={this.handleColorPickerChange}
                            onChangeComplete={this.handleColorPickerChange}
                        />
                    </div>
                }
                <div className={inputStyles['animated-underline']} />
                <div className={inputStyles['form-placeholder']}>{label}</div>
            </div>
        );
    }

    private handleOpenColorPicker = () => {
        const {input: {value}} = this.props;
        this.setState({colorPickerVisible: true});
        this.handleFocus(opt(value));
    };

    private handleCloseColorPicker = () => {
        const {input: {value}} = this.props;
        this.setState({colorPickerVisible: false});
        this.handleBlur(opt(value));
    };

    private handleColorPickerChange = (value: ColorResult) => {
        this.handleChange(opt(value.hex));
    };

    private handleDeleteClick = () => {
        this.handleChange(none);
        this.handleBlur(none);
    };

    private handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.handleChange(opt(e.target.value));
    }
}

export default ColorPicker;
