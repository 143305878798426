import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {Device} from 'types/model/devices/Device';

interface Props {
    device: Device;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {device} = this.props;
        const {id} = device;
        const editUrl = `/administration/devices/${id}/edit`;

        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={editUrl}
                />
            </div>
        );
    }
}

export default RowActions;
