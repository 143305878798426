import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadSuppliersError, loadSuppliersSuccess, LoadSuppliersAction} from '../actions';
import Api from '../api';

function* execute(action: LoadSuppliersAction): SagaIterator {
    const errorTitle = 'Načtení dodavatelů';
    const response = yield* call(Api.getSuppliers, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadSuppliersError(error));
    }

    yield* put(loadSuppliersSuccess(response.data));
}

export function* loadSuppliersSaga(): SagaIterator {
    yield takeLatestF('supplier/LOAD_SUPPLIERS', execute);
}
