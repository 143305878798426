import * as t from 'io-ts';

import {OrderStateSchema} from 'types/model/enumeration/OrderState';
import {UpdateOrderBaseSchema} from './UpdateOrderBase';

const optionalPart = t.partial({
    internNote: t.string,
    state: OrderStateSchema,
});
const requiredPart = t.interface({
    tag: t.literal('UpdateOrderByLaborer'),
});

export const UpdateOrderByLaborerSchema = t.exact(t.intersection([
    UpdateOrderBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateOrderByLaborer extends t.TypeOf<typeof UpdateOrderByLaborerSchema> {}
