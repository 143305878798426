exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-tables-components-RowActions-___styles__table-row-actions___1FLmj svg {\n  opacity: 0.8;\n  stroke: #7F9A56;\n  fill: none; }\n\n.src-modules-tables-components-RowActions-___styles__table-row-actions___1FLmj svg path {\n  fill: none; }\n\n.src-modules-tables-components-RowActions-___styles__disabled___3YkzC svg {\n  stroke: #ddd !important; }\n", ""]);

// exports
exports.locals = {
	"table-row-actions": "src-modules-tables-components-RowActions-___styles__table-row-actions___1FLmj",
	"disabled": "src-modules-tables-components-RowActions-___styles__disabled___3YkzC"
};