import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {IcoSchema} from 'types/generic/IcoSchema';
import {DicSchema} from 'types/generic/DicSchema';
import {OptionalAddressSchema} from '../common/OptionalAddress';

const optionalPart = t.partial({
    ico: IcoSchema,
    dic: DicSchema,
    note: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    name: t.string,
    address: OptionalAddressSchema,
    canDelete: t.boolean,
});

export const SupplierViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SupplierView extends t.TypeOf<typeof SupplierViewSchema> {}
