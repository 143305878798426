import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {Analysis} from 'types/model/analyses/Analysis';

interface Props {
    analysis: Analysis;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {analysis} = this.props;
        const {id} = analysis;
        const editUrl = `/administration/analyses/${id}/edit`;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={editUrl}
                />
            </div>
        );
    }
}

export default RowActions;
