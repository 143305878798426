import {opt, Opt} from 'ts-opt';

import {DataItem} from 'types/model/dataItems/DataItem';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

const filterProceduresByDataItem = (
    dataItem: DataItem,
    procedures: OperatingProcedure[],
): OperatingProcedure[] => {
    const procedureIds =  dataItem.procedures.map(x => x.procedureId);

    return procedures.filter(procedure => procedureIds.includes(procedure.id));
};

export const getProceduresByDataItem = (
    procedures: OperatingProcedure[] | null,
    dataItem: Opt<DataItem>,
): OperatingProcedure[] =>
    opt(procedures)
        .zip(dataItem)
        .map(([procedures, dataItem]) => filterProceduresByDataItem(dataItem, procedures))
        .orElse([]);
