import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {difference, filter, flatten, flow, isEmpty, map, negate} from 'lodash/fp';
import {match as Match} from 'react-router';
import {Opt, opt, optEmptyArray} from 'ts-opt';
import {routerActions} from 'connected-react-router';

import {SelectOptions} from 'forms/components/BasicSelect';
import {CreateSample} from 'types/model/samples/CreateSample';
import withUser, {WithUserProps} from 'user/components/withUser';
import {StoreState} from 'app/types/StoreState';
import {loadAnalyses} from 'analysis/actions';
import {StoreFormState} from 'app/types/StoreFormState';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {formValuesF, destroyF, resetF} from 'utils/formHelpers';
import {Analysis} from 'types/model/analyses/Analysis';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import LeavePage from 'layout/components/LeavePage';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {Components as Layout} from 'layout';

import {
    prefillCreateSampleForm,
    setAnalysesBySelectedDataItems,
    updateSampleInOrderCreation,
    setLastCreatedSample,
} from '../actions';
import {LEAVE_CREATING_ORDER_MESSAGE} from '../constants';
import {getMilkRoomsOptions, getAnalysesOptions} from '../utils/selectOptions';
import {CreateOrderFormValues} from '../types/CreateOrderFormValues';
import CreateSampleForm from '../components/CreateSampleForm';
import {
    getOrderCustomer,
    getPriceClassForCustomer,
    enableShowResultsByCustomer,
    enableQCZByCustomer,
} from '../utils/orderUtils';
import {prepareCreateSampleFormValues} from '../utils/prepareCreateSampleFormValues';

interface OuterProps {
}

interface StateProps {
    analyses: Analysis[];
    order: Opt<CreateOrderFormValues>;
    milkRooms: MilkRoom[];
    customerMilkRooms: MilkRoom[] | null;
    analysesBySelectedDataItems: Analysis[];
    createSampleFormData: Opt<CreateSampleFormValues>;
    customerForOrder: CustomerDetailsForLaborer | null;
}

interface DispatchProps {
    handleUpdateSample(idx: number): void;
    handleLoadAnalyses(): void;
    handleSetAnalysesBySelectedDataItems(analyses: Analysis[]): void;
    handlePrefillCreateSampleForm(sample: CreateSample): void;
    goToNewOrder(): void;
    handleResetCreateOrderForm(): void;
}

type UserProps = Pick<WithUserProps,
    'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentCustomer'>;
type RouterProps = {match: Match<{sampleIdx: string}>};
type InnerProps = StateProps & DispatchProps & UserProps & RouterProps;
type Props = InnerProps & OuterProps;

class NewEditSample extends Component<Props> {
    private get sample(): Opt<CreateSample> {
        const {order} = this.props;

        return order.map(o => o.samples[this.sampleIdx]);
    }

    private get barcode():  string | undefined {
        return this.sample.map(s => s.barcode).orUndef();
    }

    private get sampleIdx(): number {
        const {match: {params}} = this.props;

        return Number(params.sampleIdx);
    }

    private get orderCustomer(): Opt<CustomerDetails> {
        const {currentCustomer, customerForOrder} = this.props;

        return getOrderCustomer(currentCustomer, opt(customerForOrder), this.isAdminOrLaborer);
    }

    private get priceClass(): PriceClass | undefined {
        return getPriceClassForCustomer(this.orderCustomer);
    }

    private get enableShowResults(): boolean {
        return enableShowResultsByCustomer(this.orderCustomer);
    }

    private get isDairy(): boolean {
        const {isRoleDairy, isRoleDairyEmployee} = this.props;

        return isRoleDairy || isRoleDairyEmployee;
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    private get showQCZ(): boolean {
        return enableQCZByCustomer(this.orderCustomer);
    }

    private get showTraits(): boolean {
        return this.isAdminOrLaborer || this.isDairy;
    }

    componentDidMount(): void {
        const {
            handleLoadAnalyses,
            handlePrefillCreateSampleForm,
        } = this.props;

        this.sample.onSome(s => {
            handleLoadAnalyses();
            handlePrefillCreateSampleForm(s);
        });
    }

    render(): ReactNode {
        const {
            order,
            goToNewOrder,
            createSampleFormData,
            handleResetCreateOrderForm,
        } = this.props;

        if (order.isEmpty) {
            goToNewOrder();
            return null;
        }

        return (
            <Layout.ItemPage
                title="Úprava vzorku"
                backLabel="Nová objednávka"
            >
                <CreateSampleForm
                    formValues={createSampleFormData.orUndef()}
                    onSubmit={this.handleSubmit}
                    analysesOptions={this.getAnalysesOptions()}
                    milkRoomsOptions={this.getMilkRoomsOptions()}
                    sampleBarcodeIds={this.getBarcodes()}
                    handleLoadAnalysesOptions={this.handleLoadAnalysesOptions}
                    enableShowResults={this.enableShowResults}
                    showTraits={this.showTraits}
                    allowQCZ={this.showQCZ}
                />
                <LeavePage
                    canLeave={false}
                    except={['/orders/new']}
                    afterLeave={handleResetCreateOrderForm}
                    message={LEAVE_CREATING_ORDER_MESSAGE}
                />
            </Layout.ItemPage>
        );
    }

    private readonly handleSubmit = () => {
        const {handleUpdateSample} = this.props;

        handleUpdateSample(this.sampleIdx);
    };

    private readonly handleLoadAnalysesOptions = (ids: number[]): void => {
        const {
            handleSetAnalysesBySelectedDataItems,
            analyses,
        } = this.props;
        if (isEmpty(ids)) {
            handleSetAnalysesBySelectedDataItems([]);
        } else {
            const dataItemIdsBySelectedAnalyses: number[][] = flow(
                map(id => filter((a) => a.id === id, analyses)),
                flatten,
                map((a) => a.dataItemIds),
            )(ids);

            const includeSameDataItemIds = (analysisIds: number[]): boolean => flow(
                map((x: number[]) => analysisIds !== x && difference(analysisIds, x)),
                filter((x: number[]) => x.length === 0),
                negate(isEmpty),
            )(dataItemIdsBySelectedAnalyses);

            const filteredAnalyses: Analysis[] =
                filter(a => !includeSameDataItemIds(a.dataItemIds), analyses);
            handleSetAnalysesBySelectedDataItems(filteredAnalyses);
        }
    };

    private getMilkRoomsOptions(): SelectOptions<number> | undefined {
        const {milkRooms, customerMilkRooms} = this.props;

        return this.isAdminOrLaborer
            ? getMilkRoomsOptions(customerMilkRooms)
            : getMilkRoomsOptions(milkRooms);
    }

    private getAnalysesOptions(): SelectOptions<number> {
        const {analyses, analysesBySelectedDataItems} = this.props;
        const filteredAnalyses = optEmptyArray(analysesBySelectedDataItems)
            .orElse(analyses);

        return getAnalysesOptions(filteredAnalyses, this.priceClass);
    }

    private getBarcodes(): string[] {
        const {order} = this.props;
        const {samples} = order.orCrash('missing order data');

        return map(x => x.barcode, samples)
            .filter(x => x !== this.barcode);
    }
}

const createFormName: keyof StoreFormState = 'createOrder';
const createSampleFormName: keyof StoreFormState = 'createSample';

const mapStateToProps = (state: StoreState): StateProps => ({
    order: formValuesF(createFormName)(state),
    createSampleFormData: formValuesF(createSampleFormName)(state),
    milkRooms: state.supplier.milkRooms || [],
    customerMilkRooms: state.user.customerMilkRooms.orNull(),
    analyses: state.analysis.analyses || [],
    analysesBySelectedDataItems: state.order.analysesBySelectedDataItems || [],
    customerForOrder: state.order.customer,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleUpdateSample: (idx: number) => dispatch(updateSampleInOrderCreation(idx)),
    handleSetAnalysesBySelectedDataItems: (analyses: Analysis[]) =>
        dispatch(setAnalysesBySelectedDataItems(analyses)),
    handlePrefillCreateSampleForm: (sample: CreateSample) =>
        dispatch(prefillCreateSampleForm(prepareCreateSampleFormValues(sample))),
    goToNewOrder: () => dispatch(routerActions.push('/orders/new')),
    handleResetCreateOrderForm: () => {
        dispatch(destroyF('createOrder'));
        dispatch(resetF('orderCustomer'));
        dispatch(setLastCreatedSample(null));
    },
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(NewEditSample) as ComponentClass<OuterProps>;
