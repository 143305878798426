exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-tables-components-___styles__wide___2hHy3 {\n  min-width: 18rem !important; }\n\n.src-modules-tables-components-___styles__medium___2zJGQ {\n  min-width: 12rem !important;\n  max-width: 16rem !important; }\n\n.src-modules-tables-components-___styles__normal___34cwa {\n  min-width: 6rem !important;\n  max-width: 10rem !important; }\n\n.src-modules-tables-components-___styles__narrow___302hl {\n  min-width: 2rem !important;\n  max-width: 4rem !important; }\n", ""]);

// exports
exports.locals = {
	"wide": "src-modules-tables-components-___styles__wide___2hHy3",
	"medium": "src-modules-tables-components-___styles__medium___2zJGQ",
	"normal": "src-modules-tables-components-___styles__normal___34cwa",
	"narrow": "src-modules-tables-components-___styles__narrow___302hl"
};