import * as t from 'io-ts';
import {ArrayBufferSchema, PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';

import {apiRequester} from 'app/api';

import {SyncCmschParams} from './types/SyncCmschParams';
import {StatusCodes} from 'http-status-codes';

class Api {
    syncCmsch(params: SyncCmschParams): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/synchronization/cmsch-ws',
            method: 'post',
            params,
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    generateQCZProtocol(yearMonth: string): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/subsidy',
            method: 'get',
            params: {yearMonth},
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    generatePreviewQCZProtocol(yearMonth: string): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/subsidy/preview',
            method: 'get',
            params: {yearMonth},
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    generateQCZProtocolByCustomers(yearMonth: string): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/subsidy-by-customers',
            method: 'get',
            params: {yearMonth},
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    generatePreviewQCZProtocolByCustomers(yearMonth: string): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/subsidy-by-customers/preview',
            method: 'get',
            params: {yearMonth},
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    massGenerateProtocols(): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/protocols/approve-all',
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    generatePreviewProtocol(): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/preview-summary',
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }
}

export default new Api();
