import * as t from 'io-ts';

import {SampleBaseSchema} from './SampleBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('UpdateSampleByCustomer'),
});

export const UpdateSampleByCustomerSchema = t.exact(t.intersection([
    SampleBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateSampleByCustomer extends t.TypeOf<typeof UpdateSampleByCustomerSchema> {}
