import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {loadMilkRooms} from 'supplier/actions';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import withUser from 'user/components/withUser';
import {deleteSupplyChain, loadSupplyChains} from '../actions';
import SupplyChainsTable from '../components/SupplyChainsTable';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface StateProps {
    supplyChains: SupplyChain[] | null;
    milkRooms: MilkRoom[] | null;
}

interface DispatchProps {
    handleLoadSupplyChains(): void;
    handleLoadMilkRooms(): void;
    handleDeleteSupplyChain(id: number): void;
}

type Props = OuterProps & StateProps & DispatchProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {handleLoadSupplyChains, handleLoadMilkRooms} = this.props;

        handleLoadMilkRooms();
        handleLoadSupplyChains();
    }

    render(): ReactNode {
        const {
            supplyChains,
            milkRooms,
            handleDeleteSupplyChain,
        } = this.props;

        if (milkRooms && isEmpty(milkRooms)) {
            return this.renderNoMilkrooms();
        }

        if (supplyChains && isEmpty(supplyChains)) {
            return this.renderNoSupplyChains();
        }

        return (
            <Layout.ListPage
                title="Seznam linek"
                toolbar={this.renderToolbar()}
                fluid
            >
                <SupplyChainsTable
                    supplyChains={supplyChains}
                    handleDelete={handleDeleteSupplyChain}
                />
            </Layout.ListPage>
        );
    }

    private renderToolbar(): ReactNode {
        return (
            <Buttons.Group>
                <Buttons.RightIconButton
                    label="Nová"
                    to="/supply-chains/new"
                    icon="ADD"
                />
            </Buttons.Group>
        );
    }

    private renderNoSupplyChains(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nemáte prozatím žádné linky.
                    </div>
                    <div>
                        Chcete vytvořit novou linku? Klikněte na
                        <Buttons.RightIconButton
                            label="Nová"
                            to="/supply-chains/new"
                            className="m-2"
                            icon="ADD"
                        />
                    </div>
                </div>
            </Layout.EmptyPage>
        );
    }

    private renderNoMilkrooms(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center h5">
                    Pro vytvoření nové linky nejprve vytvořte dodavatele na stránce
                    <Buttons.LinkButton
                        label="Dodavatelé"
                        to="/suppliers"
                        className="m-2"
                    />
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    supplyChains: state.supplyChain.supplyChains,
    milkRooms: state.supplier.milkRooms,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadSupplyChains: () => dispatch(loadSupplyChains()),
    handleDeleteSupplyChain: (id: number) => dispatch(showConfirmDialog({
        title: 'Smazat linku',
        text: 'Opravdu chcete linku smazat?',
        confirmAction: deleteSupplyChain(id),
    })),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
