import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put, select} from 'typed-redux-saga';

import {formValuesF} from 'utils/formHelpers';

import {PickupLineApi} from '../api';
import {ApprovePickupLinePlanAction, pickupLineAction} from '../actions';
import {PICKUP_LINE_PLANNING_FORM_NAME} from '../components/PickupLinePlanningForm/pickupLinePlanningFormValues';
import {parsePlanningFormValues} from '../utils/parsePlanningFormValues';

const title = 'Schválení plánu svozové linky';

function* execute ({payload: {pickupLineId, yearMonth}}: ApprovePickupLinePlanAction): SagaIterator {
    yield* put(pickupLineAction.setLoading('planning', true));

    const formValues = (yield* select(formValuesF(PICKUP_LINE_PLANNING_FORM_NAME)))
        .orCrash('no pickupLine plan form values');
    const data = parsePlanningFormValues(formValues);
    const updateResponse = yield* call(PickupLineApi.updatePickupLinesOptions, data);

    if (updateResponse.isSuccess) {
        const approveResponse = yield* call(PickupLineApi.approvePickupLinePlan, yearMonth, pickupLineId);

        if (approveResponse.isSuccess) {
            yield* put(pickupLineAction.getPickupLinesOptions(yearMonth));
        } else {
            yield putAll(showBeError(approveResponse, title));
        }
    } else {
        yield putAll(showBeError(updateResponse, title));
    }

    yield* put(pickupLineAction.setLoading('planning', false));
}

export function* approvePickupLinePlanSaga(): SagaIterator {
    yield takeLatestF('pickupLine/APPROVE_PICKUP_LINE_PLAN', execute);
}
