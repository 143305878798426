import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {routerActions} from 'connected-react-router';
import {
    convertStringToFloat,
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    showSuccess,
    ErrorResponse,
    takeLatestF,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {UpdateSample} from 'types/model/samples/UpdateSample';
import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';
import Api from 'sample/api';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';
import {SampleBase} from 'types/model/samples/SampleBase';

import {UpdateSampleAction} from '../actions';

const title = 'Upravení vzorku';

export function prepareUpdateSample(
    values: CreateSampleFormValues,
    sample: SampleDetails,
    isLaborer: boolean
): UpdateSample {
    const sampleBase: SampleBase = {
        analysisIds: values.analysisIds,
        barcode: values.barcode,
        type: opt(values.type).orCrash('missing type'),
        harvestType: opt(values.harvestType).orCrash('missing harvestType'),
        samplingDate: values.samplingDate,
        samplingTime: opt(values.samplingTime)
            .chainToOpt(emptyStringToUndefined)
            .orUndef(),
        samplingTemperature: opt(values.samplingTemperature)
            .chainToOpt(emptyStringToUndefined)
            .map(t => convertStringToFloat(t).orCrash('invalid sampling temperature'))
            .orUndef(),
        sampleSourceAmount: opt(values.sampleSourceAmount).chainToOpt(parseInt).orUndef(),
        qualitative: values.qualitative,
        cistern: values.cistern,
        control: values.control,
        subsidy: values.subsidy,
        showResultsOnWeb: values.showResultsOnWeb,
        showResultsToBreeders: values.showResultsToBreeders,
        customerNote: opt(values.customerNote).chainToOpt(emptyStringToUndefined).orUndef(),
        traitNote: opt(values.traitNote).orUndef(),
        milkRoomId: opt(values.milkRoomId).orUndef(),
    };

    return isLaborer
        ? {
            ...sampleBase,
            canceled: sample.canceled,
            disabled: sample.disabled,
            note: emptyStringToUndefined(sample.note),
            preservatives: sample.preservatives,
            deliveryTemperature: sample.deliveryTemperature,
            tag: 'UpdateSampleByLaborer',
        }
        : {
            ...sampleBase,
            tag: 'UpdateSampleByCustomer',
        };
}

function* handleSuccessResponse(): SagaIterator {
    yield* put(showSuccess(title, 'Vzorek upraven.'));
    yield* put(routerActions.goBack());
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('createSample', extractFormErrorsFromResponse(response)));
}

function* getUpdateSample(): SagaIterator<UpdateSample> {
    const values = (yield* select(formValuesF('createSample')))
        .orCrash('missing form values');
    const sample = opt(yield* select((state: StoreState) => state.sample.sampleDetails))
        .orCrash('missing sample');
    const user = (yield* select((state: StoreState) => state.user.currentUser))
        .orCrash('missing current user');
    const isLaborer = user.role === 'LABORER' || user.role === 'ADMIN';

    return prepareUpdateSample(values, sample, isLaborer);
}

function* execute(action: UpdateSampleAction): SagaIterator {
    yield* put(isLoading(true));

    const {sampleId} = action.payload;
    const sampleData = yield* call(getUpdateSample);
    const response = yield* call(Api.updateSample, sampleId, sampleData);

    yield* response.isSuccess ? call(handleSuccessResponse) : call(handleErrorResponse, response);
    yield* put(isLoading(false));
}

export function* updateSampleSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_SAMPLE', execute);
}
