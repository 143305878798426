import {UpdateSampleGloballyFormValues} from 'order/types/UpdateSampleGloballyFormValues';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {OrderStats} from 'types/model/orders/OrderStats';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {ProtocolDetails} from 'types/model/protocols/ProtocolDetails';
import {ParsedSample} from 'types/model/samples/ParsedSample';
import {OrderAction} from './actions';
import {OrdersPage} from 'types/model/orders/OrdersPage';
import {SampleCreationType} from './types/SampleCreationType';
import {Analysis} from 'types/model/analyses/Analysis';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {OrderSearch} from 'types/model/orders/OrderSearch';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';

export interface OrderState {
    ordersPage: OrdersPage | null;
    current: OrderDetails | null;
    currentSample: OrderSampleView | null;
    sampleIdForDisable: number | null;
    analysesBySelectedDataItems: Analysis[] | null;
    ordersStats: OrderStats | null;
    lastCreatedSample: CreateSampleFormValues | null;
    importedSamples: ParsedSample[] | null;
    sampleCreationType: SampleCreationType | null;
    protocols: ProtocolDetails[] | null;
    updateSampleGloballyFormValues: UpdateSampleGloballyFormValues | null;
    lastCreatedOrderId: number | null;
    customer: CustomerDetailsForLaborer | null;
    isAresLoading: boolean;
    orderSearches: OrderSearch[] | null;
    approveInProgress: boolean;
    selectedOrders: number[];
}

export const initialOrderState: OrderState = {
    ordersPage: null,
    current: null,
    currentSample: null,
    sampleIdForDisable: null,
    analysesBySelectedDataItems: null,
    ordersStats: null,
    lastCreatedSample: null,
    importedSamples: null,
    sampleCreationType: null,
    protocols: null,
    updateSampleGloballyFormValues: null,
    lastCreatedOrderId: null,
    customer: null,
    isAresLoading: false,
    orderSearches: null,
    approveInProgress: false,
    selectedOrders: [],
};

// eslint-disable-next-line complexity
const reducer = (state: OrderState | undefined = initialOrderState, action: OrderAction): OrderState => {
    switch (action.type) {
        case 'order/RESET_ORDERS': {
            return {...state, ordersPage: null};
        }

        case 'order/LOAD_ORDERS_SUCCESS': {
            return {...state, ordersPage: action.payload, current: null};
        }

        case 'order/RESET_ORDER': {
            return {...state, current: null};
        }

        case 'order/LOAD_ORDER_SUCCESS': {
            const {order} = action.payload;

            return {...state, current: order};
        }

        case 'order/LOAD_ORDERS_STATS_SUCCESS': {
            return {...state, ordersStats: action.payload};
        }

        case 'order/SET_LAST_CREATED_SAMPLE': {
            return {...state, lastCreatedSample: action.payload};
        }

        case 'order/SET_ANALYSES_BY_SELECTED_DATA_ITEMS': {
            return {...state, analysesBySelectedDataItems: action.payload};
        }

        case 'order/IMPORT_SAMPLE_SUCCESS': {
            return {...state, importedSamples: action.payload};
        }

        case 'order/SELECT_SAMPLE_CREATION_TYPE': {
            // removes lastCreatedSample if import type is not the same
            const lastCreatedSample = action.payload === state?.sampleCreationType ? state?.lastCreatedSample : null;

            return {
                ...state,
                sampleCreationType: action.payload || null,
                lastCreatedSample,
            };
        }

        case 'order/UPDATE_SAMPLE_GLOBALLY_FORM_VALUES': {
            return {...state, updateSampleGloballyFormValues: action.payload};
        }

        case 'order/LOAD_PROTOCOLS_SUCCESS': {
            return {...state, protocols: action.payload};
        }

        case 'order/RESET_IMPORTED_SAMPLES': {
            return {...state, importedSamples: null};
        }

        case 'order/SET_CURRENT_SAMPLE': {
            return {...state, currentSample: action.payload};
        }

        case 'order/RESET_CURRENT_SAMPLE': {
            return {...state, currentSample: null};
        }

        case 'order/CREATE_ORDER_SUCCESS': {
            const {orderId} = action.payload;
            return {
                ...state,
                lastCreatedOrderId: orderId,
                lastCreatedSample: null,
                importedSamples: null,
                sampleCreationType: null,
            };
        }

        case 'order/SET_SAMPLE_ID_FOR_DISABLE': {
            return {
                ...state,
                sampleIdForDisable: action.payload,
            };
        }

        case 'order/RESET_SAMPLE_ID_FOR_DISABLE': {
            return {
                ...state,
                sampleIdForDisable: null,
            };
        }

        case 'order/LOAD_CUSTOMER_FOR_ORDER_SUCCESS': {
            const {customer} = action.payload;

            return {...state, customer};
        }

        case 'order/RESET_CUSTOMER_FOR_ORDER': {
            return {...state, customer: null};
        }

        case 'order/IS_ARES_LOADING': {
            return {...state, isAresLoading: action.payload.value};
        }

        case 'order/SET_ORDER_SEARCHES': {
            return {...state, orderSearches: action.payload.value};
        }

        case 'order/APPROVE_PROTOCOL': {
            return {...state, approveInProgress: true};
        }

        case 'order/APPROVE_PROTOCOL_SUCCESS': {
            return {...state, approveInProgress: false};
        }

        case 'order/APPROVE_PROTOCOL_ERROR': {
            return {...state, approveInProgress: false};
        }

        case 'order/SELECT_ORDERS': {
            return {...state, selectedOrders: action.payload};
        }

        default:
            return state;
    }
};

export default reducer;
