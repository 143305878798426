import {pullAt} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {changeF, formValuesF, touchF} from 'utils/formHelpers';

import {RemoveMilkRoomInSupplierCreationAction} from '../actions';

function* execute({payload: {id}}: RemoveMilkRoomInSupplierCreationAction): SagaIterator {
    const formValues = yield* select(formValuesF('createSupplier'));
    const oldMilkRooms = formValues.map(values => values.milkRooms).orElse([]);
    const newMilkRooms = pullAt(id)(oldMilkRooms);

    yield* put(changeF('createSupplier', 'milkRooms', newMilkRooms));
    yield* put(touchF('createSupplier', 'milkRooms'));
}

export function* removeMilkRoomInSupplierCreationSaga(): SagaIterator {
    yield takeLatestF('supplier/REMOVE_MILK_ROOM_IN_SUPPLIER_CREATION', execute);
}
