import * as t from 'io-ts';
import {PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {OperatingProcedure, OperatingProcedureSchema} from 'types/model/operatingProcedures/OperatingProcedure';
import {
    UpdateOperatingProcedure,
    UpdateOperatingProcedureSchema,
} from 'types/model/operatingProcedures/UpdateOperatingProcedure';
import {apiRequester} from 'app/api';

class Api {
    getOperatingProcedures(): PResponse<OperatingProcedure[]> {
        return apiRequester.makeRequest({
            url: '/operating-procedures',
            method: 'get',
            requestSchema: t.void,
            responseSchema: t.array(OperatingProcedureSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getOperatingProcedure(id: number): PResponse<OperatingProcedure> {
        return apiRequester.makeRequest({
            url: `/operating-procedures/${id}`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: OperatingProcedureSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    createOperatingProcedure(data: UpdateOperatingProcedure): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/operating-procedures',
            data,
            method: 'post',
            requestSchema: UpdateOperatingProcedureSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    updateOperatingProcedure(id: number, data: UpdateOperatingProcedure): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/operating-procedures/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateOperatingProcedureSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
