import React, {ReactNode, PureComponent} from 'react';

import {Fields} from 'forms';
import {DataItem} from 'types/model/dataItems/DataItem';
import {RefInput} from 'forms/components/Input';
import shortenDataItemName from 'measurement/utils/shortenDataItemName';

import styles from './styles.sass';

interface ResultProps {
    name: string;
    dataItem: DataItem;
    rowIndex: number;
    index: number;
    inputRef: RefInput;

    focusNextValue(rowIndex: number, index: number): void;
}

export class Result extends PureComponent<ResultProps> {
    render(): ReactNode {
        const {name, dataItem, rowIndex, index, inputRef} = this.props;

        return (
            <td
                className={styles.narrowTd}
            >
                <Fields.Input
                    name={name}
                    type="number"
                    label={shortenDataItemName(dataItem.name)}
                    inputRef={inputRef}
                    onEnter={this.focusNext}
                    autoFocus={rowIndex === 0 && index === 0}
                />
            </td>
        );
    }

    private focusNext = (): void => {
        const {rowIndex, index, focusNextValue} = this.props;

        focusNextValue(rowIndex, index);
    }
}
