import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadOrdersError, loadOrdersSuccess, LoadOrdersAction} from '../actions';
import Api from '../api';

function* execute(action: LoadOrdersAction): SagaIterator {
    const errorTitle = 'Načtení objednávek';
    const response = yield* call(Api.getOrders, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadOrdersError(error));
    }

    yield* put(loadOrdersSuccess(response.data));
}

export function* loadOrdersSaga(): SagaIterator {
    yield takeLatestF('order/LOAD_ORDERS', execute);
}
