import React, {ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';
import {NavLink} from 'react-router-dom';

import {MenuItem} from 'layout/types/MenuItem';

import styles from './styles.sass';
import {Role} from 'types/model/enumeration/Role';

const dropdownClasses = classNames('dropdown-menu', styles.dropdownMenuCenter);

const isDropdownActive = (path: string, currentPath: string): boolean => currentPath.startsWith(path);

const renderSubItems = (
    path: string,
    label: string,
    subItems: MenuItem[],
    currentPath: string,
    reverseColor?: boolean
): ReactNode =>
    <div
        key={path}
        className={`dropwdown ${styles.dropdownButton}`}
    >
        <button
            className={classNames(
                'btn btn-primary dropdown-toggle',
                isDropdownActive(path, currentPath) ? styles.active : '',
                reverseColor ? styles.reverseColor : ''
            )}
            type="button"
            id={path}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {label}
        </button>
        <div
            className={dropdownClasses}
            aria-labelledby={path}
        >
            {subItems.map((y) => !y.hidden &&
                <NavLink
                    key={`${path}${y.path}`}
                    className="dropdown-item"
                    to={`${path}${y.path}`}
                    activeClassName={styles.active}
                >
                    {y.label}
                </NavLink>
            )}
        </div>
    </div>
;


export const renderMenuItems = (
    menuItems: MenuItem[],
    userRole: Role,
    currentPath: string,
    reverseColor?: boolean,
): ReactNode =>
    menuItems.map(x => x.permissions.includes(userRole) && !x.hidden && (
        x.subItems?.length
            ? renderSubItems(x.path, x.label, x.subItems, currentPath, reverseColor)
            : <NavLink
                key={x.path}
                to={x.path}
                activeClassName={styles.active}
            >
                {x.label}
            </NavLink>
    ))
;
