import {UnsafeBeFormErrors, ErrorsFromBE} from 'favorlogic-utils';

import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {SupplyChainValidation} from 'types/model/supplyChains/SupplyChainValidation';
import {SupplyChainFormValues} from './types/SupplyChainFormValues';

export interface LoadSupplyChainsAction {
    type: 'supplyChain/LOAD_SUPPLY_CHAINS';
}

export const loadSupplyChains = (): LoadSupplyChainsAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAINS',
});

export interface LoadSupplyChainsSuccessAction {
    payload: SupplyChain[];
    type: 'supplyChain/LOAD_SUPPLY_CHAINS_SUCCESS';
}

export const loadSupplyChainsSuccess = (payload: SupplyChain[]): LoadSupplyChainsSuccessAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAINS_SUCCESS',
    payload,
});

export interface LoadSupplyChainsErrorAction {
    type: 'supplyChain/LOAD_SUPPLY_CHAINS_ERROR';
    payload: ErrorsFromBE;
}

export const loadSupplyChainsError = (err: ErrorsFromBE): LoadSupplyChainsErrorAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAINS_ERROR',
    payload: err,
});

// ---
export interface LoadSupplyChainAction {
    type: 'supplyChain/LOAD_SUPPLY_CHAIN';
    payload: number;
}

export const loadSupplyChain = (id: number): LoadSupplyChainAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAIN',
    payload: id,
});

export interface LoadSupplyChainSuccessAction {
    payload: SupplyChain;
    type: 'supplyChain/LOAD_SUPPLY_CHAIN_SUCCESS';
}

export const loadSupplyChainSuccess = (payload: SupplyChain): LoadSupplyChainSuccessAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAIN_SUCCESS',
    payload,
});

export interface LoadSupplyChainErrorAction {
    type: 'supplyChain/LOAD_SUPPLY_CHAIN_ERROR';
    payload: ErrorsFromBE;
}

export const loadSupplyChainError = (err: ErrorsFromBE): LoadSupplyChainErrorAction => ({
    type: 'supplyChain/LOAD_SUPPLY_CHAIN_ERROR',
    payload: err,
});

// ---

export interface DeleteSupplyChainAction {
    type: 'supplyChain/DELETE_SUPPLY_CHAIN';
    payload: number;
}

export const deleteSupplyChain = (payload: number): DeleteSupplyChainAction => ({
    type: 'supplyChain/DELETE_SUPPLY_CHAIN',
    payload,
});

export interface DeleteSupplyChainSuccessAction {
    type: 'supplyChain/DELETE_SUPPLY_CHAIN_SUCCESS';
}

export const deleteSupplyChainSuccess = (): DeleteSupplyChainSuccessAction => ({
    type: 'supplyChain/DELETE_SUPPLY_CHAIN_SUCCESS',
});

export interface DeleteSupplyChainErrorAction {
    type: 'supplyChain/DELETE_SUPPLY_CHAIN_ERROR';
    payload: ErrorsFromBE;
}

export const deleteSupplyChainError = (err: ErrorsFromBE): DeleteSupplyChainErrorAction => ({
    type: 'supplyChain/DELETE_SUPPLY_CHAIN_ERROR',
    payload: err,
});

// ---

export interface CreateSupplyChainAction {
    type: 'supplyChain/CREATE_SUPPLY_CHAIN';
}

export const createSupplyChain = (): CreateSupplyChainAction => ({
    type: 'supplyChain/CREATE_SUPPLY_CHAIN',
});

export interface CreateSupplyChainSuccessAction {
    type: 'supplyChain/CREATE_SUPPLY_CHAIN_SUCCESS';
}

export const createSupplyChainSuccess = (): CreateSupplyChainSuccessAction => ({
    type: 'supplyChain/CREATE_SUPPLY_CHAIN_SUCCESS',
});

export interface CreateSupplyChainErrorAction {
    type: 'supplyChain/CREATE_SUPPLY_CHAIN_ERROR';
    payload: ErrorsFromBE;
}

export const createSupplyChainError = (err: ErrorsFromBE): CreateSupplyChainErrorAction => ({
    type: 'supplyChain/CREATE_SUPPLY_CHAIN_ERROR',
    payload: err,
});

// ---

export interface UpdateSupplyChainAction {
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN';
    payload: {
        supplyChainId: number,
    };
}

export const updateSupplyChain = (supplyChainId: number): UpdateSupplyChainAction => ({
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN',
    payload: {supplyChainId},
});

export interface UpdateSupplyChainSuccessAction {
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN_SUCCESS';
}

export const updateSupplyChainSuccess = (): UpdateSupplyChainSuccessAction => ({
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN_SUCCESS',
});

export interface UpdateSupplyChainErrorAction {
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN_ERROR';
    payload: ErrorsFromBE;
}

export const updateSupplyChainError = (err: ErrorsFromBE): UpdateSupplyChainErrorAction => ({
    type: 'supplyChain/UPDATE_SUPPLY_CHAIN_ERROR',
    payload: err,
});

// ---

export interface PrefillSupplyChainFormAction {
    type: 'supplyChain/PREFILL_SUPPLY_CHAIN_FORM';
    payload: SupplyChainFormValues;
}

export const prefillSupplyChainForm = (payload: SupplyChainFormValues): PrefillSupplyChainFormAction => ({
    type: 'supplyChain/PREFILL_SUPPLY_CHAIN_FORM',
    payload,
});

// ---

export interface ValidateSupplyChainActionArgs {
    values: SupplyChainValidation;
    isCreate: boolean;

    reject(_: UnsafeBeFormErrors): void;

    resolve(_: boolean): void;
}

export interface ValidateSupplyChainAction {
    type: 'supplier/VALIDATE_SUPPLY_CHAIN';
    payload: ValidateSupplyChainActionArgs;
}

export const validateSupplyChain = (payload: ValidateSupplyChainActionArgs): ValidateSupplyChainAction => ({
    type: 'supplier/VALIDATE_SUPPLY_CHAIN',
    payload,
});

// ---

export type SupplyChainAction =
    LoadSupplyChainsAction | LoadSupplyChainsSuccessAction | LoadSupplyChainsErrorAction |
    LoadSupplyChainAction | LoadSupplyChainSuccessAction | LoadSupplyChainErrorAction |
    DeleteSupplyChainAction | DeleteSupplyChainSuccessAction | DeleteSupplyChainErrorAction |
    CreateSupplyChainAction | CreateSupplyChainSuccessAction | CreateSupplyChainErrorAction |
    UpdateSupplyChainAction | UpdateSupplyChainSuccessAction | UpdateSupplyChainErrorAction |
    PrefillSupplyChainFormAction |
    ValidateSupplyChainAction
    ;
