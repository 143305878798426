import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {none, opt} from 'ts-opt';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadCurrentError, loadCurrentSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const errorTitle = 'Načtení uživatele';
    const userResponse = yield* call(Api.getCurrent);

    if (!userResponse.isSuccess) {
        const error = userResponse.data as ErrorsFromBE;

        if (!userResponse.isUnauthorized) {
            yield* call(handleResponseError, userResponse, errorTitle);
        }

        return yield* put(loadCurrentError(error));
    }
    const user = userResponse.data;

    if (user.tag === 'UserDetailsForCustomer') {
        const customerResponse = yield* call(Api.getCustomer, user.customerId);

        if (!customerResponse.isSuccess) {
            const error = customerResponse.data as ErrorsFromBE;
            yield* call(handleResponseError, customerResponse, errorTitle);
            return yield* put(loadCurrentError(error));
        }

        return yield* put(loadCurrentSuccess(opt(user), opt(customerResponse.data)));
    }

    yield* put(loadCurrentSuccess(opt(user), none));
}

export function* loadCurrentSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CURRENT', execute);
}
