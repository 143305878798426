import {find, flow, isNumber, isString, isBoolean, isEmpty} from 'lodash/fp';
import React, {ReactNode} from 'react';
import {opt} from 'ts-opt';
import {
    convertStringToLimitedLengthElement,
    formatBoolYesNo,
    formatCurrency,
    formatDate,
    formatTime,
    isObject,
    formatTemperatureEn,
    isStringArray,
    isArray,
    truncateString,
} from 'favorlogic-utils';

import {
    BoolColumn,
    Column,
    ColumnType,
    CurrencyColumn,
    DateColumn,
    MultiSelectOptionsColumn,
    NodeColumn,
    SelectOptionsColumn,
    TemperatureColumn,
    DynamicNodeColumn,
} from 'tables/types/Column';
import {Row} from 'tables/types/Row';

import {renderTooltipCell} from './renderTooltipCell';

// A lot of dynamic type checks, because compiler doesn't support combining generic and mapped types.
// https://github.com/Microsoft/TypeScript/issues/30728

const renderNode = <T>(value: unknown, column: NodeColumn<T> | DynamicNodeColumn): ReactNode => {
    if (isString(value) || isNumber(value)) {
        if (column.maxChars && String(value).length > column.maxChars) {
            return renderTooltipCell(truncateString(String(value), column.maxChars), String(value));
        }
        return value;
    }
    if (isObject(value) && 'type' in value) { return value; } // component
    if (isArray(value) && (isEmpty(value) || isObject(value[0]) && 'type' in value[0])) {
        return value; // array of elements/components
    }
    throw new Error(`unknown node type: ${typeof value}`);
};

const renderDate = <T>(value: unknown, column: DateColumn<T>): ReactNode => {
    if (!isString(value)) {
        throw new Error('invalid value, expected string');
    }
    return flow(
        (x: string) => x.toString(),
        column.dateFormat === 'TIME' ? formatTime : formatDate,
        x => x.orCrash('failed to format date/time'), // TODO: replace with curried fn when in library
    )(value);
};

const renderSelectOption = <T>(value: unknown, column: SelectOptionsColumn<T>): ReactNode =>
    opt(value)
        .chainToOpt(val =>
            opt(find(x => x.value === val, column.selectOptions)).orCrash(`option ${val} not found`),
        )
        .map(x => x.label)
        .map(opt(column.lengthLimit).map(convertStringToLimitedLengthElement).orElse(x => x))
        .orElse('');

const renderMultiSelectOption = <T extends {}>(
    values: unknown,
    column: MultiSelectOptionsColumn<T>,
): ReactNode => {
    if (values instanceof Object && React.isValidElement(values)) {
        return values;
    }

    if (!isStringArray(values)) {
        throw new Error('Value must be array');
    }

    const {format} = column;
    if (format) {
        return format(values);
    }

    return values.map(value =>
        opt(column.selectOptions.find(o => o.value === value)).caseOf(
            o => o.label,
            () => {
                // eslint-disable-next-line no-console
                console.error(`Unknown option "${value}"`);
            },
        )).join(', ');
};

const renderCurrency = <T>(value: unknown, _column: CurrencyColumn<T>): ReactNode => {
    if (!isNumber(value)) { throw new Error('expected number'); }
    return opt(value).map(formatCurrency).orElse('');
};

const renderTemperature = <T>(value: unknown, _column: TemperatureColumn<T>): ReactNode => {
    if (!isNumber(value)) { throw new Error('expected number'); }
    return opt(value).map(formatTemperatureEn).orElse('');
};

const renderBool = <T>(value: unknown, column: BoolColumn<T>): ReactNode => {
    if (!isBoolean(value)) { throw new Error(`expected boolean`); }

    return opt(value).map(column.format || formatBoolYesNo).orElse('');
};

export const renderCell = <T>(row: Row<T>, column: Column<T>): ReactNode =>
    opt(row[column.accessor]).map((value: unknown) => {
        switch (column.type) {
            case ColumnType.Node: { return renderNode(value, column); }
            case ColumnType.DynamicNode: { return renderNode(value, column); }
            case ColumnType.Date: { return renderDate(value, column); }
            case ColumnType.SelectOption: { return renderSelectOption(value, column); }
            case ColumnType.Currency: { return renderCurrency(value, column); }
            case ColumnType.Temperature: { return renderTemperature(value, column); }
            case ColumnType.Bool: { return renderBool(value, column); }
            case ColumnType.MultiSelectOption: {
                return renderMultiSelectOption(value, column);
            }
            default: { throw new Error('unknown column type'); }
        }
    }).orNull();
