import {YearMonth} from 'types/model/common/YearMonth';

export const isFutureYearMonth = (yearMonth: YearMonth, currentYearMonth?: YearMonth): boolean => {
    const date = new Date(yearMonth);
    const currentDate = currentYearMonth ? new Date(currentYearMonth) : new Date();

    return date.getFullYear() > currentDate.getFullYear() ||
        date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() > currentDate.getMonth();
};
