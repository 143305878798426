import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import Api from '../api';
import {LogoutAction} from '../actions';

function* execute(_: LogoutAction): SagaIterator {
    const response = yield* call(Api.logout);

    if (!response.isSuccess) {
        return yield* call(handleResponseError, response, 'Odhlášení');
    }

    yield* put(routerActions.push('/login'));
}

export function* logoutSaga(): SagaIterator {
    yield takeLatestF('user/LOGOUT', execute);
}
