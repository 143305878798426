import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';

class PageNotFound extends Component {
    render(): ReactNode {
        return (
            <div className="container">
                <div className="mt-5 mb-5">&nbsp;</div>
                <div className="row">
                    <div className="col">
                        <h1>Stránka nenalezena.</h1>
                        <Link to="/">
                            Pokračovat na úvodní stránku.
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;
