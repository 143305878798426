import {
    change, FormAction, isDirty, isValid, reset,
    getFormSyncErrors, touch, blur, initialize, destroy,
    startSubmit, stopSubmit, isSubmitting, submit, stopAsyncValidation,
} from 'redux-form';
import {property} from 'lodash/fp';
import {Opt, opt} from 'ts-opt';
import {UnsafeBeFormErrors} from 'favorlogic-utils';

import {StoreFormState, FormName} from 'app/types/StoreFormState';
import {StoreState} from 'app/types/StoreState';

import {Errors} from './Validator';

type FormValues<N extends FormName> = NonNullable<Required<StoreFormState[N]>['values']>;
type FormFieldName<N extends FormName> = keyof FormValues<N> & string;

export const formState = <N extends FormName>(name: N) =>
    (state: StoreState): Opt<StoreFormState[N]> => opt(state.form[name]);

export const formValuesF = <N extends FormName>(name: N) => (state: StoreState): Opt<FormValues<N>> =>
    formState(name)(state).chainToOpt(form => form.values) as Opt<FormValues<N>>;

export const isValidF = <N extends FormName>(name: N) => (state: StoreState): boolean => isValid(name)(state);

export const isDirtyF = <N extends FormName>(name: N) => (state: StoreState): boolean => isDirty(name)(state);

export const isFieldValidUnsafe = <N extends FormName>(formName: N, path: string) =>
    (state: StoreState): boolean => !property(path, getFormSyncErrors(formName)(state));

export const isSubmitingF = <N extends FormName>(name: N) => (state: StoreState): boolean => isSubmitting(name)(state);

export const changeF =
    <N extends FormName,
        F extends FormFieldName<N>,
        V extends FormValues<N>[F]>
    (formName: N, fieldName: F, value: V): FormAction =>
        change(formName, fieldName, value);

export const blurF =
    <N extends FormName,
        F extends FormFieldName<N>,
        V extends FormValues<N>[F]>
    (formName: N, fieldName: F, value?: V): FormAction =>
        blur(formName, fieldName, value);

export const touchF = <N extends FormName, F extends FormFieldName<N>>(formName: N, fieldName: F): FormAction =>
    touch(formName, fieldName);

export const resetF = (name: FormName): FormAction => reset(name);

export const initializeF = <N extends FormName>(name: N, values: FormValues<N>): FormAction =>
    initialize(name, values);

export const destroyF = (name: FormName): FormAction => destroy(name);

export const submitF = (name: FormName): FormAction => submit(name);

export const startSubmitF = (name: FormName): FormAction => startSubmit(name);

export const stopSubmitF = <N extends FormName>(
    name: N,
    errors?: Errors<FormValues<N>>,
): FormAction =>
    stopSubmit(name, errors);

export const stopSubmitUnsafe = <N extends FormName>(
    name: N,
    errors?: UnsafeBeFormErrors,
): FormAction =>
    stopSubmit(name, errors);

export const stopAsyncValidationF = <N extends FormName>(
    name: N,
    errors?: Errors<FormValues<N>>,
): FormAction =>
    stopAsyncValidation(name, errors);

export const stopAsyncValidationUnsafe = <N extends FormName>(
    name: N,
    errors?: UnsafeBeFormErrors,
): FormAction =>
    stopAsyncValidation(name, errors);
