import React, {Component, ReactNode} from 'react';
import {compact} from 'lodash/fp';

import {Components as Icons} from 'icons';
import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import RowActions from './RowActions';
import {SuppliersPage} from 'types/model/suppliers/SuppliersPage';
import {SupplierView} from 'types/model/suppliers/SupplierView';
import {Filter} from 'tables/types/Filter';
import {SortDir} from 'tables/components/withSort';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {OptionalAddress} from 'types/model/common/OptionalAddress';
import {LoaderWrapper} from 'layout/components';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';

import noteIcon from 'buttons/components/RowActionButton/img/update.svg';

const limit = convertStringToLimitedLengthElement(60);

export const formatAddress = (address: OptionalAddress): string => {
    const {street, city, zip} = address;

    return compact([street, city, zip]).join(', ');
};

type SupplierRow = Omit<SupplierView, 'note' | 'address' | 'name'> & {
    name: ReactNode,
    note: ReactNode,
    address: string,
    actions: ReactNode,
}

export const definedFilters: FilterDefinition<keyof SupplierRow>[] = [
    {key: 'name', type: 'equals'},
    {key: 'ico', type: 'equals'},
];

export interface Props {
    suppliersPage: SuppliersPage | null;
    sortBy?: string;
    sortDir?: SortDir;
    filter: Filter;

    handleDelete(id: number): void;
    changePage(page: number): void;
    changePageSize(size: number): void;
    changeSort(property: string): void;
    changeFilter(accessor: string, type: string, values: string[]): void;
    resetFilter(): void;
    filterSuppliers(): void;
}

class SuppliersTable extends Component<Props> {
    render(): ReactNode {
        const {
            suppliersPage,
            changePage,
            changePageSize,
            changeSort,
            sortBy,
            sortDir,
            changeFilter,
            resetFilter,
            filterSuppliers,
            filter,
        } = this.props;

        return (
            <LoaderWrapper showLoader={!suppliersPage}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData(suppliersPage ? suppliersPage.content : [])}
                    page={suppliersPage?.pageable.pageNumber}
                    pageSize={suppliersPage?.pageable.pageSize}
                    total={suppliersPage?.totalElements}
                    handlePageChange={changePage}
                    handlePageSizeChange={changePageSize}
                    handleSortChange={changeSort}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    hasFooter
                    filter={filter}
                    handleFilterChange={changeFilter}
                    handleFilterSubmit={filterSuppliers}
                    handleFilterClear={resetFilter}
                    hasHeader
                    hasTwoMainColumns
                />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<SupplierRow>[] {
        return [
            {
                accessor: 'note',
                header: '',
                type: ColumnType.Node,
            },
            {
                accessor: 'name',
                header: 'Společnost',
                type: ColumnType.Node,
                filterable: true,
                filterType: 'equals',
                sortable: true,
            },
            {
                accessor: 'ico',
                header: 'IČO',
                type: ColumnType.Node,
                filterable: true,
                filterType: 'equals',
                sortable: true,
            },
            {
                accessor: 'dic',
                header: 'DIČ',
                type: ColumnType.Node,
            },
            {
                accessor: 'address',
                header: 'Adresa',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getData(suppliers: SupplierView[]): Row<SupplierRow>[] {
        const {handleDelete} = this.props;

        return suppliers.map(supplier => ({
            ...supplier,
            name: limit(supplier.name),
            note: supplier.note
                ? <Icons.RowIcon
                    icon={noteIcon}
                    tooltip={supplier.note}
                />
                : '',
            address: formatAddress(supplier.address),
            actions:
                <RowActions
                    supplier={supplier}
                    handleDelete={handleDelete}
                />,
        }));
    }
}

export default SuppliersTable;
