import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {AnalysisFormValues} from '../../types/AnalysisFormValues';

type Values = AnalysisFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název');
    validator.integerNumber('index', 'Pořadí');
    validator.nonEmpty('index', 'Pořadí');
    validator.nonEmpty('abbr', 'Zkratka');
    validator.nonEmpty('price', 'Cena');
    validator.nonEmpty('availability', 'Dostupnost');
    validator.floatNumber('price', 'Cena');

    return validator.generateErrorsObject();
};
