import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, Fragment, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteComponentProps} from 'react-router';
import {opt} from 'ts-opt';
import {parseSearch, toggleItem} from 'favorlogic-utils';

import {DownloadFileActionArguments, downloadFileAction} from 'download/actions';
import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {OrderStats} from 'types/model/orders/OrderStats';
import withUser, {WithUserProps} from 'user/components/withUser';
import {OrdersPage} from 'types/model/orders/OrdersPage';
import {withPagination, withSort, withFilter, TableAction} from 'tables/components';
import {SortProps} from 'tables/components/withSort';
import {initializeF, formValuesF} from 'utils/formHelpers';
import {loadDataItems} from 'dataItem/actions';
import {loadOperatingProcedures} from 'operatingProcedure/actions';
import {DataItem} from 'types/model/dataItems/DataItem';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {Modal} from 'layout/components';
import {setModalVisibility} from 'layout/actions';
import {isModalVisibleSelector} from 'layout/reducer';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';

import {
    exportTxtProtocols,
    exportMeasurementsFilling,
    loadOrders,
    loadOrdersStats,
    resetOrders,
    selectOrders,
    exportTranscripts,
    exportTranscriptSubset,
    downloadInvoice,
    exportOrdersInvoice,
    exportOrdersInvoiceSubset,
} from '../actions';
import ShowOrderStats from '../components/ShowOrderStats';
import OrdersTable, {definedFilters} from '../components/OrdersTable';
import ExportMeasurementsFillingForm from '../components/ExportMeasurementsFillingForm';
import ExportTxtProtocolsForm from '../components/ExportTxtProtocolsForm';
import {OrdersFilter, OrdersParams} from '../types/OrderParams';
import {ExportMeasurementsFillingFormValues} from '../types/ExportMeasurementsFillingFormValues';

const transcriptDownloadSelectionDisabledTitle = 'Opis vzorků lze vytvořit pouze pro přijaté objednávky';
const invoiceOrdersDisableTitle = 'Nelze vyfakturovat již vyfakturované objednávky';

interface OuterProps {}

interface StateProps {
    ordersPage: OrdersPage | null;
    ordersStats: OrderStats | null;
    dataItems: DataItem[] | null;
    operatingProcedures: OperatingProcedure[] | null;
    exportMeasurementsFillingModalVisible: boolean;
    exportMeasurementsFillingValues: ExportMeasurementsFillingFormValues | null;
    exportTxtProtocolsModalVisible: boolean;
    selectedOrders: number[];
}

interface DispatchProps {
    handleLoadOrders(params: OrdersParams): void;
    handleLoadStats(): void;
    handleDownload(args: DownloadFileActionArguments<undefined>): void;
    handleResetOrders(): void;
    handleLoadDataItems(): void;
    handleLoadOperatingProcedures(): void;
    handleSetExportMeasurementsFillingModalVisibility(visibility: boolean): void;
    handleExportMeasurementsFilling(): void;
    handleResetExportMeasurementsFillingForm(): void;
    handleSetExportTxtProtocolsModalVisibility(visibility: boolean): void;
    handleExportTxtProtocols(): void;
    handleSelectOrders(ids: number[]): void;
    exportTranscripts(params: OrdersParams): void;
    exportTranscriptSubset(ids: number[]): void;
    downloadInvoice(orderId: number): void;
    exportOrdersInvoiceSubset(orderIds: number[]): void;
    exportOrdersInvoice(params: OrdersFilter): void;
}

type UserProps = Pick<WithUserProps, 'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type Props =  OuterProps & UserProps & DispatchProps & StateProps & RouteComponentProps & ListProps;

class List extends Component<Props> {
    private get newOrderPage(): string {
        return this.isAdminOrLaborer ? '/orders/new/orderCustomer' : '/orders/new';
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    componentDidMount(): void {
        const {
            location: {search},
            handleLoadStats,
            isRoleAdmin,
            isRoleLaborer,
            handleLoadDataItems,
            handleLoadOperatingProcedures,
        } = this.props;

        if (search) { this.loadOrdersByQuery(search); }

        if (isRoleAdmin || isRoleLaborer) {
            handleLoadStats();
            handleLoadDataItems();
            handleLoadOperatingProcedures();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}, filter: prevFilter} = prevProps;
        const {location: {search}, filter, handleResetOrders} = this.props;

        if (prevFilter !== filter && isEmpty(filter)) {
            handleResetOrders();
            this.loadOrdersByQuery(search);
        } else if (prevSearch !== search) {
            this.loadOrdersByQuery(search);
        }
    }

    render(): ReactNode {
        const {
            ordersPage,
            isRoleAdmin,
            isRoleDairy,
            isRoleDairyEmployee,
            ordersStats,
            handleDownload,
            handlePageChange,
            handlePageSizeChange,
            sortBy,
            sortDir,
            handleSortChange,
            filter,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            dataItems,
            operatingProcedures,
            exportMeasurementsFillingModalVisible,
            handleSetExportMeasurementsFillingModalVisibility,
            handleExportMeasurementsFilling,
            exportTxtProtocolsModalVisible,
            handleSetExportTxtProtocolsModalVisibility,
            handleExportTxtProtocols,
            selectedOrders,
            downloadInvoice,
        } = this.props;

        if (ordersPage && isEmpty(ordersPage.content) && isEmpty(filter)) {
            return this.renderNoOrders();
        }

        return (
            <Fragment>
                <Modal.Blurer>
                    <Layout.ListPage
                        title="Objednávky"
                        toolbar={this.renderToolbar()}
                        fluid={this.isAdminOrLaborer}
                    >
                        {this.isAdminOrLaborer && this.renderTableActions()}
                        <div className="row">
                            <div className={`${this.isAdminOrLaborer ? 'col-lg-9' : 'col-lg-12'} col-12`}>
                                <OrdersTable
                                    ordersPage={opt(ordersPage).orUndef()}
                                    sort={{property: sortBy, direction: sortDir}}
                                    filter={filter}
                                    isRoleAdminOrLaborer={this.isAdminOrLaborer}
                                    isRoleAdmin={isRoleAdmin}
                                    isRoleDairy={isRoleDairy}
                                    isRoleDairyEmployee={isRoleDairyEmployee}
                                    handleDownload={handleDownload}
                                    changePage={handlePageChange}
                                    changePageSize={handlePageSizeChange}
                                    changeSort={handleSortChange}
                                    changeFilter={handleFilterChange}
                                    resetFilter={handleFilterClear}
                                    filterOrders={handleFilterSubmit}
                                    selectedIds={selectedOrders}
                                    handleSelect={this.toggleSelect}
                                    downloadInvoice={downloadInvoice}
                                />
                            </div>
                            {this.isAdminOrLaborer &&
                                <div className="col-12 col-lg-3">
                                    <Layout.Loader show={!ordersStats} />
                                    {ordersStats && <ShowOrderStats stats={ordersStats} />}
                                </div>
                            }
                        </div>
                    </Layout.ListPage>
                </Modal.Blurer>
                <Modal.Container
                    show={exportMeasurementsFillingModalVisible}
                    onClickAway={() => handleSetExportMeasurementsFillingModalVisibility(false)}
                >
                    <ExportMeasurementsFillingForm
                        onSubmit={handleExportMeasurementsFilling}
                        dataItems={dataItems || []}
                        operatingProcedures={operatingProcedures || []}
                        selectedDataItem={this.getSelectedDataItem() || null}
                    />
                </Modal.Container>
                <Modal.Container
                    show={exportTxtProtocolsModalVisible}
                    onClickAway={() => handleSetExportTxtProtocolsModalVisibility(false)}
                >
                    <ExportTxtProtocolsForm
                        onSubmit={handleExportTxtProtocols}
                    />
                </Modal.Container>
            </Fragment>
        );
    }

    private renderToolbar(): ReactNode {
        const {isRoleDairy} =  this.props;

        return (
            <Buttons.Group>
                {isRoleDairy &&
                    <Buttons.RightIconButton
                        label="Stáhnout protokoly"
                        onClick={this.clickExportTxts}
                        icon="DOWNLOAD"
                    />
                }
                {this.isAdminOrLaborer && <Fragment>
                    <Buttons.RightIconButton
                        label="Stáhnout podklady"
                        onClick={this.resetAndOpenExportMeasurementsFillingModal}
                        icon="DOWNLOAD"
                    />
                    <Buttons.RightIconButton
                        label="Příjem objednávek"
                        to="/orders/accept"
                        icon="ADD"
                    />
                </Fragment>}
                <Buttons.RightIconButton
                    label="Nová"
                    to={this.newOrderPage}
                    icon="ADD"
                />
            </Buttons.Group>
        );
    }

    private renderTableActions(): ReactNode {
        const {
            ordersPage,
            selectedOrders,
            isRoleAdmin,
            exportTranscripts,
            exportTranscriptSubset,
            filterParams,
            exportOrdersInvoiceSubset,
            exportOrdersInvoice,
        } = this.props;

        return (
            <Layout.Panel className="mt-0">
                <div className="row">
                    {isRoleAdmin && <TableAction
                        title="Vyfakturovat"
                        filteredItemsCount={ordersPage?.totalElements ?? 0}
                        selectedItemsCount={selectedOrders.length}
                        actionForFilter={() => exportOrdersInvoice(filterParams)}
                        actionForSelection={() => exportOrdersInvoiceSubset(selectedOrders)}
                        selectionDisabled={this.isSelectedSomeInvoiced()}
                        selectionDisabledTitle={invoiceOrdersDisableTitle}
                    />}

                    <TableAction
                        title="Stažení opisů"
                        filteredItemsCount={ordersPage?.totalElements ?? 0}
                        selectedItemsCount={selectedOrders.length}
                        actionForFilter={() => exportTranscripts(filterParams)}
                        actionForSelection={() => exportTranscriptSubset(selectedOrders)}
                        selectionDisabled={this.isSelectedSomeNewOrder()}
                        selectionDisabledTitle={transcriptDownloadSelectionDisabledTitle}
                    />
                </div>
            </Layout.Panel>
        );
    }

    private isSelectedSomeNewOrder = (): boolean => {
        const {selectedOrders, ordersPage} = this.props;
        if (!ordersPage) {
            return false;
        }

        return ordersPage.content
            .filter(o => selectedOrders.includes(o.orderId))
            .some(o => o.state === 'NEW');
    };

    private isSelectedSomeInvoiced = (): boolean => {
        const {selectedOrders, ordersPage} = this.props;
        if (!ordersPage) {
            return false;
        }

        return ordersPage.content
            .filter(o => selectedOrders.includes(o.orderId))
            .some(o => o.hasInvoice && o.hasAdditionalProtocolBilled);
    };

    private clickExportTxts = () => {
        const {handleSetExportTxtProtocolsModalVisibility} = this.props;
        handleSetExportTxtProtocolsModalVisibility(true);
    };

    private toggleSelect = (id: number) => {
        const {selectedOrders, handleSelectOrders} = this.props;
        const newSelectedOrders = toggleItem(id)(selectedOrders);
        handleSelectOrders(newSelectedOrders);
    };

    private getSelectedDataItem(): DataItem | undefined {
        const {exportMeasurementsFillingValues, dataItems} = this.props;
        const dataItemId = opt(exportMeasurementsFillingValues)
            .map(x => x.dataItemId)
            .orNull();

        return opt(dataItems)
            .orElse([])
            .find(x => x.id === dataItemId);
    }

    private renderNoOrders(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nemáte prozatím žádné objednávky.
                    </div>
                    <div>
                        Chcete vytvořit novou objednávku? Klikněte na
                        <Buttons.RightIconButton
                            label="Nová"
                            to={this.newOrderPage}
                            className="m-2"
                            icon="ADD"
                        />
                    </div>
                </div>
            </Layout.EmptyPage>
        );
    }

    private loadOrdersByQuery(search: string): void {
        const {handleLoadOrders} = this.props;

        handleLoadOrders(parseSearch(search));
    }

    private readonly resetAndOpenExportMeasurementsFillingModal = (): void => {
        const {
            handleResetExportMeasurementsFillingForm,
            handleSetExportMeasurementsFillingModalVisibility,
        } = this.props;
        handleResetExportMeasurementsFillingForm();
        handleSetExportMeasurementsFillingModalVisibility(true);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    ordersPage: state.order.ordersPage,
    ordersStats: state.order.ordersStats,
    dataItems: state.dataItem.dataItems,
    operatingProcedures: state.operatingProcedure.operatingProcedures,
    exportTxtProtocolsModalVisible: isModalVisibleSelector(state.layout, 'exportTxtProtocols'),
    exportMeasurementsFillingModalVisible: isModalVisibleSelector(state.layout, 'exportMeasurementsFilling'),
    exportMeasurementsFillingValues: formValuesF('exportMeasurementsFilling')(state).orNull(),
    selectedOrders: state.order.selectedOrders,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadOrders: (params: OrdersParams) => dispatch(loadOrders(params)),
    handleLoadStats: () => dispatch(loadOrdersStats()),
    handleDownload: (args: DownloadFileActionArguments<undefined>) => dispatch(downloadFileAction(args)),
    handleResetOrders: () => dispatch(resetOrders()),
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleLoadOperatingProcedures: () => dispatch(loadOperatingProcedures()),
    handleSetExportMeasurementsFillingModalVisibility: (visibility: boolean) =>
        dispatch(setModalVisibility('exportMeasurementsFilling', visibility)),
    handleSetExportTxtProtocolsModalVisibility: (visibility: boolean) =>
        dispatch(setModalVisibility('exportTxtProtocols', visibility)),
    handleExportMeasurementsFilling: () => dispatch(exportMeasurementsFilling()),
    handleResetExportMeasurementsFillingForm: () => dispatch(initializeF(
        'exportMeasurementsFilling',
        {dataItemId: null, operatingProcedureId: null})
    ),
    handleExportTxtProtocols: () => dispatch(exportTxtProtocols()),
    handleSelectOrders: (ids: number[]) => dispatch(selectOrders(ids)),
    exportTranscripts: (params: OrdersFilter) => dispatch(exportTranscripts(params)),
    exportTranscriptSubset: (ids: number[]) => dispatch(exportTranscriptSubset(ids)),
    downloadInvoice: (orderId: number) => dispatch(downloadInvoice(orderId)),
    exportOrdersInvoiceSubset: (orderIds: number[]) => dispatch(exportOrdersInvoiceSubset(orderIds)),
    exportOrdersInvoice: (params: OrdersFilter) => dispatch(exportOrdersInvoice(params)),
});

export default flow([
    withUser,
    withSort({sortBy: 'created', sortDir: 'desc'}),
    withPagination,
    withFilter(definedFilters),
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
