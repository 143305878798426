import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import React, {ChangeEvent, FocusEvent, ReactNode, createRef, RefObject, KeyboardEvent, Fragment} from 'react';
import {opt} from 'ts-opt';
import {classNames, stringTuple} from 'favorlogic-utils';
import {isNumber} from 'lodash/fp';

import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';

import styles from './styles.sass';

export const inputFieldValidTypes = stringTuple('text', 'email', 'tel', 'hidden', 'number', 'password');
export type InputFieldValidType = typeof inputFieldValidTypes[number];

export type RefInput = RefObject<HTMLInputElement>;

type FieldValue = string;

type OwnProps = {
    type: InputFieldValidType,
    autoFocus?: boolean,
    inputRef?: RefInput,
    noHelper?: boolean,
    onlyPositive?: boolean,
    placeholder?: string,

    onEnter?(): void,
    onPasting?(newValue: string): void,
}

export type InputOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type InputInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

interface State {
    pasting: boolean;
}

class Input extends ComponentForWrapping<InputInnerProps, FieldValue, State> {
    state: State = {
        pasting: false,
    };

    private inputRef = createRef<HTMLInputElement>() ;

    componentDidMount(): void {
        const {autoFocus} = this.props;

        if (this.inputRef.current && autoFocus) {
            this.inputRef.current.focus();
        }
    }

    render(): ReactNode {
        const {
            className: classNameRaw,
            type,
            label,
            helpText,
            input,
            meta,
            disabled,
            autoFocus,
            inputRef,
            noHelper,
            onlyPositive,
            placeholder,
            onEnter,
            onPasting,
        } = this.props;
        const className = classNameRaw || '';
        if (!inputFieldValidTypes.includes(type)) {
            throw new Error(`invalid type = ${type}`);
        }
        const {value, name} = input;
        const {pasting} = this.state;
        const {asyncValidating, submitting} = meta;
        const nonEmpty = Boolean(input?.value);
        const classes = classNames('form-group', 'w-100', 'position-relative', className);

        const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
            if (
                type === 'number' &&
                (!isNumber(Number(evt.target.value)) || onlyPositive && Number(evt.target.value) < 0)
            ) {
                this.handleChange(opt('0'));
            } else {
                this.handleChange(opt(evt.target.value));
            }

            if (onPasting) {
                if (pasting) {
                    this.setState({
                        pasting: false,
                    });
                    onPasting(evt.target.value);
                }
            }
        };

        const handlePaste = () => {
            this.setState({
                pasting: true,
            });
        };

        const handleBlur = (_evt: FocusEvent<HTMLInputElement>) => {
            if (type === 'number' && !isNumber(Number(value))) {
                this.handleBlur(opt('0'));
            } else {
                this.handleBlur(opt(value));
            }
        };

        const handleFocus = (_evt: FocusEvent<HTMLInputElement>) => {
            this.handleFocus(opt(value));
        };

        const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && onEnter) {
                event.preventDefault();
                onEnter();
            }
        };

        this.inputRef = inputRef || this.inputRef;

        return (
            <div className={classes}>
                {!noHelper &&
                    <Fragment>
                        <HelpText>
                            {helpText}
                        </HelpText>
                        <div className={`${styles['helper-pt15']}`} />
                    </Fragment>
                }
                <input
                    className={`form-control form-custom ${nonEmpty ? styles['non-empty'] : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleKeydown}
                    onPaste={handlePaste}
                    value={this.getFormValue().orElse('')}
                    type={type}
                    id={name}
                    disabled={asyncValidating || submitting || disabled}
                    autoFocus={autoFocus}
                    ref={this.inputRef}
                    placeholder={placeholder}
                />
                <div className={styles['animated-underline']} />
                <div className={styles['form-placeholder']}>{label}</div>
            </div>
        );
    }
}

export default Input;
