import {deletePickupLineSaga} from './deletePickupLineSaga';
import {getPickupLinesOptionsSaga} from './getPickupLinesOptionsSaga';
import {getPickupLinesSaga} from './getPickupLinesSaga';
import {getUnassignedDairiesSaga} from './getUnassignedDairiesSaga';
import {postPickupLineSaga} from './postPickupLineSaga';
import {updatePickupLineSaga} from './updatePickupLine';
import {updatePickupLinesOptionsSaga} from './updatePickupLinesOptionsSaga';
import {validatePickupLineNameSaga} from './validatePickupLineNameSaga';
import {approvePickupLinePlanSaga} from './approvePickupLinePlanSaga';
import {getPickupLineRequestsSaga} from './getPickupLineRequestsSaga';
import {getPickupLineRequestsExportSaga} from './getPickupLineRequestsExportSaga';
import {getPickupLineDairyPlanSaga} from './getPickupLineDairyPlanSaga';
import {updatePickupLineDairyPlanSaga} from './updatePickupLineDairyPlanSaga';

export const pickupLinesSagas = {
    getPickupLinesSaga,
    postPickupLineSaga,
    validatePickupLineNameSaga,
    getUnassignedDairiesSaga,
    deletePickupLineSaga,
    updatePickupLineSaga,
    getPickupLinesOptionsSaga,
    updatePickupLinesOptionsSaga,
    approvePickupLinePlanSaga,
    getPickupLineRequestsSaga,
    getPickupLineRequestsExportSaga,
    getPickupLineDairyPlanSaga,
    updatePickupLineDairyPlanSaga,
};
