import {uniqBy} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {setLastCreatedSample, AddSampleInOrderCreationAction} from 'order/actions';
import {formValuesF, blurF, touchF, resetF} from 'utils/formHelpers';

import {prepareSample, getNewSampleOrder} from './addSampleUtils';

function* execute(action: AddSampleInOrderCreationAction): SagaIterator {
    const createOrder = yield* select(formValuesF('createOrder'));
    const oldSamples = createOrder.map(x => x.samples).orElse([]);
    const createSample = action.payload;
    const newSample = prepareSample(createSample, getNewSampleOrder(oldSamples));
    const newSamples = uniqBy(x => x.barcode, [newSample, ...oldSamples]);

    if (oldSamples.length === newSamples.length) { return; }

    yield* put(blurF('createOrder', 'samples', newSamples));
    yield* put(touchF('createOrder', 'samples'));
    yield* put(resetF('createSample'));
    yield* put(setLastCreatedSample(createSample));
}

export function* addSampleInOrderCreationSaga(): SagaIterator {
    yield takeLatestF('order/ADD_SAMPLE_IN_ORDER_CREATION', execute);
}
