import React, {Component, ReactNode} from 'react';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {CustomerView} from 'types/model/customers/CustomerView';
import RowActions from './RowActions';
import {Sort} from 'types/generic/Sort';
import {Filter} from 'tables/types/Filter';
import {CustomersPage} from 'types/model/customers/CustomerPage';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {priceClassOptions} from 'user/types/PriceClassOptions';
import {LoaderWrapper} from 'layout/components';

const limit = convertStringToLimitedLengthElement(30);

type CustomerRow = Omit<CustomerView, 'name' | 'email'> & {
    email: string | ReactNode,
    name: string | ReactNode,
    actions: ReactNode,
};

interface Props {
    customersPage: CustomersPage | null;
    sort: Sort;
    filter: Filter;

    changeSort: (property: string) => void;
    changePage: (page: number) => void;
    changePageSize(size: number): void;
    changeFilter: (accessor: string, type: string, values: string[]) => void;
    resetFilter: () => void;
    filterCustomers: () => void;
}

export const definedFilters: FilterDefinition<keyof CustomerRow>[] = [
    {key: 'code', type: 'equals'},
    {key: 'name', type: 'equals'},
    {key: 'firstName', type: 'equals'},
    {key: 'lastName', type: 'equals'},
    {key: 'email', type: 'equals'},
    {key: 'phone', type: 'equals'},
    {key: 'priceClass', type: 'select'},
];

class CustomersTable extends Component<Props> {
    render(): ReactNode {
        const {
            customersPage,
            sort,
            changeSort,
            changePage,
            changePageSize,
            changeFilter,
            resetFilter,
            filter,
            filterCustomers,
        } = this.props;

        return (
            <LoaderWrapper showLoader={!customersPage}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData(customersPage ? customersPage.content : [])}
                    handleSortChange={changeSort}
                    sortBy={sort.property}
                    sortDir={sort.direction}
                    hasFooter
                    handlePageChange={changePage}
                    handlePageSizeChange={changePageSize}
                    page={customersPage?.pageable.pageNumber}
                    pageSize={customersPage?.pageable.pageSize}
                    total={customersPage?.totalElements}
                    hasHeader
                    handleFilterChange={changeFilter}
                    handleFilterClear={resetFilter}
                    handleFilterSubmit={filterCustomers}
                    filter={filter}
                />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<CustomerRow>[] {
        return [
            {
                accessor: 'code',
                header: 'Kód',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'name',
                header: 'Objednatel',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'firstName',
                header: 'Jméno',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'lastName',
                header: 'Příjmení',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'email',
                header: 'Email',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'phone',
                header: 'Telefon',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'priceClass',
                header: 'Cenová třída',
                type: ColumnType.SelectOption,
                selectOptions: priceClassOptions,
                filterable: true,
                filterType: 'select',
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getData(customers: CustomerView[]): Row<CustomerRow>[] {
        return customers.map(customer => ({
            ...customer,
            name: limit(customer.name),
            email: limit(customer.email),
            actions: <RowActions customer={customer} />,
        }));
    }
}

export default CustomersTable;
