import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF, showSuccess} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ImportSamplesSubsidyAction} from '../actions';
import Api from '../api';
import {reloadMeasurements} from './utils/measurementsSagaUtils';

const title = 'Import dotací';

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Import byl úspěšný'));
    yield* call(reloadMeasurements);
}

function* execute({payload: {file}}: ImportSamplesSubsidyAction): SagaIterator {
    const response = yield* call(Api.importSamplesSubsidy, file);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleResponseError, response, title);
}

export function* importSamplesSubsidySaga(): SagaIterator {
    yield takeLatestF('measurement/IMPORT_SAMPLES_SUBSIDY', execute);
}
