import * as t from 'io-ts';

import {UuidSchema} from 'types/model/common/Uuid';

const optionalPart = t.partial({});

const requiredPart = t.interface({
    uuid: UuidSchema,
});

export const UuidDetailsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UuidDetails extends t.TypeOf<typeof UuidDetailsSchema> {}
