import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {loadDataItem, updateDataItem} from 'dataItem/actions';
import DataItemForm from '../components/DataItemForm';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {SelectOptions} from 'forms/components/BasicSelect';
import {loadOperatingProcedures} from 'operatingProcedure/actions';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface StateProps {
    operatingProcedures: OperatingProcedure[] | null;
}

interface DispatchProps {
    loadOperatingProcedures(): void;
    loadDataItem(id: number): void;
    updateDataItem(id: number): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class DataItemEdit extends Component<Props> {
    private get dataItemId(): number {
        const {match} = this.props;
        return Number(match.params.id);
    }

    private get operatingProceduresOptions(): SelectOptions<number> {
        const {operatingProcedures} = this.props;
        if (!operatingProcedures) {
            return [];
        }

        return operatingProcedures.map(operatingProcedure => ({
            value: operatingProcedure.id,
            label: operatingProcedure.name,
        }));
    }

    componentDidMount(): void {
        const {loadDataItem, loadOperatingProcedures} = this.props;

        loadOperatingProcedures();
        loadDataItem(this.dataItemId);
    }

    render(): ReactNode {
        return (
            <Layout.ItemPage
                title="Úprava datové položky"
                backLabel="Datové položky"
            >
                <DataItemForm
                    onSubmit={this.submitForm}
                    operatingProceduresOptions={this.operatingProceduresOptions}
                />
            </Layout.ItemPage>
        );
    }

    private submitForm = () => {
        const {updateDataItem} = this.props;

        updateDataItem(this.dataItemId);
    };
}

const mapStateToProps = (state: StoreState): StateProps => ({
    operatingProcedures: state.operatingProcedure.operatingProcedures || [],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadOperatingProcedures: () => dispatch(loadOperatingProcedures()),
    loadDataItem: (id: number) => dispatch(loadDataItem(id)),
    updateDataItem: (id: number) => dispatch(updateDataItem({id})),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(DataItemEdit) as ComponentClass<OuterProps>;
