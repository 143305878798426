import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {CustomerRoleSchema} from 'types/model/enumeration/Role';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    firstName: t.string,
    lastName: t.string,
    username: t.string,
    role: CustomerRoleSchema,
    customerId: IntegerSchema,
    pickupLineAccess: t.boolean,
    tag: t.literal('UserDetailsForCustomer'),
});

export const UserDetailsForCustomerSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UserDetailsForCustomer extends t.TypeOf<typeof UserDetailsForCustomerSchema> {}
