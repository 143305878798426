import React, {Component, ReactNode} from 'react';

import {DownloadFileActionArguments} from 'download/actions';
import {Components as Buttons} from 'buttons';
import {OrderView} from 'types/model/orders/OrderView';

interface Props {
    order: OrderView;
    canDownloadTxtProcol: boolean;
    canDownloadInvoice: boolean;
    canDownloadPdfProcol: boolean;
    canDownloadSamples: boolean;
    selected: boolean;

    onDownload(args: DownloadFileActionArguments<undefined>): void;
    onDownloadInvoice(orderId: number): void;
    onSelect(id: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            order,
            canDownloadTxtProcol,
            canDownloadPdfProcol,
            canDownloadSamples,
            onSelect,
            selected,
            canDownloadInvoice,
            onDownloadInvoice,
        } = this.props;
        const {orderId} = order;

        return (
            <div>
                {order.hasProtocol && canDownloadPdfProcol &&
                 <Buttons.RowActionButton
                     type="DOWNLOAD_PDF"
                     title="Stáhnout protokol (PDF)"
                     onClick={this.downloadPdf}
                 />}
                {order.hasProtocol && canDownloadTxtProcol &&
                 <Buttons.RowActionButton
                     type="DOWNLOAD_TXT"
                     title="Stáhnout protokol (TXT)"
                     onClick={this.downloadTxt}
                 />}
                {order.hasInvoice && canDownloadInvoice &&
                 <Buttons.RowActionButton
                     type="DOWNLOAD_CSV"
                     title="Stáhnout fakturu (CSV)"
                     onClick={() => onDownloadInvoice(order.orderId)}
                 />}
                {order.state  !== 'NEW' && canDownloadSamples &&
                 <Buttons.RowActionButton
                     type="DOWNLOAD_PDF"
                     title="Stáhnout opis vzorků"
                     onClick={this.downloadSamples}
                 />}
                <Buttons.RowActionButton
                    type="DOWNLOAD"
                    title="Stáhnout přepravní lístek"
                    onClick={this.downloadShippingTicket}
                />
                <Buttons.RowActionButton
                    type="DETAIL"
                    to={`/orders/${orderId}`}
                />
                <Buttons.RowActionButton
                    type={selected ? 'UNSELECT' : 'SELECT'}
                    title={selected ? 'Zrušit výběr' : 'Vybrat'}
                    onClick={() => onSelect(orderId)}
                />
            </div>
        );
    }

    private downloadShippingTicket = () => {
        const {onDownload, order: {orderId}} = this.props;

        onDownload({url: `/orders/${orderId}/shipping-ticket`});
    };

    private downloadTxt = () => {
        const {onDownload, order: {orderId}} = this.props;

        onDownload({url: `/orders/${orderId}/protocols/txt`});
    };

    private downloadPdf = () => {
        const {onDownload, order: {orderId}} = this.props;

        onDownload({url: `/orders/${orderId}/protocols/latest`});
    };

    private downloadSamples = () => {
        const {onDownload, order: {orderId}} = this.props;

        onDownload({url: `/orders/${orderId}/samples/export`});
    };
}

export default RowActions;
