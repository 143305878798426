import {Role} from 'types/model/enumeration/Role';

export const getHomepageOfRole = (role: Role | undefined): string => {
    switch (role) {
        case 'PICKUP_LINE_ADMIN':
            return '/pickup-lines/overview';

        default:
            return '/orders';
    }
};
