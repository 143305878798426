import React from 'react';
import {flow, isEmpty} from 'lodash/fp';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {opt} from 'ts-opt';

import {Ares} from 'types/model/ares/Ares';
import {formValuesF, isFieldValidUnsafe} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {loadAres, register, resetAresValues} from '../actions';
import RegisterForm from '../components/RegisterForm';
import {RegisterFormValues} from '../types/RegisterFormValues';

interface Props {
    formValue?: RegisterFormValues;
    ares?: Ares;
    isAresLoading: boolean;
    // TODO: formErrors Type
    isIcoValid: boolean;

    handleRegister(): void;

    handleLoadAres(ico: string): void;

    handleResetAres(): void;
}

const Register = (props: Props) => {
    const {handleRegister, formValue, handleLoadAres, ares, isAresLoading, handleResetAres, isIcoValid} = props;
    const values = opt(formValue).orUndef();
    const onLoadAres = () => opt(formValue).chainToOpt(x =>
        x.invoiceDetails.ico && handleLoadAres(x.invoiceDetails.ico)).orCrash('Missing ico value');
    return (
        <Layout.Page>
            <h1 className="text-center">Registrace</h1>
            <RegisterForm
                onSubmit={handleRegister}
                onLoadAres={onLoadAres}
                hasSameBillingAddress={values ? values.hasSameBillingAddress : false}
                hasNoIco={values ? values.hasNoIco : false}
                isAresLoaded={!isEmpty(ares)}
                isAresLoading={isAresLoading}
                onIcoChange={handleResetAres}
                onHasNoIcoChange={handleResetAres}
                isIcoValid={isIcoValid}
            />
        </Layout.Page>
    );
};

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    formValue: formValuesF('register')(state).orUndef(),
    ares: state.user.ares.orUndef(),
    isAresLoading: state.user.isAresLoading,
    isIcoValid: isFieldValidUnsafe('register', 'invoiceDetails.ico')(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleRegister: () => dispatch(register()),
    handleLoadAres: (ico: string) => dispatch(loadAres(ico)),
    handleResetAres: () => dispatch(resetAresValues()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Register) as typeof Register;
