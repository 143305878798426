import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';
import {todayDate} from 'favorlogic-utils';

import {Components as Layout} from 'layout';
import {createMeasurement, resetSearchSamples, searchSamples, loadRequestedDataItems} from 'measurement/actions';
import {SelectOptions} from 'forms/components/BasicSelect';
import {StoreState} from 'app/types/StoreState';
import {DataItem} from 'types/model/dataItems/DataItem';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {UserDetails} from 'types/model/users/UserDetails';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {loadLaborers} from 'user/actions';
import withUser, {WithUserProps} from 'user/components/withUser';
import {formValuesF, initializeF} from 'utils/formHelpers';
import {loadDataItems} from 'dataItem/actions';
import {loadOperatingProcedures} from 'operatingProcedure/actions';

import {SampleMeasurementFormValues} from '../type/SampleMeasurementFormValues';
import SampleMeasurementForm, {measurementInitialValues} from '../components/SampleMeasurementForm';
import {buildDataItemOptions} from '../utils/buildDataItemOptions';
import {buildUserOptions} from '../utils/buildUserOptions';
import {buildProcedureOptions} from '../utils/buildProcedureOptions';

interface OuterProps {}

interface StateProps {
    samples: Opt<SampleSearch[]>;
    laborers: Opt<UserDetails[]>;
    dataItems: DataItem[] | null;
    procedures: OperatingProcedure[] | null;
    values?: SampleMeasurementFormValues;
    requestedDataItemIds: number[];
}

interface DispatchProps {
    handleLoadRequestedDataItems(): void;
    handleLoadLaborers(): void;
    handleSearchSamples(dataItemId: number | null): void;
    handleLoadDataItems(): void;
    handleLoadProcedures(): void;
    handleCreateMeasurement(): void;
    initializeForm(values: SampleMeasurementFormValues): void;
}

type UserProps = Pick<WithUserProps, 'currentUser'>;
type Props = OuterProps & StateProps & DispatchProps & UserProps;

class NewSample extends Component<Props> {
    private get dataItem(): Opt<DataItem> {
        const {values} = this.props;

        return opt(values).chainToOpt(v => v.dataItem);
    }

    private get barcode(): Opt<string> {
        const {values} = this.props;

        return opt(values).chainToOpt(v => v.barcode);
    }

    componentDidMount(): void {
        const {
            handleLoadLaborers,
            handleLoadDataItems,
            handleLoadRequestedDataItems,
            handleLoadProcedures,
            initializeForm,
            currentUser,
        } = this.props;

        initializeForm({
            ...measurementInitialValues,
            measured: todayDate(),
            measuredById: currentUser.orCrash('missing user').id,
        });
        handleLoadLaborers();
        handleLoadDataItems();
        handleLoadRequestedDataItems();
        handleLoadProcedures();
    }

    render(): ReactNode {
        const {
            handleCreateMeasurement,
            handleSearchSamples,
            requestedDataItemIds,
            samples,
        } = this.props;

        return (
            <Layout.ItemPage
                title="Nové měření"
                backLabel="Měření"
            >
                {isEmpty(requestedDataItemIds)
                    ? <Layout.Panel>
                        <div className="p-4 d-flex justify-content-center">
                            Všechny požadované vzorky byly naměřeny.
                        </div>
                    </Layout.Panel>
                    : <SampleMeasurementForm
                        dataItemOptions={this.buildDataItemOptions()}
                        userOptions={this.buildUserOptions()}
                        procedureOptions={this.buildProcedureOptions()}
                        onSampleSearch={handleSearchSamples}
                        samples={samples.orNull()}
                        selectedDataItem={this.dataItem.orNull()}
                        selectedBarcode={this.barcode.orNull()}
                        onSubmit={handleCreateMeasurement}
                    />}
            </Layout.ItemPage >
        );
    }

    private buildDataItemOptions(): SelectOptions<DataItem> {
        const {dataItems, requestedDataItemIds} = this.props;

        return buildDataItemOptions(dataItems, requestedDataItemIds);
    }

    private buildUserOptions(): SelectOptions<number> {
        const {laborers} = this.props;

        return buildUserOptions(laborers);
    }

    private buildProcedureOptions(): SelectOptions<number> {
        const {procedures} = this.props;

        return buildProcedureOptions(procedures, this.dataItem);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    laborers: state.user.laborers,
    samples: state.measurement.samplesSearch,
    dataItems: state.dataItem.dataItems,
    procedures: state.operatingProcedure.operatingProcedures,
    requestedDataItemIds: state.measurement.requestedDataItems,
    values: formValuesF('sampleMeasurement')(state).orUndef(),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadLaborers: () => dispatch(loadLaborers()),
    handleSearchSamples: (dataItemId: number | null) =>
        opt(dataItemId).caseOf(
            dataItemId => {
                dispatch(resetSearchSamples());
                dispatch(searchSamples({dataItemId}));
            },
            () => { dispatch(resetSearchSamples()); },
        ),
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleLoadRequestedDataItems: () => dispatch(loadRequestedDataItems()),
    handleLoadProcedures: () => dispatch(loadOperatingProcedures()),
    handleCreateMeasurement: () => dispatch(createMeasurement()),
    initializeForm: (values: SampleMeasurementFormValues) => dispatch(initializeF('sampleMeasurement', values)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
    withUser,
])(NewSample) as ComponentClass<OuterProps>;
