import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {convertStringToFloat, ErrorResponse, showSuccess, takeLatestF, ErrorsFromBE} from 'favorlogic-utils';

import {CreateMeasurement} from 'types/model/measurements/CreateMeasurement';
import {CreateMeasurements} from 'types/model/measurements/CreateMeasurements';
import {formValuesF, startSubmitF, stopSubmitF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';

import {
    CreateMeasurementsAction,
    createMeasurementsError,
    createMeasurementsSuccess,
} from '../actions';
import {DataItemMeasurementFormValues} from '../type/DataItemMeasurementFormValues';
import {DataItemMeasurementsFormValues} from '../type/DataItemMeasurementsFormValues';
import Api from '../api';
import {isMeasurementValid} from '../components/DataItemMeasurementsForm/validations';

const title = 'Vytvoření měření';

export function toCreateMeasurement(x: DataItemMeasurementFormValues): CreateMeasurement {
    return {
        sampleId: opt(x.sampleId).orCrash('missing sample'),
        result: opt(x.result).chain(convertStringToFloat).orCrash('invalid result'),
        dilution: opt(x.dilution).chain(convertStringToFloat).orCrash('invalid dilution'),
        procedureId: opt(x.procedureId).orCrash('missing procedure'),
    };
}

export function buildCreateMeasurements(values: DataItemMeasurementsFormValues): CreateMeasurements {
    return {
        measured: opt(values.measured).orCrash('missing date'),
        dataItemId: opt(values.dataItem).orCrash('missing data item').id,
        measuredById: opt(values.measuredById).orCrash('missing user'),
        measurements: values.measurements.filter(isMeasurementValid).map(toCreateMeasurement),
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(createMeasurementsError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('dataItemMeasurements'));
}

function* handleSuccess(): SagaIterator {
    yield* put(createMeasurementsSuccess());
    yield* put(stopSubmitF('dataItemMeasurements'));
    yield* put(showSuccess(title, 'Měření úspěšně vytvořeno.'));
    yield* put(routerActions.goBack());
}

function* execute(_action: CreateMeasurementsAction): SagaIterator {
    yield* put(startSubmitF('dataItemMeasurements'));

    const values = (yield* select(formValuesF('dataItemMeasurements'))).orCrash('missing form values');
    const response = yield* call(Api.createMeasurements, buildCreateMeasurements(values));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* createMeasurementsSaga(): SagaIterator {
    yield takeLatestF('measurement/CREATE_MEASUREMENTS', execute);
}
