import {Reducer, combineReducers, AnyAction, ReducersMapObject} from 'redux';
import {FormReducerMapObject, reducer as form} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {toastrReducer} from 'favorlogic-utils';

import {reducer as confirmDialog} from 'confirmDialog';
import {reducer as analysis, formReducer as analysisFormReducer} from 'analysis';
import {reducer as layout} from 'layout';
import {reducer as order, formReducer as orderFormReducer} from 'order';
import {reducer as sample, formReducer as sampleFormReducer} from 'sample';
import {reducer as supplier, formReducer as supplierFormReducer} from 'supplier';
import {reducer as supplyChain, formReducer as supplyChainFormReducer} from 'supplyChain';
import {reducer as user, formReducer as userFormReducer} from 'user';
import {reducer as wizard} from 'wizard';
import {reducer as measurement, formReducer as measurementFormReducer} from 'measurement';
import {reducer as operatingProcedure, formReducer as operatingProcedureFormReducer} from 'operatingProcedure';
import {reducer as dataItem, formReducer as dataItemFormReducer} from 'dataItem';
import {reducer as device, formReducer as deviceFormReducer} from 'device';
import {reducer as administration} from 'administration';
import {pickupLinereducer as pickupLine, pickupLineFormReducer} from 'pickupLine';

import {StoreState} from './types/StoreState';

const formReducers = {
    ...userFormReducer,
    ...supplierFormReducer,
    ...orderFormReducer,
    ...supplyChainFormReducer,
    ...analysisFormReducer,
    ...sampleFormReducer,
    ...measurementFormReducer,
    ...operatingProcedureFormReducer,
    ...dataItemFormReducer,
    ...deviceFormReducer,
    ...pickupLineFormReducer,
};

type StateWithoutRouter = Omit<StoreState, 'router'>;

const reducers: ReducersMapObject<StateWithoutRouter, AnyAction> = {
    toastr: toastrReducer,
    form: form.plugin(formReducers as FormReducerMapObject) as Reducer,

    confirmDialog,
    user,
    layout,
    supplier,
    order,
    supplyChain,
    analysis,
    wizard,
    sample,
    measurement,
    operatingProcedure,
    dataItem,
    device,
    administration,
    pickupLine,
};

export const createReducer = (history: History): Reducer<StoreState, AnyAction> =>
    combineReducers<StoreState>({
        router: connectRouter(history),
        ...reducers,
    });
