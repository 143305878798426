exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-buttons-components-Group-___styles__group___7lmaH > :not(:last-child) {\n  margin-right: 0.5rem; }\n\n.src-modules-buttons-components-Group-___styles__group___7lmaH > * {\n  margin-top: 0.15rem;\n  margin-bottom: 0.15rem; }\n", ""]);

// exports
exports.locals = {
	"group": "src-modules-buttons-components-Group-___styles__group___7lmaH"
};