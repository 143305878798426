import {isEmpty} from 'lodash/fp';
import {Dispatch} from 'redux';

import {Config} from 'app/Config';
import {CustomerValidation} from 'types/model/customers/CustomerValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {validateCustomer} from '../../actions';
import {RegisterFormValues} from '../../types/RegisterFormValues';

const formConfig = Config.forms;

export const validate = (values: RegisterFormValues): Errors<RegisterFormValues> => {
    if (isEmpty(values)) { return {}; }
    const validator = new DairyValidator(values);

    validator.nonEmpty('firstName', 'Jméno');
    validator.nonEmpty('lastName', 'Příjmení');
    validator.nonEmpty('name', 'Objednatel');

    validator.address('address');
    if (!values.hasSameBillingAddress) {
        validator.address('billingAddress');
    }

    validator.invoiceDetails('invoiceDetails', {
        nonEmptyIcoWhenDicIsFilled: true,
        icoRequired: !values.hasNoIco,
    });

    validator.nonEmpty('username', 'Uživatelské jméno');
    validator.minStringLength('username', formConfig.usernameMinLen, 'Uživatelské jméno');
    validator.nonEmpty('password', 'Heslo');
    validator.minStringLength('password', formConfig.passwordMinLen, 'Heslo');
    validator.nonEmpty('checkPassword', 'Potvrzení hesla');
    validator.equal('password', 'checkPassword', 'Heslo', 'Potvrzení hesla', true);

    return validator.generateErrorsObject();
};

export const asyncValidate = (
    values: RegisterFormValues,
    dispatch: Dispatch,
): Promise<boolean> => {
    const validationValues: CustomerValidation = {
        username: values.username,
        email: values.invoiceDetails.email,
        ico: values.invoiceDetails.ico,
        dic: values.invoiceDetails.dic,
    };

    return new Promise<boolean>((resolve, reject) => {
        dispatch(validateCustomer({
            values: validationValues,
            resolve,
            reject,
            isCreate: true,
            isAdminOrLaborer: false,
        }));
    });
};
