import * as t from 'io-ts';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    username: t.string,
    password: t.string,
});

export const UserLoginDetailsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UserLoginDetails extends t.TypeOf<typeof UserLoginDetailsSchema> {}
