import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {LoadMilkRoomAction, loadMilkRoomError, loadMilkRoomSuccess, prefillUpdateMilkRoomForm} from '../actions';
import Api from '../api';

function* execute(action: LoadMilkRoomAction): SagaIterator {
    const errorTitle = 'Načtení dodavatele';
    const response = yield* call(Api.getMilkRoom, action.payload.milkRoomId);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadMilkRoomError(error));
    }

    const milkRoom = response.data;
    yield* put(loadMilkRoomSuccess(milkRoom));
    yield* put(prefillUpdateMilkRoomForm({
        veterinaryId: milkRoom.veterinaryId,
        code: milkRoom.code,
        name: milkRoom.name,
    }));
}

export function* loadMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/LOAD_MILK_ROOM', execute);
}
