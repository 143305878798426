import {optEmptyArray, optEmptyObject} from 'ts-opt';

import {DeviceFormValues} from 'device/types/DeviceFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {DeviceDataItemFormValues} from 'device/types/DeviceDataItemValues';

type Values = DeviceFormValues;
type DataItemErrors = Partial<Record<keyof DeviceDataItemFormValues, string>>;

function validateDataItem(values: DeviceDataItemFormValues): DataItemErrors | undefined {
    const errors: DataItemErrors = {};

    if (!values.dataItemId) {
        errors.dataItemId = 'Dartová položka musí být vybrána';
    }

    if (!values.maximum && (values.minimum || values.action)) {
        errors.maximum = 'Maximum je povinné pokud je nastaveno minimum nebo akce';
    }

    if (!values.minimum && (values.maximum || values.action)) {
        errors.minimum = 'Minimum je povinné pokud je nastaveno maximum nebo akce';
    }

    if (!values.action && (values.maximum || values.minimum)) {
        errors.action = 'Akce je povinná pokud je nastaveno maximum nebo minimum';
    }

    if (values.maximum && values.minimum &&
        Number(values.minimum) > Number(values.maximum)) {
        errors.minimum = 'Minimum musí být menší nebo rovno maximu.';
    }

    return optEmptyObject(errors).orUndef();
}

function validateDataItems(values: DeviceDataItemFormValues[]): DataItemErrors[] | undefined {
    const errors: DataItemErrors[] = [];

    values.forEach((value, index) => {
        const itemErrors = validateDataItem(value);

        if (itemErrors) {
            errors[index] = itemErrors;
        }
    });

    return optEmptyArray(errors).orUndef();
}

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název');
    validator.nonEmpty('dataItems', 'Datové položky měřené přístrojem');

    const errors = validator.generateErrorsObject();

    if (!errors.dataItems) {
        errors.dataItems = validateDataItems(values.dataItems);
    }

    return errors;
};
