exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-pickupLine-components-PickupLinesTables-___styles__cards___1hVDY > [class*='card'] {\n  background-color: transparent !important;\n  border: 0px !important; }\n\n@media (min-width: 480px) {\n  .src-modules-pickupLine-components-PickupLinesTables-___styles__cards___1hVDY {\n    column-count: 1 !important; } }\n\n@media (min-width: 768px) {\n  .src-modules-pickupLine-components-PickupLinesTables-___styles__cards___1hVDY {\n    column-count: 2 !important; } }\n\n@media (min-width: 1200px) {\n  .src-modules-pickupLine-components-PickupLinesTables-___styles__cards___1hVDY {\n    column-count: 3 !important; } }\n", ""]);

// exports
exports.locals = {
	"cards": "src-modules-pickupLine-components-PickupLinesTables-___styles__cards___1hVDY"
};