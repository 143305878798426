import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import RowActions from './RowActions';
import {LoaderWrapper} from 'layout/components';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';

const limitName = convertStringToLimitedLengthElement(55);
const limitDescription = convertStringToLimitedLengthElement(35);

export interface OperatingProcedureRow {
    id: number;
    name: ReactNode | string;
    description: ReactNode | string;
    actions: ReactNode;
}

interface OuterProps {
    operatingProcedures: OperatingProcedure[] | null;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

class OperatingProceduresTable extends Component<Props> {
    private static getColumns(): Column<OperatingProcedureRow>[] {
        return [
            {
                accessor: 'id',
                header: 'ID',
                type: ColumnType.Node,
            },
            {
                accessor: 'name',
                header: 'Název',
                type: ColumnType.Node,
            },
            {
                accessor: 'description',
                header: 'Popis',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    render(): React.ReactNode {
        const {operatingProcedures} = this.props;
        return (
            <LoaderWrapper showLoader={!operatingProcedures}>
                <Tables.Table
                    columns={OperatingProceduresTable.getColumns()}
                    rows={this.getData()}
                />
            </LoaderWrapper>
        );
    }

    private getData(): Row<OperatingProcedureRow>[] {
        const {operatingProcedures} = this.props;
        if (!operatingProcedures) {
            return [];
        }

        return operatingProcedures.map(operatingProcedure => {
            const actions =
                <RowActions
                    operatingProcedure={operatingProcedure}
                />
            ;
            const data  = {
                ...operatingProcedure,
                name: limitName(operatingProcedure.name),
                description: limitDescription(operatingProcedure.description),
            };

            return {
                ...data,
                actions,
            };
        });
    }
}

export default OperatingProceduresTable;
