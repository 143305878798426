import React, {FunctionComponent} from 'react';
import {opt} from 'ts-opt';
import {emptyStringToUndefined} from 'favorlogic-utils';

import DateTime from 'forms/components/BasicDateTime';
import {FilterChangeHandler} from 'tables/types/FilterChangeHandler';
import {FilterPart} from 'tables/types/FilterPart';

interface Props {
    fromFilter?: FilterPart;
    toFilter?: FilterPart;
    accessor: string;
    placeholder?: string;

    onChange: FilterChangeHandler;
}

type FilterType = 'From' | 'To';

const DateRangeFilter: FunctionComponent<Props> = props => {
    const {fromFilter, toFilter, accessor, onChange} = props;

    const onDateChange = (value: string | null, type: FilterType) => {
        onChange(`${accessor}${type}`, 'date', value ? [value] : []);
    };

    const getValue = (filter?: FilterPart) => {
        if (!filter || !filter.values) {
            return '';
        }
        return filter.values[0] || '';
    };

    const fromDate: Date | undefined = opt(emptyStringToUndefined(getValue(fromFilter)))
        .chainToOpt(x => new Date(x)).orUndef();
    const toDate: Date | undefined = opt(emptyStringToUndefined(getValue(toFilter)))
        .chainToOpt(x => new Date(x)).orUndef();

    return (
        <div className="d-flex align-items-center">
            <DateTime
                value={getValue(fromFilter)}
                placeholder="Od"
                onChange={value => onDateChange(value, 'From')}
                mode="DATE"
                maxDate={toDate}
                adjustDateOnChange={false}
            />

            <span className="mx-1"> - </span>

            <DateTime
                value={getValue(toFilter)}
                placeholder="Do"
                onChange={value => onDateChange(value, 'To')}
                mode="DATE"
                minDate={fromDate}
                adjustDateOnChange={false}
            />
        </div>
    );
};

export default DateRangeFilter;
