import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF, ErrorsFromBE, ErrorResponse, SuccessResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {UserDetailsForCustomer} from 'types/model/users/UserDetailsForCustomer';
import {initializeF} from 'utils/formHelpers';

import {loadUserError, loadUserSuccess, LoadUserAction} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, 'Načtení uživatele');
    yield* put(loadUserError(error));
}

function* handleSuccessResponse({data: user}: SuccessResponse<UserDetailsForCustomer>): SagaIterator {
    const {firstName, lastName} = user;

    yield* put(initializeF('updateUser', {firstName, lastName}));
    yield* put(loadUserSuccess(user));
}

export function* execute({payload: {userId}}: LoadUserAction): SagaIterator {
    const response = yield* call(Api.getUser, userId);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadUserSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_USER', execute);
}
