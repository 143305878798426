import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {SuccessResponse, takeLatestF} from 'favorlogic-utils';

import {OrderDetails} from 'types/model/orders/OrderDetails';
import {handleResponseError} from 'utils/handleResponseError';

import {LoadOrderAction, loadOrderSuccess, prefillUpdateOrderDetailsForm} from '../actions';
import Api from '../api';
import {UpdateOrderFormValues} from '../types/UpdateOrderFormValues';
import {isOrderDetailsForLaborer} from '../utils/orderUtils';

const title = 'Načtení objednávky';

export function getUpdateFormPrefill(order: OrderDetails): UpdateOrderFormValues {
    return isOrderDetailsForLaborer(order)
        ? {
            deliveryType: order.deliveryType,
            customerDetails: order.customerDetails,
            customerId: order.customerDetails.id,
            note: order.note,
            internNote: order.internNote,
            state: order.state,
        }
        : {
            deliveryType: order.deliveryType,
            customerDetails: order.customerDetails,
            customerId: order.customerDetails.id,
            note: order.note,
            state: order.state,
        }
    ;
}

function* handleSuccessResponse({data: order}: SuccessResponse<OrderDetails>): SagaIterator {
    const updateFormPrefill = getUpdateFormPrefill(order);

    yield* put(loadOrderSuccess(order));
    yield* put(prefillUpdateOrderDetailsForm(updateFormPrefill));
}

function* execute({payload: {orderId}}: LoadOrderAction): SagaIterator {
    const response = yield* call(Api.getOrder, orderId);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleResponseError, response, title);
}

export function* loadOrderSaga(): SagaIterator {
    yield takeLatestF('order/LOAD_ORDER', execute);
}
