import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {OrderStateSchema} from 'types/model/enumeration/OrderState';
import {SampleDeliveryTypeSchema} from 'types/model/enumeration/SampleDeliveryType';
import {CustomerSchema} from 'types/model/customers/Customer';

const optionalPart = t.partial({
    accepted: DateStringSchema,
    note: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    created: DateStringSchema,
    state: OrderStateSchema,
    deliveryType: SampleDeliveryTypeSchema,
    customerDetails: CustomerSchema,
    hasInvoice: t.boolean,
});

export const OrderDetailsBaseSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OrderDetailsBase extends t.TypeOf<typeof OrderDetailsBaseSchema> {}
