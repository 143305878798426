import {intercalateElem, zipWithIndex} from 'favorlogic-utils';
import {find, flow, isEmpty, map} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {opt} from 'ts-opt';

import {Components as Buttons} from 'buttons';
import {Components as Icons} from 'icons';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';

import crossSvg from './cross.svg';

import styles from './styles.sass';

export type SupplyChainViewMode = 'VIEW' | 'EDIT';
export type MoveHandler = (idx: number, dir: 1 | -1) => void;
export type DeleteHandler = (idx: number) => void;

export const milkRoomIdToChainItem = (milkRooms: MilkRoom[]) =>
    (milkRoomId: number): ChainItem =>
        opt(find((x: MilkRoom) => x.id === milkRoomId, milkRooms))
            .map(x => ({milkRoom: x}))
            .orCrash('milk room not found');

export interface Props {
    milkRoomIds: number[];
    milkRooms: MilkRoom[];
    mode: SupplyChainViewMode;
    onMove?: MoveHandler;
    onDelete?: DeleteHandler;
}

export interface ChainItem {
    milkRoom: MilkRoom;
}

class SupplyChainView extends Component<Props> {
    renderItem = (x: ChainItem, idx: number, isLast: boolean): ReactNode => {
        const {
            mode,
            onMove,
            onDelete,
        } = this.props;
        const handleMove = (dir: -1 | 1) => () => {
            if (onMove) { onMove(idx, dir); }
        };
        const handleDelete = () => {
            if (onDelete) { onDelete(idx); }
        };
        const renderField = (name: string, value: string) =>
            <div className="col-12 col-md-3 d-flex justify-content-center flex-column">
                <div className="ml-2 ml-md-1 mt-2 mt-md-0">
                    <strong>{name}</strong>
                </div>
                <div className="ml-2 ml-md-1">
                    {value}
                </div>
            </div>;
        return (
            <div
                key={x.milkRoom.id}
                className={`mt-1 mb-1 d-flex w-100 ${styles.chainItem}`}
            >
                <div className="flex-grow-1 row">
                    {renderField('Dodavatel', x.milkRoom.name)}
                    {renderField('Veterinární číslo', x.milkRoom.veterinaryId)}
                    {renderField('Identifikace', x.milkRoom.code)}
                </div>
                {mode === 'EDIT' &&
                 <div className="ml-4 align-self-stretch d-flex flex-column">
                     <Buttons.Button
                         type="button"
                         onClick={handleMove(-1)}
                         importance="secondary"
                         disabled={idx === 0}
                         small
                         className={`mb-1 flex-grow-1 ${styles.itemAction}`}
                         title="Přesunout výše"
                     >
                         ▲
                     </Buttons.Button>
                     <Buttons.Button
                         type="button"
                         onClick={handleDelete}
                         importance="secondary"
                         small
                         className={`mb-1 flex-grow-1 ${styles.itemAction}`}
                         title="Odebrat z linky"
                     >
                         <Icons.SvgIcon
                             icon={crossSvg}
                             className={styles.svgIcon}
                         />
                     </Buttons.Button>
                     <Buttons.Button
                         type="button"
                         onClick={handleMove(1)}
                         importance="secondary"
                         disabled={isLast}
                         small
                         className={`flex-grow-1 ${styles.itemAction}`}
                         title="Přesunout níže"
                     >
                         ▼
                     </Buttons.Button>
                 </div>
                }
            </div>
        );
    }

    render(): ReactNode {
        const {
            milkRoomIds,
            milkRooms,
        } = this.props;
        const items = map(milkRoomIdToChainItem(milkRooms), milkRoomIds);
        const renderedItems = flow([
            zipWithIndex,
            map(([x, i]) => this.renderItem(x, i, i === items.length - 1)),
            intercalateElem(<div className={styles.chainArrow}>⇩</div>, 'w-100 d-block'),
        ])(items);
        return (
            <div className={styles.wrapper}>
                {isEmpty(items) &&
                 <div>Prozatím nebyly přidány žádné mléčnice do této linky.</div>
                }
                {renderedItems}
            </div>
        );
    }
}

export default SupplyChainView;
