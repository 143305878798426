import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadUsers, DeleteUserAction} from '../actions';
import Api from '../api';

const title = 'Smazání uživatele';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Uživatel úspěšně smazán'));
    yield* put(loadUsers());
}

export function* execute({payload: {userId}}: DeleteUserAction): SagaIterator {
    const response = yield* call(Api.deleteUser, userId);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* deleteUserSaga(): SagaIterator {
    yield takeLatestF('user/DELETE_USER', execute);
}
