import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {ErrorHandler} from 'app/components/ErrorHandler';
import logger from 'app/model/Logger';
import {Config} from 'app/Config';
import Main from 'app/components/Main';

const getAppElement = () => {
    const element = document.getElementById('app');

    if (!element) {
        throw new Error('app element not found');
    }

    return element;
};

const renderApplication = () => {
    console.log({api: Config.apiUrl});
    ReactDOM.render(
        <AppContainer>
            <ErrorHandler>
                <div className="h-100">
                    <Main />
                </div>
            </ErrorHandler>
        </AppContainer>,
        getAppElement(),
    );
};

window.onerror = function(message, source, lineno, colno, error) {
    logger.logError(error);
};

renderApplication();

if (module.hot) {
    module.hot.accept('./modules/app/components/Main/index', () => {
        renderApplication();
    });
}
