import {Type} from 'io-ts';
import {RequestMethod} from 'favorlogic-utils';

export interface DownloadFileActionArguments<Data> {
    url: string;
    params?: object;
    method?: RequestMethod;
    data?: Data;
    requestSchema?: Type<Data>;
}

export interface DownloadFileAction<Data> {
    type: 'download/DOWNLOAD_FILE';
    payload: DownloadFileActionArguments<Data>;
}

export const downloadFileAction =
    <Data>(payload: DownloadFileActionArguments<Data>): DownloadFileAction<Data> => ({
        type: 'download/DOWNLOAD_FILE',
        payload,
    });

// ---

export type DownloadAction = DownloadFileAction<unknown>;
