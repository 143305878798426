import React, {PureComponent, ReactNode} from 'react';
import {Form, FieldArray} from 'redux-form';
import {Opt, opt} from 'ts-opt';
import {find, memoize} from 'lodash/fp';
import {validateKey, crash, isArray, classNames} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {SelectedValue, SelectOptions} from 'forms/components/BasicSelect';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {DataItemMeasurementsFormValues} from 'measurement/type/DataItemMeasurementsFormValues';
import {DataItemMeasurementFormValues} from 'measurement/type/DataItemMeasurementFormValues';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {DataItem} from 'types/model/dataItems/DataItem';
import {measurementUnitNP} from 'measurement/constants';
import {genSampleOptions} from 'measurement/utils/genSampleOptions';

import {MeasurementResults, MeasurementResultsProps} from './MeasurementResults';
import {validate, warn} from './validations';

import styles from './styles.sass';

export const measurementsInitialValues: DataItemMeasurementsFormValues = {
    measured: null,
    dataItem: null,
    measuredById: null,
    measurements: [],
};

interface OwnProps {
    dataItemOptions: SelectOptions<DataItem>;
    userOptions: SelectOptions<number>;
    procedureOptions: SelectOptions<number>;
    samples: SampleSearch[] | null;
    selectedDataItem?: DataItem;

    onDataItemChanged(value: number | null): void;
}

export type Props = PropsForForm<DataItemMeasurementsFormValues, OwnProps>;

class DataItemMeasurementsForm extends PureComponent<Props> {
    private get isNP(): boolean {
        const {selectedDataItem} = this.props;

        return opt(selectedDataItem).map(x => x.measurementUnit === measurementUnitNP).orFalse();
    }

    componentDidUpdate(prevProps: Props) {
        const {samples: prevSamples} = prevProps;
        const {samples} = this.props;

        if (prevSamples !== samples) {
            this.initializeMeasurements();
        }
    }

    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
            dataItemOptions,
            userOptions,
            procedureOptions,
            selectedDataItem,
            valid,
            samples,
        } = this.props;
        const loading = Boolean(selectedDataItem) && !samples;

        return (
            <Form
                onSubmit={handleSubmit}
            >
                <Layout.Panel>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.DateTime
                                name={validateKey<DataItemMeasurementsFormValues>('measured')}
                                mode="DATE"
                                label="Datum stanovení*"
                            />
                        </div>

                        <div className="col-12 col-md-6">
                            <Fields.Select<number>
                                name={validateKey<DataItemMeasurementsFormValues>('measuredById')}
                                label="Naměřil*"
                                options={userOptions}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Select<DataItem>
                                name={validateKey<DataItemMeasurementsFormValues>('dataItem')}
                                label="Datová položka*"
                                options={dataItemOptions}
                                onFieldChange={this.onDataItemChange}
                            />
                        </div>
                    </div>
                </Layout.Panel>

                <Layout.Panel>
                    <div className={classNames('position-relative', loading && styles.resultPanelLoading)}>
                        <Layout.Loader show={submitting || loading} />
                        <div className="col-12">
                            <FieldArray<MeasurementResultsProps, DataItemMeasurementFormValues>
                                name={validateKey<DataItemMeasurementsFormValues>('measurements')}
                                component={MeasurementResults}
                                sampleOptions={this.getSampleOptions(samples)}
                                procedureOptions={procedureOptions}
                                selectedDataItem={selectedDataItem}
                                isNP={this.isNP}
                            />
                        </div>
                    </div>
                </Layout.Panel>

                <div className="row">
                    <div className="col-12 mt-4">
                        <Buttons.Button
                            type="submit"
                            importance="primary"
                            disabled={!valid || submitting}
                            fullWidth
                        >
                            Vytvořit měření
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }

    private getSampleOptions = memoize(genSampleOptions);

    private untouchMeasurementField(index: number, field: keyof DataItemMeasurementFormValues): void {
        const {untouchUnsafe} = this.props;

        untouchUnsafe(`measurements[${index}].${field}`);
    }

    private initializeMeasurements(): void {
        const {autofill} = this.props;
        const measurements = this.buildInitialMeasurements();

        measurements.forEach((_measurement, index) => {
            this.untouchMeasurementField(index, 'dilution');
            this.untouchMeasurementField(index, 'result');
        });
        autofill('measurements', measurements);
    }

    private onDataItemChange = (valueOpt: Opt<SelectedValue<DataItem>>) => {
        const {onDataItemChanged} = this.props;
        const value = valueOpt.orNull();

        if (isArray(value)) {
            return crash('unexpected array');
        }

        onDataItemChanged(value ? value.id : null);
    };

    private buildInitialMeasurements(): DataItemMeasurementFormValues[] {
        const {samples, selectedDataItem} = this.props;

        const procedureId = opt(selectedDataItem)
            .map(x => x.procedures).chainToOpt(find(x => x.isDefault)).map(x => x.procedureId).orNull();
        return opt(samples).orElse([]).map((sample) => ({
            dilution: (selectedDataItem?.dilution || 0).toString(),
            sampleId: sample.id,
            result: this.isNP ? '0' : null,
            procedureId,
        }));
    }
}

export default Forms.withForm<DataItemMeasurementsFormValues, OwnProps, Props>(DataItemMeasurementsForm, {
    form: 'dataItemMeasurements',
    validate,
    warn,
    initialValues: measurementsInitialValues,
});
