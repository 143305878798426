import {ErrorsFromBE} from 'favorlogic-utils';

import {DataItem} from 'types/model/dataItems/DataItem';
import {DataItemFormValues} from './types/DataItemFormValues';

export interface LoadDataItemsAction {
    type: 'dataItem/LOAD_DATA_ITEMS';
}

export const loadDataItems = (): LoadDataItemsAction => ({
    type: 'dataItem/LOAD_DATA_ITEMS',
});

export interface LoadDataItemsSuccessAction {
    payload: DataItem[];
    type: 'dataItem/LOAD_DATA_ITEMS_SUCCESS';
}

export const loadDataItemsSuccess = (
    payload: DataItem[]
): LoadDataItemsSuccessAction => ({
    type: 'dataItem/LOAD_DATA_ITEMS_SUCCESS',
    payload,
});

export interface LoadDataItemsErrorAction {
    type: 'dataItem/LOAD_DATA_ITEMS_ERROR';
    payload: ErrorsFromBE;
}

export const loadDataItemsError = (err: ErrorsFromBE): LoadDataItemsErrorAction => ({
    type: 'dataItem/LOAD_DATA_ITEMS_ERROR',
    payload: err,
});

// ---
export interface LoadDataItemAction {
    type: 'dataItem/LOAD_DATA_ITEM';
    payload: number;
}

export const loadDataItem = (id: number): LoadDataItemAction => ({
    type: 'dataItem/LOAD_DATA_ITEM',
    payload: id,
});

export interface LoadDataItemSuccessAction {
    payload: DataItem;
    type: 'dataItem/LOAD_DATA_ITEM_SUCCESS';
}

export const loadDataItemSuccess = (payload: DataItem): LoadDataItemSuccessAction => ({
    type: 'dataItem/LOAD_DATA_ITEM_SUCCESS',
    payload,
});

export interface LoadDataItemErrorAction {
    type: 'dataItem/LOAD_DATA_ITEM_ERROR';
    payload: ErrorsFromBE;
}

export const loadDataItemError = (error: ErrorsFromBE): LoadDataItemErrorAction => ({
    type: 'dataItem/LOAD_DATA_ITEM_ERROR',
    payload: error,
});

// ---

interface UpdateDataItemActionArgs {
    id: number;
}

export interface UpdateDataItemAction {
    type: 'dataItem/UPDATE_DATA_ITEM';
    payload: UpdateDataItemActionArgs;
}

export const updateDataItem = (
    payload: UpdateDataItemActionArgs
): UpdateDataItemAction => ({
    type: 'dataItem/UPDATE_DATA_ITEM',
    payload,
});

export interface UpdateDataItemSuccessAction {
    type: 'dataItem/UPDATE_DATA_ITEM_SUCCESS';
}

export const updateDataItemSuccess = (): UpdateDataItemSuccessAction => ({
    type: 'dataItem/UPDATE_DATA_ITEM_SUCCESS',
});

export interface UpdateDataItemErrorAction {
    type: 'dataItem/UPDATE_DATA_ITEM_ERROR';
    payload: ErrorsFromBE;
}

export const updateDataItemError = (err: ErrorsFromBE): UpdateDataItemErrorAction => ({
    type: 'dataItem/UPDATE_DATA_ITEM_ERROR',
    payload: err,
});

// ---

export interface PrefillDataItemFormAction {
    type: 'dataItem/PREFILL_DATA_ITEM_FORM';
    payload: DataItemFormValues;
}

export const prefillDataItemForm = (
    payload: DataItemFormValues
): PrefillDataItemFormAction => ({
    type: 'dataItem/PREFILL_DATA_ITEM_FORM',
    payload,
});

// ---

export type DataItemAction =
    LoadDataItemsAction | LoadDataItemsSuccessAction | LoadDataItemsErrorAction |
    LoadDataItemAction | LoadDataItemSuccessAction | LoadDataItemErrorAction |
    UpdateDataItemAction | UpdateDataItemSuccessAction | UpdateDataItemErrorAction |
    PrefillDataItemFormAction
    ;
