import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode, Fragment} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {opt, Opt} from 'ts-opt';
import {parseSearch, beDateFormat} from 'favorlogic-utils';
import moment from 'moment';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import withUser, {WithUserProps} from 'user/components/withUser';
import {MeasurementDetailsPage} from 'types/model/measurements/MeasurementDetailsPage';
import {SortProps} from 'tables/components/withSort';
import {withSort, withPagination, withFilter, TableAction} from 'tables/components';
import {DataItem} from 'types/model/dataItems/DataItem';
import {loadDataItems} from 'dataItem/actions';
import {Filter} from 'tables/types/Filter';
import {Components as Layout} from 'layout';
import {Analysis} from 'types/model/analyses/Analysis';
import {loadAnalyses} from 'analysis/actions';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {Modal} from 'layout/components';
import {isModalVisibleSelector} from 'layout/reducer';
import {setModalVisibility} from 'layout/actions';
import {loadCustomerList} from 'user/actions';
import {Customer} from 'types/model/customers/Customer';
import {MeasurementView} from 'types/model/measurements/MeasurementView';
import {SelectOptions, SelectOption} from 'forms/components/BasicSelect';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';
import GenerateQCzProtocolForm from 'administration/components/GenerateQCzProtocolForm';
import {generateQCZProtocol} from 'administration/actions';
import {isSubmitingF} from 'utils/formHelpers';

import {
    loadMeasurements,
    setMeasurementsParams,
    toggleSampleSelect,
    approveSamplesByFilter,
    approveSamplesSelection,
    setSamplesSubsidyByFilter,
    setSamplesSubsidySelection,
    exportXlsByFilter,
    exportXlsSelection,
    deleteBySelection,
    deleteByFilter,
    exportSamplesSubsidyByFilter,
    exportSamplesSubsidySelection,
    importSamplesSubsidy,
    exportGeometricMeans,
} from '../actions';
import MeasurementsTable, {definedFilters} from '../components/MeasurementsTable';
import {MeasurementsParams, MeasurementsFilter} from '../type/MeasurementsParams';
import ExportGeometricMeansForm from '../components/ExportGeometricMeansForm';

// eslint-disable-next-line max-len
const cantSetForSubsidyMessage = 'Pro Q CZ je možné označit pouze vzorky, které byly odebrány v neukončeném měsíci a nejsou kontrolní ani cisternové.';
const cantDeleteMessage = 'Není možné smazat měření z vyfakturovaných nebo dokončených objednávek.';

export const canDeleteMeasurements = (
    measurements: MeasurementView[],
    sampleIds: number[]
): boolean => measurements.filter(x => sampleIds.includes(x.sampleId)).every(x => x.canDelete);

interface OuterProps {}

interface StateProps {
    measurementsPage: MeasurementDetailsPage | null;
    dataItems: DataItem[];
    selectedSamples: number[];
    analyses: Analysis[] | null;
    exportGeometricMeansModalVisible: boolean;
    customers: Opt<Customer[]>;
    generateQCzProtocolInProgress: boolean;
    generateQCzProtocolModalVisible: boolean;
}

interface DispatchProps {
    handleLoadMeasurements(params: MeasurementsParams): void;
    handleLoadAnalyses(): void;
    handleLoadDataItems(): void;
    handleApproveSamplesByFilter(filter: MeasurementsFilter): void;
    handleApproveSamplesSelection(sampleIds: number[]): void;
    handleSetSamplesSubsidyByFilter(filter: MeasurementsFilter): void;
    handleSetSamplesSubsidySelection(sampleIds: number[]): void;
    handleUnsetSamplesSubsidyByFilter(filter: MeasurementsFilter): void;
    handleUnsetSamplesSubsidySelection(sampleIds: number[]): void;
    toggleSampleSelect(sampleId: number): void;
    handleExportXlsByFilter(filter: MeasurementsFilter): void;
    handleExportXlsSelection(sampleIds: number[]): void;
    handleDeleteByFilter(filter: MeasurementsFilter): void;
    handleDeleteBySelection(sampleIds: number[]): void;
    handleExportSamplesSubsidyByFilter(filter: MeasurementsFilter): void;
    handleExportSamplesSubsidySelection(sampleIds: number[]): void;
    handleImportSamplesSubsidy(file: File): void;
    handleGeometricMeansExport(isAdminOrLaborer: boolean): void;
    handleSetExportGeometrictMeansModalVisibility(visibility: boolean): void;
    handleLoadCustomers(): void;
    openGenerateQCzProtocolModal(): void;
    closeGenerateQCzProtocolModal(): void;
    generateQCZProtocol(): void;
}

type RouterProps = Pick<RouteComponentProps, 'history' | 'location'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type UserProps = Pick<WithUserProps, 'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy'>;
type Props =  OuterProps & UserProps & StateProps & DispatchProps & ListProps & RouterProps;

class List extends Component<Props> {
    private get isAdminOrLaborer(): boolean {
        const {isRoleLaborer, isRoleAdmin} = this.props;

        return isRoleLaborer || isRoleAdmin;
    }

    private get customerOptions(): SelectOptions<number> {
        const {customers} = this.props;

        return customers.map(x => x.map(
            (c: Customer): SelectOption<number> => ({
                label: c.customerName,
                value: c.id,
            }),
        )).orElse([]);
    }

    private get canSetSamplesForSubsidy(): boolean {
        const {measurementsPage, selectedSamples} = this.props;
        const measurements = opt(measurementsPage).map(x => x.content).orElse([]);

        return measurements.filter(x => selectedSamples.includes(x.sampleId))
            .every(x => !x.cistern && !x.control);

        // task 7891
        // return measurements.filter(x => selectedSamples.includes(x.sampleId))
        //     .every(canSetSampleForSubsidy);
    }

    private get canExportSamplesForSubsidy(): boolean {
        const {measurementsPage, selectedSamples} = this.props;
        const measurements = opt(measurementsPage).map(x => x.content).orElse([]);

        return measurements.filter(x => selectedSamples.includes(x.sampleId))
            .every(x => !x.cistern && !x.control);
    }

    private get canDeleteMeasurements(): boolean {
        const {measurementsPage, selectedSamples} = this.props;

        return canDeleteMeasurements(
            measurementsPage?.content ?? [],
            selectedSamples
        );
    }

    componentDidMount(): void {
        const {location: {search}, handleLoadDataItems, handleLoadAnalyses, handleLoadCustomers} = this.props;

        handleLoadDataItems();
        handleLoadAnalyses();
        if (this.isAdminOrLaborer) { handleLoadCustomers(); }
        if (search) { this.loadMeasurementsByQuery(search); }
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}} = prevProps;
        const {location: {search}} = this.props;

        if (prevSearch !== search) {
            this.loadMeasurementsByQuery(search);
        }
    }

    render(): ReactNode {
        const {
            analyses,
            measurementsPage,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            sortDir,
            sortBy,
            dataItems,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            filter,
            toggleSampleSelect,
            selectedSamples,
            exportGeometricMeansModalVisible,
            generateQCzProtocolModalVisible,
            generateQCZProtocol,
        } = this.props;

        if (measurementsPage && isEmpty(measurementsPage.content) && isEmpty(filter)) {
            return this.renderNoMeasurements();
        }

        return (
            <Fragment>
                <Modal.Blurer>
                    <Layout.ListPage
                        title="Přehled měření"
                        toolbar={this.renderToolbar()}
                        fluid
                    >
                        {this.renderTableActions()}
                        {analyses && <MeasurementsTable
                            measurementsPage={measurementsPage}
                            handlePageChange={handlePageChange}
                            handlePageSizeChange={handlePageSizeChange}
                            handleSortChange={handleSortChange}
                            sortDir={sortDir}
                            sortBy={sortBy}
                            dataItems={dataItems}
                            filter={filter}
                            changeFilter={handleFilterChange}
                            resetFilter={handleFilterClear}
                            filterMeasurements={handleFilterSubmit}
                            toggleSelect={toggleSampleSelect}
                            selectedSamples={selectedSamples}
                            analyses={analyses}
                            showCustomerNameColumn={this.isAdminOrLaborer}
                        />}
                    </Layout.ListPage>
                </Modal.Blurer>
                <Modal.Container
                    show={exportGeometricMeansModalVisible}
                    onClickAway={this.closeExportGeometricMeansModal}
                >
                    <ExportGeometricMeansForm
                        onSubmit={this.exportGeometricMeans}
                        isAdminOrLaborer={this.isAdminOrLaborer}
                        customerOptions={this.customerOptions}
                    />
                </Modal.Container>
                <Modal.Container
                    show={generateQCzProtocolModalVisible}
                    onClickAway={this.tryCloseGenerateQCzProtocolModal}
                >
                    <GenerateQCzProtocolForm
                        title="Generování Q CZ protokolu"
                        onSubmit={generateQCZProtocol}
                    />
                </Modal.Container>
            </Fragment>
        );
    }

    private renderTableActions(): ReactNode {
        const {
            measurementsPage,
            selectedSamples,
            isRoleDairy,
            filterParams,
            handleApproveSamplesByFilter,
            handleApproveSamplesSelection,
            handleSetSamplesSubsidyByFilter,
            handleSetSamplesSubsidySelection,
            handleUnsetSamplesSubsidyByFilter,
            handleUnsetSamplesSubsidySelection,
            handleExportXlsByFilter,
            handleExportXlsSelection,
            handleDeleteByFilter,
            handleDeleteBySelection,
            handleExportSamplesSubsidyByFilter,
            handleExportSamplesSubsidySelection,
        } = this.props;

        return (
            <Layout.Panel className="mt-0">
                <div className="row">
                    <TableAction
                        title="Schválit výsledky"
                        hoverTitle="Výsledky měření budou zaslány do aplikace data.cmsch.cz"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleApproveSamplesByFilter(filterParams)}
                        actionForSelection={() => handleApproveSamplesSelection(selectedSamples)}
                    />
                    <TableAction
                        title="Označit Q CZ"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleSetSamplesSubsidyByFilter(filterParams)}
                        actionForSelection={() => handleSetSamplesSubsidySelection(selectedSamples)}
                        selectionDisabled={!this.canSetSamplesForSubsidy}
                        selectionDisabledTitle={cantSetForSubsidyMessage}
                    />
                    <TableAction
                        title="Zrušit Q CZ"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleUnsetSamplesSubsidyByFilter(filterParams)}
                        actionForSelection={() => handleUnsetSamplesSubsidySelection(selectedSamples)}
                        selectionDisabled={!this.canSetSamplesForSubsidy}
                        selectionDisabledTitle={cantSetForSubsidyMessage}
                    />
                    {isRoleDairy && <TableAction
                        title="Export Q CZ"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleExportSamplesSubsidyByFilter(filterParams)}
                        actionForSelection={() => handleExportSamplesSubsidySelection(selectedSamples)}
                        selectionDisabled={!this.canExportSamplesForSubsidy}
                    />}
                    <TableAction
                        title="Export výsledků"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleExportXlsByFilter(filterParams)}
                        actionForSelection={() => handleExportXlsSelection(selectedSamples)}
                    />
                    {this.isAdminOrLaborer && <TableAction
                        title="Smazat měření"
                        filteredItemsCount={measurementsPage?.totalElements ?? 0}
                        selectedItemsCount={selectedSamples.length}
                        actionForFilter={() => handleDeleteByFilter(filterParams)}
                        actionForSelection={() => handleDeleteBySelection(selectedSamples)}
                        selectionDisabled={!this.canDeleteMeasurements}
                        selectionDisabledTitle={cantDeleteMessage}
                    />}
                </div>
            </Layout.Panel>
        );
    }

    private renderToolbar(): ReactNode {
        const {isRoleDairy, handleImportSamplesSubsidy, openGenerateQCzProtocolModal} = this.props;

        return (
            <Buttons.Group>
                {isRoleDairy && <Fragment>
                    <span>
                        <Buttons.UploadButton
                            id="importSamplesSubsidy"
                            label="Import Q CZ"
                            onUpload={handleImportSamplesSubsidy}
                        />
                    </span>
                    <Buttons.RightIconButton
                        label="Q CZ Protokol"
                        icon="DOWNLOAD"
                        onClick={openGenerateQCzProtocolModal}
                    />
                </Fragment>}
                {(isRoleDairy || this.isAdminOrLaborer) && <Buttons.RightIconButton
                    label="Geom. průměry"
                    icon="DOWNLOAD"
                    onClick={this.openExportGeometricMeansModal}
                />}
                {this.isAdminOrLaborer && <Fragment>
                    <Buttons.RightIconButton
                        label="Import měření"
                        to="/measurements/import"
                        icon="ADD"
                    />
                    <Buttons.RightIconButton
                        label="Nové z přístroje"
                        to="/measurements/newDevice"
                        icon="ADD"
                    />
                    <Buttons.RightIconButton
                        label="Nové z DP"
                        to="/measurements/newDataItem"
                        icon="ADD"
                        title="Nové měření z datové položky"
                    />
                    <Buttons.RightIconButton
                        label="Nové"
                        to="/measurements/newSample"
                        icon="ADD"
                    />
                </Fragment>}
            </Buttons.Group>
        );
    }

    private exportGeometricMeans = () => {
        const {handleGeometricMeansExport} = this.props;

        handleGeometricMeansExport(this.isAdminOrLaborer);
    }

    private openExportGeometricMeansModal = () => {
        const {handleSetExportGeometrictMeansModalVisibility} = this.props;
        handleSetExportGeometrictMeansModalVisibility(true);
    };

    private closeExportGeometricMeansModal = () => {
        const {handleSetExportGeometrictMeansModalVisibility} = this.props;
        handleSetExportGeometrictMeansModalVisibility(false);
    };

    private tryCloseGenerateQCzProtocolModal = (): void => {
        const {generateQCzProtocolInProgress, closeGenerateQCzProtocolModal} = this.props;

        if (!generateQCzProtocolInProgress) {
            closeGenerateQCzProtocolModal();
        }
    }

    private renderNoMeasurements(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nemáte prozatím žádné měření.
                    </div>
                    {this.isAdminOrLaborer && <Fragment>
                        <div>
                            Chcete přidat nové měření?
                        </div>
                        <div>
                            Klikněte na
                            <Buttons.RightIconButton
                                label="Nové"
                                to="/measurements/newSample"
                                className="m-2"
                                icon="ADD"
                            />,
                            <Buttons.RightIconButton
                                label="Nové z DP"
                                to="/measurements/newDataItem"
                                className="m-2"
                                icon="ADD"
                                title="Nové měření z datové položky"
                            />
                            ,
                            <Buttons.RightIconButton
                                label="Import z přístroje"
                                to="/measurements/import"
                                className="m-2"
                                icon="ADD"
                            />
                            nebo
                            <Buttons.RightIconButton
                                label="Nové z přístroje"
                                to="/measurements/newDevice"
                                className="m-2"
                                icon="ADD"
                            />
                        </div>
                    </Fragment>}
                </div>
            </Layout.EmptyPage>
        );
    }

    private loadMeasurementsByQuery(search: string) {
        const {handleLoadMeasurements} = this.props;

        handleLoadMeasurements(parseSearch(search));
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    analyses: state.analysis.analyses,
    measurementsPage: state.measurement.measurementsPage.orNull(),
    dataItems: state.dataItem.dataItems || [],
    selectedSamples: state.measurement.selectedSamples,
    exportGeometricMeansModalVisible: isModalVisibleSelector(state.layout, 'exportGeometrictMeans'),
    customers: state.user.customerList,
    generateQCzProtocolInProgress: isSubmitingF('generateQCzProtocol')(state),
    generateQCzProtocolModalVisible: isModalVisibleSelector(state.layout, 'generateQCzProtocol'),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadMeasurements: (params: MeasurementsParams) => {
        dispatch(loadMeasurements(params));
        dispatch(setMeasurementsParams(params));
    },
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleApproveSamplesByFilter: (filter: MeasurementsFilter) =>
        dispatch(showConfirmDialog({
            title: 'Schválit výsledky',
            text: 'Opravdu chcete schválit výsledky pro zobrazená měření?',
            confirmAction: approveSamplesByFilter(filter),
        })),
    handleApproveSamplesSelection: (sampleIds: number[]) =>
        dispatch(showConfirmDialog({
            title: 'Schválit výsledky',
            text: 'Opravdu chcete schválit výsledky pro vybraná měření?',
            confirmAction: approveSamplesSelection(sampleIds),
        })),
    toggleSampleSelect: (sampleId: number) => dispatch(toggleSampleSelect(sampleId)),
    handleSetSamplesSubsidyByFilter: (filter: MeasurementsFilter) =>
        dispatch(showConfirmDialog({
            title: 'Označit Q CZ',
            text: 'Opravdu chcete označit Q CZ pro zobrazená měření?',
            confirmAction: setSamplesSubsidyByFilter(filter, true),
        })),
    handleSetSamplesSubsidySelection: (sampleIds: number[]) =>
        dispatch(showConfirmDialog({
            title: 'Označit Q CZ',
            text: 'Opravdu chcete označit Q CZ pro vybraná měření?',
            confirmAction: setSamplesSubsidySelection(sampleIds, true),
        })),
    handleUnsetSamplesSubsidyByFilter: (filter: MeasurementsFilter) =>
        dispatch(showConfirmDialog({
            title: 'Zrušit Q CZ',
            text: 'Opravdu chcete zrušit Q CZ pro zobrazená měření?',
            confirmAction: setSamplesSubsidyByFilter(filter, false),
        })),
    handleUnsetSamplesSubsidySelection: (sampleIds: number[]) =>
        dispatch(showConfirmDialog({
            title: 'Zrušit Q CZ',
            text: 'Opravdu chcete zrušit Q CZ pro vybraná měření?',
            confirmAction: setSamplesSubsidySelection(sampleIds, false),
        })),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleExportXlsByFilter: (filter: MeasurementsFilter) =>
        dispatch(exportXlsByFilter(filter)),
    handleExportXlsSelection: (sampleIds: number[]) =>
        dispatch(exportXlsSelection(sampleIds)),
    handleDeleteByFilter: (filter: MeasurementsFilter) =>
        dispatch(showConfirmDialog({
            title: 'Smazat měření',
            text: 'Opravdu chcete smazat zobrazená měření?',
            confirmAction: deleteByFilter(filter),
        })),
    handleDeleteBySelection: (sampleIds: number[]) =>
        dispatch(showConfirmDialog({
            title: 'Smazat měření',
            text: 'Opravdu chcete smazat vybraná měření?',
            confirmAction: deleteBySelection(sampleIds),
        })),
    handleExportSamplesSubsidyByFilter: (filter: MeasurementsFilter) =>
        dispatch(exportSamplesSubsidyByFilter(filter)),
    handleExportSamplesSubsidySelection: (sampleIds: number[]) =>
        dispatch(exportSamplesSubsidySelection(sampleIds)),
    handleImportSamplesSubsidy: (file: File) =>
        dispatch(importSamplesSubsidy(file)),
    handleGeometricMeansExport: (isAdminOrLaborer: boolean) =>
        dispatch(exportGeometricMeans(isAdminOrLaborer)),
    handleSetExportGeometrictMeansModalVisibility: (visibility: boolean) =>
        dispatch(setModalVisibility('exportGeometrictMeans', visibility)),
    handleLoadCustomers: () => dispatch(loadCustomerList()),
    openGenerateQCzProtocolModal: () => dispatch(setModalVisibility('generateQCzProtocol', true)),
    closeGenerateQCzProtocolModal: () => dispatch(setModalVisibility('generateQCzProtocol', false)),
    generateQCZProtocol: () => dispatch(generateQCZProtocol()),
});

function getDefualtSamplingDateFrom(): string {
    const samplingDateFrom = moment().subtract(3, 'month');

    return samplingDateFrom.format(beDateFormat);
}

const defaultFilterValues: Filter = {
    samplingDateFrom: {
        type: 'date',
        values: [getDefualtSamplingDateFrom()],
    },
};

export default flow([
    withUser,
    withPagination,
    withSort({sortBy: 'measuredTo', sortDir: 'desc'}),
    withFilter(definedFilters, defaultFilterValues),
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
