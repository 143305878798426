import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {SampleDeliveryType} from 'types/model/enumeration/SampleDeliveryType';
import {sampleDeliveryTypeOptions} from '../../enums/SampleDeliveryTypeEnum';
import {CreateOrderFormValues} from '../../types/CreateOrderFormValues';
import {validate} from './validations';

export const initialCreateOrderFormValues: CreateOrderFormValues = {
    samples: [],
    deliveryType: null,
    note: '',
    customerDetails: null,
};

interface OwnProps {
    hideSubmitButton?: boolean;
}

export type Props = PropsForForm<CreateOrderFormValues, OwnProps>;

class CreateOrderForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            hideSubmitButton,
        } = this.props;

        return (
            <Layout.Panel caption="">
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />
                    <strong className="mt-3 d-block">Údaje k objednávce</strong>
                    <div className="row">
                        <div className="col-md-6">
                            <Fields.Select<SampleDeliveryType>
                                className="mt-2"
                                name="deliveryType"
                                label="Způsob doručení*"
                                options={sampleDeliveryTypeOptions}
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Textarea
                                className="mt-2"
                                name="note"
                                label="Poznámka"
                            />
                        </div>
                    </div>
                    <Fields.Input
                        type="hidden"
                        name="samples"
                        label=""
                    />
                    {!hideSubmitButton &&
                     <div className="row">
                         <div className="d-flex justify-content-end mt-4">
                             <Buttons.Button
                                 className="w-100"
                                 importance="primary"
                                 disabled={!valid || submitting}
                                 type="submit"
                             >
                                 Vytvořit objednavku
                             </Buttons.Button>
                         </div>
                     </div>
                    }
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<CreateOrderFormValues, OwnProps, Props>(CreateOrderForm, {
    form: 'createOrder',
    destroyOnUnmount: false,
    validate,
    initialValues: initialCreateOrderFormValues,
});
