import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ExportSamplesSubsidySelectionAction} from '../actions';
import Api from '../api';
import {handleSuccess} from './utils/exportSagaUtils';

function* execute({payload: {sampleIds}}: ExportSamplesSubsidySelectionAction): SagaIterator {
    const response = yield* call(Api.exportSamplesSubsidySelection, sampleIds);

    yield* response.isSuccess
        ? call(handleSuccess, response)
        : call(handleResponseError, response, 'Export dotací');
}

export function* exportSamplesSubsidySelectionSaga(): SagaIterator {
    yield takeLatestF('measurement/EXPORT_SAMPLES_SUBSIDY_SELECTION', execute);
}
