import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {ResetPasswordFormValues} from 'user/types/ResetPasswordFormValues';
import {validate} from './validations';

export interface ResetFormOwnProps {
    isLoading?: boolean;
}

type Props = PropsForForm<ResetPasswordFormValues, ResetFormOwnProps>;

class ResetPasswordForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            isLoading,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    onSubmit={handleSubmit}
                    className="position-relative"
                >
                    <Layout.Loader show={submitting || Boolean(isLoading)} />
                    <div className="row">
                        <div className="col-12">
                            <strong className="mt-3 d-block">Nové heslo</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<ResetPasswordFormValues>('password')}
                                type="password"
                                label="Heslo"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<ResetPasswordFormValues>('checkPassword')}
                                type="password"
                                label="Potvrzení hesla"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Změnit heslo
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<ResetPasswordFormValues, ResetFormOwnProps, Props>(ResetPasswordForm, {
    form: 'resetPassword',
    validate,
    initialValues: {
        password: '',
        checkPassword: '',
    },
});
