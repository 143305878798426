import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    procedureId: IntegerSchema,
    isDefault: t.boolean,
});

export const DataItemProcedureSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface DataItemProcedure extends t.TypeOf<typeof DataItemProcedureSchema> {}
