import React, {PureComponent, ReactNode} from 'react';
import {Field} from 'redux-form';

import {withGenericFormFieldElements} from 'forms/components/Field/withGenericFormFieldElements';
import ColorPicker, {ColorPickerInnerProps, ColorPickerOuterProps} from 'forms/components/ColorPicker';


class ColorPickerField extends PureComponent<ColorPickerInnerProps> {
    render(): ReactNode {
        return withGenericFormFieldElements(ColorPicker, this.props);
    }
}

class ColorPickerFieldOuter extends PureComponent<ColorPickerOuterProps> {
    render(): ReactNode {
        const {
            name,
            label,
            className,
            disabled,
            helpText,
            onAction,
            tooltip,
            wrapperClassName,
            onFieldBlur,
            onFieldChange,
            fieldIsLoading,
        } = this.props;

        return <Field
            component={ColorPickerField}
            name={name}
            label={label}
            className={className}
            disabled={disabled}
            helpText={helpText}
            onAction={onAction}
            tooltip={tooltip}
            wrapperClassName={wrapperClassName}
            onFieldChange={onFieldChange}
            onFieldBlur={onFieldBlur}
            fieldIsLoading={fieldIsLoading}
        />;
    }
}

export default ColorPickerFieldOuter;
