import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt, Opt} from 'ts-opt';
import {formatFloat, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {UpdateSampleFormValues} from 'sample/types/UpdateSampleFormValues';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {handleResponseError} from 'utils/handleResponseError';
import {StoreState} from 'app/types/StoreState';
import {UpdateSampleGloballyFormValues} from 'order/types/UpdateSampleGloballyFormValues';

import {
    LoadSampleDetailsAction,
    loadSampleDetailsError,
    loadSampleDetailsSuccess,
    prefillUpdateSampleForm,
} from '../actions';
import Api from '../api';

export function prepareUpdateSampleFormValues(sample: SampleDetails): UpdateSampleFormValues {
    return {
        analysisIds: sample.analysisIds,
        samplingDate: sample.samplingDate,
        type: sample.type,
        harvestType: sample.harvestType,
        samplingTemperature: opt(sample.samplingTemperature).chainToOpt(formatFloat).orNull(),
        sampleSourceAmount: sample.sampleSourceAmount ? formatFloat(sample.sampleSourceAmount) : '',
        barcode: sample.barcode,
        samplingTime: opt(sample.samplingTime).orElse(''),
        milkRoomId: sample.milkRoomId ?? null,
        supplyChainCode: sample.supplyChainCode ?? null,
        showResultsOnWeb: sample.showResultsOnWeb,
        showResultsToBreeders: sample.showResultsToBreeders,
        qualitative: sample.qualitative,
        cistern: sample.cistern,
        control: sample.control,
        subsidy: sample.subsidy,
        disabled: sample.disabled,
        canceled: sample.canceled,
        deliveryTemperature: opt(sample.deliveryTemperature).map(x => x.toString()).orElse(''),
        note: opt(sample.note).orElse(''),
        preservatives: opt(sample.preservatives).orElse([]),
        customerNote: sample.customerNote ?? null,
        traitNote: sample.traitNote ?? null,
    };
}

function* execute(action: LoadSampleDetailsAction): SagaIterator {
    const errorTitle = 'Načtení vzorku';
    const response = yield* call(Api.getSample, action.payload);

    const globallyFormValues = yield* select((x: StoreState): Opt<UpdateSampleGloballyFormValues> =>
        opt(x.order.updateSampleGloballyFormValues));

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, errorTitle, {includeFieldErrors: true});
        return yield* put(loadSampleDetailsError(response.data as ErrorsFromBE));
    }

    const sample = response.data;

    yield* put(loadSampleDetailsSuccess(sample));

    const updateFormPrefillObj = prepareUpdateSampleFormValues(sample);
    const prefillObj: UpdateSampleFormValues = globallyFormValues.caseOf(
        (x) => ({...updateFormPrefillObj, ...x}),
        () => ({...updateFormPrefillObj})
    );

    yield* put(prefillUpdateSampleForm(prefillObj));
}

export function* loadSampleDetailsSaga(): SagaIterator {
    yield takeLatestF('sample/LOAD_SAMPLE_DETAILS', execute);
}
