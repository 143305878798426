import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {GetPickupLinesOptionsAction, pickupLineAction} from '../actions';

const title = 'Načtení plánu svozových linek';

function* execute ({payload: {yearMonth}}: GetPickupLinesOptionsAction): SagaIterator {
    yield* put(pickupLineAction.setLoading('planning', true));

    const response = yield* call(PickupLineApi.getPickupLinesOptions, yearMonth);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLinesOptionsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(pickupLineAction.setLoading('planning', false));
}

export function* getPickupLinesOptionsSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_PICKUP_LINES_OPTIONS', execute);
}
