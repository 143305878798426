import * as t from 'io-ts';

import {DicSchema} from 'types/generic/DicSchema';

const optionalPart = t.partial({
    companyName: t.string,
    dic: DicSchema,
    street: t.string,
    city: t.string,
    zip: t.string,
});
const requiredPart = t.interface({});

export const AresSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Ares extends t.TypeOf<typeof AresSchema> {}
