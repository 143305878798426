import React, {Component, Fragment, ReactNode} from 'react';
import {flow, sortBy, map} from 'lodash/fp';

import {Components as Icons} from 'icons';
import {Components as Tables} from 'tables';
import {SortDir} from 'tables/components/withSort';
import {Column, ColumnType} from 'tables/types/Column';
import {Filter} from 'tables/types/Filter';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {Row} from 'tables/types/Row';
import {SampleDetailsPage} from 'types/model/samples/SampleDetailsPage';
import {SampleView} from 'types/model/samples/SampleView';
import RowActions from './RowActions';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';
import {Analysis} from 'types/model/analyses/Analysis';
import {LoaderWrapper} from 'layout/components';
import {intercalateElem} from 'favorlogic-utils';

import noteIcon from 'buttons/components/RowActionButton/img/update.svg';

import styles from './styles.sass';

export const getMilkRoomName = (sample: SampleView): string =>
    sample.milkRoomName
        ? `${sample.milkRoomName} (${sample.milkRoomCode || '-'})`
        : '';

const TABLE_ANNOTATIONS: ColorAnnotation[] = [
    {color: 'warning', label: 'Nahrána měření'},
    {color: 'info', label: 'Nahrána všechna měření'},
    {color: 'lowlight', label: 'Nepoužitelné a zrušené vzorky'},
];

export type SampleRow = Omit<SampleView, 'supplyChainCode'> & {
    notes: ReactNode,
    analyses: ReactNode,
    actions: ReactNode,
    id: number,
    supplyChainCode: ReactNode,
};

export const definedFilters: FilterDefinition<keyof SampleRow>[] = [
    {key: 'orderId', type: 'equals'},
    {key: 'sequenceNumber', type: 'equals'},
    {key: 'samplingDate', type: 'betweenDates'},
    {key: 'barcode', type: 'equals'},
    {key: 'accepted', type: 'betweenDates'},
    {key: 'milkRoomName', type: 'equals'},
    {key: 'supplyChainCode', type: 'equals'},
    {key: 'hasAllMeasurements', type: 'select'},
];

interface OuterProps {
    samplesPage: SampleDetailsPage | null;
    sortBy?: string;
    sortDir?: SortDir;
    filter: Filter;
    selectedSamples: number[];
    analyses: Analysis[];

    handlePageChange(page: number): void;
    handlePageSizeChange(size: number): void;
    handleSortChange(property: string): void;
    changeFilter: (accessor: string, type: string, values: string[]) => void;
    resetFilter: () => void;
    filterSamples: () => void;
    handleDelete(sampleId: number, orderId: number): void;
    toggleSelect(sampleId: number): void;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

class SamplesTable extends Component<Props> {
    render(): ReactNode {
        const {
            samplesPage,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            sortBy,
            sortDir,
            changeFilter,
            filterSamples,
            resetFilter,
            filter,
            toggleSelect,
        } = this.props;

        return (
            <Fragment>
                <LoaderWrapper showLoader={!samplesPage}>
                    <Tables.Table
                        columns={this.genColumns()}
                        rows={this.genData(samplesPage ? samplesPage.content : [])}
                        page={samplesPage?.pageable.pageNumber}
                        pageSize={samplesPage?.pageable.pageSize}
                        total={samplesPage?.totalElements}
                        handlePageChange={handlePageChange}
                        handlePageSizeChange={handlePageSizeChange}
                        handleSortChange={handleSortChange}
                        handleFilterChange={changeFilter}
                        handleFilterClear={resetFilter}
                        handleFilterSubmit={filterSamples}
                        filter={filter}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        hasFooter
                        rowSelect
                        handleSelectRow={toggleSelect}
                    />
                    <Tables.Legend annotations={TABLE_ANNOTATIONS} />
                </LoaderWrapper>
            </Fragment>
        );
    }

    private genColumns(): Column<SampleRow>[] {
        return [
            {
                accessor: 'notes',
                header: '',
                type: ColumnType.Node,
            },
            {
                accessor: 'orderId',
                header: 'Obj.',
                tooltip: 'Objednávka',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
                filterInputType: 'integer',
                size: 'narrow',
            },
            {
                accessor: 'sequenceNumber',
                header: 'P. č.',
                tooltip: 'Pořadové číslo',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
                filterInputType: 'integer',
                size: 'narrow',
            },
            {
                accessor: 'samplingDate',
                header: 'Dat. odběru',
                tooltip: 'Datum odběru',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable: true,
                filterable: true,
                filterType: 'betweenDates',
                size: 'normal',
            },
            {
                accessor: 'barcode',
                header: 'Čárový kód',
                tooltip: 'Unikátní identifikace vzorku',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'accepted',
                header: 'Dat. příjmu',
                tooltip: 'Datum příjmu',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable: true,
                filterable: true,
                filterType: 'betweenDates',
                size: 'normal',
            },
            {
                accessor: 'milkRoomName',
                header: 'Mléčnice (kód)',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'supplyChainCode',
                header: 'Linka',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
                size: 'narrow',
            },
            {
                accessor: 'analyses',
                header: 'Rozbory',
                type: ColumnType.Node,
            },
            {
                accessor: 'hasAllMeasurements',
                header: 'Dokončený',
                type: ColumnType.Bool,
                filterable: true,
                filterType: 'select',
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private genData(samples: SampleView[]): Row<SampleRow>[] {
        const {selectedSamples, toggleSelect} = this.props;

        return samples.map(x => {
            const selected = selectedSamples.includes(x.sampleId);
            const lowlighted = x.disabled || x.canceled;
            const isFullMeasured = x.hasAllMeasurements;
            const isPartMeasured = x.hasMeasurements && !isFullMeasured;

            return {
                ...x,
                id: x.sampleId,
                milkRoomName: getMilkRoomName(x),
                selected,
                lowlighted,
                supplyChainCode: <span title={x.supplyChainName}>{x.supplyChainCode}</span>,
                warninged: isPartMeasured && !lowlighted,
                informed: isFullMeasured && !lowlighted,
                analyses: this.getAnalysesNames(x.analysisIds),
                notes: <span className={styles.notes}>
                    {x.customerNote && <Icons.RowIcon
                        icon={noteIcon}
                        tooltip={`Poznámka od zákazníka: ${x.customerNote}`}
                    />}
                    {x.note && <Icons.RowIcon
                        fillColor="green"
                        icon={noteIcon}
                        tooltip={`Poznámka laboratoře: ${x.note}`}
                    />}
                </span>,
                actions:
                    <RowActions
                        sample={x}
                        handleDelete={this.props.handleDelete}
                        selected={selected}
                        toggleSelect={toggleSelect}
                    />,
            };
        });
    }

    private getAnalysesNames(analysisIds: number[]): ReactNode {
        const renderAllAnalysis = (analysis: Analysis[]) => flow([
            sortBy((a: Analysis) => a.id),
            map((a: Analysis) =>  <span title={a.name}>{a.abbr}</span>),
            intercalateElem(<span> | </span>),
        ])(analysis);

        return (
            <span>
                {renderAllAnalysis(this.getAnalyses(analysisIds))}
            </span>
        );
    }

    private getAnalyses(analysisIds: number[]): Analysis[] {
        const {analyses} = this.props;

        return analyses.filter(x => analysisIds.includes(x.id));
    }
}

export default SamplesTable;
