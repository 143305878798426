import React from 'react';

import {Components as Layout} from 'layout';
import {Components as Buttons} from 'buttons';

export interface Props {
    onGenerateProtocol(): void;
}

class GenerateQCZProtocol extends React.PureComponent<Props> {
    render(): React.ReactNode {
        const {onGenerateProtocol} = this.props;

        return (
            <Layout.Panel>
                <div className="row">
                    <div className="col-12 mt-2 mb-3">
                        <strong className="d-block">
                            Generování Q CZ protokolu
                        </strong>
                    </div>

                    <div className="col-12 mt-2">
                        <Buttons.Button
                            fullWidth
                            importance="primary"
                            type="button"
                            onClick={onGenerateProtocol}
                        >
                            Generovat protokol
                        </Buttons.Button>
                    </div>
                </div>
            </Layout.Panel>
        );
    }
}


export default GenerateQCZProtocol;
