import React, {FunctionComponent} from 'react';

import SvgIcon from '../SvgIcon';

import styles from './styles.sass';

interface Props {
    icon: string;
    className?: string;
}

const SquareIcon: FunctionComponent<Props> = props => {
    const {icon, className} = props;

    return (
        <span className={`${styles['square-icon']} ${className}`}>
            <SvgIcon icon={icon} />
        </span>
    );
};

export default SquareIcon;
