import React from 'react';

import {Components as Layout} from 'layout';
import {Components as Buttons} from 'buttons';

export interface Props {
    onGeneratePreviewProtocol(): void;
    generateProtocolInProgress: boolean;
}

class GeneratePreviewProtocol extends React.PureComponent<Props> {
    render(): React.ReactNode {
        const {onGeneratePreviewProtocol, generateProtocolInProgress} = this.props;

        return (
            <Layout.Panel>
                <Layout.LoaderWrapper showLoader={generateProtocolInProgress}>
                    <div className="row">
                        <div className="col-12 mt-2 mb-3">
                            <strong className="d-block">
                                Generování souhrnného předběžného protokolu
                            </strong>
                        </div>

                        <div className="col-12 mt-2">
                            <Buttons.Button
                                fullWidth
                                importance="primary"
                                type="button"
                                onClick={onGeneratePreviewProtocol}
                                disabled={generateProtocolInProgress}
                            >
                                Generovat protokol
                            </Buttons.Button>
                        </div>
                    </div>
                </Layout.LoaderWrapper>
            </Layout.Panel>
        );
    }
}


export default GeneratePreviewProtocol;
