import {SagaIterator} from 'redux-saga';
import {takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {pickupLineAction} from '../actions';

function* execute (): SagaIterator {
    yield* put(pickupLineAction.setLoading('dairy', true));

    const response = yield* call(PickupLineApi.getPickupLineDairyPlan);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLineDairyPlanSuccess(response.data));
    }

    yield* put(pickupLineAction.setLoading('dairy', false));
}

export function* getPickupLineDairyPlanSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_PICKUP_LINE_DAIRY_PLAN', execute);
}
