import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';

import {GenerateProtocolFormValues} from 'order/types/GenerateProtocolFormValues';
import GenerateProtocolForm from 'order/components/GenerateProtocolForm';
import {StoreState} from 'app/types/StoreState';
import {Opt} from 'ts-opt';
import withUser from 'user/components/withUser';
import {generateProtocol} from 'order/actions';
import {formValuesF} from 'utils/formHelpers';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface InnerProps {
    match: Match<{id: string}>;
    isSubmitting: boolean;
    formValues: Opt<GenerateProtocolFormValues>;

    handleSubmit(orderId: number): void;
}

type Props = InnerProps & OuterProps;

class GenerateProtocol extends Component<Props> {
    private get orderId(): number {
        const {match} = this.props;
        return Number(match.params.id);
    }

    render(): ReactNode {
        const {handleSubmit, isSubmitting, formValues} = this.props;
        const processedFrom: Date | undefined = formValues
            .chainToOpt(x => x.processedFrom ? new Date(x.processedFrom) : undefined).orUndef();

        return (
            <Layout.Page>
                <h1 className="text-center">Nový předběžný protokol</h1>
                <GenerateProtocolForm
                    onSubmit={() => handleSubmit(this.orderId)}
                    isSubmitting={isSubmitting}
                    processedFrom={processedFrom}
                />
            </Layout.Page>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isSubmitting: state.layout.isLoading,
    formValues: formValuesF('generateProtocol')(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleSubmit: (orderId: number) => dispatch(generateProtocol({orderId})),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(GenerateProtocol) as ComponentClass<OuterProps>;
