import {Device} from 'types/model/devices/Device';
import {DeviceAction} from './actions';

export interface DeviceState {
    devices: Device[] | null;
    device: Device | null;
}

export const initialDeviceState: DeviceState = {
    devices: null,
    device: null,
};

const reducer = (
    state: DeviceState | undefined = initialDeviceState,
    action: DeviceAction
): DeviceState => {
    switch (action.type) {
        case 'device/LOAD_DEVICES_SUCCESS': {
            return {...state, devices: action.payload};
        }

        case 'device/LOAD_DEVICE_SUCCESS': {
            return {...state, device: action.payload};
        }

        default:
            return state;
    }
};

export default reducer;
