import {OperatingProcedureFormValues} from 'operatingProcedure/types/OperatingProcedureFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

export const validate = (values: OperatingProcedureFormValues): Errors<OperatingProcedureFormValues> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název');
    validator.nonEmpty('description', 'Popis');

    return validator.generateErrorsObject();
};
