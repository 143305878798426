import * as t from 'io-ts';

export const PreservativeSchema = t.keyof({
    AZIDIOL: null,
    HESCHEN: null,
    BRONOPOL: null,
    NONE: null,
});

export type Preservative = t.TypeOf<typeof PreservativeSchema>;
