import {ConfirmDialogActionType} from './types/ConfirmDialogActionType';

export interface ShowParamsType {
    text: string;
    title?: string;
    confirmAction?: ConfirmDialogActionType;
    cancelAction?: ConfirmDialogActionType;
    onConfirm?(): void;
}

export interface ShowAction {
    type: 'confirmDialog/SHOW';
    payload: ShowParamsType;
}

export const show = (payload: ShowParamsType): ShowAction => ({
    type: 'confirmDialog/SHOW',
    payload,
});

export interface ConfirmAction {
    type: 'confirmDialog/CONFIRM';
}
export const confirm = (): ConfirmAction => ({
    type: 'confirmDialog/CONFIRM',
});

export interface CancelAction {
    type: 'confirmDialog/CANCEL';
}
export const cancel = (): CancelAction => ({
    type: 'confirmDialog/CANCEL',
});

export interface ResetAction {
    type: 'confirmDialog/RESET';
}
export const reset = (): ResetAction => ({
    type: 'confirmDialog/RESET',
});


// ---

export type ConfirmDialogAction =
    ShowAction |  ConfirmAction | CancelAction  | ResetAction
    ;
