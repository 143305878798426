import React, {FunctionComponent, ReactNode} from 'react';

interface Props {
    children: ReactNode | string;
}

const defaultProps = {
    children: null,
};

const HelpText: FunctionComponent<Props> = props => {
    const {children} = props;

    if (!children) {
        return null;
    }

    return (
        <small className="form-text text-muted d-inline-block float-right">
            {children}
        </small>
    );
};
HelpText.defaultProps = defaultProps;

export default HelpText;
