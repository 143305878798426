import React, {Component, ReactNode} from 'react';
import {Form, FieldArray} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {DeviceFormValues} from 'device/types/DeviceFormValues';
import {SelectOptions} from 'forms/components/BasicSelect';
import {DeviceDataItemFormValues} from 'device/types/DeviceDataItemValues';

import DeviceDataItems, {DeviceDataItemsProps} from './DeviceDataItems';

import {validate} from './validations';

export type DeviceFormMode = 'CREATE' | 'EDIT';

interface OwnProps {
    dataItemsOptions: SelectOptions<number>;
    mode: DeviceFormMode;
}

export type Props = PropsForForm<DeviceFormValues, OwnProps>;

class DeviceForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            mode,
            dataItemsOptions,
            pristine,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || mode === 'EDIT' && pristine} />
                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name="name"
                            type="text"
                            label="Název"
                            disabled
                        />
                        <Fields.Input
                            type="hidden"
                            name="dataItems"
                            label=""
                        />
                    </div>

                    <div className="col-12">
                        {dataItemsOptions && <FieldArray<DeviceDataItemsProps, DeviceDataItemFormValues>
                            name="dataItems"
                            component={DeviceDataItems}
                            label="Datové položky měřené přístrojem"
                            dataItemsOptions={dataItemsOptions}
                            mode={mode}
                        />}
                    </div>

                    <div className="col-12">
                        <div className="d-flex justify-content-end mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                {mode === 'CREATE' ? 'Vytvořit' : 'Uložit'}
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<DeviceFormValues, OwnProps, Props>(DeviceForm, {
    form: 'device',
    validate,
    initialValues: {
        name: '',
        dataItems: [],
    },
});
