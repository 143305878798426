import {Requester, redirectToHome} from 'favorlogic-utils';

import {Config} from './Config';
import logger from './model/Logger';

const reportValidationError = (error: Error): void => {
    logger.logError(error);
};

export const apiRequester = new Requester({
    baseUrl: Config.apiUrl,
    devMode: Config.isDevelopment,
    convertEmptyStringResponse: true,
    removeNullFieldsFromResponse: true,
    withCredentials: Config.isDevelopment,
    onForbidden: redirectToHome,
    onValidationError: reportValidationError,
});
