import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {DicSchema} from 'types/generic/DicSchema';
import {EmailSchema} from 'types/generic/EmailSchema';
import {IcoSchema} from 'types/generic/IcoSchema';
import {CustomerCodeSchema} from 'types/generic/CustomerCodeSchema';

const optionalPart = t.partial({
    username: t.string,
    email: EmailSchema,
    ico: IcoSchema,
    dic: DicSchema,
    code: CustomerCodeSchema,
    customerId: IntegerSchema,
});
const requiredPart = t.interface({});

export const CustomerValidationSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CustomerValidation extends t.TypeOf<typeof CustomerValidationSchema> {}
