import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {downloadFileFromResponse, takeLatestF} from 'favorlogic-utils';

import {isLoading} from 'layout/actions';
import {handleResponseError} from 'utils/handleResponseError';

import Api from '../api';
import {ExportOrdersInvoiceSubsetAction} from '../actions';

function* execute({payload: {orderIds}}: ExportOrdersInvoiceSubsetAction): SagaIterator {
    yield* put(isLoading(true));

    const response = yield* call(Api.exportInvoiceSubset, orderIds);

    yield* response.isSuccess
        ? call(downloadFileFromResponse, response)
        : call(handleResponseError, response, 'Fakturace objednávek');

    yield* put(isLoading(false));
}

export function* exportOrdersInvoiceSubsetSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_ORDERS_INVOICE_SUBSET', execute);
}
