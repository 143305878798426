import * as t from 'io-ts';

import {CreateGeometricMeanByCustomerSchema} from './CreateGeometricMeanByCustomer';
import {CreateGeometricMeanByLaborerSchema} from './CreateGeometricMeanByLaborer';

export const CreateGeometricMeanSchema = t.taggedUnion('tag', [
    CreateGeometricMeanByLaborerSchema,
    CreateGeometricMeanByCustomerSchema,
]);

export type CreateGeometricMean = t.TypeOf<typeof CreateGeometricMeanSchema>;
