import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {LimitActionSchema} from 'types/model/enumeration/LimitAction';

const optionalPart = t.partial({
    minimum: t.number,
    maximum: t.number,
    action: LimitActionSchema,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    dataItemId: IntegerSchema,
});

export const DeviceDataItemSchema = t.exact(t.intersection([
    optionalPart,
    requiredPart,
]));

export interface DeviceDataItem extends t.TypeOf<typeof DeviceDataItemSchema> {}
