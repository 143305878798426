import * as t from 'io-ts';

import {PickupLineOptionSchema} from './PickupLineOption';

const requiredPart = t.interface({
    id: t.number,
    name: t.string,
    color: t.string,
    published: t.boolean,
    options: t.array(PickupLineOptionSchema),
});

export const PickupLineOptionsSchema = t.exact(requiredPart);

export interface PickupLineOptions extends t.TypeOf<typeof PickupLineOptionsSchema> {}
