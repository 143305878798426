import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF, showSuccess} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {MassGenerateProtocolsAction, massGenerateProtocolsFinished} from '../actions';
import Api from '../api';

const title = 'Hromadné generování protokolů';

export function* execute(_: MassGenerateProtocolsAction): SagaIterator {
    const response = yield* call(Api.massGenerateProtocols);

    yield* response.isSuccess
        ? put(showSuccess(title, 'Generování protokolů bylo spuštěno'))
        : call(handleResponseError, response, title);
    yield* put(massGenerateProtocolsFinished());
}

function* massGenerateProtocolsSaga(): SagaIterator {
    yield takeLatestF('administration/MASS_GENERATE_PROTOCOLS', execute);
}

export default massGenerateProtocolsSaga;
