import {isString} from 'lodash/fp';
import {StringPatternSchema} from 'favorlogic-utils';

import {validationRegularExpressions} from 'utils/DairyValidator';

export const isDicValid = (str: string): boolean => {
    if (!isString(str)) { return false; }
    return validationRegularExpressions.dic.test(str);
};

export const DicSchema = StringPatternSchema<Dic>(validationRegularExpressions.dic);

export type Dic = string;
