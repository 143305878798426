import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {DeviceDataItemSchema} from 'types/model/devices/DeviceDataItem';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    name: t.string,
    dataItems: t.array(DeviceDataItemSchema),
});

export const DeviceSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Device extends t.TypeOf<typeof DeviceSchema> {}
