import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {SupplierView} from 'types/model/suppliers/SupplierView';

interface Props {
    supplier: SupplierView;

    handleDelete(id: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            supplier,
            handleDelete,
        } = this.props;
        const {id, canDelete} = supplier;
        const url = `/suppliers/${id}`;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={url}
                />
                <Buttons.RowActionButton
                    type="DELETE"
                    onClick={() => handleDelete(id)}
                    disabled={!canDelete}
                />
            </div>
        );
    }
}

export default RowActions;
