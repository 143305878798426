import React, {Component, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

export interface LoaderProps {
    show: boolean;
    inModal?: boolean;
}

class Loader extends Component<LoaderProps> {
    render(): ReactNode {
        const {show, inModal} = this.props;
        const wrapperClasses = classNames(styles.loaderWrapper, inModal && styles.inModal);

        return show &&
            <div className={wrapperClasses}>
                <div className={styles.loader} />
            </div>
        ;
    }
}

export default Loader;
