import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';

// TODO: rename title to label and hoverTitle to title
interface OuterProps {
    title: string;
    hoverTitle?: string;
    filteredItemsCount: number;
    selectedItemsCount: number;
    selectionDisabled?: boolean;
    selectionDisabledTitle?: string;

    actionForFilter(): void;
    actionForSelection(): void;
}

interface InnerProps {
}

type Props = InnerProps & OuterProps;

class TableAction extends Component<Props> {
    render(): ReactNode {
        const {
            title,
            filteredItemsCount,
            actionForFilter,
            selectedItemsCount,
            actionForSelection,
            hoverTitle,
            selectionDisabled,
            selectionDisabledTitle,
        } = this.props;

        return (
            <div className="col-auto px-2 py-1 m-1">
                <div
                    title={hoverTitle}
                    className="mb-1"
                >
                    {title}
                </div>
                <Buttons.Group>
                    <Buttons.Button
                        type="button"
                        importance="primary"
                        disabled={filteredItemsCount === 0}
                        onClick={actionForFilter}
                        title={hoverTitle}
                    >
                        Pro filtr ({filteredItemsCount})
                    </Buttons.Button>
                    <Buttons.Button
                        type="button"
                        importance="primary"
                        disabled={selectedItemsCount === 0 || selectionDisabled}
                        onClick={actionForSelection}
                        title={selectionDisabled ? selectionDisabledTitle : hoverTitle}
                    >
                        Pro výběr ({selectedItemsCount})
                    </Buttons.Button>
                </Buttons.Group>
            </div>
        );
    }
}

export default TableAction;
