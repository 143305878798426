import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {UpdateSampleFormValues} from 'sample/types/UpdateSampleFormValues';

type UpdateSampleDetailsState = GenericFormState<UpdateSampleFormValues>;

const formReducer: CFormReducer = {
    updateSample: (state: UpdateSampleDetailsState, action: Action): UpdateSampleDetailsState => {
        switch (action.type) {
            case 'sample/PREFILL_UPDATE_SAMPLE_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
