import * as t from 'io-ts';
import {PResponse, EmptyObjectSchema, EmptyObject} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {Device, DeviceSchema} from 'types/model/devices/Device';
import {DeviceDataItem, DeviceDataItemSchema} from 'types/model/devices/DeviceDataItem';
import {apiRequester} from 'app/api';

class Api {
    getDevices(): PResponse<Device[]> {
        return apiRequester.makeRequest({
            url: '/devices',
            method: 'get',
            requestSchema: t.void,
            responseSchema: t.array(DeviceSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getDevice(id: number): PResponse<Device> {
        return apiRequester.makeRequest({
            url: `/devices/${id}`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: DeviceSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateDevice(id: number, data: DeviceDataItem[]): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/devices/${id}`,
            method: 'put',
            data,
            requestSchema: t.array(DeviceDataItemSchema),
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
