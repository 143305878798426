import * as t from 'io-ts';
import {PResponse, EmptyObjectSchema, EmptyObject} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {DataItem, DataItemSchema} from 'types/model/dataItems/DataItem';
import {UpdateDataItem, UpdateDataItemSchema} from 'types/model/dataItems/UpdateDataItem';
import {apiRequester} from 'app/api';

class Api {
    getDataItems(): PResponse<DataItem[]> {
        return apiRequester.makeRequest({
            url: '/data-items',
            requestSchema: t.void,
            responseSchema: t.array(DataItemSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getDataItem(id: number): PResponse<DataItem> {
        return apiRequester.makeRequest({
            url: `/data-items/${id}`,
            requestSchema: t.void,
            responseSchema: DataItemSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateDataItem(id: number, data: UpdateDataItem): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/data-items/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateDataItemSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
