import {CreateOrderFormValues} from 'order/types/CreateOrderFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

type Values = CreateOrderFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('deliveryType', 'Typ odběru');
    validator.nonEmpty('samples', 'Seznam vzorků');

    return validator.generateErrorsObject();
};
