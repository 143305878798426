import {SagaIterator} from 'redux-saga';
import {select, call, put} from 'typed-redux-saga';
import {
    takeLatestF,
    showSuccess,
    extractFormErrorsFromResponse,
    ErrorResponse,
} from 'favorlogic-utils';

import {formValuesF, startSubmitF, resetF, stopSubmitF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';

import {ChangeCustomerPasswordAction} from '../actions';
import Api from '../api';

const title = 'Změna hesla';

function* getNewPassword(): SagaIterator<string> {
    const values = (yield* select(formValuesF('resetPassword')))
        .orCrash('missing form data');

    return values.password;
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Heslo úspěšně změněno'));
    yield* put(stopSubmitF('resetPassword'));
    yield* put(resetF('resetPassword'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('resetPassword', extractFormErrorsFromResponse(response)));
}

export function* execute({payload: {customerId}}: ChangeCustomerPasswordAction): SagaIterator {
    yield* put(startSubmitF('resetPassword'));

    const password = yield* call(getNewPassword);
    const response = yield* call(Api.changeCustomerPassword, customerId, password);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* changeCustomerPasswordSaga(): SagaIterator {
    yield takeLatestF('user/CHANGE_CUSTOMER_PASSWORD', execute);
}
