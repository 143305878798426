import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {ExportGeometricMeansFormValues} from 'measurement/type/ExportGeometricMeansFormValues';

import {Props} from './index';

type Values = ExportGeometricMeansFormValues;

export const validate = (values: Values, props: Props): Errors<Values> => {
    const validator = new DairyValidator(values);

    if (props.isAdminOrLaborer) {
        validator.nonEmpty('customerId', 'Zákazník');
    }

    return validator.generateErrorsObject();
};
