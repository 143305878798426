import {flow} from 'lodash/fp';
import React, {Component, ComponentClass} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import withUser from 'user/components/withUser';
import {MilkRoomAddress, loadMilkRoom, updateMilkRoom} from '../actions';
import UpdateMilkRoomForm from '../components/UpdateMilkRoomForm';

interface OuterProps {}

interface InnerProps {
    milkRoom: MilkRoom | null;
    match: Match<{ supplierId: string, milkRoomId: string }>;

    handleLoadMilkRoom(addr: MilkRoomAddress): void;

    handleUpdateMilkRoom(addr: MilkRoomAddress): void;
}

type Props = InnerProps & OuterProps;

class MilkRoomDetail extends Component<Props> {
    private get milkRoomAddress(): MilkRoomAddress {
        const {match} = this.props;

        return {
            supplierId: Number(match.params.supplierId),
            milkRoomId: Number(match.params.milkRoomId),
        };
    }

    private get supplierId(): number {
        const {match} = this.props;

        return Number(match.params.supplierId);
    }

    componentDidMount(): void {
        const {handleLoadMilkRoom} = this.props;

        handleLoadMilkRoom(this.milkRoomAddress);
    }

    render(): React.ReactNode {
        const {milkRoom} = this.props;

        if (!milkRoom) { return null; }

        return (
            <Layout.ItemPage
                title="Detail mléčnice"
                backLabel="Dodavatel"
            >
                <UpdateMilkRoomForm
                    onSubmit={this.handleSubmit}
                    milkRoomId={milkRoom.id}
                    supplierId={this.supplierId}
                />
            </Layout.ItemPage>
        );
    }

    private handleSubmit = () => {
        const {handleUpdateMilkRoom} = this.props;

        handleUpdateMilkRoom(this.milkRoomAddress);
    };
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    milkRoom: state.supplier.milkRoom,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadMilkRoom: (addr: MilkRoomAddress) => dispatch(loadMilkRoom(addr)),
    handleUpdateMilkRoom: (addr: MilkRoomAddress) => dispatch(updateMilkRoom(addr)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(MilkRoomDetail) as ComponentClass<OuterProps>;
