import React, {FunctionComponent, memo, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';
import {range} from 'lodash/fp';

import {DateString} from 'types/generic/DateString';

import {editDateNumber} from '../../utils/editDateNumber';
import {isFreeDay} from '../../utils/isFreeDay';
import {getDaysCountInMonth} from '../../utils/getDaysCountInMonth';
import {getDayAbbreviation} from '../../utils/getDayAbbreviation';

import styles from './styles.sass';

interface Props {
    date: DateString;
    footerText?: string;
    noFooter?: boolean;
    footerPadding?: boolean;
}

const renderHeaderOrFooter = (text?: string, footerPadding?: boolean) =>
    <div className={classNames(styles.daysCol, styles.header, footerPadding && styles.footerPadding)}>
        <div className={styles.content}>{text}</div>
    </div>
;

const renderCols = (planningDate: DateString): ReactNode[] => {
    const [year, month] = planningDate?.split('-');

    return range(1, getDaysCountInMonth(planningDate) + 1).map(i => {
        const date = `${year}-${month}-${editDateNumber(i)}`;
        return (
            <div
                key={date}
                className={classNames('row', styles.daysCol, isFreeDay(date) && styles.freeDay)}
            >
                <div className="col-6 text-center">{i}</div>
                <div className="col-6 text-center">{getDayAbbreviation(date)}</div>
            </div>
        );
    });
};

const DaysColumnBase: FunctionComponent<Props> = props => {
    const {date, footerText, noFooter, footerPadding} = props;

    return (
        <div className={classNames('d-inline-block', styles.daysColumn)}>
            {renderHeaderOrFooter('Den')}
            {renderCols(date)}
            {!noFooter && renderHeaderOrFooter(footerText, footerPadding)}
        </div>
    );
};

export const DaysColumn = memo(DaysColumnBase);
