import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {
    loadCustomerMilkRoomsError,
    LoadCustomerMilkRoomsAction,
    loadCustomerMilkRoomsSuccess,
} from '../actions';
import Api from '../api';

function* execute(action: LoadCustomerMilkRoomsAction): SagaIterator {
    const errorTitle = 'Načtení zákazníka';

    const response = yield* call(Api.getCustomerMilkrooms, action.payload.id);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadCustomerMilkRoomsError(error));
    }

    yield* put(loadCustomerMilkRoomsSuccess(response.data));
}

export function* loadCustomerMilkRoomsSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CUSTOMER_MILK_ROOMS', execute);
}
