import * as t from 'io-ts';
import {DateStringSchema, TimeStringSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
    samplingTime: TimeStringSchema,
    samplingDate: DateStringSchema,
});
const requiredPart = t.interface({});

export const MassUpdateSampleSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MassUpdateSample extends t.TypeOf<typeof MassUpdateSampleSchema> {}
