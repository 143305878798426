import React, {FunctionComponent, ReactNode} from 'react';

import {classNames} from 'favorlogic-utils';

import Panel from '../Panel';

import styles from './styles.sass';

interface Props {
    children: ReactNode;
    narrow?: boolean;
}

const Message: FunctionComponent<Props> = props => {
    const {children, narrow} = props;

    return (
        <Panel className={classNames(narrow && styles.narrow)}>
            <div className="container-fluid d-flex py-5 flex-column">
                <div className="w-100 px-4">
                    {children}
                </div>
            </div>
        </Panel>
    );
};

export default Message;
