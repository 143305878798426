import React, {ReactNode} from 'react';

export const getShortenedTextWithTooltip = (text: string, maxVisibleChars: number): ReactNode =>
    <div
        title={text.length > maxVisibleChars ? text : undefined}
        className="overflow-hidden text-truncate"
    >
        {text}
    </div>
;
