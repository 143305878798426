import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {UpdateSamplesByFilterAction} from '../actions';
import {
    getFormValues,
    handleErrorResponse,
    handleSuccess,
    updateSamplesRequestData,
} from './updateSamplesSagaUtils';
import Api from '../api';

function* execute(action: UpdateSamplesByFilterAction): SagaIterator {
    const formValues = yield* call(getFormValues);
    const response = yield* call(
        Api.updateSamplesByFilter,
        action.payload,
        updateSamplesRequestData(formValues),
    );

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateSamplesByFilterSaga(): SagaIterator {
    yield takeLatestF('sample/UPDATE_SAMPLES_BY_FILTER', execute);
}
