import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {ProtocolDetails} from 'types/model/protocols/ProtocolDetails';

interface Props {
    protocol: ProtocolDetails;
    canApprove: boolean;
    canDelete: boolean;
    disableDelete: boolean;
    disableApprove: boolean;

    onDownload(_: ProtocolDetails): void;
    onApprove(): void;
    onDelete(_: ProtocolDetails): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            onApprove,
            protocol,
            canApprove,
            canDelete,
            disableApprove,
            disableDelete,
        } = this.props;
        const {approved} = protocol;

        return (
            <div>
                {canApprove && !approved && <Buttons.RowActionButton
                    type="ACCEPT"
                    title="Schválit"
                    onClick={onApprove}
                    disabled={disableApprove}
                />}
                {approved && <Buttons.RowActionButton
                    type="DOWNLOAD"
                    onClick={this.download}
                />}
                {canDelete && <Buttons.RowActionButton
                    type="DELETE"
                    title="Smazat"
                    onClick={this.delete}
                    disabled={disableDelete}
                />}
            </div>
        );
    }

    download = (): void => {
        const {onDownload, protocol} = this.props;

        onDownload(protocol);
    }

    delete = (): void => {
        const {onDelete, protocol} = this.props;

        onDelete(protocol);
    };
}

export default RowActions;
