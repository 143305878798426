import {flow, capitalize} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';
import {classNames} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {Components as Icons} from 'icons';

import styles from './styles.sass';

import acceptSvg from './img/accept.svg';
import deleteSvg from './img/delete.svg';
import detailSvg from './img/detail.svg';
import updateSvg from './img/update.svg';
import downloadSvg from './img/download.svg';
import downloadTxtSvg from './img/download-txt.svg';
import downloadPdfSvg from './img/download-pdf.svg';
import downloadCsvSvg from './img/download-csv.svg';
import selectSvg from './img/select.svg';
import unselectSvg from './img/unselect.svg';
import cancelSvg from './img/cancel.svg';
import activateSvg from './img/activate.svg';
import deactivateSvg from './img/deactivate.svg';

export type RowActionButtonType =
    'UPDATE' |
    'DELETE' |
    'DETAIL' |
    'ACCEPT' |
    'DOWNLOAD' |
    'DOWNLOAD_TXT' |
    'DOWNLOAD_CSV' |
    'DOWNLOAD_PDF' |
    'SELECT' |
    'UNSELECT' |
    'NOTE' |
    'CANCEL'|
    'ACTIVATE' |
    'DEACTIVATE';

interface TypeDescriptor {
    title: string;
    node: ReactNode;
}

const mkIcon = (svg: string): ReactNode => <Icons.SvgIcon icon={svg} />;

const descriptors: Record<RowActionButtonType, TypeDescriptor> = {
    UPDATE: {
        title: 'Upravit',
        node: mkIcon(updateSvg),
    },
    DELETE: {
        title: 'Smazat',
        node: mkIcon(deleteSvg),
    },
    DETAIL: {
        title: 'Detail',
        node: mkIcon(detailSvg),
    },
    ACCEPT: {
        title: 'Přijmout',
        node: mkIcon(acceptSvg),
    },
    DOWNLOAD: {
        title: 'Stáhnout',
        node: mkIcon(downloadSvg),
    },
    DOWNLOAD_TXT: {
        title: 'Stáhnout',
        node: mkIcon(downloadTxtSvg),
    },
    DOWNLOAD_PDF: {
        title: 'Stáhnout',
        node: mkIcon(downloadPdfSvg),
    },
    DOWNLOAD_CSV: {
        title: 'Stáhnout',
        node: mkIcon(downloadCsvSvg),
    },
    SELECT: {
        title: 'Vybrat',
        node: mkIcon(selectSvg),
    },
    UNSELECT: {
        title: 'Zrušit výběr',
        node: mkIcon(unselectSvg),
    },
    NOTE: {
        title: 'Poznámka',
        node: mkIcon(updateSvg),
    },
    CANCEL: {
        title: 'Zrušit',
        node: mkIcon(cancelSvg),
    },
    ACTIVATE: {
        title: 'Aktivovat',
        node: mkIcon(activateSvg),
    },
    DEACTIVATE: {
        title: 'Deaktivovat',
        node: mkIcon(deactivateSvg),
    },
};

interface OuterProps {
    type: RowActionButtonType;
    to?: string;
    title?: string;
    standalone?: boolean;
    disabled?: boolean;

    onClick?(): void;
}

type Props = OuterProps & {
    handleGoTo(url: string): void,
};

// TODO: promote to universal button?
// (extend descriptors and remove BackButton, EditButton, IconButton, RightIconButton)
class RowActionButton extends Component<Props> {
    render(): ReactNode {
        const {
            type,
            to,
            onClick,
            handleGoTo,
            title,
            standalone,
            disabled,
        } = this.props;

        const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (!disabled && to) { handleGoTo(to); }
            if (!disabled && onClick) { onClick(); }
            e.preventDefault();
            e.stopPropagation();
        };
        const dsc = descriptors[type];
        const className = classNames(
            styles.rowActionButton,
            standalone ? '' : styles.sizeHack,
            styles[`type${capitalize(type)}`],
            disabled ? styles.disabled : '',
        );

        return (
            <a
                title={title || dsc.title}
                onClick={handleClick}
                href={to}
                className={className}
            >
                {dsc.node}
            </a>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleGoTo: (url: string) => dispatch(routerActions.push(url)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(RowActionButton) as ComponentClass<OuterProps>;
