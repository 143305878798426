import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {Analysis} from 'types/model/analyses/Analysis';
import {DataItem} from 'types/model/dataItems/DataItem';
import withUser from 'user/components/withUser';
import {loadDataItems} from 'dataItem/actions';
import {Components as Layout} from 'layout';

import {loadAnalysis, updateAnalysis} from '../actions';
import AnalysisForm from '../components/AnalysisForm';

interface OuterProps {}

interface StateProps {
    dataItems: Opt<DataItem[]>;
    analysis: Opt<Analysis>;
}

interface DispatchProps {
    handleLoadDataItems(): void;
    handleLoadAnalysis(id: number): void;
    handleUpdate(id: number): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class Edit extends Component<Props> {
    private get id(): number {
        const {match: {params}} = this.props;

        return Number(params.id);
    }

    componentDidMount(): void {
        const {handleLoadDataItems, handleLoadAnalysis} = this.props;

        handleLoadDataItems();
        handleLoadAnalysis(this.id);
    }

    render(): ReactNode {
        const {dataItems} = this.props;

        return (
            <Layout.ItemPage
                title="Úprava rozboru"
                backLabel="Rozbory"
            >
                <AnalysisForm
                    dataItems={dataItems.orNull()}
                    onSubmit={this.handleSubmit}
                />
            </Layout.ItemPage>
        );
    }

    private handleSubmit = () => {
        const {handleUpdate} = this.props;

        handleUpdate(this.id);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    dataItems: opt(state.dataItem.dataItems),
    analysis: opt(state.analysis.analysis),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleLoadAnalysis: (id: number) => dispatch(loadAnalysis(id)),
    handleUpdate: (id: number) => dispatch(updateAnalysis(id)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Edit) as ComponentClass<OuterProps>;
