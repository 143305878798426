import {Components as Layout} from 'layout';
import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {UserDetails} from 'types/model/users/UserDetails';

import {loadLaborers} from '../actions';
import withUser from '../components/withUser';
import LaborersTable from '../components/LaborersTable';

interface OuterProps {}

interface StateProps {
    laborers: Opt<UserDetails[]>;
}

interface DispatchProps {
    handleLoad(): void;
}

type Props = OuterProps & StateProps & DispatchProps;

class LaborersList extends Component<Props> {
    componentDidMount(): void {
        const {handleLoad} = this.props;

        handleLoad();
    }

    render(): ReactNode {
        const {laborers} = this.props;
        return (
            <Layout.ListPage title="Laboranti">
                {laborers.caseOf(xs => <LaborersTable laborers={xs} />,
                    () => <Layout.Loader show />,
                )}
            </Layout.ListPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    laborers: state.user.laborers,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoad: () => dispatch(loadLaborers()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(LaborersList) as ComponentClass<OuterProps>;
