import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {MeasurementView} from 'types/model/measurements/MeasurementView';

interface Props {
    measurement: MeasurementView;
    selected: boolean;

    toggleSelect(sampleId: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {toggleSelect, measurement, selected} = this.props;
        const {sampleId} = measurement;

        return (
            <div>
                <Buttons.RowActionButton
                    type={selected ? 'UNSELECT' : 'SELECT'}
                    onClick={() => toggleSelect(sampleId)}
                />
            </div>
        );
    }
}

export default RowActions;
