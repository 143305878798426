import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {formValuesF} from 'utils/formHelpers';
import {PriceClass} from 'types/model/enumeration/PriceClass';

import {
    loadCustomer,
    update,
    fillUpdateCustomerFormFromAres,
    changeCustomerPassword,
} from '../actions';
import UpdateForm from '../components/UpdateForm';
import withUser from '../components/withUser';
import {UpdateCustomerFormValues} from '../types/UpdateCustomerFormValues';
import ResetPasswordForm from '../components/ResetPasswordForm';

interface OuterProps {}

interface StateProps {
    customer: Opt<CustomerDetailsForLaborer>;
    formValues: Opt<UpdateCustomerFormValues>;
    isAresLoading: boolean;
}

interface DispatchProps {
    handleLoadCustomer(id: number): void;
    handle404(): void;
    handleUpdate(customerId: number): void;
    fillFormFromAres(ico: string): void;
    changePassword(customerId: number): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class EditCustomer extends Component<Props> {
    private get customerId(): number {
        const {match: {params}} = this.props;

        return Number(params.id);
    }

    private get customerName(): string {
        const {customer} = this.props;

        return customer.map(c => c.name).orElse('');
    }

    private get ico(): string | undefined {
        const {formValues} = this.props;

        return formValues.chainToOpt(x => x.invoiceDetails.ico).orUndef();
    }

    private get hasNoIco(): boolean {
        const {formValues} = this.props;

        return formValues.map(x => x.hasNoIco).orFalse();
    }

    private get hasSameBillingAddress(): boolean {
        const {formValues} = this.props;

        return formValues.map(x => x.hasSameBillingAddress).orFalse();
    }

    private get priceClass(): PriceClass | undefined {
        const {formValues} = this.props;

        return formValues.chainToOpt(x => x.priceClass).orUndef();
    }

    componentDidMount(): void {
        const {handleLoadCustomer, handle404} = this.props;

        opt(this.customerId).caseOf(handleLoadCustomer, handle404);
    }

    render = (): ReactNode => {
        const {isAresLoading, formValues} = this.props;

        return (
            <Layout.ItemPage
                title={`Zákazník ${this.customerName}`}
                backLabel="Zákazníci"
            >
                <UpdateForm
                    isAdminOrLaborer
                    isDairy={false}
                    hasSameBillingAddress={this.hasSameBillingAddress}
                    onSubmit={this.submitChanges}
                    hasNoIco={this.hasNoIco}
                    isAresLoading={isAresLoading}
                    onIcoSearch={this.onIcoSearch}
                    priceClass={this.priceClass}
                    formValues={formValues.orUndef()}
                />

                <ResetPasswordForm
                    onSubmit={this.changePassword}
                />
            </Layout.ItemPage>
        );
    }

    private onIcoSearch = () => {
        const {fillFormFromAres} = this.props;

        if (this.ico) {
            fillFormFromAres(this.ico);
        }
    };

    private submitChanges = () => {
        const {handleUpdate} = this.props;

        handleUpdate(this.customerId);
    }

    private changePassword = () => {
        const {changePassword} = this.props;

        changePassword(this.customerId);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    customer: state.user.customerForLaborer,
    formValues: formValuesF('updateCustomer')(state),
    isAresLoading: state.user.isAresLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadCustomer: (id: number) => dispatch(loadCustomer(id)),
    handle404: () => dispatch(routerActions.push('/404')),
    handleUpdate: (customerId: number) => dispatch(update({byLaborer: true, customerId: opt(customerId)})),
    fillFormFromAres: (ico: string) => dispatch(fillUpdateCustomerFormFromAres(ico)),
    changePassword: (customerId: number) => dispatch(changeCustomerPassword(customerId)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(EditCustomer) as ComponentClass<OuterProps>;
