import {AnyAction, Dispatch} from 'redux';

import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {WRITE_PICKUP_LINE_MAX_LENGTH} from 'types/model/pickupLine/WritePickupLine';
import {pickupLineAction} from 'pickupLine/actions';
import {hexColorRegexGen} from 'types/model/common/HexColor';

import {CREATE_PICKUP_FORM_NAME, EDIT_PICKUP_FORM_NAME, WritePickupLineFormValues} from './writePickupLineFormValues';
import {Props} from '.';

export const validate = (values: WritePickupLineFormValues): Errors<WritePickupLineFormValues> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název linky');
    validator.maxStringLength('name', WRITE_PICKUP_LINE_MAX_LENGTH, 'Název linky');
    validator.nonEmpty('customerIds', 'Zákazníci');
    validator.nonEmpty('color', 'Barva linky');
    validator.pattern('color', hexColorRegexGen(), 'Barva linky');

    return validator.generateErrorsObject();
};

export const asyncValidate = async (
    values: WritePickupLineFormValues,
    dispatch: Dispatch<AnyAction>,
    props: Props,
): Promise<boolean> => {
    const {name} = values;
    const {pickupLineIdInEdit} = props;

    return new Promise(resolve => {
        if (name) {
            dispatch(pickupLineAction.validatePickupLineName(
                {id: pickupLineIdInEdit, name},
                pickupLineIdInEdit ? EDIT_PICKUP_FORM_NAME : CREATE_PICKUP_FORM_NAME));
        }
        resolve(false);
    });
};
