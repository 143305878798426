import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorResponse, ErrorsFromBE, takeLatestF, SuccessResponse} from 'favorlogic-utils';

import {DataItem} from 'types/model/dataItems/DataItem';
import {handleResponseError} from 'utils/handleResponseError';

import {DataItemFormValues} from '../types/DataItemFormValues';
import {
    LoadDataItemAction,
    loadDataItemError,
    loadDataItemSuccess,
    prefillDataItemForm,
} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const title = 'Načtení datové položky';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadDataItemError(error));
}

export function prepareDataItem(dataItem: DataItem): DataItemFormValues {
    return {
        name: dataItem.name,
        numOfDecimalPlaces: dataItem.numOfDecimalPlaces.toString(),
        minimum: dataItem.minimum.toString(),
        maximum: dataItem.maximum.toString(),
        measurementUnit: dataItem.measurementUnit,
        dilution: dataItem.dilution.toString(),
        checkErrors: dataItem.checkErrors,
        procedureIds: dataItem.procedures.map(p => p.procedureId),
    };
}

function* handleSuccessResponse({data: dataItem}: SuccessResponse<DataItem>): SagaIterator {
    yield* put(prefillDataItemForm(prepareDataItem(dataItem)));
    yield* put(loadDataItemSuccess(dataItem));
}

function* execute(action: LoadDataItemAction): SagaIterator {
    const response = yield* call(Api.getDataItem, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadDataItemSaga(): SagaIterator {
    yield takeLatestF('dataItem/LOAD_DATA_ITEM', execute);
}
