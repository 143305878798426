import React, {FunctionComponent, ComponentClass, useEffect} from 'react';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteComponentProps} from 'react-router';

import {StoreState} from 'app/types/StoreState';
import {UserDetailsForCustomer} from 'types/model/users/UserDetailsForCustomer';
import {Components as Layout} from 'layout';

import UpdateUserForm from '../components/UpdateUserForm';
import ResetPasswordForm from '../components/ResetPasswordForm';
import withUser from '../components/withUser';
import {changeUserPassword, loadUser, updateUser} from '../actions';

interface OuterProps {
}

interface StateProps {
    user: UserDetailsForCustomer | null;
}

interface DispatchProps {
    handleLoadUser(userId: number): void;
    handleUpdateUser(): void;
    handleChangePassword(): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{userId: string}>;

const UpdateUser: FunctionComponent<Props> = (props) => {
    const {handleLoadUser, handleUpdateUser, handleChangePassword, match} = props;

    useEffect(() => {
        const {params: {userId}} = match;

        handleLoadUser(Number(userId));
    }, []);

    return (
        <Layout.ItemPage
            title="Upravit uživatele"
            backLabel="Uživatelé"
        >
            <UpdateUserForm
                onSubmit={handleUpdateUser}
            />

            <ResetPasswordForm
                onSubmit={handleChangePassword}
            />
        </Layout.ItemPage>
    );
};

const mapStateToProps = (state: StoreState): StateProps => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadUser: (userId: number) => dispatch(loadUser(userId)),
    handleUpdateUser: () => dispatch(updateUser()),
    handleChangePassword: () => dispatch(changeUserPassword()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(UpdateUser) as ComponentClass<OuterProps>;
