import {opt} from 'ts-opt';

import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {RegisterFormValues} from 'user/types/RegisterFormValues';
import {UpdateCustomerFormValues} from './types/UpdateCustomerFormValues';

type UpdateState = GenericFormState<UpdateCustomerFormValues>;
type RegisterState = GenericFormState<RegisterFormValues>;

const formReducer: CFormReducer = {
    updateCustomer: (state: UpdateState, action: Action): UpdateState => {
        switch (action.type) {
            case 'user/PREFILL_UPDATE_CUSTOMER_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
    register: (state: RegisterState, action: Action): RegisterState => {
        const val = opt(state).chainToOpt(x => x.values).orUndef();
        switch (action.type) {
            case 'user/PREFILL_REGISTER_FORM': {
                return {...state, values: action.payload};
            }

            case 'user/RESET_ARES_VALUES': {
                if (val) {
                    return {
                        ...state,
                        values: {
                            ...val,
                            address: {city: '', street: '', zip: ''},
                            invoiceDetails: {...val.invoiceDetails, dic: ''},
                            name: '',
                        },
                    };
                } else { return state; }
            }

            case 'user/LOAD_ARES': {
                if (val?.hasNoIco) {
                    return {
                        ...state, values: {...val, hasNoIco: false},
                    };
                } else { return state; }
            }

            default:
                return state;
        }
    },
};

export default formReducer;
