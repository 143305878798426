import {isEmpty} from 'lodash/fp';
import {Dispatch} from 'redux';

import {CustomerValidation} from 'types/model/customers/CustomerValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {validateCustomer} from '../../actions';
import {UpdateCustomerFormValues} from '../../types/UpdateCustomerFormValues';
import {OwnProps} from '../UpdateForm';

export const validate = (
    values: UpdateCustomerFormValues,
    props: OwnProps,
): Errors<UpdateCustomerFormValues> => {
    if (isEmpty(values)) { return {}; }
    const validator = new DairyValidator(values);

    validator.nonEmpty('firstName', 'Jméno');
    validator.nonEmpty('lastName', 'Příjmení');
    validator.nonEmpty('name', 'Objednatel');

    validator.address('address');
    if (!values.hasSameBillingAddress) {
        validator.address('billingAddress');
    }

    validator.invoiceDetails('invoiceDetails', {
        nonEmptyIcoWhenDicIsFilled: true,
        icoRequired: !values.hasNoIco,
    });

    if (props.isAdminOrLaborer) {
        validator.nonEmpty('priceClass', 'Cenová třída');
        validator.customerCode('code', 'Kód');

        if (values.priceClass === 'CONTRACTUAL') {
            validator.nonEmpty('code', 'Kód');
        }
    }

    return validator.generateErrorsObject();
};

export const asyncValidate = (
    values: UpdateCustomerFormValues,
    dispatch: Dispatch,
    props: OwnProps,
): Promise<boolean> => {
    const actionValues: CustomerValidation = {
        email: values.invoiceDetails.email,
        ico: values.invoiceDetails.ico,
        dic: values.invoiceDetails.dic,
        code: values.code,
    };

    return new Promise<boolean>((resolve, reject) => {
        dispatch(validateCustomer({
            values: actionValues,
            resolve,
            reject,
            isCreate: false,
            isAdminOrLaborer: props.isAdminOrLaborer,
        }));
    });
};
