import {SagaIterator} from 'redux-saga';
import {select, call, put} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {
    takeLatestF,
    showSuccess,
    extractFormErrorsFromResponse,
    ErrorResponse,
} from 'favorlogic-utils';

import {formValuesF, startSubmitF, stopSubmitF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';
import {UpdateUser} from 'types/model/users/UpdateUser';
import {StoreState} from 'app/types/StoreState';

import {loadUser, UpdateUserAction} from '../actions';
import Api from '../api';

const title = 'Úprava uživatele';

function* getUserId(): SagaIterator<number> {
    const user = opt(yield* select((state: StoreState) => state.user.user))
        .orCrash('missing user data');

    return user.id;
}

function* getUpdateUser(): SagaIterator<UpdateUser> {
    const values = (yield* select(formValuesF('updateUser'))).orCrash('missing form data');
    const {firstName, lastName} = values;

    return {firstName, lastName};
}

function* handleSuccess(userId: number): SagaIterator {
    yield* put(showSuccess(title, 'Uživatel úspěšně upraven'));
    yield* put(stopSubmitF('updateUser'));
    yield* put(loadUser(userId));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('updateUser', extractFormErrorsFromResponse(response)));
}

export function* execute(_: UpdateUserAction): SagaIterator {
    yield* put(startSubmitF('updateUser'));

    const userId = yield* call(getUserId);
    const updateUser = yield* call(getUpdateUser);
    const response = yield* call(Api.updateUser, userId, updateUser);

    yield* response.isSuccess
        ? call(handleSuccess, userId)
        : call(handleErrorResponse, response);
}

export function* updateUserSaga(): SagaIterator {
    yield takeLatestF('user/UPDATE_USER', execute);
}
