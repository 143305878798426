import {isNaN} from 'lodash/fp';

const editDateNumber = (value: number) => `0${String(value)}`.slice(-2);

const calculateEaster = (year: number, day: 'friday' | 'monday') => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = h + l + 7 * m + 114;
    const n = Math.floor(n0 / 31) - 1;
    const p = n0 % 31 + 1;

    if (day === 'friday') {
        return `${year}-${editDateNumber(n + 1)}-${editDateNumber(p - 2)}`;
    } else {
        return `${year}-${editDateNumber(n + 1)}-${editDateNumber(p + 1)}`;
    }
};

export const publicHolidays = (year: number): string[] => {
    if (year < 1 || isNaN(year)) {
        return [];
    } else {
        return [
            `${year}-01-01`,
            calculateEaster(year, 'friday'),
            calculateEaster(year, 'monday'),
            `${year}-05-01`,
            `${year}-05-08`,
            `${year}-07-05`,
            `${year}-07-06`,
            `${year}-09-28`,
            `${year}-10-28`,
            `${year}-11-17`,
            `${year}-12-24`,
            `${year}-12-25`,
            `${year}-12-26`,
        ];
    }
};
