import * as t from 'io-ts';
import {PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {CreateSupplier, CreateSupplierSchema} from 'types/model/suppliers/CreateSupplier';
import {MilkRoom, MilkRoomSchema} from 'types/model/milkRooms/MilkRoom';
import {MilkRoomValidation, MilkRoomValidationSchema} from 'types/model/milkRooms/MilkRoomValidation';
import {UpdateMilkRoom, UpdateMilkRoomSchema} from 'types/model/milkRooms/UpdateMilkRoom';
import {SupplierValidation, SupplierValidationSchema} from 'types/model/suppliers/SupplierValidation';
import {UpdateSupplier, UpdateSupplierSchema} from 'types/model/suppliers/UpdateSupplier';
import {SuppliersPage, SuppliersPageSchema} from 'types/model/suppliers/SuppliersPage';
import {SuppliersParams} from './types/SuppliersParams';
import {SupplierDetails, SupplierDetailsSchema} from 'types/model/suppliers/SupplierDetails';
import {apiRequester} from 'app/api';

class Api {
    getSuppliers(params: SuppliersParams): PResponse<SuppliersPage> {
        return apiRequester.makeRequest({
            url: '/suppliers',
            params,
            requestSchema: t.void,
            responseSchema: SuppliersPageSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    getSupplier(id: number): PResponse<SupplierDetails> {
        return apiRequester.makeRequest({
            url: `/suppliers/${id}`,
            requestSchema: t.void,
            responseSchema: SupplierDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    deleteSupplier(id: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/suppliers/${id}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    updateSupplier(id: number, data: UpdateSupplier): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/suppliers/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateSupplierSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    createSupplier(data: CreateSupplier): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/suppliers',
            method: 'post',
            data,
            requestSchema: CreateSupplierSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    validateSupplier(data: SupplierValidation): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/suppliers/validate',
            method: 'post',
            data,
            requestSchema: SupplierValidationSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getMilkRoom(milkRoomId: number): PResponse<MilkRoom> {
        return apiRequester.makeRequest({
            url: `/milk-rooms/${milkRoomId}`,
            requestSchema: t.void,
            responseSchema: MilkRoomSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    deleteMilkRoom(milkRoomId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/milk-rooms/${milkRoomId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    updateMilkRoom(milkRoomId: number, data: UpdateMilkRoom): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/milk-rooms/${milkRoomId}`,
            method: 'put',
            data,
            requestSchema: UpdateMilkRoomSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    createMilkRoom(supplierId: number, data: UpdateMilkRoom): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/suppliers/${supplierId}/milk-rooms`,
            method: 'post',
            data,
            requestSchema: UpdateMilkRoomSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    validateMilkRoom(supplierId: number, data: MilkRoomValidation): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/suppliers/${supplierId}/milk-rooms/validate`,
            method: 'post',
            data,
            requestSchema: MilkRoomValidationSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getSuppliersMilkRooms(supplierId: number): PResponse<MilkRoom[]> {
        return apiRequester.makeRequest({
            url: `/suppliers/${supplierId}/milk-rooms`,
            requestSchema: t.void,
            responseSchema: t.array(MilkRoomSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getMilkRooms(): PResponse<MilkRoom[]> {
        return apiRequester.makeRequest({
            url: '/milk-rooms',
            requestSchema: t.void,
            responseSchema: t.array(MilkRoomSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    activateMilkRoom(milkRoomId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/milk-rooms/${milkRoomId}/activate`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    deactivateMilkRoom(milkRoomId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/milk-rooms/${milkRoomId}/deactivate`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
