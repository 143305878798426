import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {put, select, call, take} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    takeLatestF,
    ErrorsFromBE,
    ErrorResponse,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {stopSubmitF, startSubmitF, resetF, formValuesF} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';

import Api from '../api';
import {loginSuccess, loginError, loadCurrent} from '../actions';
import {getHomepageOfRole} from '../utils/getHomepageOfRole';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, 'Přihlášení', {isLogin: true});
    yield* put(loginError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('login', extractFormErrorsFromResponse(response)));
}

function* handleSuccessResponse(): SagaIterator {
    yield* put(loginSuccess());
    yield* put(stopSubmitF('login'));
    yield* put(loadCurrent());
    yield* take('user/LOAD_CURRENT_SUCCESS');
    const user = (yield* select((state: StoreState) => state.user.currentUser))
        .orCrash('missing current user');
    yield* put(routerActions.push(getHomepageOfRole(user.role)));
    yield* put(resetF('login'));
}

function* execute(): SagaIterator {
    yield* put(startSubmitF('login'));

    const values = (yield* select(formValuesF('login'))).orCrash('missing login form data');
    const response = yield* call(Api.login, values);

    yield* response.isSuccess ? call(handleSuccessResponse) : call(handleErrorResponse, response);
}

export function* loginSaga(): SagaIterator {
    yield takeLatestF('user/LOGIN', execute);
}
