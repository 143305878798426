import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {SetSamplesSubsidySelectionAction} from '../actions';
import Api from '../api';
import {handleSuccess, handleErrorResponse} from './utils/setSamplesSubsidySagaUtils';

function* execute({payload: {sampleIds, value}}: SetSamplesSubsidySelectionAction): SagaIterator {
    const response = yield* call(Api.setSamplesSubsidySelection, sampleIds, value);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* setSamplesSubsidySelectionSaga(): SagaIterator {
    yield takeLatestF('measurement/SET_SAMPLES_SUBSIDY_SELECTION', execute);
}
