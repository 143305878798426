export interface ForceAsyncValidationActionArgs {
    formName: string;
    fieldName?: string;
    newValue?: unknown;
}

export interface ForceAsyncValidationAction {
    type: 'forms/FORCE_ASYNC_VALIDATION';
    payload: ForceAsyncValidationActionArgs;
}

export const forceAsyncValidation = (payload: ForceAsyncValidationActionArgs): ForceAsyncValidationAction => ({
    type: 'forms/FORCE_ASYNC_VALIDATION',
    payload,
});

// ---

export type FormsAction = ForceAsyncValidationAction;
