import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {DeleteBySelectionAction} from '../actions';
import Api from '../api';
import {handleErrorResponse, handleSuccess} from './utils/deleteSagaUtils';

function* execute({payload: {sampleIds}}: DeleteBySelectionAction): SagaIterator {
    const response = yield* call(Api.deleteBySelection, sampleIds);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* deleteBySelectionSaga(): SagaIterator {
    yield takeLatestF('measurement/DELETE_BY_SELECTION', execute);
}
