import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {OrderStateSchema} from 'types/model/enumeration/OrderState';

const optionalPart = t.partial({
    accepted: DateStringSchema,
    note: t.string,
    customerCode: t.string,
});
const requiredPart = t.interface({
    orderId: IntegerSchema,
    created: DateStringSchema,
    state: OrderStateSchema,
    customerName: t.string,
    hasAdditionalProtocolBilled: t.boolean,
    hasProtocol: t.boolean,
    hasMeasurements: t.boolean,
    hasAllMeasurements: t.boolean,
    hasInvoice: t.boolean,
});

export const OrderViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OrderView extends t.TypeOf<typeof OrderViewSchema> {}
