import * as t from 'io-ts';

import {DicSchema} from 'types/generic/DicSchema';
import {IcoSchema} from 'types/generic/IcoSchema';

const optionalPart = t.partial({
    phone: t.string,
    ico: IcoSchema,
    dic: DicSchema,
});
const requiredPart = t.interface({
});

export const InvoiceDetailsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface InvoiceDetails extends t.TypeOf<typeof InvoiceDetailsSchema> {}
