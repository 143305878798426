import * as O from 'optics-ts';

import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';

import {
    EDIT_PICKUP_FORM_NAME,
    WritePickupLineFormValues,
} from './components/WritePickupLineForm/writePickupLineFormValues';
import {
    PickupLinePlanningFormValues,
    PICKUP_LINE_PLANNING_FORM_NAME,
} from './components/PickupLinePlanningForm/pickupLinePlanningFormValues';
import {
    PickupLineDairyPlanFormValues,
    PICKUP_LINE_DAIRY_PLAN_FORM_NAME,
} from './components/PickupLineDairyPlanForm/pickupLineDairyPlanFormValues';

type EditState = GenericFormState<WritePickupLineFormValues>;
type PlanningState = GenericFormState<PickupLinePlanningFormValues>;
type EditDairyPlanState = GenericFormState<PickupLineDairyPlanFormValues>;

const editValuesO = O.optic<EditState>().prop('values');
const planningValuesO = O.optic<PlanningState>().prop('values');
const editDairyPlanValuesO = O.optic<EditDairyPlanState>().prop('values');

export const pickupLineFormReducer: CFormReducer = {
    [EDIT_PICKUP_FORM_NAME]: (state: EditState, action: Action): EditState => {
        switch (action.type) {
            case 'pickupLine/SET_PICKUP_LINE_EDIT_FORM_VALUES': {
                const {values} = action.payload;

                return O.set(editValuesO)(values)(state);
            }

            default:
                return state;
        }
    },
    [PICKUP_LINE_PLANNING_FORM_NAME]: (state: PlanningState, action: Action): PlanningState => {
        switch (action.type) {
            case 'pickupLine/SET_PICKUP_LINE_PLANNING_FORM_VALUES': {
                const {values} = action.payload;

                return O.set(planningValuesO)(values)(state);
            }

            default:
                return state;
        }
    },
    [PICKUP_LINE_DAIRY_PLAN_FORM_NAME]: (state: EditDairyPlanState, action: Action): EditDairyPlanState => {
        switch (action.type) {
            case 'pickupLine/SET_PICKUP_LINE_DAIRY_PLAN_FORM_VALUES': {
                const {values} = action.payload;

                return O.set(editDairyPlanValuesO)(values)(state);
            }

            default:
                return state;
        }
    },
};
