exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-icons-components-RowIcon-___styles__rowIcon___3CvEV svg {\n  opacity: .7;\n  width: 24px;\n  height: 24px;\n  transform: scaleX(-1); }\n\n.src-modules-icons-components-RowIcon-___styles__green___1SFKc {\n  fill: #259b24; }\n\n.src-modules-icons-components-RowIcon-___styles__gray___3-YfW {\n  fill: #adb5bd; }\n", ""]);

// exports
exports.locals = {
	"rowIcon": "src-modules-icons-components-RowIcon-___styles__rowIcon___3CvEV",
	"green": "src-modules-icons-components-RowIcon-___styles__green___1SFKc",
	"gray": "src-modules-icons-components-RowIcon-___styles__gray___3-YfW"
};