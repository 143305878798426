import {none, opt} from 'ts-opt';
import * as O from 'optics-ts';

import {PickupLineAction} from './actions';
import {initialPickupLineState, PickupLineState} from './state';
import {appendUniq} from 'favorlogic-utils';
import {without} from 'lodash/fp';

const loadingO = O.optic<PickupLineState>().prop('loading');
const pickupLinesO = O.optic<PickupLineState>().prop('pickupLines');
const unassignedDairiesO = O.optic<PickupLineState>().prop('unassignedDairies');
const pickupLineIdInEditO = O.optic<PickupLineState>().prop('pickupLineIdInEdit');
const pickupLinesOptionsO = O.optic<PickupLineState>().prop('pickupLinesOptions');
const overviewDateO = O.optic<PickupLineState>().prop('overviewDate');
const planningDateO = O.optic<PickupLineState>().prop('planningDate');
const pickupLineRequestsO = O.optic<PickupLineState>().prop('pickupLineRequests');
const displayedPickupLineO = O.optic<PickupLineState>().prop('displayedPickupLine');
const pickupLineDairyPlanO = O.optic<PickupLineState>().prop('pickupLineDairyPlan');

export const pickupLinereducer = (
    state: PickupLineState | undefined = initialPickupLineState,
    action: PickupLineAction,
): PickupLineState => {
    switch (action.type) {
        case 'pickupLine/SET_LOADING': {
            const {id, loading} = action.payload;

            return O.modify(loadingO)(x => loading ? appendUniq(id)(x) : without([id])(x))(state);
        }

        case 'pickupLine/GET_PICKUP_LINES_SUCCESS': {
            const {pickupLines} = action.payload;

            return O.set(pickupLinesO)(opt(pickupLines))(state);
        }

        case 'pickupLine/GET_UNASSIGNED_DAIRIES_SUCCESS': {
            const {unassignedDairies} = action.payload;

            return O.set(unassignedDairiesO)(opt(unassignedDairies))(state);
        }

        case 'pickupLine/SET_PICKUP_LINE_ID_IN_EDIT': {
            const {pickupLineId} = action.payload;

            return O.set(pickupLineIdInEditO)(pickupLineId ? opt(pickupLineId) : none)(state);
        }

        case 'pickupLine/GET_PICKUP_LINES_OPTIONS_SUCCESS': {
            const {data} = action.payload;

            return O.set(pickupLinesOptionsO)(opt(data))(state);
        }

        case 'pickupLine/SET_OVERVIEW_DATE': {
            const {date} = action.payload;

            return O.set(overviewDateO)(opt(date))(state);
        }

        case 'pickupLine/SET_PLANNING_DATE': {
            const {date} = action.payload;

            return O.set(planningDateO)(opt(date))(state);
        }

        case 'pickupLine/GET_PICKUP_LINE_REQUESTS_SUCCESS': {
            const {data} = action.payload;

            return O.set(pickupLineRequestsO)(opt(data))(state);
        }

        case 'pickupLine/GET_PICKUP_LINE_REQUESTS_ERROR': {
            return O.set(pickupLineRequestsO)(none)(state);
        }

        case 'pickupLine/SET_DISPLAYED_PICKUP_LINE': {
            const {pickupLineId} = action.payload;

            return O.set(displayedPickupLineO)(opt(pickupLineId))(state);
        }

        case 'pickupLine/GET_PICKUP_LINE_DAIRY_PLAN_SUCCESS': {
            const {data} = action.payload;

            return O.set(pickupLineDairyPlanO)(opt(data))(state);
        }

        default:
            return state;
    }
};
