import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {SamplesFromSupplyChainFormValues} from '../../types/SamplesFromSupplyChainFormValues';

type Values = SamplesFromSupplyChainFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('supplyChainIds', 'Linky');
    validator.nonEmpty('samples', 'Vzorky');

    return validator.generateErrorsObject();
};
