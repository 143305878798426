import * as t from 'io-ts';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    note: t.string,
});

export const DisableSampleSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface DisableSample extends t.TypeOf<typeof DisableSampleSchema> {}
