import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadSupplyChainsError, loadSupplyChainsSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const errorTitle = 'Načtení linek';
    const response = yield* call(Api.getSupplyChains);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadSupplyChainsError(error));
    }

    yield* put(loadSupplyChainsSuccess(response.data));
}

export function* loadSupplyChainsSaga(): SagaIterator {
    yield takeLatestF('supplyChain/LOAD_SUPPLY_CHAINS', execute);
}
