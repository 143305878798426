import {isEmpty} from 'lodash/fp';
import {Dispatch} from 'redux';
import {emptyStringToUndefined} from 'favorlogic-utils';

import {CreateSupplierFormValues} from 'supplier/types/CreateSupplierFormValues';
import {SupplierValidation} from 'types/model/suppliers/SupplierValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {validateSupplier} from '../../actions';
import {UpdateSupplierFormValues} from '../../types/UpdateSupplierFormValues';

type Values = CreateSupplierFormValues | UpdateSupplierFormValues;

interface SupplierFormValidateArgs {isCreate: boolean}

const isCreate = (
    args: SupplierFormValidateArgs,
    _validator: DairyValidator<Values>,
): _validator is DairyValidator<CreateSupplierFormValues> => args.isCreate;

export const validate = (args: SupplierFormValidateArgs) => (values: Values): Errors<Values> => {
    if (isEmpty(values)) { return {}; }
    const validator = new DairyValidator(values);

    validator.nonEmpty('name', 'Název firmy/družstva');
    validator.supplierInvoiceDetails('invoiceDetails', {
        nonEmptyIcoWhenDicIsFilled: true,
        icoRequired: !values.hasNoIco,
    });
    validator.address('address');

    if (isCreate(args, validator)) {
        validator.nonEmpty('milkRooms', 'Mléčnice');
    }

    return validator.generateErrorsObject();
};

export const asyncValidate =
    (args: SupplierFormValidateArgs) => (values: Values, dispatch: Dispatch): Promise<boolean> =>
        new Promise<boolean>((resolve, reject) => {
            const v = values;
            const actionValues: SupplierValidation = {
                name: emptyStringToUndefined(v.name),
                email: emptyStringToUndefined(v.invoiceDetails.email),
                ico: emptyStringToUndefined(v.invoiceDetails.ico),
                dic: emptyStringToUndefined(v.invoiceDetails.dic),
            };
            dispatch(validateSupplier({values: actionValues, resolve, reject, isCreate: args.isCreate}));
        });
