import {ArrayBufferSchema, PResponse} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {DownloadFileActionArguments} from 'download/actions';
import {apiRequester} from 'app/api';

class Api {
    download<Data>(args: DownloadFileActionArguments<Data>): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: args.url,
            method: args.method || 'get',
            data: args.data,
            params: args.params,
            responseType: 'arraybuffer',
            requestSchema: args.requestSchema || null,
            responseSchema: ArrayBufferSchema,
            expectedStatus: StatusCodes.OK,
        });
    }
}

export default new Api();
