var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-app-components-Main-___styles__btn___2Wq1g, .src-modules-app-components-Main-___styles__form-control___3PlLh {\n  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15); }\n\nbody {\n  overflow: visible;\n  height: 100%; }\n\nhtml, #src-modules-app-components-Main-___styles__app___31MoQ {\n  height: 100%; }\n\n.src-modules-app-components-Main-___styles__customTable___3qNXd, img.src-modules-app-components-Main-___styles__cow___1mXEx {\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }\n\n.src-modules-app-components-Main-___styles__undefined___AB6kD, #src-modules-app-components-Main-___styles__undefined___AB6kD {\n  background-color: red;\n  color: lime;\n  border: 0.1em solid cyan; }\n  .src-modules-app-components-Main-___styles__undefined___AB6kD::before, #src-modules-app-components-Main-___styles__undefined___AB6kD::before {\n    content: 'UNDEFINED';\n    color: magenta;\n    width: 0; }\n\n.src-modules-app-components-Main-___styles__customTable___3qNXd {\n  background-color: white !important;\n  border-radius: 5px;\n  width: fit-content;\n  margin: auto;\n  white-space: nowrap;\n  overflow: auto;\n  max-width: 100%; }\n\n.src-modules-app-components-Main-___styles__scrollbarTop___3fP7Q {\n  transform: rotateX(180deg) !important; }\n\n.src-modules-app-components-Main-___styles__mainWrapper___3waEJ {\n  height: 100%;\n  background-image: url(" + escape(require("./background.png")) + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow: auto; }\n", ""]);

// exports
exports.locals = {
	"btn": "src-modules-app-components-Main-___styles__btn___2Wq1g",
	"form-control": "src-modules-app-components-Main-___styles__form-control___3PlLh",
	"app": "src-modules-app-components-Main-___styles__app___31MoQ",
	"customTable": "src-modules-app-components-Main-___styles__customTable___3qNXd",
	"cow": "src-modules-app-components-Main-___styles__cow___1mXEx",
	"undefined": "src-modules-app-components-Main-___styles__undefined___AB6kD",
	"scrollbarTop": "src-modules-app-components-Main-___styles__scrollbarTop___3fP7Q",
	"mainWrapper": "src-modules-app-components-Main-___styles__mainWrapper___3waEJ"
};