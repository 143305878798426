import React, {PureComponent, ReactNode} from 'react';
import {Form, FieldArray} from 'redux-form';
import {Opt, opt} from 'ts-opt';
import {validateKey, crash, isArray, classNames} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {Components as Forms, Fields} from 'forms';
import {SelectedValue, SelectOptions} from 'forms/components/BasicSelect';
import {PropsForForm} from 'forms/components/withForm';
import {
    DeviceMeasurementFormValues,
    DeviceMeasurementsFormValues,
} from 'measurement/type/DeviceMeasurementsFormValues';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {DataItem} from 'types/model/dataItems/DataItem';
import {Device} from 'types/model/devices/Device';
import {genSampleOptions} from 'measurement/utils/genSampleOptions';

import {DeviceMeasurementResultsProps, DeviceMeasurementResults} from './DeviceMeasurementResults';
import {validate} from './validations';

import styles from './styles.sass';
import {memoize} from 'lodash/fp';

export const deviceMeasurementsInitialValues: DeviceMeasurementsFormValues = {
    measured: null,
    measuredById: null,
    deviceId: null,
    measurements: [],
};

export interface OwnProps {
    dataItems: DataItem[];
    userOptions: SelectOptions<number>;
    deviceOptions: SelectOptions<number>;
    samples: SampleSearch[] | null;
    selectedDevice?: Device;

    onDeviceChange(value: number | null): void;
}

export type Props = PropsForForm<DeviceMeasurementsFormValues, OwnProps>;

class DeviceMeasurementsForm extends PureComponent<Props> {
    componentDidUpdate(prevProps: Props) {
        const {samples: prevSamples} = prevProps;
        const {samples} = this.props;

        if (prevSamples !== samples) {
            this.initializeMeasurements();
        }
    }

    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
            userOptions,
            selectedDevice,
            deviceOptions,
            valid,
            dataItems,
            samples,
        } = this.props;
        const loading = Boolean(selectedDevice) && !samples;

        return (
            <Form
                onSubmit={handleSubmit}
            >
                <Layout.Panel>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.DateTime
                                name={validateKey<DeviceMeasurementsFormValues>('measured')}
                                mode="DATE"
                                label="Datum stanovení*"
                            />
                        </div>

                        <div className="col-12 col-md-6">
                            <Fields.Select<number>
                                name={validateKey<DeviceMeasurementsFormValues>('measuredById')}
                                label="Naměřil*"
                                options={userOptions}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Select<number>
                                name={validateKey<DeviceMeasurementsFormValues>('deviceId')}
                                label="Přístroj*"
                                options={deviceOptions}
                                onFieldChange={this.onDeviceChange}
                            />
                        </div>
                    </div>
                </Layout.Panel>

                <Layout.Panel>
                    <div className={classNames('position-relative', loading && styles.resultPanelLoading)}>
                        <Layout.Loader show={submitting || loading} />
                        <FieldArray<DeviceMeasurementResultsProps, DeviceMeasurementFormValues>
                            name="measurements"
                            component={DeviceMeasurementResults}
                            sampleOptions={this.getSampleOptions(samples)}
                            dataItems={dataItems}
                            deviceOptions={deviceOptions}
                            selectedDevice={selectedDevice}
                            loading={loading}
                        />
                    </div>
                </Layout.Panel>

                <div className="row">
                    <div className="col-12 mt-4">
                        <Buttons.Button
                            type="submit"
                            importance="primary"
                            disabled={!valid || submitting}
                            fullWidth
                        >
                            Vytvořit měření
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }

    private getSampleOptions = memoize(genSampleOptions);

    private initializeMeasurements(): void {
        const {autofill} = this.props;
        const measurements = this.buildInitialMeasurements();

        autofill('measurements', measurements);
    }

    private onDeviceChange = (valueOpt: Opt<SelectedValue<number>>) => {
        const {onDeviceChange} = this.props;
        const value = valueOpt.orNull();

        if (isArray(value)) {
            return crash('unexpected array');
        }

        onDeviceChange(value);
    };

    private buildInitialMeasurements(): DeviceMeasurementFormValues[] {
        const {samples} = this.props;

        const measurements: DeviceMeasurementFormValues[] = [];
        opt(samples).orElse([]).forEach((sample) => {
            measurements.push({
                sampleId: sample.id,
            });
        });

        return measurements;
    }
}

export default Forms.withForm<DeviceMeasurementsFormValues, OwnProps, Props>(DeviceMeasurementsForm, {
    form: 'deviceMeasurements',
    validate,
    initialValues: deviceMeasurementsInitialValues,
});
