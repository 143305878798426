import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {OptionalAddressSchema} from '../common/OptionalAddress';
import {SupplierInvoiceDetailsSchema} from '../common/SupplierInvoiceDetails';

const optionalPart = t.partial({
    note: t.string,
    address: OptionalAddressSchema,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    name: t.string,
    invoiceDetails: SupplierInvoiceDetailsSchema,
});

export const SupplierDetailsSchema = t.exact(t.intersection([
    optionalPart,
    requiredPart,
]));

export interface SupplierDetails extends t.TypeOf<typeof SupplierDetailsSchema> {}
