import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';

import {UpdateUserFormValues} from '../../types/UpdateUserFormValues';
import {validate} from './validations';

export interface UpdateUserFormProps {
}

type Props = PropsForForm<UpdateUserFormValues, UpdateUserFormProps>;

class UpdateUserForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    onSubmit={handleSubmit}
                    className="position-relative"
                >
                    <Layout.Loader show={submitting} />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<UpdateUserFormValues>('firstName')}
                                type="text"
                                label="Křestní jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<UpdateUserFormValues>('lastName')}
                                type="text"
                                label="Příjmení*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Buttons.Button
                                className="w-100 mt-4"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Uložit
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateUserFormValues, UpdateUserFormProps, Props>(UpdateUserForm, {
    form: 'updateUser',
    validate,
    initialValues: {
        lastName: '',
        firstName: '',
    },
});
