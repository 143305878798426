import {
    extractFormErrorsFromResponse,
    SuccessResponse,
    downloadFileFromResponse,
    takeLatestF,
    putAll,
    showBeError,
    ErrorResponse,
} from 'favorlogic-utils';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';

import {CreateGeometricMean} from 'types/model/measurements/geometricMeans/CreateGeometricMean';
import {formValuesF, resetF, stopSubmitF, startSubmitF} from 'utils/formHelpers';
import {setModalVisibility} from 'layout/actions';

import {ExportGeometricMeansFormValues} from '../type/ExportGeometricMeansFormValues';
import {ExportGeometricMeansAction} from '../actions';
import Api from '../api';

export function prepareGeometricMeansData(
    formValues: ExportGeometricMeansFormValues,
    isAdminOrLaborer: boolean
): CreateGeometricMean {
    if (isAdminOrLaborer) {
        return {
            tag: 'CreateGeometricMeanByLaborer',
            suspicious: formValues.suspicious,
            customerId: opt(formValues.customerId).orCrash('missing customer id'),
        };
    } else {
        return {
            tag: 'CreateGeometricMeanByCustomer',
            suspicious: formValues.suspicious,
        };
    }
}

function* getGeometricMeansData(isAdminOrLaborer: boolean): SagaIterator<CreateGeometricMean> {
    const formValues = (yield* select(formValuesF('exportGeometricMeans')))
        .orCrash('missing form values');

    return prepareGeometricMeansData(formValues, isAdminOrLaborer);
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield putAll(showBeError(response, 'Export geometrických průměrů'));
    yield* put(stopSubmitF('exportGeometricMeans', extractFormErrorsFromResponse(response)));
}

function* handleSuccessResponse(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* call(downloadFileFromResponse, response);
    yield* put(stopSubmitF('exportGeometricMeans'));
    yield* put(resetF('exportGeometricMeans'));
    yield* put(setModalVisibility('exportGeometrictMeans', false));
}

function* execute({payload: {isAdminOrLaborer}}: ExportGeometricMeansAction): SagaIterator {
    yield* put(startSubmitF('exportGeometricMeans'));

    const data = yield* call(getGeometricMeansData, isAdminOrLaborer);
    const response = yield* call(Api.generateGeometricMeans, data);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* exportGeometricMeansSaga(): SagaIterator {
    yield takeLatestF('measurement/EXPORT_GEOMETRIC_MEANS', execute);
}
