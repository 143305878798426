import * as t from 'io-ts';

import {VeterinaryIdSchema} from 'types/generic/VeterinaryIdSchema';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    name: t.string,
    code: t.string,
    veterinaryId: VeterinaryIdSchema,
});

export const UpdateMilkRoomSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateMilkRoom extends t.TypeOf<typeof UpdateMilkRoomSchema> {}
