import * as t from 'io-ts';

import {YearMonthSchema} from '../common/YearMonth';
import {PickupLineDairyPlanAnalysisSchema} from './PickupLineDairyPlanAnalysis';

const requiredPart = t.interface({
    yearMonth: YearMonthSchema,
    analysis: t.array(PickupLineDairyPlanAnalysisSchema),
});

export const PickupLineDairyPlanSchema = t.exact(requiredPart);

export interface PickupLineDairyPlan extends t.TypeOf<typeof PickupLineDairyPlanSchema> {}
