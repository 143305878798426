import * as t from 'io-ts';
import {ArrayBufferSchema, FormDataSchema, PResponse, EmptyObjectSchema, EmptyObject} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {
    CreateGeometricMean,
    CreateGeometricMeanSchema,
} from 'types/model/measurements/geometricMeans/CreateGeometricMean';
import {CreateMeasurements, CreateMeasurementsSchema} from 'types/model/measurements/CreateMeasurements';
import {MeasurementDetails, MeasurementDetailsSchema} from 'types/model/measurements/MeasurementDetails';
import {
    MeasurementImportResults,
    MeasurementImportResultsSchema,
} from 'types/model/measurements/MeasurementImportResults';
import {UpdateMeasurement, UpdateMeasurementSchema} from 'types/model/measurements/UpdateMeasurement';
import {SampleSearch, SampleSearchSchema} from 'types/model/samples/SampleSearch';
import {MeasurementDetailsPage, MeasurementDetailsPageSchema} from 'types/model/measurements/MeasurementDetailsPage';
import {MeasurementsImport, MeasurementsImportSchema} from 'types/model/measurements/MeasurementsImport';
import {SearchSamplesParams} from 'measurement/type/SearchSamplesParams';
import {apiRequester} from 'app/api';

import {buildFilterParams} from './utils/buildParams';
import {MeasurementsParams, MeasurementsFilter} from './type/MeasurementsParams';

class Api {
    generateGeometricMeans(data: CreateGeometricMean): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/measurements/geometric-means',
            method: 'post',
            data,
            requestSchema: CreateGeometricMeanSchema,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    import(operatorId: number, file: File): PResponse<MeasurementImportResults> {
        const data = new FormData();
        data.append('file', file);
        return apiRequester.makeRequest({
            url: '/measurements/import',
            method: 'post',
            params: {operatorId},
            data,
            requestSchema: FormDataSchema,
            responseSchema: MeasurementImportResultsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    searchSamples(params: SearchSamplesParams): PResponse<SampleSearch[]> {
        return apiRequester.makeRequest({
            url: '/samples/search',
            method: 'get',
            params,
            requestSchema: t.void,
            responseSchema: t.array(SampleSearchSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    createMeasurements(data: CreateMeasurements): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/measurements',
            data,
            method: 'post',
            requestSchema: CreateMeasurementsSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    createDeviceMeasurements(data: MeasurementsImport): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/measurements/import-manually',
            data,
            method: 'post',
            requestSchema: MeasurementsImportSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    getMeasurements(params: MeasurementsParams): PResponse<MeasurementDetailsPage> {
        return apiRequester.makeRequest({
            url: '/measurements',
            method: 'get',
            params: buildFilterParams(params),
            requestSchema: t.void,
            responseSchema: MeasurementDetailsPageSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    deleteMeasurement(measurementId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/measurements/${measurementId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    deleteBySelection(sampleIds: number[]): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/measurements/subset`,
            method: 'delete',
            params: {
                sampleIds,
            },
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    deleteByFilter(params: MeasurementsFilter): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/measurements`,
            method: 'delete',
            params,
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getMeasurement(id: number): PResponse<MeasurementDetails> {
        return apiRequester.makeRequest({
            url: `/measurements/${id}`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: MeasurementDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateMeasurement(id: number, data: UpdateMeasurement): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/measurements/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateMeasurementSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    approveSamplesByFilter(filter: MeasurementsFilter): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples/approve',
            params: buildFilterParams(filter),
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    approveSamplesSelection(sampleIds: number[]): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples/approve/subset',
            method: 'post',
            params: {sampleIds},
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    setSamplesSubsidyByFilter(filter: MeasurementsFilter, value: boolean): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples/subsidy',
            params: {
                value,
                ...buildFilterParams(filter),
            },
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    setSamplesSubsidySelection(sampleIds: number[], value: boolean): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples/subsidy/subset',
            method: 'post',
            params: {sampleIds, value},
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getRequestedDataItems(): PResponse<number[]> {
        return apiRequester.makeRequest({
            url: '/data-items/requested',
            requestSchema: t.void,
            responseSchema: t.array(t.number),
            expectedStatus: StatusCodes.OK,
        });
    }

    exportXlsByFilter(filter: MeasurementsFilter): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/measurements/export',
            params: buildFilterParams(filter),
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportXlsSelection(sampleIds: number[]): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/measurements/export/subset',
            params: {sampleIds},
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportSamplesSubsidyByFilter(filter: MeasurementsFilter): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/samples/subsidy/export',
            params: buildFilterParams(filter),
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportSamplesSubsidySelection(sampleIds: number[]): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/samples/subsidy/export/subset',
            method: 'get',
            params: {sampleIds},
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    importSamplesSubsidy(file: File): PResponse<EmptyObject> {
        const data = new FormData();

        data.append('file', file);

        return apiRequester.makeRequest({
            url: '/samples/subsidy/import',
            method: 'post',
            data,
            requestSchema: FormDataSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
