import React, {PureComponent, ReactNode} from 'react';
import {Field} from 'redux-form';

import {withGenericFormFieldElements} from 'forms/components/Field/withGenericFormFieldElements';
import Checkbox, {CheckboxInnerProps, CheckboxOuterProps} from 'forms/components/Checkbox';

class CheckboxField extends PureComponent<CheckboxInnerProps> {
    render(): ReactNode {
        return withGenericFormFieldElements(Checkbox, this.props, false);
    }
}

class CheckboxFieldOuter extends PureComponent<CheckboxOuterProps> {
    render(): ReactNode {
        const {
            name,
            label,
            className,
            disabled,
            helpText,
            onAction,
            tooltip,
            wrapperClassName,
            onFieldBlur,
            onFieldChange,
            fieldIsLoading,
        } = this.props;

        return <Field
            component={CheckboxField}
            name={name}
            label={label}
            className={className}
            disabled={disabled}
            helpText={helpText}
            onAction={onAction}
            tooltip={tooltip}
            wrapperClassName={wrapperClassName}
            onFieldChange={onFieldChange}
            onFieldBlur={onFieldBlur}
            fieldIsLoading={fieldIsLoading}
        />;
    }
}

export default CheckboxFieldOuter;
