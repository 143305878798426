import {ErrorsFromBE} from 'favorlogic-utils';

import {Device} from 'types/model/devices/Device';
import {DeviceFormValues} from './types/DeviceFormValues';

export interface LoadDevicesAction {
    type: 'device/LOAD_DEVICES';
}

export const loadDevices = (): LoadDevicesAction => ({
    type: 'device/LOAD_DEVICES',
});

export interface LoadDevicesSuccessAction {
    payload: Device[];
    type: 'device/LOAD_DEVICES_SUCCESS';
}

export const loadDevicesSuccess = (
    payload: Device[]
): LoadDevicesSuccessAction => ({
    type: 'device/LOAD_DEVICES_SUCCESS',
    payload,
});

export interface LoadDevicesErrorAction {
    type: 'device/LOAD_DEVICES_ERROR';
    payload: ErrorsFromBE;
}

export const loadDevicesError = (err: ErrorsFromBE): LoadDevicesErrorAction => ({
    type: 'device/LOAD_DEVICES_ERROR',
    payload: err,
});

// ---
export interface LoadDeviceAction {
    type: 'device/LOAD_DEVICE';
    payload: number;
}

export const loadDevice = (id: number): LoadDeviceAction => ({
    type: 'device/LOAD_DEVICE',
    payload: id,
});

export interface LoadDeviceSuccessAction {
    payload: Device;
    type: 'device/LOAD_DEVICE_SUCCESS';
}

export const loadDeviceSuccess = (payload: Device): LoadDeviceSuccessAction => ({
    type: 'device/LOAD_DEVICE_SUCCESS',
    payload,
});

export interface LoadDeviceErrorAction {
    type: 'device/LOAD_DEVICE_ERROR';
    payload: ErrorsFromBE;
}

export const loadDeviceError = (error: ErrorsFromBE): LoadDeviceErrorAction => ({
    type: 'device/LOAD_DEVICE_ERROR',
    payload: error,
});

// ---

interface UpdateDeviceActionArgs {
    id: number;
}

export interface UpdateDeviceAction {
    type: 'device/UPDATE_DEVICE';
    payload: UpdateDeviceActionArgs;
}

export const updateDevice = (
    payload: UpdateDeviceActionArgs
): UpdateDeviceAction => ({
    type: 'device/UPDATE_DEVICE',
    payload,
});

export interface UpdateDeviceSuccessAction {
    type: 'device/UPDATE_DEVICE_SUCCESS';
}

export const updateDeviceSuccess = (): UpdateDeviceSuccessAction => ({
    type: 'device/UPDATE_DEVICE_SUCCESS',
});

export interface UpdateDeviceErrorAction {
    type: 'device/UPDATE_DEVICE_ERROR';
    payload: ErrorsFromBE;
}

export const updateDeviceError = (err: ErrorsFromBE): UpdateDeviceErrorAction => ({
    type: 'device/UPDATE_DEVICE_ERROR',
    payload: err,
});

// ---

export interface PrefillDeviceFormAction {
    type: 'device/PREFILL_DEVICE_FORM';
    payload: DeviceFormValues;
}

export const prefillDeviceForm = (
    payload: DeviceFormValues
): PrefillDeviceFormAction => ({
    type: 'device/PREFILL_DEVICE_FORM',
    payload,
});

// ---

export type DeviceAction =
    LoadDevicesAction | LoadDevicesSuccessAction | LoadDevicesErrorAction |
    LoadDeviceAction | LoadDeviceSuccessAction | LoadDeviceErrorAction |
    UpdateDeviceAction | UpdateDeviceSuccessAction | UpdateDeviceErrorAction |
    PrefillDeviceFormAction
    ;
