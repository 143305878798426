import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {Opt, opt} from 'ts-opt';
import {
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    showSuccess,
    ErrorsFromBE,
    takeLatestF,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {handleResponseError} from 'utils/handleResponseError';
import {UpdateCustomer} from 'types/model/customers/UpdateCustomer';

import {UpdateAction, loadCurrent, updateError, updateSuccess, loadCustomer} from '../actions';
import {UpdateCustomerFormValues} from '../types/UpdateCustomerFormValues';
import Api from '../api';
import {correctCustomerErrors} from '../utils/correctCustomerErrors';
import {formValuesF, startSubmitF, stopSubmitF} from 'utils/formHelpers';

export function prepareUpdateData(byLaborer: boolean, formValues: UpdateCustomerFormValues): UpdateCustomer {
    const {hasSameBillingAddress, hasNoIco, code, note, priceClass, ...data} = formValues;
    const {invoiceDetails, address} = data;
    const error = 'missing price class for update customer by laborer';
    const byLaborerData = {code, note};

    if (hasSameBillingAddress) {
        data.billingAddress = {...address};
    }

    data.invoiceDetails = {
        ...invoiceDetails,
        ico: emptyStringToUndefined(invoiceDetails.ico),
        dic: emptyStringToUndefined(invoiceDetails.dic),
        phone: emptyStringToUndefined(invoiceDetails.phone),
    };

    return byLaborer
        ? {...data, ...byLaborerData, priceClass: opt(priceClass).orCrash(error), tag: 'UpdateCustomerByLaborer'}
        : {...data, tag: 'UpdateCustomerByCustomer'};
}

function* execute(action: UpdateAction): SagaIterator {
    const formName = 'updateCustomer';
    const {payload: {byLaborer, customerId: customerIdFromLaborer}} = action;
    const title = byLaborer ? 'Úprava zákazníka' : 'Úprava uživatelského účtu';

    yield* put(startSubmitF(formName));

    const formValues = (yield* select(formValuesF(formName))).orCrash('missing form values');
    const updateData = prepareUpdateData(byLaborer, formValues);

    const customer = yield* select((x: StoreState): Opt<CustomerDetails> => x.user.customerOfCurrentUser);
    const customerId = byLaborer
        ? customerIdFromLaborer.orCrash('missing customer id')
        : customer.orCrash('invalid state - customer is missing').id;

    const response = yield* call(Api.updateCustomer, updateData, customerId);

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        yield* put(updateError(response.data as ErrorsFromBE));
        return yield* put(stopSubmitF(formName, correctCustomerErrors(extractFormErrorsFromResponse(response))));
    }

    yield* put(showSuccess(title, 'Změny uloženy.'));
    yield* put(updateSuccess());
    yield* put(stopSubmitF(formName));

    if (byLaborer) {
        yield* put(loadCustomer(customerId));
    } else {
        yield* put(loadCurrent());
    }
}
export function* updateSaga(): SagaIterator {
    yield takeLatestF('user/UPDATE', execute);
}
