import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {UpdateDataItemSchema} from './UpdateDataItem';
import {DataItemProcedureSchema} from './DataItemProcedure';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    name: t.string,
    columnIndex: IntegerSchema,
    procedures: t.array(DataItemProcedureSchema),
});

export const DataItemSchema = t.exact(t.intersection([UpdateDataItemSchema.type, optionalPart, requiredPart]));

export interface DataItem extends t.TypeOf<typeof DataItemSchema> {}
