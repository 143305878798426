import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';

import {CreateUserFormValues} from '../../types/CreateUserFormValues';
import {validate} from './validations';

export interface CreateUserFormProps {
}

type Props = PropsForForm<CreateUserFormValues, CreateUserFormProps>;

class CreateUserForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    onSubmit={handleSubmit}
                    className="position-relative"
                >
                    <Layout.Loader show={submitting} />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<CreateUserFormValues>('username')}
                                type="text"
                                label="Uživatelské jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<CreateUserFormValues>('password')}
                                type="password"
                                label="Heslo*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<CreateUserFormValues>('firstName')}
                                type="text"
                                label="Křestní jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name={validateKey<CreateUserFormValues>('lastName')}
                                type="text"
                                label="Příjmení*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Buttons.Button
                                className="w-100 mt-4"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Vytvořit uživatele
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<CreateUserFormValues, CreateUserFormProps, Props>(CreateUserForm, {
    form: 'createUser',
    validate,
    initialValues: {
        lastName: '',
        firstName: '',
        username: '',
        password: '',
    },
});
