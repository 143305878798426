import {Column} from '../types/Column';

type ColumnName<RowT> = keyof RowT & string

/**
 * Pick columns from array. Useful mostly for conditional rendering of different columns. Eg.
 * depending on current user role.
 *
 * @param {Object[]} columns - Array of objects representing table columns.
 * @param {string} columnsToPick - One or more columns to pick.
 *
 * @return {Object[]} - Array of table columns.
 */
const pickColumns = <RowT>(columns: Column<RowT>[], ...columnsToPick: ColumnName<RowT>[]): Column<RowT>[] =>
    columns.filter(column => columnsToPick.includes(column.accessor as ColumnName<RowT>));

export default pickColumns;
