import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {UserDetails} from 'types/model/users/UserDetails';
import {show as showConfirmDialog} from 'confirmDialog/actions';

import withUser from '../components/withUser';
import UsersTable from '../components/UsersTable';
import {deleteUser, loadUsers} from '../actions';

interface OuterProps {}

interface StateProps {
    users: UserDetails[] | null;
}

interface DispatchProps {
    handleDeleteUser(userId: number): void;
    handleLoadUsers(): void;
}

type Props =  OuterProps & StateProps & DispatchProps;

class UsersList extends Component<Props> {
    componentDidMount(): void {
        const {handleLoadUsers} = this.props;

        handleLoadUsers();
    }

    render(): ReactNode {
        const {users, handleDeleteUser} = this.props;

        if (users && isEmpty(users)) {
            return this.renderNoUsers();
        }

        return (
            <Layout.ListPage
                title="Uživatelé"
                toolbar={this.renderToolbar()}
            >
                <UsersTable
                    users={users}
                    deleteUser={handleDeleteUser}
                />
            </Layout.ListPage>
        );
    }

    private renderToolbar(): ReactNode {
        return (
            <Buttons.Group>
                <Buttons.RightIconButton
                    label="Nový"
                    to="/users/new"
                    icon="ADD"
                />
            </Buttons.Group>
        );
    }

    private renderNoUsers(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nevytvořili jste prozatím žádné uživatelské podúčty.
                    </div>
                    <div>
                        Chcete vytvořit nový podúčet? Klikněte na tlačítko
                        <Buttons.RightIconButton
                            label="Nový"
                            to="/users/new"
                            className="m-2"
                            icon="ADD"
                        />
                    </div>
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    users: state.user.users,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleDeleteUser: (userId: number) => dispatch(showConfirmDialog({
        title: 'Smazat uživatele',
        text: `Opravdu chcete smazat Uživatele?`,
        confirmAction: deleteUser(userId),
    })),
    handleLoadUsers: () => dispatch(loadUsers()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(UsersList) as ComponentClass<OuterProps>;
