import {optEmptyObject} from 'ts-opt';

import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {
    SampleMeasurementFormValues,
    SampleMeasurementResult,
} from 'measurement/type/SampleMeasurementFormValues';

type Values = SampleMeasurementFormValues;
type ResultErrors = Partial<Record<keyof SampleMeasurementResult, string>>;

const validateMeasurement = (values: SampleMeasurementResult): ResultErrors | undefined => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('sampleId', 'Vzorek');
    validator.nonEmpty('result', 'Výsledek');
    validator.nonEmpty('dilution', 'Ředění');
    validator.nonEmpty('procedureId', 'Operační postup');

    return optEmptyObject(validator.generateErrorsObject() as ResultErrors).orUndef();
};

const validateBasics = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('measured', 'Datum stanovení');
    validator.nonEmpty('measuredById', 'Změřil');
    validator.nonEmpty('dataItem', 'Datová položka');
    validator.nonEmpty('barcode', 'Vzorek');
    validator.nonEmpty('measurement', 'Měření');

    return validator.generateErrorsObject();
};

export const validate = (values: Values): Errors<Values> => {
    const errors = validateBasics(values);

    if (!errors.measurement && values.measurement) {
        errors.measurement = validateMeasurement(values.measurement);
    }

    return errors;
};
