import * as t from 'io-ts';

import {UpdateCustomerByCustomerSchema} from './UpdateCustomerByCustomer';
import {UpdateCustomerByLaborerSchema} from './UpdateCustomerByLaborer';

export const UpdateCustomerSchema = t.taggedUnion('tag', [
    UpdateCustomerByCustomerSchema,
    UpdateCustomerByLaborerSchema,
]);

export type UpdateCustomer = t.TypeOf<typeof UpdateCustomerSchema>;
