import React, {Fragment, FunctionComponent, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

interface Props {
    caption?: string;
    children: ReactNode;
    className?: string;
}

const Panel: FunctionComponent<Props> = props => {
    const {caption, children, className} = props;
    const classes = classNames('w-100', styles.panel, className);

    return (
        <Fragment>
            {caption && <h3>{caption}</h3>}
            <div className={classes}>
                {children}
            </div>
        </Fragment>
    );
};

export default Panel;
