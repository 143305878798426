import React, {FunctionComponent} from 'react';
import {classNames} from 'favorlogic-utils';

import SvgIcon from '../SvgIcon';

import styles from './styles.sass';

interface Props {
    icon: string;
    tooltip?: string;
    fillColor?: 'green' | 'gray';
}

const RowIcon: FunctionComponent<Props> = (props) => {
    const {icon, tooltip, fillColor} = props;

    return (
        <SvgIcon
            className={classNames(styles.rowIcon, fillColor && styles[fillColor])}
            icon={icon}
            title={tooltip}
        />
    );
};

export default RowIcon;
