import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
});

export const IdResponseSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface IdResponse extends t.TypeOf<typeof IdResponseSchema> {}
