import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import withUser from 'user/components/withUser';
import {StoreState} from 'app/types/StoreState';
import {downloadShippingTicket} from '../actions';

interface OuterProps {
}

interface InnerProps {
    orderId: number | null;
    isDownloading: boolean;

    handleDownloadShippingTicket(orderId: number): void;
}

type Props = InnerProps & OuterProps;

class NewShippingTicket extends Component<Props> {
    render(): ReactNode {
        const {handleDownloadShippingTicket, isDownloading, orderId} = this.props;

        if (!orderId) { return null; }

        const handleDownload = () => {
            handleDownloadShippingTicket(orderId);
        };

        return (
            <Layout.Page>
                <Layout.Panel className="p-5">
                    <div className="text-center">
                        <h4>Děkujeme za Vaši objednávku</h4>
                    </div>
                    <div className="text-center p-4">
                        <h1>Objednávka přijata</h1>
                    </div>
                    <div className="text-center p-3">
                        <Buttons.Button
                            importance="primary"
                            type="button"
                            onClick={handleDownload}
                            disabled={isDownloading}
                        >
                            Stáhnout přepravní lístek
                        </Buttons.Button>
                    </div>
                </Layout.Panel>
            </Layout.Page>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    orderId: state.order.lastCreatedOrderId,
    isDownloading: state.layout.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleDownloadShippingTicket: (orderId: number) => dispatch(downloadShippingTicket(orderId)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(NewShippingTicket) as ComponentClass<OuterProps>;
