import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {CreateMeasurementSchema} from 'types/model/measurements/CreateMeasurement';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    measured: DateStringSchema,
    measuredById: IntegerSchema,
    dataItemId: IntegerSchema,
    measurements: t.array(CreateMeasurementSchema),
});

export const CreateMeasurementsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateMeasurements extends t.TypeOf<typeof CreateMeasurementsSchema> {}
