import React, {PureComponent, ReactNode, createRef, FC} from 'react';
import {Field, WrappedFieldProps, WrappedFieldArrayProps} from 'redux-form';
import {isEmpty, isEqual} from 'lodash/fp';
import {opt} from 'ts-opt';
import {intercalateElem, classNames} from 'favorlogic-utils';

import {SelectOptions} from 'forms/components/BasicSelect';
import {RefInput} from 'forms/components/Input';
import {Fields} from 'forms';
import {DataItem} from 'types/model/dataItems/DataItem';
import {Device} from 'types/model/devices/Device';
import {
    DeviceMeasurementsFormValues,
    DeviceMeasurementFormValues,
} from 'measurement/type/DeviceMeasurementsFormValues';

import {Result} from './Result';

import styles from './styles.sass';

export interface DeviceMeasurementResultsProps {
    name: keyof DeviceMeasurementsFormValues;
    sampleOptions: SelectOptions<number>;
    deviceOptions: SelectOptions<number>;
    dataItems: DataItem[];
    selectedDevice?: Device;
    loading?: boolean;
}

type Props = WrappedFieldArrayProps<DeviceMeasurementFormValues> & DeviceMeasurementResultsProps;

export class DeviceMeasurementResults extends PureComponent<Props> {
    private resultRefs: RefInput[][] = [];

    componentDidUpdate(prevProps: Props): void {
        const {sampleOptions: prevSampleOptions} = prevProps;
        const {sampleOptions} = this.props;

        if (!isEqual(prevSampleOptions, sampleOptions)) {
            this.focusValue(0, 0);
        }
    }

    render(): ReactNode {
        const {fields, selectedDevice, loading} = this.props;
        this.setupRefs();

        if (isEmpty(fields)) {
            return (
                <div className="w-100 text-center text-muted">
                    {!selectedDevice && 'Vyberte přístroj'}
                    {selectedDevice && !loading && `Pro přístroj ${selectedDevice.name} nebyly nalezeny žádné vzorky`}
                </div>
            );
        }

        return <div>
            {!isEmpty(fields) && this.renderTable()}
        </div>;
    }


    renderTable = (): ReactNode => {
        const {fields, dataItems} = this.props;

        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Vzorek</th>
                            {dataItems.map((dataItem) =>
                                <th key={dataItem.id}>{dataItem.name}</th>,
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((field, index) => this.renderSampleRow(field, index))}
                    </tbody>
                </table>
            </div>
        );
    };

    private setupRefs(): void {
        const {fields, dataItems} = this.props;

        dataItems.forEach((dataItem, dataItemIndex) => {
            fields.forEach((_, index) => {
                if (!this.resultRefs[index] || this.resultRefs[index].length !== dataItems.length) {
                    this.resultRefs[index] = [];
                }

                this.resultRefs[index][dataItemIndex] = this.resultRefs[index][dataItemIndex] || createRef();
            });
        });
    }

    private focusNextValue = (rowIndex: number, dataItemIndex: number): void => {
        const {sampleOptions, dataItems} = this.props;

        const isLastDataItem = dataItemIndex === dataItems.length - 1;
        const existNextSampleItem = rowIndex + 1 <= sampleOptions.length - 1;

        let nextRowIndex;
        if (isLastDataItem) {
            nextRowIndex = existNextSampleItem ? rowIndex + 1 : 0;
        } else {
            nextRowIndex = rowIndex;
        }

        const nextDataItemIndex = isLastDataItem ? 0 : dataItemIndex + 1;
        this.focusValue(nextRowIndex, nextDataItemIndex);
    }

    private focusValue(rowIndex: number, dataItemIndex: number): void {
        this.resultRefs[rowIndex]?.[dataItemIndex]?.current?.focus();
    }

    private getSampleLabel(index: number): ReactNode {
        const {sampleOptions} = this.props;
        const labelRaw = opt(sampleOptions[index])
            .map(x => x.label)
            .orElse('Vzorek nenalezen');
        const labelDelim = <span className={styles.sampleLabelDelimiter}>/</span>;

        return intercalateElem(labelDelim, 'd-inline-block')(labelRaw.split(' / '));
    }

    private renderSampleRow(fieldName: string, index: number): ReactNode {
        const {dataItems} = this.props;
        const sampleColumnClasses = classNames(
            styles.wideTd,
            styles.sampleColumn,
            dataItems.length >= 8 && styles.sampleColumnNarrow,
        );

        return <tr key={index}>
            <td
                key={0}
                className={sampleColumnClasses}
            >
                {this.getSampleLabel(index)}
                <Fields.Input
                    type="hidden"
                    name={`${fieldName}.sampleId`}
                    label="Vzorek"
                    className="d-none"
                />
            </td>

            {dataItems.map((dataItem, dataItemIndex) =>
                <Result
                    key={dataItem.id}
                    name={`${fieldName}.${dataItem.id}`}
                    dataItem={dataItem}
                    rowIndex={index}
                    index={dataItemIndex}
                    inputRef={this.resultRefs[index][dataItemIndex]}
                    focusNextValue={this.focusNextValue}

                />
            )}

            <Field
                name={`${fieldName}.results`}
                component={this.renderErrorResultsField}
            />
        </tr>;
    }

    private renderErrorResultsField: FC<WrappedFieldProps> = props => {
        const {meta: {error}} = props;
        if (error) {
            return <td className={`invalid-tooltip ${styles.invalidRowTooltip}`}>
                {error}
            </td>;
        } else {
            return null;
        }
    }
}
