import React from 'react';
import {Form} from 'redux-form';

import {Components as Layout} from 'layout';
import {Components as Forms, Fields} from 'forms';
import {Components as Buttons} from 'buttons';
import {PropsForForm} from 'forms/components/withForm';
import {ExportTxtProtocolsFormValues} from 'order/types/ExportTxtProtocolsFormValues';

import {validate} from './validation';

interface OwnProps {}

type Props = PropsForForm<ExportTxtProtocolsFormValues, OwnProps>;

class ExportTxtProtocolsForm extends React.Component<Props> {
    render(): React.ReactNode {
        const {
            handleSubmit,
            submitting,
            valid,
        } = this.props;

        return (
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />

                <h2>Stažení protokolů</h2>

                <div className="row mt-2">
                    <div className="col-md-6">
                        <Fields.DateTime
                            mode="DATE"
                            name="from"
                            label="Od"
                        />
                    </div>

                    <div className="col-md-6">
                        <Fields.DateTime
                            mode="DATE"
                            name="to"
                            label="Do"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Stáhnout protokoly
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }
}


export default Forms.withForm<ExportTxtProtocolsFormValues, OwnProps, Props>(ExportTxtProtocolsForm, {
    form: 'exportTxtProtocols',
    validate,
    initialValues: {
        from: '',
        to: '',
    },
});
