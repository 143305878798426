import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {OperatingProcedureFormValues} from 'operatingProcedure/types/OperatingProcedureFormValues';
import {validate} from './validations';

export type OperatingProcedureFormMode = 'NEW' | 'EDIT';

interface OwnProps {
    mode: OperatingProcedureFormMode;
}

export type Props = PropsForForm<OperatingProcedureFormValues, OwnProps>;

class OperatingProcedureForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            mode,
            pristine,
        } = this.props;

        return (
            <Layout.Panel>
                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || mode === 'EDIT' && pristine} />
                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name="name"
                            type="text"
                            label="Název*"
                        />
                    </div>

                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name="description"
                            type="text"
                            label="Popis*"
                        />
                    </div>

                    <div className="col-12">
                        <div className="d-flex justify-content-end mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                {mode === 'NEW' ? 'Vytvořit' : 'Uložit'}
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<OperatingProcedureFormValues, OwnProps, Props>(OperatingProcedureForm, {
    form: 'operatingProcedure',
    validate,
    initialValues: {
        name: '',
        description: '',
    },
});
