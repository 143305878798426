import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    resetSearchSamples,
    SearchSamplesAction,
    searchSamplesError,
    searchSamplesSuccess,
} from '../actions';
import Api from '../api';

function* execute(action: SearchSamplesAction): SagaIterator {
    const title = 'Vyhledání vzorků';
    const response = yield* call(Api.searchSamples, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        yield* put(searchSamplesError(error));
        return yield* put(resetSearchSamples());
    }

    yield* put(searchSamplesSuccess(response.data));
}

export function* searchSamplesSaga(): SagaIterator {
    yield takeLatestF('measurement/SEARCH_SAMPLES', execute);
}
