import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {MilkRoomsTableMode} from './index';

interface Props {
    id: number;
    mode: MilkRoomsTableMode;
    supplierId?: number;
    canDelete: boolean;
    active: boolean;

    handleDelete?(id: number): void;
    handleDeactivate?(id: number): void;
    handleActivate?(id: number): void;
}

class RowActions extends Component<Props> {
    handleToggleActive = (): void => {
        const {handleDeactivate, handleActivate, active, id} = this.props;
        const handleFn = active ? handleDeactivate : handleActivate;

        handleFn?.(id);
    };

    render(): ReactNode {
        const {
            handleDelete,
            id,
            mode,
            supplierId,
            canDelete,
            active,
        } = this.props;
        const detailUrl = `/suppliers/${supplierId}/milk-room/${id}`;
        return (
            <div>
                {mode === 'EDIT' && <Buttons.RowActionButton
                    type={active ? 'DEACTIVATE' : 'ACTIVATE'}
                    onClick={this.handleToggleActive}
                />}
                {mode === 'EDIT' && <Buttons.RowActionButton
                    type="UPDATE"
                    to={detailUrl}
                />}
                {handleDelete && mode !== 'VIEW' && <Buttons.RowActionButton
                    type="DELETE"
                    onClick={() => handleDelete(id)}
                    disabled={!canDelete}
                />}
            </div>
        );
    }
}

export default RowActions;
