import React, {Component, ReactNode, Fragment} from 'react';

import {DownloadFileActionArguments} from 'download/actions';
import {Components as Tables} from 'tables';
import {Components as Icons} from 'icons';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {OrderView} from 'types/model/orders/OrderView';
import {orderStateOptions} from '../../enums/OrderStateEnum';
import RowActions from './RowActions';
import {OrdersPage} from 'types/model/orders/OrdersPage';
import {Filter} from 'tables/types/Filter';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {Sort} from 'types/generic/Sort';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';

import noteIcon from 'buttons/components/RowActionButton/img/update.svg';
import {LoaderWrapper} from 'layout/components';

const TABLE_ANNOTATIONS: ColorAnnotation[] = [
    {color: 'warning', label: 'Obsahuje měření'},
    {color: 'info', label: 'Obsahuje všechna měření'},
    {color: 'dangerous', label: 'Dokončená/zrušená objednávka'},
];

const limit = convertStringToLimitedLengthElement(40);

export const isCompletedOrCanceled = (order: OrderView): boolean =>
    order.state === 'COMPLETED' || order.state === 'CANCELED';

export type OrderRow = Omit<OrderView, 'customerName' | 'note'> & {
    customerName: string | ReactNode,
    note: string | ReactNode,
    actions: ReactNode,
};

interface OuterProps {
    ordersPage?: OrdersPage;
    sort: Sort;
    filter: Filter;
    isRoleAdminOrLaborer: boolean;
    isRoleDairy: boolean;
    isRoleDairyEmployee: boolean;
    isRoleAdmin: boolean;
    selectedIds: number[];

    handleDownload(args: DownloadFileActionArguments<undefined>): void;
    changePage(page: number): void;
    handleSelect(id: number): void;
    changePageSize(size: number): void;
    changeSort(property: string): void;
    changeFilter(accessor: string, type: string, values: string[]): void;
    resetFilter(): void;
    filterOrders(): void;
    downloadInvoice(orderId: number): void;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

export const definedFilters: FilterDefinition<keyof OrderRow>[] = [
    {key: 'orderId', type: 'equals'},
    {key: 'accepted', type: 'date'},
    {key: 'state', type: 'select'},
    {key: 'customerName', type: 'equals'},
    {key: 'customerCode', type: 'equals'},
    {key: 'created', type: 'date'},
];

class OrdersTable extends Component<Props> {
    render(): React.ReactNode {
        const {
            ordersPage,
            changePage,
            changePageSize,
            filter,
            changeFilter,
            resetFilter,
            filterOrders,
            changeSort,
            sort,
            handleSelect,
        } = this.props;

        return (
            <Fragment>
                <LoaderWrapper showLoader={!ordersPage}>
                    <Tables.Table
                        columns={this.genColumns()}
                        rows={this.genData()}
                        handleSortChange={changeSort}
                        sortBy={sort.property}
                        sortDir={sort.direction}
                        page={ordersPage ? ordersPage.pageable.pageNumber : 0}
                        pageSize={ordersPage ? ordersPage.pageable.pageSize : 0}
                        total={ordersPage ? ordersPage.totalElements : 0}
                        handlePageChange={changePage}
                        handlePageSizeChange={changePageSize}
                        hasFooter
                        handleFilterChange={changeFilter}
                        handleFilterClear={resetFilter}
                        handleFilterSubmit={filterOrders}
                        filter={filter}
                        hasHeader
                        hasTwoMainColumns
                        rowSelect
                        handleSelectRow={handleSelect}
                    />
                    <Tables.Legend annotations={TABLE_ANNOTATIONS} />
                </LoaderWrapper>
            </Fragment>
        );
    }

    private genData(): Row<OrderRow>[] {
        const {
            handleDownload,
            handleSelect,
            selectedIds,
            ordersPage,
            isRoleDairy,
            isRoleDairyEmployee,
            isRoleAdminOrLaborer,
            isRoleAdmin,
            downloadInvoice,
        } = this.props;

        if (!ordersPage) {
            return [];
        }

        return ordersPage.content.map(order => {
            const isFullMeasured = order.hasAllMeasurements;
            const isPartMeasured = order.hasMeasurements && !isFullMeasured;
            const isCompletedOrCanceled = order.state === 'COMPLETED' || order.state === 'CANCELED';
            const selected = selectedIds.includes(order.orderId);

            return {
                id: order.orderId,
                ...order,
                customerName: limit(order.customerName),
                dangered: isCompletedOrCanceled,
                warninged: isPartMeasured && !isCompletedOrCanceled,
                informed: isFullMeasured && !isCompletedOrCanceled,
                selected,
                note: order.note
                    ? <Icons.RowIcon
                        icon={noteIcon}
                        tooltip={order.note}
                    />
                    : '',
                actions:
                    <RowActions
                        order={order}
                        selected={selected}
                        canDownloadTxtProcol={isRoleDairy}
                        canDownloadPdfProcol={!isRoleDairyEmployee}
                        canDownloadSamples={isRoleAdminOrLaborer}
                        canDownloadInvoice={isRoleAdmin}
                        onDownload={handleDownload}
                        onSelect={handleSelect}
                        onDownloadInvoice={() => downloadInvoice(order.orderId)}
                    />
                ,
            };
        });
    }

    private genColumns(): Column<OrderRow>[] {
        const {isRoleAdminOrLaborer} = this.props;
        const customerCodeColumn: Column<{customerCode: string}>  = {
            accessor: 'customerCode',
            header: 'Číslo zák.',
            tooltip: 'Číslo zákazníka',
            type: ColumnType.Node,
            filterable: true,
            filterType: 'equals',
            sortable: true,
            size: 'narrow',
        };

        return [
            {
                accessor: 'note',
                header: '',
                type: ColumnType.Node,
                size: 'narrow',
            },
            {
                accessor: 'orderId',
                header: 'ID',
                type: ColumnType.Node,
                filterable: true,
                filterType: 'equals',
                filterInputType: 'integer',
                sortable: true,
                size: 'narrow',
            },
            {
                accessor: 'state',
                header: 'Stav',
                type: ColumnType.SelectOption,
                selectOptions: orderStateOptions,
                filterable: true,
                filterType: 'select',
            },
            {
                accessor: 'created',
                header: 'Vytvořeno',
                tooltip: 'Datum vytvoření',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable: true,
                size: 'normal',
                filterable: true,
                filterType: 'betweenDates',
            },
            {
                accessor: 'accepted',
                header: 'Přijato',
                tooltip: 'Datum přijetí',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                filterable: true,
                filterType: 'betweenDates',
                sortable: true,
                size: 'normal',
            },
            ...isRoleAdminOrLaborer ? [customerCodeColumn] : [],
            {
                accessor: 'customerName',
                header: 'Zákazník',
                type: ColumnType.Node,
                filterable: true,
                filterType: 'equals',
                sortable: true,
                size: 'wide',
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }
}

export default OrdersTable;
