import {DateStringSchema} from 'favorlogic-utils';
import * as t from 'io-ts';

import {PickupLineOptionStateSchema} from './PickupLineOptionState';


const requiredPart = t.interface({
    id: t.number,
    pickupDate: DateStringSchema,
    state: PickupLineOptionStateSchema,
});

export const PickupLineOptionSchema = t.exact(requiredPart);

export interface PickupLineOption extends t.TypeOf<typeof PickupLineOptionSchema> {}
