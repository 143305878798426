import React, {FunctionComponent, SyntheticEvent} from 'react';

import Label from 'forms/components/Label';
import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {FilterPart} from '../../types/FilterPart';

import styles from './styles.sass';

interface Props {
    filter?: FilterPart;
    accessor: string;
    onChange: FilterChangeHandler;
}

const CheckFilter: FunctionComponent<Props> = props => {
    const {filter, accessor, onChange} = props;

    const inputOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const {currentTarget: {checked}} = event;
        onChange(accessor, 'equals', checked ? ['true'] : []);
    };

    const getValue = () => {
        if (!filter || !filter.values) {
            return false;
        }

        return Boolean(filter.values[0]);
    };

    return <Label
        htmlFor={accessor}
        className={styles.checkFilter}
    >
        <input
            id={accessor}
            type="checkbox"
            className="form-check-input"
            onChange={inputOnChange}
            checked={getValue()}
        />

        <div className={`form-check-label ${styles['custom-checkbox-label']}`} />
    </Label>;
};

export default CheckFilter;
