import * as Sentry from '@sentry/browser';

import {Config} from 'app/Config';

class Logger {
    isDev: boolean;

    constructor(isDev: boolean) {
        this.isDev = isDev;
        if (!isDev) {
            Sentry.init({dsn: 'https://f7f0a9e3bc0c4419b078772ad2892cf4@o413656.ingest.sentry.io/5300656'});
        }
    }

    logError(error: Error) {
        if (this.isDev) {
            // eslint-disable-next-line no-console
            console.error('App error: ', error.message);
        } else {
            Sentry.captureException(error);
        }
    }
}

export default new Logger(Config.isDevelopment || Config.isTest);
