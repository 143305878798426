import {ErrorsFromBE} from 'favorlogic-utils';

import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {OperatingProcedureFormValues} from './types/OperatingProcedureFormValues';

export interface LoadOperatingProceduresAction {
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES';
}

export const loadOperatingProcedures = (): LoadOperatingProceduresAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES',
});

export interface LoadOperatingProceduresSuccessAction {
    payload: OperatingProcedure[];
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES_SUCCESS';
}

export const loadOperatingProceduresSuccess = (
    payload: OperatingProcedure[]
): LoadOperatingProceduresSuccessAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES_SUCCESS',
    payload,
});

export interface LoadOperatingProceduresErrorAction {
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES_ERROR';
    payload: ErrorsFromBE;
}

export const loadOperatingProceduresError = (err: ErrorsFromBE): LoadOperatingProceduresErrorAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURES_ERROR',
    payload: err,
});

// ---
export interface LoadOperatingProcedureAction {
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE';
    payload: number;
}

export const loadOperatingProcedure = (id: number): LoadOperatingProcedureAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE',
    payload: id,
});

export interface LoadOperatingProcedureSuccessAction {
    payload: OperatingProcedure;
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE_SUCCESS';
}

export const loadOperatingProcedureSuccess = (payload: OperatingProcedure): LoadOperatingProcedureSuccessAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE_SUCCESS',
    payload,
});

export interface LoadOperatingProcedureErrorAction {
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE_ERROR';
    payload: ErrorsFromBE;
}

export const loadOperatingProcedureError = (error: ErrorsFromBE): LoadOperatingProcedureErrorAction => ({
    type: 'operatingProcedure/LOAD_OPERATING_PROCEDURE_ERROR',
    payload: error,
});

// ---

export interface CreateOperatingProcedureAction {
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE';
}

export const createOperatingProcedure = (): CreateOperatingProcedureAction => ({
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE',
});

export interface CreateOperatingProcedureSuccessAction {
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE_SUCCESS';
}

export const createOperatingProcedureSuccess = (): CreateOperatingProcedureSuccessAction => ({
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE_SUCCESS',
});

export interface CreateOperatingProcedureErrorAction {
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE_ERROR';
    payload: ErrorsFromBE;
}

export const createOperatingProcedureError = (err: ErrorsFromBE): CreateOperatingProcedureErrorAction => ({
    type: 'operatingProcedure/CREATE_OPERATING_PROCEDURE_ERROR',
    payload: err,
});

// ---

interface UpdateOperatingProcedureActionArgs {
    id: number;
}

export interface UpdateOperatingProcedureAction {
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE';
    payload: UpdateOperatingProcedureActionArgs;
}

export const updateOperatingProcedure = (
    payload: UpdateOperatingProcedureActionArgs
): UpdateOperatingProcedureAction => ({
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE',
    payload,
});

export interface UpdateOperatingProcedureSuccessAction {
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE_SUCCESS';
}

export const updateOperatingProcedureSuccess = (): UpdateOperatingProcedureSuccessAction => ({
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE_SUCCESS',
});

export interface UpdateOperatingProcedureErrorAction {
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE_ERROR';
    payload: ErrorsFromBE;
}

export const updateOperatingProcedureError = (err: ErrorsFromBE): UpdateOperatingProcedureErrorAction => ({
    type: 'operatingProcedure/UPDATE_OPERATING_PROCEDURE_ERROR',
    payload: err,
});

// ---

export interface PrefillOperatingProcedureFormAction {
    type: 'operatingProcedure/PREFILL_OPERATING_PROCEDURE_FORM';
    payload: OperatingProcedureFormValues;
}

export const prefillOperatingProcedureForm = (
    payload: OperatingProcedureFormValues
): PrefillOperatingProcedureFormAction => ({
    type: 'operatingProcedure/PREFILL_OPERATING_PROCEDURE_FORM',
    payload,
});

// ---

export type OperatingProcedureAction =
    LoadOperatingProceduresAction | LoadOperatingProceduresSuccessAction | LoadOperatingProceduresErrorAction |
    LoadOperatingProcedureAction | LoadOperatingProcedureSuccessAction | LoadOperatingProcedureErrorAction |
    CreateOperatingProcedureAction | CreateOperatingProcedureSuccessAction | CreateOperatingProcedureErrorAction |
    UpdateOperatingProcedureAction | UpdateOperatingProcedureSuccessAction | UpdateOperatingProcedureErrorAction |
    PrefillOperatingProcedureFormAction
    ;
