import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {PageableSchema} from './Pageable';
import {SortSchema} from './Sort';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    pageable: PageableSchema,
    totalPages: IntegerSchema,
    totalElements: IntegerSchema,
    last: t.boolean,
    number: IntegerSchema,
    size: IntegerSchema,
    sort: SortSchema,
    numberOfElements: IntegerSchema,
    first: t.boolean,
    empty: t.boolean,
});

export const PageSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Page extends t.TypeOf<typeof PageSchema> {}
