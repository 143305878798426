import {flow, isEmpty, some} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode, Fragment} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {opt} from 'ts-opt';
import {classNames, parseSearch} from 'favorlogic-utils';

import {loadAnalyses} from 'analysis/actions';
import {downloadFileAction, DownloadFileActionArguments} from 'download/actions';
import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {
    deleteSample,
    DeleteSamplePayload,
    disableSample,
    loadSamplesByOrder,
    resetSamplesByOrder,
} from 'sample/actions';
import {loadMilkRooms} from 'supplier/actions';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {ProtocolDetails} from 'types/model/protocols/ProtocolDetails';
import withUser, {WithUserProps} from 'user/components/withUser';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {loadCustomerList, loadCustomerMilkRooms} from 'user/actions';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {Analysis} from 'types/model/analyses/Analysis';
import {Modal} from 'layout/components/Modal';
import {setModalVisibility} from 'layout/actions';
import DisableSampleForm from 'sample/components/DisableSampleForm';
import {DisableSampleFormValues} from 'supplier/types/DisableSampleFormValues';
import {SamplesByOrderParams} from 'sample/types/SamplesByOrderParams';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {isModalVisibleSelector} from 'layout/reducer';
import {withFilter, withSort} from 'tables/components';
import {SortProps} from 'tables/components/withSort';
import {DisableSampleParams} from 'sample/types/DisableSampleParams';
import {LoaderWrapper} from 'layout/components';
import {Customer} from 'types/model/customers/Customer';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';
import {EditAction} from 'layout/components/ItemPageHeader';
import {resetF} from 'utils/formHelpers';

import {
    approveProtocol,
    loadOrder,
    loadProtocols,
    setSampleIdForDisable,
    updateOrder,
    resetSampleIdForDisable,
    cancelOrder,
    downloadInvoice,
    deleteProtocol,
} from '../actions';
import OrderDetailsView from '../components/OrderDetailsView';
import SamplesTable, {definedFilters} from '../components/SamplesTable';
import UpdateOrderForm from '../components/UpdateOrderForm';
import ProtocolsTable from '../components/ProtocolsTable';
import {isOrderDetailsForLaborer, canGenerateProtocol} from '../utils/orderUtils';
import {getCustomerNamesOptions} from '../utils/selectOptions';

interface OuterProps {}

interface StateProps {
    order: OrderDetails | null;
    sampleIdForDisable: number | null;
    customerMilkRooms: MilkRoom[] | null;
    milkRooms: MilkRoom[];
    analyses: Analysis[];
    protocols: ProtocolDetails[];
    customers: Customer[] | null;
    sampleDisableModalVisible: boolean;
    orderSamples: OrderSampleView[] | null;
    approveInProgress: boolean;
}

interface DispatchProps {
    handleLoadAnalyses(): void;
    handleLoadOrder(id: number): void;
    handleUpdateOrder(id: number): void;
    handleDeleteSample(barcode: string, payload: DeleteSamplePayload): void;
    handleCancelOrder(id: number): void;
    handleDownload(args: DownloadFileActionArguments<undefined>): void;
    handleLoadCustomerMilkRooms(customerId: number): void;
    handleLoadMilkRooms(): void;
    handleLoadProtocols(orderId: number): void;
    handleProtocolApprove(orderId: number, isEdit: boolean): void;
    loadCustomers(): void;
    handleSetSampleIdForDisable(sampleId: number): void;
    handleResetSampleIdForDisable(): void;
    handleDisableSample(params: DisableSampleParams): void;
    handleSetSampleDisableModalVisibility(visibility: boolean): void;
    handleLoadSamplesByOrder(orderId: number, params: SamplesByOrderParams): void;
    handleResetSamplesOrder(): void;
    downloadInvoice(orderId: number): void;
    handleResetDisableSampleForm(): void;
    handleProtocolDelete(protocolId: number, orderId: number): void;
}

type UserProps = Pick<WithUserProps, 'isRoleDairy' | 'isRoleLaborer' | 'isRoleAdmin' | 'isRoleDairyEmployee'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type RouteProps = RouteComponentProps<{id: string}>;
type Props = OuterProps & StateProps & DispatchProps & UserProps & ListProps & RouteProps;

class Detail extends Component<Props> {
    private get orderId(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    private get isOrderNew(): boolean {
        const {order} = this.props;

        return opt(order).map(o => o.state === 'NEW').orFalse();
    }

    private get isOrderCanceled(): boolean {
        const {order} = this.props;

        return opt(order).map(o => o.state === 'CANCELED').orFalse();
    }

    private get isOrderCompleted(): boolean {
        const {order} = this.props;

        return opt(order).map(o => o.state === 'COMPLETED').orFalse();
    }

    private get milkRooms(): MilkRoom[] | null {
        const {milkRooms, customerMilkRooms} = this.props;

        return this.isAdminOrLaborer ? customerMilkRooms : milkRooms;
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    private get isDairy(): boolean {
        const {isRoleDairy, isRoleDairyEmployee} = this.props;

        return isRoleDairy || isRoleDairyEmployee;
    }

    private get isEdit(): boolean {
        const {match} = this.props;

        return match.url.split('/').pop() === 'edit';
    }

    private get canEdit(): boolean {
        return this.isAdminOrLaborer || this.isOrderNew;
    }

    private get canGenerateProtocol(): boolean {
        const {order} = this.props;

        return canGenerateProtocol(order);
    }

    private get hasSamplesFromMilkroom(): boolean {
        const {orderSamples} = this.props;

        return some<OrderSampleView>(sample => sample.milkRoomId !== undefined)(orderSamples);
    }

    componentDidMount(): void {
        const {
            handleLoadOrder,
            handleLoadMilkRooms,
            handleLoadAnalyses,
            analyses,
            handleLoadProtocols,
            loadCustomers,
            isRoleDairyEmployee,
            location: {search},
        } = this.props;
        if (this.isAdminOrLaborer) {
            loadCustomers();
            this.loadMilkRoomsByCustomer();
        } else {
            handleLoadMilkRooms();
        }

        if (isEmpty(analyses)) {
            handleLoadAnalyses();
        }

        handleLoadOrder(this.orderId);
        if (search) { this.loadOrderSamples(search); }

        if (!isRoleDairyEmployee) {
            handleLoadProtocols(this.orderId);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}, filter: prevFilter} = prevProps;
        const {location: {search}, filter, handleResetSamplesOrder} = this.props;

        if (prevFilter !== filter && isEmpty(filter)) {
            handleResetSamplesOrder();
            this.loadOrderSamples(search);
        } else if (prevSearch !== search) {
            this.loadOrderSamples(search);
        }
    }

    render(): ReactNode {
        const {
            isRoleAdmin,
            order,
            protocols,
            analyses,
            sampleDisableModalVisible,
            orderSamples,
            sortDir,
            sortBy,
            filter,
            handleSortChange,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            approveInProgress,
        } = this.props;

        return (
            <Fragment>
                <Modal.Blurer>
                    <Layout.Page fluid>
                        <div className="container-lg px-0 d-flex flex-column">
                            <LoaderWrapper showLoader={!order}>
                                {this.renderHeader()}
                                {this.renderOrderDetails(order)}
                                {this.renderActions(order)}
                            </LoaderWrapper>
                        </div>
                        <h3 className="mt-4 d-flex justify-content-between">
                            Vzorky
                            {order && this.canEdit && !this.isOrderCanceled &&
                                <Buttons.RightIconButton
                                    label="Přidat vzorek"
                                    to={`/orders/${order.id}/newSample`}
                                    disabled={!this.isOrderNew}
                                    title={!this.isOrderNew
                                        ? 'Vzorky je možné přidat pouze do nových objednávek.'
                                        : undefined
                                    }
                                    icon="ADD"
                                />}
                        </h3>
                        <div className="mt-2">
                            <SamplesTable
                                samples={orderSamples}
                                orderId={this.orderId}
                                mode={this.isEdit ? 'EDIT' : 'DETAIL'}
                                handleDelete={this.handleDeleteSampleInDetail}
                                handleDisable={this.handleOpenDisableSampleModal}
                                milkRooms={this.milkRooms}
                                isRoleAdminOrLaborer={this.isAdminOrLaborer}
                                analyses={analyses}
                                showSupplyChainCode={this.isDairy}
                                showNote
                                sortDir={sortDir}
                                sortBy={sortBy}
                                sortable
                                filter={filter}
                                handleSortChange={handleSortChange}
                                handleFilterChange={handleFilterChange}
                                handleFilterClear={handleFilterClear}
                                handleFilterSubmit={handleFilterSubmit}
                                showDisabled={!this.isOrderNew && !this.isOrderCompleted}
                                canEdit={this.canEdit}
                                disableActions={this.isOrderCanceled}
                            />
                        </div>

                        {order && !isEmpty(protocols) && <Fragment>
                            <h3 className="mt-4 d-flex justify-content-between">
                                Protokoly
                                {this.renderDownloadTxtProcolButton(order)}
                            </h3>
                            <div className="mt-2">
                                <ProtocolsTable
                                    protocols={protocols}
                                    handleDownload={this.handlePdfProtocolDownload}
                                    handleApprove={this.handleProtocolApprove}
                                    handleDelete={this.handleProtocolDelete}
                                    canApprove={isRoleAdmin && !this.isOrderCanceled}
                                    canDelete={isRoleAdmin  && !this.isOrderCompleted}
                                    approveInProgress={approveInProgress}
                                />
                            </div>
                        </Fragment>}

                        <div className="d-flex justify-content-between mt-4">
                            <div className="text-left">
                                {this.canGenerateProtocol && <Buttons.RightIconButton
                                    importance="secondary"
                                    label="Nový předběžný protokol"
                                    to={`/orders/${this.orderId}/generate-protocol`}
                                    icon="ADD"
                                />}
                            </div>
                            <div className="text-right">
                                {this.isAdminOrLaborer && this.isOrderNew && <Buttons.RightIconButton
                                    label="Příjem vzorků"
                                    to={`/orders/${this.orderId}/acceptSamples`}
                                    icon="NEXT"
                                />}
                            </div>
                        </div>
                    </Layout.Page>
                </Modal.Blurer>
                <Modal.Container
                    show={sampleDisableModalVisible}
                    onClickAway={this.handleSampleDisableModalClickAway}
                >
                    <DisableSampleForm
                        onSubmit={this.handleSubmitDisableSampleForm}
                    />
                </Modal.Container>
            </Fragment>
        );
    }

    private loadOrderSamples(search: string): void {
        const {handleLoadSamplesByOrder} = this.props;

        handleLoadSamplesByOrder(this.orderId, parseSearch(search));
    }

    private handleDeleteSampleInDetail = (id: number) => {
        const {order, orderSamples, handleDeleteSample} = this.props;

        if (!order) {
            return;
        }

        const sample = opt(orderSamples).orCrash('Order samples not set')
            .find(s => s.sampleId === id);

        if (!sample) {
            throw new Error(`Sample with id ${id} not found`);
        }

        handleDeleteSample(sample.barcode, {sampleId: id, orderId: order.id});
    };

    private handleProtocolApprove = () => {
        const {order, handleProtocolApprove} = this.props;

        if (!order) {
            return;
        }

        handleProtocolApprove(order.id, this.isEdit);
    };

    private handleProtocolDelete = (protocol: ProtocolDetails) => {
        const {order, handleProtocolDelete} = this.props;

        if (!order) {
            return;
        }

        handleProtocolDelete(protocol.id, order.id);
    };

    private handlePdfProtocolDownload = (p: ProtocolDetails) => {
        const {handleDownload} = this.props;

        handleDownload({url: `/protocols/${p.id}`});
    };

    private getEditAction(): EditAction | undefined {
        const {order} = this.props;

        if (!order || this.isEdit || !this.isAdminOrLaborer) {
            return;
        }

        return {
            to: `/orders/${this.orderId}/edit`,
            disabled: this.isOrderCanceled,
            disabledTooltip: 'Objednávka již byla zrušena a nelze ji upravit.',
        };
    }

    private renderTitle = (): ReactNode =>
        <span className={classNames(this.isOrderCanceled && 'text-black-50')}>
            Objednávka #{this.orderId}
        </span>

    private renderHeader = (): ReactNode =>
        <Layout.ItemPageHeader
            title={this.renderTitle()}
            backLabel={this.isEdit ? 'Zpět' : 'Objednávky'}
            editAction={this.getEditAction()}
        />

    private renderActions = (order: OrderDetails | null): ReactNode => {
        const {isRoleAdmin, handleCancelOrder, downloadInvoice} = this.props;

        return !this.isEdit && order && isRoleAdmin &&
            <div className="w-100">
                <Buttons.Group>
                    {order.hasInvoice && <Buttons.RightIconButton
                        label="Stáhnout fakturu"
                        onClick={() => downloadInvoice(order.id)}
                        icon="DOWNLOAD"
                    />}
                    {!this.isOrderCanceled && <Buttons.Button
                        type="button"
                        importance="secondary"
                        onClick={() => handleCancelOrder(order.id)}
                    >
                        Zrušit objednávku
                    </Buttons.Button>}
                </Buttons.Group>
            </div>;
    };

    private renderOrderDetails = (order: OrderDetails | null): ReactNode => {
        const {handleUpdateOrder, customers, orderSamples} = this.props;

        const handleSubmit = () => {
            handleUpdateOrder(this.orderId);
        };

        const allowChangeState = this.isAdminOrLaborer && this.isOrderCompleted;

        return this.isEdit
            ? <UpdateOrderForm
                onSubmit={handleSubmit}
                customerOptions={customers ? getCustomerNamesOptions(customers) : null}
                customers={customers}
                isAdminOrLaborer={this.isAdminOrLaborer}
                hasSamplesFromMilkroom={this.hasSamplesFromMilkroom}
                states={
                    allowChangeState
                        ? ['COMPLETED', 'IN_PROGRESS']
                        : opt(order).map(o => [o.state]).orElse([])
                }
                allowChangeState={allowChangeState}
            />
            : <OrderDetailsView
                order={order}
                samples={orderSamples ? orderSamples : []}
            />
        ;
    };

    private readonly handleSampleDisableModalClickAway = (): void => {
        const {
            handleResetSampleIdForDisable,
            handleSetSampleDisableModalVisibility,
            handleResetDisableSampleForm,
        } = this.props;
        handleResetDisableSampleForm();
        handleResetSampleIdForDisable();
        handleSetSampleDisableModalVisibility(false);
    }

    private handleSubmitDisableSampleForm = (values: DisableSampleFormValues) => {
        const {handleDisableSample, sampleIdForDisable} = this.props;
        const {location: {search}} = this.props;
        const params = parseSearch(search);

        if (sampleIdForDisable === null) {
            throw new Error('SampleId not found');
        }

        handleDisableSample({
            sampleId: sampleIdForDisable,
            note: values.note,
            orderId: this.orderId,
            orderSamplesParams: params,
        });
    };

    private loadMilkRoomsByCustomer(): void {
        const {order, handleLoadCustomerMilkRooms} = this.props;

        if (order && isOrderDetailsForLaborer(order)) {
            handleLoadCustomerMilkRooms(order.customerDetails.id);
        }
    }

    private renderDownloadTxtProcolButton(order: OrderDetails): ReactNode {
        const {handleDownload, isRoleDairy} = this.props;
        const handleTxtProtocolDownload = () => {
            handleDownload({url: `/orders/${order.id}/protocols/txt`});
        };

        return isRoleDairy && <Buttons.RightIconButton
            label="Stáhnout TXT"
            importance="primary"
            icon="DOWNLOAD"
            onClick={handleTxtProtocolDownload}
        />;
    }

    private handleOpenDisableSampleModal = (sampleId: number) => {
        const {handleSetSampleIdForDisable, handleSetSampleDisableModalVisibility} = this.props;
        handleSetSampleIdForDisable(sampleId);
        handleSetSampleDisableModalVisibility(true);
    };
}

const mapStateToProps = (state: StoreState): StateProps => ({
    order: state.order.current,
    orderSamples: state.sample.orderSamples,
    sampleIdForDisable: state.order.sampleIdForDisable,
    milkRooms: state.supplier.milkRooms || [],
    customerMilkRooms: state.user.customerMilkRooms.orNull(),
    analyses: state.analysis.analyses || [],
    protocols: state.order.protocols || [],
    customers: state.user.customerList.orNull(),
    sampleDisableModalVisible: isModalVisibleSelector(state.layout, 'sampleDisable'),
    approveInProgress: state.order.approveInProgress,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleLoadOrder: (id: number) => dispatch(loadOrder(id)),
    handleLoadSamplesByOrder: (orderId: number, params: SamplesByOrderParams) =>
        dispatch(loadSamplesByOrder(orderId, params)),
    handleResetSamplesOrder: () => dispatch(resetSamplesByOrder()),
    handleUpdateOrder: (id: number) => dispatch(updateOrder(id)),
    handleDeleteSample: (barcode: string, {sampleId, orderId}) => dispatch(showConfirmDialog({
        title: 'Smazat vzorek',
        text: `Opravdu chcete smazat vzorek ${barcode}?`,
        confirmAction: deleteSample({sampleId, orderId}),
    })),
    handleDownload: (args: DownloadFileActionArguments<undefined>) => dispatch(downloadFileAction(args)),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
    handleLoadCustomerMilkRooms: (customerId: number) => dispatch(loadCustomerMilkRooms(customerId)),
    handleLoadProtocols: (orderId: number) => dispatch(loadProtocols(orderId)),
    handleProtocolApprove: (orderId: number, isEdit: boolean) =>
        dispatch(approveProtocol(orderId, isEdit)),
    handleSetSampleIdForDisable: (sampleId: number) => dispatch(setSampleIdForDisable(sampleId)),
    handleResetSampleIdForDisable: () => dispatch(resetSampleIdForDisable()),
    handleDisableSample: (params: DisableSampleParams) => dispatch(disableSample(params)),
    loadCustomers: () => dispatch(loadCustomerList()),
    handleSetSampleDisableModalVisibility: (visibility: boolean) =>
        dispatch(setModalVisibility('sampleDisable', visibility)),
    handleResetDisableSampleForm: () => dispatch(resetF('disableSampleForm')),
    handleCancelOrder: (id: number) => dispatch(showConfirmDialog({
        title: 'Zrušit objednávku',
        text: `Opravdu chcete zrušit objednávku ${id}?`,
        confirmAction: cancelOrder(id),
    })),
    downloadInvoice: (orderId: number) => dispatch(downloadInvoice(orderId)),
    handleProtocolDelete: (protocolId: number, orderId: number) =>
        dispatch(deleteProtocol(protocolId, orderId)),
});

export default flow([
    withUser,
    withSort({sortBy: 'sampleOrder', sortDir: 'asc'}),
    withFilter(definedFilters),
    connect(mapStateToProps, mapDispatchToProps),
])(Detail) as ComponentClass<OuterProps>;
