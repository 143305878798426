import {isEmpty} from 'lodash/fp';

import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {OrderCustomerFormValues} from 'order/types/OrderCustomerFormValues';

type Values = OrderCustomerFormValues;

export const validate = (values: Values): Errors<Values> => {
    if (isEmpty(values)) {
        return {};
    }

    const validator = new DairyValidator(values);

    validator.orderCustomerDetails('customerDetails');

    return validator.generateErrorsObject();
};
