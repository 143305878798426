import {opt} from 'ts-opt';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {CreateSample} from 'types/model/samples/CreateSample';

export const prepareCreateSampleFormValues = (sample: SampleDetails | CreateSample): CreateSampleFormValues => ({
    barcode: sample.barcode,
    analysisIds: sample.analysisIds,
    samplingTime: opt(sample.samplingTime).orElse(''),
    type: sample.type,
    harvestType: sample.harvestType,
    sampleSourceAmount: opt(sample.sampleSourceAmount).map(x => x.toString()).orElse(''),
    samplingTemperature: opt(sample.samplingTemperature).map(x => x.toString()).orElse(''),
    samplingDate: sample.samplingDate,
    supplyChainId: 'supplyChainId' in sample ? sample.supplyChainId ?? null : null,
    supplyChainCode: 'supplyChainCode' in sample ? sample.supplyChainCode ?? null : null,
    milkRoomId: sample.milkRoomId ?? null,
    showResultsOnWeb: sample.showResultsOnWeb,
    showResultsToBreeders: sample.showResultsToBreeders,
    customerNote: sample.customerNote ?? null,
    qualitative: sample.qualitative,
    cistern: sample.cistern,
    control: sample.control,
    subsidy: sample.subsidy,
    traitNote: sample.traitNote ?? null,
});
