import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {CancelOrderAction, loadOrder} from '../actions';
import Api from '../api';

function* execute(action: CancelOrderAction): SagaIterator {
    const title = 'Zrušení objednávky';
    const orderId = action.payload;
    const response = yield* call(Api.cancelOrder, orderId);

    if (!response.isSuccess) {
        return yield* call(handleResponseError, response, title);
    }

    yield* put(showSuccess(title, 'Objednávka úspěšně zrušena.'));
    yield* put(loadOrder(orderId));
    yield* put(routerActions.goBack());
}

export function* cancelOrderSaga(): SagaIterator {
    yield takeLatestF('order/CANCEL_ORDER', execute);
}
