import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {SortSchema} from './Sort';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    sort: SortSchema,
    offset: IntegerSchema,
    pageNumber: IntegerSchema,
    pageSize: IntegerSchema,
    unpaged: t.boolean,
    paged: t.boolean,
});

export const PageableSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Pageable extends t.TypeOf<typeof PageableSchema> {}
