import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/samples"
        component={Screens.List}
        key="samples"
    />,
    <Route
        exact
        path="/samples/updateSamples"
        component={Screens.UpdateSamples}
        key="updateSamples"
    />,
    <Route
        exact
        path="/samples/:id"
        component={Screens.Detail}
        key="sampleDetail"
    />,
];

export default routes;
