import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    deleteMeasurementSuccess, deleteMeasurementError, DeleteMeasurementAction,
} from '../actions';
import Api from '../api';

const title = 'Smazání měření';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(deleteMeasurementError(response.data as ErrorsFromBE));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Měření bylo úspěšně smazáno'));
    yield* put(deleteMeasurementSuccess());
    yield* put(routerActions.goBack());
}

function* execute(action: DeleteMeasurementAction): SagaIterator {
    const {measurementId} = action.payload;
    const response = yield* call(Api.deleteMeasurement, measurementId);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* deleteMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/DELETE_MEASUREMENT', execute);
}
