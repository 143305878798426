import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {downloadFileFromResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ExportTranscriptSubsetAction} from '../actions';
import Api from '../api';

function* execute({payload: {orderIds}}: ExportTranscriptSubsetAction): SagaIterator {
    const response = yield* call(Api.exportTranscriptSubset, orderIds);

    yield* response.isSuccess
        ? call(downloadFileFromResponse, response)
        : call(handleResponseError, response, 'Stažení opisů');
}

export function* exportTranscriptSubsetSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_TRANSCRIPT_SUBSET', execute);
}
