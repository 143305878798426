import {UpdateSamplesFormValues} from 'sample/types/UpdateSamplesFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

export const validate = (values: UpdateSamplesFormValues): Errors<UpdateSamplesFormValues> => {
    const validator = new DairyValidator(values);

    validator.oneIsFilled(['samplingDate', 'samplingTime'], 'Datum a Čas odběru');

    return validator.generateErrorsObject();
};
