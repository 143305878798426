exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-layout-components-Loader-___styles__loaderWrapper___2-NVP {\n  width: calc(100% + 2em);\n  height: calc(100% + 2em);\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n  background: rgba(0, 0, 0, 0.1);\n  margin: -1em; }\n  .src-modules-layout-components-Loader-___styles__loaderWrapper___2-NVP .src-modules-layout-components-Loader-___styles__loader___2bpOP {\n    border: 16px solid #DCEDC8;\n    border-top: 16px solid #689F38;\n    border-radius: 50%;\n    width: 120px;\n    height: 120px;\n    animation: src-modules-layout-components-Loader-___styles__spin___2NCB2 2s linear infinite; }\n  .src-modules-layout-components-Loader-___styles__loaderWrapper___2-NVP.src-modules-layout-components-Loader-___styles__inModal___1kLxm {\n    width: calc(100% + 3rem);\n    height: calc(100% + 3rem);\n    margin: -1.5rem; }\n\n@keyframes src-modules-layout-components-Loader-___styles__spin___2NCB2 {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n", ""]);

// exports
exports.locals = {
	"loaderWrapper": "src-modules-layout-components-Loader-___styles__loaderWrapper___2-NVP",
	"loader": "src-modules-layout-components-Loader-___styles__loader___2bpOP",
	"spin": "src-modules-layout-components-Loader-___styles__spin___2NCB2",
	"inModal": "src-modules-layout-components-Loader-___styles__inModal___1kLxm"
};