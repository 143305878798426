import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {PriceClassSchema} from '../enumeration/PriceClass';
import {UpdateCustomerBaseSchema} from './UpdateCustomerBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('CustomerDetailsForCustomer'),
    id: IntegerSchema,
    priceClass: PriceClassSchema,
});

export const CustomerDetailsForCustomerSchema = t.exact(t.intersection([
    UpdateCustomerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface CustomerDetailsForCustomer extends t.TypeOf<typeof CustomerDetailsForCustomerSchema> {}
