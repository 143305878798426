import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import UserApi from 'user/api';

import {LoadCustomerForOrderAction, loadCustomerForOrderError, loadCustomerForOrderSuccess} from '../actions';

const title = 'Načtení zákazníka';

function* execute({payload: {customerId}}: LoadCustomerForOrderAction): SagaIterator {
    const response = yield* call(UserApi.getCustomerForLaborer, customerId);

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        return yield* put(loadCustomerForOrderError(response.data as ErrorsFromBE));
    }

    yield* put(loadCustomerForOrderSuccess(response.data));
}

export function* loadCustomerForOrderSaga(): SagaIterator {
    yield takeLatestF('order/LOAD_CUSTOMER_FOR_ORDER', execute);
}
