import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Icons} from 'icons';
import {completeWizard, nextStep, setStep, skipStep} from 'wizard/actions';

import styles from './styles.sass';

import nextSvg from './next.svg';

interface OuterProps {
    children: ReactNode;
    isValid?: boolean;
    lastStepButtonLabel?: string;
    showSkipButton?: boolean;
    showBackButton?: boolean;
    disableCompleteButton?: boolean;

    onNextClick?(): void;

    onSkipButtonClick?(): void;
}

interface InnerProps {
    currentStep: number;
    step: number;
    stepsSize: number;
    skippedSteps: number[];

    handleSubmit(): void;

    handleSkip(): void;

    handleBack(): void;
}

type Props = InnerProps & OuterProps;

class Step extends Component<Props> {
    renderButtonLabel(): ReactNode {
        const {step, stepsSize, lastStepButtonLabel} = this.props;
        if (step === stepsSize) {
            return lastStepButtonLabel ? lastStepButtonLabel : 'Dokončit';
        } else {
            return <span>Další <Icons.SvgIcon icon={nextSvg} /></span>;
        }
    }

    render(): ReactNode {
        const {
            currentStep,
            children,
            step,
            handleSubmit,
            isValid,
            handleSkip,
            handleBack,
            showSkipButton,
            showBackButton,
            disableCompleteButton,
            stepsSize,
            skippedSteps,
        } = this.props;

        const disableSkipButton = !(skippedSteps.length < stepsSize - 1);

        if (step === currentStep) {
            return <div className={styles.step}>
                {children}
                <div className={`d-flex ${showBackButton ? 'justify-content-between' : 'float-right'}`}>
                    {showBackButton && <Buttons.Button
                        className="justify-self-start ml-3"
                        importance="primary"
                        type="submit"
                        onClick={() => handleBack()}
                        disabled={step <= 1}
                    >Zpět</Buttons.Button>}
                    <div className="align-content-end">
                        {showSkipButton && <Buttons.Button
                            className="mr-3"
                            importance="secondary"
                            type="submit"
                            onClick={() => handleSkip()}
                            disabled={disableSkipButton}
                        >Přeskočit</Buttons.Button>}
                        <Buttons.Button
                            className="mr-3"
                            importance="primary"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={disableCompleteButton || !isValid}
                        >{this.renderButtonLabel()}</Buttons.Button>
                    </div>
                </div>
            </div>;
        }
        return null;
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    currentStep: state.wizard.currentStep,
    stepsSize: state.wizard.stepsSize,
    skippedSteps: state.wizard.skippedSteps,
});
const mapDispatchToProps = (dispatch: Dispatch, props: Props): Partial<Props> => ({
    handleSubmit: () => {
        const {onNextClick, stepsSize, step} = props;
        if (onNextClick) {
            onNextClick();
        }
        if (step === stepsSize) {
            dispatch(completeWizard());
        } else {
            dispatch(nextStep());
        }
    },
    handleBack: () => {
        const {step} = props;
        dispatch(setStep(step - 1));
    },
    handleSkip: () => {
        const {step, onSkipButtonClick} = props;
        if (onSkipButtonClick) {
            onSkipButtonClick();
        }
        dispatch(skipStep(step));
        dispatch(setStep(step + 1));
    },
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Step) as ComponentClass<OuterProps>;
