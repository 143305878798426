import React, {SyntheticEvent, KeyboardEvent, FunctionComponent} from 'react';

import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {FilterPart} from '../../types/FilterPart';
import {FilterInputType} from '../../types/Column';

import styles from './styles.sass';

interface Props {
    filter?: FilterPart;
    accessor: string;
    onChange: FilterChangeHandler;
    inputType?: FilterInputType;
    placeholder?: string;
    onSubmit(): void;
}

const isValidValue = (value: string, type?: FilterInputType): boolean => {
    if (type === 'integer') {
        return Number.isInteger(Number(value));
    }

    return true;
};

const SimpleFilter: FunctionComponent<Props> = props => {
    const {filter, accessor, onChange, placeholder, onSubmit, inputType} = props;

    const inputOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const {currentTarget: {value}} = event;

        if (isValidValue(value, inputType)) {
            onChange(accessor, 'equals', value ? [value] : []);
        }
    };

    const inputOnKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    const getValue = () => {
        if (!filter || !filter.values) {
            return '';
        }

        return filter.values[0] || '';
    };

    return <input
        className={`form-control ${styles.input}`}
        value={getValue()}
        placeholder={placeholder}
        onChange={inputOnChange}
        onKeyPress={inputOnKeypress}
    />;
};

export default SimpleFilter;
