import {flow} from 'lodash/fp';
import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {none, opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {formValuesF, isFieldValidUnsafe} from 'utils/formHelpers';
import {Components as Layout} from 'layout';

import {genHasSameBillingAddress} from '../utils/genHasSameBillingAddress';
import {prefillUpdateCustomerForm, update, fillUpdateCustomerFormFromAres} from '../actions';
import UpdateForm from '../components/UpdateForm';
import withUser, {WithUserProps} from '../components/withUser';
import {UpdateCustomerFormValues} from '../types/UpdateCustomerFormValues';

interface OuterProps {}

interface InnerProps {
    formValue?: UpdateCustomerFormValues;
    isIcoValid: boolean;
    isAresLoading: boolean;

    handlePrefillUpdateCustomerForm(value: UpdateCustomerFormValues): void;
    handleUpdate(): void;
    fillFormFromAres(ico: string): void;
}

type UserProps = Pick<WithUserProps, 'isRoleDairy' | 'currentUser' | 'currentCustomer'>;
type Props = InnerProps & OuterProps & UserProps;

class Update extends React.Component<Props> {
    componentDidMount(): void {
        const {currentCustomer} = this.props;
        if (!currentCustomer.isEmpty) { this.handlePrefill(); }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {currentCustomer} = this.props;
        if (prevProps.currentCustomer.isEmpty && !currentCustomer.isEmpty) {
            this.handlePrefill();
        }
    }

    render(): ReactNode {
        const {
            formValue,
            handleUpdate,
            fillFormFromAres,
            isIcoValid,
            isRoleDairy,
            isAresLoading,
        } = this.props;
        const ico = opt(formValue).map(x => x.invoiceDetails.ico).orUndef();
        const hasNoIco = opt(formValue).map(x => x.hasNoIco).orFalse();
        const onIcoSearch = () => {
            if (ico && isIcoValid) {
                fillFormFromAres(ico);
            }
        };

        return (
            <Layout.Page>
                <h1 className="text-center">Uživatelský účet</h1>
                <UpdateForm
                    onSubmit={handleUpdate}
                    hasSameBillingAddress={formValue ? formValue.hasSameBillingAddress : false}
                    isDairy={isRoleDairy}
                    isAdminOrLaborer={false}
                    hasNoIco={hasNoIco}
                    onIcoSearch={onIcoSearch}
                    isAresLoading={isAresLoading}
                    formValues={formValue}
                />
            </Layout.Page>
        );
    }

    private handlePrefill(): void {
        const {
            currentCustomer,
            handlePrefillUpdateCustomerForm,
        } = this.props;
        const cc = currentCustomer.orCrash('currentCustomer is missing');
        handlePrefillUpdateCustomerForm({
            firstName: cc.firstName,
            lastName: cc.lastName,
            invoiceDetails: cc.invoiceDetails,
            address: cc.address,
            billingAddress: cc.billingAddress,
            hasSameBillingAddress: genHasSameBillingAddress(cc),
            showResultsOnWeb: cc.showResultsOnWeb,
            showResultsToBreeders: cc.showResultsToBreeders,
            name: cc.name,
            priceClass: cc.priceClass,
            hasNoIco: !cc.invoiceDetails.ico,
        });
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    formValue: formValuesF('updateCustomer')(state).orUndef(),
    isIcoValid: isFieldValidUnsafe('updateCustomer', 'invoiceDetails.ico')(state),
    isAresLoading: state.user.isAresLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleUpdate: () => dispatch(update({byLaborer: false, customerId: none})),
    handlePrefillUpdateCustomerForm: (value: UpdateCustomerFormValues) => dispatch(prefillUpdateCustomerForm(value)),
    fillFormFromAres: (ico: string) => dispatch(fillUpdateCustomerFormFromAres(ico)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Update) as typeof Update;
