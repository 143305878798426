import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Layout} from 'layout';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {BarcodeSearchFormValues} from 'order/types/BarcodeSearchFormValues';

interface OwnProps {
    formValues?: BarcodeSearchFormValues;
    onChangeBarcode(barcode: string): void;
}

export type Props = PropsForForm<BarcodeSearchFormValues, OwnProps>;

class BarcodeSearchForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            formValues,
            onChangeBarcode,
        } = this.props;

        const enter = () => {
            if (formValues) {
                onChangeBarcode(formValues.barcode);
            }
        };

        const paste = (newValue: string) => {
            if (formValues) {
                onChangeBarcode(newValue);
            }
        };

        return (
            <Layout.Panel>
                <Form
                    onSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-12">
                            <h4>Naskenujte čárový kód vzorku</h4>

                            <Fields.Input
                                type="text"
                                className="mt-2"
                                name="barcode"
                                label="Čárový kód"
                                autoFocus
                                onEnter={enter}
                                onPasting={paste}
                            />
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}


export default Forms.withForm<BarcodeSearchFormValues, OwnProps, Props>(BarcodeSearchForm, {
    form: 'barcodeSearchForm',
    initialValues: {
        barcode: '',
    },
});
