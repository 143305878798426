import {flow, isEqual} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, withRouter, RouteComponentProps} from 'react-router-dom';
import {Opt} from 'ts-opt';
import {Dispatch} from 'redux';
import {classNames} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {Components as Icons} from 'icons';
import {UserDetails} from 'types/model/users/UserDetails';
import {getHomepageOfRole} from 'user/utils/getHomepageOfRole';

import {toggleMenu} from '../../actions';
import {menuItems} from './menuItems';
import {renderMenuItems} from './renderMenuItems';

import avatarSvg from './img/avatar.svg';
import logoImg from './img/logo.png';
import logoutSvg from './img/logout.svg';

import styles from './styles.sass';

interface OuterProps {}

interface StateProps {
    user: Opt<UserDetails>;
    isOpen: boolean;
}

interface DispatchProps {
    handleToggle(): void;
}

type RouterProps = Pick<RouteComponentProps, 'location'>;

export type Props = OuterProps & StateProps & DispatchProps & RouterProps;

class MainMenu extends Component<Props> {
    componentDidUpdate(prevProps: Props): void {
        const {location: prevLocation} = prevProps;
        const {location, isOpen, handleToggle} = this.props;

        if (isOpen && !isEqual(prevLocation, location)) {
            handleToggle();
        }
    }

    render(): ReactNode {
        const {user, handleToggle, isOpen, location: {pathname: currentPath}} = this.props;
        const isUserLoaded = !user.isEmpty;
        const username = user.map(x => `${x.firstName} ${x.lastName}`).orNull();
        const role = user.map(x => x.role).orUndef();
        const canUpdateAccount = role === 'BREEDER' || role === 'DAIRY';
        const showUsers = role === 'DAIRY';
        const mainMenuClasses = classNames(
            styles.mainMenu,
            isOpen && styles.open
        );
        const mainNavClasses = classNames(
            styles.mainNav,
            showUsers && styles.mainNavShowUsers,
        );

        return (
            <div className={mainMenuClasses}>
                <div className={styles.leftMenu}>
                    {isUserLoaded &&
                        <button
                            type="button"
                            onClick={handleToggle}
                            className={styles.hamButton}
                        >
                            <span
                                role="img"
                                aria-label="Menu"
                            />
                        </button>
                    }
                    <div className={styles.logo}>
                        <NavLink to={getHomepageOfRole(role)}>
                            <img
                                src={logoImg}
                                alt="Domovská stránka"
                                title="Českomoravská společnost chovatelů"
                            />
                        </NavLink>
                        <span className="text-nowrap">Dairy Rating</span>
                    </div>
                </div>
                {isUserLoaded &&
                    <div className={styles.rightMenu}>
                        <div className={mainNavClasses}>
                            {role && renderMenuItems(menuItems(user.orNull()), role, currentPath, isOpen)}
                        </div>
                        <div className={styles.user}>
                            {canUpdateAccount
                                ? <NavLink
                                    to="/update-account"
                                    activeClassName={styles.active}
                                >
                                    <Icons.SvgIcon
                                        icon={avatarSvg}
                                        className={styles.avatar}
                                    />
                                    {username}
                                </NavLink>
                                : <div>
                                    <Icons.SvgIcon
                                        icon={avatarSvg}
                                        className={styles.avatar}
                                    />
                                    {username}
                                </div>
                            }
                            {showUsers &&
                                <NavLink
                                    to="/users"
                                    activeClassName={styles.active}
                                >
                                    Uživatelé
                                </NavLink>
                            }
                            <NavLink
                                to="/contact"
                                activeClassName={styles.active}
                            >
                                Kontakt
                            </NavLink>
                        </div>

                        <div className={styles.logout}>
                            <Link to="/logout">
                                <Icons.SvgIcon
                                    icon={logoutSvg}
                                    className={styles.logoutIcon}
                                />
                            </Link>
                        </div>
                    </div>}
            </div>
        );
    }
}

export const MainMenuWithoutStore = MainMenu as ComponentClass<Props>;

const mapStateToProps = (state: StoreState): StateProps => ({
    user: state.user.currentUser,
    isOpen: state.layout.menuOpen,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleToggle: () => dispatch(toggleMenu()),
});

export default flow([
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
])(MainMenu) as ComponentClass<OuterProps>;
