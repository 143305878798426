import * as t from 'io-ts';

import {EmailSchema} from 'types/generic/EmailSchema';

const optionalPart = t.partial({});

const requiredPart = t.interface({
    email: EmailSchema,
});

export const EmailDetailsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface EmailDetails extends t.TypeOf<typeof EmailDetailsSchema> {}
