import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {handleResponseError} from 'utils/handleResponseError';

import {
    DeleteSampleAction,
    deleteSampleError,
    deleteSampleSuccess,
    loadSamples,
    loadSamplesByOrder,
    resetSamplesByOrder,
} from '../actions';
import Api from '../api';

const title = 'Smazání vzorku';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(deleteSampleError(response.data as ErrorsFromBE));
}

function* handleSuccess(orderId?: number): SagaIterator {
    const params = yield* select((state: StoreState) => state.sample.samplesParams);

    yield* put(showSuccess(title, 'Vzorek byl úspěšně smazán'));
    yield* put(deleteSampleSuccess());

    if (orderId) {
        yield* put(resetSamplesByOrder());
        yield* put(loadSamplesByOrder(orderId));
    } else {
        yield* put(loadSamples(params));
    }
}

function* execute({payload: {orderId, sampleId}}: DeleteSampleAction): SagaIterator {
    const response = yield* call(Api.deleteSample, sampleId);

    yield* response.isSuccess ? call(handleSuccess, orderId) : call(handleErrorResponse, response);
}

export function* deleteSampleSaga(): SagaIterator {
    yield takeLatestF('sample/DELETE_SAMPLE', execute);
}
