import React, {Fragment, FunctionComponent, useEffect} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Opt} from 'ts-opt';
import {flow} from 'lodash/fp';

import {Components as Layout} from 'layout';
import {StoreState} from 'app/types/StoreState';
import {Group, RightIconButton} from 'buttons/components';
import {PickupLineDairyPlan} from 'types/model/pickupLine/PickupLineDairyPlan';
import {withUser} from 'user/components';
import {LoaderWrapper, NoData} from 'layout/components';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';
import {Legend} from 'tables/components';

import {pickupLineAction} from '../actions';
import {PickupLineDairyPlanForm} from '../components/PickupLineDairyPlanForm';
import {PickupLineDairyPlanFormValues} from '../components/PickupLineDairyPlanForm/pickupLineDairyPlanFormValues';
import {getDairyPlanFormInitialValues} from '../utils/getDairyPlanFormInitialValues';
import {PickupLineLoading} from '../types/PickupLineLoading';
import {getYearMonthName} from '../utils/getYearMonthName';
import {isFutureYearMonth} from '../utils/isFutureYearMonth';

const renderToolbar = (
    updatePickupLinesDairyPlan: () => void,
    disabled: boolean,
) =>
    <Fragment>
        <Group>
            <RightIconButton
                label="Uložit"
                icon="ADD"
                onClick={updatePickupLinesDairyPlan}
                disabled={disabled}
            />
        </Group>
    </Fragment>
;

const legendAnnotation: ColorAnnotation[] = [
    {color: 'other', label: 'Pracovní den'},
    {color: 'disabled', label: 'Svátek nebo víkend'},
    {color: 'success', label: 'Preferovaný den svozu'},
    {color: 'warning', label: 'Alternativní den svozu'},
    {color: 'dangerous', label: 'Svoz neprobíhá'},
];

interface StateProps {
    loading: PickupLineLoading[];
    pickupLineDairyPlan: Opt<PickupLineDairyPlan>;
}

interface DispatchProps {
    getPickupLineDairyPlan(): void;
    updatePickupLineDairyPlan(): void;
    setPickupLineDairyPlanFormValues(values: PickupLineDairyPlanFormValues): void;
}

type Props = StateProps & DispatchProps;

const DairyPlanBase: FunctionComponent<Props> = props => {
    const {
        loading,
        pickupLineDairyPlan,
        getPickupLineDairyPlan,
        updatePickupLineDairyPlan,
        setPickupLineDairyPlanFormValues,
    } = props;

    useEffect(() => {
        getPickupLineDairyPlan();
    }, [getPickupLineDairyPlan]);

    useEffect(() => {
        pickupLineDairyPlan.onSome(x => setPickupLineDairyPlanFormValues(getDairyPlanFormInitialValues(x)));
    }, [pickupLineDairyPlan, setPickupLineDairyPlanFormValues]);

    const isEditable = pickupLineDairyPlan.map(x => isFutureYearMonth(x.yearMonth)).orFalse();

    return (
        <Layout.ListPage
            title={`Vyplnění svozových linek${pickupLineDairyPlan.map(
                x => getYearMonthName(x.yearMonth) ? ` - ${getYearMonthName(x.yearMonth)}` : ''
            ).orElse('')}`}
            toolbar={renderToolbar(updatePickupLineDairyPlan, !isEditable)}
        >
            {!isEditable &&
                <div className="alert alert-warning">
                    Není možné vyplňovat aktuální měsíc nebo měsíc z minulosti.
                </div>
            }
            <LoaderWrapper showLoader={loading.includes('dairy')}>
                {pickupLineDairyPlan.map(x =>
                    <Fragment>
                        <Legend annotations={legendAnnotation} />
                        <PickupLineDairyPlanForm
                            analysis={x.analysis}
                            date={x.yearMonth}
                            disabled={!isEditable}
                        />
                    </Fragment>
                ).orElse(
                    <NoData
                        title="Data nejsou k dispozici"
                        text="Pro následující měsíc není zveřejnení plán svozu."
                    />
                )}
            </LoaderWrapper>
        </Layout.ListPage>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getPickupLineDairyPlan: () => dispatch(pickupLineAction.getPickupLineDairyPlan()),
    updatePickupLineDairyPlan: () => dispatch(pickupLineAction.updatePickupLineDairyPlan()),
    setPickupLineDairyPlanFormValues: (values: PickupLineDairyPlanFormValues) =>
        dispatch(pickupLineAction.setPickupLineDairyPlanFormValues(values)),

});

const mapStateToProps = (state: StoreState): StateProps => ({
    loading: state.pickupLine.loading,
    pickupLineDairyPlan: state.pickupLine.pickupLineDairyPlan,
});

export const DairyPlan = flow(
    connect(mapStateToProps, mapDispatchToProps),
    withUser
)(DairyPlanBase);
