import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadOrdersStatsError, loadOrdersStatsSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const errorTitle = 'Načtení statistik objednávek';
    const response = yield* call(Api.getOrdersStats);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadOrdersStatsError(error));
    }

    yield* put(loadOrdersStatsSuccess(response.data));
}

export function* loadOrdersStatsSaga(): SagaIterator {
    yield takeLatestF('order/LOAD_ORDERS_STATS', execute);
}
