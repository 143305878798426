import * as t from 'io-ts';
import {ArrayBufferSchema, FormDataSchema, PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {OrderStats, OrderStatsSchema} from 'types/model/orders/OrderStats';
import {CreateOrder, CreateOrderSchema} from 'types/model/orders/CreateOrder';
import {OrderDetails, OrderDetailsSchema} from 'types/model/orders/OrderDetails';
import {UpdateOrder, UpdateOrderSchema} from 'types/model/orders/UpdateOrder';
import {CreateProtocol, CreateProtocolSchema} from 'types/model/protocols/CreateProtocol';
import {ProtocolDetails, ProtocolDetailsSchema} from 'types/model/protocols/ProtocolDetails';
import {ParsedSample, ParsedSampleSchema} from 'types/model/samples/ParsedSample';
import {OrdersPage, OrdersPageSchema} from 'types/model/orders/OrdersPage';
import {IdResponse, IdResponseSchema} from 'types/model/common/IdResponse';
import {OrderSearch, OrderSearchSchema} from 'types/model/orders/OrderSearch';
import {apiRequester} from 'app/api';

import {OrdersFilter, OrdersParams} from './types/OrderParams';

class Api {
    getOrders(params: OrdersParams): PResponse<OrdersPage> {
        return apiRequester.makeRequest({
            url: '/orders',
            params,
            requestSchema: t.void,
            responseSchema: OrdersPageSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    getOrder(id: number): PResponse<OrderDetails> {
        return apiRequester.makeRequest({
            url: `/orders/${id}`,
            requestSchema: t.void,
            responseSchema: OrderDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    createOrder(data: CreateOrder): PResponse<IdResponse> {
        return apiRequester.makeRequest({
            url: '/orders',
            method: 'post',
            data,
            requestSchema: CreateOrderSchema,
            responseSchema: IdResponseSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    updateOrder(id: number, data: UpdateOrder): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/orders/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateOrderSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getOrdersStats(): PResponse<OrderStats> {
        return apiRequester.makeRequest({
            url: '/orders/stats',
            requestSchema: t.void,
            responseSchema: OrderStatsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    acceptOrder(id: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/orders/${id}/accept`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    cancelOrder(id: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/orders/${id}/cancel`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    parseSample(file: File): PResponse<ParsedSample[]> {
        const data = new FormData();
        data.append('file', file);
        return apiRequester.makeRequest({
            url: '/samples/parse',
            method: 'post',
            data,
            requestSchema: FormDataSchema,
            responseSchema: t.array(ParsedSampleSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getProtocols(orderId: number): PResponse<ProtocolDetails[]> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/protocols`,
            requestSchema: t.void,
            responseSchema: t.array(ProtocolDetailsSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    createProtocol(data: CreateProtocol, orderId: number): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/protocols`,
            data,
            method: 'post',
            requestSchema: CreateProtocolSchema,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    approveProtocol(orderId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/protocols/latest/approve`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    getShippingTicket(orderId: number): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/shipping-ticket`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    searchOrders(barcode: string): PResponse<OrderSearch[]> {
        return apiRequester.makeRequest({
            url: `/orders/search`,
            method: 'get',
            params: {barcode},
            requestSchema: t.void,
            responseSchema: t.array(OrderSearchSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    exportMeasurementsFilling(dataItemId: number, operatingProcedureId: number): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/measurements-filling`,
            method: 'get',
            params: {
                dataItemId,
                operatingProcedureId,
            },
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportTxtProtocols(from: string, to: string): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/protocols/txt',
            method: 'get',
            params: {
                from,
                to,
            },
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportTranscripts(params: OrdersFilter): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/orders/transcript/export',
            method: 'get',
            params,
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportTranscriptSubset(orderIds: number[]): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: '/orders/transcript/export/subset',
            method: 'get',
            params: {
                orderIds,
            },
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    downloadInvoice(orderId: number): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/invoice`,
            method: 'get',
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportInvoiceSubset(orderIds: number[]): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/invoice/export/subset`,
            method: 'post',
            params: {
                orderIds,
            },
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    exportInvoice(params: OrdersFilter): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/orders/invoice/export`,
            method: 'post',
            params,
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    deleteProtocol(protocolId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/protocols/${protocolId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
