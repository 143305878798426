import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import React, {ReactNode} from 'react';
import {opt} from 'ts-opt';

import {InnerFormFieldProps, OuterFormFieldProps} from 'forms/types/BaseFieldType';
import {ChildProps} from 'forms/types/ChildProps';
import BasicDateTime, {DateTimeMode} from '../BasicDateTime';

type FieldValue = string;

type OwnProps = {
    minDate?: Date,
    maxDate?: Date,
    placeholderText?: string,
    mode: DateTimeMode,
    includeFuture?: boolean,
    noPadding?: boolean,
}

export type DateTimeOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type DateTimeInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

class DateTime extends ComponentForWrapping<DateTimeInnerProps, FieldValue> {
    render(): ReactNode {
        const {
            className,
            label,
            helpText,
            input,
            meta,
            disabled,
            minDate,
            maxDate,
            mode,
            includeFuture,
            noPadding,
        } = this.props;

        const {asyncValidating, submitting} = meta;

        const handleChange = (date: string): void => { this.handleChange(opt(date)); };

        const handleBlur = (newValue: string | null): void => { this.handleBlur(opt(newValue)); };

        const handleFocus = (newValue: string | null): void => { this.handleFocus(opt(newValue)); };

        return (
            <BasicDateTime
                mode={mode}
                name={input.name}
                className={className}
                disabled={asyncValidating || submitting || disabled}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                minDate={minDate}
                maxDate={maxDate}
                includeFuture={includeFuture}
                value={this.getFormValue().orUndef()}
                label={label}
                helpText={helpText}
                noPadding={noPadding}
                inForm
                clearable
            />
        );
    }
}

export default DateTime;
