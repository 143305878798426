import React, {PureComponent, ReactNode} from 'react';

import {SelectOptions} from 'forms/components/BasicSelect';
import {Fields} from 'forms';

import {SampleMeasurementResult} from '../../type/SampleMeasurementFormValues';
import {npUnitOptions} from '../../type/npUnits';


interface Props {
    name: string;
    sampleOptions: SelectOptions<number>;
    procedureOptions: SelectOptions<number>;
    isNP: boolean;
}

export class MeasurementResult extends PureComponent<Props> {
    render(): ReactNode {
        const {sampleOptions, procedureOptions, isNP, name} = this.props;
        const buildName = (key: keyof SampleMeasurementResult) => `${name}.${key}`;

        return <div className="row mt-2">
            <div className="col-lg-4">
                <Fields.Select<number>
                    name={buildName('sampleId')}
                    label="Vzorek"
                    options={sampleOptions}
                    disabled
                />
            </div>
            <div className="col-lg-2">
                <Fields.Input
                    name={buildName('dilution')}
                    type="number"
                    label="Ředění*"
                />
            </div>
            <div className="col-lg-2">
                {isNP
                    ? <Fields.Select<string>
                        name={buildName('result')}
                        label="Výsledek*"
                        options={npUnitOptions}
                        autoFocus
                    />
                    : <Fields.Input
                        name={buildName('result')}
                        type="number"
                        label="Výsledek*"
                        autoFocus
                    />
                }
            </div>
            <div className="col-lg-4">
                <Fields.Select<number>
                    name={buildName('procedureId')}
                    label="Operační postup*"
                    options={procedureOptions}
                />
            </div>
        </div>;
    }
}
