import {put, call} from 'typed-redux-saga';
import {SagaIterator} from 'redux-saga';
import {downloadFileFromResponse, SuccessResponse} from 'favorlogic-utils';

import {resetSamplesSelection} from '../../actions';

export function* handleSuccess(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* call(downloadFileFromResponse, response);
    yield* put(resetSamplesSelection());
}

