import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
    suspicious: t.boolean,
});
const requiredPart = t.interface({
    tag: t.literal('CreateGeometricMeanByLaborer'),
    customerId: IntegerSchema,
});

export const CreateGeometricMeanByLaborerSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateGeometricMeanByLaborer extends t.TypeOf<typeof CreateGeometricMeanByLaborerSchema> {}
