import * as t from 'io-ts';
import {PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {SupplyChain, SupplyChainSchema} from 'types/model/supplyChains/SupplyChain';
import {SupplyChainValidation, SupplyChainValidationSchema} from 'types/model/supplyChains/SupplyChainValidation';
import {UpdateSupplyChain, UpdateSupplyChainSchema} from 'types/model/supplyChains/UpdateSupplyChain';
import {apiRequester} from 'app/api';

class Api {
    getSupplyChains(): PResponse<SupplyChain[]> {
        return apiRequester.makeRequest({
            url: '/supply-chains',
            requestSchema: t.void,
            responseSchema: t.array(SupplyChainSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getSupplyChain(supplyChainId: number): PResponse<SupplyChain> {
        return apiRequester.makeRequest({
            url: `/supply-chains/${supplyChainId}`,
            requestSchema: t.void,
            responseSchema: SupplyChainSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    createSupplyChain(data: UpdateSupplyChain): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/supply-chains',
            method: 'post',
            data,
            requestSchema: UpdateSupplyChainSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    updateSupplyChain(data: UpdateSupplyChain, supplyChainId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/supply-chains/${supplyChainId}`,
            method: 'put',
            data,
            requestSchema: UpdateSupplyChainSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    deleteSupplyChain(supplyChainId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/supply-chains/${supplyChainId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    validateSupplyChain(data: SupplyChainValidation): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/supply-chains/validate',
            method: 'post',
            data,
            requestSchema: SupplyChainValidationSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
