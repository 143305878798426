import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {showError, ErrorsFromBE, ErrorResponse, takeLatestF, SuccessResponse} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {RegisterFormValues} from 'user/types/RegisterFormValues';
import {handleResponseError} from 'utils/handleResponseError';
import {Ares} from 'types/model/ares/Ares';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';
import {CustomerInvoiceDetails} from 'types/model/common/CustomerInvoiceDetails';
import {Address} from 'types/model/common/Address';

import {LoadAresAction, isAresLoading, loadAresError, loadAresSuccess, prefillRegisterForm} from '../actions';
import Api from '../api';

const errorTitle = 'Načtení zákazníka z ARES';
const formName = 'register';

export function getFilledFormValues(ares: Ares, currentValues: RegisterFormValues): RegisterFormValues {
    const address: Address = {
        city: opt(ares.city).orElse(''),
        street: opt(ares.street).orElse(''),
        zip: opt(ares.zip).orElse(''),
    };
    const invoiceDetails: CustomerInvoiceDetails = {
        ...currentValues.invoiceDetails,
        dic: opt(ares.dic).orElse(''),
    };

    return {
        ...currentValues,
        invoiceDetails,
        address,
        name: opt(ares.companyName).orElse(''),
    };
}

function* getCurrentRegisterCustomerFormValues(): SagaIterator<RegisterFormValues> {
    return yield* select((state: StoreState) => formValuesF(formName)(state).orCrash('Missing form values'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    if (!response.isUnauthorized) {
        yield* call(handleResponseError, response, errorTitle);
    }

    yield* put(loadAresError(response.data as ErrorsFromBE));
}

function* handleSuccessResponse({data}: SuccessResponse<Ares | null>): SagaIterator {
    const formValues: RegisterFormValues = yield* call(getCurrentRegisterCustomerFormValues);

    if (data) {
        yield* put(prefillRegisterForm(getFilledFormValues(data, formValues)));
        yield* put(loadAresSuccess(data));
    } else {
        yield* put(showError(errorTitle, 'Žádné výsledky nebyly nalezeny.'));
        yield* put(stopSubmitF(formName, {
            invoiceDetails: {
                ico: 'IČO nebylo nalezeno v ARES.',
            },
        }));
    }
}

function* execute(action: LoadAresAction): SagaIterator {
    yield* put(isAresLoading(true));

    const response = yield* call(Api.getAres, action.payload.ico);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
    yield* put(isAresLoading(false));
}

export function* loadAresSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_ARES', execute);
}
