import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {GenerateQCzProtocolFormValues} from '../../types/GenerateQCzProtocolFormValues';

type Values = GenerateQCzProtocolFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('yearMonth', 'Měsíc');

    return validator.generateErrorsObject();
};
