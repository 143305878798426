import * as t from 'io-ts';
import {PResponse, EmptyObject, EmptyObjectSchema} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {SamplesParams} from 'sample/types/SampleQueryParams';
import {MassUpdateSample, MassUpdateSampleSchema} from 'types/model/samples/MassUpdateSample';
import {SampleDetails, SampleDetailsSchema} from 'types/model/samples/SampleDetails';
import {SampleDetailsPage, SampleDetailsPageSchema} from 'types/model/samples/SampleDetailsPage';
import {UpdateSample, UpdateSampleSchema} from 'types/model/samples/UpdateSample';
import {CreateSample, CreateSampleSchema} from 'types/model/samples/CreateSample';
import {DisableSampleSchema} from 'types/model/samples/DisableSample';
import {SamplesByOrderParams} from 'sample/types/SamplesByOrderParams';
import {OrderSampleView, OrderSampleViewSchema} from 'types/model/samples/OrderSampleView';
import {apiRequester} from 'app/api';

class Api {
    getSamples(params: SamplesParams): PResponse<SampleDetailsPage> {
        return apiRequester.makeRequest({
            url: '/samples',
            params,
            requestSchema: t.void,
            responseSchema: SampleDetailsPageSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    getSamplesByOrder(orderId: number, params?: SamplesByOrderParams): PResponse<OrderSampleView[]> {
        return apiRequester.makeRequest({
            url: `/orders/${orderId}/samples`,
            params,
            requestSchema: t.void,
            responseSchema: t.array(OrderSampleViewSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getSample(sampleId: number): PResponse<SampleDetails> {
        return apiRequester.makeRequest({
            url: `/samples/${sampleId}`,
            requestSchema: t.void,
            responseSchema: SampleDetailsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateSample(sampleId: number, data: UpdateSample): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/samples/${sampleId}`,
            method: 'put',
            data,
            requestSchema: UpdateSampleSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    disableSample(sampleId: number, note: string): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/samples/${sampleId}/disable`,
            method: 'patch',
            data: {note},
            requestSchema: DisableSampleSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    acceptSample(sampleId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/samples/${sampleId}/accept`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    deleteSample(sampleId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/samples/${sampleId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    createSampleInOrder(data: CreateSample, id: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/orders/${id}/samples`,
            method: 'post',
            data,
            requestSchema: CreateSampleSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    updateSamplesByFilter(filter: SamplesParams, data: MassUpdateSample): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples',
            data,
            params: filter,
            method: 'patch',
            requestSchema: MassUpdateSampleSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    updateSamplesSelection(sampleIds: number[], data: MassUpdateSample): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/samples/subset',
            params: {sampleIds},
            data,
            method: 'patch',
            requestSchema: MassUpdateSampleSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
