import {flow, find} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';

import {Components as Layout} from 'layout';
import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {SelectOptions} from 'forms/components/BasicSelect';
import {loadMeasurement, updateMeasurement} from 'measurement/actions';
import {StoreState} from 'app/types/StoreState';
import {DataItem} from 'types/model/dataItems/DataItem';
import {Device} from 'types/model/devices/Device';
import {MeasurementDetails} from 'types/model/measurements/MeasurementDetails';
import {UserDetails} from 'types/model/users/UserDetails';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {loadLaborers} from 'user/actions';
import withUser from 'user/components/withUser';
import UpdateMeasurementForm from 'measurement/components/UpdateMeasurementForm';
import {loadDataItems} from 'dataItem/actions';
import {loadDevices} from 'device/actions';
import {loadOperatingProcedures} from 'operatingProcedure/actions';
import {measurementUnitNP} from 'measurement/constants';
import {Loader} from 'layout/components';

import {buildProcedureOptions} from '../utils/buildProcedureOptions';

interface OuterProps {
}

interface InnerProps {
    match: Match<{ id: string }>;
    measurement: Opt<MeasurementDetails>;
    dataItems: Opt<DataItem[]>;
    devices: Opt<Device[]>;
    procedures: Opt<OperatingProcedure[]>;
    laborers: Opt<UserDetails[]>;

    handleLoadMeasurement(id: number): void;

    handleUpdateMeasurement(id: number): void;

    handleLoadLaborers(): void;

    handleLoadDataItems(): void;

    handleLoadDevices(): void;

    handleLoadProcedures(): void;
}

type Props = InnerProps & OuterProps;

class Edit extends Component<Props> {
    private get id(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    private get isNP(): boolean {
        return this.getDataItem()
            .chainToOpt(x => x.measurementUnit === measurementUnitNP)
            .orFalse();
    }

    componentDidMount(): void {
        const {
            handleLoadMeasurement,
            handleLoadLaborers,
            handleLoadDataItems,
            handleLoadDevices,
            handleLoadProcedures,
        } = this.props;
        handleLoadDataItems();
        handleLoadDevices();
        handleLoadProcedures();
        handleLoadLaborers();
        handleLoadMeasurement(this.id);
    }

    render(): ReactNode {
        const {measurement} = this.props;

        return (
            <Layout.ItemPage
                title="Úprava měření"
                backLabel="Měření"
            >
                <Layout.Panel>
                    <Loader show={measurement.isEmpty} />
                    <div className="row mb-4">
                        <ShowField
                            className="col-md-4"
                            label="Datová položka"
                            value={this.getDataItemName()}
                            type={ShowFieldType.String}
                        />
                        <ShowField
                            className="col-md-4"
                            label="Přístroj"
                            value={this.getDeviceName()}
                            type={ShowFieldType.String}
                        />
                        <ShowField
                            className="col-md-4"
                            label="Operátor"
                            value={this.getLaborerName()}
                            type={ShowFieldType.String}
                        />
                    </div>

                    <UpdateMeasurementForm
                        isNP={this.isNP}
                        onSubmit={this.handleSubmit}
                        procedureOptions={this.getProcedureOptions()}
                    />
                </Layout.Panel>
            </Layout.ItemPage>
        );
    }

    private handleSubmit = () => {
        const {handleUpdateMeasurement} = this.props;

        handleUpdateMeasurement(this.id);
    }


    private getLaborerName(): string {
        const {laborers, measurement} = this.props;
        const measuredById = measurement.chainToOpt(x => x.measuredById).orUndef();
        const laborer = opt(laborers.orElse([]).find(l => l.id === measuredById));

        return laborer.chainToOpt(x => `${x.firstName} ${x.lastName}`).orElse('?');
    }

    private getDeviceName(): string {
        const {measurement, devices} = this.props;
        const deviceId = measurement.chainToOpt(x => x.deviceId).orUndef();
        const device = find(x => x.id === deviceId, devices.orUndef());

        return opt(device).map(x => x.name).orElse('-');
    }

    private getDataItemName(): string {
        return this.getDataItem().chainToOpt(x => x.name).orElse('?');
    }

    private getDataItem(): Opt<DataItem> {
        const {measurement, dataItems} = this.props;
        const dataItemId = measurement.chainToOpt(x => x.dataItemId).orUndef();

        return opt(find(x => x.id === dataItemId, dataItems.orUndef()));
    }

    private getProcedureOptions(): SelectOptions<number> {
        const {procedures} = this.props;

        return buildProcedureOptions(procedures.orNull(), this.getDataItem());
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    measurement: state.measurement.current,
    laborers: state.user.laborers,
    dataItems: opt(state.dataItem.dataItems),
    devices: opt(state.device.devices),
    procedures: opt(state.operatingProcedure.operatingProcedures),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadLaborers: () => dispatch(loadLaborers()),
    handleLoadDataItems: () => dispatch(loadDataItems()),
    handleLoadDevices: () => dispatch(loadDevices()),
    handleLoadProcedures: () => dispatch(loadOperatingProcedures()),
    handleLoadMeasurement: (id: number) => dispatch(loadMeasurement(id)),
    handleUpdateMeasurement: (id: number) => dispatch(updateMeasurement(id)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Edit) as ComponentClass<OuterProps>;
