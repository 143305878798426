import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, ErrorResponse, SuccessResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {Device} from 'types/model/devices/Device';

import {loadDevicesError, loadDevicesSuccess} from '../actions';
import Api from '../api';

const title = 'Načtení přístrojů';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadDevicesError(error));
}

function* handleSuccessResponse(response: SuccessResponse<Device[]>): SagaIterator {
    yield* put(loadDevicesSuccess(response.data));
}

function* execute(): SagaIterator {
    const response = yield* call(Api.getDevices);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadDevicesSaga(): SagaIterator {
    yield takeLatestF('device/LOAD_DEVICES', execute);
}
