import * as t from 'io-ts';

import {PickupLineOptionStateSchema} from './PickupLineOptionState';

const requiredPart = t.interface({
    id: t.number,
    state: PickupLineOptionStateSchema,
});

export const UpdatePickupLineOptionSchema = t.exact(requiredPart);

export interface UpdatePickupLineOption extends t.TypeOf<typeof UpdatePickupLineOptionSchema> {}
