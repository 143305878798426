import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {loadOperatingProcedure, updateOperatingProcedure} from '../actions';
import OperatingProcedureForm from '../components/OperatingProcedureForm';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface InnerProps {
    match: Match<{id: string}>;

    handleLoadOperatingProcedure(id: number): void;
    handleUpdateOperatingProcedure(id: number): void;
}

type Props = InnerProps & OuterProps;

class OperatingProcedureEdit extends Component<Props> {
    private get id(): number {
        const {
            match,
        } = this.props;

        return Number(match.params.id);
    }

    componentDidMount(): void {
        const {handleLoadOperatingProcedure} = this.props;

        handleLoadOperatingProcedure(this.id);
    }

    render(): ReactNode {
        return (
            <Layout.ItemPage
                title="Úprava operačního postupu"
                backLabel="Operační postupy"
            >
                <OperatingProcedureForm
                    mode="EDIT"
                    onSubmit={this.handleSubmit}
                />
            </Layout.ItemPage>
        );
    }

    private handleSubmit = () => {
        const {handleUpdateOperatingProcedure} = this.props;

        handleUpdateOperatingProcedure(this.id);
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadOperatingProcedure: (id: number) => dispatch(loadOperatingProcedure(id)),
    handleUpdateOperatingProcedure: (id: number) => dispatch(updateOperatingProcedure({id})),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(OperatingProcedureEdit) as ComponentClass<OuterProps>;
