import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {DeviceFormValues} from './types/DeviceFormValues';

type DeviceFormState = GenericFormState<DeviceFormValues>;

const formReducer: CFormReducer = {
    device: (state: DeviceFormState, action: Action): DeviceFormState => {
        switch (action.type) {
            case 'device/PREFILL_DEVICE_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
