import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    showSuccess,
    ErrorsFromBE,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {CreateSupplier} from 'types/model/suppliers/CreateSupplier';
import {stopSubmitF, startSubmitF, formValuesF} from 'utils/formHelpers';

import {
    createSupplierError,
    createSupplierSuccess,
    CreateSupplierAction,
} from '../actions';
import Api from '../api';
import {CreateSupplierFormValues} from '../types/CreateSupplierFormValues';

const title = 'Vytvoření dodavatele';

export function prepareCreateSupplierData(formData: CreateSupplierFormValues): CreateSupplier {
    const {prefilled, hasNoIco, ...data} = formData;
    const {invoiceDetails} = data;

    data.invoiceDetails = {
        ...invoiceDetails,
        ico: emptyStringToUndefined(invoiceDetails.ico),
        dic: emptyStringToUndefined(invoiceDetails.dic),
        phone: emptyStringToUndefined(invoiceDetails.phone),
        email: emptyStringToUndefined(invoiceDetails.email),
    };

    return data;
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(createSupplierError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('createSupplier', extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně vytvořen.'));
    yield* put(createSupplierSuccess());
    yield* put(stopSubmitF('createSupplier'));
    yield* put(routerActions.goBack());
}

function* execute(_action: CreateSupplierAction): SagaIterator {
    yield* put(startSubmitF('createSupplier'));

    const values = (yield* select(formValuesF('createSupplier')))
        .orCrash('missing form values');
    const response = yield* call(Api.createSupplier, prepareCreateSupplierData(values));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* createSupplierSaga(): SagaIterator {
    yield takeLatestF('supplier/CREATE_SUPPLIER', execute);
}
