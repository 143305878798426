import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';

const optionalPart = t.partial({
});

const requiredPart = t.interface({
    id: IntegerSchema,
    sequenceNumber: IntegerSchema,
    barcode: BarcodeSchema,
    accepted: DateStringSchema,
});

export const SampleSearchSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SampleSearch extends t.TypeOf<typeof SampleSearchSchema> {}
