import {none, opt, Opt} from 'ts-opt';
import {toggleItem} from 'favorlogic-utils';

import {MeasurementAction} from 'measurement/actions';
import {MeasurementDetails} from 'types/model/measurements/MeasurementDetails';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {MeasurementDetailsPage} from 'types/model/measurements/MeasurementDetailsPage';
import {MeasurementsParams} from './type/MeasurementsParams';

export interface MeasurementState {
    measurementsPage: Opt<MeasurementDetailsPage>;
    current: Opt<MeasurementDetails>;
    measurementsParams: MeasurementsParams;
    samplesSearch: Opt<SampleSearch[]>;
    selectedSamples: number[];
    requestedDataItems: number[];
}

type State = MeasurementState;

export const initialMeasurementState: State = {
    measurementsPage: none,
    measurementsParams: {},
    samplesSearch: none,
    current: none,
    selectedSamples: [],
    requestedDataItems: [],
};

const reducer = (state: State | undefined = initialMeasurementState, action: MeasurementAction): State => {
    switch (action.type) {
        case 'measurement/SEARCH_SAMPLES_SUCCESS': {
            return {...state, samplesSearch: opt(action.payload)};
        }
        case 'measurement/RESET_SEARCH_SAMPLES': {
            return {...state, samplesSearch: none};
        }
        case 'measurement/LOAD_MEASUREMENTS_SUCCESS': {
            return {...state, measurementsPage: opt(action.payload)};
        }
        case 'measurement/LOAD_MEASUREMENT_SUCCESS': {
            return {...state, current: opt(action.payload)};
        }
        case 'measurement/SET_MEASUREMENTS_PARAMS': {
            return {...state, measurementsParams: action.payload};
        }
        case 'measurement/TOGGLE_SAMPLE_SELECT': {
            const {selectedSamples} = state;
            const {payload: sampleId} = action;

            return {...state, selectedSamples: toggleItem(sampleId)(selectedSamples)};
        }
        case 'measurement/RESET_SAMPLES_SELECTION': {
            return {...state, selectedSamples: []};
        }
        case 'measurement/LOAD_REQUSTED_DATA_ITEMS_SUCCESS': {
            return {...state, requestedDataItems: action.payload};
        }
        default: { return state; }
    }
};

export default reducer;
