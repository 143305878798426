import * as t from 'io-ts';

import {UpdatePasswordSchema} from 'types/model/customers/UpdatePassword';
import {UuidSchema} from 'types/model/common/Uuid';

const optionalPart = t.partial({});

const requiredPart = t.interface({
    uuid: UuidSchema,
});

export const PasswordResetSchema = t.exact(t.intersection([UpdatePasswordSchema.type, optionalPart, requiredPart]));

export interface PasswordReset extends t.TypeOf<typeof PasswordResetSchema> {}
