import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ExportSamplesSubsidyByFilterAction} from '../actions';
import Api from '../api';
import {handleSuccess} from './utils/exportSagaUtils';

function* execute({payload: {filter}}: ExportSamplesSubsidyByFilterAction): SagaIterator {
    const response = yield* call(Api.exportSamplesSubsidyByFilter, filter);

    yield* response.isSuccess
        ? call(handleSuccess, response)
        : call(handleResponseError, response, 'Export dotací');
}

export function* exportSamplesSubsidyByFilterSaga(): SagaIterator {
    yield takeLatestF('measurement/EXPORT_SAMPLES_SUBSIDY_BY_FILTER', execute);
}
