import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {UpdateMilkRoomFormValues} from './types/UpdateMilkRoomFormValues';
import {UpdateSupplierFormValues} from './types/UpdateSupplierFormValues';
import {CreateSupplierFormValues} from './types/CreateSupplierFormValues';

type CreateSupplierState = GenericFormState<CreateSupplierFormValues>;
type UpdateSupplierState = GenericFormState<UpdateSupplierFormValues>;
type UpdateMilkRoomState = GenericFormState<UpdateMilkRoomFormValues>;

const formReducer: CFormReducer = {
    createSupplier: (state: CreateSupplierState, action: Action): CreateSupplierState => {
        switch (action.type) {
            case 'supplier/PREFILL_CREATE_SUPPLIER_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
    updateSupplier: (state: UpdateSupplierState, action: Action): UpdateSupplierState => {
        switch (action.type) {
            case 'supplier/PREFILL_UPDATE_SUPPLIER_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
    updateMilkRoom: (state: UpdateMilkRoomState, action: Action): UpdateMilkRoomState => {
        switch (action.type) {
            case 'supplier/PREFILL_UPDATE_MILK_ROOM_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
