import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {maxBy} from 'lodash/fp';
import {opt} from 'ts-opt';
import {
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    convertStringToFloat,
    showSuccess,
    ErrorsFromBE,
    takeLatestF,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {CreateSample} from 'types/model/samples/CreateSample';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {
    CreateSampleInOrderAction,
    createSampleInOrderError,
    createSampleInOrderSuccess,
} from '../actions';
import Api from '../api';
import {CreateSampleFormValues} from '../types/CreateSampleFormValues';

export function prepareCreateSampleData(values: CreateSampleFormValues, sampleOrder: number): CreateSample {
    const samplingTemperature = values.samplingTemperature
        ? convertStringToFloat(values.samplingTemperature).orCrash('invalid sampling temperature')
        : undefined;

    return {
        analysisIds: values.analysisIds,
        barcode: values.barcode,
        type: opt(values.type).orCrash('missing type'),
        harvestType: opt(values.harvestType).orCrash('missing harvestType'),
        supplyChainId: opt(values.supplyChainId).orUndef(),
        milkRoomId: opt(values.milkRoomId).orUndef(),
        samplingDate: values.samplingDate,
        samplingTime: opt(values.samplingTime).map(emptyStringToUndefined).orUndef(),
        samplingTemperature,
        sampleSourceAmount: opt(values.sampleSourceAmount).chainToOpt(parseInt).orUndef(),
        showResultsOnWeb: values.showResultsOnWeb,
        showResultsToBreeders: values.showResultsToBreeders,
        sampleOrder,
        customerNote: opt(values.customerNote).orUndef(),
        qualitative: values.qualitative,
        cistern: values.cistern,
        control: values.control,
        subsidy: values.subsidy,
        traitNote: opt(values.traitNote).orUndef(),
        tag: 'CreateSample',
    };
}

function* execute({payload}: CreateSampleInOrderAction): SagaIterator {
    const title = 'Vytvoření vzorku';
    const successMessage = 'Vzorek vytvořen.';
    const formName = 'createSample';

    const values = (yield* select(formValuesF('createSample'))).orCrash('missing form values');
    const orderSamples = yield* select((x: StoreState) => x.sample.orderSamples);
    const lastSample = maxBy((sample) => sample.sampleOrder, orderSamples);
    const reqData = prepareCreateSampleData(values, lastSample ? lastSample.sampleOrder + 1 : 1);
    const response = yield* call(Api.createSampleInOrder, reqData, payload);

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        yield* put(createSampleInOrderError(response.data as ErrorsFromBE));
        return yield* put(stopSubmitF(formName, extractFormErrorsFromResponse(response)));
    }

    yield* put(showSuccess(title, successMessage));
    yield* put(createSampleInOrderSuccess());
    yield* put(routerActions.goBack());
}

export function* createSampleInOrderSaga(): SagaIterator {
    yield takeLatestF('sample/CREATE_SAMPLE_IN_ORDER', execute);
}
