import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema, TimeStringSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';
import {SampleTemperatureSchema} from 'types/generic/SampleTemperatureSchema';

import {SampleHarvestTypeSchema} from '../enumeration/SampleHarvestType';
import {SampleTypeSchema} from '../enumeration/SampleType';

const optionalPart = t.partial({
    sampleSourceAmount: IntegerSchema,
    samplingTemperature: SampleTemperatureSchema,
    samplingTime: TimeStringSchema,
    customerNote: t.string,
    traitNote: t.string,
    milkRoomId: IntegerSchema,
});
const requiredPart = t.interface({
    barcode: BarcodeSchema,
    type: SampleTypeSchema,
    harvestType: SampleHarvestTypeSchema,
    samplingDate: DateStringSchema,
    qualitative: t.boolean,
    cistern: t.boolean,
    control: t.boolean,
    subsidy: t.boolean,
    showResultsOnWeb: t.boolean,
    showResultsToBreeders: t.boolean,
    analysisIds: t.array(IntegerSchema),
});

export const SampleBaseSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SampleBase extends t.TypeOf<typeof SampleBaseSchema> {}
