import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';

interface Props {
    supplyChain: SupplyChain;

    handleDelete(id: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            supplyChain,
            handleDelete,
        } = this.props;
        const {id} = supplyChain;
        const detailUrl = `/supply-chains/${id}`;
        const editUrl = `/supply-chains/${id}/edit`;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={editUrl}
                />
                <Buttons.RowActionButton
                    type="DETAIL"
                    to={detailUrl}
                />
                <Buttons.RowActionButton
                    type="DELETE"
                    onClick={() => handleDelete(id)}
                />
            </div>
        );
    }
}

export default RowActions;
