import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema, TimeStringSchema} from 'favorlogic-utils';

import {SampleTemperatureSchema} from 'types/generic/SampleTemperatureSchema';
import {VeterinaryIdSchema} from 'types/generic/VeterinaryIdSchema';
import {BarcodeSchema} from 'types/generic/BarcodeSchema';
import {SampleTypeSchema} from 'types/model/enumeration/SampleType';
import {SampleHarvestTypeSchema} from 'types/model/enumeration/SampleHarvestType';

const optionalPart = t.partial({
    samplingTemperature: SampleTemperatureSchema,
    samplingDate: DateStringSchema,
    samplingTime: TimeStringSchema,
    note: t.string,
    customerNote: t.string,
    milkRoomName: t.string,
    veterinaryId: VeterinaryIdSchema,
    supplyChainCode: t.string,
    supplyChainName: t.string,
    sampleSourceAmount: IntegerSchema,
    milkRoomId: IntegerSchema,
    traitNote: t.string,
});
const requiredPart = t.interface({
    sampleId: IntegerSchema,
    sampleOrder: IntegerSchema,
    barcode: BarcodeSchema,
    type: SampleTypeSchema,
    harvestType: SampleHarvestTypeSchema,
    canceled: t.boolean,
    disabled: t.boolean,
    canUpdate: t.boolean,
    canDelete: t.boolean,
    hasMeasurements: t.boolean,
    hasAllMeasurements: t.boolean,
    analysisIds: t.array(IntegerSchema),
    accepted: t.boolean,
    showResultsOnWeb: t.boolean,
    showResultsToBreeders: t.boolean,
    qualitative: t.boolean,
    cistern: t.boolean,
    control: t.boolean,
    subsidy: t.boolean,
});

export const OrderSampleViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OrderSampleView extends t.TypeOf<typeof OrderSampleViewSchema> {}
