import {SelectOptions} from 'forms/components/BasicSelect';
import {Opt} from 'ts-opt';

import {DataItem} from 'types/model/dataItems/DataItem';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

import {getProceduresByDataItem} from './getProceduresByDataItem';

export const buildProcedureOptions = (
    procedures: OperatingProcedure[] | null,
    dataItem: Opt<DataItem>,
): SelectOptions<number> =>
    getProceduresByDataItem(procedures, dataItem)
        .map((procedure) => ({
            label: procedure.name,
            value: procedure.id,
        }));
