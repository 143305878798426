import * as t from 'io-ts';

import {AddressSchema} from '../common/Address';
import {CustomerInvoiceDetailsSchema} from '../common/CustomerInvoiceDetails';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    firstName: t.string,
    lastName: t.string,
    invoiceDetails: CustomerInvoiceDetailsSchema,
    address: AddressSchema,
    billingAddress: AddressSchema,
    showResultsOnWeb: t.boolean,
    showResultsToBreeders: t.boolean,
    name: t.string,
});

export const UpdateCustomerBaseSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateCustomerBase extends t.TypeOf<typeof UpdateCustomerBaseSchema> {}
