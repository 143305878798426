import React, {PureComponent, ReactNode} from 'react';

import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {Components as Layout} from 'layout';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';

import {orderStateOptions} from '../../enums/OrderStateEnum';
import {sampleDeliveryTypeOptions} from '../../enums/SampleDeliveryTypeEnum';
import CustomerDetailsView from '../CustomerDetailsView';
import {isOrderDetailsForLaborer} from '../../utils/orderUtils';

export interface Props {
    order: OrderDetails | null;
    samples: OrderSampleView[];
}

class OrderDetailsView extends PureComponent<Props> {
    render(): ReactNode {
        const {order, samples} = this.props;
        return (
            <Layout.Panel caption="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3"><strong className="d-block">Základní údaje</strong></div>
                        {order && isOrderDetailsForLaborer(order) &&
                         <div className="row">
                             <div className="col-md-6">
                                 <ShowField
                                     label="Zákazník"
                                     value={order.customerDetails.customerName}
                                     type={ShowFieldType.String}
                                 />
                             </div>
                             <div className="col-md-6">
                                 <ShowField
                                     label="Přijal(a)"
                                     value={order.acceptedBy}
                                     type={ShowFieldType.String}
                                 />
                             </div>
                         </div>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <ShowField
                                    label="Stav"
                                    value={order?.state}
                                    selectOptions={orderStateOptions}
                                    type={ShowFieldType.SelectOption}
                                    color={order?.state === 'CANCELED' ? 'dangerous' : undefined}
                                />
                                <ShowField
                                    label="Typ dopravy"
                                    value={order?.deliveryType}
                                    selectOptions={sampleDeliveryTypeOptions}
                                    type={ShowFieldType.SelectOption}
                                />
                            </div>
                            <div className="col-md-6">
                                <ShowField
                                    value={order?.created}
                                    label="Datum vytvoření"
                                    type={ShowFieldType.Date}
                                />
                                <ShowField
                                    value={order?.accepted}
                                    label="Datum přijetí"
                                    type={ShowFieldType.Date}
                                />
                            </div>
                        </div>
                        {order && <div className="row">
                            <div className="col-12">
                                {isOrderDetailsForLaborer(order) &&
                                 <ShowField
                                     value={order.internNote  || '-'}
                                     label="Poznámka laboratoře"
                                     type={ShowFieldType.String}
                                 />
                                }
                                <ShowField
                                    value={order.note || '-'}
                                    label="Poznámka od zákazníka"
                                    type={ShowFieldType.String}
                                    color={isOrderDetailsForLaborer(order) ? 'dangerous' : undefined}
                                    bold={isOrderDetailsForLaborer(order)}
                                />
                            </div>
                        </div>}
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <div className="mb-3"><strong className="d-block">Údaje o vzorcích</strong></div>
                                <ShowField
                                    value={samples.length.toString()}
                                    label="Celkové množství vzorků"
                                    type={ShowFieldType.String}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 border-left">
                        {order && <CustomerDetailsView
                            customer={order.customerDetails}
                            priceClass={isOrderDetailsForLaborer(order) ? order.priceClass : undefined}
                        />
                        }
                    </div>
                </div>
            </Layout.Panel>
        );
    }
}

export default OrderDetailsView;
