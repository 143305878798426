import React, {FunctionComponent, ComponentClass} from 'react';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';

import CreateUserForm from '../components/CreateUserForm';
import withUser from '../components/withUser';
import {createUser} from '../actions';

interface OuterProps {
}

interface StateProps {
}

interface DispatchProps {
    handleCreateUser(): void;
}

type Props = OuterProps & StateProps & DispatchProps;

const NewUser: FunctionComponent<Props> = (props) => {
    const {handleCreateUser} = props;

    return (
        <Layout.ItemPage
            title="Nový uživatel"
            backLabel="Uživatelé"
        >
            <CreateUserForm
                onSubmit={handleCreateUser}
            />
        </Layout.ItemPage>
    );
};

const mapStateToProps = (_state: StoreState): StateProps => ({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleCreateUser: () => dispatch(createUser()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(NewUser) as ComponentClass<OuterProps>;
