import React, {Component, ReactNode} from 'react';

import {Components as Layout} from 'layout';
import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {Device} from 'types/model/devices/Device';
import {SelectOptions} from 'forms/components/BasicSelect';
import {limitActionsOptions} from 'device/types/LimitActionOptions';

export interface Props {
    device: Device;
    dataItemsOptions: SelectOptions<number>;
}

class DeviceDetail extends Component<Props> {
    render(): ReactNode {
        const {device, dataItemsOptions} = this.props;

        return (
            <Layout.Panel>
                <div className="col-12">
                    <ShowField
                        value={device.name}
                        type={ShowFieldType.String}
                        label="Název"
                    />
                </div>

                <div className="col-12 mt-1">
                    <span className="align-self-center">Datové položky měřené přístrojem</span>
                    {device.dataItems.map(dataItem  =>
                        <Layout.Panel key={dataItem.id}>
                            <div className="row">
                                <div className="col-md-8">
                                    <ShowField
                                        value={dataItem.dataItemId}
                                        type={ShowFieldType.SelectOption}
                                        selectOptions={dataItemsOptions}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <ShowField
                                        value={dataItem.minimum}
                                        type={ShowFieldType.Number}
                                        label="Minimum"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <ShowField
                                        value={dataItem.maximum}
                                        type={ShowFieldType.Number}
                                        label="Maximum"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <ShowField
                                        value={dataItem.action}
                                        type={ShowFieldType.SelectOption}
                                        label="Akce"
                                        selectOptions={limitActionsOptions}
                                    />
                                </div>
                            </div>
                        </Layout.Panel>
                    )}
                </div>
            </Layout.Panel>
        );
    }
}

export default DeviceDetail;
