import Detail from './Detail';
import List from './List';
import MilkRoomDetail from './MilkRoomDetail';
import New from './New';
import NewMilkRoom from './NewMilkRoom';

export {
    List,
    Detail,
    MilkRoomDetail,
    NewMilkRoom,
    New,
};
