exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-tables-components-SelectFilter-___styles__selectFilter___tX8ln {\n  max-width: 15rem;\n  min-width: 6.5rem;\n  height: auto !important;\n  vertical-align: middle; }\n", ""]);

// exports
exports.locals = {
	"selectFilter": "src-modules-tables-components-SelectFilter-___styles__selectFilter___tX8ln"
};