import {ImportMeasurementFormValues} from 'measurement/type/ImportMeasurementFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

type Values = ImportMeasurementFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('operatorId', 'Číslo pracovníka');

    return validator.generateErrorsObject();
};
