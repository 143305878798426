import React, {FunctionComponent, memo, useCallback} from 'react';

import {RowActionButton} from 'buttons/components';
import {PickupLine} from 'types/model/pickupLine/PickupLine';

interface Props {
    pickupLine: PickupLine;
    onDelete(pickupLineId: number): void;
    onEdit(pickupLineId: number): void;
}

const PickupLineActionsBase: FunctionComponent<Props> = props => {
    const {pickupLine, onDelete, onEdit} = props;
    const {id, canDelete} = pickupLine;

    const handleDelete = useCallback(() => {
        onDelete(id);
    }, [id, onDelete]);

    const handleEdit = useCallback(() => {
        onEdit(id);
    }, [id, onEdit]);

    return (
        <div className="d-flex flex-row-reverse">
            <div className="px-2">
                <RowActionButton
                    type="DELETE"
                    title="Odstranit svozovou linku"
                    onClick={handleDelete}
                    disabled={!canDelete}
                />
            </div>
            <div className="px-2">
                <RowActionButton
                    type="UPDATE"
                    title="Upravit svozovou linku"
                    onClick={handleEdit}
                />
            </div>
        </div>
    );
};

export const PickupLineActions = memo(PickupLineActionsBase);
