import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import withUser from 'user/components/withUser';
import {Device} from 'types/model/devices/Device';
import {DataItem} from 'types/model/dataItems/DataItem';
import {loadDataItems} from 'dataItem/actions';

import {loadDevices} from '../actions';
import DevicesTable from '../components/DevicesTable';

interface OuterProps {}

interface StateProps {
    devices: Device[] | null;
    dataItems: DataItem[] | null;
}

interface DispatchProps {
    handleLoadDevices(): void;
    handleLoadDataItems(): void;
}

type Props =  OuterProps & StateProps & DispatchProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {handleLoadDevices, handleLoadDataItems} = this.props;

        handleLoadDataItems();
        handleLoadDevices();
    }

    render(): ReactNode {
        const {devices, dataItems} = this.props;

        if (devices && isEmpty(devices)) {
            return this.renderNoDevices();
        }

        return (
            <Layout.ListPage title="Přístroje">
                <DevicesTable
                    devices={devices}
                    dataItems={dataItems}
                />
            </Layout.ListPage>
        );
    }

    private renderNoDevices(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="text-center h5">
                    Zatím nejsou žádná zařízení.
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    devices: state.device.devices,
    dataItems: state.dataItem.dataItems,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadDevices: () => dispatch(loadDevices()),
    handleLoadDataItems: () => dispatch(loadDataItems()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
