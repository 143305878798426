import {flow} from 'lodash/fp';
import React, {Component, ReactNode, ComponentType} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {logout} from '../actions';

interface OuterProps {
}

interface Props {
    logoutUser(): void;
}

class Logout extends Component<Props> {
    componentDidMount(): void {
        const {logoutUser} = this.props;

        logoutUser();
    }

    render(): ReactNode {
        return (
            <div className="d-flex justify-content-center pb-5 pt-5">
                Odhlašuji...
            </div>
        );
    }
}

const mapStateToProps = (): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    logoutUser: () => dispatch(logout()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Logout) as ComponentType<OuterProps>;
