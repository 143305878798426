import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {DilutionSchema} from 'types/generic/DilutionSchema';
import {MeasurementHistorySchema} from 'types/model/measurements/MeasurementHistory';

const optionalPart = t.partial({
    deviceId: IntegerSchema,
    history: MeasurementHistorySchema,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    dataItemId: IntegerSchema,
    procedureId: IntegerSchema,
    measured: DateStringSchema,
    measuredById: IntegerSchema,
    dilution: DilutionSchema,
    result: t.number,
    suspicious: t.boolean,
    exceededLimit: t.boolean,
    canDelete: t.boolean,
});

export const MeasurementDetailsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementDetails extends t.TypeOf<typeof MeasurementDetailsSchema> {}
