import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
    name: t.string,
    code: t.string,
    milkRoomId: IntegerSchema,
});
const requiredPart = t.interface({});

export const MilkRoomValidationSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MilkRoomValidation extends t.TypeOf<typeof MilkRoomValidationSchema> {}
