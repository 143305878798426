import {Dictionary} from 'favorlogic-utils';

import {PickupLineDairyPlan} from 'types/model/pickupLine/PickupLineDairyPlan';
import {PickupLineDairyPlanAnalysis} from 'types/model/pickupLine/PickupLineDairyPlanAnalysis';

import {PickupLineDairyPlanFormValues} from '../components/PickupLineDairyPlanForm/pickupLineDairyPlanFormValues';

export const getDairyPlanFormInitialValues = (
    pickupLineDairyPlan: PickupLineDairyPlan
): PickupLineDairyPlanFormValues => {
    const initialValues: PickupLineDairyPlanFormValues = {states: {}};

    pickupLineDairyPlan.analysis.forEach((x: PickupLineDairyPlanAnalysis) => {
        const analysisValues: Dictionary<number, string> = {};
        x.days.forEach((y) => {
            analysisValues[y.id] = String(y.count);
        });
        initialValues.states[x.id] = analysisValues;
    });

    return initialValues;
};
