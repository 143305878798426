import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    showSuccess,
    ErrorsFromBE,
    takeLatestF,
} from 'favorlogic-utils';

import {CreateCustomer} from 'types/model/customers/CreateCustomer';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, resetF, startSubmitF, stopSubmitF} from 'utils/formHelpers';

import {registerError, registerSuccess} from '../actions';
import {RegisterFormValues} from '../types/RegisterFormValues';
import Api from '../api';
import {correctCustomerErrors} from '../utils/correctCustomerErrors';

const formName = 'register';
const title = 'Registrace';

export function prepareReigsterData(formData: RegisterFormValues): CreateCustomer {
    const {hasSameBillingAddress, hasNoIco, checkPassword, ...data} = formData;
    const {invoiceDetails, address} = data;

    if (hasSameBillingAddress) {
        data.billingAddress = {...address};
    }

    data.invoiceDetails = {
        ...invoiceDetails,
        ico: emptyStringToUndefined(invoiceDetails.ico),
        dic: emptyStringToUndefined(invoiceDetails.dic),
        phone: emptyStringToUndefined(invoiceDetails.phone),
    };

    return data;
}

function* execute(): SagaIterator {
    yield* put(startSubmitF(formName));

    const formValuesOpt = yield* select(formValuesF(formName));
    const formValues = formValuesOpt.orCrash('missing form data');
    const response = yield* call(Api.signUp, prepareReigsterData(formValues));

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        yield* put(registerError(response.data as ErrorsFromBE));
        return yield* put(stopSubmitF(formName, correctCustomerErrors(extractFormErrorsFromResponse(response))));
    }

    yield* put(registerSuccess());
    yield* put(stopSubmitF(formName));
    yield* put(routerActions.push('/login'));
    yield* put(showSuccess(title, 'Vytvoření účtu dokončeno, prosím přihlašte se.'));
    yield* put(resetF(formName));
}

export function* registerSaga(): SagaIterator {
    yield takeLatestF('user/REGISTER', execute);
}
