import {PureComponent} from 'react';
import {opt, Opt} from 'ts-opt';

import {InnerFormFieldProps} from 'forms/types/BaseFieldType';

export class ComponentForWrapping<Props extends InnerFormFieldProps<TValue>, TValue, TState = {}>
    extends PureComponent<Props, TState> {
    getFormValue(): Opt<TValue> {
        const {input: {value}} = this.props;
        return opt(value);
    }

    protected handleChange = (newValue: Opt<TValue>): void => {
        const {input: {onChange}, onFieldChange} = this.props;
        onChange(newValue.orNull());
        onFieldChange?.(newValue);
    }

    protected handleBlur = (newValue: Opt<TValue>): void => {
        const {input: {onBlur}, onFieldBlur} = this.props;
        onBlur(newValue.orNull());
        onFieldBlur?.(newValue);
    }

    protected handleFocus= (newValue: Opt<TValue>): void => {
        const {input: {onFocus}, onFieldFocus} = this.props;
        onFocus(newValue.orNull());
        onFieldFocus?.(newValue);
    }
}
