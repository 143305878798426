import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {convertStringToFloat, ErrorResponse, showSuccess, takeLatestF, ErrorsFromBE} from 'favorlogic-utils';

import {CreateMeasurement} from 'types/model/measurements/CreateMeasurement';
import {CreateMeasurements} from 'types/model/measurements/CreateMeasurements';
import {formValuesF, resetF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';

import {
    CreateMeasurementAction,
    createMeasurementError,
    createMeasurementSuccess,
    loadRequestedDataItems,
} from '../actions';
import {SampleMeasurementFormValues, SampleMeasurementResult} from '../type/SampleMeasurementFormValues';
import Api from '../api';

const title = 'Vytvoření měření';

function toCreateMeasurement(x: SampleMeasurementResult): CreateMeasurement {
    return {
        sampleId: opt(x.sampleId).orCrash('missing sample'),
        result: opt(x.result).chain(convertStringToFloat).orCrash('invalid result'),
        dilution: opt(x.dilution).chain(convertStringToFloat).orCrash('invalid dilution'),
        procedureId: opt(x.procedureId).orCrash('missing procedure'),
    };
}

function buildCreateMeasurements(values: SampleMeasurementFormValues): CreateMeasurements {
    const measurement = opt(values.measurement).orCrash('missing measurement');

    return {
        measured: opt(values.measured).orCrash('missing date'),
        dataItemId: opt(values.dataItem).orCrash('missing data item').id,
        measuredById: opt(values.measuredById).orCrash('missing user'),
        measurements: [toCreateMeasurement(measurement)],
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(createMeasurementError(response.data as ErrorsFromBE));
}

function* handleSuccess(continueToNext: boolean): SagaIterator {
    yield* put(createMeasurementSuccess());
    yield* put(showSuccess(title, 'Měření úspěšně vytvořeno.'));

    if (continueToNext) {
        yield* put(resetF('sampleMeasurement'));
        yield* put(loadRequestedDataItems());
    } else {
        yield* put(routerActions.goBack());
    }
}

function* execute(_action: CreateMeasurementAction): SagaIterator {
    const values = (yield* select(formValuesF('sampleMeasurement')))
        .orCrash('missing form values');
    const response = yield* call(Api.createMeasurements, buildCreateMeasurements(values));

    yield* response.isSuccess
        ? call(handleSuccess, values.continueToNext)
        : call(handleErrorResponse, response);
}

export function* createMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/CREATE_MEASUREMENT', execute);
}
