import {isEmpty, every} from 'lodash/fp';

import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {
    DeviceMeasurementFormValues,
    DeviceMeasurementsFormValues,
} from 'measurement/type/DeviceMeasurementsFormValues';
import {OwnProps} from 'measurement/components/DeviceMeasurementsForm';
import {DataItem} from 'types/model/dataItems/DataItem';

type Values = DeviceMeasurementsFormValues;

const validateBasics = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('measured', 'Datum stanovení');
    validator.nonEmpty('measuredById', 'Změřil');
    validator.nonEmpty('deviceId', 'Přístroj');

    return validator.generateErrorsObject();
};

interface SampleMeasurementValidationResult {
    isFilled: boolean;
    errors: Record<string, string>;
}

const validateMeasurement =
    (
        sampleMeasurement: DeviceMeasurementFormValues,
        dataItems: DataItem[]
    ): SampleMeasurementValidationResult => {
        const errors: Record<string, string> = {};

        let isFilled = false;

        dataItems.forEach((item) => {
            if (isEmpty(sampleMeasurement[item.id])) {
                errors[item.id] = `Vypňte měření pro ${item.name}`;
            } else {
                isFilled = true;
            }
        });

        if (isFilled && !isEmpty(errors)) {
            errors.results = 'Řádek nemá vyplněné všechny hodnoty';
        }

        return {isFilled, errors};
    };

export const validate = (values: Values, props: OwnProps): Errors<Values> => {
    const errors = validateBasics(values);

    if (isEmpty(values.measurements) || isEmpty(props.dataItems)) {
        errors.measurements = {_error: 'Nejsou načtena žádná měření'};
    } else {
        const measurementErrors = values.measurements.map(x => validateMeasurement(x, props.dataItems));

        if (every((x: SampleMeasurementValidationResult) => !x.isFilled)(measurementErrors)) {
            errors.measurements = {_error: 'Alespoň jedno měření musí být vyplněné'};
        } else {
            errors.measurements = measurementErrors.map(x => x.isFilled ? x.errors : {});
        }
    }

    return errors;
};

export const isMeasurementValid = (
    sampleMeasurement: DeviceMeasurementFormValues,
    dataItems: DataItem[]
): boolean => isEmpty(validateMeasurement(sampleMeasurement, dataItems).errors);

