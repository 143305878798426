import {flow, range} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {resetWizard} from 'wizard/actions';
import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

interface OuterProps {
    children: ReactNode;

    onComplete?(): void;
    withoutCard?: boolean;
}

interface InnerProps {
    isComplete: boolean;
    stepsSize: number;
    currentStep: number;
    skippedSteps: number[];

    handleReset(): void;
}

type Props = InnerProps & OuterProps;

class Wizard extends Component<Props> {
    componentDidUpdate(_: Props): void {
        const {onComplete, handleReset, isComplete} = this.props;

        if (onComplete && isComplete) {
            onComplete();
            handleReset();
        }
    }

    componentWillUnmount(): void {
        this.props.handleReset();
    }

    render(): ReactNode {
        const {children, stepsSize, currentStep, skippedSteps, withoutCard} = this.props;

        return (
            <div className={classNames(styles.wizard, withoutCard  && styles.withoutCard)}>
                <div className={`${styles.navigation} d-flex justify-content-center`}>
                    {range(1, stepsSize + 1).map(x =>
                        <span
                            key={x}
                            className={`${x < currentStep && styles.done} 
                            ${currentStep === x && styles.active} 
                            ${skippedSteps.includes(x) && styles.skipped}`}
                        >{x}</span>,
                    )}
                </div>
                {children}
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isComplete: state.wizard.isComplete,
    stepsSize: state.wizard.stepsSize,
    currentStep: state.wizard.currentStep,
    skippedSteps: state.wizard.skippedSteps,
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleReset: () => dispatch(resetWizard()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Wizard) as ComponentClass<OuterProps>;
