import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {UserDetails} from 'types/model/users/UserDetails';

type CustomerRow = UserDetails;

interface Props {
    laborers: UserDetails[];
}

class LaborersTable extends Component<Props> {
    private static genColumns(): Column<CustomerRow>[] {
        return [
            {
                accessor: 'firstName',
                header: 'Jméno',
                type: ColumnType.Node,
            },
            {
                accessor: 'lastName',
                header: 'Příjmení',
                type: ColumnType.Node,
            },
        ];
    }

    render(): ReactNode {
        return (
            <div>
                <Tables.Table
                    columns={LaborersTable.genColumns()}
                    rows={this.genData()}
                />
            </div>
        );
    }

    private genData(): Row<CustomerRow>[] {
        const {laborers} = this.props;
        return laborers.map(x => ({...x}));
    }
}

export default LaborersTable;
