import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {Analysis} from 'types/model/analyses/Analysis';
import {handleResponseError} from 'utils/handleResponseError';

import {LoadAnalysisAction, loadAnalysisError, loadAnalysisSuccess, prefillAnalysisForm} from '../actions';
import Api from '../api';
import {AnalysisFormValues} from '../types/AnalysisFormValues';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const title = 'Načtení rozboru';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadAnalysisError(error));
}

export function preparePrefill(analysis: Analysis): AnalysisFormValues {
    return {
        name: analysis.name,
        abbr: analysis.abbr,
        index: analysis.index.toString(),
        price: analysis.price.toString(),
        dataItemIds: analysis.dataItemIds,
        availability: analysis.availability,
    };
}

function* handleSuccessResponse({data: analysis}: SuccessResponse<Analysis>): SagaIterator {
    const prefill = preparePrefill(analysis);

    yield* put(prefillAnalysisForm(prefill));
    yield* put(loadAnalysisSuccess(analysis));
}

function* execute({payload: {analysisId}}: LoadAnalysisAction): SagaIterator {
    const response = yield* call(Api.getAnalysis, analysisId);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* loadAnalysisSaga(): SagaIterator {
    yield takeLatestF('analysis/LOAD_ANALYSIS', execute);
}
