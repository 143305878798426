import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {opt, Opt} from 'ts-opt';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Components as Layout} from 'layout';
import {SampleDeliveryType} from 'types/model/enumeration/SampleDeliveryType';
import {OrderState} from 'types/model/enumeration/OrderState';
import {Customer} from 'types/model/customers/Customer';

import {orderStateOptions} from '../../enums/OrderStateEnum';
import {sampleDeliveryTypeOptions} from '../../enums/SampleDeliveryTypeEnum';
import {UpdateOrderFormValues} from '../../types/UpdateOrderFormValues';
import {validate} from './validations';

const customerDisabledTooltip = 'Nelze změnit zákazníka, protože některé vzorky pocházejí z jeho mléčnic';

interface OwnProps {
    customerOptions: SelectOptions<number> | null;
    isAdminOrLaborer: boolean;
    states?: OrderState[];
    allowChangeState?: boolean;
    hasSamplesFromMilkroom?: boolean;
    customers: Customer[] | null;
}

type Props = PropsForForm<UpdateOrderFormValues, OwnProps>;

export const getStateOptions = (states: OrderState[]): SelectOptions<OrderState> =>
    orderStateOptions.filter(o => states.includes(o.value));

class UpdateOrderForm extends Component<Props> {
    private onCustomerChange = (customerId: Opt<number>): void => {
        const {customers, autofill} = this.props;

        customerId.chainToOpt(id => customers?.find(x => x.id === id))
            .onSome(customer => autofill('customerDetails', customer));
    };

    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            customerOptions,
            isAdminOrLaborer,
            states,
            allowChangeState,
            pristine,
            hasSamplesFromMilkroom,
        } = this.props;
        return (
            <Layout.Panel>
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || !customerOptions || pristine} />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mb-3"><strong className="d-block">Základní údaje</strong></div>
                            {isAdminOrLaborer && <Fields.Select<number>
                                name={validateKey<UpdateOrderFormValues>('customerId')}
                                label="Zákazník"
                                options={customerOptions ?? []}
                                disabled={hasSamplesFromMilkroom}
                                tooltip={hasSamplesFromMilkroom ? customerDisabledTooltip : ''}
                                onFieldChange={this.onCustomerChange}
                                clearable={false}
                            />}
                            <Fields.Select<OrderState>
                                name={validateKey<UpdateOrderFormValues>('state')}
                                className="mt-2"
                                label="Stav"
                                disabled={!allowChangeState}
                                options={getStateOptions(opt(states).orElse([]))}
                            />
                            <Fields.Select<SampleDeliveryType>
                                name={validateKey<UpdateOrderFormValues>('deliveryType')}
                                className="mt-2"
                                label="Typ dopravy"
                                options={sampleDeliveryTypeOptions}
                            />
                            {isAdminOrLaborer && <div className="container-fluid">
                                <Fields.Textarea
                                    name={validateKey<UpdateOrderFormValues>('internNote')}
                                    label="Poznámka laboratoře"
                                />
                            </div>}
                            <div className="container-fluid">
                                <Fields.Textarea
                                    name={validateKey<UpdateOrderFormValues>('note')}
                                    label="Poznámka od zákazníka"
                                />
                            </div>
                        </div>
                        <div className="col-md-7 border-left">
                            <div className="mb-3"><strong className="d-block">Údaje o zákazníkovi</strong></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Fields.Input
                                        name="customerDetails.firstName"
                                        type="text"
                                        label="Jméno"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Fields.Input
                                        name="customerDetails.lastName"
                                        type="text"
                                        label="Příjmení"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Fields.Input
                                        name="customerDetails.customerName"
                                        type="text"
                                        label="Objednatel"
                                    />
                                    <Fields.Input
                                        name="customerDetails.invoiceDetails.email"
                                        type="text"
                                        label="E-mail"
                                    />
                                    <Fields.Input
                                        name="customerDetails.invoiceDetails.phone"
                                        type="text"
                                        label="Telefonní číslo"
                                    />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Fields.Input
                                                name="customerDetails.invoiceDetails.ico"
                                                type="text"
                                                label="IČO"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Fields.Input
                                                name="customerDetails.invoiceDetails.dic"
                                                type="text"
                                                label="DIČ"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <div className="mb-3"><strong>Adresa</strong></div>
                                    <Fields.Input
                                        name="customerDetails.address.street"
                                        type="text"
                                        label="Ulice"
                                    />
                                    <Fields.Input
                                        name="customerDetails.address.city"
                                        type="text"
                                        label="Město"
                                    />
                                    <Fields.Input
                                        name="customerDetails.address.zip"
                                        type="text"
                                        label="Směrovací číslo"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3"><strong>Fakturační adresa</strong></div>
                                    <Fields.Input
                                        name="customerDetails.billingAddress.street"
                                        type="text"
                                        label="Ulice"
                                    />
                                    <Fields.Input
                                        name="customerDetails.billingAddress.city"
                                        type="text"
                                        label="Město"
                                    />
                                    <Fields.Input
                                        name="customerDetails.billingAddress.zip"
                                        type="text"
                                        label="Směrovací číslo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Buttons.Button
                                importance="primary"
                                className="w-100 mt-4"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Uložit
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<UpdateOrderFormValues, OwnProps, Props>(UpdateOrderForm, {
    form: 'updateOrder',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true,
    initialValues: {
        deliveryType: null,
        state: null,
        customerDetails: {
            id: 0,
            firstName: '',
            lastName: '',
            invoiceDetails: {
                phone: '',
                ico: '',
                dic: '',
                email: '',
                tag: 'CustomerInvoiceDetails',
            },
            address: {street: '', city: '', zip: ''},
            billingAddress: {street: '', city: '', zip: ''},
            customerName: '',
        },
        customerId: 0,
    },
});
