import React, {Component, ReactNode, Fragment} from 'react';
import {Form} from 'redux-form';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {CreateSupplierFormValues} from '../../types/CreateSupplierFormValues';
import {asyncValidate, validate} from '../SupplierForm/validations';
import {Address} from 'types/model/common/Address';

export const createEmptyAddress = (): Address => ({street: '', city: '', zip: ''});

interface OwnProps {
    hasNoIco: boolean;
    isIcoValid: boolean;
    filledFromAres: boolean;

    onIcoSearch(): void;
}

type Props = PropsForForm<CreateSupplierFormValues, OwnProps>;

class CreateSupplierForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
            onIcoSearch,
            hasNoIco,
            isIcoValid,
            autofillUnsafe,
            filledFromAres,
        } = this.props;
        const onHasNoIcoChange = () => {
            autofillUnsafe('invoiceDetails.ico', '');
            autofillUnsafe('invoiceDetails.dic', '');
        };
        const onChange = () => {
            if (!isIcoValid) {
                this.resetValuesFromAres();
            }
        };
        const showDetails = filledFromAres || hasNoIco;

        return (
            <Layout.Panel>
                <Form onSubmit={handleSubmit}>
                    <Layout.Loader show={submitting} />
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Základní údaje</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.email"
                                type="text"
                                label="E-mail"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.phone"
                                type="text"
                                label="Telefon"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="note"
                                type="text"
                                label="Poznámka"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.ico"
                                type="text"
                                label="IČO"
                                onAction={onIcoSearch}
                                onFieldChange={onChange}
                                onFieldBlur={onIcoSearch}
                                disabled={hasNoIco}
                            />
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasNoIco"
                                onFieldChange={onHasNoIcoChange}
                                label="Nemám ičo"
                            />
                        </div>
                        {showDetails && <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.dic"
                                type="text"
                                label="DIČ"
                                disabled
                            />
                        </div>}
                    </div>

                    {showDetails && <Fragment>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="name"
                                    type="text"
                                    label="Název firmy/družstva*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <strong className="mt-3 d-block">Adresa</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.street"
                                    type="text"
                                    label="Ulice*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.city"
                                    type="text"
                                    label="Město*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.zip"
                                    type="text"
                                    label="Směrovací číslo*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>
                    </Fragment>}

                    {/* updated from outside of this form */}
                    <div
                        className="row"
                        hidden
                    >
                        <Fields.Input
                            type="hidden"
                            name="milkRooms"
                            label=""
                        />
                    </div>
                </Form>
            </Layout.Panel>
        );
    }

    private resetValuesFromAres(): void {
        const {autofill, autofillUnsafe} = this.props;

        autofill('prefilled', false);
        autofillUnsafe('invoiceDetails.dic', '');
        autofill('address', createEmptyAddress());
        autofill('name', '');
    }
}

export default Forms.withForm<CreateSupplierFormValues, OwnProps, Props>(CreateSupplierForm, {
    form: 'createSupplier',
    validate: validate({isCreate: true}),
    asyncValidate: asyncValidate({isCreate: true}),
    asyncBlurFields: [
        'name',
        'invoiceDetails.email',
        'invoiceDetails.ico',
        'invoiceDetails.dic',
    ],
    initialValues: {
        milkRooms: [],
        address: createEmptyAddress(),
        invoiceDetails: {tag: 'SupplierInvoiceDetails'},
        name: '',
        hasNoIco: false,
        prefilled: false,
    },
});
