import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {validateKey} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {UpdateMeasurementFormValues} from 'measurement/type/UpdateMeasurementFormValues';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Components as Layout} from 'layout';
import {validate} from './validations';
import {npUnitOptions} from '../../type/npUnits';

interface OwnProps {
    isNP: boolean;
    procedureOptions: SelectOptions<number>;
}

export type Props = PropsForForm<UpdateMeasurementFormValues, OwnProps>;

type Values = UpdateMeasurementFormValues;

class UpdateMeasurementForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            isNP,
            procedureOptions,
        } = this.props;

        return (
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />

                <div className="row mb-4">
                    <div className="col-md-4">
                        <Fields.DateTime
                            className="mt-2"
                            name={validateKey<Values>('measured')}
                            mode="DATE"
                            label="Změřeno*"
                        />
                    </div>

                    <div className="col-md-4">
                        {isNP
                            ? <Fields.Select<string>
                                className="mt-2"
                                name={validateKey<Values>('result')}
                                label="Výsledek*"
                                options={npUnitOptions}
                            />
                            : <Fields.Input
                                className="mt-2"
                                name={validateKey<Values>('result')}
                                type="text"
                                label="Výsledek*"
                            />
                        }
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<Values>('dilution')}
                            type="text"
                            label="Ředění*"
                        />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <Fields.Select<number>
                            className="mt-2"
                            name={validateKey<Values>('procedureId')}
                            label="Opearační postup*"
                            options={procedureOptions}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <Fields.Input
                            className="mt-2"
                            name={validateKey<Values>('reason')}
                            type="text"
                            label="Důvod úpravy"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Buttons.Button
                            fullWidth
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Uložit
                        </Buttons.Button>
                    </div>
                </div>
            </Form>

        );
    }
}

export default Forms.withForm<UpdateMeasurementFormValues, OwnProps, Props>(UpdateMeasurementForm, {
    form: 'updateMeasurement',
    validate,
    initialValues: {
        result: '',
        dilution: '',
        measured: '',
        procedureId: null,
        reason: '',
    },
});
