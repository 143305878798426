import * as t from 'io-ts';

import {SampleTemperatureSchema} from 'types/generic/SampleTemperatureSchema';

import {PreservativeSchema} from '../enumeration/Preservative';
import {SampleBaseSchema} from './SampleBase';

const optionalPart = t.partial({
    note: t.string,
    preservatives: t.array(PreservativeSchema),
    deliveryTemperature: SampleTemperatureSchema,
});
const requiredPart = t.interface({
    tag: t.literal('UpdateSampleByLaborer'),
    canceled: t.boolean,
    disabled: t.boolean,
});

export const UpdateSampleByLaborerSchema = t.exact(t.intersection([
    SampleBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateSampleByLaborer extends t.TypeOf<typeof UpdateSampleByLaborerSchema> {}
