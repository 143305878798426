import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {pickupLineAction} from '../actions';

const title = 'Načtení seznamu nepřiřazených mlékáren';

function* execute (): SagaIterator {
    const response = yield* call(PickupLineApi.getUnassignedDairies);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getUnassignedDairiesSuccess(response.data));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* getUnassignedDairiesSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_UNASSIGNED_DAIRIES', execute);
}
