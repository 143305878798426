import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {ExportTxtProtocolsFormValues} from 'order/types/ExportTxtProtocolsFormValues';

type Values = ExportTxtProtocolsFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('from', 'Od');
    validator.nonEmpty('to', 'Do');
    validator.dateEqualOrHigher('from', 'to', 'Od', 'Do');

    return validator.generateErrorsObject();
};
