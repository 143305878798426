import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {LoadSamplesByOrderAction, loadSamplesByOrderSuccess} from '../actions';
import Api from '../api';

function* execute({payload: {orderId, params}}: LoadSamplesByOrderAction): SagaIterator {
    const errorTitle = 'Načtení vzorku objednávky';
    const response = yield* call(Api.getSamplesByOrder, orderId, params);

    if (!response.isSuccess) {
        return yield* call(handleResponseError, response, errorTitle);
    }

    yield* put(loadSamplesByOrderSuccess(response.data));
}

export function* loadSamplesByOrderSaga(): SagaIterator {
    yield takeLatestF('sample/LOAD_SAMPLES_BY_ORDER', execute);
}
