import * as t from 'io-ts';

import {OptionalAddressSchema} from '../common/OptionalAddress';
import {SupplierInvoiceDetailsSchema} from '../common/SupplierInvoiceDetails';

const optionalPart = t.partial({
    note: t.string,
    address: OptionalAddressSchema,
});
const requiredPart = t.interface({
    invoiceDetails: SupplierInvoiceDetailsSchema,
    name: t.string,
});

export const UpdateSupplierSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateSupplier extends t.TypeOf<typeof UpdateSupplierSchema> {}
