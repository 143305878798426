import {createOperatingProcedureSaga} from './createOperatingProcedureSaga';
import {loadOperatingProceduresSaga} from './loadOperatingProceduresSaga';
import {loadOperatingProcedureSaga} from './loadOperatingProcedureSaga';
import {updateOperatingProcedureSaga} from './updateOperatingProcedureSaga';

export {
    createOperatingProcedureSaga,
    loadOperatingProceduresSaga,
    loadOperatingProcedureSaga,
    updateOperatingProcedureSaga,
};
