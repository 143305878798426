export interface NextStepAction {
    type: 'wizard/NEXT_STEP';
}

export const nextStep = (): NextStepAction => ({
    type: 'wizard/NEXT_STEP',
});

export interface ResetWizardAction {
    type: 'wizard/RESET_WIZARD';
}
export const resetWizard = (): ResetWizardAction => ({
    type: 'wizard/RESET_WIZARD',
});

export interface CompleteWizardAction {
    type: 'wizard/COMPLETE_WIZARD';
}
export const completeWizard = (): CompleteWizardAction => ({
    type: 'wizard/COMPLETE_WIZARD',
});

export interface SetStepsSizeAction {
    type: 'wizard/SET_STEPS_SIZE';
    payload: number;
}
export const setStepsSize = (num: number): SetStepsSizeAction => ({
    type: 'wizard/SET_STEPS_SIZE',
    payload: num,
});

export interface SetStepAction {
    type: 'wizard/SET_STEP';
    payload: number;
}
export const setStep = (num: number): SetStepAction => ({
    type: 'wizard/SET_STEP',
    payload: num,
});

export interface SkipStepAction {
    type: 'wizard/SKIP_STEP';
    payload: number;
}
export const skipStep = (num: number): SkipStepAction => ({
    type: 'wizard/SKIP_STEP',
    payload: num,
});

// ---

export type WizardAction =
    NextStepAction | ResetWizardAction |  CompleteWizardAction | SetStepsSizeAction | SetStepAction | SkipStepAction
    ;
