import * as t from 'io-ts';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    unsorted: t.boolean,
    sorted: t.boolean,
    empty: t.boolean,
});

export const SortSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface Sort extends t.TypeOf<typeof SortSchema> {}
