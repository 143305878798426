import * as t from 'io-ts';

import {UpdateSupplierSchema} from './UpdateSupplier';
import {UpdateMilkRoomSchema} from '../milkRooms/UpdateMilkRoom';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    milkRooms: t.array(UpdateMilkRoomSchema),
});

export const CreateSupplierSchema = t.exact(t.intersection([UpdateSupplierSchema.type, optionalPart, requiredPart]));

export interface CreateSupplier extends t.TypeOf<typeof CreateSupplierSchema> {}
