import * as t from 'io-ts';
import {DateStringSchema} from 'favorlogic-utils';

import {PickupLineOptionStateSchema} from './PickupLineOptionState';

const requiredPart = t.interface({
    id: t.number,
    pickupDate: DateStringSchema,
    state: PickupLineOptionStateSchema,
    count: t.number,
});

export const PickupLineDairyPlanAnalysisDaySchema = t.exact(requiredPart);

export interface PickupLineDairyPlanAnalysisDay extends t.TypeOf<typeof PickupLineDairyPlanAnalysisDaySchema> {}
