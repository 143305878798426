import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/suppliers"
        component={Screens.List}
        key="suppliers"
    />,
    <Route
        exact
        path="/suppliers/new"
        component={Screens.New}
        key="suppliersNew"
    />,
    <Route
        exact
        path="/suppliers/:id"
        component={Screens.Detail}
        key="suppliersDetail"
    />,
    <Route
        exact
        path="/suppliers/:supplierId/milk-room/:milkRoomId"
        component={Screens.MilkRoomDetail}
        key="suppliersMilkRoomDetail"
    />,
    <Route
        exact
        path="/suppliers/:supplierId/new-milk-room"
        component={Screens.NewMilkRoom}
        key="suppliersNewMilkRoom"
    />,
];

export default routes;
