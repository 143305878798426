import {ErrorsFromBE} from 'favorlogic-utils';

import {Analysis} from 'types/model/analyses/Analysis';

import {AnalysisFormValues} from './types/AnalysisFormValues';

export interface LoadAnalysesAction {
    type: 'analysis/LOAD_ANALYSES';
}

export const loadAnalyses = (): LoadAnalysesAction => ({
    type: 'analysis/LOAD_ANALYSES',
});

export interface LoadAnalysesSuccessAction {
    type: 'analysis/LOAD_ANALYSES_SUCCESS';
    payload: {
        analyses: Analysis[],
    };
}

export const loadAnalysesSuccess = (analyses: Analysis[]): LoadAnalysesSuccessAction => ({
    type: 'analysis/LOAD_ANALYSES_SUCCESS',
    payload: {analyses},
});

export interface LoadAnalysesErrorAction {
    type: 'analysis/LOAD_ANALYSES_ERROR';
    payload: {
        error: ErrorsFromBE,
    };
}

export const loadAnalysesError = (error: ErrorsFromBE): LoadAnalysesErrorAction => ({
    type: 'analysis/LOAD_ANALYSES_ERROR',
    payload: {error},
});

// ---

export interface LoadAnalysisAction {
    type: 'analysis/LOAD_ANALYSIS';
    payload: {
        analysisId: number,
    };
}

export const loadAnalysis = (analysisId: number): LoadAnalysisAction => ({
    type: 'analysis/LOAD_ANALYSIS',
    payload: {analysisId},
});

export interface LoadAnalysisSuccessAction {
    payload: {
        analysis: Analysis,
    };
    type: 'analysis/LOAD_ANALYSIS_SUCCESS';
}

export const loadAnalysisSuccess = (analysis: Analysis): LoadAnalysisSuccessAction => ({
    type: 'analysis/LOAD_ANALYSIS_SUCCESS',
    payload: {analysis},
});

export interface LoadAnalysisErrorAction {
    type: 'analysis/LOAD_ANALYSIS_ERROR';
    payload: {
        error: ErrorsFromBE,
    };
}

export const loadAnalysisError = (error: ErrorsFromBE): LoadAnalysisErrorAction => ({
    type: 'analysis/LOAD_ANALYSIS_ERROR',
    payload: {error},
});

// ---

export interface UpdateAnalysisAction {
    type: 'analysis/UPDATE_ANALYSIS';
    payload: {
        analysisId: number,
    };
}

export const updateAnalysis = (analysisId: number): UpdateAnalysisAction => ({
    type: 'analysis/UPDATE_ANALYSIS',
    payload: {analysisId},
});

export interface UpdateAnalysisSuccessAction {
    type: 'analysis/UPDATE_ANALYSIS_SUCCESS';
}

export const updateAnalysisSuccess = (): UpdateAnalysisSuccessAction => ({
    type: 'analysis/UPDATE_ANALYSIS_SUCCESS',
});

export interface UpdateAnalysisErrorAction {
    type: 'analysis/UPDATE_ANALYSIS_ERROR';
    payload: {
        error: ErrorsFromBE,
    };
}

export const updateAnalysisError = (error: ErrorsFromBE): UpdateAnalysisErrorAction => ({
    type: 'analysis/UPDATE_ANALYSIS_ERROR',
    payload: {error},
});

// ---

export interface PrefillAnalysisFormAction {
    type: 'analysis/PREFILL_ANALYSIS_FORM';
    payload: {
        values: AnalysisFormValues,
    };
}

export const prefillAnalysisForm = (values: AnalysisFormValues): PrefillAnalysisFormAction => ({
    type: 'analysis/PREFILL_ANALYSIS_FORM',
    payload: {values},
});

// ---

export type AnalysisAction =
    LoadAnalysesAction | LoadAnalysesSuccessAction | LoadAnalysesErrorAction |
    LoadAnalysisAction | LoadAnalysisSuccessAction | LoadAnalysisErrorAction |
    UpdateAnalysisAction | UpdateAnalysisSuccessAction | UpdateAnalysisErrorAction |
    PrefillAnalysisFormAction
    ;
