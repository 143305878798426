import {ReactNode} from 'react';
import {KeysOfType} from 'favorlogic-utils';

import {SelectOptions} from 'forms/components/BasicSelect';

export enum ColumnType {
    Node,
    Date,
    SelectOption,
    MultiSelectOption,
    Currency,
    Temperature,
    Bool,
    DynamicNode,
}

export type FilterType = 'equals' | 'betweenDates' | 'select' | 'multiselect' | 'date' | 'check';

export type FilterInputType = 'text' | 'integer';

type Accesor<T, ValidPropValue> = string & KeysOfType<T, ValidPropValue | undefined>


interface BaseColumn {
    type: ColumnType;
    header?: ReactNode;
    subHeader?: string;
    tooltip?: string;
    size?: 'wide' | 'normal' | 'medium' | 'narrow';

    sortable?: boolean;
    filterable?: boolean;
    filterType?: FilterType;
    filterInputType?: FilterInputType;
    filterMulti?: boolean;
    filterOptions?: SelectOptions<string>;
}

interface BaseStaticColumn<T, ValidPropValue> extends BaseColumn {
    accessor: Accesor<T, ValidPropValue>;
}

export interface NodeColumn<T> extends BaseStaticColumn<T, ReactNode> {
    type: ColumnType.Node;
    maxChars?: number;
}

export interface DynamicNodeColumn extends BaseColumn {
    accessor: string;
    type: ColumnType.DynamicNode;
    maxChars?: number;
}

export type DateFormat = 'TIME' | 'DATE';

export interface DateColumn<T> extends BaseStaticColumn<T, string> {
    type: ColumnType.Date;
    dateFormat: DateFormat;
}

export interface SelectOptionsColumn<T> extends BaseStaticColumn<T, string> {
    type: ColumnType.SelectOption;
    selectOptions: SelectOptions<string>;
    lengthLimit?: number;
}

export interface MultiSelectOptionsColumn<T> extends BaseStaticColumn<T, ReactNode> {
    type: ColumnType.MultiSelectOption;
    selectOptions: SelectOptions<string>;
    format?: (value: string[]) => ReactNode;
}

export interface CurrencyColumn<T> extends BaseStaticColumn<T, number> {
    accessor: Accesor<T, number>;
    type: ColumnType.Currency;
}

export interface TemperatureColumn<T> extends BaseStaticColumn<T, number> {
    type: ColumnType.Temperature;
}

export interface BoolColumn<T> extends  BaseStaticColumn<T, boolean> {
    type: ColumnType.Bool;
    format?: (value: boolean) => ReactNode;
}

export type Column<T> =
    NodeColumn<T> |
    DateColumn<T> |
    SelectOptionsColumn<T> |
    MultiSelectOptionsColumn<T> |
    CurrencyColumn<T> |
    TemperatureColumn<T> |
    BoolColumn<T> |
    DynamicNodeColumn
    ;
