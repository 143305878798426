import {SagaIterator} from 'redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {ForceAsyncValidationAction} from 'forms/actions';
import {formDatabase} from 'forms/components/withForm/FormDatabase';

// eslint-disable-next-line require-yield
function* execute(action: ForceAsyncValidationAction): SagaIterator {
    const {payload: {formName, fieldName, newValue}} = action;
    const comp = formDatabase.get(formName).orCrash(`form ${JSON.stringify(formName)} not found`);
    comp.props.asyncValidate(fieldName, newValue, 'blur');
}

export function* forceAsyncValidationSaga(): SagaIterator {
    yield takeLatestF('forms/FORCE_ASYNC_VALIDATION', execute);
}
