import {FormName} from 'app/types/StoreFormState';
import {DateString} from 'types/generic/DateString';
import {PickupLine} from 'types/model/pickupLine/PickupLine';
import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';
import {PickupLineValidation} from 'types/model/pickupLine/PickupLineValidation';
import {Customer} from 'types/model/pickupLine/Customer';
import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import {PickupLineRequests} from 'types/model/pickupLine/PickupLineRequests';
import {PickupLineDairyPlan} from 'types/model/pickupLine/PickupLineDairyPlan';

import {WritePickupLineFormValues} from './components/WritePickupLineForm/writePickupLineFormValues';
import {PickupLinePlanningFormValues} from './components/PickupLinePlanningForm/pickupLinePlanningFormValues';
import {PickupLineDairyPlanFormValues} from './components/PickupLineDairyPlanForm/pickupLineDairyPlanFormValues';
import {PickupLineLoading} from './types/PickupLineLoading';

export const pickupLineAction = {
    setLoading: (id: PickupLineLoading, loading: boolean) => ({
        type: 'pickupLine/SET_LOADING',
        payload: {id, loading},
    }) as const,
    getPickupLines: () => ({
        type: 'pickupLine/GET_PICKUP_LINES',
    }) as const,
    getPickupLinesSuccess: (pickupLines: PickupLine[]) => ({
        type: 'pickupLine/GET_PICKUP_LINES_SUCCESS',
        payload: {
            pickupLines,
        },
    }) as const,
    postPickupLine: (data: WritePickupLine) => ({
        type: 'pickupLine/POST_PICKUP_LINE',
        payload: {
            data,
        },
    }) as const,
    validatePickupLineName: (data: PickupLineValidation, formName: FormName) => ({
        type: 'pickupLine/VALIDATE_PICKUP_LINE_NAME',
        payload: {
            data, formName,
        },
    }) as const,
    getUnassignedDairies: () => ({
        type: 'pickupLine/GET_UNASSIGNED_DAIRIES',
    }) as const,
    getUnassignedDairiesSuccess: (unassignedDairies: Customer[]) => ({
        type: 'pickupLine/GET_UNASSIGNED_DAIRIES_SUCCESS',
        payload: {
            unassignedDairies,
        },
    }) as const,
    deletePickupLine: (pickupLineId: number) => ({
        type: 'pickupLine/DELETE_PICKUP_LINE',
        payload: {
            pickupLineId,
        },
    }) as const,
    updatePickupLine: (pickupLineId: number, data: WritePickupLine) => ({
        type: 'pickupLine/UPDATE_PICKUP_LINE',
        payload: {
            pickupLineId, data,
        },
    }) as const,
    setPickupLineIdInEdit: (pickupLineId?: number) => ({
        type: 'pickupLine/SET_PICKUP_LINE_ID_IN_EDIT',
        payload: {
            pickupLineId,
        },
    }) as const,
    setPickupLineEditFormValues: (values: WritePickupLineFormValues) => ({
        type: 'pickupLine/SET_PICKUP_LINE_EDIT_FORM_VALUES',
        payload: {
            values,
        },
    }) as const,
    getPickupLinesOptions: (yearMonth: DateString) => ({
        type: 'pickupLine/GET_PICKUP_LINES_OPTIONS',
        payload: {
            yearMonth,
        },
    }) as const,
    getPickupLinesOptionsSuccess: (data: PickupLineOptions[]) => ({
        type: 'pickupLine/GET_PICKUP_LINES_OPTIONS_SUCCESS',
        payload: {
            data,
        },
    }) as const,
    setOverviewDate: (date: DateString) => ({
        type: 'pickupLine/SET_OVERVIEW_DATE',
        payload: {
            date,
        },
    }) as const,
    setPlanningDate: (date: DateString) => ({
        type: 'pickupLine/SET_PLANNING_DATE',
        payload: {
            date,
        },
    }) as const,
    setPickupLinePlanningFormValues: (values: PickupLinePlanningFormValues) => ({
        type: 'pickupLine/SET_PICKUP_LINE_PLANNING_FORM_VALUES',
        payload: {
            values,
        },
    }) as const,
    updatePickupLinesOptions: (yearMonth: DateString) => ({
        type: 'pickupLine/UPDATE_PICKUP_LINES_OPTIONS',
        payload: {
            yearMonth,
        },
    }) as const,
    approvePickupLinePlan: (yearMonth: DateString, pickupLineId: number) => ({
        type: 'pickupLine/APPROVE_PICKUP_LINE_PLAN',
        payload: {
            yearMonth, pickupLineId,
        },
    }) as const,
    getPickupLineRequests: (yearMonth: DateString, pickupLineId: number) => ({
        type: 'pickupLine/GET_PICKUP_LINE_REQUESTS',
        payload: {
            yearMonth, pickupLineId,
        },
    }) as const,
    getPickupLineRequestsSuccess: (data: PickupLineRequests) => ({
        type: 'pickupLine/GET_PICKUP_LINE_REQUESTS_SUCCESS',
        payload: {
            data,
        },
    }) as const,
    getPickupLineRequestsError: () => ({
        type: 'pickupLine/GET_PICKUP_LINE_REQUESTS_ERROR',
    }) as const,
    getPickupLineRequestsExport: (yearMonth: DateString, pickupLineId: number) => ({
        type: 'pickupLine/GET_PICKUP_LINE_REQUESTS_EXPORT',
        payload: {
            yearMonth, pickupLineId,
        },
    }) as const,
    setDisplayedPickupLine: (pickupLineId: number) => ({
        type: 'pickupLine/SET_DISPLAYED_PICKUP_LINE',
        payload: {
            pickupLineId,
        },
    }) as const,
    getPickupLineDairyPlan: () => ({
        type: 'pickupLine/GET_PICKUP_LINE_DAIRY_PLAN',
    }) as const,
    getPickupLineDairyPlanSuccess: (data: PickupLineDairyPlan) => ({
        type: 'pickupLine/GET_PICKUP_LINE_DAIRY_PLAN_SUCCESS',
        payload: {
            data,
        },
    }) as const,
    updatePickupLineDairyPlan: () => ({
        type: 'pickupLine/UPDATE_PICKUP_LINE_DAIRY_PLAN',
    }) as const,
    setPickupLineDairyPlanFormValues: (values: PickupLineDairyPlanFormValues) => ({
        type: 'pickupLine/SET_PICKUP_LINE_DAIRY_PLAN_FORM_VALUES',
        payload: {
            values,
        },
    }) as const,
};

export type SetLoadingAction = ReturnType<typeof pickupLineAction.setLoading>;
export type GetPickupLinesAction = ReturnType<typeof pickupLineAction.getPickupLines>;
export type GetPickupLinesSuccessAction = ReturnType<typeof pickupLineAction.getPickupLinesSuccess>;
export type PostPickupLineAction = ReturnType<typeof pickupLineAction.postPickupLine>;
export type ValidatePickupLineNameAction = ReturnType<typeof pickupLineAction.validatePickupLineName>;
export type GetUnassignedDairiesAction = ReturnType<typeof pickupLineAction.getUnassignedDairies>;
export type GetUnassignedDairiesSuccessAction = ReturnType<typeof pickupLineAction.getUnassignedDairiesSuccess>;
export type DeletePickupLineAction = ReturnType<typeof pickupLineAction.deletePickupLine>;
export type UpdatePickupLineAction = ReturnType<typeof pickupLineAction.updatePickupLine>;
export type SetPickupLineIdInEditAction = ReturnType<typeof pickupLineAction.setPickupLineIdInEdit>;
export type SetPickupLineEditFormValuesAction = ReturnType<typeof pickupLineAction.setPickupLineEditFormValues>;
export type GetPickupLinesOptionsAction = ReturnType<typeof pickupLineAction.getPickupLinesOptions>;
export type GetPickupLinesOptionsSuccessAction = ReturnType<typeof pickupLineAction.getPickupLinesOptionsSuccess>;
export type SetOverviewDateAction = ReturnType<typeof pickupLineAction.setOverviewDate>;
export type SetPlanningDateAction = ReturnType<typeof pickupLineAction.setPlanningDate>;
export type SetPickupLinePlanningFormValuesAction = ReturnType<typeof pickupLineAction.setPickupLinePlanningFormValues>;
export type UpdatePickupLinesOptionsAction = ReturnType<typeof pickupLineAction.updatePickupLinesOptions>;
export type ApprovePickupLinePlanAction = ReturnType<typeof pickupLineAction.approvePickupLinePlan>;
export type GetPickupLineRequestsAction = ReturnType<typeof pickupLineAction.getPickupLineRequests>;
export type GetPickupLineRequestsSuccessAction = ReturnType<typeof pickupLineAction.getPickupLineRequestsSuccess>;
export type GetPickupLineRequestsErrorAction = ReturnType<typeof pickupLineAction.getPickupLineRequestsError>;
export type GetPickupLineRequestsExportAction = ReturnType<typeof pickupLineAction.getPickupLineRequestsExport>;
export type SetDisplayedPickupLineAction = ReturnType<typeof pickupLineAction.setDisplayedPickupLine>;
export type GetPickupLineDairyPlanAction = ReturnType<typeof pickupLineAction.getPickupLineDairyPlan>;
export type GetPickupLineDairyPlanSuccessAction = ReturnType<typeof pickupLineAction.getPickupLineDairyPlanSuccess>;
export type UpdatePickupLineDairyPlanAction = ReturnType<typeof pickupLineAction.updatePickupLineDairyPlan>;
export type SetPickupLineDairyPlanFormValuesAction =
    ReturnType<typeof pickupLineAction.setPickupLineDairyPlanFormValues>;

export type PickupLineAction =
| SetLoadingAction
| GetPickupLinesAction
| GetPickupLinesSuccessAction
| PostPickupLineAction
| ValidatePickupLineNameAction
| GetUnassignedDairiesAction
| GetUnassignedDairiesSuccessAction
| DeletePickupLineAction
| UpdatePickupLineAction
| SetPickupLineIdInEditAction
| SetPickupLineEditFormValuesAction
| GetPickupLinesOptionsAction
| GetPickupLinesOptionsSuccessAction
| SetOverviewDateAction
| SetPlanningDateAction
| SetPickupLinePlanningFormValuesAction
| UpdatePickupLinesOptionsAction
| ApprovePickupLinePlanAction
| GetPickupLineRequestsAction
| GetPickupLineRequestsSuccessAction
| GetPickupLineRequestsErrorAction
| GetPickupLineRequestsExportAction
| SetDisplayedPickupLineAction
| GetPickupLineDairyPlanAction
| GetPickupLineDairyPlanSuccessAction
| UpdatePickupLineDairyPlanAction
| SetPickupLineDairyPlanFormValuesAction
;
