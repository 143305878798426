import {flow, noop} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import withUser from 'user/components/withUser';
import {createMilkRoom} from '../actions';
import CreateMilkRoomForm from '../components/CreateMilkRoomForm';

interface OuterProps {}

interface InnerProps {
    match: Match<{ supplierId: string }>;

    handleCreateMilkRoom(supplierId: number): void;
}

type Props = InnerProps & OuterProps;

class NewMilkRoom extends Component<Props> {
    render(): ReactNode {
        const {
            match,
            handleCreateMilkRoom,
        } = this.props;
        const supplierId = Number(match.params.supplierId);

        const handleSubmit = () => {
            handleCreateMilkRoom(supplierId);
        };
        return (
            <Layout.ItemPage
                title="Nová mléčnice"
                backLabel="Dodavatel"
            >
                <CreateMilkRoomForm
                    supplierId={supplierId}
                    onSubmit={handleSubmit}
                    onBack={noop}
                />
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleCreateMilkRoom: (supplierId: number) => dispatch(createMilkRoom({supplierId})),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(NewMilkRoom) as ComponentClass<OuterProps>;
