import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    convertStringToFloat,
    showSuccess,
    ErrorsFromBE,
    takeLatestF,
    ErrorResponse,
    convertStringToInt,
} from 'favorlogic-utils';

import {UpdateAnalysis} from 'types/model/analyses/UpdateAnalysis';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {UpdateAnalysisAction, updateAnalysisError, updateAnalysisSuccess} from '../actions';
import Api from '../api';
import {AnalysisFormValues} from '../types/AnalysisFormValues';

const title = 'Upravení rozboru';

export function prepareData(values: AnalysisFormValues): UpdateAnalysis {
    return {
        name: values.name,
        abbr: values.abbr,
        index: convertStringToInt(values.index).orCrash('invalid index value'),
        price: convertStringToFloat(values.price).orCrash('invalid price'),
        availability: values.availability,
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateAnalysisError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('analysis', extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně upraven.'));
    yield* put(updateAnalysisSuccess());
}

function* getAnalysisData(): SagaIterator<UpdateAnalysis> {
    const values = (yield* select(formValuesF('analysis')))
        .orCrash('missing form values');

    return prepareData(values);
}

function* execute({payload: {analysisId}}: UpdateAnalysisAction): SagaIterator {
    const analysisData = yield* call(getAnalysisData);
    const response = yield* call(Api.updateAnalysis, analysisId, analysisData);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* updateAnalysisSaga(): SagaIterator {
    yield takeLatestF('analysis/UPDATE_ANALYSIS', execute);
}
