import React, {FunctionComponent} from 'react';
import {classNames} from 'favorlogic-utils';

interface Props {
    fluid?: boolean;
}

const Page: FunctionComponent<Props> = props => {
    const {fluid, children} = props;
    const classes = classNames(
        'd-flex py-4 py-sm-5 px-3 px-sm-4 flex-column',
        fluid ? 'container-fluid' : 'container-lg',
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default Page;
