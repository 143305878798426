import {OrderState} from 'types/model/enumeration/OrderState';
import {SelectOptions} from 'forms/components/BasicSelect';

export const orderStateOptions: SelectOptions<OrderState> = [
    {value: 'NEW', label: 'Nová'},
    {value: 'ACCEPTED', label: 'Přijatá'},
    {value: 'IN_PROGRESS', label: 'Rozpracovaná'},
    {value: 'COMPLETED', label: 'Dokončená'},
    {value: 'CANCELED', label: 'Zrušená'},
];
