import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {isEmpty} from 'lodash/fp';
import {
    extractFormErrorsFromResponse,
    UnsafeBeFormErrors,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {MilkRoomValidation} from 'types/model/milkRooms/MilkRoomValidation';

import {ValidateMilkRoomAction} from '../actions';
import Api from '../api';

export function removeEmpty(values: MilkRoomValidation): MilkRoomValidation {
    const {name, code, milkRoomId} = values;

    return {
        milkRoomId,
        ...name && {name},
        ...code && {code},
    };
}

function handleErrorResponse(response: ErrorResponse, reject: (_: UnsafeBeFormErrors) => void): void {
    const errors = extractFormErrorsFromResponse(response);

    if (!isEmpty(errors)) {
        reject(errors);
    }
}

function* execute({payload: {reject, resolve, values, supplierId}}: ValidateMilkRoomAction): SagaIterator {
    const response = yield* call(Api.validateMilkRoom, supplierId, removeEmpty(values));

    yield* response.isSuccess
        ? call(resolve, false)
        : call(handleErrorResponse, response, reject);
}

export function* validateMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/VALIDATE_MILK_ROOM', execute);
}
