import React, {FunctionComponent} from 'react';

import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

// TODO: a11y

export interface SvgIconProps {
    icon: string;
    className?: string | null;
    title?: string;
}

const SvgIcon: FunctionComponent<SvgIconProps> = props => {
    const {icon, className, title} = props;
    const classes = classNames(className, 'd-inline-flex', 'align-items-center');

    return (
        <span
            className={classNames(
                styles['svg-icon'],
                classes,
            )}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{__html: icon}}
            title={title}
        />
    );
};
SvgIcon.defaultProps = {
    className: null,
};

export default SvgIcon;
