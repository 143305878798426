import React, {Fragment, FunctionComponent} from 'react';
import {Opt} from 'ts-opt';
import {classNames, Nullable} from 'favorlogic-utils';
import {isEmpty} from 'lodash/fp';

import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import {DateString} from 'types/generic/DateString';
import withForm, {PropsForForm} from 'forms/components/withForm';
import {LoaderWrapper, NoData} from 'layout/components';
import {PickupLineLoading} from 'pickupLine/types/PickupLineLoading';
import {Legend} from 'tables/components';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';

import {PickupLineColumn} from './pickupLineColumn';
import {DaysColumn} from '../DaysColumn';
import {PickupLinePlanningFormValues, PICKUP_LINE_PLANNING_FORM_NAME} from './pickupLinePlanningFormValues';

import styles from './styles.sass';

interface OwnProps {
    loading: PickupLineLoading[];
    pickupLinesOptions: Opt<PickupLineOptions[]>;
    planningDate: Nullable<DateString>;
    disabled: boolean;
    onPickupLinePlanApprove(pickupLineId: number): void;
}

type Props = PropsForForm<PickupLinePlanningFormValues, OwnProps>;

const legendAnnotation: ColorAnnotation[] = [
    {color: 'other', label: 'Pracovní den'},
    {color: 'disabled', label: 'Svátek nebo víkend'},
    {color: 'success', label: 'Schválený plán'},
];

const PickupLinePlanningFormBase: FunctionComponent<Props> = props => {
    const {loading, pickupLinesOptions, planningDate, disabled, submitting, onPickupLinePlanApprove} = props;

    return (
        <LoaderWrapper showLoader={loading.includes('planning')}>
            {!planningDate || pickupLinesOptions.map(isEmpty).orTrue()
                ? <NoData
                    title="Nemáte prozatím žádné svozové linky."
                    text='Vytvořit novou svozovou linku je možné v sekci "Svozové linky" -> "Administrace"'
                />
                :   <Fragment>
                    <Legend annotations={legendAnnotation} />
                    <div className={classNames(styles.customTable, styles.scrollbarTop, 'd-fit-content')}>
                        <div className={styles.scrollbarTop}>
                            <DaysColumn
                                date={planningDate}
                                footerPadding
                            />
                            <div className="d-inline-block">
                                {pickupLinesOptions.orElse([]).map(x =>
                                    <PickupLineColumn
                                        pickupLine={x}
                                        key={x.id}
                                        submitting={submitting}
                                        disabled={disabled}
                                        onPickupLinePlanApprove={onPickupLinePlanApprove}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </LoaderWrapper>
    );
};

export const PickupLinePlanningForm = withForm<PickupLinePlanningFormValues, OwnProps, Props>(
    PickupLinePlanningFormBase,
    {
        form: PICKUP_LINE_PLANNING_FORM_NAME,
        initialValues: {
            states: {},
        },
    });
