import React, {FunctionComponent, ReactNode} from 'react';

import styles from './styles.sass';

interface Props {
    children?: ReactNode;
}

const RowActions: FunctionComponent<Props> = props => {
    const {children} = props;

    return (
        <div
            className={`d-flex align-items-center justify-content-end ${styles['table-row-actions']}`}
        >
            {children}
        </div>
    );
};

export default RowActions;
