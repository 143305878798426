import * as t from 'io-ts';

import {PriceClassSchema} from 'types/model/enumeration/PriceClass';
import {OrderDetailsBaseSchema} from './OrderDetailsBase';

const optionalPart = t.partial({
    acceptedBy: t.string,
    internNote: t.string,
});
const requiredPart = t.interface({
    tag: t.literal('OrderDetailsForLaborer'),
    hasMeasurements: t.boolean,
    priceClass: PriceClassSchema,
});

export const OrderDetailsForLaborerSchema = t.exact(t.intersection([
    OrderDetailsBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface OrderDetailsForLaborer extends t.TypeOf<typeof OrderDetailsForLaborerSchema> {}
