import {put, select, cancelled} from 'typed-redux-saga';
import {SagaIterator} from 'redux-saga';
import {putAll, takeLatestF} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';

import {reset} from '../actions';

function* execute(): SagaIterator {
    const {confirmAction, onConfirm} = yield* select((x: StoreState) => x.confirmDialog);

    if (confirmAction.isSome())  {
        yield putAll(confirmAction.value);
    } else {
        yield* cancelled();
    }

    onConfirm.onSome(x => x());

    yield* put(reset());
}

export function* confirmSaga(): SagaIterator {
    yield takeLatestF('confirmDialog/CONFIRM', execute);
}
