import React, {ComponentType, ReactElement, ReactNode} from 'react';
import {Dispatch} from 'redux';
import {ConfigProps, reduxForm} from 'redux-form';
import {InjectedFormProps} from 'redux-form/lib/reduxForm';
import {opt} from 'ts-opt';

import {StoreFormState} from 'app/types/StoreFormState';
import {formDatabase} from 'forms/components/withForm/FormDatabase';

export type ErrorType = string;

type ErrorsRecord = Partial<Record<string, string>>

// TODO: nested fields type checking
type Errors<FormData> = {
    [_ in keyof FormData]?: string | ReactElement<unknown> | ErrorsRecord | ErrorsRecord[]
};

type Warnings<FormData> = Errors<FormData>;

interface ShouldAsyncValidateArgs<FormData> {
    asyncErrors?: Errors<FormData>;
    initialized: boolean;
    trigger: 'blur' | 'submit';
    blurredField?: string;
    pristine: boolean;
    syncValidationPasses: boolean;
}

interface Opts<FormData, P> {
    form: keyof StoreFormState;
    asyncChangeFields?: string[];
    asyncBlurFields?: string[];
    initialValues: FormData;
    enableReinitialize?: boolean;
    destroyOnUnmount?: boolean;
    forceUnregisterOnUnmount?: boolean;
    keepDirtyOnReinitialize?: boolean;

    validate?(values: FormData, props: P): Errors<FormData>;
    warn?(values: FormData, props: P): Warnings<FormData>;

    asyncValidate?(
        values: FormData,
        dispatch: Dispatch,
        props: P & InjectedFormProps<FormData, P, ErrorType>,
        blurredField: string,
    ): Promise<boolean>;

    shouldAsyncValidate?(params: ShouldAsyncValidateArgs<FormData>): boolean;
}

export type PropsForForm<FormData, OwnProps> =
    WithFormInjectedProps<FormData> &
    InjectedFormProps<FormData, OwnProps, ErrorType> & OwnProps;

export interface WithFormInjectedProps<FormData> extends WithFormOuterInjectedProps<FormData> {
    autofillUnsafe(field: string, value: unknown): void;

    untouchUnsafe(field: string): void;

    renderErrors(): ReactNode;
}

export interface WithFormOuterInjectedProps<FormData> {
    onSubmit?(_: FormData): void;
}

type OuterProps<FormData, OwnProps> = WithFormOuterInjectedProps<FormData> & OwnProps;

export const renderFormError = (error?: string): ReactNode => {
    if (!error) { return null; }
    return <div
        className="alert alert-danger"
        role="alert"
    >
        {error}
    </div>;
};

const withForm = <FormData,
    OwnProps,
    P extends WithFormInjectedProps<FormData> & InjectedFormProps<FormData, OwnProps, ErrorType>>
(
    WrappedComponent: ComponentType<P>,
    opts: Opts<FormData, P>,
): ComponentType<OuterProps<FormData, OwnProps>> => {
    const {
        form,
        validate,
        warn,
        asyncValidate,
        asyncChangeFields,
        asyncBlurFields,
        initialValues,
        enableReinitialize,
        destroyOnUnmount = true,
        forceUnregisterOnUnmount = false,
        keepDirtyOnReinitialize = false,
        shouldAsyncValidate,
    } = opts;

    if (!initialValues) { console.error(`Missing initialValues in ${form} form.`); } // eslint-disable-line no-console

    const WithForm = (props: P) => {
        const {error, autofill, untouch} = props;
        const renderErrors = React.useCallback(() => renderFormError(error), [error]);
        const autofillUnsafe = React.useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (field: string, value: unknown) => autofill(field as any, value as any),
            [autofill]
        );
        const untouchUnsafe = React.useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (field: string) => untouch(field as any),
            [untouch]
        );

        const wrapped = <WrappedComponent
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            renderErrors={renderErrors}
            autofillUnsafe={autofillUnsafe}
            untouchUnsafe={untouchUnsafe}
        />;
        opt(form).onSome(formName => formDatabase.register(formName, wrapped));
        return wrapped;
    };

    const rfConfig: ConfigProps<FormData, P> = {
        asyncValidate,
        asyncChangeFields,
        asyncBlurFields,
        form,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate: validate as any, // official types don't seem to support nesting
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        warn: warn as any, // same issue as with validate
        enableReinitialize,
        initialValues,
        destroyOnUnmount,
        forceUnregisterOnUnmount,
        keepDirtyOnReinitialize,
    };
    if (shouldAsyncValidate) { rfConfig.shouldAsyncValidate = shouldAsyncValidate; }
    return reduxForm(rfConfig)(WithForm) as unknown as ComponentType<OuterProps<FormData, OwnProps>>;
};

export default withForm;
