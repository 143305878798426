import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {ExportMeasurementsFillingFormValues} from 'order/types/ExportMeasurementsFillingFormValues';

type Values = ExportMeasurementsFillingFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('dataItemId', 'Datová položka');

    validator.nonEmpty('operatingProcedureId', 'Operační postup');

    return validator.generateErrorsObject();
};
