import React, {Component, ReactNode} from 'react';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import CreateSampleForm from 'order/components/CreateSampleForm';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {Step, Steps, Wizard} from 'wizard/components';
import {SamplesFromSupplyChainFormValues} from '../../types/SamplesFromSupplyChainFormValues';
import {SampleDefaults} from 'sample/types/SampleDefaults';
import {SelectOptions} from 'forms/components/BasicSelect';

import {validate} from './validations';

import styles from './styles.sass';

interface OwnProps {
    samplesFromSupplyChain: CreateSampleFormValues[];
    analysesOptions: SelectOptions<number>;
    milkRoomsOptions?: SelectOptions<number>;
    supplyChainsOptions: SelectOptions<number>;
    selectedSupplyChainIds: number[];
    isCreateSampleFormValid?: boolean;
    lastCreatedSample?: CreateSampleFormValues;
    sampleBarcodeIds: string[];
    canFinishWizard: boolean;
    sampleDefaults?: SampleDefaults;
    formValues?: CreateSampleFormValues;
    enableShowResults: boolean;
    showTraits: boolean;
    allowQCZ: boolean;

    handleUpdateSample(idx: number): void;

    handleSkipSample(idx: number): void;

    handleCompleteWizard(): void;

    handleLoadAnalysesOptions(ids: number[]): void;
}

type Props = PropsForForm<SamplesFromSupplyChainFormValues, OwnProps>;

class SamplesFromSupplyChainForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleUpdateSample,
            handleSkipSample,
            analysesOptions,
            milkRoomsOptions,
            supplyChainsOptions,
            selectedSupplyChainIds,
            samplesFromSupplyChain,
            isCreateSampleFormValid,
            handleCompleteWizard,
            lastCreatedSample,
            sampleBarcodeIds,
            canFinishWizard,
            handleLoadAnalysesOptions,
            sampleDefaults,
            formValues,
            enableShowResults,
            showTraits,
            allowQCZ,
        } = this.props;

        return (
            <Layout.Panel
                caption=""
                className="mt-0"
            >
                <div className={styles.supplyChainSelect}>
                    <Fields.Select<number>
                        name="supplyChainIds"
                        label="Vyberte linky"
                        options={supplyChainsOptions}
                        multi
                    />
                </div>
                {selectedSupplyChainIds && selectedSupplyChainIds.length > 0 &&
                 <Wizard
                     onComplete={handleCompleteWizard}
                     withoutCard
                 >
                     <Steps>{samplesFromSupplyChain.map((s, i) =>
                         <Step
                             key={s.barcode}
                             onNextClick={() => isCreateSampleFormValid && handleUpdateSample(i)}
                             onSkipButtonClick={() => handleSkipSample(i)}
                             isValid={isCreateSampleFormValid}
                             showBackButton
                             showSkipButton
                             disableCompleteButton={samplesFromSupplyChain.length === i + 1 && canFinishWizard
                                 ? false
                                 : !canFinishWizard && !isCreateSampleFormValid}
                         >
                             <CreateSampleForm
                                 formValues={formValues}
                                 analysesOptions={analysesOptions}
                                 milkRoomsOptions={milkRoomsOptions}
                                 supplyChainsOptions={supplyChainsOptions}
                                 lastCreatedSample={lastCreatedSample}
                                 inSupplyChain
                                 initialValues={{...s, ...sampleDefaults}}
                                 sampleBarcodeIds={sampleBarcodeIds}
                                 handleLoadAnalysesOptions={handleLoadAnalysesOptions}
                                 enableShowResults={enableShowResults}
                                 showTraits={showTraits}
                                 allowQCZ={allowQCZ}
                             />
                         </Step>,
                     )}</Steps>
                 </Wizard>
                }
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<SamplesFromSupplyChainFormValues, OwnProps, Props>(SamplesFromSupplyChainForm, {
    form: 'samplesFromSupplyChain',
    validate,
    enableReinitialize: true,
    initialValues: {
        supplyChainIds: [],
        samples: [],
    },
});
