import * as t from 'io-ts';

export const LimitActionSchema = t.keyof({
    KEEP_VALUES: null,
    REPLACE_MIN: null,
    REPLACE_MAX: null,
    REPLACE_BOTH: null,
});

export type LimitAction = t.TypeOf<typeof LimitActionSchema>;
