import * as t from 'io-ts';

import {PageSchema} from '../common/Page';
import {SampleViewSchema} from './SampleView';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    content: t.array(SampleViewSchema),
});

export const SampleDetailsPageSchema = t.exact(t.intersection([PageSchema.type, optionalPart, requiredPart]));

export interface SampleDetailsPage extends t.TypeOf<typeof SampleDetailsPageSchema> {}
