import {Opt} from 'ts-opt';

import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {SampleDefaults} from 'sample/types/SampleDefaults';

const buildSampleDefaults = (customer: CustomerDetails, isDairy: boolean): SampleDefaults => {
    const {showResultsOnWeb, showResultsToBreeders, priceClass} = customer;
    const isNonContractual = priceClass === 'NON_CONTRACTUAL';

    return {
        showResultsOnWeb: isNonContractual ? false : showResultsOnWeb,
        showResultsToBreeders: isNonContractual ? false : showResultsToBreeders,
        harvestType: isDairy ? 'DAIRY' : null,
    };
};

export const getSampleDefaults = (customer: Opt<CustomerDetails>, isDairy: boolean): SampleDefaults | undefined =>
    customer.map(c => buildSampleDefaults(c, isDairy)).orUndef();
