import React, {FunctionComponent} from 'react';
import {classNames, Nullable} from 'favorlogic-utils';

import {DateString} from 'types/generic/DateString';
import withForm, {PropsForForm} from 'forms/components/withForm';
import {PickupLineDairyPlanAnalysis} from 'types/model/pickupLine/PickupLineDairyPlanAnalysis';

import {DaysColumn} from '../DaysColumn';
import {PickupLineDairyPlanFormValues, PICKUP_LINE_DAIRY_PLAN_FORM_NAME} from './pickupLineDairyPlanFormValues';
import {PickupLineDairyPlanColumn} from './pickupLineDairyPlanColumn';

import styles from './styles.sass';

interface OwnProps {
    analysis: PickupLineDairyPlanAnalysis[];
    date: Nullable<DateString>;
    disabled: boolean;
}

type Props = PropsForForm<PickupLineDairyPlanFormValues, OwnProps>;

const PickupLineDairyPlanFormBase: FunctionComponent<Props> = props => {
    const {analysis, date, disabled, submitting} = props;

    if (!date || analysis.length === 0) {
        return null;
    }

    return (
        <div className={classNames(styles.customTable, styles.scrollbarTop, 'd-fit-content')}>
            <div className={styles.scrollbarTop}>
                <DaysColumn
                    date={date}
                    noFooter
                />
                <div className="d-inline-block">
                    {analysis.map(x =>
                        <PickupLineDairyPlanColumn
                            analysis={x}
                            key={x.id}
                            submitting={submitting}
                            disabled={disabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export const PickupLineDairyPlanForm = withForm<PickupLineDairyPlanFormValues, OwnProps, Props>(
    PickupLineDairyPlanFormBase,
    {
        form: PICKUP_LINE_DAIRY_PLAN_FORM_NAME,
        initialValues: {
            states: {},
        },
    });
