import React from 'react';
import {classNames} from 'favorlogic-utils';

import Panel from '../Panel';

import styles from './styles.sass';

interface Props {
    children: React.ReactNode;
    show?: boolean;
    onClickAway?(): void;
}

export class Container extends React.PureComponent<Props> {
    render(): React.ReactNode {
        const {children, show, onClickAway} = this.props;

        return (
            <div className={classNames(styles.container, show && styles.visible)}>
                <div
                    className={styles.modalBackdrop}
                    onClick={onClickAway}
                />
                <Panel className={classNames('w-auto', styles.dialog)} >
                    {children}
                </Panel>
            </div>
        );
    }
}
