import {Analysis} from 'types/model/analyses/Analysis';

import {AnalysisAction} from './actions';

export interface AnalysisState {
    analyses: Analysis[] | null;
    analysis: Analysis | null;
}

export const initialAnalysisState: AnalysisState = {
    analyses: null,
    analysis: null,
};

const reducer = (
    state: AnalysisState | undefined = initialAnalysisState,
    action: AnalysisAction,
): AnalysisState => {
    switch (action.type) {
        case 'analysis/LOAD_ANALYSES_SUCCESS': {
            const {analyses} = action.payload;

            return {...state, analyses};
        }

        case 'analysis/LOAD_ANALYSIS_SUCCESS': {
            const {analysis} = action.payload;

            return {...state, analysis};
        }

        default:
            return state;
    }
};

export default reducer;
