exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-icons-components-SvgIcon-___styles__svg-icon___39aqS {\n  display: inline-block;\n  vertical-align: middle; }\n\n.src-modules-icons-components-SvgIcon-___styles__svg-icon___39aqS svg {\n  width: 1rem;\n  height: 1rem; }\n", ""]);

// exports
exports.locals = {
	"svg-icon": "src-modules-icons-components-SvgIcon-___styles__svg-icon___39aqS"
};