import * as t from 'io-ts';

const optionalPart = t.partial({
    street: t.string,
    city: t.string,
    zip: t.string,
});
const requiredPart = t.interface({});

export const OptionalAddressSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OptionalAddress extends t.TypeOf<typeof OptionalAddressSchema> {}
