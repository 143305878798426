import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {UpdateOperatingProcedure} from 'types/model/operatingProcedures/UpdateOperatingProcedure';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {
    createOperatingProcedureError,
    createOperatingProcedureSuccess,
} from '../actions';
import Api from '../api';
import {OperatingProcedureFormValues} from '../types/OperatingProcedureFormValues';

const title = 'Vytvoření operačního postupu';
const operatingProcedureForm = 'operatingProcedure';

export function prepareCreateOperatingProcedure(formValues: OperatingProcedureFormValues): UpdateOperatingProcedure {
    return {
        name: formValues.name,
        description: formValues.description,
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(createOperatingProcedureError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF(operatingProcedureForm, extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně vytvořen.'));
    yield* put(createOperatingProcedureSuccess());
    yield* put(routerActions.goBack());
}

function* execute(): SagaIterator {
    const formValues = (yield* select(formValuesF(operatingProcedureForm))).orCrash('Missing form values.');
    const response = yield* call(Api.createOperatingProcedure, prepareCreateOperatingProcedure(formValues));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* createOperatingProcedureSaga(): SagaIterator {
    yield takeLatestF('operatingProcedure/CREATE_OPERATING_PROCEDURE', execute);
}
