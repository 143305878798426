import {ErrorsFromBE} from 'favorlogic-utils';

import {UpdateSamplesBy} from 'sample/screens/List';
import {SamplesParams} from 'sample/types/SampleQueryParams';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {SampleDetailsPage} from 'types/model/samples/SampleDetailsPage';
import {UpdateSampleFormValues} from './types/UpdateSampleFormValues';
import {SamplesByOrderParams} from 'sample/types/SamplesByOrderParams';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {DisableSampleParams} from 'sample/types/DisableSampleParams';

// --- Load Samples

export interface LoadSamplesAction {
    type: 'sample/LOAD_SAMPLES';
    payload: SamplesParams;
}

export const loadSamples = (params: SamplesParams): LoadSamplesAction => ({
    type: 'sample/LOAD_SAMPLES',
    payload: params,
});

export interface LoadSamplesSuccessAction {
    payload: SampleDetailsPage;
    type: 'sample/LOAD_SAMPLES_SUCCESS';
}

export const loadSamplesSuccess = (payload: SampleDetailsPage): LoadSamplesSuccessAction => ({
    type: 'sample/LOAD_SAMPLES_SUCCESS',
    payload,
});

export interface LoadSamplesErrorAction {
    type: 'sample/LOAD_SAMPLES_ERROR';
    payload: ErrorsFromBE;
}

export const loadSamplesError = (err: ErrorsFromBE): LoadSamplesErrorAction => ({
    type: 'sample/LOAD_SAMPLES_ERROR',
    payload: err,
});

export const loadSamplesByOrder = (orderId:  number, params?: SamplesByOrderParams) => ({
    type: 'sample/LOAD_SAMPLES_BY_ORDER',
    payload: {
        orderId,
        params,
    },
}) as const;
export type LoadSamplesByOrderAction = ReturnType<typeof loadSamplesByOrder>;

export const loadSamplesByOrderSuccess = (params: OrderSampleView[]) => ({
    type: 'sample/LOAD_SAMPLES_BY_ORDER_SUCCESS',
    payload: params,
}) as const;
export type LoadSamplesByOrderSuccessAction = ReturnType<typeof loadSamplesByOrderSuccess>;

export const resetSamplesByOrder = () => ({
    type: 'sample/RESET_SAMPLE_BY_ORDER',
}) as const;
export type ResetSamplesByOrderAction = ReturnType<typeof resetSamplesByOrder>;


// --- Load Sample Details

export interface LoadSampleDetailsAction {
    type: 'sample/LOAD_SAMPLE_DETAILS';
    payload: number;
}

export const loadSampleDetails = (payload: number): LoadSampleDetailsAction => ({
    type: 'sample/LOAD_SAMPLE_DETAILS',
    payload,
});

export interface LoadSampleDetailsSuccessAction {
    payload: SampleDetails;
    type: 'sample/LOAD_SAMPLE_DETAILS_SUCCESS';
}

export const loadSampleDetailsSuccess = (payload: SampleDetails): LoadSampleDetailsSuccessAction => ({
    type: 'sample/LOAD_SAMPLE_DETAILS_SUCCESS',
    payload,
});

export interface LoadSampleDetailsErrorAction {
    type: 'sample/LOAD_SAMPLE_DETAILS_ERROR';
    payload: ErrorsFromBE;
}

export const loadSampleDetailsError = (err: ErrorsFromBE): LoadSampleDetailsErrorAction => ({
    type: 'sample/LOAD_SAMPLE_DETAILS_ERROR',
    payload: err,
});

// --- Accept Sample
export interface AcceptSampleAction {
    type: 'sample/ACCEPT_SAMPLE';
    payload: {
        sampleId: number,
        isLastAccepted: boolean,
    };
}

export const acceptSample = (sampleId: number, isLastAccepted: boolean): AcceptSampleAction => ({
    type: 'sample/ACCEPT_SAMPLE',
    payload: {sampleId, isLastAccepted},
});

// --- Update Sample
export interface UpdateSampleAction {
    type: 'sample/UPDATE_SAMPLE';
    payload: {
        sampleId: number,
        redirectUrl?: string,
    };
}

export const updateSample = (sampleId: number, redirectUrl?: string): UpdateSampleAction => ({
    type: 'sample/UPDATE_SAMPLE',
    payload: {
        sampleId,
        redirectUrl,
    },
});

export interface UpdateSampleSuccessAction {
    type: 'sample/UPDATE_SAMPLE_SUCCESS';
}

export const updateSampleSuccess = (): UpdateSampleSuccessAction => ({
    type: 'sample/UPDATE_SAMPLE_SUCCESS',
});

export interface UpdateSampleErrorAction {
    type: 'sample/UPDATE_SAMPLE_ERROR';
    payload: ErrorsFromBE;
}

export const updateSampleError = (err: ErrorsFromBE): UpdateSampleErrorAction => ({
    type: 'sample/UPDATE_SAMPLE_ERROR',
    payload: err,
});

// --- Update Samples

export interface UpdateSamplesSelectionAction {
    payload: number[];
    type: 'sample/UPDATE_SAMPLES_SELECTION';
}

export const updateSamplesSelection = (sampleIds: number[]): UpdateSamplesSelectionAction => ({
    type: 'sample/UPDATE_SAMPLES_SELECTION',
    payload: sampleIds,
});

export interface UpdateSamplesByFilterAction {
    payload: SamplesParams;
    type: 'sample/UPDATE_SAMPLES_BY_FILTER';
}

export const updateSamplesByFilter = (filter: SamplesParams): UpdateSamplesByFilterAction => ({
    type: 'sample/UPDATE_SAMPLES_BY_FILTER',
    payload: filter,
});

export interface UpdateSamplesSuccessAction {
    type: 'sample/UPDATE_SAMPLES_SUCCESS';
}

export const updateSamplesSuccess = (): UpdateSamplesSuccessAction => ({
    type: 'sample/UPDATE_SAMPLES_SUCCESS',
});

export interface UpdateSamplesErrorAction {
    type: 'sample/UPDATE_SAMPLES_ERROR';
    payload: ErrorsFromBE;
}

export const updateSamplesError = (err: ErrorsFromBE): UpdateSamplesErrorAction => ({
    type: 'sample/UPDATE_SAMPLES_ERROR',
    payload: err,
});


// --- Delete sample

export interface DeleteSamplePayload {
    sampleId: number;
    orderId?: number;
}

export interface DeleteSampleAction {
    type: 'sample/DELETE_SAMPLE';
    payload: DeleteSamplePayload;
}

export const deleteSample = (payload: DeleteSamplePayload): DeleteSampleAction => ({
    type: 'sample/DELETE_SAMPLE',
    payload,
});

export interface DeleteSampleSuccessAction {
    type: 'sample/DELETE_SAMPLE_SUCCESS';
}

export const deleteSampleSuccess = (): DeleteSampleSuccessAction => ({
    type: 'sample/DELETE_SAMPLE_SUCCESS',
});

export interface DeleteSampleErrorAction {
    type: 'sample/DELETE_SAMPLE_ERROR';
    payload: ErrorsFromBE;
}

export const deleteSampleError = (err: ErrorsFromBE): DeleteSampleErrorAction => ({
    type: 'sample/DELETE_SAMPLE_ERROR',
    payload: err,
});

// --- Prefill Update Sample Details

export interface PrefillUpdateSampleFormAction {
    type: 'sample/PREFILL_UPDATE_SAMPLE_FORM';
    payload: UpdateSampleFormValues;
}

export const prefillUpdateSampleForm =
    (payload: UpdateSampleFormValues): PrefillUpdateSampleFormAction => ({
        type: 'sample/PREFILL_UPDATE_SAMPLE_FORM',
        payload,
    });

// ---

export interface CreateSampleInOrderAction {
    type: 'sample/CREATE_SAMPLE_IN_ORDER';
    payload: number;
}

export const createSampleInOrder = (payload: number): CreateSampleInOrderAction => ({
    type: 'sample/CREATE_SAMPLE_IN_ORDER',
    payload,
});

export interface CreateSampleInOrderSuccessAction {
    type: 'sample/CREATE_SAMPLE_IN_ORDER_SUCCESS';
}

export const createSampleInOrderSuccess = (): CreateSampleInOrderSuccessAction => ({
    type: 'sample/CREATE_SAMPLE_IN_ORDER_SUCCESS',
});


export interface CreateSampleInOrderErrorAction {
    type: 'sample/CREATE_SAMPLE_IN_ORDER_ERROR';
    payload: ErrorsFromBE;
}

export const createSampleInOrderError = (err: ErrorsFromBE): CreateSampleInOrderErrorAction => ({
    type: 'sample/CREATE_SAMPLE_IN_ORDER_ERROR',
    payload: err,
});

// ---

export interface SetSamplesParamsAction {
    payload: SamplesParams;
    type: 'sample/SET_SAMPLES_PARAMS';
}

export const setSamplesParams = (params: SamplesParams): SetSamplesParamsAction => ({
    type: 'sample/SET_SAMPLES_PARAMS',
    payload: params,
});

// ---

export interface ToggleSampleSelectAction {
    payload: number;
    type: 'sample/TOGGLE_SAMPLE_SELECT';
}

export const toggleSampleSelect = (sampleId: number): ToggleSampleSelectAction => ({
    type: 'sample/TOGGLE_SAMPLE_SELECT',
    payload: sampleId,
});

// ---

export interface SetUpdateSamplesByParamsAction {
    type: 'sample/SET_UPDATE_SAMPLES_BY';
    payload: UpdateSamplesBy;
}

export const setUpdateSamplesBy = (type: UpdateSamplesBy): SetUpdateSamplesByParamsAction => ({
    type: 'sample/SET_UPDATE_SAMPLES_BY',
    payload: type,
});

export const disableSample = (params: DisableSampleParams) => ({
    type: 'sample/DISABLE_SAMPLE',
    payload: params,
}) as const;
export type DisableSampleAction = ReturnType<typeof disableSample>;
// ---

export type SampleAction =
    LoadSamplesAction | LoadSamplesSuccessAction | LoadSamplesErrorAction |
    LoadSamplesByOrderAction | LoadSamplesByOrderSuccessAction | ResetSamplesByOrderAction |
    LoadSampleDetailsAction | LoadSampleDetailsSuccessAction | LoadSampleDetailsErrorAction |
    DeleteSampleAction | DeleteSampleSuccessAction | DeleteSampleErrorAction |
    UpdateSampleAction | AcceptSampleAction | UpdateSampleSuccessAction | UpdateSampleErrorAction |
    PrefillUpdateSampleFormAction | SetSamplesParamsAction |
    UpdateSamplesSelectionAction | UpdateSamplesByFilterAction | UpdateSamplesSuccessAction | UpdateSamplesErrorAction |
    ToggleSampleSelectAction |
    SetUpdateSamplesByParamsAction | DisableSampleAction
    ;
