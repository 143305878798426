import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';
import {showSuccess, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {resetSamplesSelection} from '../../actions';
import {reloadMeasurements} from './measurementsSagaUtils';

const title = 'Schválení rozborů';

export function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

export function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Rozbory byly úspěšně schváleny'));
    yield* put(resetSamplesSelection());
    yield* call(reloadMeasurements);
}
