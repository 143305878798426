import {UpdatePickupLineOption} from 'types/model/pickupLine/UpdatePickupLineOption';

import {PickupLinePlanningFormValues} from '../components/PickupLinePlanningForm/pickupLinePlanningFormValues';

export const parsePlanningFormValues = (values: PickupLinePlanningFormValues): UpdatePickupLineOption[] => {
    const data: UpdatePickupLineOption[] = [];

    Object.values(values.states).forEach(
        x => {
            if (x) {
                Object.entries(x).forEach(
                    ([key, y]) => {
                        if (y) {
                            data.push({id: Number(key), state: y});
                        }
                    }
                );
            }
        }
    );

    return data;
};
