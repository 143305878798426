import * as t from 'io-ts';

import {PageSchema} from '../common/Page';
import {OrderViewSchema} from './OrderView';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    content: t.array(OrderViewSchema),
});

export const OrdersPageSchema = t.exact(t.intersection([PageSchema.type, optionalPart, requiredPart]));

export interface OrdersPage extends t.TypeOf<typeof OrdersPageSchema> {}
