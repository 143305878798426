import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {SampleCreationType} from 'order/types/SampleCreationType';
import {Components as Buttons} from 'buttons';
import {importSample, selectSampleCreationType} from 'order/actions';

interface InnerProps {
    handleSelectSampleCreationType(type: SampleCreationType): void;

    handleSampleImport(file: File): void;
}
interface OuterProps {
}

type Props = InnerProps & OuterProps;

class SampleCreationTypeButtons extends Component<Props> {
    render(): ReactNode {
        const {
            handleSelectSampleCreationType,
            handleSampleImport,
        } = this.props;

        return (
            <div className="d-md-flex justify-content-center flex-md-row text-center">
                <div className="mr-2 mt-2">
                    <Buttons.UploadButton
                        id="uploadSample"
                        onUpload={handleSampleImport}
                        label="Import vzorků"
                    />
                </div>
                <div className="mr-2 mt-2">
                    <Buttons.Button
                        type="button"
                        importance="primary"
                        onClick={() => handleSelectSampleCreationType('FROM_SUPPLY_CHAIN')}
                    >
                        Přidat z linky
                    </Buttons.Button>
                </div>
                <div className="mr-2 mt-2">
                    <Buttons.Button
                        type="button"
                        importance="primary"
                        onClick={() => handleSelectSampleCreationType('MANUALLY')}
                    >
                        Přidat ručně
                    </Buttons.Button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (_: StoreState): Partial<Props> => ({
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleSampleImport: (file: File) => dispatch(importSample(file)),
    handleSelectSampleCreationType: (type: SampleCreationType) => dispatch(selectSampleCreationType(type)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(SampleCreationTypeButtons) as ComponentClass<OuterProps>;
