import {optEmptyArray} from 'ts-opt';
import {some, isEmpty} from 'lodash/fp';

import {DairyValidator} from 'utils/DairyValidator';
import {Errors, Warnings} from 'utils/Validator';
import {DataItemMeasurementsFormValues} from 'measurement/type/DataItemMeasurementsFormValues';
import {DataItemMeasurementFormValues} from 'measurement/type/DataItemMeasurementFormValues';

type Values = DataItemMeasurementsFormValues;
type MeasurementErrors = Partial<Record<keyof DataItemMeasurementFormValues, string>>;

const validateMeasurement = (values: DataItemMeasurementFormValues): MeasurementErrors => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('result', 'Výsledek');
    validator.nonEmpty('dilution', 'Ředění');
    validator.nonEmpty('procedureId', 'Operační postup');

    return validator.generateErrorsObject() as MeasurementErrors;
};

export const isMeasurementValid = (x: DataItemMeasurementFormValues): boolean => isEmpty(validateMeasurement(x));
export const anyMeasurementValid = (xs: DataItemMeasurementFormValues[]): boolean => some(isMeasurementValid, xs);

const validateMeasurements = (values: DataItemMeasurementFormValues[] | undefined): MeasurementErrors[] | undefined => {
    const errors: MeasurementErrors[] = [];

    (values || []).forEach((value, index) => {
        const itemErrors = validateMeasurement(value);

        if (itemErrors) {
            errors[index] = itemErrors;
        }
    });

    return optEmptyArray(errors).orUndef();
};

const validateBasics = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('measured', 'Datum stanovení');
    validator.nonEmpty('measuredById', 'Změřil');
    validator.nonEmpty('dataItem', 'Datová položka');
    validator.nonEmpty('measurements', 'Měření');

    return validator.generateErrorsObject();
};

export const validate = (values: Values): Errors<Values> => {
    const errors = validateBasics(values);

    if (!errors.measurements && !anyMeasurementValid(values.measurements)) {
        errors.measurements = {_error: 'Alespoň jedno měření musí být vyplněné'};
    }

    return errors;
};

export const warn = (values: Values): Warnings<Values> => {
    const warnings: Warnings<Values> = {};

    if (!anyMeasurementValid(values.measurements)) {
        warnings.measurements = validateMeasurements(values.measurements);
    }

    return warnings;
};
