import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {DeviceDataItem} from 'types/model/devices/DeviceDataItem';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {DeviceDataItemFormValues} from '../types/DeviceDataItemValues';
import {UpdateDeviceAction, updateDeviceError, updateDeviceSuccess} from '../actions';
import Api from '../api';

const title = 'Uprava přístroje';
const deviceForm = 'device';

export function getDeviceDataItems(formValues: DeviceDataItemFormValues[]): DeviceDataItem[] {
    const dataItems: DeviceDataItem[] = [];

    formValues.forEach(dataItem => {
        if (dataItem.dataItemId) {
            dataItems.push({
                id: dataItem.id,
                dataItemId: opt(dataItem.dataItemId).orCrash('missing data item id'),
                minimum: opt(dataItem.minimum).map(x => parseInt(x, 10)).orUndef(),
                maximum: opt(dataItem.maximum).map(x => parseInt(x, 10)).orUndef(),
                action: dataItem.action,
            });
        }
    });

    return dataItems;
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateDeviceError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF(deviceForm, extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně upraven.'));
    yield* put(updateDeviceSuccess());
}

function* execute({payload: {id}}: UpdateDeviceAction): SagaIterator {
    const formValues = (yield* select(formValuesF(deviceForm))).orCrash('Missing form values.');
    const response = yield* call(Api.updateDevice, id, getDeviceDataItems(formValues.dataItems));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateDeviceSaga(): SagaIterator {
    yield takeLatestF('device/UPDATE_DEVICE', execute);
}
