import {SagaIterator} from 'redux-saga';
import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {GetPickupLineRequestsExportAction} from '../actions';

const title = 'Export přehledu svozové linky';

function* execute ({payload: {pickupLineId, yearMonth}}: GetPickupLineRequestsExportAction): SagaIterator {
    const response = yield* call(PickupLineApi.getPickupLineRequestsExport, yearMonth, pickupLineId);

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* getPickupLineRequestsExportSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_PICKUP_LINE_REQUESTS_EXPORT', execute);
}
