import * as t from 'io-ts';
import {includes} from 'lodash/fp';

export const PickupLineOptionStateSchema = t.keyof({
    PREFERRED: null,
    ALTERNATIVE: null,
    FORBIDDEN: null,
});

export const PICKUP_LINE_OPTIONS_STATE_NAMES = ['PREFERRED', 'ALTERNATIVE', 'FORBIDDEN'] as const;
export type PickupLineOptionState = typeof PICKUP_LINE_OPTIONS_STATE_NAMES[number];

export const isValidOrderState = (x: string): x is PickupLineOptionState =>
    includes(x, PICKUP_LINE_OPTIONS_STATE_NAMES);
