import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {showSuccess, takeLatestF} from 'favorlogic-utils';

import {UpdateOrder} from 'types/model/orders/UpdateOrder';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';
import {UpdateOrderBase} from 'types/model/orders/UpdateOrderBase';

import {UpdateOrderAction, loadOrder} from '../actions';
import Api from '../api';
import {UpdateOrderFormValues} from '../types/UpdateOrderFormValues';

const title = 'Aktualizace objednávky';

function* handleSuccessResponse(orderId: number): SagaIterator {
    yield* put(showSuccess(title, 'Změny uloženy'));
    yield* put(loadOrder(orderId));
}

export function buildUpdateOrder(values: UpdateOrderFormValues, isLaborer: boolean): UpdateOrder {
    const deliveryType = opt(values.deliveryType).orCrash('missing delivery type');
    const updateOrderBase: UpdateOrderBase = {
        deliveryType,
        customerDetails: values.customerDetails,
        note: values.note,
    };

    return isLaborer
        ? {
            ...updateOrderBase,
            tag: 'UpdateOrderByLaborer',
            internNote: values.internNote,
            state: opt(values.state).orUndef(),
        }
        : {
            ...updateOrderBase,
            tag: 'UpdateOrderByCustomer',
        };
}

function* getUpdateOrder(): SagaIterator<UpdateOrder> {
    const values = (yield* select(formValuesF('updateOrder')))
        .orCrash('missing form values');
    const user = (yield* select((state: StoreState) => state.user.currentUser))
        .orCrash('missing current user');
    const isLaborer = user.role === 'LABORER' || user.role === 'ADMIN';

    return buildUpdateOrder(values, isLaborer);
}

function* execute({payload: {orderId}}: UpdateOrderAction): SagaIterator {
    const updateOrder = yield* call(getUpdateOrder);
    const response = yield* call(Api.updateOrder, orderId, updateOrder);

    yield* response.isSuccess
        ? call(handleSuccessResponse, orderId)
        : call(handleResponseError, response, title);
}

export function* updateOrderSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_ORDER', execute);
}
