import {none, opt,  Opt} from 'ts-opt';

import {ConfirmDialogActionType} from './types/ConfirmDialogActionType';
import {ConfirmDialogAction} from './actions';

type OnConfirmHandler = () => void;

export const initialConfirmDialogState: ConfirmDialogState = {
    opened: false,
    title: '',
    text: '',
    confirmAction: none,
    cancelAction: none,
    onConfirm: none,
};

export interface ConfirmDialogState {
    opened: boolean;
    title: string;
    text: string;
    confirmAction: Opt<ConfirmDialogActionType>;
    cancelAction: Opt<ConfirmDialogActionType>;
    onConfirm: Opt<OnConfirmHandler>;
}

const reducer = (state: ConfirmDialogState = initialConfirmDialogState,
    action: ConfirmDialogAction): ConfirmDialogState => {
    switch (action.type) {
        case 'confirmDialog/SHOW': {
            const p = action.payload;
            return {
                ...state,
                text: p.text,
                title: p.title || '',
                confirmAction: opt(p.confirmAction),
                cancelAction: opt(p.cancelAction),
                opened: true,
                onConfirm: opt(p.onConfirm),
            };
        }
        case 'confirmDialog/RESET': {
            return initialConfirmDialogState;
        }
        default:
            return state;
    }
};

export default reducer;
