import * as t from 'io-ts';

export const WRITE_PICKUP_LINE_MAX_LENGTH = 255;

const optionalPart = t.partial({
    id: t.number,
});

const requiredPart = t.interface({
    name: t.string,
});

export const PickupLineValidationSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface PickupLineValidation extends t.TypeOf<typeof PickupLineValidationSchema> {}
