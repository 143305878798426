import * as t from 'io-ts';

import {SampleDeliveryTypeSchema} from '../enumeration/SampleDeliveryType';
import {CustomerSchema} from 'types/model/customers/Customer';

const optionalPart = t.partial({
    note: t.string,
});
const requiredPart = t.interface({
    deliveryType: SampleDeliveryTypeSchema,
    customerDetails: CustomerSchema,
});

export const UpdateOrderBaseSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateOrderBase extends t.TypeOf<typeof UpdateOrderBaseSchema> {}
