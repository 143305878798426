import React, {PureComponent, ReactNode} from 'react';
import {Form} from 'redux-form';
import {Opt, opt} from 'ts-opt';
import {memoize, find, noop} from 'lodash/fp';
import {crash, isArray, validateKeyGen, classNames} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {SelectedValue, SelectOptions} from 'forms/components/BasicSelect';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {DataItem} from 'types/model/dataItems/DataItem';

import {
    SampleMeasurementFormValues,
    SampleMeasurementResult,
} from '../../type/SampleMeasurementFormValues';
import {measurementUnitNP} from '../../constants';
import {genSampleOptions, genSampleBarcodeOptions} from '../../utils/genSampleOptions';
import {MeasurementResult} from './MeasurementResult';

import {validate} from './validations';

const validateKey = validateKeyGen<SampleMeasurementFormValues>();

export const measurementInitialValues: SampleMeasurementFormValues = {
    measured: null,
    dataItem: null,
    measuredById: null,
    barcode: null,
    measurement: null,
    continueToNext: false,
};

interface OwnProps {
    dataItemOptions: SelectOptions<DataItem>;
    userOptions: SelectOptions<number>;
    procedureOptions: SelectOptions<number>;
    samples: SampleSearch[] | null;
    selectedDataItem: DataItem | null;
    selectedBarcode: string | null;

    onSampleSearch(dataItemId: number | null): void;
}

export type Props = PropsForForm<SampleMeasurementFormValues, OwnProps>;

class SampleMeasurementsForm extends PureComponent<Props> {
    private get isNP(): boolean {
        const {selectedDataItem} = this.props;

        return opt(selectedDataItem).map(x => x.measurementUnit === measurementUnitNP).orFalse();
    }

    private get isDataItemNotSelected(): boolean {
        const {selectedDataItem} = this.props;

        return !selectedDataItem;
    }

    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
            dataItemOptions,
            userOptions,
            procedureOptions,
            samples,
            valid,
            selectedBarcode,
        } = this.props;

        return (
            <Form onSubmit={noop}>
                <Layout.Panel>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.DateTime
                                name={validateKey('measured')}
                                mode="DATE"
                                label="Datum stanovení*"
                            />
                        </div>

                        <div className="col-12 col-md-6">
                            <Fields.Select<number>
                                name={validateKey('measuredById')}
                                label="Naměřil*"
                                options={userOptions}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Select<DataItem>
                                name={validateKey('dataItem')}
                                label="Datová položka*"
                                options={dataItemOptions}
                                onFieldChange={this.onDataItemChange}
                            />
                        </div>

                        <div className="col-12 col-md-6">
                            <Fields.Select<string>
                                name={validateKey('barcode')}
                                label="Vzorek*"
                                options={this.getSampleBarcodeOptions(samples)}
                                disabled={this.isDataItemNotSelected}
                                onFieldChange={this.onSelectSampleBarcode}
                            />
                        </div>
                    </div>
                </Layout.Panel>

                <Layout.Panel>
                    {!selectedBarcode && <div className="row mt-2">
                        <div className="w-100 text-center text-muted">
                            Vyberte vzorek
                        </div>
                    </div>}

                    <div className={classNames(!selectedBarcode && 'd-none')}>
                        <MeasurementResult
                            name={validateKey('measurement')}
                            sampleOptions={this.getSampleOptions(samples)}
                            procedureOptions={procedureOptions}
                            isNP={this.isNP}
                        />
                    </div>
                </Layout.Panel>

                <div className="row">
                    <div className="col-6 mt-4">
                        <Buttons.Button
                            type="submit"
                            importance="primary"
                            disabled={!valid || submitting}
                            fullWidth
                            onClick={handleSubmit(this.createMeasurement)}
                        >
                            Vytvořit měření
                        </Buttons.Button>
                    </div>
                    <div className="col-6 mt-4">
                        <Buttons.Button
                            type="submit"
                            importance="primary"
                            disabled={!valid || submitting}
                            fullWidth
                            onClick={handleSubmit(this.createMeasurementAndContinue)}
                        >
                            Vytvořit a pokračovat
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }

    private createMeasurement = (values: SampleMeasurementFormValues): void => {
        const {change, onSubmit} = this.props;

        change('continueToNext', false);
        onSubmit?.({...values, continueToNext: false});
    };

    private createMeasurementAndContinue = (values: SampleMeasurementFormValues): void => {
        const {change, onSubmit} = this.props;

        change('continueToNext', true);
        onSubmit?.({...values, continueToNext: true});
    };

    private getSampleBarcodeOptions = memoize(genSampleBarcodeOptions);
    private getSampleOptions = memoize(genSampleOptions);

    private onDataItemChange = (valueOpt: Opt<SelectedValue<DataItem>>) => {
        const {onSampleSearch} = this.props;
        const value = valueOpt.orNull();

        if (isArray(value)) {
            return crash('unexpected array');
        }

        onSampleSearch(value ? value.id : null);
        this.resetBarcode();
    }

    private resetBarcode(): void {
        const {change, untouch} = this.props;

        change('barcode', null);
        untouch('barcode');
    }

    private onSelectSampleBarcode = (valueOpt: Opt<SelectedValue<string>>) => {
        const barcode = valueOpt.orNull();

        if (isArray(barcode)) {
            return crash('unexpected array');
        }

        this.prefillMeasurementByBarcode(barcode);
    }

    private findSampleByBarcode(barcode: string | null): SampleSearch | null {
        const {samples} = this.props;

        return find((x: SampleSearch) => x.barcode === barcode)(samples) || null;
    }

    private untouchResult(key: keyof SampleMeasurementResult): void {
        const {untouchUnsafe} = this.props;

        untouchUnsafe(`measurement.${key}`);
    }

    private prefillMeasurementByBarcode(barcode: string | null): void {
        const {autofill} = this.props;
        const sample = this.findSampleByBarcode(barcode);
        const prefill = sample ? this.buildInitialMeasurement(sample) : null;

        this.untouchResult('dilution');
        this.untouchResult('result');
        this.untouchResult('procedureId');
        autofill('measurement', prefill);
    }

    private getDefaultProcedure(): number | null {
        const {selectedDataItem} = this.props;

        return opt(selectedDataItem)
            .map(x => x.procedures)
            .chainToOpt(find(x => x.isDefault))
            .map(x => x.procedureId)
            .orNull();
    }

    private getDefaultDilution(): string {
        const {selectedDataItem} = this.props;

        return opt(selectedDataItem)
            .map(x => x.dilution)
            .orElse(0)
            .toString();
    }

    private buildInitialMeasurement(sample: SampleSearch): SampleMeasurementResult {
        return {
            dilution: this.getDefaultDilution(),
            sampleId: sample.id,
            result: this.isNP ? '0' : null,
            procedureId: this.getDefaultProcedure(),
        };
    }
}

export default Forms.withForm<SampleMeasurementFormValues, OwnProps, Props>(SampleMeasurementsForm, {
    form: 'sampleMeasurement',
    validate,
    initialValues: measurementInitialValues,
});
