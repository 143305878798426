import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import withUser from 'user/components/withUser';
import {loadOperatingProcedures} from '../actions';
import OperatingProceduresTable from '../components/OperatingProceduresTable';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

interface OuterProps {}

interface StateProps {
    operatingProcedures: OperatingProcedure[] | null;
}

interface DispatchProps {
    handleLoadOperatingProcedures(): void;
}

type Props =  OuterProps & StateProps & DispatchProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {handleLoadOperatingProcedures} = this.props;

        handleLoadOperatingProcedures();
    }

    render(): ReactNode {
        const {operatingProcedures} = this.props;

        if (operatingProcedures && isEmpty(operatingProcedures)) {
            return this.renderNoOperatingProcedures();
        }

        return (
            <Layout.ListPage
                title="Operační postupy"
                toolbar={this.renderToolbar()}
            >
                <OperatingProceduresTable
                    operatingProcedures={operatingProcedures}
                />
            </Layout.ListPage>
        );
    }

    private renderToolbar(): ReactNode {
        return (
            <Buttons.Group>
                <Buttons.RightIconButton
                    label="Nový"
                    to="/administration/operating-procedures/new"
                    icon="ADD"
                />
            </Buttons.Group>
        );
    }

    private renderNoOperatingProcedures(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nemáte prozatím žádné operační postupy.
                    </div>
                    <div>
                        Chcete vytvořit nový postup? Klikněte na
                        <Buttons.RightIconButton
                            label="Nový"
                            to="/administration/operating-procedures/new"
                            className="m-2"
                            icon="ADD"
                        />
                    </div>
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    operatingProcedures: state.operatingProcedure.operatingProcedures,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadOperatingProcedures: () => dispatch(loadOperatingProcedures()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
