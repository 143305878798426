import * as t from 'io-ts';

import {UpdatePasswordSchema} from '../customers/UpdatePassword';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    firstName: t.string,
    lastName: t.string,
    username: t.string,
});

export const CreateUserSchema = t.exact(t.intersection([UpdatePasswordSchema.type, optionalPart, requiredPart]));

export interface CreateUser extends t.TypeOf<typeof CreateUserSchema> {}
