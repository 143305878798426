import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt} from 'ts-opt';

import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {importMeasurements} from 'measurement/actions';
import {SelectOption, SelectOptions} from 'forms/components/BasicSelect';
import {StoreState} from 'app/types/StoreState';
import {UserDetails} from 'types/model/users/UserDetails';
import {loadLaborers} from 'user/actions';
import withUser from 'user/components/withUser';
import {isValidF} from 'utils/formHelpers';
import ImportMeasurementForm from '../components/ImportMeasurementForm';

interface OuterProps {}

interface InnerProps {
    isImportFormValid: boolean;
    laborers: Opt<UserDetails[]>;

    handleLoadLaborers(): void;
    handleImport(file: File): void;
}

type Props = InnerProps & OuterProps;

class Import extends Component<Props> {
    private get laborersOptions(): SelectOptions<number> {
        const {laborers} = this.props;

        return laborers.map(x => x.map(
            (l: UserDetails): SelectOption<number> => ({
                label: `${l.firstName} ${l.lastName}`,
                value: l.id,
            }),
        )).orElse([]);
    }

    componentDidMount(): void {
        const {handleLoadLaborers} = this.props;

        handleLoadLaborers();
    }

    render(): ReactNode {
        const {handleImport, isImportFormValid} = this.props;

        return (
            <Layout.ItemPage
                title="Import měření z přístroje"
                backLabel="Měření"
            >
                <Layout.Panel>
                    <ImportMeasurementForm
                        operatorIdOptions={this.laborersOptions}
                    />
                    <div className="text-right">
                        <Buttons.UploadButton
                            id="importMeasurement"
                            onUpload={handleImport}
                            label="Importovat"
                            disabled={!isImportFormValid}
                        />
                    </div>
                </Layout.Panel>
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isImportFormValid: isValidF('importMeasurement')(state),
    laborers: state.user.laborers,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadLaborers: () => dispatch(loadLaborers()),
    handleImport: (file: File) => dispatch(importMeasurements(file)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Import) as ComponentClass<OuterProps>;
