import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';

import {AnalysisFormValues} from './types/AnalysisFormValues';

type AnalysisState = GenericFormState<AnalysisFormValues>;

const formReducer: CFormReducer = {
    analysis: (state: AnalysisState, action: Action): AnalysisState => {
        switch (action.type) {
            case 'analysis/PREFILL_ANALYSIS_FORM': {
                const {values} = action.payload;

                return {...state, values};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
