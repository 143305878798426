import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import React, {FocusEvent, FormEvent, ReactNode} from 'react';
import {opt} from 'ts-opt';

import {classNames} from 'favorlogic-utils';
import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

type FieldValue = string;

type OwnProps = {
    radioValue: string,
}

export type RadioOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type RadioInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

// TODO: styles and feedback icon for group of radios
class Radio extends ComponentForWrapping<RadioInnerProps, FieldValue> {
    render(): ReactNode {
        const {
            className,
            label,
            helpText,
            input: {name, value: inputValue},
            meta,
            disabled,
            radioValue,
        } = this.props;

        const {asyncValidating, submitting} = meta;

        const classes = classNames('form-check', className);
        const id = `${name}--${radioValue}`;

        const handleChange = (_evt: FormEvent<HTMLInputElement>) => {
            this.handleChange(opt(radioValue));
        };

        const handleBlur = (_evt: FocusEvent<HTMLInputElement>) => {
            this.handleBlur(opt(inputValue));
        };

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>

                <input
                    type="radio"
                    className="form-check-input"
                    id={id}
                    disabled={asyncValidating || submitting || disabled}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={radioValue}
                    checked={radioValue === inputValue}
                />

                <Label
                    htmlFor={id}
                    className="form-check-label"
                >
                    {label}
                </Label>

                <div className="check-radio" />
            </div>
        );
    }
}

export default Radio;
