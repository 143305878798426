import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt, Opt, optEmptyArray} from 'ts-opt';
import {join, flow, map, flatten, isEmpty} from 'lodash/fp';
import {showSuccess, SuccessResponse, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {MeasurementImportResults} from 'types/model/measurements/MeasurementImportResults';
import {formValuesF, resetF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';

import {ImportMeasurementsAction} from '../actions';
import Api from '../api';

const title = 'Import měření přístroji';

export const formatBarcodes = (barcodes: string[]): string => barcodes.join(', ');

export const genSuccessMessage = (data: MeasurementImportResults): string => {
    const impPart = opt(`Úspěsně importováno ${data.importedSamples} vzorků.`);
    const missingPart = optEmptyArray(data.missingBarcodesOfSamples)
        .map(xs => `Chybějící vzorky: ${formatBarcodes(xs)}.`);
    const notRequiredPart = optEmptyArray(data.notRequiredBarcodesOfSamples)
        .map(x => `Nepožadované vzorky: ${formatBarcodes(x)}.`);

    return flow(
        map((x: Opt<string>) => x.toArray()),
        flatten,
        join('\n'),
    )([impPart, missingPart, notRequiredPart]);
};

function importIsOk(data: MeasurementImportResults) {
    return isEmpty(data.missingBarcodesOfSamples) && isEmpty(data.notRequiredBarcodesOfSamples);
}

function getOperatorId(state: StoreState): number {
    const formValues = formValuesF('importMeasurement')(state)
        .orCrash('missing form values');

    return opt(formValues.operatorId).orCrash('operatorId is not number');
}

function* handleSuccess({data: results}: SuccessResponse<MeasurementImportResults>): SagaIterator {
    const messageOptions = importIsOk(results) ? {} : {timeOut: 0};

    yield* put(showSuccess(title, genSuccessMessage(results), messageOptions));
    yield* put(resetF('importMeasurement'));
}

function* handleError(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

function* execute({payload: {file}}: ImportMeasurementsAction): SagaIterator {
    const operatorId = yield* select(getOperatorId);
    const response = yield* call(Api.import, operatorId, file);

    yield* response.isSuccess
        ? call(handleSuccess, response)
        : call(handleError, response);
}

export function* importMeasurementsSaga(): SagaIterator {
    yield takeLatestF('measurement/IMPORT_MEASUREMENTS', execute);
}
