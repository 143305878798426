import React, {PureComponent, ReactNode} from 'react';
import {Field} from 'redux-form';

import {withGenericFormFieldElements} from 'forms/components/Field/withGenericFormFieldElements';
import Input, {InputInnerProps, InputOuterProps} from 'forms/components/Input';

class InputField extends PureComponent<InputInnerProps> {
    render(): ReactNode {
        return withGenericFormFieldElements(Input, this.props);
    }
}

// TODO: Split to all types of inputs, e.g. TextField, PasswordField, HiddenField (without label) and so on.
//       Don't forget to remove `type` prop.
class InputFieldOuter extends PureComponent<InputOuterProps> {
    render(): ReactNode {
        const {
            name,
            label,
            className,
            disabled,
            helpText,
            onAction,
            tooltip,
            wrapperClassName,
            type,
            onFieldBlur,
            onFieldChange,
            autoFocus,
            inputRef,
            onEnter,
            fieldIsLoading,
            noHelper,
            onlyPositive,
            placeholder,
            onPasting,
        } = this.props;

        return <Field
            component={InputField}
            name={name}
            type={type}
            label={label}
            className={className}
            disabled={disabled}
            helpText={helpText}
            onAction={onAction}
            tooltip={tooltip}
            wrapperClassName={wrapperClassName}
            onFieldChange={onFieldChange}
            onFieldBlur={onFieldBlur}
            autoFocus={autoFocus}
            inputRef={inputRef}
            onEnter={onEnter}
            fieldIsLoading={fieldIsLoading}
            noHelper={noHelper}
            onlyPositive={onlyPositive}
            placeholder={placeholder}
            onPasting={onPasting}
        />;
    }
}

export default InputFieldOuter;
