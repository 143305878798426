import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/orders"
        component={Screens.List}
        key="orders"
    />,
    <Route
        exact
        path="/orders/new"
        component={Screens.New}
        key="ordersNew"
    />,
    <Route
        exact
        path="/orders/accept"
        component={Screens.AcceptOrder}
        key="ordersAccept"
    />,
    <Route
        exact
        path="/orders/new/editSample/:sampleIdx"
        component={Screens.NewEditSample}
        key="ordersNewEditSample"
    />,
    <Route
        exact
        path="/orders/new/orderCustomer"
        component={Screens.NewOrderCustomer}
        key="ordersNewOrderCustomer"
    />,
    <Route
        exact
        path="/orders/new/orderInfo"
        component={Screens.NewOrderInfo}
        key="ordersNewOrderInfo"
    />,
    <Route
        exact
        path="/orders/new/summary"
        component={Screens.Summary}
        key="ordersNewSummary"
    />,
    <Route
        exact
        path="/orders/new/shippingTicket"
        component={Screens.NewShippingTicket}
        key="ordersNewShippingTicket"
    />,
    <Route
        exact
        path="/orders/:id"
        component={Screens.Detail}
        key="orderDetails"
    />,
    <Route
        exact
        path="/orders/:id/edit"
        component={Screens.Detail}
        key="orderDetailsEdit"
    />,
    <Route
        exact
        path="/orders/:id/acceptSamples/"
        component={Screens.AcceptSamples}
        key="orderAcceptSamples"
    />,
    <Route
        exact
        path="/orders/:id/generate-protocol"
        component={Screens.GenerateProtocol}
        key="orderGenerateProtocol"
    />,
    <Route
        exact
        path="/orders/:id/newSample"
        component={Screens.EditNewSample}
        key="newSample"
    />,
    <Route
        exact
        path="/orders/:id/editSample/:sampleId"
        component={Screens.EditExistingSample}
        key="editSample"
    />,
];

export default routes;
