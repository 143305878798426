import React, {FunctionComponent, memo} from 'react';

import EmptyPage from '../EmptyPage';

interface Props {
    title: string;
    text: string;
}

const NoDataBase: FunctionComponent<Props> = props => {
    const {title, text} = props;

    return (
        <EmptyPage>
            <div className="d-flex flex-column align-items-center">
                <div className="h5">
                    {title}
                </div>
                <div>
                    {text}
                </div>
            </div>
        </EmptyPage>
    );
};

export const NoData = memo(NoDataBase);
