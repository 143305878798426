import * as t from 'io-ts';

import {OrderDetailsBaseSchema} from './OrderDetailsBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('OrderDetailsForCustomer'),
});

export const OrderDetailsForCustomerSchema = t.exact(t.intersection([
    OrderDetailsBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface OrderDetailsForCustomer extends t.TypeOf<typeof OrderDetailsForCustomerSchema> {}
