import {DataItem} from 'types/model/dataItems/DataItem';
import {DataItemAction} from './actions';

export interface DataItemState {
    dataItems: DataItem[] | null;
    dataItem: DataItem | null;
}

export const initialDataItemState: DataItemState = {
    dataItems: null,
    dataItem: null,
};

const reducer = (
    state: DataItemState | undefined = initialDataItemState,
    action: DataItemAction
): DataItemState => {
    switch (action.type) {
        case 'dataItem/LOAD_DATA_ITEMS_SUCCESS': {
            return {...state, dataItems: action.payload};
        }

        case 'dataItem/LOAD_DATA_ITEM_SUCCESS': {
            return {...state, dataItem: action.payload};
        }

        default:
            return state;
    }
};

export default reducer;
