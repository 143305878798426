import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {emptyStringToUndefined, showSuccess, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {UpdateSupplier} from 'types/model/suppliers/UpdateSupplier';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF} from 'utils/formHelpers';

import {
    UpdateSupplierAction,
    loadSupplier,
    updateSupplierError,
    updateSupplierSuccess,
} from '../actions';
import {UpdateSupplierFormValues} from '../types/UpdateSupplierFormValues';
import Api from '../api';

export function prepareUpdateSupplierData(formData: UpdateSupplierFormValues): UpdateSupplier {
    const {hasNoIco, ...data} = formData;
    const {invoiceDetails} = data;

    data.invoiceDetails = {
        ...invoiceDetails,
        ico: emptyStringToUndefined(invoiceDetails.ico),
        dic: emptyStringToUndefined(invoiceDetails.dic),
        phone: emptyStringToUndefined(invoiceDetails.phone),
        email: emptyStringToUndefined(invoiceDetails.email),
    };

    return data;
}

function* execute(action: UpdateSupplierAction): SagaIterator {
    const title = 'Upravení dodavatele';
    const values = (yield* select(formValuesF('updateSupplier'))).orCrash('missing form values');
    const id = action.payload;
    const response = yield* call(Api.updateSupplier, id, prepareUpdateSupplierData(values));

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        return yield* put(updateSupplierError(error));
    }

    yield* put(updateSupplierSuccess());
    yield* put(showSuccess(title, 'Změny uloženy'));
    yield* put(loadSupplier(id));
}

export function* updateSupplierSaga(): SagaIterator {
    yield takeLatestF('supplier/UPDATE_SUPPLIER', execute);
}
