import {UpdateMeasurementFormValues} from 'measurement/type/UpdateMeasurementFormValues';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {ErrorsFromBE, takeLatestF, ErrorResponse, SuccessResponse} from 'favorlogic-utils';

import {MeasurementDetails} from 'types/model/measurements/MeasurementDetails';
import {handleResponseError} from 'utils/handleResponseError';
import {initializeF} from 'utils/formHelpers';

import {
    loadMeasurementSuccess,
    loadMeasurementError,
    LoadMeasurementAction,
} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const title = 'Načtení detailu měření';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadMeasurementError(error));
}

export function prepareUpdateMeasurementsFormValues(measurement: MeasurementDetails): UpdateMeasurementFormValues {
    return {
        measured: measurement.measured,
        dilution: measurement.dilution.toString(),
        result: measurement.result.toString(),
        procedureId: measurement.procedureId,
        reason: opt(measurement?.history?.reason).orNull(),
    };
}

function* handleSuccessResponse({data: measurement}: SuccessResponse<MeasurementDetails>): SagaIterator {
    const formValues = prepareUpdateMeasurementsFormValues(measurement);

    yield* put(initializeF('updateMeasurement', formValues));
    yield* put(loadMeasurementSuccess(measurement));
}

function* execute(action: LoadMeasurementAction): SagaIterator {
    const response = yield* call(Api.getMeasurement, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/LOAD_MEASUREMENT', execute);
}
