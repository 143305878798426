import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteComponentProps} from 'react-router';
import {parseSearch} from 'favorlogic-utils';

import {Components as Layout} from 'layout';
import {StoreState} from 'app/types/StoreState';
import {TableAction, withFilter, withPagination, withSort} from 'tables/components';
import {SortProps} from 'tables/components/withSort';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';
import {SampleDetailsPage} from 'types/model/samples/SampleDetailsPage';
import withUser from 'user/components/withUser';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {loadAnalyses} from 'analysis/actions';
import {Analysis} from 'types/model/analyses/Analysis';

import {
    deleteSample,
    loadSamples,
    setSamplesParams,
    toggleSampleSelect,
    setUpdateSamplesBy,
} from '../actions';
import {SamplesFilterParams, SamplesParams} from '../types/SampleQueryParams';
import SamplesTable, {definedFilters} from '../components/SamplesTable';

export type UpdateSamplesBy = 'FILTER' | 'SELECTION' | null;

interface OuterProps {}

interface StateProps {
    samplesPage: SampleDetailsPage | null;
    selectedSamples: number[];
    samplesFilterParams: SamplesFilterParams;
    updateSamplesBy: UpdateSamplesBy | null;
    analyses: Analysis[];
}

interface DispatchProps {
    handleLoadSamplesPage(params: SamplesParams): void;
    handleDelete(sampleId: number): void;
    handleSetSamplesParams(params: SamplesParams): void;
    toggleSampleSelect(sampleId: number): void;
    handleSetUpdateSamplesBy(type: UpdateSamplesBy): void;
    handleLoadAnalyses(): void;
}

type RouterProps = Pick<RouteComponentProps, 'history' | 'location'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type Props =  OuterProps & StateProps & DispatchProps & ListProps & RouterProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {location: {search}, handleLoadAnalyses} = this.props;

        if (search) { this.handleLoadSamplesBySearchQuery(search); }
        handleLoadAnalyses();
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}} = prevProps;
        const {location: {search}} = this.props;

        if (prevSearch !== search) {
            this.handleLoadSamplesBySearchQuery(search);
        }
    }

    handleLoadSamplesBySearchQuery(search: string) {
        const {handleLoadSamplesPage, handleSetSamplesParams} = this.props;
        const params = parseSearch(search);

        handleSetSamplesParams(params);
        handleLoadSamplesPage(params);
    }

    render(): ReactNode {
        const {
            samplesPage,
            selectedSamples,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            sortDir,
            sortBy,
            handleDelete,
            handleFilterChange,
            handleFilterClear,
            handleFilterSubmit,
            filter,
            toggleSampleSelect,
            analyses,
        } = this.props;

        if (samplesPage && isEmpty(samplesPage.content) && isEmpty(filter)) {
            return this.renderNoSamples();
        }

        return (
            <Layout.ListPage
                title="Přehled vzorků"
                fluid
            >
                <Layout.Panel className="mt-0">
                    <div className="row">
                        <TableAction
                            title="Upravit vzorky"
                            filteredItemsCount={samplesPage?.totalElements ?? 0}
                            selectedItemsCount={selectedSamples.length}
                            actionForFilter={this.updateSamplesByFilter}
                            actionForSelection={this.updateSamplesBySelection}
                        />
                    </div>
                </Layout.Panel>
                <SamplesTable
                    samplesPage={samplesPage}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                    handleSortChange={handleSortChange}
                    filter={filter}
                    changeFilter={handleFilterChange}
                    resetFilter={handleFilterClear}
                    filterSamples={handleFilterSubmit}
                    sortDir={sortDir}
                    sortBy={sortBy}
                    handleDelete={handleDelete}
                    toggleSelect={toggleSampleSelect}
                    selectedSamples={selectedSamples}
                    analyses={analyses}
                />
            </Layout.ListPage>
        );
    }

    private updateSamplesByFilter = () => {
        const {handleSetUpdateSamplesBy, history} = this.props;

        handleSetUpdateSamplesBy('FILTER');
        history.push('/samples/updateSamples');
    };

    private updateSamplesBySelection = () => {
        const {handleSetUpdateSamplesBy, history} = this.props;

        handleSetUpdateSamplesBy('SELECTION');
        history.push('/samples/updateSamples');
    };

    private renderNoSamples(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="text-center h5">
                    Nemáte prozatím žádné vzorky
                </div>
            </Layout.EmptyPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    samplesPage: state.sample.samples,
    selectedSamples: state.sample.selectedSamples,
    samplesFilterParams: state.sample.samplesFilterParams,
    updateSamplesBy: state.sample.updateSamplesBy,
    analyses: state.analysis.analyses || [],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadSamplesPage: (params) => dispatch(loadSamples(params)),
    handleDelete: (sampleId: number) => dispatch(showConfirmDialog({
        title: 'Smazat vzorek',
        text: 'Opravdu chcete vzorek smazat?',
        confirmAction: deleteSample({sampleId}),
    })),
    handleSetSamplesParams: (params) => dispatch(setSamplesParams(params)),
    handleSetUpdateSamplesBy: (type: UpdateSamplesBy) => dispatch(setUpdateSamplesBy(type)),
    toggleSampleSelect: (sampleId: number) => dispatch(toggleSampleSelect(sampleId)),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
});

export default flow([
    withUser,
    withPagination,
    withSort({sortBy: 'accepted,sequenceNumber', sortDir: 'desc'}),
    withFilter(definedFilters),
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
