import React, {FC, useEffect, useState} from 'react';
import {Form} from 'redux-form';
import {isEmpty} from 'lodash/fp';
import moment from 'moment';
import {Opt} from 'ts-opt';
import {validateKeyGen, todayDate} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Icons} from 'icons';
import {Components as Layout} from 'layout';
import {sampleHarvestTypeOptions} from 'sample/enums/SampleHarvestTypeEnum';
import {sampleTypeOptions} from 'sample/enums/SampleTypeEnum';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {SelectOptions} from 'forms/components/BasicSelect';
import {SampleHarvestType} from 'types/model/enumeration/SampleHarvestType';
import {SampleType} from 'types/model/enumeration/SampleType';
import Traits from 'order/components/CreateSampleForm/traits';
import {validate} from './validations';

import styles from './styles.sass';

import infoSvg from './infoIcon.svg';

const showResultsOnWebTooltip = 'Výsledky rozborů budou automaticky odeslány do aplikace https://data.cmsch.cz';
const validateKey = validateKeyGen<CreateSampleFormValues>();

export interface OwnProps {
    initialValues?: CreateSampleFormValues;
    inOrderCreation?: boolean;
    inSupplyChain?: boolean;
    hasMoreSamples?: boolean;
    analysesOptions: SelectOptions<number>;
    milkRoomsOptions?: SelectOptions<number>;
    supplyChainsOptions?: SelectOptions<number>;
    lastCreatedSample?: CreateSampleFormValues;
    sampleBarcodeIds: string[];
    formValues?: CreateSampleFormValues;
    enableShowResults: boolean;
    showTraits: boolean;
    allowQCZ: boolean;
    showTraitNoteSupplyChain?: boolean;

    handleLoadAnalysesOptions(ids: number[]): void;
    addMoreSamples?(): void;
}

export type Props = PropsForForm<CreateSampleFormValues, OwnProps>;

export const initialCreateSampleState: CreateSampleFormValues = {
    barcode: '',
    sampleSourceAmount: '',
    samplingTemperature: '',
    samplingTime: '',
    samplingDate: todayDate(),
    type: 'RAW_COW_MILK',
    harvestType: null,
    supplyChainId: null,
    supplyChainCode: null,
    milkRoomId: null,
    analysisIds: [],
    showResultsOnWeb: false,
    showResultsToBreeders: false,
    customerNote: '',
    qualitative: false,
    cistern: false,
    control: false,
    subsidy: false,
    traitNote: '',
};

function preparePrefill(lastCreatedSample: CreateSampleFormValues): Partial<CreateSampleFormValues> {
    return {
        analysisIds: lastCreatedSample.analysisIds,
        type: lastCreatedSample.type,
        harvestType: lastCreatedSample.harvestType,
        sampleSourceAmount: lastCreatedSample.sampleSourceAmount,
        samplingTemperature: lastCreatedSample.samplingTemperature,
        samplingTime: lastCreatedSample.samplingTime,
        showResultsOnWeb: lastCreatedSample.showResultsOnWeb,
        showResultsToBreeders: lastCreatedSample.showResultsToBreeders,
    };
}

const CreateSampleForm: FC<Props> = (props: Props) => {
    const {
        handleSubmit,
        submitting,
        inSupplyChain,
        analysesOptions,
        milkRoomsOptions,
        supplyChainsOptions,
        formValues,
        enableShowResults,
        showTraits,
        handleLoadAnalysesOptions,
        change,
        lastCreatedSample,
        initialize,
        initialValues,
        valid,
        inOrderCreation,
        hasMoreSamples,
        addMoreSamples,
        allowQCZ,
        showTraitNoteSupplyChain,
    } = props;

    const [isClickedUseForAll, setIsClickedUseForAll] = useState(false);

    useEffect(() => {
        if (!isClickedUseForAll) {
            if (lastCreatedSample || inSupplyChain) {
                initialize({...initialValues, ...lastCreatedSample && preparePrefill(lastCreatedSample)});
            }
        } else {
            setIsClickedUseForAll(false);
            initialize(initialValues);
        }
    }, [lastCreatedSample]);

    const skimmedMilkSelected = formValues?.type === 'SKIMMED_MILK';
    const maxSamplingDate = moment().add(7, 'days').toDate();
    const isCistern = Boolean(formValues?.cistern);
    const resultsOnWebChecked = Boolean(formValues?.showResultsOnWeb);

    const onChangeSampleTypeOptions = (option: Opt<SampleType>): void => {
        if (option.contains('SKIMMED_MILK')) {
            change('showResultsOnWeb', false);
            change('showResultsToBreeders', false);
        }
    };

    const onAnalysesChange = (ids: Opt<number[]>): void => {
        handleLoadAnalysesOptions(ids.orElse([]));
    };

    const onShowResultsOnWebChanged = (value: Opt<boolean>) => {
        if (!value.orFalse()) {
            change('showResultsToBreeders', false);
        }
    };

    const onAddMoreSamples = () => {
        setIsClickedUseForAll(true);
        addMoreSamples?.();
    };

    return (
        <Layout.Panel>
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />
                <div className="row">
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('barcode')}
                            type="text"
                            label="Čárový kód*"
                            tooltip="Unikátní identifikace vzorku"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('sampleSourceAmount')}
                            type="text"
                            label="Množství"
                        />
                        <div className={styles.infoIcon}>
                            <Icons.SvgIcon
                                title="Množství, ze kterého byl proveden odběr (v litrech)"
                                icon={infoSvg}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <Fields.DateTime
                            name={validateKey('samplingDate')}
                            mode="DATE"
                            label="Datum odběru*"
                            includeFuture
                            maxDate={maxSamplingDate}
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.DateTime
                            name={validateKey('samplingTime')}
                            mode="TIME"
                            label="Čas odběru"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('samplingTemperature')}
                            type="text"
                            label="Teplota při odběru"
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <Fields.Select<SampleType>
                            name={validateKey('type')}
                            label="Typ vzorku*"
                            options={sampleTypeOptions}
                            onFieldChange={onChangeSampleTypeOptions}
                        />
                    </div>
                    <div className="col-md-6">
                        <Fields.Select<SampleHarvestType>
                            name={validateKey('harvestType')}
                            label="Způsob odběru*"
                            options={sampleHarvestTypeOptions}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <Fields.Select<number>
                            name={validateKey('analysisIds')}
                            label="Požadované rozbory*"
                            options={analysesOptions}
                            multi
                            onFieldChange={onAnalysesChange}
                        />
                    </div>
                    <div className="col-md-6">
                        {milkRoomsOptions && (
                            isEmpty(milkRoomsOptions)
                                ? <div className="mt-3">
                                    Nejsou dostupné žádné mléčnice.
                                </div>
                                : <Fields.Select<number>
                                    name={validateKey('milkRoomId')}
                                    label="Mléčnice"
                                    options={milkRoomsOptions}
                                    disabled={isCistern || inSupplyChain}
                                />
                        )}
                        {inSupplyChain && <Fields.Select<number>
                            name={validateKey('supplyChainId')}
                            label="Linka"
                            options={supplyChainsOptions || []}
                            disabled
                        />}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <Fields.Input
                            name={validateKey('customerNote')}
                            type="text"
                            label="Poznámka zákazníka"
                        />
                    </div>
                </div>
                {enableShowResults && <div className="row mt-2">
                    <div className="col-md-4">
                        <Fields.Checkbox
                            name={validateKey('showResultsOnWeb')}
                            label="Zasílat výsledky na web"
                            disabled={skimmedMilkSelected}
                            tooltip={showResultsOnWebTooltip}
                            onFieldChange={onShowResultsOnWebChanged}
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Checkbox
                            name={validateKey('showResultsToBreeders')}
                            label="Zasílat výsledky chovatelům"
                            disabled={skimmedMilkSelected || !resultsOnWebChecked}
                            tooltip={!resultsOnWebChecked
                                ? 'Není možné odeslat výsledky chovateli, pokud nejsou zaslány na web'
                                : undefined
                            }
                        />
                    </div>
                </div>}
                <Traits
                    change={change}
                    formValues={formValues}
                    showDairyTraits={showTraits}
                    allowQCZ={allowQCZ}
                    showTraitNoteSupplyChain={showTraitNoteSupplyChain && isCistern}
                    supplyChainsOptions={supplyChainsOptions}
                />
                {!inSupplyChain && <div className="d-flex justify-content-end mt-4">
                    <Buttons.Button
                        fullWidth
                        importance={inOrderCreation ? 'secondary' : 'primary'}
                        disabled={!valid || submitting}
                        type="submit"
                    >
                        {inOrderCreation ? 'Přidat vzorek' : 'Upravit vzorek'}
                    </Buttons.Button>
                </div>}
                {inOrderCreation && hasMoreSamples && <div className="d-flex justify-content-end mt-4">
                    <Buttons.Button
                        fullWidth
                        importance="secondary"
                        disabled={!valid || submitting}
                        type="button"
                        onClick={onAddMoreSamples}
                    >
                        Použít pro všechny zbývající
                    </Buttons.Button>
                </div>}
            </Form>
        </Layout.Panel>
    );
};

export default Forms.withForm<CreateSampleFormValues, OwnProps, Props>(CreateSampleForm, {
    form: 'createSample',
    validate,
    enableReinitialize: false,
    initialValues: initialCreateSampleState,
});
