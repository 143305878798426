import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {find, flatten, concat, flow, isEmpty, map, negate, pullAt, filter, difference} from 'lodash/fp';
import {Dispatch} from 'redux';
import {change, reset, touch} from 'redux-form';
import {Opt, opt} from 'ts-opt';
import {formatFloat} from 'favorlogic-utils';

import {StoreFormState} from 'app/types/StoreFormState';
import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {loadAnalyses} from 'analysis/actions';
import {renderFormError} from 'forms/components/withForm';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {loadMilkRooms} from 'supplier/actions';
import {loadSupplyChains} from 'supplyChain/actions';
import {CreateSample} from 'types/model/samples/CreateSample';
import {ParsedSample} from 'types/model/samples/ParsedSample';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import withUser, {WithUserProps} from 'user/components/withUser';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {formState, formValuesF, isValidF, destroyF, resetF} from 'utils/formHelpers';
import {resetWizard} from 'wizard/actions';
import LeavePage from 'layout/components/LeavePage';
import {SampleDefaults} from 'sample/types/SampleDefaults';
import {Analysis} from 'types/model/analyses/Analysis';
import {Customer} from 'types/model/customers/Customer';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {SelectOptions} from 'forms/components/BasicSelect';
import {loadCustomerMilkRooms, resetCustomerMilkRooms} from 'user/actions';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import logger from 'app/model/Logger';

import {
    addSampleInOrderCreation,
    addImportedSamplesInOrderCreation,
    prefillCreateSampleForm,
    updateSamplesFromSupplyChain,
    setLastCreatedSample,
    setAnalysesBySelectedDataItems,
    selectSampleCreationType,
    prefillCreateOrderForm,
    resetImportedSamples,
    loadCustomerForOrder,
    resetCustomerForOrder,
    reloadMilkRoomsInOrderSamples,
} from '../actions';
import {LEAVE_CREATING_ORDER_MESSAGE} from '../constants';
import CreateSampleForm, {initialCreateSampleState} from '../components/CreateSampleForm';
import CreateOrderForm, {initialCreateOrderFormValues} from '../components/CreateOrderForm';
import {SampleCreationType} from '../types/SampleCreationType';
import SamplesFromSupplyChainForm from '../components/SamplesFromSupplyChainForm';
import SamplesTable from '../components/SamplesTable';
import {CreateOrderFormValues} from '../types/CreateOrderFormValues';
import SampleCreationTypeButtons from '../components/SampleCreationTypeButtons';
import {getMilkRoomsOptions, getAnalysesOptions, getSupplyChainsOptions} from '../utils/selectOptions';
import {
    getOrderCustomer,
    enableShowResultsByCustomer,
    getPriceClassForCustomer,
    enableQCZByCustomer,
} from '../utils/orderUtils';
import {getSampleDefaults} from '../utils/getSampleDefaults';

interface OuterProps {}

type PrefillFromImportedF = (sample: ParsedSample) => void;

interface InnerProps {
    analyses: Analysis[];
    samples: CreateSample[];
    supplyChains: SupplyChain[];
    createFormValue: Opt<CreateOrderFormValues>;
    samplesFromSupplyChain: CreateSampleFormValues[];
    currentCreateSample: CreateSampleFormValues;
    selectedSupplyChainIds: number[];
    samplesError?: string;
    samplesTouched?: boolean;
    isCreateSampleFormValid?: boolean;
    customerMilkRooms: MilkRoom[] | null;
    milkRooms: MilkRoom[];
    lastCreatedSample?: CreateSampleFormValues;
    importedSamples: Opt<ParsedSample[]>;
    selectedSampleCreationType: SampleCreationType;
    customerForOrder: CustomerDetailsForLaborer | null;

    handlePrefillFromImported(sample: CreateSampleFormValues): void;

    handleLoadMilkRooms(): void;

    handleLoadCustomerMilkRooms(customerId: number): void;

    handleLoadAnalyses(): void;

    handleCreateSample(sample: CreateSampleFormValues): void;

    handleLoadSupplyChains(): void;

    deleteSampleInOrderCreation(id: number, samples: CreateSample[]): void;

    updateSampleFromChain(idx: number, sample: CreateSampleFormValues, unsetLastCreatedSample: boolean): void;

    updateSamplesFromChain(samples: CreateSampleFormValues[]): void;

    handleResetSamplesFromSupplyChainForm(): void;

    handleResetCreateOrderForm(): void;

    handleResetWizard(): void;

    handleSetAnalysesBySelectedDataItems(analyses: Analysis[]): void;

    addImportedSamplesInOrderCreation(): void;

    prefillCustomerDetails(
        customerDetails: Customer,
        formValues: CreateOrderFormValues,
    ): void;

    handleSelectSampleCreationType(type?: SampleCreationType): void;

    handleResetImportedSamples(): void;

    handleLoadCustomerForOrder(customerId: number): void;

    handleResetCustomerForOrder(): void;

    reloadMilkRoomsInOrderSamples(customerMilkRooms: MilkRoom[]): void;

    resetCustomerMilkRooms(): void;
}

type UserProps = Pick<WithUserProps,
    'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentCustomer'
>;
type Props = InnerProps & OuterProps & UserProps;

class New extends Component<Props> {
    private static renderImportedSamples(
        imported: ParsedSample[],
        prefillSamplesFromImport: PrefillFromImportedF,
    ) {
        const renderItem = (x: ParsedSample): ReactNode =>
            <Buttons.Button
                type="button"
                onClick={() => prefillSamplesFromImport(x)}
                importance="primary"
                key={x.barcode}
                className="m-1"
            >
                {x.barcode}
            </Buttons.Button>;

        return imported.map(renderItem);
    }

    private get orderCustomer(): Opt<CustomerDetails> {
        const {currentCustomer, customerForOrder} = this.props;

        return getOrderCustomer(currentCustomer, opt(customerForOrder), this.isAdminOrLaborer);
    }

    private get priceClass(): PriceClass | undefined {
        return getPriceClassForCustomer(this.orderCustomer);
    }

    private get enableShowResults(): boolean {
        return enableShowResultsByCustomer(this.orderCustomer);
    }

    private get sampleDefaults(): SampleDefaults | undefined {
        return getSampleDefaults(this.orderCustomer, this.isDairy);
    }

    private get milkRooms(): MilkRoom[] | null {
        const {isRoleAdmin, isRoleLaborer, milkRooms, customerMilkRooms} = this.props;

        return isRoleAdmin || isRoleLaborer ? customerMilkRooms : milkRooms;
    }

    private get isDairy(): boolean {
        const {isRoleDairy, isRoleDairyEmployee} = this.props;

        return isRoleDairy || isRoleDairyEmployee;
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    private get showQCZ(): boolean {
        return enableQCZByCustomer(this.orderCustomer);
    }

    private get showTraits(): boolean {
        return this.isAdminOrLaborer || this.isDairy;
    }

    componentDidMount(): void {
        const {
            handleLoadAnalyses,
            handleLoadMilkRooms,
            handleLoadSupplyChains,
            handleSelectSampleCreationType,
            resetCustomerMilkRooms,
        } = this.props;
        resetCustomerMilkRooms();

        if (this.isDairy) {
            handleLoadSupplyChains();
        } else {
            handleSelectSampleCreationType('MANUALLY');
        }

        if (this.isAdminOrLaborer) {
            this.loadMilkRoomsByCustomer();
            this.loadCustomerForOrder();
        } else {
            handleLoadMilkRooms();
            this.prefillCurrentCustomerDetails();
        }

        handleLoadAnalyses();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {
            selectedSupplyChainIds,
            handleResetSamplesFromSupplyChainForm,
            handleResetWizard,
            customerMilkRooms,
            reloadMilkRoomsInOrderSamples,
        } = this.props;
        if (selectedSupplyChainIds && prevProps.selectedSupplyChainIds !== selectedSupplyChainIds) {
            this.prefillSamplesFromChain();
            if (selectedSupplyChainIds.length === 0) {
                handleResetSamplesFromSupplyChainForm();
                handleResetWizard();
            }
        }

        if (prevProps.customerMilkRooms !== customerMilkRooms && customerMilkRooms) {
            reloadMilkRoomsInOrderSamples(customerMilkRooms);
        }

        if (!this.showTraits && !this.showQCZ) {
            const {isRoleAdmin, isRoleLaborer, isRoleDairy, isRoleDairyEmployee, currentCustomer} = this.props;
            logger.logError(new Error(`
                'Invalid input data, not showing Traits & QCZ:',
                 currentCustomer: ${currentCustomer},
                 isRoleAdmin: ${isRoleAdmin},
                 isRoleLaborer: ${isRoleLaborer},
                 isRoleDairy, ${isRoleDairy},
                 isRoleDairyEmployee: ${isRoleDairyEmployee}
            `));
        }
    }

    componentWillUnmount(): void {
        this.props.handleResetImportedSamples();
    }

    render(): ReactNode {
        const {
            samples,
            handleCreateSample,
            supplyChains,
            selectedSupplyChainIds,
            samplesFromSupplyChain,
            isCreateSampleFormValid,
            lastCreatedSample,
            importedSamples,
            selectedSampleCreationType,
            handleResetCreateOrderForm,
            addImportedSamplesInOrderCreation,
            analyses,
            currentCreateSample,
        } = this.props;

        const analysesOptions = getAnalysesOptions(analyses, this.priceClass);
        const milkRoomsOptions = this.getMilkRoomsOptions();
        const supplyChainsOptions = getSupplyChainsOptions(supplyChains);
        const sampleBarcodeIds = this.getBarcodes();
        const enableNextButton = !isEmpty(this.getSamplesToAddFromSupplyChain());
        const showCreateSampleForm = selectedSampleCreationType === 'MANUALLY' ||
                                      selectedSampleCreationType === 'IMPORTED' && importedSamples;
        const showSupplyChainForm = selectedSampleCreationType === 'FROM_SUPPLY_CHAIN';

        return (
            <Layout.ItemPage
                title="Nová objednávka"
                backLabel={this.isAdminOrLaborer ? 'Zpět' : 'Objednávky'}
            >
                <div className="w-100">
                    {this.isDairy && this.renderEmptyState()}

                    <div className="w-100 mt-2">
                        {this.renderSamples()}

                        {showSupplyChainForm && <div className="w-100 mb-5">
                            <h3>Nové vzorky z linek</h3>
                            {isEmpty(supplyChainsOptions)
                                ? <div>
                                    Prozatím nebyla vytvořena žádná linka. Můžete tak učinit na stránce
                                    <Buttons.LinkButton
                                        label="Linky"
                                        to="/supply-chains"
                                        className="ml-2"
                                    />
                                </div>
                                : <SamplesFromSupplyChainForm
                                    formValues={currentCreateSample}
                                    analysesOptions={analysesOptions}
                                    milkRoomsOptions={milkRoomsOptions}
                                    supplyChainsOptions={supplyChainsOptions}
                                    samplesFromSupplyChain={samplesFromSupplyChain}
                                    selectedSupplyChainIds={selectedSupplyChainIds}
                                    isCreateSampleFormValid={isCreateSampleFormValid}
                                    handleCompleteWizard={this.handleCompleteWizard}
                                    lastCreatedSample={lastCreatedSample}
                                    sampleBarcodeIds={sampleBarcodeIds}
                                    handleUpdateSample={this.handleUpdateSampleInSupplyChain}
                                    handleSkipSample={this.handleSkipSampleInSupplyChain}
                                    canFinishWizard={enableNextButton}
                                    handleLoadAnalysesOptions={this.handleLoadAnalysesOptions}
                                    sampleDefaults={this.sampleDefaults}
                                    enableShowResults={this.enableShowResults}
                                    showTraits={this.showTraits}
                                    allowQCZ={this.showQCZ}
                                />
                            }
                        </div>}

                        {showCreateSampleForm && <div className="w-100">
                            <h3>Nový vzorek</h3>
                            {selectedSampleCreationType === 'IMPORTED' && this.renderImportedSamples()}
                            <CreateSampleForm
                                formValues={currentCreateSample}
                                onSubmit={handleCreateSample}
                                inOrderCreation
                                hasMoreSamples={selectedSampleCreationType === 'IMPORTED'}
                                analysesOptions={analysesOptions}
                                milkRoomsOptions={milkRoomsOptions}
                                lastCreatedSample={lastCreatedSample}
                                sampleBarcodeIds={sampleBarcodeIds}
                                handleLoadAnalysesOptions={this.handleLoadAnalysesOptions}
                                addMoreSamples={addImportedSamplesInOrderCreation}
                                initialValues={{...initialCreateSampleState, ...this.sampleDefaults}}
                                enableShowResults={this.enableShowResults}
                                showTraits={this.showTraits}
                                allowQCZ={this.showQCZ}
                                supplyChainsOptions={supplyChainsOptions}
                                showTraitNoteSupplyChain={this.isDairy && selectedSampleCreationType === 'MANUALLY'}
                            />
                        </div>}

                        <div className="m-5 d-none">
                            <CreateOrderForm
                                hideSubmitButton
                            />
                        </div>

                        {(selectedSampleCreationType || !isEmpty(samples)) && <div className="w-100">
                            <div className="d-flex justify-content-end mt-4">
                                <Buttons.RightIconButton
                                    label="Údaje k objednávce"
                                    disabled={isEmpty(samples)}
                                    to="new/orderInfo"
                                    icon="NEXT"
                                />
                            </div>
                        </div>}
                    </div>
                </div>
                <LeavePage
                    canLeave={this.isAdminOrLaborer ? false : isEmpty(samples)}
                    except={['/orders/new/orderInfo', '/orders/new/editSample/\\d+', '/orders/new/orderCustomer']}
                    afterLeave={handleResetCreateOrderForm}
                    message={LEAVE_CREATING_ORDER_MESSAGE}
                />
            </Layout.ItemPage>
        );
    }

    private renderSamples = (): ReactNode => {
        const {samplesError, samplesTouched, samples, analyses, supplyChains} = this.props;
        if (isEmpty(samples)) {
            return null;
        }

        return (
            <div className="w-100 mb-5">
                <div className="d-flex justify-content-between pb-2">
                    <h3>Seznam vzorků *</h3>
                    {this.isDairy && <SampleCreationTypeButtons />}
                </div>
                <SamplesTable
                    samples={samples}
                    milkRooms={this.milkRooms}
                    handleDelete={this.handleDeleteSample}
                    mode="CREATE"
                    analyses={analyses}
                    supplyChains={supplyChains}
                    showSupplyChainCode={this.isDairy}
                />
                {samplesError && samplesTouched &&
                <div className="mt-2">
                    {renderFormError(samplesError)}
                </div>
                }
            </div>
        );
    };

    private renderImportedSamples(): ReactNode {
        const samplesForImport = this.getRemainingImportedSamples();

        if (isEmpty(samplesForImport)) {
            return null;
        }

        return (
            <Layout.Panel>
                {New.renderImportedSamples(
                    samplesForImport,
                    this.prefillSamplesFromImport,
                )}
            </Layout.Panel>
        );
    }

    private renderEmptyState(): ReactNode {
        const {selectedSampleCreationType, samples} = this.props;

        if (selectedSampleCreationType || !isEmpty(samples)) {
            return null;
        }

        return (
            <Layout.Panel>
                <div className="container-fluid d-flex py-5 flex-column">
                    <div className="w-100 px-4">
                        <div className="h5 text-center">
                            Přidejte prosím vzorky do objednávky
                        </div>
                        <SampleCreationTypeButtons />
                    </div>
                </div>
            </Layout.Panel>
        );
    }

    private getRemainingImportedSamples(): ParsedSample[] {
        const {importedSamples, samples} = this.props;
        const barcodes = samples.map(x => x.barcode);
        const filterSamples = filter((x: ParsedSample) => !barcodes.includes(x.barcode));

        return importedSamples.map(filterSamples).orElse([]);
    }

    private getCustomerId(): number | null {
        const {createFormValue} = this.props;

        return createFormValue.chainToOpt(v => v.customerDetails?.id).orNull();
    }

    private loadMilkRoomsByCustomer(): void {
        const {handleLoadCustomerMilkRooms} = this.props;
        const customerId = this.getCustomerId();

        if (customerId) {
            handleLoadCustomerMilkRooms(customerId);
        }
    }

    private loadCustomerForOrder(): void {
        const {handleLoadCustomerForOrder, handleResetCustomerForOrder} = this.props;
        const customerId = this.getCustomerId();

        if (customerId) {
            handleLoadCustomerForOrder(customerId);
        } else {
            handleResetCustomerForOrder();
        }
    }

    private getMilkRoomsOptions(): SelectOptions<number> | undefined {
        const {milkRooms, customerMilkRooms} = this.props;

        return this.isAdminOrLaborer
            ? getMilkRoomsOptions(customerMilkRooms)
            : getMilkRoomsOptions(milkRooms);
    }

    private prefillCurrentCustomerDetails(): void {
        const {prefillCustomerDetails, currentCustomer, createFormValue} = this.props;
        const customer = currentCustomer.orCrash('missing customer');
        const createFormValues = createFormValue.orElse(initialCreateOrderFormValues);

        if (this.isDairy) {
            createFormValues.deliveryType = 'LABORATORY_COLLECTION';
        }

        prefillCustomerDetails({
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            invoiceDetails: customer.invoiceDetails,
            address: customer.address,
            billingAddress: customer.billingAddress,
            customerName: customer.name,
        }, createFormValues);
    }

    private readonly handleLoadAnalysesOptions = (ids: number[]): void => {
        const {
            handleSetAnalysesBySelectedDataItems,
            analyses,
        } = this.props;

        if (isEmpty(ids)) {
            handleSetAnalysesBySelectedDataItems([]);
        } else {
            const dataItemIdsBySelectedAnalyses: number[][] = flow(
                map(id => filter(a => a.id === id, analyses)),
                flatten,
                map(x => x.dataItemIds),
            )(ids);

            const includeSameDataItemIds = (analysisIds: number[]): boolean => flow(
                map((x: number[]): number[] | false => analysisIds !== x && difference(analysisIds, x)),
                filter((x: number[] | false): boolean => x && isEmpty(x)),
                negate(isEmpty),
            )(dataItemIdsBySelectedAnalyses);

            const filteredAnalyses: Analysis[] =
                filter(a => !includeSameDataItemIds(a.dataItemIds), analyses);
            handleSetAnalysesBySelectedDataItems(filteredAnalyses);
        }
    };

    private readonly handleDeleteSample = (idx: number): void => {
        const {
            deleteSampleInOrderCreation,
            createFormValue,
            handleSelectSampleCreationType,
        } = this.props;
        const {samples} = createFormValue.orCrash('missing form');

        deleteSampleInOrderCreation(idx, samples);
        if (samples.length === 1) {
            handleSelectSampleCreationType(this.isDairy ? undefined : 'MANUALLY');
        }
    }

    private readonly handleUpdateSampleInSupplyChain = (idx: number): void => {
        const {currentCreateSample, updateSampleFromChain, samplesFromSupplyChain} = this.props;
        updateSampleFromChain(
            idx,
            currentCreateSample,
            this.isNextSampleDifferentSupplyChain(idx, samplesFromSupplyChain),
        );
    }

    private readonly handleSkipSampleInSupplyChain = (idx: number): void => {
        const {updateSampleFromChain, samplesFromSupplyChain, currentCreateSample} = this.props;
        updateSampleFromChain(
            idx,
            {...currentCreateSample, barcode: ''},
            this.isNextSampleDifferentSupplyChain(idx, samplesFromSupplyChain),
        );

        if (idx === samplesFromSupplyChain.length - 1) {
            this.handleCompleteWizard();
        }
    }

    private readonly isNextSampleDifferentSupplyChain = (id: number, supplyChainSamples: CreateSampleFormValues[]) =>
        id < supplyChainSamples.length - 1 &&
        supplyChainSamples[id].supplyChainId !== supplyChainSamples[id + 1].supplyChainId;

    private readonly handleCompleteWizard = (): void => {
        const {handleCreateSample, handleResetSamplesFromSupplyChainForm} = this.props;
        const newSamples: CreateSampleFormValues[] = this.getSamplesToAddFromSupplyChain();
        newSamples.map(x => handleCreateSample(x));
        handleResetSamplesFromSupplyChainForm();
    }

    private readonly prefillSamplesFromChain = (): void => {
        const {updateSamplesFromChain, selectedSupplyChainIds} = this.props;
        const prefiledValues = this.getPrefilledSamples();
        if (selectedSupplyChainIds.length === 0) {
            updateSamplesFromChain([]);
        } else {
            updateSamplesFromChain(prefiledValues);
        }
    }

    private prefillSamplesFromImport = (sample: ParsedSample): void => {
        const {lastCreatedSample, handlePrefillFromImported} = this.props;

        const s: CreateSampleFormValues = {
            barcode: sample.barcode,
            analysisIds: [],
            samplingTime: '',
            type: 'RAW_COW_MILK',
            harvestType: this.isDairy ? 'DAIRY' : null,
            sampleSourceAmount: opt(sample.sampleSourceAmount).map(x => x.toString()).orElse(''),
            samplingTemperature: opt(sample.samplingTemperature).map(x => formatFloat(x)).orElse(''),
            samplingDate: sample.samplingDate || '',
            supplyChainId: opt(sample.supplyChainId).orNull(),
            supplyChainCode: null,
            milkRoomId: opt(sample.milkRoomId).orNull(),
            showResultsOnWeb: opt(this.sampleDefaults).map(x => x.showResultsOnWeb).orFalse(),
            showResultsToBreeders: opt(this.sampleDefaults).map(x => x.showResultsToBreeders).orFalse(),
            customerNote: null,
            qualitative: false,
            cistern: Boolean(sample.traitNote),
            control: Boolean(sample.traitNote),
            subsidy: false,
            traitNote: sample.traitNote || null,
        };
        const finalSample: CreateSampleFormValues = opt(lastCreatedSample).map((x: CreateSampleFormValues) => ({
            ...s,
            analysisIds: x.analysisIds,
            type: x.type,
            harvestType: x.harvestType,
            samplingTime: x.samplingTime,
            showResultsOnWeb: x.showResultsOnWeb,
            showResultsToBreeders: x.showResultsToBreeders,
        })).orElse(s);
        handlePrefillFromImported(finalSample);
    }

    private getPrefilledSamples(): CreateSampleFormValues[] {
        const {supplyChains, selectedSupplyChainIds} = this.props;
        const findSupplyChain = (sId: number): Opt<SupplyChain> => opt(
            find((sCh: SupplyChain) => sCh.id === sId, supplyChains),
        );

        const prefilledSample = (sCh: Opt<SupplyChain>): CreateSampleFormValues[] => sCh
            .map((x): CreateSampleFormValues[] => x.milkRooms.map(m => ({
                ...initialCreateSampleState,
                ...this.sampleDefaults,
                supplyChainId: x.id,
                milkRoomId: m.id,
            })))
            .orElse([]);

        return flow(
            map(flow(findSupplyChain, prefilledSample)),
            flatten,
        )(opt(selectedSupplyChainIds).orCrash('Missing selectedSupplyChainIds data'));
    }

    private getBarcodes(): string[] {
        const {samples, samplesFromSupplyChain} = this.props;
        const samplesBarcodes = map(x => opt(x.barcode).orElse(''), samples);
        const samplesFromSupplyChainBarcodes = map(x => x.barcode, samplesFromSupplyChain);
        if (!isEmpty(samplesFromSupplyChainBarcodes)) {
            return concat(samplesFromSupplyChainBarcodes, samplesBarcodes);
        }
        return samplesBarcodes;
    }

    private getSamplesToAddFromSupplyChain(): CreateSampleFormValues[] {
        const {samplesFromSupplyChain} = this.props;
        return opt(filter(
            x => x.barcode !== '', samplesFromSupplyChain)).orElse([]);
    }
}

function setSampleOrder(sample: CreateSample, index: number): CreateSample {
    return {...sample, sampleOrder: index + 1};
}

const createFormName: keyof StoreFormState = 'createOrder';
const samplesFromSupplyChainFormName: keyof StoreFormState = 'samplesFromSupplyChain';
const createSampleFormName: keyof StoreFormState = 'createSample';

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    createFormValue: formValuesF(createFormName)(state),
    samples: formValuesF(createFormName)(state).chainToOpt(x => x.samples).orElse([]),
    samplesError: formState(createFormName)(state)
        .chainToOpt(x => x.syncErrors).chainToOpt(x => x.samples).orUndef(),
    samplesTouched: formState(createFormName)(state)
        .chainToOpt(x => x.fields).chainToOpt(x => x.samples).chainToOpt(s => s.touched).orFalse(),
    selectedSupplyChainIds: formValuesF(samplesFromSupplyChainFormName)(state).map(x => x.supplyChainIds).orUndef(),
    samplesFromSupplyChain: formValuesF(samplesFromSupplyChainFormName)(state).map(x => x.samples).orElse([]),
    currentCreateSample: formValuesF(createSampleFormName)(state).orUndef(),
    analyses: state.analysis.analyses || [],
    supplyChains: state.supplyChain.supplyChains || [],
    isCreateSampleFormValid: isValidF(createSampleFormName)(state),
    milkRooms: state.supplier.milkRooms || [],
    customerMilkRooms: state.user.customerMilkRooms.orUndef(),
    lastCreatedSample: state.order.lastCreatedSample || undefined,
    importedSamples: opt(state.order.importedSamples),
    selectedSampleCreationType: state.order.sampleCreationType || undefined,
    customerForOrder: state.order.customer,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleLoadCustomerMilkRooms: (customerId: number) => dispatch(loadCustomerMilkRooms(customerId)),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
    handleCreateSample: (x) => dispatch(addSampleInOrderCreation(x)),
    handleLoadSupplyChains: () => dispatch(loadSupplyChains()),
    handleResetSamplesFromSupplyChainForm: () => dispatch(reset(samplesFromSupplyChainFormName)),
    handleResetCreateOrderForm: () => {
        dispatch(destroyF(createFormName));
        dispatch(resetF('orderCustomer'));
        dispatch(selectSampleCreationType());
        dispatch(resetImportedSamples());
        dispatch(setLastCreatedSample(null));
    },
    handleResetImportedSamples: () => {
        dispatch(resetImportedSamples());
        dispatch(selectSampleCreationType());
    },
    handleResetWizard: () => dispatch(resetWizard()),
    handleSetAnalysesBySelectedDataItems: (analyses: Analysis[]) =>
        dispatch(setAnalysesBySelectedDataItems(analyses)),

    updateSamplesFromChain: (samples: CreateSampleFormValues[]) => {
        const field: keyof CreateOrderFormValues = 'samples';
        dispatch(updateSamplesFromSupplyChain(samples));
        dispatch(change(samplesFromSupplyChainFormName, field, samples));
        dispatch(touch(samplesFromSupplyChainFormName, field));
    },

    updateSampleFromChain: (idx: number, sample: CreateSampleFormValues, unsetLastCreatedSample: boolean) => {
        dispatch(change(samplesFromSupplyChainFormName, `samples[${idx}]`, sample));
        dispatch(setLastCreatedSample(unsetLastCreatedSample ? null : sample));
    },

    deleteSampleInOrderCreation: (idx: number, samples: CreateSample[]) => {
        const field: keyof CreateOrderFormValues = 'samples';
        dispatch(showConfirmDialog({
            title: 'Smazat vzorek',
            text: 'Opravdu chcete vzorek smazat?',
            confirmAction: change(createFormName, field, pullAt(idx, samples).map(setSampleOrder)),
        }));
    },

    handlePrefillFromImported: (sample: CreateSampleFormValues) => dispatch(prefillCreateSampleForm(sample)),

    addImportedSamplesInOrderCreation: () => dispatch(addImportedSamplesInOrderCreation()),

    prefillCustomerDetails: (
        customerDetails: Customer, formValues: CreateOrderFormValues,
    ) =>
        dispatch(prefillCreateOrderForm({
            ...formValues,
            customerDetails,
        })),

    handleSelectSampleCreationType: (type?: SampleCreationType) => dispatch(selectSampleCreationType(type)),
    handleLoadCustomerForOrder: (customerId: number) => dispatch(loadCustomerForOrder(customerId)),
    handleResetCustomerForOrder: () => dispatch(resetCustomerForOrder()),
    reloadMilkRoomsInOrderSamples: (customerMilkRooms: MilkRoom[]) =>
        dispatch(reloadMilkRoomsInOrderSamples(customerMilkRooms)),
    resetCustomerMilkRooms: () => dispatch(resetCustomerMilkRooms()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(New) as ComponentClass<OuterProps>;
