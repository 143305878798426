import {Dispatch} from 'redux';

import {Config} from 'app/Config';
import {MilkRoomValidation} from 'types/model/milkRooms/MilkRoomValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {validateMilkRoom} from '../../actions';
import {UpdateMilkRoomFormValues} from '../../types/UpdateMilkRoomFormValues';
import {UpdateMilkRoomFormProps} from '.';

type Values = UpdateMilkRoomFormValues;

export const validate = (values: Values): Errors<Values> => {
    const cfg = Config.forms;
    const vetIdLabel = 'Veterinární číslo';

    const validator = new DairyValidator(values);

    validator.nonEmpty('veterinaryId', vetIdLabel);
    validator.maxStringLength('veterinaryId', cfg.veterinaryIdMaxLen, vetIdLabel);
    validator.veterinaryId('veterinaryId', vetIdLabel);
    validator.nonEmpty('name', 'Název firmy/družstva');
    validator.nonEmpty('code', 'Kód');
    validator.milkRoomCode('code');

    return validator.generateErrorsObject();
};

export const asyncValidate = (values: Values, dispatch: Dispatch, props: UpdateMilkRoomFormProps): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
        const {milkRoomId, supplierId} = props;
        const {name, code} = values;
        const actionValues: MilkRoomValidation = {name, code, milkRoomId};

        dispatch(validateMilkRoom({
            supplierId,
            values: actionValues,
            resolve,
            reject,
        }));
    });
