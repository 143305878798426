import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    measurementId: IntegerSchema,
    result: t.number,
    dataItemId: IntegerSchema,
});

export const SimpleMeasurementResultSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SimpleMeasurementResult extends t.TypeOf<typeof SimpleMeasurementResultSchema> {}
