import React, {FC} from 'react';

import {EmptyPage} from 'layout/components';

const Contact: FC = () =>
    <EmptyPage narrow>
        <h5>
            Kontakty
        </h5>
        <div className="pt-3">
            <div>LRM Brno</div>
            <div>adresa: Popelova 53, 620 00 Brno – Tuřany</div>
            <div>email: <a href="mailto:lrmbrno.mlekarny@cmsch.cz">lrmbrno.mlekarny@cmsch.cz</a></div>
            <div>tel. laboratoř: <a href="tel:+420607085398">607 085 398</a></div>
        </div>
        <div className="pt-3">
            <div>Svoz a logistika beden, harmonogram svozů</div>
            <div>Ing. David Lipovský</div>
            <div>email: <a href="mailto:lipovsky@cmsch.cz">lipovsky@cmsch.cz</a></div>
            <div>tel.: <a href="tel:+420602116742">602 116 742</a></div>
        </div>
        <div className="pt-3">
            <div>Laboratoř, zpracování vzorků</div>
            <div>Ing. Martina Tišnovská</div>
            <div>email: <a href="mailto:tisnovska@cmsch.cz">tisnovska@cmsch.cz</a></div>
            <div>tel.: <a href="tel:+420602263985">602 263 985</a></div>
        </div>
    </EmptyPage>;

export default Contact;
