import {Response, putAll, showBeError, ErrorOptions} from 'favorlogic-utils';
import {SagaIterator} from 'redux-saga';

export function* handleResponseError(
    response: Response<unknown>,
    title: string,
    options?: ErrorOptions,
): SagaIterator {
    yield putAll(showBeError(response, title, options));
}
