import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {showSuccess, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF} from 'utils/formHelpers';

import {
    UpdateMilkRoomAction,
    loadMilkRoom,
    updateMilkRoomError,
    updateMilkRoomSuccess,
} from '../actions';
import Api from '../api';

function* execute(action: UpdateMilkRoomAction): SagaIterator {
    const title = 'Upravení mléčnice';
    const values = (yield* select(formValuesF('updateMilkRoom'))).orCrash('missing form values');
    const addr = action.payload;
    const response = yield* call(Api.updateMilkRoom, addr.milkRoomId, values);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, title);
        return yield* put(updateMilkRoomError(error));
    }

    yield* put(updateMilkRoomSuccess());
    yield* put(showSuccess(title, 'Změny uloženy'));
    yield* put(routerActions.goBack());
    yield* put(loadMilkRoom(addr));
}

export function* updateMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/UPDATE_MILK_ROOM', execute);
}
