import React, {ReactNode, PureComponent} from 'react';

import {Fields} from 'forms';
import {RefInput} from 'forms/components/Input';
import {npUnitOptions} from 'measurement/type/npUnits';
import {RefSelect} from 'forms/components/BasicSelect';

interface ResultProps {
    name: string;
    rowIndex: number;
    inputRef: RefInput | RefSelect<string>;
    isNP: boolean;

    onResultEnter(rowIndex: number): void;
}

export class Result extends PureComponent<ResultProps> {
    render(): ReactNode {
        const {name, rowIndex, inputRef, isNP} = this.props;

        return isNP
            ? <Fields.Select<string>
                name={name}
                label="Výsledek*"
                options={npUnitOptions}
                autoFocus={rowIndex === 0}
                selectRef={inputRef as RefSelect<string>}
                onEnter={this.onResultEnter}
            />
            : <Fields.Input
                name={name}
                type="number"
                label="Výsledek*"
                autoFocus={rowIndex === 0}
                inputRef={inputRef as RefInput}
                onEnter={this.onResultEnter}
            />
        ;
    }

    private onResultEnter = (): void => {
        const {rowIndex, onResultEnter} = this.props;

        onResultEnter(rowIndex);
    }
}
