import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {SelectOptions} from 'forms/components/BasicSelect';
import {ExportGeometricMeansFormValues} from 'measurement/type/ExportGeometricMeansFormValues';
import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {validate} from './validations';

interface OwnProps {
    isAdminOrLaborer?: boolean;
    customerOptions?: SelectOptions<number>;
}

export type Props = PropsForForm<ExportGeometricMeansFormValues, OwnProps>;

class ExportGeometricMeansForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            customerOptions,
            isAdminOrLaborer,
        } = this.props;
        return (
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />

                <h2>Exportovat geometrické průměry</h2>

                {isAdminOrLaborer && <div className="row mt-2">
                    <div className="col-12">
                        <Fields.Select<number>
                            name="customerId"
                            label="Zákazník*"
                            options={customerOptions || []}
                        />
                    </div>
                </div>}

                <div className="row">
                    <div className="col-12 mt-2">
                        <Fields.Checkbox
                            name="suspicious"
                            label="Zahrnout podezřelé"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <Buttons.Button
                            fullWidth
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Stáhnout
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }
}

export default Forms.withForm<ExportGeometricMeansFormValues, OwnProps, Props>(ExportGeometricMeansForm, {
    form: 'exportGeometricMeans',
    validate,
    initialValues: {
        suspicious: false,
        customerId: null,
    },
});
