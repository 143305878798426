import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {DilutionSchema} from 'types/generic/DilutionSchema';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    sampleId: IntegerSchema,
    result: t.number,
    dilution: DilutionSchema,
    procedureId: IntegerSchema,
});

export const CreateMeasurementSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateMeasurement extends t.TypeOf<typeof CreateMeasurementSchema> {}
