import * as t from 'io-ts';

import {UpdateCustomerBaseSchema} from './UpdateCustomerBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('UpdateCustomerByCustomer'),
});

export const UpdateCustomerByCustomerSchema = t.exact(t.intersection([
    UpdateCustomerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateCustomerByCustomer extends t.TypeOf<typeof UpdateCustomerByCustomerSchema> {}
