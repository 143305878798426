import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {UpdateMeasurementFormValues} from 'measurement/type/UpdateMeasurementFormValues';

type UpdateMeasurementState = GenericFormState<UpdateMeasurementFormValues>;

const formReducer: CFormReducer = {
    updateMeasurement: (state: UpdateMeasurementState, action: Action) => {
        switch (action.type) {
            default:
                return state;
        }
    },
};

export default formReducer;
