import {SagaIterator} from 'redux-saga';
import {
    putAll,
    showBeError,
    showSuccess,
    takeLatestF,
} from 'favorlogic-utils';
import {call, put, select} from 'typed-redux-saga';

import {formValuesF} from 'utils/formHelpers';

import {PickupLineApi} from '../api';
import {pickupLineAction, UpdatePickupLinesOptionsAction} from '../actions';
import {PICKUP_LINE_PLANNING_FORM_NAME} from '../components/PickupLinePlanningForm/pickupLinePlanningFormValues';
import {parsePlanningFormValues} from '../utils/parsePlanningFormValues';

const title = 'Aktualizace plánu svozových linek';

function* execute (action: UpdatePickupLinesOptionsAction): SagaIterator {
    const {yearMonth} = action.payload;

    const formValues = (yield* select(formValuesF(PICKUP_LINE_PLANNING_FORM_NAME)))
        .orCrash('no pickupLine plan form values');
    const data = parsePlanningFormValues(formValues);
    const response = yield* call(PickupLineApi.updatePickupLinesOptions, data);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLinesOptions(yearMonth));
        yield* put(showSuccess(title, 'Plán byl úspěšně aktualizován.'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* updatePickupLinesOptionsSaga(): SagaIterator {
    yield takeLatestF('pickupLine/UPDATE_PICKUP_LINES_OPTIONS', execute);
}
