import React, {FC} from 'react';
import {isEmpty} from 'lodash/fp';

import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';

export interface Props {
    samples: OrderSampleView[];
    onClickSample(barcode: string): void;
}

export const SampleButtons: FC<Props> = (props) => {
    const {samples, onClickSample} = props;

    if (isEmpty(samples)) {
        return null;
    }

    return (
        <Layout.Panel>
            {samples.map(sample => {
                const clickSample = () => {
                    onClickSample(sample.barcode);
                };

                return (
                    <Buttons.Button
                        type="button"
                        onClick={clickSample}
                        importance="primary"
                        key={sample.sampleId}
                        className="m-1"
                    >
                        {sample.barcode}
                    </Buttons.Button>
                );
            })}
        </Layout.Panel>
    );
};
