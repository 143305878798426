import CustomersList from './CustomersList';
import EditCustomer from './EditCustomer';
import LaborersList from './LaborersList';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import Update from './Update';
import UsersList from './UsersList';
import NewUser from './NewUser';
import UpdateUser from './UpdateUser';
import ResetPassword from './ResetPassword';
import ResetPasswordEmail from './ResetPasswordEmail';

export {
    Login,
    Logout,
    Register,
    Update,
    CustomersList,
    EditCustomer,
    LaborersList,
    UsersList,
    NewUser,
    UpdateUser,
    ResetPassword,
    ResetPasswordEmail,
};
