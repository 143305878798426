import {SelectOptions} from 'forms/components/BasicSelect';

export const npUnits = ['N', 'P', 'P1', 'P2', 'P3', 'P4'];

export const npUnitOptions: Readonly<SelectOptions<string>> = [
    {label: 'N', value: '0'},
    {label: 'P', value: '1'},
    {label: 'P1', value: '2'},
    {label: 'P2', value: '3'},
    {label: 'P3', value: '4'},
    {label: 'P4', value: '5'},
];
