import React from 'react';
import {classNames} from 'favorlogic-utils';
import {Panel} from 'layout/components';

import styles from './styles.sass';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';

interface Props {
    annotations: ColorAnnotation[];
}

const Legend: React.FunctionComponent<Props> = props => {
    const {annotations} = props;

    const renderAnnotation = (annotation: ColorAnnotation) =>
        <div
            key={annotation.color}
            className={styles.annotation}
        >
            <span className={classNames(styles.colorSquare, styles[annotation.color])} />
            {annotation.label}
        </div>;

    return (
        <Panel className="mt-2">
            Legenda: {annotations.map(a => renderAnnotation(a))}
        </Panel>
    );
};

export default Legend;
