import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {UpdateCustomerByLaborerBaseSchema} from './UpdateCustomerByLaborerBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('CustomerDetailsForLaborer'),
    id: IntegerSchema,
    created: DateStringSchema,
    username: t.string,
});

export const CustomerDetailsForLaborerSchema = t.exact(t.intersection([
    UpdateCustomerByLaborerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface CustomerDetailsForLaborer extends t.TypeOf<typeof CustomerDetailsForLaborerSchema> {}
