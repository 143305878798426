import * as t from 'io-ts';

export const SampleDeliveryTypeSchema = t.keyof({
    PERSONALLY: null,
    COURIER: null,
    LABORATORY_COLLECTION: null,
    MAIL: null,
});

export type SampleDeliveryType = t.TypeOf<typeof SampleDeliveryTypeSchema>;
