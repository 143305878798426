import React, {FC} from 'react';
import {Form} from 'redux-form';
import {Opt} from 'ts-opt';
import {validateKeyGen, todayDate} from 'favorlogic-utils';
import {isEmpty} from 'lodash/fp';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Icons} from 'icons';
import {Components as Layout} from 'layout';
import {SampleHarvestType} from 'types/model/enumeration/SampleHarvestType';
import {SampleType} from 'types/model/enumeration/SampleType';
import {SelectOptions} from 'forms/components/BasicSelect';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';

import {sampleHarvestTypeOptions} from '../../enums/SampleHarvestTypeEnum';
import {sampleTypeOptions} from '../../enums/SampleTypeEnum';
import {UpdateSampleFormValues} from '../../types/UpdateSampleFormValues';
import {AdditionalInfo} from './additionalInfo';

import infoSvg from './infoIcon.svg';

import styles from './styles.sass';

import {validate} from './validations';

const showResultsOnWebTooltip = 'Výsledky rozborů budou automaticky odeslány do aplikace https://data.cmsch.cz';
const validateKey = validateKeyGen<UpdateSampleFormValues>();

export interface OwnProps {
    sample: SampleDetails | OrderSampleView | null;
    mode: 'ADD' | 'EDIT';
    analysesOptions: SelectOptions<number>;
    hasGloballyValues?: boolean;
    milkRoomsOptions?: SelectOptions<number>;
    enableShowResults?: boolean;
    initialValues?: UpdateSampleFormValues;
    showDairyTraits?: boolean;
    formValues?: UpdateSampleFormValues;
    allowQCZ: boolean;

    setGlobally?(): void;
    cancelSetGlobally?(): void;
}

type Props = PropsForForm<UpdateSampleFormValues, OwnProps>;

const UpdateSampleForm: FC<Props> = (
    {
        handleSubmit,
        valid,
        submitting,
        sample,
        mode,
        analysesOptions,
        milkRoomsOptions,
        enableShowResults,
        showDairyTraits,
        formValues,
        hasGloballyValues,
        cancelSetGlobally,
        setGlobally,
        change,
        allowQCZ,
    }
) => {
    const isCistern = Boolean(formValues?.cistern);
    const resultsOnWebChecked = Boolean(formValues?.showResultsOnWeb);

    const onShowResultsOnWebChanged = (value: Opt<boolean>) => {
        if (!value.orFalse()) {
            change('showResultsToBreeders', false);
        }
    };

    return (
        <Layout.Panel>
            <Form
                className="position-relative"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={!sample || submitting} />
                <div className="row">
                    <div className="col-12 mt-2 mb-3">
                        <strong>Údaje z objednávky</strong>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('barcode')}
                            type="text"
                            label="Čárový kód"
                            tooltip="Unikátní identifikace vzorku"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('sampleSourceAmount')}
                            type="text"
                            label="Množství"
                        />

                        <div className={styles.infoIcon}>
                            <Icons.SvgIcon
                                title="Množství, ze kterého byl proveden odběr (v litrech)"
                                icon={infoSvg}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <Fields.DateTime
                            name={validateKey('samplingDate')}
                            mode="DATE"
                            label="Datum odběru*"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.DateTime
                            name={validateKey('samplingTime')}
                            mode="TIME"
                            label="Čas odběru"
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Input
                            name={validateKey('samplingTemperature')}
                            type="text"
                            label="Teplota při odběru"
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <Fields.Select<SampleType>
                            name={validateKey('type')}
                            label="Typ vzorku*"
                            options={sampleTypeOptions}
                        />
                    </div>
                    <div className="col-md-6">
                        <Fields.Select<SampleHarvestType>
                            name={validateKey('harvestType')}
                            label="Způsob odběru*"
                            options={sampleHarvestTypeOptions}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <Fields.Select<number>
                            name={validateKey('analysisIds')}
                            label="Požadované rozbory*"
                            options={analysesOptions}
                            multi
                        />
                    </div>
                    <div className="col-md-6">
                        {milkRoomsOptions && (
                            isEmpty(milkRoomsOptions)
                                ? <div className="mt-3">
                                    Nejsou dostupné žádné mléčnice.
                                </div>
                                : <Fields.Select<number>
                                    name={validateKey('milkRoomId')}
                                    label="Mléčnice"
                                    options={milkRoomsOptions}
                                    disabled={isCistern}
                                />
                        )}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <Fields.Input
                            name={validateKey('customerNote')}
                            type="text"
                            label="Poznámka zákazníka"
                        />
                    </div>
                </div>
                {enableShowResults && <div className="row mt-2">
                    <div className="col-md-4">
                        <Fields.Checkbox
                            name={validateKey('showResultsOnWeb')}
                            label="Zasílat výsledky na web"
                            tooltip={showResultsOnWebTooltip}
                            onFieldChange={onShowResultsOnWebChanged}
                        />
                    </div>
                    <div className="col-md-4">
                        <Fields.Checkbox
                            name={validateKey('showResultsToBreeders')}
                            label="Zasílat výsledky chovatelům"
                            disabled={!resultsOnWebChecked}
                            tooltip={!resultsOnWebChecked
                                ? 'Není možné odeslat výsledky chovateli, pokud nejsou zaslány na web'
                                : undefined
                            }
                        />
                    </div>
                </div>}
                <AdditionalInfo
                    hasGloballyValues={hasGloballyValues}
                    showDairyTraits={showDairyTraits}
                    formValues={formValues}
                    allowQCZ={allowQCZ}
                    setGlobally={setGlobally}
                    cancelSetGlobally={cancelSetGlobally}
                    change={change}
                />
                <div className="d-flex justify-content-end mt-4">
                    {mode === 'EDIT' && <Buttons.Button
                        fullWidth
                        importance="primary"
                        disabled={!valid || submitting}
                        type="submit"
                    >
                        Uložit
                    </Buttons.Button>}
                </div>
            </Form>
        </Layout.Panel>
    );
};

export default Forms.withForm<UpdateSampleFormValues, OwnProps, Props>(UpdateSampleForm, {
    form: 'updateSample',
    validate,
    enableReinitialize: true,
    initialValues: {
        barcode: '',
        sampleSourceAmount: '',
        samplingTemperature: '',
        samplingTime: '',
        samplingDate: todayDate(),
        type: null,
        harvestType: null,
        milkRoomId: null,
        supplyChainCode: null,
        customerNote: null,
        analysisIds: [],
        deliveryTemperature: '',
        qualitative: false,
        cistern: false,
        control: false,
        subsidy: false,
        canceled: false,
        disabled: false,
        note: '',
        preservatives: [],
        showResultsOnWeb: false,
        showResultsToBreeders: false,
        traitNote: null,
    },
});
