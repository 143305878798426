import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {extractFormErrorsFromResponse, showSuccess, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {UpdateSample} from 'types/model/samples/UpdateSample';
import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';
import {resetCurrentSample, createBarcodeSearch, acceptOrder} from 'order/actions';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {AcceptSampleAction, loadSamplesByOrder} from '../actions';
import Api from '../api';
import {prepareUpdateSample} from './updateSampleSagaUtils';

const title = 'Přijetí vzorku';

function* prepareSampleData(): SagaIterator<UpdateSample> {
    const values = (yield* select(formValuesF('updateSample')))
        .orCrash('missing form values');
    const user = (yield* select((state: StoreState) => state.user.currentUser))
        .orCrash('missing current user');
    const isLaborer = user.role === 'LABORER' || user.role === 'ADMIN';

    return prepareUpdateSample(values, isLaborer);
}

function* handleUpdateErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('updateSample', extractFormErrorsFromResponse(response)));
    yield* put(isLoading(false));
}

function* handleAcceptErrorResponse(response: ErrorResponse) {
    yield* call(handleResponseError, response, title);
    yield* put(isLoading(false));
}

function* getOrderId(): SagaIterator<number> {
    const order = opt(yield* select((state: StoreState) => state.order.current))
        .orCrash('missing current order');

    return order.id;
}

function* handleSuccess(orderId: number, isLastAccepted: boolean): SagaIterator {
    yield* put(showSuccess(title, 'Vzorek přijat.'));
    yield* put(isLoading(false));
    yield* put(resetCurrentSample());
    yield* put(createBarcodeSearch(''));
    yield* put(loadSamplesByOrder(orderId));

    if (isLastAccepted) {
        yield* put(acceptOrder(orderId));
    }
}

function* executeUpdate(sampleId: number): SagaIterator<boolean> {
    const sampleData = yield* call(prepareSampleData);
    const response = yield* call(Api.updateSample, sampleId, sampleData);

    if (!response.isSuccess) {
        yield* call(handleUpdateErrorResponse, response);
    }

    return response.isSuccess;
}

function* executeAccept(sampleId: number): SagaIterator<boolean> {
    const response = yield* call(Api.acceptSample, sampleId);

    if (!response.isSuccess) {
        yield* call(handleAcceptErrorResponse, response);
    }

    return response.isSuccess;
}

function* execute({payload: {sampleId, isLastAccepted}}: AcceptSampleAction): SagaIterator {
    const orderId = yield* call(getOrderId);

    yield* put(isLoading(true));

    const updateIsOk = yield* call(executeUpdate, sampleId);
    if (!updateIsOk) { return; }

    const acceptIsOk = yield* call(executeAccept, sampleId);
    if (!acceptIsOk) { return; }

    yield* call(handleSuccess, orderId, isLastAccepted);
}

export function* acceptSampleSaga(): SagaIterator {
    yield takeLatestF('sample/ACCEPT_SAMPLE', execute);
}
