import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';
import {MilkRoomCodeSchema} from 'types/generic/MilkRoomCodeSchema';

import {SimpleMeasurementResultSchema} from './SimpleMeasurementResult';

const optionalPart = t.partial({
    milkRoomName: t.string,
    milkRoomCode: MilkRoomCodeSchema,
});
const requiredPart = t.interface({
    sampleId: IntegerSchema,
    orderId: IntegerSchema,
    barcode: BarcodeSchema,
    samplingDate: DateStringSchema,
    accepted: DateStringSchema,
    measuredFrom: DateStringSchema,
    measuredTo: DateStringSchema,
    qualitative: t.boolean,
    cistern: t.boolean,
    control: t.boolean,
    canceled: t.boolean,
    disabled: t.boolean,
    subsidy: t.boolean,
    hasAllMeasurements: t.boolean,
    customerName: t.string,
    canDelete: t.boolean,
    showResultsOnWeb: t.boolean,
    analysisIds: t.array(IntegerSchema),
    results: t.array(SimpleMeasurementResultSchema),
});

export const MeasurementViewSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementView extends t.TypeOf<typeof MeasurementViewSchema> {}
