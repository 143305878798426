import React, {FunctionComponent, memo} from 'react';
import {classNames} from 'favorlogic-utils';
import {isEmpty} from 'lodash/fp';

import {DateString} from 'types/generic/DateString';
import {PickupLineAnalysis} from 'types/model/pickupLine/PickupLineAnalysis';
import {PickupLineLoading} from 'pickupLine/types/PickupLineLoading';
import {LoaderWrapper} from 'layout/components';

import {DaysColumn} from '../DaysColumn';
import {AnalysisColumn} from './analysisColumn';

import styles from './styles.sass';

interface Props {
    loading: PickupLineLoading[];
    overviewDate: DateString | null;
    analysis: PickupLineAnalysis[];
}

const OverviewTableBase: FunctionComponent<Props> = props => {
    const {loading, overviewDate, analysis} = props;

    return (
        <LoaderWrapper showLoader={loading.includes('overview')}>
            {!overviewDate || isEmpty(analysis)
                ? <div>Pro zvolený měsíc neexistují data.</div>
                : <div className={classNames(styles.customTable, styles.scrollbarTop, 'd-fit-content')}>
                    <div className={styles.scrollbarTop}>
                        <DaysColumn
                            date={overviewDate}
                            footerText="Celkově"
                        />
                        <div className="d-inline-block">
                            {analysis.map(x =>
                                <AnalysisColumn
                                    analysis={x}
                                    key={x.id}
                                />
                            )}
                        </div>
                    </div>
                </div>
            }
        </LoaderWrapper>
    );
};

export const OverviewTable = memo(OverviewTableBase);
