exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-supplyChain-components-SupplyChainForm-___styles__form___1-pdU {\n  min-width: 25em; }\n", ""]);

// exports
exports.locals = {
	"form": "src-modules-supplyChain-components-SupplyChainForm-___styles__form___1-pdU"
};