import React, {FunctionComponent} from 'react';
import {Form} from 'redux-form';

import {PropsForForm} from 'forms/components/withForm';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Loader} from 'layout/components';
import {Fields} from 'forms';
import {Button} from 'buttons/components';

import {WritePickupLineFormValues} from './writePickupLineFormValues';

export interface OwnProps {
    customerOptions: SelectOptions<number>;
    pickupLineIdInEdit?: number;
}

export type Props = PropsForForm<WritePickupLineFormValues, OwnProps>;

export const WritePickupLineForm: FunctionComponent<Props> = props => {
    const {customerOptions, pickupLineIdInEdit, valid, submitting, handleSubmit} = props;

    return (
        <Form
            onSubmit={handleSubmit}
        >
            <Loader show={submitting} />

            <h2>{pickupLineIdInEdit ? 'Upravit svozovou linku' : 'Vytvořit novou svozovou linku'}</h2>

            <div className="row">
                <div className="col-12">
                    <Fields.Input
                        name="name"
                        label="Název linky"
                        type="text"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.Select
                        name="customerIds"
                        label="Zákazníci"
                        options={customerOptions}
                        multi
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Fields.ColorPicker
                        name="color"
                        label="Barva linky"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-4">
                    <Button
                        fullWidth
                        importance="primary"
                        disabled={!valid || submitting}
                        type="submit"
                    >
                        {pickupLineIdInEdit ? 'Upravit' : 'Vytvořit'}
                    </Button>
                </div>
            </div>
        </Form>
    );
};
