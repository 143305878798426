import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    importedSamples: IntegerSchema,
    missingBarcodesOfSamples: t.array(BarcodeSchema),
    notRequiredBarcodesOfSamples: t.array(BarcodeSchema),
});

export const MeasurementImportResultsSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementImportResults extends t.TypeOf<typeof MeasurementImportResultsSchema> {}
