exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-forms-components-ColorPicker-___styles__cover___3wlZl {\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px; }\n\n.src-modules-forms-components-ColorPicker-___styles__popover___DbZ_U {\n  position: absolute;\n  z-index: 2; }\n\n.src-modules-forms-components-ColorPicker-___styles__withDeleteButton___2PcdQ > span {\n  position: absolute;\n  right: 8px;\n  top: 24px; }\n  .src-modules-forms-components-ColorPicker-___styles__withDeleteButton___2PcdQ > span > svg {\n    fill: #999;\n    cursor: pointer; }\n    .src-modules-forms-components-ColorPicker-___styles__withDeleteButton___2PcdQ > span > svg:hover {\n      fill: #777; }\n", ""]);

// exports
exports.locals = {
	"cover": "src-modules-forms-components-ColorPicker-___styles__cover___3wlZl",
	"popover": "src-modules-forms-components-ColorPicker-___styles__popover___DbZ_U",
	"withDeleteButton": "src-modules-forms-components-ColorPicker-___styles__withDeleteButton___2PcdQ"
};