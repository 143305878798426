import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {showSuccess, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF} from 'utils/formHelpers';

import {SyncCmschParams} from '../types/SyncCmschParams';
import {SyncCmschAction} from '../actions';
import Api from '../api';

const title = 'Odeslání dat do aplikace "Přístup k datům"';

function* getSyncCmschParams(): SagaIterator<SyncCmschParams> {
    return (yield* select(formValuesF('syncCmsch')))
        .orCrash('missing form data');
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Data byla synchronizována'));
    yield* put(routerActions.push('/'));
}

function* execute(_: SyncCmschAction): SagaIterator {
    const params = yield* call(getSyncCmschParams);
    const response = yield* call(Api.syncCmsch, params);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleResponseError, response, title);
}

function* syncCmschSaga(): SagaIterator {
    yield takeLatestF('administration/SYNC_CMSCH', execute);
}

export default syncCmschSaga;
