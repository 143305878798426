import React, {Component, ComponentClass, ReactNode} from 'react';

import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

interface OuterProps {
    children: ReactNode;
}

interface InnerProps {}

type Props = InnerProps & OuterProps;

class Group extends Component<Props> {
    render(): ReactNode {
        const {children} = this.props;
        const classes = classNames(
            styles.group,
            'd-flex align-items-center flex-wrap',
        );

        return (
            <div className={classes}>
                {children}
            </div>
        );
    }
}

export default Group as ComponentClass<OuterProps>;
