import {Address} from 'types/model/common/Address';

export interface BillingAddressShape {
    address: Address;
    billingAddress: Address;
}

export const genHasSameBillingAddress = (obj: BillingAddressShape): boolean =>
    obj.address.street === obj.billingAddress.street &&
    obj.address.city === obj.billingAddress.city &&
    obj.address.zip === obj.billingAddress.zip;
