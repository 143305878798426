import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {Analysis} from 'types/model/analyses/Analysis';
import {handleResponseError} from 'utils/handleResponseError';

import {loadAnalysesError, loadAnalysesSuccess} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const errorTitle = 'Načtení rozborů';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, errorTitle);
    yield* put(loadAnalysesError(error));
}

function* handleSuccessResponse({data: analyses}: SuccessResponse<Analysis[]>): SagaIterator {
    yield* put(loadAnalysesSuccess(analyses));
}

export function* executeLoadAnalysis(): SagaIterator {
    const response = yield* call(Api.getAnalyses);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* loadAnalysesSaga(): SagaIterator {
    yield takeLatestF('analysis/LOAD_ANALYSES', executeLoadAnalysis);
}
