exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-order-components-ShowOrderStats-___styles__separator___1nDo9 {\n  height: .1em;\n  background-color: #cecece;\n  margin: 1em .1em 1em .15em; }\n\n.src-modules-order-components-ShowOrderStats-___styles__analysesTag___1GZ58 {\n  max-width: 100%;\n  transition: background-color .15s ease-in-out;\n  cursor: default;\n  padding: .2em .5em;\n  margin: .1em 0; }\n  .src-modules-order-components-ShowOrderStats-___styles__analysesTag___1GZ58:hover {\n    background-color: #cecece; }\n  .src-modules-order-components-ShowOrderStats-___styles__analysesTag___1GZ58 .src-modules-order-components-ShowOrderStats-___styles__name___3Sbtc {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden; }\n  .src-modules-order-components-ShowOrderStats-___styles__analysesTag___1GZ58 .src-modules-order-components-ShowOrderStats-___styles__count___3A2ks {\n    margin-left: 0.33em; }\n\n.src-modules-order-components-ShowOrderStats-___styles__sectionCaption___37irA {\n  color: #434b53;\n  cursor: default; }\n", ""]);

// exports
exports.locals = {
	"separator": "src-modules-order-components-ShowOrderStats-___styles__separator___1nDo9",
	"analysesTag": "src-modules-order-components-ShowOrderStats-___styles__analysesTag___1GZ58",
	"name": "src-modules-order-components-ShowOrderStats-___styles__name___3Sbtc",
	"count": "src-modules-order-components-ShowOrderStats-___styles__count___3A2ks",
	"sectionCaption": "src-modules-order-components-ShowOrderStats-___styles__sectionCaption___37irA"
};