import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
    code: t.string,
    name: t.string,
    supplyChainId: IntegerSchema,
    milkRoomIds: t.array(IntegerSchema),
});
const requiredPart = t.interface({});

export const SupplyChainValidationSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SupplyChainValidation extends t.TypeOf<typeof SupplyChainValidationSchema> {}
