import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/test"
        component={Screens.Test}
        key="test"
    />,
    <Route
        exact
        path="/test/forms"
        component={Screens.TestForms}
        key="test-forms"
    />,
];

export default routes;
