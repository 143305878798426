import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {loadDevice} from '../actions';
import {DataItem} from 'types/model/dataItems/DataItem';
import {loadDataItems} from 'dataItem/actions';
import {Device} from 'types/model/devices/Device';
import DeviceDetail from 'device/components/DeviceDetail';
import {SelectOptions} from 'forms/components/BasicSelect';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface StateProps {
    device: Device | null;
    dataItems: DataItem[];
}

interface DispatchProps {
    loadDataItems(): void;
    loadDevice(id: number): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class DeviceEdit extends Component<Props> {
    private get deviceId(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    componentDidMount(): void {
        const {loadDevice, loadDataItems} = this.props;
        loadDataItems();
        loadDevice(this.deviceId);
    }

    render(): ReactNode {
        const {dataItems, device} = this.props;
        const dataItemsOptions: SelectOptions<number> = dataItems.map(dataItem => ({
            value: dataItem.id,
            label: dataItem.name,
        }));

        return (
            <Layout.ItemPage
                title="Detail přístroje"
                backLabel="Přístroje"
            >
                {device && <DeviceDetail
                    device={device}
                    dataItemsOptions={dataItemsOptions}
                />}
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    device: state.device.device,
    dataItems: state.dataItem.dataItems || [],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadDataItems: () => dispatch(loadDataItems()),
    loadDevice: (id: number) => dispatch(loadDevice(id)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(DeviceEdit) as ComponentClass<OuterProps>;
