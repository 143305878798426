import React, {PureComponent, ReactNode} from 'react';
import {Field} from 'redux-form';

import {withGenericFormFieldElements} from 'forms/components/Field/withGenericFormFieldElements';
import Select, {SelectInnerProps, SelectOuterProps} from 'forms/components/Select';

class SelectField<OptionT> extends PureComponent<SelectInnerProps<OptionT>> {
    render(): ReactNode {
        return withGenericFormFieldElements(Select, this.props);
    }
}

// TODO: Split to single and multi variants, so OptionT/TValue will be actually correct (e.g. onFieldChange, onAction)
//       T | null for single variant, T[] for multi variant
class SelectFieldOuter<OptionT> extends PureComponent<SelectOuterProps<OptionT>> {
    render(): ReactNode {
        const {
            name,
            label,
            className,
            disabled,
            helpText,
            onAction,
            tooltip,
            wrapperClassName,
            async,
            asyncMinLength,
            debounceTime,
            loadOptions,
            multi,
            options,
            onFieldBlur,
            onFieldChange,
            onFieldFocus,
            autoFocus,
            onEnter,
            selectRef,
            fieldIsLoading,
            clearable,
            noMargin,
        } = this.props;

        return <Field
            component={SelectField}
            name={name}
            async={async}
            asyncMinLength={asyncMinLength}
            debounceTime={debounceTime}
            loadOptions={loadOptions}
            multi={multi}
            onFieldChange={onFieldChange}
            onFieldBlur={onFieldBlur}
            onFieldFocus={onFieldFocus}
            options={options}
            label={label}
            className={className}
            disabled={disabled}
            helpText={helpText}
            onAction={onAction}
            tooltip={tooltip}
            wrapperClassName={wrapperClassName}
            autoFocus={autoFocus}
            onEnter={onEnter}
            selectRef={selectRef}
            fieldIsLoading={fieldIsLoading}
            clearable={clearable}
            noMargin={noMargin}
        />;
    }
}

export default SelectFieldOuter;
