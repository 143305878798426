import AcceptSamples from './AcceptSamples';
import Detail from './Detail';
import List from './List';
import New from './New';
import AcceptOrder from './AcceptOrder';
import Summary from './Summary';
import NewOrderInfo from './New.OrderInfo';
import NewEditSample from './New.EditSample';
import GenerateProtocol from './GenerateProtocol';
import EditNewSample from './EditNewSample';
import NewShippingTicket from './New.ShippingTicket';
import NewOrderCustomer from './New.OrderCustomer';
import EditExistingSample from './EditExistingSample';

export {
    AcceptSamples,
    List,
    Detail,
    New,
    AcceptOrder,
    Summary,
    NewOrderInfo,
    GenerateProtocol,
    EditNewSample,
    NewShippingTicket,
    NewEditSample,
    NewOrderCustomer,
    EditExistingSample,
};
