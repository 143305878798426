import {opt} from 'ts-opt';
import {filter, map, sortBy} from 'lodash/fp';

import {SelectOptions, SelectOption} from 'forms/components/BasicSelect';
import {Analysis} from 'types/model/analyses/Analysis';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {Customer} from 'types/model/customers/Customer';

const buildMilkRoomOption = (milkRoom: MilkRoom): SelectOption<number> => ({
    value: milkRoom.id,
    label: `${milkRoom.name} (${milkRoom.veterinaryId})`,
});

export const getMilkRoomsOptions = (milkRooms: MilkRoom[] | null): SelectOptions<number> | undefined =>
    opt(milkRooms)
        .mapFlow(
            filter(x => x.active),
            map(buildMilkRoomOption),
        )
        .orUndef();

const isAvailable = (analysis: Analysis, priceClass?: PriceClass): boolean =>
    analysis.availability === 'ALL' || analysis.availability === priceClass;

export const getAnalysesOptions = (analyses: Analysis[], priceClass?: PriceClass): SelectOptions<number> =>
    sortBy<Analysis>('index')(analyses)
        .filter(analysis => isAvailable(analysis, priceClass))
        .map(analysis => ({value: analysis.id, label: `${analysis.index} - ${analysis.name}`}));

const getSupplyChainOption = (supplyChain: SupplyChain): SelectOption<number> => ({
    value: supplyChain.id,
    label: supplyChain.name ? `${supplyChain.code} (${supplyChain.name})` : supplyChain.code,
});

export const getSupplyChainsOptions = (supplyChains: SupplyChain[]): SelectOptions<number> =>
    supplyChains.map(getSupplyChainOption);

export const getCustomerNamesOptions = (customers: Customer[]): SelectOptions<number> =>
    customers.map(c => ({value: c.id, label: c.customerName}));
