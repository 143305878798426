import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {UpdateDataItem} from 'types/model/dataItems/UpdateDataItem';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {
    UpdateDataItemAction,
    updateDataItemError,
    updateDataItemSuccess,
} from '../actions';
import Api from '../api';
import {DataItemFormValues} from '../types/DataItemFormValues';

const title = 'Upravení datové položky';
const dataItemForm = 'dataItem';

export function prepareUpdateData(formValues: DataItemFormValues): UpdateDataItem {
    return {
        numOfDecimalPlaces: parseInt(formValues.numOfDecimalPlaces, 10),
        minimum: parseInt(formValues.minimum, 10),
        maximum: parseInt(formValues.maximum, 10),
        measurementUnit: formValues.measurementUnit,
        dilution: parseInt(formValues.dilution, 10),
        checkErrors: formValues.checkErrors,
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateDataItemError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF(dataItemForm, extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně upravena.'));
    yield* put(updateDataItemSuccess());
}

function* execute({payload: {id}}: UpdateDataItemAction): SagaIterator {
    const formValues = (yield* select(formValuesF(dataItemForm))).orCrash('Missing form values.');
    const response = yield* call(Api.updateDataItem, id, prepareUpdateData(formValues));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateDataItemSaga(): SagaIterator {
    yield takeLatestF('dataItem/UPDATE_DATA_ITEM', execute);
}
