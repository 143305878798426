import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {startSubmitF, stopSubmitF} from 'utils/formHelpers';
import {Errors} from 'utils/Validator';
import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';

import {PickupLineApi} from '../api';
import {pickupLineAction, PostPickupLineAction} from '../actions';
import {CREATE_PICKUP_FORM_NAME} from '../components/WritePickupLineForm/writePickupLineFormValues';

const title = 'Vytvoření svozové linky';

function* execute ({payload: {data}}: PostPickupLineAction): SagaIterator {
    yield* put(startSubmitF(CREATE_PICKUP_FORM_NAME));

    const response = yield* call(PickupLineApi.createPickupLine, data);

    if (response.isSuccess) {
        yield* put(stopSubmitF(CREATE_PICKUP_FORM_NAME));
        yield* put(pickupLineAction.getPickupLines());
    } else {
        yield* put(stopSubmitF(CREATE_PICKUP_FORM_NAME, response.data as Errors<WritePickupLine>));
        yield putAll(showBeError(response, title));
    }
}

export function* postPickupLineSaga(): SagaIterator {
    yield takeLatestF('pickupLine/POST_PICKUP_LINE', execute);
}
