import {opt} from 'ts-opt';

import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';

import {UpdateOrderFormValues} from './types/UpdateOrderFormValues';
import {CreateOrderFormValues} from './types/CreateOrderFormValues';
import {OrderCustomerFormValues} from './types/OrderCustomerFormValues';
import {BarcodeSearchFormValues} from './types/BarcodeSearchFormValues';

type CreateOrderDetailsState = GenericFormState<CreateOrderFormValues>;
type UpdateBasicOrderDetailsState = GenericFormState<UpdateOrderFormValues>;
type CreateSampleState = GenericFormState<CreateSampleFormValues>;
type OrderCustomerState = GenericFormState<OrderCustomerFormValues>;
type BarcodeSearchFormState = GenericFormState<BarcodeSearchFormValues>;

const formReducer: CFormReducer = {
    createOrder: (state: CreateOrderDetailsState, action: Action): CreateOrderDetailsState => {
        switch (action.type) {
            case 'order/PREFILL_CREATE_ORDER_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
    updateOrder: (state: UpdateBasicOrderDetailsState, action: Action): UpdateBasicOrderDetailsState => {
        switch (action.type) {
            case 'order/PREFILL_UPDATE_ORDER_DETAILS_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
    orderCustomer: (state: OrderCustomerState, action: Action): OrderCustomerState => {
        switch (action.type) {
            case 'order/PREFILL_ORDER_CUSTOMER_FORM': {
                return {...state, values: action.payload};
            }

            case 'order/RESET_ARES_FORM_VALUES': {
                const val = opt(state).chainToOpt(x => x.values).orUndef();
                if (val) {
                    return {
                        ...state,
                        values: {
                            ...val,
                            customerDetails: {
                                ...val.customerDetails,
                                invoiceDetails: {...val.customerDetails.invoiceDetails, ico: '', dic: ''},
                                address: {city: '', street: '', zip: ''},
                                billingAddress: {city: '', street: '', zip: ''},
                                customerName: '',
                            },
                            hasSameBillingAddress: true,
                            prefilled: false,
                        },
                    };
                } else { return state; }
            }

            default:
                return state;
        }
    },
    createSample: (state: CreateSampleState, action: Action): CreateSampleState => {
        switch (action.type) {
            case 'order/PREFILL_CREATE_SAMPLE_FORM': {
                return {...state, values: action.payload};
            }
            default:
                return state;
        }
    },
    barcodeSearchForm: (state: BarcodeSearchFormState, action: Action): BarcodeSearchFormState => {
        switch (action.type) {
            case 'order/PREFILL_BARCODE_SEARCH_FORM': {
                return {
                    ...state,
                    values: {
                        barcode: '',
                    },
                };
            }
            default:
                return state;
        }
    },
};

export default formReducer;
