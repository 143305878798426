const monthNames = [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec',
];

const getMonthNumber = (date: string): number => new Date(date).getMonth();

export const getYearMonthName = (date: string): string | null =>
    getMonthNumber(date) >= 0 && getMonthNumber(date) < 12
        ? `${monthNames[getMonthNumber(date)]} ${new Date(date).getFullYear()}`
        : null;
