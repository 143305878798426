import React, {Component, ComponentType, ReactNode} from 'react';
import {RouteComponentProps} from 'react-router';
import {opt} from 'ts-opt';
import {isArray, isEmpty} from 'lodash/fp';
import {parseSearch, mapStateToSearch, ParsedQuery, StateData} from 'favorlogic-utils';

export interface SortProps {
    sortDir?: SortDir;
    sortBy?: string;

    handleSortChange(sortBy: string): void;
}

type Props = RouteComponentProps;

export type SortDir =  'desc' | 'asc';

interface SortState {
    sortDir?: SortDir;
    sortBy?: string;
}

const withSort = (defaultSort?: SortState) =>
    (WrappedComponent: ComponentType<SortProps>): ReactNode => {
        class Sort extends Component<Props, SortState> {
            constructor(props: Props) {
                super(props);

                const parsedSearch = this.getParsedQuery();
                const {sort: sortString} = parsedSearch;
                const sort = this.parseSortString(sortString);

                const sortDir = opt(sort)
                    .caseOf(x => this.determineSortDir(x), () => defaultSort?.sortDir);
                const sortBy = opt(sort)
                    .caseOf(x => isArray(x) ? x[0] : x, () => defaultSort?.sortBy);

                this.state = {sortDir, sortBy};

                if (!isEmpty(defaultSort)) {
                    this.replaceQuery({...parsedSearch, sort: `${sortBy},${sortDir}`});
                }
            }

            private setQuery(query: StateData): void {
                const {history} = this.props;

                history.push(`?${mapStateToSearch(query)}`);
            }

            private replaceQuery(query: StateData): void {
                const {history} = this.props;

                history.replace(`?${mapStateToSearch(query)}`);
            }

            private getParsedQuery(): ParsedQuery {
                const {history: {location}} = this.props;

                return parseSearch(location.search);
            }

            private determineSortDir(sort: string[] | null): SortDir {
                if (isArray(sort)) {
                    return  sort[sort.length - 1] === 'asc' ? 'asc' : 'desc';
                } else {
                    return 'asc';
                }
            }

            private parseSortString(sortString: string | string[] | null | undefined): string[] | null {
                if (!sortString) {
                    return null;
                }

                if (Array.isArray(sortString)) {
                    return sortString[0].split(',');
                }

                return sortString.split(',');
            }

            private determineNextSortDir(sortBy: string): SortDir {
                const {sortBy: prevSortBy, sortDir: prevSortDir} = this.state;

                if (sortBy !== prevSortBy) {
                    return 'asc';
                } else {
                    return prevSortDir === 'asc' ? 'desc' : 'asc';
                }
            }

            private handleSortChange = (sortBy: string) => {
                const parsedSearch = this.getParsedQuery();
                const sortDir = this.determineNextSortDir(sortBy);

                this.setQuery({...parsedSearch, sort: `${sortBy},${sortDir}`});
                this.setState({...parsedSearch, sortDir, sortBy});
            };

            render() {
                const {sortBy, sortDir} = this.state;

                return (
                    <WrappedComponent
                        handleSortChange={this.handleSortChange}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        {...this.props} // eslint-disable-line react/jsx-props-no-spreading
                    />
                );
            }
        }

        return Sort;
    };

export default withSort;
