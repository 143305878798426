import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {UpdateMilkRoom} from 'types/model/milkRooms/UpdateMilkRoom';
import {CreateMilkRoomFormValues} from '../../types/CreateMilkRoomFormValues';
import {asyncValidate, validate} from './validations';
import {classNames} from 'favorlogic-utils';

type AnyMilkRoom = MilkRoom | UpdateMilkRoom;

export interface OwnProps {
    inSupplierCreation?: boolean;
    supplierId?: number;
    existingMilkRooms?: AnyMilkRoom[];

    onBack(): void;
}

export type CreateMilkRoomFormProps = PropsForForm<CreateMilkRoomFormValues, OwnProps>;

class CreateMilkRoomForm extends Component<CreateMilkRoomFormProps> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            inSupplierCreation,
            onBack,
        } = this.props;
        return (
            <Layout.Panel className={classNames(inSupplierCreation && 'mt-0')}>
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />

                    <Fields.Input
                        className="mt-2"
                        name="name"
                        type="text"
                        label="Název firmy/družstva*"
                    />

                    <Fields.Input
                        className="mt-2"
                        name="code"
                        type="text"
                        label="Kód*"
                    />

                    <Fields.Input
                        className="mt-2"
                        name="veterinaryId"
                        type="text"
                        label="Veterinární číslo*"
                        // eslint-disable-next-line max-len
                        placeholder="Veterinární číslo* (v případě slovenských dodavatelů zadejte schvalovací číslo bez speciálních znaků)"
                    />

                    <div className="d-flex justify-content-between mt-4">
                        {inSupplierCreation &&
                         <Buttons.Button
                             importance="secondary"
                             type="button"
                             onClick={onBack}
                         >
                             Zrušit
                         </Buttons.Button>
                        }
                        <Buttons.Button
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                            className={inSupplierCreation ? '' : 'w-100'}
                        >
                            {inSupplierCreation ? 'Přidat mléčnici' : 'Vytvořit'}
                        </Buttons.Button>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<CreateMilkRoomFormValues, OwnProps, CreateMilkRoomFormProps>(CreateMilkRoomForm, {
    form: 'createMilkRoom',
    asyncBlurFields: ['name', 'code'],
    validate,
    asyncValidate,
    initialValues: {
        name: '',
        code: '',
        veterinaryId: '',
    },
});
