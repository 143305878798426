import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {MilkRoomSchema} from '../milkRooms/MilkRoom';

const optionalPart = t.partial({
    name: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    code: t.string,
    milkRooms: t.array(MilkRoomSchema),
});

export const SupplyChainSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SupplyChain extends t.TypeOf<typeof SupplyChainSchema> {}
