import {createBrowserHistory} from 'history';
import {routerMiddleware as createRouterMiddleware} from 'connected-react-router';
import {applyMiddleware, compose, createStore, PreloadedState} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {opt, ReduxDevtoolsCompatibilityHelper} from 'ts-opt';
import {CustomWindow, ReduxDevtoolsExtensionOptions, CustomModule} from 'favorlogic-utils';

import {initialConfirmDialogState} from 'confirmDialog/reducer';
import {initialAnalysisState} from 'analysis/reducer';
import {initialLayoutState} from 'layout/reducer';
import {initialOrderState} from 'order/reducer';
import {initialSampleState} from 'sample/reducer';
import {initialSupplierState} from 'supplier/reducer';
import {initialMeasurementState} from 'measurement/reducer';
import {initialUserState} from 'user/reducer';
import {initialWizardState} from 'wizard/reducer';
import {initialOperatingProcedureState} from 'operatingProcedure/reducer';
import {initialDataItemState} from 'dataItem/reducer';
import {initialDeviceState} from 'device/reducer';
import {initialAdministrationState} from 'administration/reducer';
import {initialSupplyStateState} from 'supplyChain/reducer';
import {initialPickupLineState} from 'pickupLine';

import {Action} from './actions';
import {createReducer} from './reducer';
import sagas from './sagas';
import {StoreState} from './types/StoreState';

const reduxDevtoolsOptions: ReduxDevtoolsExtensionOptions = {
    serialize: {
        replacer: ReduxDevtoolsCompatibilityHelper.replacer,
        reviver: ReduxDevtoolsCompatibilityHelper.reviver,
    },
};

const composeEnhancers = opt((window as CustomWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    .map(f => f(reduxDevtoolsOptions))
    .orElse(compose);

const defaultState: Partial<StoreState> = {
    user: initialUserState,
    layout: initialLayoutState,
    supplier: initialSupplierState,
    order: initialOrderState,
    analysis: initialAnalysisState,
    wizard: initialWizardState,
    sample: initialSampleState,
    confirmDialog: initialConfirmDialogState,
    measurement: initialMeasurementState,
    operatingProcedure: initialOperatingProcedureState,
    dataItem: initialDataItemState,
    device: initialDeviceState,
    administration: initialAdministrationState,
    supplyChain: initialSupplyStateState,
    pickupLine: initialPickupLineState,
};

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);

const reducer = createReducer(history);
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware));
const store = createStore<StoreState, Action, unknown, void>(
    reducer,
    defaultState as unknown as PreloadedState<StoreState>,
    enhancer,
);

const customModule = module as unknown as CustomModule;
if (customModule.hot) {
    // Enable Webpack hot module replacement for reducers
    customModule.hot.accept('./reducer', () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
        const nextReducer = require('./reducer').createReducer(history);
        store.replaceReducer(nextReducer);
    });
}

sagaMiddleware.run(sagas);

export {store, history};
