import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {DataItem} from 'types/model/dataItems/DataItem';
import {handleResponseError} from 'utils/handleResponseError';

import {loadDataItemsError, loadDataItemsSuccess} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const errorTitle = 'Načtení datových položek';
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, errorTitle);
    yield* put(loadDataItemsError(error));
}

function* handleSuccessResponse(response: SuccessResponse<DataItem[]>): SagaIterator {
    yield* put(loadDataItemsSuccess(response.data));
}

function* execute(): SagaIterator {
    const response = yield* call(Api.getDataItems);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadDataItemsSaga(): SagaIterator {
    yield takeLatestF('dataItem/LOAD_DATA_ITEMS', execute);
}
