import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {UpdateSampleFormValues} from '../../types/UpdateSampleFormValues';

type Values = UpdateSampleFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('samplingDate', 'Datum odběru');
    validator.nonEmpty('type', 'Typ vzorku');
    validator.nonEmpty('harvestType', 'Způsob odběru');
    validator.nonEmpty('analysisIds', 'Požadované rozbory');
    validator.nonEmpty('barcode', 'Čárový kód');
    validator.barcode('barcode', 'Čárový kód');
    validator.floatNumber('sampleSourceAmount', 'Počet');
    validator.temperature('samplingTemperature', 'Teplota');
    validator.temperature('deliveryTemperature', 'Teplota');

    return validator.generateErrorsObject();
};
