/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/supply-chains"
        component={Screens.List}
        key="supply-chains"
    />,
    <Route
        exact
        path="/supply-chains/new"
        render={props => <Screens.NewEdit
            mode="CREATE"
            {...props}
        />}
        key="supply-chains-new"
    />,
    <Route
        exact
        path="/supply-chains/:id"
        component={Screens.Detail}
        key="supply-chains-detail"
    />,
    <Route
        exact
        path="/supply-chains/:id/edit"
        render={props => <Screens.NewEdit
            mode="UPDATE"
            {...props}
        />}
        key="supply-chains-edit"
    />,
];

export default routes;
