import React, {FunctionComponent, memo, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';

import {PickupLineDairyPlanAnalysis} from 'types/model/pickupLine/PickupLineDairyPlanAnalysis';
import {Fields} from 'forms';
import {PickupLineDairyPlanAnalysisDay} from 'types/model/pickupLine/PickupLineDairyPlanAnalysisDay ';

import {isFreeDay} from '../../utils/isFreeDay';

import styles from './styles.sass';

interface Props {
    analysis: PickupLineDairyPlanAnalysis;
    submitting: boolean;
    disabled: boolean;
}

const renderHeader = (abbr: string): ReactNode =>
    <div className={classNames(styles.analysisCol, styles.header)}>
        <div className={styles.content}>{abbr}</div>
    </div>
;

const renderStates = (
    pickupLineid: number,
    days: PickupLineDairyPlanAnalysisDay[],
    submitting: boolean,
    disabled: boolean,
): ReactNode[] =>
    days.map(x =>
        <div
            key={x.id}
            className={classNames(
                'text-center',
                styles.analysisCol,
                isFreeDay(x.pickupDate) && styles.freeDay,
                x.state === 'PREFERRED' && styles.preferred,
                x.state === 'ALTERNATIVE' && styles.alternative,
                x.state === 'FORBIDDEN' && styles.forbidden,
            )}
        >
            {x.state !== 'FORBIDDEN' && <Fields.Input
                name={`states.${pickupLineid}.${x.id}`}
                type="number"
                label=""
                disabled={submitting || disabled}
                noHelper
                onlyPositive
            />}
        </div>
    )
;

const PickupLineDairyPlanBase: FunctionComponent<Props> = props => {
    const {analysis, submitting, disabled} = props;
    const {id, abbr, days} = analysis;

    return (
        <div
            key={id}
            className="d-inline-block"
        >
            {renderHeader(abbr)}
            {renderStates(id, days, submitting, disabled)}
        </div>
    );
};

export const PickupLineDairyPlanColumn = memo(PickupLineDairyPlanBase);
