import React, {Component, Fragment, ReactNode} from 'react';
import {opt} from 'ts-opt';
import {max} from 'lodash/fp';
import {formatDate} from 'favorlogic-utils';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {ProtocolDetails} from 'types/model/protocols/ProtocolDetails';

import RowActions from './RowActions';

type ProtocolRow = ProtocolDetails & {
    preliminary: boolean,
    actions: ReactNode,
    processedFromTo: string,
    approvedCell: ReactNode,
};

interface Props {
    protocols: ProtocolDetails[];
    approveInProgress: boolean;
    canApprove: boolean;
    canDelete: boolean;

    handleDownload(_: ProtocolDetails): void;
    handleApprove(): void;
    handleDelete(_: ProtocolDetails): void;
}

export const getLatestVersion = (protocols: ProtocolDetails[]): number =>
    opt(max(protocols.map(p => p.version))).orElse(1);

export const getLatestApprovedVersion = (protocols: ProtocolDetails[]): number =>
    opt(max(protocols.filter(p => p.approved).map(p => p.version))).orElse(0);

class ProtocolsTable extends Component<Props> {
    static genColumns(): Column<ProtocolRow>[] {
        return [
            {
                accessor: 'created',
                header: 'Vytvořeno',
                type: ColumnType.Date,
                dateFormat: 'DATE',
            },
            {
                accessor: 'processedFromTo',
                header: 'Zpracováno od/do',
                type: ColumnType.Node,
            },
            {
                accessor: 'version',
                header: 'Verze',
                type: ColumnType.Node,
            },
            {
                accessor: 'preliminary',
                header: 'Předběžný',
                type: ColumnType.Bool,
            },
            {
                accessor: 'createdBy',
                header: 'Vytvořil',
                type: ColumnType.Node,
            },
            {
                accessor: 'approvedCell',
                header: 'Schválil',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    render(): React.ReactNode {
        return (
            <Tables.Table
                columns={ProtocolsTable.genColumns()}
                rows={this.getData()}
            />
        );
    }

    private genRow = (
        protocol: ProtocolDetails,
        latestVersion: number,
        isLatestVersionApproved: boolean,
    ): ProtocolRow => {
        const {
            handleDownload,
            handleApprove,
            handleDelete,
            approveInProgress,
            canApprove,
            canDelete,
        } = this.props;
        const {processedFrom, processedTo, approved, approvedBy, version} = protocol;
        const isLatestVersion = version === latestVersion;
        const disableDelete = !isLatestVersion || isLatestVersionApproved && !approved;

        return {
            ...protocol,
            processedFromTo: `${formatDate(processedFrom).orElse('?')} / ${formatDate(processedTo).orElse('?')}`,
            approvedCell: <Fragment>{approved ? approvedBy : '-'}</Fragment>,
            preliminary: !approved,
            actions: <RowActions
                protocol={protocol}
                onDownload={handleDownload}
                onApprove={handleApprove}
                onDelete={handleDelete}
                canApprove={isLatestVersion && canApprove}
                canDelete={canDelete}
                disableDelete={disableDelete}
                disableApprove={approveInProgress}
            />,
        };
    };

    private getData(): ProtocolRow[] {
        const {protocols} = this.props;
        const latestVersion = getLatestVersion(protocols);
        const latestApprovedVersion = getLatestApprovedVersion(protocols);
        const isLatestVersionApproved = latestVersion === latestApprovedVersion;

        return protocols.map((protocol) => this.genRow(protocol, latestVersion, isLatestVersionApproved));
    }
}

export default ProtocolsTable;
