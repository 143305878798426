import * as t from 'io-ts';

import {UpdateCustomerByLaborerBaseSchema} from './UpdateCustomerByLaborerBase';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    tag: t.literal('UpdateCustomerByLaborer'),
});

export const UpdateCustomerByLaborerSchema = t.exact(t.intersection([
    UpdateCustomerByLaborerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateCustomerByLaborer extends t.TypeOf<typeof UpdateCustomerByLaborerSchema> {}
