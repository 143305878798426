import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {UpdateOperatingProcedure} from 'types/model/operatingProcedures/UpdateOperatingProcedure';
import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';

import {
    UpdateOperatingProcedureAction,
    updateOperatingProcedureError,
    updateOperatingProcedureSuccess,
} from '../actions';
import Api from '../api';
import {OperatingProcedureFormValues} from '../types/OperatingProcedureFormValues';

const title = 'Upravení operačního postupu';
const operatingProcedureForm = 'operatingProcedure';

export function prepareOperatingProcedureData(formValues: OperatingProcedureFormValues): UpdateOperatingProcedure {
    return {
        name: formValues.name,
        description: formValues.description,
    };
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateOperatingProcedureError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF(operatingProcedureForm, extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně upraven.'));
    yield* put(updateOperatingProcedureSuccess());
}

function* execute({payload: {id}}: UpdateOperatingProcedureAction): SagaIterator {
    const formValues = (yield* select(formValuesF(operatingProcedureForm))).orCrash('Missing form values.');
    const response = yield* call(Api.updateOperatingProcedure, id, prepareOperatingProcedureData(formValues));

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateOperatingProcedureSaga(): SagaIterator {
    yield takeLatestF('operatingProcedure/UPDATE_OPERATING_PROCEDURE', execute);
}
