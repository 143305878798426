export const syncCmsch = () => ({
    type: 'administration/SYNC_CMSCH',
}) as const;
export type SyncCmschAction = ReturnType<typeof syncCmsch>;

export const generateQCZProtocol = () => ({
    type: 'administration/GENERATE_Q_CZ_PROTOCOL',
}) as const;
export type GenerateQCZProtocolAction = ReturnType<typeof generateQCZProtocol>;

export const generateCustomersQCZProtocol = () => ({
    type: 'administration/GENERATE_CUSTOMERS_Q_CZ_PROTOCOL',
}) as const;
export type GenerateCustomersQCZProtocolAction = ReturnType<typeof generateCustomersQCZProtocol>;

export const massGenerateProtocols = () => ({
    type: 'administration/MASS_GENERATE_PROTOCOLS',
}) as const;
export type MassGenerateProtocolsAction = ReturnType<typeof massGenerateProtocols>;

export const massGenerateProtocolsFinished = () => ({
    type: 'administration/MASS_GENERATE_PROTOCOLS_FINISHED',
}) as const;
export type MassGenerateProtocolsFinishedAction = ReturnType<typeof massGenerateProtocolsFinished>;

export const generatePreviewProtocol = () => ({
    type: 'administration/GENERATE_PREVIEW_PROTOCOL',
}) as const;
export type GeneratePreviewProtocolAction = ReturnType<typeof generatePreviewProtocol>;

export const generatePreviewProtocolFinished = () => ({
    type: 'administration/GENERATE_PREVIEW_PROTOCOL_FINISHED',
}) as const;
export type GeneratePreviewProtocolFinishedAction = ReturnType<typeof generatePreviewProtocolFinished>;

export type AdministrationAction =
    | SyncCmschAction
    | GenerateQCZProtocolAction
    | GenerateCustomersQCZProtocolAction
    | MassGenerateProtocolsAction
    | MassGenerateProtocolsFinishedAction
    | GeneratePreviewProtocolAction
    | GeneratePreviewProtocolFinishedAction
    ;
