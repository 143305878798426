import React, {ComponentClass} from 'react';
import {connect} from 'react-redux';
import {flow, isEmpty} from 'lodash/fp';

import {classNames} from 'favorlogic-utils';
import {StoreState} from 'app/types/StoreState';

import styles from './styles.sass';

interface OuterProps {}

interface InnerProps {
    children: React.ReactNode;
    show?: boolean;
}

type Props = InnerProps & OuterProps;

export class BlurerBase extends React.PureComponent<Props> {
    render(): React.ReactNode {
        const {children, show} = this.props;

        return (
            <div className={classNames(styles.blurer, show && styles.visible)}>
                {children}
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<InnerProps> => ({
    show: !isEmpty(state.layout.modalStack),
});

export const Blurer = flow([
    connect(mapStateToProps),
])(BlurerBase) as ComponentClass<OuterProps>;
