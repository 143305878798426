import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {changeF, formValuesF} from 'utils/formHelpers';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {CreateSample} from 'types/model/samples/CreateSample';

import {ReloadMilkRoomsInOrderSamplesAction} from '../actions';


export function reloadMilkRooms(samples: CreateSample[], customerMilkRooms: MilkRoom[]): CreateSample[] {
    const customerMilkRoomIds = customerMilkRooms.map(m => m.id);

    return samples.map(sample => {
        if (!sample.milkRoomId) {
            return sample;
        }

        if (!customerMilkRoomIds.includes(sample.milkRoomId)) {
            sample.milkRoomId = undefined;
        }
        return sample;
    });
}


export function* execute({payload: {customerMilkRooms}}: ReloadMilkRoomsInOrderSamplesAction): SagaIterator {
    const values = (yield* select(formValuesF('createOrder'))).orCrash('missing form values');
    const reloadedSamples = reloadMilkRooms(values.samples, customerMilkRooms);

    yield* put(changeF('createOrder', 'samples', reloadedSamples));
}


export function* reloadMilkRoomsInOrderSamples(): SagaIterator {
    yield takeLatestF('order/RELOAD_MILK_ROOMS_IN_ORDER_SAMPLES', execute);
}
