import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {DisableSampleFormValues} from 'supplier/types/DisableSampleFormValues';
import {Components as Buttons} from 'buttons';

interface OwnProps {
    onSubmit(values: DisableSampleFormValues): void;
}

export type Props = PropsForForm<DisableSampleFormValues, OwnProps>;

class DisableSampleForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Layout.Loader show={submitting} />

                <h2>Označit jako nepoužitelný</h2>

                <div className="row">
                    <div className="col-12 mt-2">
                        <Fields.Input
                            name="note"
                            type="text"
                            label="Poznámka"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Označit
                        </Buttons.Button>
                    </div>
                </div>

            </Form>
        );
    }
}

export default Forms.withForm<DisableSampleFormValues, OwnProps, Props>(DisableSampleForm, {
    form: 'disableSampleForm',
    destroyOnUnmount: false,
    initialValues: {
        note: '',
    },
});
