import React, {FunctionComponent, memo, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';

import {PickupLineAnalysis} from 'types/model/pickupLine/PickupLineAnalysis';
import {PickupLineAnalysisDay} from 'types/model/pickupLine/PickupLineAnalysisDay';

import {isFreeDay} from '../../utils/isFreeDay';

import styles from './styles.sass';

interface Props {
    analysis: PickupLineAnalysis;
}

const renderHeader = (name: string): ReactNode =>
    <div
        className={classNames(styles.analysisCol, styles.header)}
    >
        <div className={styles.content}>{name}</div>
    </div>
;

const renderAnalysisCountInDays = (
    days: PickupLineAnalysisDay[],
): ReactNode[] =>
    days.map(x =>
        <div
            key={x.pickupDate}
            className={classNames(styles.analysisCol, isFreeDay(x.pickupDate) && styles.freeDay)}
        >
            <div className={styles.content}>{x.count}</div>
        </div>
    )
;

const renderFooter = (sumOfCounts: number): ReactNode =>
    <div className={classNames(styles.analysisCol, styles.header)}>
        <div className={styles.content}>{sumOfCounts}</div>
    </div>
;

const AnalysisColumnBase: FunctionComponent<Props> = props => {
    const {analysis} = props;
    const {days, id, abbr, totalCount} = analysis;

    return (
        <div
            key={id}
            className="d-inline-block"
        >
            {renderHeader(abbr)}
            {renderAnalysisCountInDays(days)}
            {renderFooter(totalCount)}
        </div>
    );
};

export const AnalysisColumn = memo(AnalysisColumnBase);
