import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import RowActions from './RowActions';
import {Device} from 'types/model/devices/Device';
import {DataItem} from 'types/model/dataItems/DataItem';
import {LoaderWrapper} from 'layout/components';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';

const limit = convertStringToLimitedLengthElement(50);

export interface DeviceRow {
    name: string;
    dataItemsNames: string | ReactNode;
    actions: ReactNode;
}

interface OuterProps {
    devices: Device[] | null;
    dataItems: DataItem[] | null;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

class DevicesTable extends Component<Props> {
    render(): React.ReactNode {
        const {devices, dataItems} = this.props;

        return (
            <LoaderWrapper showLoader={!devices || !dataItems}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData()}
                />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<DeviceRow>[] {
        return [
            {
                accessor: 'name',
                header: 'Název',
                type: ColumnType.Node,
            },
            {
                accessor: 'dataItemsNames',
                header: 'Datové položky',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getData(): Row<DeviceRow>[] {
        const {devices} = this.props;
        if (!devices) {
            return [];
        }

        return devices.map(device => ({
            ...device,
            dataItemsNames: limit(this.getDataItemsNames(device)),
            actions: <RowActions
                device={device}
            />,
        }));
    }

    private getDataItemName(dataItemId: number): string {
        const {dataItems} = this.props;

        if (!dataItems) {
            return '';
        }

        const dataItem = dataItems.find(item => item.id === dataItemId);

        return dataItem ? dataItem.name : '';
    }

    private getDataItemsNames(device: Device): string {
        return device.dataItems
            .map(deviceDataItem => deviceDataItem.dataItemId)
            .map(dataItemId => this.getDataItemName(dataItemId))
            .filter(Boolean)
            .join(', ');
    }
}

export default DevicesTable;
