import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    downloadFileFromResponse,
    SuccessResponse,
    extractFormErrorsFromResponse,
    takeLatestF,
    ErrorResponse,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {formValuesF, resetF, stopSubmitF} from 'utils/formHelpers';
import Api from 'order/api';
import {handleResponseError} from 'utils/handleResponseError';
import {setModalVisibility} from 'layout/actions';

const title = 'Stažení protokolů';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('exportTxtProtocols', extractFormErrorsFromResponse(response)));
}

function* handleSuccessResponse(response: SuccessResponse<ArrayBuffer>): SagaIterator {
    yield* call(downloadFileFromResponse, response);
    yield* put(stopSubmitF('exportTxtProtocols'));
    yield* put(resetF('exportTxtProtocols'));
    yield* put(setModalVisibility('exportTxtProtocols', false));
}

function* execute(): SagaIterator {
    const values = yield* select((state: StoreState) =>
        formValuesF('exportTxtProtocols')(state).orCrash('Missing form values'));
    const {from, to} = values;

    const response = yield* call(Api.exportTxtProtocols, from, to);

    yield* response.isSuccess
        ? call(handleSuccessResponse, response)
        : call(handleErrorResponse, response);
}

export function* exportTxtProtocolsSaga(): SagaIterator {
    yield takeLatestF('order/EXPORT_TXT_PROTOCOLS', execute);
}
