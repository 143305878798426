import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {Components as Layout} from 'layout';
import {PropsForForm} from 'forms/components/withForm';
import {LoginFormValues} from 'user/types/LoginFormValues';
import {validate} from './validations';

interface OwnProps {}

type Props = PropsForForm<LoginFormValues, OwnProps>;

class LoginForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;
        return (
            <Layout.Panel caption="">
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />
                    <div
                        className="mt-1 mb-3"
                    >
                        <Fields.Input
                            className="mt-2"
                            name="username"
                            type="text"
                            label="Uživatelské jméno"
                        />
                    </div>
                    <Fields.Input
                        className="mt-2"
                        name="password"
                        type="password"
                        label="Heslo"
                    />
                    <div className="d-flex justify-content-end mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Přihlásit
                        </Buttons.Button>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<LoginFormValues, OwnProps, Props>(LoginForm, {
    form: 'login',
    validate,
    initialValues: {username: '', password: ''},
});
