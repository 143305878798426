import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {SupplyChainAction} from './actions';

export interface SupplyChainState {
    supplyChains: SupplyChain[] | null;
    supplyChain: SupplyChain | null;
}

type State = SupplyChainState;

export const initialSupplyStateState: State = {
    supplyChains: null,
    supplyChain: null,
};

const reducer = (state: State | undefined = initialSupplyStateState, action: SupplyChainAction): State => {
    switch (action.type) {
        case 'supplyChain/LOAD_SUPPLY_CHAINS_SUCCESS': {
            return {...state, supplyChains: action.payload};
        }

        case 'supplyChain/LOAD_SUPPLY_CHAIN_SUCCESS': {
            return {...state, supplyChain: action.payload};
        }

        default:
            return state;
    }
};

export default reducer;
