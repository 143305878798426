import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/administration/devices"
        component={Screens.List}
        key="devices"
    />,
    <Route
        exact
        path="/administration/devices/:id/edit"
        component={Screens.Edit}
        key="devices-edit"
    />,
    <Route
        exact
        path="/administration/devices/:id"
        component={Screens.Detail}
        key="devices-detail"
    />,
];

export default routes;
