import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {stopSubmitF, startSubmitF, formValuesF} from 'utils/formHelpers';

import {
    createSupplyChainError,
    createSupplyChainSuccess,
    CreateSupplyChainAction,
} from '../actions';
import Api from '../api';

const title = 'Vytvoření linky';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(createSupplyChainError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('supplyChain', extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně vytvořena.'));
    yield* put(createSupplyChainSuccess());
    yield* put(stopSubmitF('supplyChain'));
    yield* put(routerActions.goBack());
}

function* execute(_action: CreateSupplyChainAction): SagaIterator {
    yield* put(startSubmitF('supplyChain'));

    const values = (yield* select(formValuesF('supplyChain'))).orCrash('missing form values');
    const response = yield* call(Api.createSupplyChain, values);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* createSupplyChainSaga(): SagaIterator {
    yield takeLatestF('supplyChain/CREATE_SUPPLY_CHAIN', execute);
}
