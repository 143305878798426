import * as t from 'io-ts';
import {DateStringSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
    reason: t.string,
});
const requiredPart = t.interface({
    changed: DateStringSchema,
    changedBy: t.string,
});

export const MeasurementHistorySchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface MeasurementHistory extends t.TypeOf<typeof MeasurementHistorySchema> {}
