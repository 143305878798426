import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {UpdateSupplyChain} from 'types/model/supplyChains/UpdateSupplyChain';
import {handleResponseError} from 'utils/handleResponseError';

import {
    LoadSupplyChainAction,
    loadSupplyChainError,
    loadSupplyChainSuccess,
    prefillSupplyChainForm,
} from '../actions';
import Api from '../api';

function* execute(action: LoadSupplyChainAction): SagaIterator {
    const errorTitle = 'Načtení linky';

    yield* put(prefillSupplyChainForm({code: '', name: '', milkRoomIds: []}));

    const response = yield* call(Api.getSupplyChain, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadSupplyChainError(error));
    }

    const supplyChain = response.data;
    const prefill: UpdateSupplyChain = {
        code: supplyChain.code,
        name: supplyChain.name,
        milkRoomIds: supplyChain.milkRooms.map(x => x.id),
    };

    yield* put(prefillSupplyChainForm(prefill));
    yield* put(loadSupplyChainSuccess(supplyChain));
}

export function* loadSupplyChainSaga(): SagaIterator {
    yield takeLatestF('supplyChain/LOAD_SUPPLY_CHAIN', execute);
}
