import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {LoadProtocolsAction, loadProtocolsError, loadProtocolsSuccess} from '../actions';
import Api from '../api';

export function* executeLoadProtocol(action: LoadProtocolsAction): SagaIterator {
    const errorTitle = 'Načtení Protokolů';

    const response = yield* call(Api.getProtocols, action.payload.orderId);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadProtocolsError(error));
    }

    yield* put(loadProtocolsSuccess(response.data));
}

export function* loadProtocolsSaga(): SagaIterator {
    yield takeLatestF('order/LOAD_PROTOCOLS', executeLoadProtocol);
}
