exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-layout-components-Panel-___styles__btn___28ULJ, .src-modules-layout-components-Panel-___styles__form-control___OvBrU {\n  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15); }\n\nbody {\n  overflow: visible;\n  height: 100%; }\n\nhtml, #src-modules-layout-components-Panel-___styles__app____qSK2 {\n  height: 100%; }\n\n.src-modules-layout-components-Panel-___styles__panel___16HVz {\n  background-color: #ffffff;\n  padding: 1em;\n  border-radius: 3px;\n  display: inline-block;\n  margin: 1rem 0;\n  position: relative; }\n\n.src-modules-layout-components-Panel-___styles__customTable___2BQt2, .src-modules-layout-components-Panel-___styles__panel___16HVz {\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }\n\n.src-modules-layout-components-Panel-___styles__undefined___1_a9k, #src-modules-layout-components-Panel-___styles__undefined___1_a9k {\n  background-color: red;\n  color: lime;\n  border: 0.1em solid cyan; }\n  .src-modules-layout-components-Panel-___styles__undefined___1_a9k::before, #src-modules-layout-components-Panel-___styles__undefined___1_a9k::before {\n    content: 'UNDEFINED';\n    color: magenta;\n    width: 0; }\n\n.src-modules-layout-components-Panel-___styles__customTable___2BQt2 {\n  background-color: white !important;\n  border-radius: 5px;\n  width: fit-content;\n  margin: auto;\n  white-space: nowrap;\n  overflow: auto;\n  max-width: 100%; }\n\n.src-modules-layout-components-Panel-___styles__scrollbarTop___27gA9 {\n  transform: rotateX(180deg) !important; }\n", ""]);

// exports
exports.locals = {
	"btn": "src-modules-layout-components-Panel-___styles__btn___28ULJ",
	"form-control": "src-modules-layout-components-Panel-___styles__form-control___OvBrU",
	"app": "src-modules-layout-components-Panel-___styles__app____qSK2",
	"panel": "src-modules-layout-components-Panel-___styles__panel___16HVz",
	"customTable": "src-modules-layout-components-Panel-___styles__customTable___2BQt2",
	"undefined": "src-modules-layout-components-Panel-___styles__undefined___1_a9k",
	"scrollbarTop": "src-modules-layout-components-Panel-___styles__scrollbarTop___27gA9"
};