import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {DilutionSchema} from 'types/generic/DilutionSchema';

const optionalPart = t.partial({
    reason: t.string,
});
const requiredPart = t.interface({
    dilution: DilutionSchema,
    result: t.number,
    measured: DateStringSchema,
    procedureId: IntegerSchema,
});

export const UpdateMeasurementSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateMeasurement extends t.TypeOf<typeof UpdateMeasurementSchema> {
}
