import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {UpdateAnalysisSchema} from './UpdateAnalysis';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    dataItemIds: t.array(IntegerSchema),
});

export const AnalysisSchema = t.exact(t.intersection([UpdateAnalysisSchema.type, optionalPart, requiredPart]));

export interface Analysis extends t.TypeOf<typeof AnalysisSchema> {}
