import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

const routes = [
    <Route
        exact
        path="/administration/others"
        component={Screens.Others}
        key="others"
    />,
];

export default routes;
