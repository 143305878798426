exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-icons-components-SquareIcon-___styles__square-icon___2Y8DY {\n  padding: 4px; }\n", ""]);

// exports
exports.locals = {
	"square-icon": "src-modules-icons-components-SquareIcon-___styles__square-icon___2Y8DY"
};