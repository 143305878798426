import React, {ChangeEvent, ReactNode} from 'react';
import {opt} from 'ts-opt';
import {classNames} from 'favorlogic-utils';

import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

import styles from './styles.sass';

type FieldValue = boolean;

type OwnProps = {}

export type CheckboxOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type CheckboxInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

class Checkbox extends ComponentForWrapping<CheckboxInnerProps, FieldValue> {
    render(): ReactNode {
        const {
            label,
            helpText,
            input,
            meta,
            disabled,
            className,
        } = this.props;

        const {asyncValidating, submitting} = meta;
        const {value, name} = input;

        const handleChange = (_evt: ChangeEvent<HTMLInputElement>) => {
            this.handleChange(opt(!value));
        };

        return (
            <div>
                <HelpText>
                    {helpText}
                </HelpText>
                <Label
                    htmlFor={name}
                    className={classNames('form-group', styles['my-checkbox'], className)}
                >
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id={input.name}
                        disabled={asyncValidating || submitting || disabled}
                        onChange={handleChange}
                        checked={this.getFormValue().orFalse()}
                    />

                    <div
                        className={classNames(
                            'form-check-label',
                            styles['custom-checkbox-label'],
                            disabled && styles.customCheckboxLabelDisabled,
                        )}
                    />
                    <div
                        className={classNames(
                            styles['custom-label'],
                            disabled && styles.customLabelDisabled,
                        )}
                    >
                        {label}
                    </div>
                </Label>
            </div>
        );
    }
}

export default Checkbox;
