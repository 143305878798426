import React, {FunctionComponent, memo, ReactNode, useCallback} from 'react';
import {classNames} from 'favorlogic-utils';
import {useDispatch} from 'react-redux';

import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import {PickupLineOption} from 'types/model/pickupLine/PickupLineOption';
import {Button} from 'buttons/components';
import {Fields} from 'forms';
import {SelectOptions} from 'forms/components/BasicSelect';
import {PickupLineOptionState} from 'types/model/pickupLine/PickupLineOptionState';
import {reset, show} from 'confirmDialog/actions';

import {isFreeDay} from '../../utils/isFreeDay';

import styles from './styles.sass';

interface Props {
    pickupLine: PickupLineOptions;
    submitting: boolean;
    disabled: boolean;
    onPickupLinePlanApprove(pickupLineId: number): void;
}

const STATE_OPTIONS: SelectOptions<PickupLineOptionState> = [
    {label: 'Zakázáno', value: 'FORBIDDEN'},
    {label: 'Preferováno', value: 'PREFERRED'},
    {label: 'Alternativa', value: 'ALTERNATIVE'},
];

const renderHeader = (name: string, color: string): ReactNode =>
    <div
        className={classNames(styles.pickupLineCol, styles.header)}
        style={{textShadow: `1px 1px 5px ${color}`}}
    >
        <div className={styles.content}>{name}</div>
    </div>
;

const renderStates = (
    pickupLineid: number,
    options: PickupLineOption[],
    submitting: boolean,
    published: boolean,
    disabled: boolean,
): ReactNode[] =>
    options.map(x =>
        <div
            key={x.id}
            className={classNames(
                'text-center',
                styles.pickupLineCol,
                isFreeDay(x.pickupDate) && styles.freeDay,
                published && styles.publishedDay
            )}
        >
            <Fields.Select
                name={`states.${pickupLineid}.${x.id}`}
                label=""
                options={STATE_OPTIONS}
                clearable={false}
                noMargin
                disabled={submitting || published || disabled}
                tooltip={published ? 'Plán pro svozovou linku byl již schválen.' : undefined}
            />
        </div>
    )
;

const renderFooter = (submitting: boolean, isPublished: boolean, disabled: boolean, onApprove: () => void): ReactNode =>
    <div className={classNames('px-2', styles.pickupLineCol, styles.footerPadding)}>
        <Button
            type="button"
            importance="primary"
            onClick={onApprove}
            fullWidth
            disabled={submitting || isPublished || disabled}
            title={isPublished ? 'Plán pro svozovou linku byl již schválen.' : undefined}
        >
            {isPublished ? 'Schváleno' : 'Schválit'}
        </Button>
    </div>
;

const PickupLineColumnBase: FunctionComponent<Props> = props => {
    const {pickupLine, submitting, disabled, onPickupLinePlanApprove} = props;
    const {color, id, name, options, published} = pickupLine;

    const dispatch = useDispatch();

    const handlePickupLinePlanApprove = useCallback(() => {
        dispatch(show({
            cancelAction: reset(),
            text: `Opravdu chcete schválit plán k lince ${pickupLine.name}?`,
            onConfirm: () => onPickupLinePlanApprove(id),
        }));
    }, [id, onPickupLinePlanApprove]);

    return (
        <div
            key={id}
            className="d-inline-block"
        >
            {renderHeader(name, color)}
            {renderStates(id, options, submitting, published, disabled)}
            {renderFooter(submitting, published, disabled, handlePickupLinePlanApprove)}
        </div>
    );
};

export const PickupLineColumn = memo(PickupLineColumnBase);
