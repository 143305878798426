import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {Components as Buttons} from 'buttons';

import {GenerateQCzProtocolFormValues} from '../../types/GenerateQCzProtocolFormValues';
import {validate} from './validation';

interface OwnProps {
    title: string;
    onSubmit(values: GenerateQCzProtocolFormValues): void;
}

type Props = PropsForForm<GenerateQCzProtocolFormValues, OwnProps>;

class GenerateQCzProtocolForm extends Component<Props> {
    render(): ReactNode {
        const {
            title,
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Layout.LoaderWrapper
                showLoader={submitting}
                inModal
            >
                <Form onSubmit={handleSubmit}>
                    <h2>{title}</h2>

                    <div className="row">
                        <div className="col-12 mt-2">
                            <Fields.Checkbox
                                name="preview"
                                label="Předběžný protokol"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-2">
                            <Fields.DateTime
                                name="yearMonth"
                                label="Měsíc"
                                mode="YEARMONTH"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <Buttons.Button
                                fullWidth
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Generovat protokol
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.LoaderWrapper>
        );
    }
}

export default Forms.withForm<GenerateQCzProtocolFormValues, OwnProps, Props>(GenerateQCzProtocolForm, {
    form: 'generateQCzProtocol',
    initialValues: {
        preview: true,
        yearMonth: null,
    },
    validate,
});
