import {maxBy} from 'lodash/fp';
import {opt} from 'ts-opt';
import {convertStringToFloat, emptyStringToUndefined} from 'favorlogic-utils';

import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {CreateSample} from 'types/model/samples/CreateSample';

export function prepareSample(newSample: CreateSampleFormValues, sampleOrder: number): CreateSample {
    const samplingTemperature = newSample.samplingTemperature
        ? convertStringToFloat(newSample.samplingTemperature).orCrash('invalid sampling temperature')
        : undefined;

    return {
        analysisIds: newSample.analysisIds,
        barcode: newSample.barcode,
        type: opt(newSample.type).orCrash('missing sampel type'),
        harvestType: opt(newSample.harvestType).orCrash('missing sampel harvest type'),
        supplyChainId: opt(newSample.supplyChainId).orUndef(),
        milkRoomId: opt(newSample.milkRoomId).orUndef(),
        samplingDate: newSample.samplingDate,
        samplingTime: opt(newSample.samplingTime).map(emptyStringToUndefined).orUndef(),
        samplingTemperature,
        sampleSourceAmount: opt(newSample.sampleSourceAmount).chainToOpt(parseInt).orUndef(),
        showResultsOnWeb: newSample.showResultsOnWeb,
        showResultsToBreeders: newSample.showResultsToBreeders,
        customerNote: opt(newSample.customerNote).orUndef(),
        sampleOrder,
        qualitative: newSample.qualitative,
        cistern: newSample.cistern,
        control: newSample.control,
        subsidy: newSample.subsidy,
        traitNote: opt(newSample.traitNote).orUndef(),
        tag: 'CreateSample',
    };
}

export function getNewSampleOrder(samples: CreateSample[]): number {
    const lastSample = maxBy((sample) => sample.sampleOrder, samples);

    return lastSample ? lastSample.sampleOrder + 1 : 1;
}

