import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {Components as Buttons} from 'buttons';
import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';

interface OuterProps {}

interface InnerProps {}

type Props = InnerProps & OuterProps & RouteComponentProps<{}>;

class Test extends Component<Props> {
    render(): ReactNode {
        return (
            <div className="container d-flex mt-2 pt-1 flex-column">
                <h1>Test</h1>
                <Buttons.LinkButton
                    label="forms"
                    to="/test/forms"
                />
            </div>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (_dispatch: Dispatch): Partial<Props> => ({});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Test) as ComponentClass<OuterProps>;
