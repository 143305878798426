import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ActivateMilkRoomAction, loadSuppliersMilkRooms} from '../actions';
import Api from '../api';

const title = 'Aktivace mléčnice';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

function* handleSuccessResponse(supplierId: number): SagaIterator {
    yield* put(showSuccess(title, 'Mléčnice byla úspěšně aktivována'));
    yield* put(loadSuppliersMilkRooms(supplierId));
}

function* execute(action: ActivateMilkRoomAction): SagaIterator {
    const {milkRoomId, supplierId} = action.payload;
    const response = yield* call(Api.activateMilkRoom, milkRoomId);

    yield* response.isSuccess
        ? call(handleSuccessResponse, supplierId)
        : call(handleErrorResponse, response);
}

export function* activateMilkRoomSaga(): SagaIterator {
    yield takeLatestF('supplier/ACTIVATE_MILK_ROOM', execute);
}
