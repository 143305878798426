import * as t from 'io-ts';

const optionalPart = t.partial({
    suspicious: t.boolean,
});
const requiredPart = t.interface({
    tag: t.literal('CreateGeometricMeanByCustomer'),
});

export const CreateGeometricMeanByCustomerSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateGeometricMeanByCustomer extends t.TypeOf<typeof CreateGeometricMeanByCustomerSchema> {}
