import {Dispatch} from 'redux';

import {SupplyChainValidation} from 'types/model/supplyChains/SupplyChainValidation';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {SupplyChainFormProps} from '.';
import {validateSupplyChain} from '../../actions';
import {SupplyChainFormValues} from '../../types/SupplyChainFormValues';

type Values = SupplyChainFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('code', 'Kód');
    validator.nonEmpty('milkRoomIds', 'Stupně linky');

    return validator.generateErrorsObject();
};

export const asyncValidate = (values: Values, dispatch: Dispatch, props: SupplyChainFormProps): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
        const actionValues: SupplyChainValidation = {
            code: values.code,
            name: values.name,
            milkRoomIds: values.milkRoomIds,
        };
        dispatch(validateSupplyChain({values: actionValues, resolve, reject, isCreate: props.isCreate}));
    });
