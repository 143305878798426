import React, {PureComponent, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {OrderSearch} from 'types/model/orders/OrderSearch';

import RowActions from './RowActions';

export type OrderSearchRow = OrderSearch & {
    actions: ReactNode,
};

export interface Props {
    orderSearches: OrderSearch[];
    handleSelect(id: number): void;
}

class AcceptOrderSearchTable extends PureComponent<Props> {
    render(): React.ReactNode {
        return (
            <Tables.Table
                columns={this.genColumns()}
                rows={this.genData()}
            />
        );
    }

    private genColumns(): Column<OrderSearchRow>[] {
        return [
            {
                accessor: 'orderId',
                header: 'ID Objednávky',
                type: ColumnType.Node,
            },
            {
                accessor: 'customerName',
                header: 'Zákazník',
                type: ColumnType.Node,
            },
            {
                accessor: 'barcode',
                header: 'Čárový kód',
                tooltip: 'Unikátní identifikace vzorku',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private genData(): Row<OrderSearchRow>[] {
        const {orderSearches, handleSelect} = this.props;

        return orderSearches.map((os, rowId) => ({
            ...os,
            id: rowId,
            actions:
                <RowActions
                    id={rowId}
                    onSelect={handleSelect}
                />,
        }));
    }
}

export default AcceptOrderSearchTable;
