import {SagaIterator} from 'redux-saga';
import {takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {GetPickupLineRequestsAction, pickupLineAction} from '../actions';

function* execute ({payload: {pickupLineId, yearMonth}}: GetPickupLineRequestsAction): SagaIterator {
    yield* put(pickupLineAction.setLoading('overview', true));

    const response = yield* call(PickupLineApi.getPickupLineRequests, yearMonth, pickupLineId);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLineRequestsSuccess(response.data));
    } else {
        yield* put(pickupLineAction.getPickupLineRequestsError());
    }

    yield* put(pickupLineAction.setLoading('overview', false));
}

export function* getPickupLineRequestsSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_PICKUP_LINE_REQUESTS', execute);
}
