import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {optEmptyArray, opt} from 'ts-opt';
import {isArray} from 'lodash/fp';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {formValuesF, stopSubmitUnsafe} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';
import {DeviceMeasurementsFormValues} from 'measurement/type/DeviceMeasurementsFormValues';
import {MeasurementsImport} from 'types/model/measurements/MeasurementsImport';
import {MeasurementImportResult} from 'types/model/measurements/MeasurementImportResult';
import {MeasurementImport} from 'types/model/measurements/MeasurementImport';
import {isMeasurementValid} from '../components/DeviceMeasurementsForm/validations';
import {
    CreateDeviceMeasurementsAction,
    createDeviceMeasurementsError,
    createDeviceMeasurementsSuccess,
} from '../actions';
import Api from '../api';
import {DataItem} from 'types/model/dataItems/DataItem';

const title = 'Vytvoření měření z přístroje';

export interface PrepareMeasurements {
    measurements: MeasurementImport[];
    errorIndexTranslations: number[];
}

export function prepareMeasurements(
    formValues: DeviceMeasurementsFormValues,
    deviceDataItems: DataItem[]
): PrepareMeasurements {
    const measurements: MeasurementImport[] = [];
    const errorIndexTranslations: number[] = [];
    formValues.measurements.forEach((row, index) => {
        if (!isMeasurementValid(row, deviceDataItems)) {
            return;
        }

        const results: MeasurementImportResult[] = [];

        for (const key in row) {
            if (key === 'sampleId') {
                continue;
            }

            results.push({
                dataItemId: Number(key),
                result: Number(row[key]),
            });
        }

        measurements.push({
            sampleId: row.sampleId,
            results,
        });
        errorIndexTranslations.push(index);
    });

    return {
        measurements,
        errorIndexTranslations,
    };
}

export function prepareMeasurementsImport(
    formValues: DeviceMeasurementsFormValues,
    measurements: MeasurementImport[]
): MeasurementsImport {
    const {measured, measuredById, deviceId} = formValues;

    return {
        measured: opt(measured).orCrash('missing measured'),
        measuredById: opt(measuredById).orCrash('missing measuredById'),
        measuredUsingId: opt(deviceId).orCrash('missing deviceId'),
        measurements: optEmptyArray(measurements).orCrash('no valid measurements to send'),
    };
}

export const translateMeasurementsErrors = (
    errors: Partial<Record<string, string>>[],
    errorIndexTranslations: number[]
): Partial<Record<string, string>>[] => {
    const translatedErrors: Partial<Record<string, string>>[] = [];
    errors.forEach((error, index) => {
        if (error !== undefined) {
            translatedErrors[errorIndexTranslations[index]] = error;
        }
    });
    return translatedErrors;
};

function* execute(action: CreateDeviceMeasurementsAction): SagaIterator {
    const formValues = (yield* select(formValuesF('deviceMeasurements')))
        .orCrash('missing manually measured values');
    const deviceDataItems = optEmptyArray(action.payload.deviceDataItems).orCrash('no device data items');

    const {measurements, errorIndexTranslations} = prepareMeasurements(formValues, deviceDataItems);
    const measurementsImport = prepareMeasurementsImport(formValues, measurements);

    const response = yield* call(Api.createDeviceMeasurements, measurementsImport);

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        yield* put(createDeviceMeasurementsError(response.data as ErrorsFromBE));

        const formErrors = extractFormErrorsFromResponse(response);
        const errorsWithMeasurementsTranslated = {
            ...formErrors,
            measurements: isArray(formErrors.measurements)
                ? translateMeasurementsErrors(formErrors.measurements, errorIndexTranslations)
                : formErrors.measurements,
        };

        return yield* put(stopSubmitUnsafe('deviceMeasurements', errorsWithMeasurementsTranslated));
    }

    yield* put(createDeviceMeasurementsSuccess());
    yield* put(showSuccess(title, 'Manuální měření úspěšně vytvořeno.'));
    yield* put(routerActions.goBack());
}

export function* createDeviceMeasurementsSaga(): SagaIterator {
    yield takeLatestF('measurement/CREATE_DEVICE_MEASUREMENTS', execute);
}
