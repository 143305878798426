import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {UpdateSample} from 'types/model/samples/UpdateSample';
import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';
import {formValuesF, stopSubmitF} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';

import {UpdateSampleAction, updateSampleError, updateSampleSuccess} from '../actions';
import Api from '../api';
import {prepareUpdateSample} from './updateSampleSagaUtils';

const title = 'Upravení vzorku';

function* handleSuccessResponse(redirectUrl?: string): SagaIterator {
    yield* put(showSuccess(title, 'Vzorek upraven.'));
    yield* put(updateSampleSuccess());
    if (redirectUrl) {
        yield* put(routerActions.push(redirectUrl));
    }
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateSampleError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('updateSample', extractFormErrorsFromResponse(response)));
}

function* getSampleData(): SagaIterator<UpdateSample> {
    const values = (yield* select(formValuesF('updateSample')))
        .orCrash('missing form values');
    const user = (yield* select((state: StoreState) => state.user.currentUser))
        .orCrash('missing current user');
    const isLaborer = user.role === 'LABORER' || user.role === 'ADMIN';

    return prepareUpdateSample(values, isLaborer);
}

function* execute({payload: {sampleId, redirectUrl}}: UpdateSampleAction): SagaIterator {
    yield* put(isLoading(true));

    const sampleData = yield* call(getSampleData);
    const response = yield* call(Api.updateSample, sampleId, sampleData);

    yield* response.isSuccess ? call(handleSuccessResponse, redirectUrl) : call(handleErrorResponse, response);
    yield* put(isLoading(false));
}

export function* updateSampleSaga(): SagaIterator {
    yield takeLatestF('sample/UPDATE_SAMPLE', execute);
}
