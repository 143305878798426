import React, {Component, ComponentClass, ReactNode} from 'react';

import {Components as Icons} from 'icons';

import Button, {Importance} from '../Button';
import LinkButton from '../LinkButton';

import nextSvg from './next.svg';
import addSvg from './add.svg';
import downloadSvg from './download.svg';

type IconName = 'ADD' | 'NEXT' | 'DOWNLOAD';

const icons = {
    'NEXT': nextSvg,
    'ADD': addSvg,
    'DOWNLOAD': downloadSvg,
};

interface OuterProps {
    to?: string;
    label: string | ReactNode;
    className?: string;
    disabled?: boolean;
    icon: IconName;
    importance?: Importance;
    title?: string;

    onClick?(): void;
}

interface InnerProps {}

type Props = InnerProps & OuterProps;

// TODO: remove/merge with RowActionButton/IconButton?

class RightIconButton extends Component<Props> {
    private iconName(): string {
        const {icon} = this.props;

        return icons[icon];
    }

    render(): ReactNode {
        const {
            to,
            label,
            className,
            onClick,
            disabled,
            importance,
            title,
        } = this.props;

        const passedLabel: ReactNode =
            <div className="d-flex align-items-center text-nowrap">
                <span className="mr-1">{label}</span><Icons.SvgIcon icon={this.iconName()} />
            </div>
        ;
        if (to) {
            return (
                <LinkButton
                    title={title}
                    label={passedLabel}
                    to={to}
                    className={className}
                    disabled={disabled}
                    importance={importance}
                />
            );
        } else if (onClick) {
            return (
                <Button
                    title={title}
                    className={className}
                    type="button"
                    onClick={onClick}
                    importance={importance || 'primary'}
                    disabled={disabled}
                >
                    {passedLabel}
                </Button>
            );
        } else {
            throw new Error('Either to or onClick prop must be passed.');
        }
    }
}

export default RightIconButton as ComponentClass<OuterProps>;
