import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    LoadSuppliersMilkRoomsAction,
    loadSuppliersMilkRoomsError,
    loadSuppliersMilkRoomsSuccess,
} from '../actions';
import Api from '../api';

function* execute(action: LoadSuppliersMilkRoomsAction): SagaIterator {
    const errorTitle = 'Načtení mléčnic dodavatelů';
    const response = yield* call(Api.getSuppliersMilkRooms, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadSuppliersMilkRoomsError(error));
    }

    yield* put(loadSuppliersMilkRoomsSuccess(response.data));
}

export function* loadSuppliersMilkRoomsSaga(): SagaIterator {
    yield takeLatestF('supplier/LOAD_SUPPLIERS_MILK_ROOMS', execute);
}
