import * as t from 'io-ts';

export const SampleTypeSchema = t.keyof({
    RAW_COW_MILK: null,
    RAW_GOAT_MILK: null,
    RAW_SHEEP_MILK: null,
    SKIMMED_MILK: null,
});

export type SampleType = t.TypeOf<typeof SampleTypeSchema>;
