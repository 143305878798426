import {ErrorsFromBE} from 'favorlogic-utils';

import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {SampleCreationType} from 'order/types/SampleCreationType';
import {UpdateSampleGloballyFormValues} from 'order/types/UpdateSampleGloballyFormValues';
import {Analysis} from 'types/model/analyses/Analysis';
import {OrderStats} from 'types/model/orders/OrderStats';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {ProtocolDetails} from 'types/model/protocols/ProtocolDetails';
import {ParsedSample} from 'types/model/samples/ParsedSample';
import {OrdersPage} from 'types/model/orders/OrdersPage';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {OrderSearch} from 'types/model/orders/OrderSearch';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';

import {UpdateOrderFormValues} from './types/UpdateOrderFormValues';
import {CreateOrderFormValues} from './types/CreateOrderFormValues';
import {OrderCustomerFormValues} from './types/OrderCustomerFormValues';
import {OrdersFilter, OrdersParams} from './types/OrderParams';

export interface ResetOrdersAction {
    type: 'order/RESET_ORDERS';
}

export const resetOrders = (): ResetOrdersAction => ({
    type: 'order/RESET_ORDERS',
});

export interface LoadOrdersAction {
    type: 'order/LOAD_ORDERS';
    payload: OrdersParams;
}

export const loadOrders = (params: OrdersParams): LoadOrdersAction => ({
    type: 'order/LOAD_ORDERS',
    payload: params,
});

export interface LoadOrdersSuccessAction {
    payload: OrdersPage;
    type: 'order/LOAD_ORDERS_SUCCESS';
}

export const loadOrdersSuccess = (payload: OrdersPage): LoadOrdersSuccessAction => ({
    type: 'order/LOAD_ORDERS_SUCCESS',
    payload,
});

export interface LoadOrdersErrorAction {
    type: 'order/LOAD_ORDERS_ERROR';
    payload: ErrorsFromBE;
}

export const loadOrdersError = (err: ErrorsFromBE): LoadOrdersErrorAction => ({
    type: 'order/LOAD_ORDERS_ERROR',
    payload: err,
});

export const setCurrentSample = (payload: OrderSampleView) => ({
    type: 'order/SET_CURRENT_SAMPLE',
    payload,
}) as const;
export type SetCurrentSampleAction = ReturnType<typeof setCurrentSample>;

export interface ResetCurrentSampleAction {
    type: 'order/RESET_CURRENT_SAMPLE';
}

export const resetCurrentSample = (): ResetCurrentSampleAction => ({
    type: 'order/RESET_CURRENT_SAMPLE',
});

export interface ResetOrderAction {
    type: 'order/RESET_ORDER';
}

export const resetOrder = (): ResetOrderAction => ({
    type: 'order/RESET_ORDER',
});

// --- Load Order

export interface LoadOrderAction {
    type: 'order/LOAD_ORDER';
    payload: {
        orderId: number,
    };
}

export const loadOrder = (orderId: number): LoadOrderAction => ({
    type: 'order/LOAD_ORDER',
    payload: {orderId},
});

export interface LoadOrderSuccessAction {
    type: 'order/LOAD_ORDER_SUCCESS';
    payload: {
        order: OrderDetails,
    };

}

export const loadOrderSuccess = (order: OrderDetails): LoadOrderSuccessAction => ({
    type: 'order/LOAD_ORDER_SUCCESS',
    payload: {order},
});

// --- Update Order

export interface UpdateOrderAction {
    type: 'order/UPDATE_ORDER';
    payload: {
        orderId: number,
    };
}

export const updateOrder = (orderId: number): UpdateOrderAction => ({
    type: 'order/UPDATE_ORDER',
    payload: {orderId},
});

// --- Prefill Update Order Details

export interface PrefillUpdateOrderDetailsFormAction {
    type: 'order/PREFILL_UPDATE_ORDER_DETAILS_FORM';
    payload: UpdateOrderFormValues;
}

export const prefillUpdateOrderDetailsForm =
    (payload: UpdateOrderFormValues): PrefillUpdateOrderDetailsFormAction => ({
        type: 'order/PREFILL_UPDATE_ORDER_DETAILS_FORM',
        payload,
    });

export interface UpdateSampleGloballyFormValuesAction {
    type: 'order/UPDATE_SAMPLE_GLOBALLY_FORM_VALUES';
    payload: UpdateSampleGloballyFormValues | null;
}

export const updateSampleGloballyFormValues =
    (payload: UpdateSampleGloballyFormValues | null ): UpdateSampleGloballyFormValuesAction => ({
        type: 'order/UPDATE_SAMPLE_GLOBALLY_FORM_VALUES',
        payload,
    });

// --- Create Order

export interface CreateOrderAction {
    type: 'order/CREATE_ORDER';
    payload: {isAdminOrLaborer: boolean};
}

export const createOrder = (isAdminOrLaborer: boolean): CreateOrderAction => ({
    type: 'order/CREATE_ORDER',
    payload: {isAdminOrLaborer},
});

export interface CreateBarcodeSearchAction {
    type: 'order/PREFILL_BARCODE_SEARCH_FORM';
    payload: string;
}

export const createBarcodeSearch = (barcode: string): CreateBarcodeSearchAction => ({
    type: 'order/PREFILL_BARCODE_SEARCH_FORM',
    payload: barcode,
});

export interface CreateOrderSuccessAction {
    type: 'order/CREATE_ORDER_SUCCESS';
    payload: {orderId: number};
}

export const createOrderSuccess = (orderId: number): CreateOrderSuccessAction => ({
    type: 'order/CREATE_ORDER_SUCCESS',
    payload: {orderId},
});

export interface CreateOrderErrorAction {
    type: 'order/CREATE_ORDER_ERROR';
    payload: {error: ErrorsFromBE};
}

export const createOrderError = (error: ErrorsFromBE): CreateOrderErrorAction => ({
    type: 'order/CREATE_ORDER_ERROR',
    payload: {error},
});

// --- Accepting order
export interface AcceptOrderAction {
    type: 'order/ACCEPT_ORDER';
    payload: number;
}

export const acceptOrder = (payload: number): AcceptOrderAction => ({
    type: 'order/ACCEPT_ORDER',
    payload,
});

export interface AcceptOrderSuccessAction {
    type: 'order/ACCEPT_ORDER_SUCCESS';
}

export const acceptOrderSuccess = (): AcceptOrderSuccessAction => ({
    type: 'order/ACCEPT_ORDER_SUCCESS',
});

export interface AcceptOrderErrorAction {
    type: 'order/ACCEPT_ORDER_ERROR';
    payload: ErrorsFromBE;
}

export const acceptOrderError = (err: ErrorsFromBE): AcceptOrderErrorAction => ({
    type: 'order/ACCEPT_ORDER_ERROR',
    payload: err,
});

// --- Accepting order
export interface CancelOrderAction {
    type: 'order/CANCEL_ORDER';
    payload: number;
}

export const cancelOrder = (id: number): CancelOrderAction => ({
    type: 'order/CANCEL_ORDER',
    payload: id,
});


// --- Add/Remove sample in order Creation

export interface AddSampleInOrderCreationAction {
    type: 'order/ADD_SAMPLE_IN_ORDER_CREATION';
    payload: CreateSampleFormValues;
}

export const addSampleInOrderCreation = (payload: CreateSampleFormValues): AddSampleInOrderCreationAction => ({
    type: 'order/ADD_SAMPLE_IN_ORDER_CREATION',
    payload,
});

export interface AddImportedSamplesInOrderCreationAction {
    type: 'order/ADD_IMPORTED_SAMPLES_IN_ORDER_CREATION';
}

export const addImportedSamplesInOrderCreation = (): AddImportedSamplesInOrderCreationAction => ({
    type: 'order/ADD_IMPORTED_SAMPLES_IN_ORDER_CREATION',
});

export interface UpdateSampleInOrderCreationAction {
    type: 'order/UPDATE_SAMPLE_IN_ORDER_CREATION';
    payload: number;
}

export const updateSampleInOrderCreation = (payload: number): UpdateSampleInOrderCreationAction => ({
    type: 'order/UPDATE_SAMPLE_IN_ORDER_CREATION',
    payload,
});

export interface ResetImportedSamplesAction {
    type: 'order/RESET_IMPORTED_SAMPLES';
}

export const resetImportedSamples = (): ResetImportedSamplesAction => ({
    type: 'order/RESET_IMPORTED_SAMPLES',
});

// --- Load OrdersStats

export interface LoadOrdersStatsAction {
    type: 'order/LOAD_ORDERS_STATS';
}

export const loadOrdersStats = (): LoadOrdersStatsAction => ({
    type: 'order/LOAD_ORDERS_STATS',
});

export interface LoadOrdersStatsSuccessAction {
    payload: OrderStats;
    type: 'order/LOAD_ORDERS_STATS_SUCCESS';
}

export const loadOrdersStatsSuccess = (payload: OrderStats): LoadOrdersStatsSuccessAction => ({
    type: 'order/LOAD_ORDERS_STATS_SUCCESS',
    payload,
});

export interface LoadOrdersStatsErrorAction {
    type: 'order/LOAD_ORDERS_STATS_ERROR';
    payload: ErrorsFromBE;
}

export const loadOrdersStatsError = (err: ErrorsFromBE): LoadOrdersStatsErrorAction => ({
    type: 'order/LOAD_ORDERS_STATS_ERROR',
    payload: err,
});

// --- Add last created sample
export interface AddLastCreatedSampleAction {
    type: 'order/SET_LAST_CREATED_SAMPLE';
    payload: CreateSampleFormValues | null;
}

export const setLastCreatedSample =
    (payload: CreateSampleFormValues | null): AddLastCreatedSampleAction => ({
        type: 'order/SET_LAST_CREATED_SAMPLE',
        payload,
    });
// ---

export interface SetAnalysesBySelectedDataItemsAction {
    type: 'order/SET_ANALYSES_BY_SELECTED_DATA_ITEMS';
    payload: Analysis[];
}

export const setAnalysesBySelectedDataItems =
    (payload: Analysis[]): SetAnalysesBySelectedDataItemsAction => ({
        type: 'order/SET_ANALYSES_BY_SELECTED_DATA_ITEMS',
        payload,
    });

// ---

export interface ImportSampleAction {
    type: 'order/IMPORT_SAMPLE';
    payload: File;
}

export const importSample = (payload: File): ImportSampleAction => ({
    type: 'order/IMPORT_SAMPLE',
    payload,
});

export interface ImportSampleSuccessAction {
    payload: ParsedSample[];
    type: 'order/IMPORT_SAMPLE_SUCCESS';
}

export const importSampleSuccess = (payload: ParsedSample[]): ImportSampleSuccessAction => ({
    type: 'order/IMPORT_SAMPLE_SUCCESS',
    payload,
});

export interface ImportSampleErrorAction {
    type: 'order/IMPORT_SAMPLE_ERROR';
    payload: ErrorsFromBE;
}

export const importSampleError = (err: ErrorsFromBE): ImportSampleErrorAction => ({
    type: 'order/IMPORT_SAMPLE_ERROR',
    payload: err,
});

// ---

export interface PrefillCreateSampleFormAction {
    type: 'order/PREFILL_CREATE_SAMPLE_FORM';
    payload: CreateSampleFormValues;
}

export const prefillCreateSampleForm = (payload: CreateSampleFormValues): PrefillCreateSampleFormAction => ({
    type: 'order/PREFILL_CREATE_SAMPLE_FORM',
    payload,
});

// ---

export interface SelectSampleCreationTypeAction {
    type: 'order/SELECT_SAMPLE_CREATION_TYPE';
    payload?: SampleCreationType;
}

export const selectSampleCreationType = (payload?: SampleCreationType): SelectSampleCreationTypeAction => ({
    type: 'order/SELECT_SAMPLE_CREATION_TYPE',
    payload,
});

// --- Samples from supply chains

export interface UpdateSamplesFromSupplyChainAction {
    type: 'order/UPDATE_SAMPLES_FROM_SUPPLY_CHAIN';
    payload: CreateSampleFormValues[];
}

export const updateSamplesFromSupplyChain = (payload: CreateSampleFormValues[]): UpdateSamplesFromSupplyChainAction => (
    {
        type: 'order/UPDATE_SAMPLES_FROM_SUPPLY_CHAIN',
        payload,
    }
);
// ---

export const updateSample = (sampleId: number) => ({
    type: 'order/UPDATE_SAMPLE',
    payload: {sampleId},
}) as const;
export type UpdateSampleAction = ReturnType<typeof updateSample>;

export const loadProtocols = (orderId: number) => ({
    type: 'order/LOAD_PROTOCOLS',
    payload: {orderId},
}) as const;
export type LoadProtocolsAction = ReturnType<typeof loadProtocols>;

export const loadProtocolsSuccess = (payload: ProtocolDetails[]) => ({
    type: 'order/LOAD_PROTOCOLS_SUCCESS',
    payload,
}) as const;
export type LoadProtocolsSuccessAction = ReturnType<typeof loadProtocolsSuccess>;

export const loadProtocolsError = (err: ErrorsFromBE) => ({
    type: 'order/LOAD_PROTOCOLS_ERROR',
    payload: err,
}) as const;
export type LoadProtocolsErrorAction = ReturnType<typeof loadProtocolsError>;

// ---

export const generateProtocol = (payload: {orderId: number}) => ({
    type: 'order/GENERATE_PROTOCOL',
    payload,
}) as const;
export type GenerateProtocolAction = ReturnType<typeof generateProtocol>;

export const generateProtocolSuccess = () => ({
    type: 'order/GENERATE_PROTOCOL_SUCCESS',
}) as const;
export type GenerateProtocolSuccessAction = ReturnType<typeof generateProtocolSuccess>;

export const generateProtocolError = (err: ErrorsFromBE) => ({
    type: 'order/GENERATE_PROTOCOL_ERROR',
    payload: err,
}) as const;
export type GenerateProtocolErrorAction = ReturnType<typeof generateProtocolError>;

// ---

export const approveProtocol = (orderId: number, isEdit: boolean) => ({
    type: 'order/APPROVE_PROTOCOL',
    payload: {orderId, isEdit},
}) as const;
export type ApproveProtocolAction = ReturnType<typeof approveProtocol>;

export const approveProtocolSuccess = () => ({
    type: 'order/APPROVE_PROTOCOL_SUCCESS',
}) as const;
export type ApproveProtocolSuccessAction = ReturnType<typeof approveProtocolSuccess>;

export const approveProtocolError = (err: ErrorsFromBE) => ({
    type: 'order/APPROVE_PROTOCOL_ERROR',
    payload: err,
}) as const;
export type ApproveProtocolErrorAction = ReturnType<typeof approveProtocolError>;

// ---

export const deleteProtocol = (protocolId: number, orderId: number) => ({
    type: 'order/DELETE_PROTOCOL',
    payload: {protocolId, orderId},
}) as const;
export type DeleteProtocolAction = ReturnType<typeof deleteProtocol>;

// ---

export const downloadShippingTicket = (payload: number) => ({
    type: 'order/DOWNLOAD_SHIPPING_TICKET',
    payload,
}) as const;
export type DownloadShippingTicketAction = ReturnType<typeof downloadShippingTicket>;

export const downloadShippingTicketSuccess = () => ({
    type: 'order/DOWNLOAD_SHIPPING_TICKET_SUCCESS',
}) as const;
export type DownloadShippingTicketSuccessAction = ReturnType<typeof downloadShippingTicketSuccess>;

export const downloadShippingTicketError = (err: ErrorsFromBE) => ({
    type: 'order/DOWNLOAD_SHIPPING_TICKET_ERROR',
    payload: err,
}) as const;
export type DownloadShippingTicketErrorAction = ReturnType<typeof downloadShippingTicketError>;

export const setSampleIdForDisable = (sampleId: number) => ({
    type: 'order/SET_SAMPLE_ID_FOR_DISABLE',
    payload: sampleId,
}) as const;
export type SetSampleIdForDisable = ReturnType<typeof setSampleIdForDisable>;

export const resetSampleIdForDisable = () => ({
    type: 'order/RESET_SAMPLE_ID_FOR_DISABLE',
}) as const;
export type ResetSampleIdForDisableAction = ReturnType<typeof resetSampleIdForDisable>;

export const exportTxtProtocols = () => ({
    type: 'order/EXPORT_TXT_PROTOCOLS',
}) as const;
export type ExportTxtProtocolsAction = ReturnType<typeof exportTxtProtocols>;

export const selectOrders = (ids: number[]) => ({
    type: 'order/SELECT_ORDERS',
    payload: ids,
}) as const;
export type SelectOrdersAction = ReturnType<typeof selectOrders>;

export const exportTranscripts = (params: OrdersFilter) => ({
    type: 'order/EXPORT_TRANSCRIPTS',
    payload: {params},
}) as const;
export type ExportTranscriptAction = ReturnType<typeof exportTranscripts>;

export const exportTranscriptSubset = (orderIds: number[]) => ({
    type: 'order/EXPORT_TRANSCRIPT_SUBSET',
    payload: {orderIds},
}) as const;
export type ExportTranscriptSubsetAction = ReturnType<typeof exportTranscriptSubset>;

export const downloadInvoice = (orderId: number) => ({
    type: 'order/DOWNLOAD_INVOICE',
    payload: orderId,
}) as const;
export type DownloadInvoiceAction = ReturnType<typeof downloadInvoice>;

export const exportOrdersInvoiceSubset = (orderIds: number[]) => ({
    type: 'order/EXPORT_ORDERS_INVOICE_SUBSET',
    payload: {orderIds},
}) as const;
export type ExportOrdersInvoiceSubsetAction = ReturnType<typeof exportOrdersInvoiceSubset>;

export const exportOrdersInvoice = (params: OrdersFilter) => ({
    type: 'order/EXPORT_ORDERS_INVOICE',
    payload: {params},
}) as const;
export type ExportOrdersInvoiceAction = ReturnType<typeof exportOrdersInvoice>;



// ---

export interface PrefillCreateOrderFormAction {
    type: 'order/PREFILL_CREATE_ORDER_FORM';
    payload: CreateOrderFormValues;
}

export const prefillCreateOrderForm = (payload: CreateOrderFormValues): PrefillCreateOrderFormAction => ({
    type: 'order/PREFILL_CREATE_ORDER_FORM',
    payload,
});

// ---

export interface PrefillOrderCustomerFormAction {
    type: 'order/PREFILL_ORDER_CUSTOMER_FORM';
    payload: OrderCustomerFormValues;
}

export const prefillOrderCustomerForm = (payload: OrderCustomerFormValues): PrefillOrderCustomerFormAction => ({
    type: 'order/PREFILL_ORDER_CUSTOMER_FORM',
    payload,
});

// ---

export interface FillOrderCustomerFormFromAresAction {
    type: 'order/FILL_ORDER_CUSTOMER_FORM_FROM_ARES';
    payload: string;
}

export const fillOrderCustomerFormFromAres = (ico: string): FillOrderCustomerFormFromAresAction => ({
    type: 'order/FILL_ORDER_CUSTOMER_FORM_FROM_ARES',
    payload: ico,
});

// ---

export interface IsAresLoadingAction {
    type: 'order/IS_ARES_LOADING';
    payload: {
        value: boolean,
    };
}

export const isAresLoading = (value: boolean): IsAresLoadingAction => ({
    type: 'order/IS_ARES_LOADING',
    payload: {value},
});

// ---

export interface ResetAresFormValuesAction {
    type: 'order/RESET_ARES_FORM_VALUES';
}

export const resetAresFormValues = (): ResetAresFormValuesAction => ({
    type: 'order/RESET_ARES_FORM_VALUES',
});

// ---

export interface LoadCustomerForOrderAction {
    type: 'order/LOAD_CUSTOMER_FOR_ORDER';
    payload: {
        customerId: number,
    };
}

export const loadCustomerForOrder = (customerId: number): LoadCustomerForOrderAction => ({
    type: 'order/LOAD_CUSTOMER_FOR_ORDER',
    payload: {customerId},
});

export interface LoadCustomerForOrderSuccessAction {
    type: 'order/LOAD_CUSTOMER_FOR_ORDER_SUCCESS';
    payload: {
        customer: CustomerDetailsForLaborer,
    };
}

export const loadCustomerForOrderSuccess = (
    customer: CustomerDetailsForLaborer
): LoadCustomerForOrderSuccessAction => ({
    type: 'order/LOAD_CUSTOMER_FOR_ORDER_SUCCESS',
    payload: {customer},
});

export interface LoadCustomerForOrderErrorAction {
    type: 'order/LOAD_CUSTOMER_FOR_ORDER_ERROR';
    payload: {
        error: ErrorsFromBE,
    };
}

export const loadCustomerForOrderError = (error: ErrorsFromBE): LoadCustomerForOrderErrorAction => ({
    type: 'order/LOAD_CUSTOMER_FOR_ORDER_ERROR',
    payload: {error},
});

export interface ResetCustomerForOrderAction {
    type: 'order/RESET_CUSTOMER_FOR_ORDER';
}

export const resetCustomerForOrder = (): ResetCustomerForOrderAction => ({
    type: 'order/RESET_CUSTOMER_FOR_ORDER',
});

export interface SearchOrderSamplesBarcodeAction {
    type: 'order/SEARCH_ORDER_SAMPLES_BARCODE';
    payload: {
        barcode: string,
    };
}

export const searchOrderSamplesBarcode = (barcode: string): SearchOrderSamplesBarcodeAction => ({
    type: 'order/SEARCH_ORDER_SAMPLES_BARCODE',
    payload: {barcode},
});

export interface SetOrderSearchesAction {
    type: 'order/SET_ORDER_SEARCHES';
    payload: {
        value: OrderSearch[] | null,
    };
}

export const setOrderSearches = (value: OrderSearch[] | null): SetOrderSearchesAction => ({
    type: 'order/SET_ORDER_SEARCHES',
    payload: {value},
});

export interface ExportMeasurementsFillingAction {
    type: 'order/EXPORT_MEASUREMENT_FILLING';
}

export const exportMeasurementsFilling = (): ExportMeasurementsFillingAction => ({
    type: 'order/EXPORT_MEASUREMENT_FILLING',
});

export const reloadMilkRoomsInOrderSamples = (customerMilkRooms: MilkRoom[]) => ({
    type: 'order/RELOAD_MILK_ROOMS_IN_ORDER_SAMPLES',
    payload: {customerMilkRooms},
}) as const;
export type ReloadMilkRoomsInOrderSamplesAction = ReturnType<typeof reloadMilkRoomsInOrderSamples>;
// ---

export type OrderAction =
    LoadOrdersAction | LoadOrdersSuccessAction | LoadOrdersErrorAction |
    ResetOrderAction | LoadOrderAction | LoadOrderSuccessAction |
    CreateOrderAction | CreateOrderSuccessAction | CreateOrderErrorAction |
    UpdateOrderAction |
    AcceptOrderAction | AcceptOrderSuccessAction | AcceptOrderErrorAction |
    PrefillUpdateOrderDetailsFormAction | UpdateSampleGloballyFormValuesAction |
    AddSampleInOrderCreationAction | AddImportedSamplesInOrderCreationAction |
    ResetImportedSamplesAction | UpdateSampleInOrderCreationAction |
    LoadOrdersStatsAction | LoadOrdersStatsSuccessAction | LoadOrdersStatsErrorAction |
    AddLastCreatedSampleAction |
    SetAnalysesBySelectedDataItemsAction |
    ImportSampleAction | ImportSampleSuccessAction | ImportSampleErrorAction |
    PrefillCreateSampleFormAction |
    SelectSampleCreationTypeAction |
    UpdateSamplesFromSupplyChainAction |
    LoadProtocolsAction | LoadProtocolsSuccessAction | LoadProtocolsErrorAction |
    GenerateProtocolAction | GenerateProtocolSuccessAction | GenerateProtocolErrorAction |
    ApproveProtocolAction | ApproveProtocolSuccessAction | ApproveProtocolErrorAction |
    DownloadShippingTicketAction | DownloadShippingTicketSuccessAction | DownloadShippingTicketErrorAction |
    PrefillCreateOrderFormAction |
    PrefillOrderCustomerFormAction | FillOrderCustomerFormFromAresAction | IsAresLoadingAction |
    ResetAresFormValuesAction | ResetOrdersAction |
    SetCurrentSampleAction | ResetCurrentSampleAction | CreateBarcodeSearchAction |
    SetSampleIdForDisable | ResetSampleIdForDisableAction |
    LoadCustomerForOrderAction | LoadCustomerForOrderSuccessAction | LoadCustomerForOrderErrorAction |
    ResetCustomerForOrderAction | SearchOrderSamplesBarcodeAction | SetOrderSearchesAction | ExportTxtProtocolsAction |
    SelectOrdersAction | ExportTranscriptSubsetAction | ExportTranscriptAction |
    CancelOrderAction | DownloadInvoiceAction | ExportOrdersInvoiceSubsetAction | ExportOrdersInvoiceAction |
    ReloadMilkRoomsInOrderSamplesAction |
    DeleteProtocolAction
    ;
