import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';

import {DownloadFileAction} from 'download/actions';

import Api from '../api';

// generics don't seem to be supported by redux-saga
function* execute(action: DownloadFileAction<unknown>): SagaIterator {
    const response = yield* call(Api.download, action.payload);

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, 'Stažení souboru'));
    }
}

export function* downloadFileSaga(): SagaIterator {
    yield takeLatestF('download/DOWNLOAD_FILE', execute);
}
