import {flatten} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {UpdateMilkRoom} from 'types/model/milkRooms/UpdateMilkRoom';
import RowActions from './RowActions';

export type SupplierRow = AnyMilkRoom & {
    actions: ReactNode,
};

export type MilkRoomsTableMode = 'CREATE' | 'EDIT' | 'VIEW';

export type DeleteMilkRoomFn = (supplierId: number | null, id: number, isLast: boolean) => void;

type AnyMilkRoom = MilkRoom | UpdateMilkRoom;

export interface Props {
    milkRooms: AnyMilkRoom[];
    mode: MilkRoomsTableMode;
    supplierId?: number;
    handleDelete?: DeleteMilkRoomFn;
    handleActivate?: (id: number) => void;
    handleDeactivate?: (id: number) => void;
}

class MilkRoomsTable extends Component<Props> {
    render(): React.ReactNode {
        return (
            <div>
                <Tables.Table
                    columns={this.genColumns()}
                    rows={this.genData()}
                />
            </div>
        );
    }

    private genColumns(): Column<SupplierRow>[] {
        const {mode} = this.props;
        const dataColumns: Column<SupplierRow>[] = [
            {
                accessor: 'name',
                header: 'Společnost',
                type: ColumnType.Node,
            },
            {
                accessor: 'code',
                header: 'Kód',
                type: ColumnType.Node,
            },
            {
                accessor: 'veterinaryId',
                header: 'Veterinární číslo',
                type: ColumnType.Node,
            },
        ];
        const actionCol: Column<SupplierRow> = {
            accessor: 'actions',
            header: 'Akce',
            type: ColumnType.Node,
        };
        return flatten([
            dataColumns,
            mode !== 'VIEW' ? [actionCol] : [],
        ]);
    }

    private genData(): Row<SupplierRow>[] {
        const {milkRooms, handleDelete, mode, supplierId, handleActivate, handleDeactivate} = this.props;
        const onDelete = (id: number) => {
            if (handleDelete) { handleDelete(supplierId || null, id, milkRooms.length === 1); }
        };
        return milkRooms.map((x: AnyMilkRoom, idx: number) => {
            const id = 'id' in x && mode === 'EDIT' ? x.id : idx;
            const canDelete = 'canDelete' in x ? x.canDelete : true;
            const active = 'active' in x ? x.active : true;

            return {
                veterinaryId: x.veterinaryId,
                code: x.code,
                name: x.name,
                id,
                lowlighted: !active,
                actions:
                    <RowActions
                        id={id}
                        handleDelete={onDelete}
                        mode={mode}
                        supplierId={supplierId}
                        canDelete={canDelete}
                        active={active}
                        handleActivate={handleActivate}
                        handleDeactivate={handleDeactivate}
                    />
                ,
            };
        });
    }
}

export default MilkRoomsTable;
