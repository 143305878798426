exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-order-components-CreateSampleForm-___styles__infoIcon___3cmI5 {\n  position: absolute;\n  top: 0;\n  right: 40px; }\n", ""]);

// exports
exports.locals = {
	"infoIcon": "src-modules-order-components-CreateSampleForm-___styles__infoIcon___3cmI5"
};