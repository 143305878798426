import React, {Component, ReactNode} from 'react';
import {convertStringToLimitedLengthElement} from 'favorlogic-utils';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import RowActions from './RowActions';
import {LoaderWrapper} from 'layout/components';

const limit = convertStringToLimitedLengthElement(90);

export type SupplyChainRow = SupplyChain & {
    chain: ReactNode,
    actions: ReactNode,
};

interface OuterProps {
    supplyChains: SupplyChain[] | null;

    handleDelete(id: number): void;
}

interface InnerProps {}

export type Props = InnerProps & OuterProps;

class SupplyChainsTable extends Component<Props> {
    private static genColumns(): Column<SupplyChainRow>[] {
        return [
            {
                accessor: 'code',
                header: 'Identifikace',
                type: ColumnType.Node,
            },
            {
                accessor: 'name',
                header: 'Název',
                type: ColumnType.Node,
            },
            {
                accessor: 'chain',
                header: 'Mléčnice',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    render(): React.ReactNode {
        const {supplyChains} = this.props;

        return (
            <LoaderWrapper showLoader={!supplyChains}>
                <Tables.Table
                    columns={SupplyChainsTable.genColumns()}
                    rows={this.genData()}
                />
            </LoaderWrapper>
        );
    }

    private genData(): Row<SupplyChainRow>[] {
        const {
            supplyChains,
            handleDelete,
        } = this.props;
        if (!supplyChains) {
            return [];
        }

        return supplyChains.map(supplyChain => {
            const actions =
                <RowActions
                    supplyChain={supplyChain}
                    handleDelete={handleDelete}
                />
            ;
            const chain = supplyChain.milkRooms.map(x => x.name).join(' ⇨ ');
            return {
                ...supplyChain,
                chain: limit(chain),
                actions,
            };
        });
    }
}

export default SupplyChainsTable;
