import * as t from 'io-ts';
import {PResponse, EmptyObjectSchema, EmptyObject} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {Analysis, AnalysisSchema} from 'types/model/analyses/Analysis';
import {UpdateAnalysis, UpdateAnalysisSchema} from 'types/model/analyses/UpdateAnalysis';
import {apiRequester} from 'app/api';

class Api {
    getAnalyses(): PResponse<Analysis[]> {
        return apiRequester.makeRequest({
            url: '/analyses',
            requestSchema: t.void,
            responseSchema: t.array(AnalysisSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    getAnalysis(id: number): PResponse<Analysis> {
        return apiRequester.makeRequest({
            url: `/analyses/${id}`,
            requestSchema: t.void,
            responseSchema: AnalysisSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    updateAnalysis(id: number, data: UpdateAnalysis): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/analyses/${id}`,
            method: 'put',
            data,
            requestSchema: UpdateAnalysisSchema,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}

export default new Api();
