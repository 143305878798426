import React, {Component, ReactNode} from 'react';

import {Components as Tables} from 'tables';
import {Column, ColumnType} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {UserDetails} from 'types/model/users/UserDetails';
import {LoaderWrapper} from 'layout/components';

import RowActions from './RowActions';

type UserRow = UserDetails & {
    actions: ReactNode,
};

export interface Props {
    users: UserDetails[] | null;

    deleteUser(userId: number): void;
}

class UsersTable extends Component<Props> {
    render(): ReactNode {
        const {users} = this.props;

        return (
            <LoaderWrapper showLoader={!users}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData()}
                />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<UserRow>[] {
        return [
            {
                accessor: 'firstName',
                header: 'Jméno',
                type: ColumnType.Node,
            },
            {
                accessor: 'lastName',
                header: 'Příjmení',
                type: ColumnType.Node,
            },
            {
                accessor: 'username',
                header: ' Uživatelské jméno',
                type: ColumnType.Node,
            },
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getData(): Row<UserRow>[] {
        const {users, deleteUser} = this.props;
        if (!users) {
            return [];
        }

        return users.map(user => ({
            ...user,
            actions: <RowActions
                user={user}
                deleteUser={deleteUser}
            />,
        }));
    }
}

export default UsersTable;
