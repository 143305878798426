import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {DataItemFormValues} from 'dataItem/types/DataItemFormValues';

export const validate = (values: DataItemFormValues): Errors<DataItemFormValues> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('numOfDecimalPlaces', 'Desetinná míst');
    validator.floatNumber('numOfDecimalPlaces', 'Desetinná míst');
    validator.positiveOrZeroNumber('numOfDecimalPlaces', 'Počet desetiných míst');
    validator.nonEmpty('minimum', 'Minimum');
    validator.floatNumber('minimum', 'Minimum');

    if (values.maximum) {
        validator.maxNumberCustomError(
            'minimum',
            'Minimum musí být menší nebo rovno maximu.',
            Number(values.maximum),
        );
    }

    validator.nonEmpty('maximum', 'Maximum');
    validator.floatNumber('maximum', 'Maximum');
    validator.nonEmpty('measurementUnit', 'Jednotka');
    validator.nonEmpty('dilution', 'Ředění');
    validator.dilution('dilution', 'Ředění');

    return validator.generateErrorsObject();
};
