import {reset} from 'redux-form';
import {routerActions, RouterAction} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    emptyStringToUndefined,
    showSuccess,
    takeLatestF,
    ErrorsFromBE,
    ErrorResponse,
} from 'favorlogic-utils';

import {CreateOrder} from 'types/model/orders/CreateOrder';
import {handleResponseError} from 'utils/handleResponseError';
import {canLeavePage, isLoading} from 'layout/actions';
import {formValuesF} from 'utils/formHelpers';
import {CreateSample} from 'types/model/samples/CreateSample';
import {CustomerInvoiceDetails} from 'types/model/common/CustomerInvoiceDetails';
import {Customer} from 'types/model/customers/Customer';

import {CreateOrderAction, selectSampleCreationType, createOrderSuccess, createOrderError} from '../actions';
import {CreateOrderFormValues} from '../types/CreateOrderFormValues';
import Api from '../api';

const title = 'Vytvoření objednávky';

export function prepareCreateOrderValues(formValues: CreateOrderFormValues): CreateOrder {
    if (!formValues.deliveryType || !formValues.customerDetails) {
        throw new Error('Missing order data');
    }

    const {invoiceDetails} = formValues.customerDetails;
    const samples: CreateSample[] = formValues.samples.map(s => ({
        ...s,
        customerNote: emptyStringToUndefined(s.customerNote),
        traitNote: emptyStringToUndefined(s.traitNote),
    }));
    const invoiceDetailsUpdated: CustomerInvoiceDetails = {
        ...invoiceDetails,
        ico: emptyStringToUndefined(invoiceDetails.ico),
        dic: emptyStringToUndefined(invoiceDetails.dic),
    };
    const customerDetails: Customer = {
        ...formValues.customerDetails,
        invoiceDetails: invoiceDetailsUpdated,
    };

    return {
        samples,
        deliveryType: formValues.deliveryType,
        note: formValues.note,
        customerDetails,
    };
}

function* getCreateOrderData(): SagaIterator<CreateOrder> {
    const formValues = (yield* select(formValuesF('createOrder')))
        .orCrash('missing order form data');

    return prepareCreateOrderValues(formValues);
}

function goToNextLocation(isAdminOrLaborer: boolean): RouterAction {
    const nextLocation =  isAdminOrLaborer ? '/orders' : '/orders/new/shippingTicket';

    return routerActions.push(nextLocation);
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title, {includeFieldErrors: true});
    yield* put(createOrderError(response.data as ErrorsFromBE));
}

function* handleSuccess(orderId: number, isAdminOrLaborer: boolean): SagaIterator {
    yield* put(showSuccess(title, 'Úspěšně vytvořena.'));
    yield* put(createOrderSuccess(orderId));
    yield* put(canLeavePage(true));
    yield* put(selectSampleCreationType());
    yield* put(reset('createOrder'));
    yield* put(reset('orderCustomer'));
    yield* put(goToNextLocation(isAdminOrLaborer));
}

function* execute({payload: {isAdminOrLaborer}}: CreateOrderAction): SagaIterator {
    yield* put(isLoading(true));

    const data = yield* call(getCreateOrderData);
    const response = yield* call(Api.createOrder, data);

    yield* put(isLoading(false));

    yield* response.isSuccess
        ? call(handleSuccess, response.data.id, isAdminOrLaborer)
        : call(handleErrorResponse, response);
}

export function* createOrderSaga(): SagaIterator {
    yield takeLatestF('order/CREATE_ORDER', execute);
}
