import * as t from 'io-ts';

import {PasswordSchema} from '../common/Password';

const optionalPart = t.partial({
    password: PasswordSchema,
});
const requiredPart = t.interface({
    firstName: t.string,
    lastName: t.string,
});

export const UpdateUserSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateUser extends t.TypeOf<typeof UpdateUserSchema> {}
