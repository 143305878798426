exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-user-screens-___styles__login___3CEpQ {\n  width: 20em; }\n\n.src-modules-user-screens-___styles__logoMain___l81X9 {\n  width: 220px;\n  max-height: 134px; }\n  @media screen and (max-width: 450px) {\n    .src-modules-user-screens-___styles__logoMain___l81X9 {\n      width: 150px; } }\n\n.src-modules-user-screens-___styles__loginWrapper___2ar93 {\n  height: 100%; }\n  @media screen and (max-width: 450px) {\n    .src-modules-user-screens-___styles__loginWrapperInner___3QUVy {\n      padding-top: 6.5rem; } }\n  .src-modules-user-screens-___styles__loginWrapperInner___3QUVy img {\n    width: 100%; }\n", ""]);

// exports
exports.locals = {
	"login": "src-modules-user-screens-___styles__login___3CEpQ",
	"logoMain": "src-modules-user-screens-___styles__logoMain___l81X9",
	"loginWrapper": "src-modules-user-screens-___styles__loginWrapper___2ar93",
	"loginWrapperInner": "src-modules-user-screens-___styles__loginWrapperInner___3QUVy"
};