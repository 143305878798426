import React, {FunctionComponent, memo, useCallback} from 'react';
import {Opt} from 'ts-opt';
import {classNames} from 'favorlogic-utils';

import {PickupLine} from 'types/model/pickupLine/PickupLine';
import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';

import {PickupLineTable} from '../PickupLineTable';
import {PickupLineActions} from './PickupLineActions';

import styles from './styles.sass';

interface Props {
    pickupLines: Opt<PickupLine[]>;
    toggleAddCustomerModalVisibility(pickupLineId: number): void;
    deletePickupLine(pickupLineId: number): void;
    toggleEditPickupLineModalVisibility(): void;
    setPickupLineIdInEdit(pickupLineId: number): void;
    updatePickupLine(pickupLineId: number, data: WritePickupLine): void;
}

const PickupLinesTablesBase: FunctionComponent<Props> = props => {
    const {
        pickupLines,
        toggleAddCustomerModalVisibility,
        deletePickupLine,
        toggleEditPickupLineModalVisibility,
        setPickupLineIdInEdit,
        updatePickupLine,
    } = props;

    const onEdit = useCallback((pickupLineId: number) => {
        toggleEditPickupLineModalVisibility();
        setPickupLineIdInEdit(pickupLineId);
    }, [toggleEditPickupLineModalVisibility, setPickupLineIdInEdit]);

    return (
        <div className={classNames(styles.cards, 'card-columns')}>
            {pickupLines.orElse([]).map(x =>
                <div
                    key={x.id}
                    className="card p-2"
                >
                    <div className="row">
                        <div className="col-8">
                            <h4 style={{textShadow: `1px 1px 5px ${x.color}`}}>{x.name}</h4>
                        </div>
                        <div className="col-4">
                            <PickupLineActions
                                pickupLine={x}
                                onDelete={deletePickupLine}
                                onEdit={onEdit}
                            />
                        </div>

                    </div>
                    <PickupLineTable
                        pickupLine={x}
                        toggleAddCustomerModalVisibility={toggleAddCustomerModalVisibility}
                        updatePickupLine={updatePickupLine}
                    />
                </div>
            )}
        </div>
    );
};

export const PickupLinesTables = memo(PickupLinesTablesBase);
