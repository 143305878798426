import {Opt, none, opt} from 'ts-opt';

import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {UserDetails} from 'types/model/users/UserDetails';
import {Ares} from 'types/model/ares/Ares';
import {UserAction} from './actions';
import {CustomersPage} from 'types/model/customers/CustomerPage';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {Customer} from 'types/model/customers/Customer';
import {UserDetailsForCustomer} from 'types/model/users/UserDetailsForCustomer';

export interface UserState {
    currentUser: Opt<UserDetails>;
    customerOfCurrentUser: Opt<CustomerDetails>;
    loadingUser: boolean;
    customersPage: Opt<CustomersPage>;
    laborers: Opt<UserDetails[]>;
    customerForLaborer: Opt<CustomerDetailsForLaborer>;
    editingPrice: boolean;
    customerMilkRooms: Opt<MilkRoom[]>;
    customerList: Opt<Customer[]>;
    ares: Opt<Ares>;
    isAresLoading: boolean;
    users: UserDetails[] | null;
    user: UserDetailsForCustomer | null;
}

export const initialUserState: UserState = {
    currentUser: none,
    customerOfCurrentUser: none,
    loadingUser: false,
    customersPage: none,
    laborers: none,
    customerForLaborer: none,
    editingPrice: false,
    customerMilkRooms: none,
    customerList: none,
    ares: none,
    isAresLoading: false,
    users: null,
    user: null,
};

const reducer = (state: UserState | undefined = initialUserState, action: UserAction): UserState => {
    switch (action.type) {
        case 'user/LOAD_CURRENT': {
            return {...state, loadingUser: true};
        }

        case 'user/LOAD_CURRENT_SUCCESS': {
            return {
                ...state,
                currentUser: action.payload.user,
                customerOfCurrentUser: action.payload.customer,
                loadingUser: false,
            };
        }

        case 'user/LOGOUT': {
            return {...state, currentUser: none};
        }

        case 'user/LOAD_CUSTOMERS_SUCCESS': {
            return {...state, customersPage: opt(action.payload)};
        }

        case 'user/LOAD_CUSTOMER_SUCCESS': {
            return {...state, customerForLaborer: opt(action.payload)};
        }

        case 'user/LOAD_CUSTOMER_MILK_ROOMS_SUCCESS': {
            return {...state, customerMilkRooms: opt(action.payload)};
        }

        case 'user/RESET_CUSTOMER_ROOMS': {
            return {...state, customerMilkRooms: none};
        }

        case 'user/LOAD_LABORERS_SUCCESS': {
            return {...state, laborers: opt(action.payload)};
        }

        case 'user/LOAD_CUSTOMER_LIST_SUCCESS': {
            return {...state, customerList: opt(action.payload)};
        }

        case 'user/LOAD_ARES_SUCCESS': {
            return {...state, ares: opt(action.payload)};
        }

        case 'user/IS_ARES_LOADING': {
            return {...state, isAresLoading: action.payload.value};
        }

        case 'user/RESET_ARES_VALUES': {
            return {...state, ares: none};
        }

        case 'user/LOAD_USERS_SUCCESS': {
            const {users} = action.payload;

            return {...state, users};
        }

        case 'user/LOAD_USER_SUCCESS': {
            const {user} = action.payload;

            return {...state, user};
        }

        default:
            return state;
    }
};

export default reducer;
