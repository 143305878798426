import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {UpdateSamplesSelectionAction} from '../actions';
import {
    handleErrorResponse,
    handleSuccess,
    getFormValues,
    updateSamplesRequestData,
} from './updateSamplesSagaUtils';
import Api from '../api';

function* execute(action: UpdateSamplesSelectionAction): SagaIterator {
    const formValues = yield* call(getFormValues);
    const response = yield* call(
        Api.updateSamplesSelection,
        action.payload,
        updateSamplesRequestData(formValues),
    );

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateSamplesSelectionSaga(): SagaIterator {
    yield takeLatestF('sample/UPDATE_SAMPLES_SELECTION', execute);
}
