import {isString} from 'lodash/fp';

import {Config} from 'app/Config';
import {Address} from 'types/model/common/Address';
import {CustomerInvoiceDetails} from 'types/model/common/CustomerInvoiceDetails';
import {Customer} from 'types/model/customers/Customer';
import {SupplierInvoiceDetails} from 'types/model/common/SupplierInvoiceDetails';
import {isIcoValid} from 'types/generic/IcoSchema';
import {Validator} from './Validator';

export const validationRegularExpressions = Object.freeze({
    email: /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/,
    ico: /^\d{8}$/,
    dic: /^(CZ|cz|SK|sk)\d{8,10}$/,
    barcode: /^\d{1,10}$/,
    veterinaryId: /^CZ\d{8}$|^(?!CZ)[\w-]{1,10}$/,
    temperature: /^(0|[1-9][0-9]{0,1})([.,]\d+)?$/,
    dilution: /^[0-9]$/,
    milkRoomCode: /^\d{4,8}$/,
    customerCode: /^\d{1,6}$/,
});

export interface InvoiceDetailsArgs {
    nonEmptyIcoWhenDicIsFilled?: boolean;
    emailIsOptional?: boolean;
    icoRequired?: boolean;
}

export const milkRoomCodeErrorMessage = 'Kód mléčnice musí být 4 až 8 místné číslo.';

export class DairyValidator<Values> extends Validator<Values> {
    static genIcoError(label: string): string {
        return `${label} není validní (nesedí kontrolní číslice).`;
    }

    email(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.email, label);
    }

    ico(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.ico, label);
        if (!this.errors[fieldName]) {
            const value = this.values[fieldName];
            if (value) {
                if (!isString(value)) { throw new Error('Expected ICO to be a string.'); }
                if (!isIcoValid(value)) {
                    this.setErrorForField(fieldName, DairyValidator.genIcoError(label));
                }
            }
        }
    }

    dic(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.dic, label);
    }

    veterinaryId(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.veterinaryId, label);
    }

    barcode(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.barcode, label);
    }

    barcodeCustomError(fieldName: keyof Values, error: string): void {
        this.patternCustomError(fieldName, validationRegularExpressions.barcode, error);
    }

    temperature(fieldName: keyof Values, label: string): void {
        const error = `${label} musí být v rozmezí 0 až 99 °C`;

        this.patternCustomError(fieldName, validationRegularExpressions.temperature, error);
    }

    dilution(fieldName: keyof Values, label: string): void {
        this.patternCustomError(
            fieldName,
            validationRegularExpressions.dilution,
            `${label} musí být číslo v rozmezí 0 až 9.`,
        );
    }

    milkRoomCode(fieldName: keyof Values): void {
        this.patternCustomError(fieldName, validationRegularExpressions.milkRoomCode, milkRoomCodeErrorMessage);
    }

    customerCode(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.customerCode, label);
    }

    // TODO: not type safe
    address<K extends keyof Values>(fieldName: K): void {
        const v = new DairyValidator(this.values[fieldName] as unknown as Address);
        v.nonEmpty('street', 'Ulice');
        v.nonEmpty('city', 'Město');
        v.nonEmpty('zip', 'Směrovací číslo');
        this.setErrorForField(fieldName, v.generateErrorsObject());
    }

    // TODO: not type safe
    invoiceDetails(fieldName: keyof Values, args: InvoiceDetailsArgs): void {
        const cfg = Config.forms;
        const v = new DairyValidator(this.values[fieldName] as unknown as CustomerInvoiceDetails);

        if (!args.emailIsOptional) {
            v.nonEmpty('email', 'E-mail');
        }
        v.email('email', 'E-mail');

        v.minStringLength('ico', cfg.icoMinLen, 'IČO');
        v.maxStringLength('ico', cfg.icoMaxLen, 'IČO');
        v.ico('ico', 'IČO');

        v.minStringLength('dic', cfg.dicMinLen, 'DIČ');
        v.maxStringLength('dic', cfg.dicMaxLen, 'DIČ');
        v.dic('dic', 'DIČ');

        if (args.nonEmptyIcoWhenDicIsFilled && !v.checkIsEmpty('dic')) {
            v.nonEmpty('ico', 'IČO');
        }

        if (args.icoRequired) {
            v.nonEmpty('ico', 'IČO');
        }

        this.setErrorForField(fieldName, v.generateErrorsObject());
    }

    // TODO: not type safe
    supplierInvoiceDetails(fieldName: keyof Values, args?: InvoiceDetailsArgs): void {
        this.invoiceDetails(fieldName, {emailIsOptional: true, ...args});
        const v = new DairyValidator(this.values[fieldName] as unknown as SupplierInvoiceDetails);
        this.setErrorForField(fieldName, {
            ...this.errors[fieldName] as {} | undefined || {},
            ...v.generateErrorsObject(),
        });
    }

    // TODO: not type safe
    orderCustomerDetails(fieldName: keyof Values, args?: InvoiceDetailsArgs): void {
        const value = this.values[fieldName] as unknown as Customer;
        if (!value) { return; }
        const v = new DairyValidator(value);
        v.invoiceDetails('invoiceDetails', args || {});
        v.address('address');
        v.nonEmpty('firstName', 'Jméno');
        v.nonEmpty('lastName', 'Příjmení');
        v.nonEmpty('customerName', 'Jméno zákazníka');
        this.setErrorForField(fieldName, {
            ...this.errors[fieldName] as {} | undefined || {},
            ...v.generateErrorsObject(),
        });
    }
}
