import {History, Location} from 'history';
import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Prompt, withRouter} from 'react-router';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {canLeavePage, setNextLocation} from 'layout/actions';

interface InnerProps {
    history: History;
    getCanLeavePage: boolean;
    nextLocation: string;

    handleShowConfirmDialog(message?: string): void;

    handleLeavePage(path: string): void;

    handleNextLocation(path: string): void;

    handleCanLeavePage(can: boolean): void;

    handleAfterLeavePage(): void;
}

interface OuterProps {
    canLeave: boolean;
    except?: string[];
    message?: string;

    afterLeave?(): void;
}

type Props = InnerProps & OuterProps;

class LeavePageConfirm extends Component<Props> {
    componentDidMount () {
        const {handleCanLeavePage, canLeave} = this.props;
        handleCanLeavePage(canLeave);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            getCanLeavePage,
            handleLeavePage,
            canLeave,
            handleCanLeavePage,
            history,
            handleAfterLeavePage,
            nextLocation,
            handleNextLocation,
        } = this.props;
        if (prevProps.canLeave !== canLeave) {
            handleCanLeavePage(canLeave);
        }
        if (nextLocation && nextLocation !== history.location.pathname && getCanLeavePage) {
            handleAfterLeavePage();
            handleLeavePage(nextLocation);
            handleNextLocation('');
        }
    }

    render(): ReactNode {
        const {getCanLeavePage} = this.props;

        return <Prompt
            when={!getCanLeavePage}
            message={(location: Location) => !getCanLeavePage && this.prompt(location.pathname)}
        />;
    }

    private prompt = (path: string): boolean => {
        const {handleShowConfirmDialog, handleNextLocation, message} = this.props;

        if (this.isException(path)) {
            return true;
        }

        handleShowConfirmDialog(message);
        handleNextLocation(path);
        return false;
    }

    private isException(path: string): boolean {
        const {except} = this.props;

        if (!except) {
            return false;
        }

        return except.some((exceptPath) => {
            const regexp = new RegExp(`^${exceptPath}$`);

            return regexp.test(path);
        });
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    getCanLeavePage: state.layout.canLeavePage,
    nextLocation: state.layout.nextLocation,
});

const mapDispatchToProps = (dispatch: Dispatch, props: Props): Partial<Props> => ({
    handleShowConfirmDialog: (message?: string) => dispatch(showConfirmDialog({
        title: 'Opustit stránku',
        text: message || 'Opravdu chcete opustit stránku?',
        confirmAction: canLeavePage(true),
    })),

    handleCanLeavePage: (canLeave: boolean) => dispatch(canLeavePage(canLeave)),

    handleLeavePage: (nextPath: string) => dispatch(routerActions.push(nextPath)),

    handleNextLocation: (nextLocation: string) => dispatch(setNextLocation(nextLocation)),

    handleAfterLeavePage: () => {
        const {afterLeave} = props;
        if (afterLeave) { afterLeave(); }
    },
});

export default flow([
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
])(LeavePageConfirm) as ComponentClass<OuterProps>;

