import {call, put, select} from 'typed-redux-saga';
import {SagaIterator} from 'redux-saga';
import {showError, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {Ares} from 'types/model/ares/Ares';
import {formValuesF, stopSubmitUnsafe} from 'utils/formHelpers';
import {prefillOrderCustomerForm, FillOrderCustomerFormFromAresAction, isAresLoading} from '../actions';
import {OrderCustomerFormValues} from '../types/OrderCustomerFormValues';
import {CustomerInvoiceDetails} from 'types/model/common/CustomerInvoiceDetails';
import {Address} from 'types/model/common/Address';
import {StoreState} from 'app/types/StoreState';
import Api from 'user/api';
import {Customer} from 'types/model/customers/Customer';

const errorTitle = 'Načtení zákazníka z ARES';
const formName = 'orderCustomer';

export function getOrderCustomerDetails(ares: Ares, customerDetails: Customer): Customer {
    const address: Address = {
        street: ares.street || '',
        city: ares.city || '',
        zip: ares.zip || '',
    };
    const invoiceDetails: CustomerInvoiceDetails = {
        ...customerDetails.invoiceDetails,
        dic: ares.dic || '',
    };

    return {
        ...customerDetails,
        invoiceDetails,
        address,
        billingAddress: {street: '', city: '', zip: ''},
        customerName: ares.companyName || '',
    };
}

function* getCurrentOrderCustomerFormValues(): SagaIterator<OrderCustomerFormValues> {
    return yield* select((state: StoreState) => formValuesF(formName)(state).orCrash('Missing form values'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    if (!response.isUnauthorized) {
        yield* call(handleResponseError, response, errorTitle);
    }
}

function* handleSuccessResponse({data}: SuccessResponse<Ares | null>): SagaIterator {
    const formValues = yield* call(getCurrentOrderCustomerFormValues);

    if (data) {
        yield* put(prefillOrderCustomerForm({
            ...formValues,
            customerDetails: getOrderCustomerDetails(data, formValues.customerDetails),
            hasSameBillingAddress: true,
            hasNoIco: false,
            prefillOrderCustomer: null,
            prefilled: true,
        }));
    } else {
        yield* put(showError(errorTitle, 'Žádné výsledky nebyly nalezeny.'));
        yield* put(stopSubmitUnsafe(formName, {
            customerDetails: {
                invoiceDetails: {
                    ico: 'IČO nebylo nalezeno v ARES.',
                },
            },
        }));
    }
}

function* execute(action: FillOrderCustomerFormFromAresAction): SagaIterator {
    yield* put(isAresLoading(true));

    const response = yield* call(Api.getAres, action.payload);

    yield* response.isSuccess  ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
    yield* put(isAresLoading(false));
}

export function* fillOrderCustomerFormFromAresSaga(): SagaIterator {
    yield takeLatestF('order/FILL_ORDER_CUSTOMER_FORM_FROM_ARES', execute);
}
