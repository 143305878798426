import {flow, filter, sortBy, map} from 'lodash/fp';
import {opt} from 'ts-opt';

import {DataItem} from 'types/model/dataItems/DataItem';
import {SelectOptions} from 'forms/components/BasicSelect';

const sortKeyForDataItemsSelect: Readonly<Record<string, number>> = {
    'IL': 0,
    'Coli': 1,
    'PTM': 2,
    'TRM': 3,
    'CPM-K': 4,
    'CPM': 5,
    'Tuk': 6,
    'Bílkovina': 7,
    'Laktóza': 8,
    'TPS': 9,
    'Kasein': 10,
    'Močovina': 11,
    'VMK': 12,
    'BM': 13,
    'SB': 14,
};

export const buildDataItemOptions = (
    dataItems: DataItem[] | null,
    requestedDataItemIds: number[],
): SelectOptions<DataItem> =>
    flow(
        filter((x: DataItem) => requestedDataItemIds.includes(x.id)),
        sortBy(x => opt(sortKeyForDataItemsSelect[x.name]).orElse(Infinity)),
        map(x => ({label: x.name, value: x})),
    )(dataItems || []);
