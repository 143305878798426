import * as t from 'io-ts';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    orderId: t.number,
    sampleId: t.number,
    barcode: BarcodeSchema,
    customerName: t.string,
});

export const OrderSearchSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OrderSearch extends t.TypeOf<typeof OrderSearchSchema> {}
