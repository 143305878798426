import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {showSuccess, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {DeleteProtocolAction, loadProtocols} from '../actions';
import Api from '../api';

const title = 'Smazání protokolu';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
}

function* handleSuccess(orderId: number): SagaIterator {
    yield* put(showSuccess(title, 'Protokol smazán.'));
    yield* put(loadProtocols(orderId));
}

function* execute({payload: {protocolId, orderId}}: DeleteProtocolAction): SagaIterator {
    const response = yield* call(Api.deleteProtocol, protocolId);

    yield* response.isSuccess
        ? call(handleSuccess, orderId)
        : call(handleErrorResponse, response);
}

export function* deleteProtocolSaga(): SagaIterator {
    yield takeLatestF('order/DELETE_PROTOCOL', execute);
}
