import {StringPatternSchema} from 'favorlogic-utils';

// Color of a pickup line
// Example: "#ffffff"

export const hexColorRegexGen = (): RegExp => /^#[a-f0-9]{6}$/;

export const HexColorSchema = StringPatternSchema<HexColor>(hexColorRegexGen());

export type HexColor = string;
