import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {SetSamplesSubsidyByFilterAction} from '../actions';
import Api from '../api';
import {handleSuccess, handleErrorResponse} from './utils/setSamplesSubsidySagaUtils';

function* execute({payload: {filter, value}}: SetSamplesSubsidyByFilterAction): SagaIterator {
    const response = yield* call(Api.setSamplesSubsidyByFilter, filter, value);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* setSamplesSubsidyByFilterSaga(): SagaIterator {
    yield takeLatestF('measurement/SET_SAMPLES_SUBSIDY_BY_FILTER', execute);
}
