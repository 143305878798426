import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF, SuccessResponse, ErrorResponse} from 'favorlogic-utils';

import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {handleResponseError} from 'utils/handleResponseError';

import {
    LoadOperatingProcedureAction,
    loadOperatingProcedureError,
    loadOperatingProcedureSuccess,
    prefillOperatingProcedureForm,
} from '../actions';
import Api from '../api';
import {OperatingProcedureFormValues} from '../types/OperatingProcedureFormValues';

const title = 'Načtení operačního postupu';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, title);
    yield* put(loadOperatingProcedureError(error));
}

export function prepareOperatingProcedureFormValues(
    operatingProcedure: OperatingProcedure
): OperatingProcedureFormValues {
    return {
        name: operatingProcedure.name,
        description: operatingProcedure.description,
    };
}

function* handleSuccessResponse({data: operatingProcedure}: SuccessResponse<OperatingProcedure>): SagaIterator {
    const formValues = prepareOperatingProcedureFormValues(operatingProcedure);

    yield* put(prefillOperatingProcedureForm(formValues));
    yield* put(loadOperatingProcedureSuccess(operatingProcedure));
}

function* execute(action: LoadOperatingProcedureAction): SagaIterator {
    const response = yield* call(Api.getOperatingProcedure, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadOperatingProcedureSaga(): SagaIterator {
    yield takeLatestF('operatingProcedure/LOAD_OPERATING_PROCEDURE', execute);
}
