import * as t from 'io-ts';

export const DAIRY = 'DAIRY';
export const DAIRY_EMPLOYEE = 'DAIRY_EMPLOYEE';
export const BREEDER = 'BREEDER';
export const ADMIN = 'ADMIN';
export const LABORER = 'LABORER';
export const PICKUP_LINE_ADMIN = 'PICKUP_LINE_ADMIN';

export const CustomerRoleSchema = t.keyof({
    DAIRY: null,
    DAIRY_EMPLOYEE: null,
    BREEDER: null,
});

export const LaborerRoleSchema = t.keyof({
    ADMIN: null,
    LABORER: null,
    PICKUP_LINE_ADMIN: null,
});

export const RoleSchema = t.keyof({
    ADMIN: null,
    LABORER: null,
    DAIRY: null,
    DAIRY_EMPLOYEE: null,
    BREEDER: null,
    PICKUP_LINE_ADMIN: null,
});

export type Role = t.TypeOf<typeof RoleSchema>;
