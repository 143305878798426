import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {Opt, opt} from 'ts-opt';
import {isNumber} from 'lodash/fp';

import {SelectedValue, SelectOptions} from 'forms/components/BasicSelect';
import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {validate} from './validations';
import {ExportMeasurementsFillingFormValues} from 'order/types/ExportMeasurementsFillingFormValues';
import {DataItem} from 'types/model/dataItems/DataItem';
import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';

const SELECTABLE_DATA_ITEM_IDS: Readonly<number[]> = [8, 11];

interface OwnProps {
    dataItems: DataItem[];
    operatingProcedures: OperatingProcedure[];
    selectedDataItem: DataItem | null;
}

export type Props = PropsForForm<ExportMeasurementsFillingFormValues, OwnProps>;

export const createDataItemOptions = (dataItems: DataItem[]): SelectOptions<number> =>
    opt(dataItems)
        .map(dataItems => dataItems
            .filter(x => SELECTABLE_DATA_ITEM_IDS.includes(x.id))
            .map(x => ({value: x.id, label: x.name}))
        )
        .orElse([]);

class ExportMeasurementsFillingForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            dataItems,
            selectedDataItem,
        } = this.props;

        const operatingProceduresOptions: SelectOptions<number> = this.getOperatingProcedures()
            .map(x => ({value: x.id, label: x.name}));

        return (
            <Form
                onSubmit={handleSubmit}
            >
                <h2>Stáhnout podklady</h2>

                <Fields.Select<number>
                    className="mt-2"
                    name="dataItemId"
                    label="Datová položka*"
                    options={createDataItemOptions(dataItems)}
                    onFieldChange={this.onDataItemChange}
                />

                <Fields.Select<number>
                    className="mt-2"
                    name="operatingProcedureId"
                    label="Operační postup*"
                    options={operatingProceduresOptions}
                    disabled={selectedDataItem === null}
                />

                <Buttons.Button
                    className="w-100 mt-4"
                    importance="primary"
                    disabled={!valid || submitting}
                    type="submit"
                >
                    Stáhnout
                </Buttons.Button>
            </Form>
        );
    }

    private getOperatingProcedures(): OperatingProcedure[] {
        const {operatingProcedures, selectedDataItem} = this.props;
        const procedureIds = opt(selectedDataItem)
            .map(dataItem => dataItem.procedures.map(x => x.procedureId))
            .orElse([]);

        return operatingProcedures.filter(procedure => procedureIds.includes(procedure.id));
    }

    private getDefaultProcedureId(dataItemId: number): number | undefined {
        const {dataItems} = this.props;
        const dataItem = dataItems.find(x => x.id === dataItemId);
        const procedures = dataItem ? dataItem.procedures : [];
        const defaultProcedure = procedures.find(x => x.isDefault);

        return opt(defaultProcedure)
            .map(x => x.procedureId)
            .orUndef();
    }

    private readonly onDataItemChange = (value: Opt<SelectedValue<number>>): void => {
        const {change} = this.props;
        value.onSome(dataItemId => {
            if (isNumber(dataItemId)) {
                const defaultProcedure = this.getDefaultProcedureId(dataItemId);
                if (isNumber(defaultProcedure)) {
                    change('operatingProcedureId', defaultProcedure);
                }
            }
        });
    };
}

export default Forms.withForm<ExportMeasurementsFillingFormValues, OwnProps, Props>(ExportMeasurementsFillingForm, {
    form: 'exportMeasurementsFilling',
    validate,
    enableReinitialize: true,
    initialValues: {
        dataItemId: null,
        operatingProcedureId: null,
    },
});
