import {pickBy} from 'lodash/fp';
import {toggleItem} from 'favorlogic-utils';

import {UpdateSamplesBy} from 'sample/screens/List';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {SampleDetailsPage} from 'types/model/samples/SampleDetailsPage';
import {SampleAction} from './actions';
import {SamplesFilterParams, SamplesParams} from './types/SampleQueryParams';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';

export interface SampleState {
    samples: SampleDetailsPage | null;
    orderSamples: OrderSampleView[] | null;
    samplesParams: SamplesParams;
    samplesFilterParams: SamplesFilterParams;
    sampleDetails: SampleDetails | null;
    selectedSamples: number[];
    updateSamplesBy: UpdateSamplesBy | null;
}

export const initialSampleState: SampleState = {
    samples: null,
    orderSamples: null,
    samplesParams: {},
    samplesFilterParams: {},
    sampleDetails: null,
    selectedSamples: [],
    updateSamplesBy: null,
};

const reducer = (state: SampleState | undefined = initialSampleState, action: SampleAction): SampleState => {
    switch (action.type) {
        case 'sample/LOAD_SAMPLES_SUCCESS': {
            return {...state, samples: action.payload};
        }

        case 'sample/LOAD_SAMPLE_DETAILS_SUCCESS': {
            return {...state, sampleDetails: action.payload};
        }

        case 'sample/SET_SAMPLES_PARAMS': {
            const pageParams = ['sort', 'page', 'size'];
            return {
                ...state,
                samplesParams: action.payload,
                samplesFilterParams: pickBy((x, key) => !pageParams.includes(key), action.payload),
            };
        }

        case 'sample/TOGGLE_SAMPLE_SELECT': {
            const {selectedSamples} = state;
            const {payload: sampleId} = action;

            return {...state, selectedSamples: toggleItem(sampleId)(selectedSamples)};
        }

        case 'sample/SET_UPDATE_SAMPLES_BY': {
            return {...state, updateSamplesBy: action.payload};
        }

        case 'sample/LOAD_SAMPLES_BY_ORDER_SUCCESS': {
            return {...state, orderSamples: action.payload};
        }

        case 'sample/RESET_SAMPLE_BY_ORDER': {
            return {...state, orderSamples: null};
        }

        default:
            return state;
    }
};

export default reducer;
