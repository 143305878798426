import React, {Component, ReactNode} from 'react';
import {classNames} from 'favorlogic-utils';

import Loader from '../Loader';

import styles from './styles.sass';

interface Props {
    children: ReactNode;
    showLoader: boolean;
    inModal?: boolean;
}

class LoaderWrapper extends Component<Props> {
    render(): ReactNode {
        const {children, showLoader, inModal} = this.props;

        return (
            <div className={styles.wrapper}>
                <Loader
                    show={showLoader}
                    inModal={inModal}
                />
                <div className={classNames(showLoader && styles.blur)}>
                    {children}
                </div>
            </div>
        );
    }
}

export default LoaderWrapper;
