import React, {FunctionComponent, memo, useCallback} from 'react';

import {RowActionButton} from 'buttons/components';
import {Customer} from 'types/model/pickupLine/Customer';

interface Props {
    customer: Customer;
    disabled?: boolean;
    onCustomerDelete(customerId: number): void;
}

const RowActionsBase: FunctionComponent<Props> = props => {
    const {customer, disabled, onCustomerDelete} = props;

    const handleDelete = useCallback(() => {
        onCustomerDelete(customer.id);
    }, [customer, onCustomerDelete]);

    return (
        <RowActionButton
            type="DELETE"
            title={disabled ? 'Nelze odebrat zákazníka' : 'Odebrat zákazníka'}
            onClick={handleDelete}
            disabled={disabled}
        />
    );
};

export const RowActions = memo(RowActionsBase);
