import React from 'react';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dispatch} from 'redux';

import {login} from '../actions';
import LoginForm from '../components/LoginForm';

import styles from './styles.sass';
import logoMain from 'layout/components/MainMenu/img/logo-cmsch.png';

interface Props {
    handleLogin(): void;
}

const Login = (props: Props) => {
    const {handleLogin} = props;
    const loginWrapperClasses = `d-flex align-items-center justify-content-center w-100 ${styles.loginWrapper}`;
    const loginWrapperInnerClasses = `d-flex align-items-center flex-column flex-wrap ${styles.loginWrapperInner}`;
    const loginClasses = `mt-3 d-flex justify-content-cente flex-column flex-wrap ${styles.login}`;

    return (
        <div className={loginWrapperClasses}>
            <div>
                <div className={loginWrapperInnerClasses}>
                    <div className={styles.logoMain}>
                        <img
                            src={logoMain}
                            alt="Logo čmsch"
                            title="Českomoravská společnost chovatelů"
                        />
                    </div>
                    <div className={loginClasses}>
                        <div>
                            <LoginForm
                                onSubmit={handleLogin}
                            />
                        </div>
                        <div className="text-right">
                            <Link to="/register">Registrace nového účtu</Link>
                        </div>
                        <div className="text-right mt-1">
                            <Link to="/reset-password-email">Zapomenuté heslo</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLogin: () => dispatch(login()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Login) as typeof Login;
