import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {UpdateUserFormValues} from '../../types/UpdateUserFormValues';

export const validate = (values: UpdateUserFormValues): Errors<UpdateUserFormValues> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('firstName', 'Jméno');
    validator.nonEmpty('lastName', 'Příjmení');


    return validator.generateErrorsObject();
};
