import {StringPatternSchema} from 'favorlogic-utils';

// Date in year-month format
// Example: "2021-01"

export const yearMonthRegexGen = (): RegExp => /^\d{4}-\d{2}$/;

export const YearMonthSchema = StringPatternSchema<YearMonth>(yearMonthRegexGen());

export type YearMonth = string;
