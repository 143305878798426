import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {SupplierDetails} from 'types/model/suppliers/SupplierDetails';
import {handleResponseError} from 'utils/handleResponseError';

import {
    LoadSupplierAction,
    loadSupplierError,
    loadSupplierSuccess,
    prefillUpdateSupplierForm,
} from '../actions';
import {UpdateSupplierFormValues} from '../types/UpdateSupplierFormValues';
import Api from '../api';

export function prepareUpdateFormPrefillObj(supplier: SupplierDetails): UpdateSupplierFormValues {
    return {
        invoiceDetails: {...supplier.invoiceDetails},
        address: {...supplier.address},
        name: supplier.name,
        note: supplier.note,
        hasNoIco: !supplier.invoiceDetails.ico,
    };
}

function* execute(action: LoadSupplierAction): SagaIterator {
    const errorTitle = 'Načtení dodavatele';
    const response = yield* call(Api.getSupplier, action.payload);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;
        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadSupplierError(error));
    }

    const supplier = response.data;
    yield* put(loadSupplierSuccess(supplier));
    yield* put(prefillUpdateSupplierForm(prepareUpdateFormPrefillObj(supplier)));
}

export function* loadSupplierSaga(): SagaIterator {
    yield takeLatestF('supplier/LOAD_SUPPLIER', execute);
}
