import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {todayDate} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {GenerateProtocolFormValues} from 'order/types/GenerateProtocolFormValues';
import {validate} from './validations';

export interface OwnProps {
    isSubmitting: boolean;
    processedFrom: Date | undefined;
}

type Props = PropsForForm<GenerateProtocolFormValues, OwnProps>;

class GenerateProtocolForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            isSubmitting,
            processedFrom,
        } = this.props;
        return (
            <Layout.Panel caption="">
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || isSubmitting} />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.DateTime
                                className="mt-2"
                                name="processedFrom"
                                mode="DATE"
                                label="Zpracováváno od"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.DateTime
                                className="mt-2"
                                name="processedTo"
                                mode="DATE"
                                minDate={processedFrom}
                                label="Zpracováváno do"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end mt-4">
                                <Buttons.Button
                                    className="w-100"
                                    importance="primary"
                                    disabled={!valid || submitting}
                                    type="submit"
                                >
                                    Vytvořit
                                </Buttons.Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<GenerateProtocolFormValues, OwnProps, Props>(GenerateProtocolForm, {
    form: 'generateProtocol',
    validate,
    initialValues: {
        processedFrom: todayDate(),
        processedTo: null,
    },
});
