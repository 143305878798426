import {isString} from 'lodash/fp';
import {StringPatternSchema} from 'favorlogic-utils';

import {validationRegularExpressions} from 'utils/DairyValidator';

export const isEmailValid = (value: string): boolean => {
    if (!isString(value)) { return false; }
    return validationRegularExpressions.email.test(value);
};

export const EmailSchema = StringPatternSchema<Email>(validationRegularExpressions.email);

export type Email = string;
