import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {History} from 'history';
import {Opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import withUser from 'user/components/withUser';
import {isValidF, formValuesF, isFieldValidUnsafe, initializeF, resetF, destroyF, isDirtyF} from 'utils/formHelpers';
import LeavePage from 'layout/components/LeavePage';
import {
    fillOrderCustomerFormFromAres,
    resetAresFormValues,
    setLastCreatedSample,
} from 'order/actions';
import {Customer} from 'types/model/customers/Customer';
import {loadCustomerList} from 'user/actions';
import {CreateOrderFormValues} from '../types/CreateOrderFormValues';
import {OrderCustomerFormValues} from '../types/OrderCustomerFormValues';
import OrderCustomerForm from '../components/OrderCustomerForm';
import {LEAVE_CREATING_ORDER_MESSAGE} from '../constants';
import {initialCreateOrderFormValues} from '../components/CreateOrderForm';

interface OuterProps {}

interface InnerProps {
    history: History;
    isOrderCustomerFormValid: boolean;
    isOrderCustomerFormDirty: boolean;
    createOrderFormValues?: CreateOrderFormValues;
    orderCustomerFormValues: Opt<OrderCustomerFormValues>;
    customerList: Customer[] | null;
    isIcoValid: boolean;
    isAresLoading: boolean;

    fillCreateOrderForm(values: CreateOrderFormValues): void;
    fiilOrderCustomerForm(values: OrderCustomerFormValues): void;
    fillFormFromAres(ico: string): void;
    resetCreateOrderForm(): void;
    handleResetAresFormValues(): void;
    loadCustomerList(): void;
}

type Props = InnerProps & OuterProps;

class New extends Component<Props> {
    componentDidMount() {
        const {loadCustomerList} = this.props;

        loadCustomerList();
    }

    render(): ReactNode {
        const {
            isOrderCustomerFormValid,
            isOrderCustomerFormDirty,
            resetCreateOrderForm,
            fillFormFromAres,
            customerList,
            orderCustomerFormValues,
            isIcoValid,
            isAresLoading,
            handleResetAresFormValues,
        } = this.props;
        const prefilled = orderCustomerFormValues.map(x => x.prefilled).orUndef();
        const hasNoIco = orderCustomerFormValues.map(x => x.hasNoIco).orFalse();
        const hasSameBillingAddress = orderCustomerFormValues.map(x => x.hasSameBillingAddress).orFalse();
        const ico = orderCustomerFormValues.map(x => x.customerDetails.invoiceDetails.ico).orUndef();
        const onIcoSearch = () => {
            if (ico && isIcoValid) {
                fillFormFromAres(ico);
            }
        };

        const onIcoChange = () => {
            if (!ico) { handleResetAresFormValues(); }
        };

        const disabledByEmptyIco = !hasNoIco && !prefilled;

        return (
            <Layout.ItemPage
                title="Nová objednávka"
                backLabel="Objednávky"
            >
                <OrderCustomerForm
                    customers={customerList}
                    onIcoSearch={onIcoSearch}
                    hasSameBillingAddress={hasSameBillingAddress}
                    hasNoIco={hasNoIco}
                    isAresLoading={isAresLoading}
                    prefilled={prefilled}
                    handleIcoChange={onIcoChange}
                />

                <div className="w-100 d-flex justify-content-end mt-4">
                    <Buttons.RightIconButton
                        label="Údaje k objednávce"
                        disabled={!isOrderCustomerFormValid || disabledByEmptyIco || isAresLoading}
                        icon="NEXT"
                        onClick={() => this.fillCustomerDetailsForOrder()}
                    />
                </div>
                <LeavePage
                    canLeave={!isOrderCustomerFormDirty}
                    except={['/orders/new', '/orders/new/orderCustomer']}
                    afterLeave={resetCreateOrderForm}
                    message={LEAVE_CREATING_ORDER_MESSAGE}
                />
            </Layout.ItemPage>
        );
    }

    private fillCustomerDetailsForOrder() {
        const {createOrderFormValues, fillCreateOrderForm, orderCustomerFormValues, history} = this.props;
        const {customerDetails, hasSameBillingAddress} = orderCustomerFormValues
            .orCrash('missing form values');
        const createOrderData: CreateOrderFormValues = {
            ...initialCreateOrderFormValues,
            ...createOrderFormValues,
            customerDetails: {
                ...customerDetails,
                billingAddress: hasSameBillingAddress
                    ? customerDetails.address
                    : customerDetails.billingAddress,
            },
        };

        fillCreateOrderForm(createOrderData);
        history.push('/orders/new');
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    isOrderCustomerFormValid: isValidF('orderCustomer')(state),
    isOrderCustomerFormDirty: isDirtyF('orderCustomer')(state),
    createOrderFormValues: formValuesF('createOrder')(state).orUndef(),
    orderCustomerFormValues: formValuesF('orderCustomer')(state),
    customerList: state.user.customerList.orNull(),
    isIcoValid: isFieldValidUnsafe('orderCustomer', 'customerDetails.invoiceDetails.ico')(state),
    isAresLoading: state.order.isAresLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    fillCreateOrderForm: (values: CreateOrderFormValues) => dispatch(initializeF('createOrder', values)),
    fillFormFromAres: (ico: string) => dispatch(fillOrderCustomerFormFromAres(ico)),
    resetCreateOrderForm: () => {
        dispatch(destroyF('createOrder'));
        dispatch(resetF('orderCustomer'));
        dispatch(setLastCreatedSample(null));
    },
    loadCustomerList: () => dispatch(loadCustomerList()),
    handleResetAresFormValues: () => dispatch(resetAresFormValues()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(New) as ComponentClass<OuterProps>;
