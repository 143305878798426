import {ModalId} from 'app/types/ModalId';

export interface ToggleMenuAction {
    type: 'layout/TOGGLE_MENU';
}

export const toggleMenu = (): ToggleMenuAction => ({
    type: 'layout/TOGGLE_MENU',
});

export interface CanLeavePageAction {
    type: 'layout/CAN_LEAVE_PAGE';
    payload: boolean;
}
export const canLeavePage = (payload: boolean): CanLeavePageAction => ({
    type: 'layout/CAN_LEAVE_PAGE',
    payload,
});

export interface SetNextLocationAction {
    type: 'layout/SET_NEXT_LOCATION';
    payload: string;
}

export const setNextLocation = (payload: string): SetNextLocationAction => ({
    type: 'layout/SET_NEXT_LOCATION',
    payload,
});

export interface IsLoadingAction {
    type: 'layout/IS_LOADING';
    payload: boolean;
}
export const isLoading = (payload: boolean): IsLoadingAction => ({
    type: 'layout/IS_LOADING',
    payload,
});

export interface SetModalVisibilityAction {
    type: 'layout/SET_MODAL_VISIBILITY';
    payload: {
        id: ModalId,
        visibility: boolean,
    };
}
export const setModalVisibility = (id: ModalId, visibility: boolean): SetModalVisibilityAction => ({
    type: 'layout/SET_MODAL_VISIBILITY',
    payload: {id, visibility},
});

export interface ToggleModalVisibilityAction {
    type: 'layout/TOGGLE_MODAL_VISIBILITY';
    payload: {
        id: ModalId,
    };
}
export const toggleModalVisibility = (id: ModalId): ToggleModalVisibilityAction => ({
    type: 'layout/TOGGLE_MODAL_VISIBILITY',
    payload: {
        id,
    },
});

export const showModal = (id: ModalId): SetModalVisibilityAction => setModalVisibility(id, true);
export const hideModal = (id: ModalId): SetModalVisibilityAction => setModalVisibility(id, false);

export type LayoutAction =
    ToggleMenuAction | CanLeavePageAction | SetNextLocationAction | IsLoadingAction
    | SetModalVisibilityAction | ToggleModalVisibilityAction;
