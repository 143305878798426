import moment from 'moment';
import {beDateFormat} from 'favorlogic-utils';

import {MeasurementView} from 'types/model/measurements/MeasurementView';

import {CreateSampleFormValues} from '../types/CreateSampleFormValues';
import {UpdateSampleFormValues} from '../types/UpdateSampleFormValues';

type SampleData = CreateSampleFormValues | UpdateSampleFormValues | MeasurementView;

const canSetSubsidyByDate = (samplingDate: string) => {
    const todayDate = moment();
    const currentDayInMonth = todayDate.date();
    const canSetForSubsidyFromDate = todayDate.startOf('month');

    if (currentDayInMonth <= 20) {
        canSetForSubsidyFromDate.subtract(1, 'month');
    }

    return moment(samplingDate, beDateFormat)
        .isSameOrAfter(canSetForSubsidyFromDate, 'day');
};

export const canSetSampleForSubsidy = (sample: SampleData): boolean => {
    const {samplingDate, cistern, control} = sample;
    const notCisterNorControl = !cistern && !control;

    return canSetSubsidyByDate(samplingDate) && notCisterNorControl;
};
