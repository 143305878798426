import React, {ReactNode} from 'react';
import {Opt, opt} from 'ts-opt';

import {classNames} from 'favorlogic-utils';
import {Components as Buttons} from 'buttons';
import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import {InnerFormFieldProps} from 'forms/types/BaseFieldType';

import styles from 'forms/components/Field/styles.sass';

import checkSvg from 'forms/components/Field/check.svg';
import searchSvg from 'forms/components/Field/search.svg';
import spinnerSvg from 'forms/components/Field/spinner.svg';
import warningSvg from 'forms/components/Field/warning.svg';

export const genStyleNameAndFeedbackIcon = (
    asyncValidating: boolean,
    active: boolean,
    touched: boolean,
    error: unknown,
    fieldIsLoading: boolean,
    warning: unknown,
): [string, string | null] => {
    if (asyncValidating || fieldIsLoading) {
        return ['is-async-validating', spinnerSvg];
    } else if (!active && touched) {
        if (error || warning) {
            return ['is-invalid', warningSvg];
        } else {
            return ['is-valid', checkSvg];
        }
    }
    return ['', null];
};

const verifyChildComponent =
    <TValue extends unknown>(name: string) =>
        (child: ComponentForWrapping<InnerFormFieldProps<TValue>, TValue>) => {
            const tag = '[verifyChildComponent]';
            const typeName = child.constructor.name;
            try {
                child.getFormValue();
                // console.debug(`${tag} ok '${name}'`);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(`${tag} getActionValue failed for field '${name}' of type '${typeName}'.`);
            }
        };

export const withGenericFormFieldElements = <TValue extends unknown>(
    Child: typeof ComponentForWrapping,
    props: InnerFormFieldProps<TValue>,
    showFeedbackIcon = true,
): ReactNode => {
    const {
        meta: {
            touched,
            error,
            warning,
            asyncValidating,
            active,
            dirty,
            valid,
        },
        className,
        wrapperClassName,
        onAction,
        tooltip,
        disabled,
        fieldIsLoading,
        input: {name},
    } = props;
    const [styleName, feedbackIcon] =
        genStyleNameAndFeedbackIcon(asyncValidating, Boolean(active), touched, error, Boolean(fieldIsLoading), warning);

    const wrapperClassNames = classNames(
        'field-component', 'position-relative', 'd-flex', 'align-items-center', wrapperClassName,
        styles.field, styles[styleName],
    );

    const feedbackIconClassNames = classNames(className, 'ml-2', styles['feedback-icon']);
    const actionButtonClassNames = classNames(className, 'btn', 'btn-warning', 'ml-2', styles['action-button']);

    const ref = React.createRef<ComponentForWrapping<InnerFormFieldProps<TValue>, TValue>>();

    const getChildInstance = (): Opt<ComponentForWrapping<InnerFormFieldProps<TValue>, TValue>> => opt(ref.current);
    setTimeout(() => getChildInstance().onSome(verifyChildComponent(name)));

    const onActionClick = () => {
        const val = getChildInstance().orCrash('empty ref').getFormValue();
        if (!onAction) { throw new Error(`unexpected empty onAction`); }
        onAction(val);
    };

    return (
        <div
            className={wrapperClassNames}
            title={tooltip}
            data-field-name={name}
        >
            <Child
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
                ref={ref}
            />

            {onAction &&
             <Buttons.IconButton
                 icon={searchSvg}
                 className={actionButtonClassNames}
                 type="button"
                 onClick={onActionClick}
                 disabled={!dirty || !valid || asyncValidating || disabled}
             />}

            {showFeedbackIcon &&
             <span
                 className={feedbackIconClassNames}
                 /* eslint-disable-next-line react/no-danger */
                 dangerouslySetInnerHTML={{__html: feedbackIcon || ''}}
             />}

            <div
                className={`invalid-tooltip ${styles['invalid-tooltip']}`}
            >
                {touched && (error || warning)}
            </div>
        </div>
    );
};
