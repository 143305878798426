import {ComponentForWrapping} from 'forms/components/Field/ComponentForWrapping';
import React, {ChangeEvent, FocusEvent, ReactNode} from 'react';
import {opt} from 'ts-opt';

import {classNames} from 'favorlogic-utils';
import {InnerFormFieldProps, OuterFormFieldProps} from '../../types/BaseFieldType';
import {ChildProps} from '../../types/ChildProps';
import HelpText from '../HelpText';
import Label from '../Label';

type FieldValue = string;

type OwnProps = {
    rows?: number,
}

export type TextareaOuterProps = OwnProps & OuterFormFieldProps<FieldValue>;

export type TextareaInnerProps = OwnProps & InnerFormFieldProps<FieldValue> & ChildProps;

class Textarea extends ComponentForWrapping<TextareaInnerProps, FieldValue> {
    render(): ReactNode {
        const {
            className,
            label,
            helpText,
            input,
            rows,
            meta,
            input: {value},
        } = this.props;

        const {asyncValidating, submitting} = meta;

        const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
            this.handleChange(opt(evt.target.value));
        };

        const handleBlur = (_evt: FocusEvent<HTMLTextAreaElement>) => {
            this.handleBlur(opt(value));
        };

        const handleFocus = (_evt: FocusEvent<HTMLTextAreaElement>) => {
            this.handleFocus(opt(value));
        };

        const classes = classNames('form-group', 'w-100', className);

        return (
            <div className={classes}>
                <Label htmlFor={input.name}>
                    {label}
                </Label>

                <HelpText>
                    {helpText}
                </HelpText>

                <textarea
                    value={this.getFormValue().orElse('')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    rows={rows}
                    id={input.name}
                    className="form-control"
                    disabled={asyncValidating || submitting}
                />
            </div>
        );
    }
}

export default Textarea;
