exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-measurement-components-DeviceMeasurementsForm-___styles__wideTd___3Y363 {\n  min-width: 100px; }\n\n.src-modules-measurement-components-DeviceMeasurementsForm-___styles__narrowTd___1wlvf {\n  min-width: 100px;\n  max-width: 200px; }\n\n.src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleLabelDelimiter___jR6wV {\n  opacity: 0.5; }\n\n.src-modules-measurement-components-DeviceMeasurementsForm-___styles__invalidRowTooltip___Xy7rI {\n  display: block !important;\n  top: auto !important;\n  margin-left: -10px;\n  width: 165px;\n  border-radius: 0;\n  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2); }\n\n.table td.src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleColumn___2RNXh {\n  vertical-align: middle; }\n  .table td.src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleColumnNarrow___1y146 {\n    max-width: 150px; }\n\n.src-modules-measurement-components-DeviceMeasurementsForm-___styles__resultPanelLoading___GVVbD {\n  min-height: 150px; }\n", ""]);

// exports
exports.locals = {
	"wideTd": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__wideTd___3Y363",
	"narrowTd": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__narrowTd___1wlvf",
	"sampleLabelDelimiter": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleLabelDelimiter___jR6wV",
	"invalidRowTooltip": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__invalidRowTooltip___Xy7rI",
	"sampleColumn": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleColumn___2RNXh",
	"sampleColumnNarrow": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__sampleColumnNarrow___1y146",
	"resultPanelLoading": "src-modules-measurement-components-DeviceMeasurementsForm-___styles__resultPanelLoading___GVVbD"
};