import {Nullable} from 'favorlogic-utils';
import {isNaN} from 'lodash/fp';


export const getDaysCountInMonth = (planningDate: Nullable<string>): number => {
    if (!planningDate) {
        return 0;
    }
    const [year, month] = planningDate.split('-');
    const count = new Date(Number(year), Number(month), 0).getDate();

    return isNaN(count) ? 0 : count;
};
