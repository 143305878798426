import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {ApproveSamplesSelectionAction} from '../actions';
import {handleErrorResponse, handleSuccess} from './utils/approveSamplesSagaUtils';
import Api from '../api';

function* execute({payload: {sampleIds}}: ApproveSamplesSelectionAction): SagaIterator {
    const response = yield* call(Api.approveSamplesSelection, sampleIds);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* approveSamplesSelectionSaga(): SagaIterator {
    yield takeLatestF('measurement/APPROVE_SAMPLES_SELECTION', execute);
}
