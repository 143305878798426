import React, {Component, Fragment, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';

import {RegisterFormValues} from '../../types/RegisterFormValues';

import {asyncValidate, validate} from './validations';

export interface RegisterFormOwnProps {
    hasSameBillingAddress?: boolean;
    hasNoIco: boolean;
    isAresLoaded?: boolean;
    isAresLoading: boolean;
    isIcoValid?: boolean;

    onLoadAres(): void;
    onIcoChange(): void;
    onHasNoIcoChange(): void;
}

type Props = PropsForForm<RegisterFormValues, RegisterFormOwnProps>;

class RegisterForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            hasSameBillingAddress,
            hasNoIco,
            onLoadAres,
            isAresLoaded,
            isAresLoading,
            isIcoValid,
            onIcoChange,
            onHasNoIcoChange,
            autofillUnsafe,
        } = this.props;
        const showDetails = isAresLoaded || hasNoIco;
        const handleIcoBlur = () => {
            if (isIcoValid) { onLoadAres(); }
        };
        const handleHasNoIcoChange = () => {
            autofillUnsafe('invoiceDetails.ico', '');
            onHasNoIcoChange();
        };
        return (
            <Layout.Panel>
                <Form onSubmit={handleSubmit}>
                    <Layout.Loader show={submitting} />
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Přihlašovací údaje</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="username"
                                type="text"
                                label="Uživatelské jméno*"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="password"
                                type="password"
                                label="Heslo*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="checkPassword"
                                type="password"
                                label="Potvrzení hesla*"
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Uživatelské údaje</strong>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="firstName"
                                type="text"
                                label="Křestní jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="lastName"
                                type="text"
                                label="Příjmení*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.email"
                                type="text"
                                label="E-mail*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.phone"
                                type="text"
                                label="Telefonní číslo"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.ico"
                                type="text"
                                label="IČO"
                                onAction={onLoadAres}
                                onFieldChange={onIcoChange}
                                onFieldBlur={handleIcoBlur}
                                disabled={hasNoIco || isAresLoading}
                                fieldIsLoading={isAresLoading}
                            />
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasNoIco"
                                onFieldChange={handleHasNoIcoChange}
                                label="Nemám ičo"
                                disabled={isAresLoading}
                            />
                        </div>
                        {!isAresLoading && showDetails &&  <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="invoiceDetails.dic"
                                type="text"
                                label="DIČ"
                                disabled
                            />
                        </div>}
                    </div>

                    {!isAresLoading && showDetails && <Fragment>
                        <div className="row mt-4">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="name"
                                    type="text"
                                    label="Objednatel*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <strong className="mt-3 d-block">Adresa</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.street"
                                    type="text"
                                    label="Ulice*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.city"
                                    type="text"
                                    label="Město*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="address.zip"
                                    type="text"
                                    label="Směrovací číslo*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Checkbox
                                    className="mt-2"
                                    name="hasSameBillingAddress"
                                    label="Fakturační adresa je stejná"
                                />
                            </div>
                        </div>

                        {!hasSameBillingAddress && <Fragment>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <strong className="mt-3 d-block">Fakturační adresa</strong>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="billingAddress.street"
                                        type="text"
                                        label="Ulice*"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="billingAddress.city"
                                        type="text"
                                        label="Město*"
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="billingAddress.zip"
                                        type="text"
                                        label="Směrovací číslo*"
                                    />
                                </div>
                            </div>
                        </Fragment>}
                    </Fragment>}

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end mt-4">
                                <Buttons.Button
                                    className="w-100"
                                    importance="primary"
                                    disabled={!valid || isAresLoading || submitting}
                                    type="submit"
                                >
                                    Registrovat
                                </Buttons.Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<RegisterFormValues, RegisterFormOwnProps, Props>(RegisterForm, {
    form: 'register',
    validate,
    asyncValidate,
    asyncBlurFields: [
        'username',
        'name',
        'invoiceDetails.email',
        'invoiceDetails.ico',
    ],
    initialValues: {
        hasSameBillingAddress: true,
        hasNoIco: false,
        lastName: '',
        firstName: '',
        address: {city: '', street: '', zip: ''},
        billingAddress: {city: '', street: '', zip: ''},
        invoiceDetails: {email: '', tag: 'CustomerInvoiceDetails'},
        username: '',
        password: '',
        checkPassword: '',
        tag: 'CreateCustomer',
        showResultsOnWeb: false,
        showResultsToBreeders: false,
        name: '',
    },
});
