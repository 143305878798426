import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';

import Button, {Importance} from '../Button';

interface OuterProps {
    to: string;
    label: string | ReactNode;
    className?: string;
    importance?: Importance;
    disabled?: boolean;
    title?: string;
}

interface InnerProps {
    handleGoTo(url: string): void;
}

type Props = InnerProps & OuterProps;

class LinkButton extends Component<Props> {
    render(): ReactNode {
        const {
            to,
            handleGoTo,
            label,
            className,
            importance,
            disabled,
            title,
        } = this.props;

        return (
            <Button
                type="button"
                title={title}
                onClick={() => handleGoTo(to)}
                importance={importance || 'primary'}
                className={className}
                disabled={disabled}
            >
                {label}
            </Button>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleGoTo: (url: string) => dispatch(routerActions.push(url)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(LinkButton) as ComponentClass<OuterProps>;
