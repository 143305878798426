import {SagaIterator} from 'redux-saga';
import {
    putAll,
    showBeError,
    showSuccess,
    takeLatestF,
} from 'favorlogic-utils';
import {call, put, select} from 'typed-redux-saga';

import {formValuesF} from 'utils/formHelpers';
import {UpdatePickupLineDairyPlanDay} from 'types/model/pickupLine/UpdatePickupLineDairyPlanDay';

import {PickupLineApi} from '../api';
import {pickupLineAction} from '../actions';
import {
    PickupLineDairyPlanFormValues,
    PICKUP_LINE_DAIRY_PLAN_FORM_NAME,
} from '../components/PickupLineDairyPlanForm/pickupLineDairyPlanFormValues';

const title = 'Vyplnění svozových linek';

const parseFormData = (formValues: PickupLineDairyPlanFormValues): UpdatePickupLineDairyPlanDay[] => {
    const data: UpdatePickupLineDairyPlanDay[] = [];

    Object.values(formValues.states).forEach(
        x => {
            if (x) {
                Object.entries(x).forEach(
                    ([key, y]) => {
                        if (y) {
                            data.push({pickupLineRequestOptionId: Number(key), count: Number(y)});
                        }
                    });
            }
        }
    );

    return data;
};

function* execute (): SagaIterator {
    const formValues = (yield* select(formValuesF(PICKUP_LINE_DAIRY_PLAN_FORM_NAME)))
        .orCrash('no dairy plan form values');
    const data = parseFormData(formValues);
    const response = yield* call(PickupLineApi.updatePickupLineDairyPlan, data);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLineDairyPlan());
        yield* put(showSuccess(title, 'Úspěšně uloženo.'));
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* updatePickupLineDairyPlanSaga(): SagaIterator {
    yield takeLatestF('pickupLine/UPDATE_PICKUP_LINE_DAIRY_PLAN', execute);
}
