import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {SupplierAction} from './actions';
import {SuppliersPage} from 'types/model/suppliers/SuppliersPage';
import {SuppliersParams} from './types/SuppliersParams';
import {SupplierDetails} from 'types/model/suppliers/SupplierDetails';

export interface SupplierState {
    suppliersPage: SuppliersPage | null;
    current: SupplierDetails | null;
    suppliersMilkRooms: MilkRoom[] | null;
    milkRoom: MilkRoom | null;
    addingMilkRoomInProgress: boolean;
    suppliersParams: SuppliersParams;
    milkRooms: MilkRoom[] | null;
    isAresLoading: boolean;
}

export const initialSupplierState: SupplierState = {
    suppliersPage: null,
    current: null,
    milkRoom: null,
    addingMilkRoomInProgress: false,
    suppliersMilkRooms: null,
    suppliersParams: {},
    milkRooms: null,
    isAresLoading: false,
};

const reducer = (state: SupplierState | undefined = initialSupplierState, action: SupplierAction): SupplierState => {
    switch (action.type) {
        case 'supplier/RESET_SUPPLIERS': {
            return {...state, suppliersPage: null};
        }

        case 'supplier/LOAD_SUPPLIERS_SUCCESS': {
            return {...state, suppliersPage: action.payload};
        }

        case 'supplier/SET_SUPPLIERS_PARAMS': {
            return {...state, suppliersParams: action.payload};
        }

        case 'supplier/LOAD_SUPPLIER_SUCCESS': {
            return {...state, current: action.payload};
        }

        case 'supplier/LOAD_MILK_ROOM_SUCCESS': {
            return {...state, milkRoom: action.payload};
        }

        case 'supplier/SET_ADDING_MILK_ROOM_IN_PROGRESS': {
            return {...state, addingMilkRoomInProgress: action.payload};
        }

        case 'supplier/LOAD_SUPPLIERS_MILK_ROOMS_SUCCESS': {
            return {...state, suppliersMilkRooms: action.payload};
        }

        case 'supplier/LOAD_MILK_ROOMS_SUCCESS': {
            return {...state, milkRooms: action.payload};
        }

        case 'supplier/IS_ARES_LOADING': {
            return {...state, isAresLoading: action.payload.value};
        }

        default:
            return state;
    }
};

export default reducer;
