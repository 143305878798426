import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

import {SupplyChainItemFormValues} from '../../types/SupplyChainItemFormValues';

type Values = SupplyChainItemFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('milkRoom', 'Mléčnice');

    return validator.generateErrorsObject();
};
