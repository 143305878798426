import {YearMonth} from 'types/model/common/YearMonth';

import {editDateNumber} from './editDateNumber';

export const getNextYearMonth = (currentYearMonth?: YearMonth): string => {
    const date = currentYearMonth ? new Date(currentYearMonth) : new Date();
    const newYear = date.getMonth() > 10 ? date.getFullYear() + 1 : date.getFullYear();
    const newMonth = date.getMonth() > 10 ? 1 : date.getMonth() + 2;

    return `${newYear}-${editDateNumber(newMonth)}`;
};
