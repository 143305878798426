import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {SuccessResponse, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {SampleDetailsPage} from 'types/model/samples/SampleDetailsPage';
import {handleResponseError} from 'utils/handleResponseError';

import {LoadSamplesAction, loadSamplesError, loadSamplesSuccess} from '../actions';
import Api from '../api';

const title = 'Načtení vzorků';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(loadSamplesError(response.data as ErrorsFromBE));
}

function* handleSuccessResponse(response: SuccessResponse<SampleDetailsPage>): SagaIterator {
    yield* put(loadSamplesSuccess(response.data));
}

function* execute(action: LoadSamplesAction): SagaIterator {
    const response = yield* call(Api.getSamples, action.payload);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadSamplesPageSaga(): SagaIterator {
    yield takeLatestF('sample/LOAD_SAMPLES', execute);
}
