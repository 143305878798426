import React, {Component, ComponentClass, ReactNode} from 'react';
import {Dispatch} from 'redux';
import {routerActions} from 'connected-react-router';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';

import {Components as Icons} from 'icons';
import {StoreState} from 'app/types/StoreState';

import Button, {Importance} from '../Button';

import back from './back.svg';

interface OuterProps {
    label: ReactNode;
    importance?: Importance;
}

interface InnerProps {
    handleGoBack(): void;
}

type Props = InnerProps & OuterProps;

class BackButton extends Component<Props> {
    render(): ReactNode {
        const {label, importance, handleGoBack} = this.props;

        return (
            <Button
                type="button"
                onClick={handleGoBack}
                importance={importance || 'primary'}
            >
                <Icons.SvgIcon icon={back} /> {label}
            </Button>
        );
    }
}

const mapStateToProps = (_state: StoreState): Partial<Props> => ({});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleGoBack: () => dispatch(routerActions.goBack()),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(BackButton) as ComponentClass<OuterProps>;
