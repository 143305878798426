import * as t from 'io-ts';

import {PickupLineAnalysisSchema} from './PickupLineAnalysis';

const requiredPart = t.interface({
    id: t.number,
    name: t.string,
    analysis: t.array(PickupLineAnalysisSchema),
});

export const PickupLineRequestsSchema = t.exact(requiredPart);

export interface PickupLineRequests extends t.TypeOf<typeof PickupLineRequestsSchema> {}
