import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';
import {ResetPasswordFormValues} from 'user/types/ResetPasswordFormValues';

type Values = ResetPasswordFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('password', 'Heslo');
    validator.equal('password', 'checkPassword', 'Heslo', 'Potvrzení hesla', true);

    return validator.generateErrorsObject();
};
