import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import withUser from 'user/components/withUser';
import {loadDevice, updateDevice} from '../actions';
import DeviceForm from '../components/DeviceForm';
import {DataItem} from 'types/model/dataItems/DataItem';
import {SelectOptions} from 'forms/components/BasicSelect';
import {loadDataItems} from 'dataItem/actions';
import {Components as Layout} from 'layout';

interface OuterProps {}

interface StateProps {
    dataItems: DataItem[];
}

interface DispatchProps {
    loadDataItems(): void;
    loadDevice(id: number): void;
    updateDevice(id: number): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class DeviceEdit extends Component<Props> {
    private get deviceId(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    componentDidMount(): void {
        const {loadDevice, loadDataItems} = this.props;

        loadDataItems();
        loadDevice(this.deviceId);
    }

    render(): ReactNode {
        const {updateDevice, dataItems} = this.props;
        const handleSubmit = () => updateDevice(this.deviceId);
        const dataItemsOptions: SelectOptions<number> = dataItems.map(dataItem => ({
            value: dataItem.id,
            label: dataItem.name,
        }));

        return (
            <Layout.ItemPage
                title="Úprava přístroje"
                backLabel="Přístroje"
            >
                <DeviceForm
                    mode="EDIT"
                    dataItemsOptions={dataItemsOptions}
                    onSubmit={handleSubmit}
                />
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    dataItems: state.dataItem.dataItems || [],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadDataItems: () => dispatch(loadDataItems()),
    loadDevice: (id: number) => dispatch(loadDevice(id)),
    updateDevice: (id: number) => dispatch(updateDevice({id})),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(DeviceEdit) as ComponentClass<OuterProps>;
