import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {DeleteByFilterAction} from '../actions';
import Api from '../api';
import {handleErrorResponse, handleSuccess} from './utils/deleteSagaUtils';

function* execute({payload: {filter}}: DeleteByFilterAction): SagaIterator {
    const response = yield* call(Api.deleteByFilter, filter);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* deleteByFilterSaga(): SagaIterator {
    yield takeLatestF('measurement/DELETE_BY_FILTER', execute);
}
