import React, {Component, ReactNode, ComponentClass} from 'react';
import {withRouter} from 'react-router';
import {flow} from 'lodash/fp';
import {Redirect, Route, Switch} from 'react-router-dom';
import {History} from 'history';
import {classNames} from 'favorlogic-utils';

import withUser from 'user/components/withUser';
import {routes as userRoutes} from 'user';
import {routes as supplierRoutes} from 'supplier';
import {routes as orderRoutes} from 'order';
import {routes as supplyChainRoutes} from 'supplyChain';
import {routes as analysisRoutes} from 'analysis';
import {routes as sampleRoutes} from 'sample';
import {routes as measurementRoutes} from 'measurement';
import {routes as operatingProcedureRoutes} from 'operatingProcedure';
import {routes as dataItemRoutes} from 'dataItem';
import {routes as deviceRoutes} from 'device';
import {routes as testRoutes} from 'test';
import {routes as administrationRoutes} from 'administration';
import {pickupLinesRoutes} from 'pickupLine';
import PageNotFound from 'app/components/PageNotFound';

import Contact from '../Contact';

import 'styles/styles.sass';
import styles from './styles.sass';

interface InnerProps {
    history: History;
}

interface OuterProps {
}

type Props = InnerProps & OuterProps;

class PageContent extends Component<Props> {
    render(): ReactNode {
        const {history: {location: {pathname}}} = this.props;
        const classes = classNames(pathname !== '/login' ? styles.contentWrapper : styles.loginWrapper);

        return (
            <div className={classes}>
                <Switch>
                    <Redirect
                        exact
                        from="/"
                        to="/orders"
                    />
                    <Route
                        exact
                        path="/contact"
                        component={withUser(Contact)}
                    />
                    {userRoutes}
                    {supplierRoutes}
                    {orderRoutes}
                    {supplyChainRoutes}
                    {analysisRoutes}
                    {sampleRoutes}
                    {measurementRoutes}
                    {operatingProcedureRoutes}
                    {dataItemRoutes}
                    {deviceRoutes}
                    {testRoutes}
                    {administrationRoutes}
                    {pickupLinesRoutes}

                    <Route
                        path=""
                        component={PageNotFound}
                    />
                </Switch>
            </div>
        );
    }
}

export default flow([withRouter])(PageContent) as ComponentClass<OuterProps>;
