import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {difference, filter, flatten, flow, isEmpty, map, negate} from 'lodash/fp';
import {match as Match} from 'react-router';
import {Opt, opt, optEmptyArray} from 'ts-opt';

import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {CreateSample} from 'types/model/samples/CreateSample';
import {loadCustomerMilkRooms, resetCustomerMilkRooms} from 'user/actions';
import withUser, {WithUserProps} from 'user/components/withUser';
import {createSampleInOrder, loadSamplesByOrder} from 'sample/actions';
import {Analysis} from 'types/model/analyses/Analysis';
import {Components as Layout} from 'layout';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {StoreState} from 'app/types/StoreState';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {SampleDefaults} from 'sample/types/SampleDefaults';
import {loadAnalyses} from 'analysis/actions';
import {formValuesF} from 'utils/formHelpers';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {SelectOptions} from 'forms/components/BasicSelect';
import {loadMilkRooms} from 'supplier/actions';

import {
    loadOrder,
    loadCustomerForOrder,
    setAnalysesBySelectedDataItems,
    resetCustomerForOrder,
} from '../actions';
import CreateSampleForm, {initialCreateSampleState} from '../components/CreateSampleForm';
import {getMilkRoomsOptions, getAnalysesOptions} from '../utils/selectOptions';
import {
    getOrderCustomer,
    getPriceClassForCustomer,
    enableShowResultsByCustomer,
    enableQCZByCustomer,
    getOrderCustomerId,
} from '../utils/orderUtils';
import {getSampleDefaults} from '../utils/getSampleDefaults';

interface OuterProps {
}

interface InnerProps {
    sample: CreateSample[];
    analyses: Analysis[];
    order: Opt<OrderDetails>;
    orderSamples: Opt<OrderSampleView[]>;
    match: Match<{id: string, sampleIdx: string}>;
    customerMilkRooms: MilkRoom[] | null;
    milkRooms: MilkRoom[];
    analysesBySelectedDataItems: Analysis[];
    currentCreateSample: CreateSampleFormValues;
    customerForOrder: CustomerDetailsForLaborer | null;

    handleLoadOrder(id: number): void;

    handleCreateSample(id: number): void;

    handleLoadAnalyses(): void;

    handleSetAnalysesBySelectedDataItems(analyses: Analysis[]): void;

    handleLoadMilkRooms(): void;

    handleLoadCustomerMilkRooms(customerId: number): void;

    handleResetCustomerMilkRooms(): void;

    handleLoadCustomerForOrder(customerId: number): void;

    handleResetCustomerForOrder(): void;

    handleLoadSamplesByOrder(orderId: number): void;
}

type UserProps = Pick<WithUserProps,
    'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentCustomer'
>;
type Props = InnerProps & OuterProps & UserProps;

class EditNewSample extends Component<Props> {
    private get orderId(): number {
        const {match: {params}} = this.props;

        return Number(params.id);
    }

    private get orderCustomer(): Opt<CustomerDetails> {
        const {currentCustomer, customerForOrder} = this.props;

        return getOrderCustomer(currentCustomer, opt(customerForOrder), this.isAdminOrLaborer);
    }

    private get priceClass(): PriceClass | undefined {
        return getPriceClassForCustomer(this.orderCustomer);
    }

    private get enableShowResults(): boolean {
        return enableShowResultsByCustomer(this.orderCustomer);
    }

    private get sampleDefaults(): SampleDefaults | undefined {
        return getSampleDefaults(this.orderCustomer, this.isDairy);
    }

    private get isDairy(): boolean {
        const {isRoleDairy, isRoleDairyEmployee} = this.props;

        return isRoleDairy || isRoleDairyEmployee;
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    private get showQCZ(): boolean {
        return enableQCZByCustomer(this.orderCustomer);
    }

    private get showTraits(): boolean {
        return this.isAdminOrLaborer || this.isDairy;
    }

    componentDidMount(): void {
        const {handleLoadOrder, handleLoadAnalyses, handleLoadSamplesByOrder} = this.props;
        handleLoadAnalyses();
        handleLoadOrder(this.orderId);
        handleLoadSamplesByOrder(this.orderId);
    }

    componentDidUpdate(prevProps: Readonly<InnerProps & OuterProps>): void {
        const {
            order,
            handleLoadCustomerForOrder,
            handleResetCustomerForOrder,
            handleLoadMilkRooms,
        } = this.props;
        if (!order.isEmpty && order.orUndef() !== prevProps.order.orUndef()) {
            const customerId = getOrderCustomerId(order);

            if (this.isAdminOrLaborer) {
                this.loadMilkRoomsByCustomer(customerId);
            } else {
                handleLoadMilkRooms();
            }

            if (customerId) {
                handleLoadCustomerForOrder(customerId);
            } else {
                handleResetCustomerForOrder();
            }
        }
    }

    componentWillUnmount(): void {
        this.props.handleResetCustomerMilkRooms();
    }

    render(): ReactNode {
        const {
            order,
            currentCreateSample,
        } = this.props;

        if (order.isEmpty) {
            return null;
        }

        return (
            <Layout.ItemPage
                title="Přidání vzorku"
                backLabel="Objednávka"
            >
                <CreateSampleForm
                    formValues={currentCreateSample}
                    onSubmit={this.handleSubmit}
                    inOrderCreation
                    analysesOptions={this.getAnalysesOptions()}
                    milkRoomsOptions={this.getMilkRoomsOptions()}
                    handleLoadAnalysesOptions={this.handleLoadAnalysesOptions}
                    sampleBarcodeIds={this.getBarcodes()}
                    initialValues={{...initialCreateSampleState, ...this.sampleDefaults}}
                    enableShowResults={this.enableShowResults}
                    showTraits={this.showTraits}
                    allowQCZ={this.showQCZ}
                />
            </Layout.ItemPage>
        );
    }

    private readonly handleSubmit = () => {
        const {handleCreateSample} = this.props;

        handleCreateSample(this.orderId);
    };

    private getAnalysesOptions(): SelectOptions<number> {
        const {analyses, analysesBySelectedDataItems} = this.props;
        const filteredAnalyses = optEmptyArray(analysesBySelectedDataItems)
            .orElse(analyses);

        return getAnalysesOptions(filteredAnalyses, this.priceClass);
    }

    private readonly handleLoadAnalysesOptions = (ids: number[]): void => {
        const {handleSetAnalysesBySelectedDataItems, analyses} = this.props;
        if (isEmpty(ids)) {
            handleSetAnalysesBySelectedDataItems([]);
        } else {
            const dataItemIdsBySelectedAnalyses: number[][] = flow(
                map(id => filter(a => a.id === id, analyses)),
                flatten,
                map(x => x.dataItemIds),
            )(ids);

            const includeSameDataItemIds = (analysisIds: number[]): boolean => flow(
                map((x: number[]) => analysisIds !== x && difference(analysisIds, x)),
                filter((x: number[]) => x.length === 0),
                negate(isEmpty),
            )(dataItemIdsBySelectedAnalyses);

            const filteredAnalyses: Analysis[] =
                filter(a => !includeSameDataItemIds(a.dataItemIds), analyses);
            handleSetAnalysesBySelectedDataItems(filteredAnalyses);
        }
    };

    private getMilkRoomsOptions(): SelectOptions<number> | undefined {
        const {milkRooms, customerMilkRooms} = this.props;

        return this.isAdminOrLaborer
            ? getMilkRoomsOptions(customerMilkRooms)
            : getMilkRoomsOptions(milkRooms);
    }

    private getBarcodes(): string[] {
        const {orderSamples} = this.props;
        return orderSamples
            .orElse([])
            .map(x => x.barcode);
    }

    private loadMilkRoomsByCustomer(customerId: number | null): void {
        const {handleLoadCustomerMilkRooms, customerMilkRooms} = this.props;

        if (customerId && !customerMilkRooms) {
            handleLoadCustomerMilkRooms(customerId);
        }
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    currentCreateSample: formValuesF('createSample')(state).orUndef(),
    order: opt(state.order.current),
    orderSamples: opt(state.sample.orderSamples),
    milkRooms: state.supplier.milkRooms || [],
    analyses: state.analysis.analyses || [],
    analysesBySelectedDataItems: state.order.analysesBySelectedDataItems || [],
    customerMilkRooms: state.user.customerMilkRooms.orUndef(),
    customerForOrder: state.order.customer,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadOrder: (id: number) => dispatch(loadOrder(id)),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleCreateSample: (id: number) => dispatch(createSampleInOrder(id)),
    handleSetAnalysesBySelectedDataItems: (analyses: Analysis[]) =>
        dispatch(setAnalysesBySelectedDataItems(analyses)),
    handleLoadSamplesByOrder: (orderId: number) =>
        dispatch(loadSamplesByOrder(orderId)),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
    handleLoadCustomerMilkRooms: (id: number) => dispatch(loadCustomerMilkRooms(id)),
    handleResetCustomerMilkRooms: () => dispatch(resetCustomerMilkRooms()),
    handleLoadCustomerForOrder: (customerId: number) => dispatch(loadCustomerForOrder(customerId)),
    handleResetCustomerForOrder: () => dispatch(resetCustomerForOrder()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(EditNewSample) as ComponentClass<OuterProps>;
