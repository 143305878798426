import {SagaIterator} from 'redux-saga';
import {call} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {ExportXlsSelectionAction} from '../actions';
import Api from '../api';
import {handleSuccess} from './utils/exportSagaUtils';

function* execute({payload: {sampleIds}}: ExportXlsSelectionAction): SagaIterator {
    const response = yield* call(Api.exportXlsSelection, sampleIds);

    yield* response.isSuccess
        ? call(handleSuccess, response)
        : call(handleResponseError, response, 'Export Xls');
}

export function* exportXlsSelectionSaga(): SagaIterator {
    yield takeLatestF('measurement/EXPORT_XLS_SELECTION', execute);
}
