import {ErrorsFromBE} from 'favorlogic-utils';

import {MeasurementDetails} from 'types/model/measurements/MeasurementDetails';
import {SampleSearch} from 'types/model/samples/SampleSearch';
import {MeasurementDetailsPage} from 'types/model/measurements/MeasurementDetailsPage';
import {DataItem} from 'types/model/dataItems/DataItem';
import {SearchSamplesParams} from 'measurement/type/SearchSamplesParams';

import {MeasurementsParams, MeasurementsFilter} from './type/MeasurementsParams';

export const exportGeometricMeans = (isAdminOrLaborer: boolean) => ({
    type: 'measurement/EXPORT_GEOMETRIC_MEANS',
    payload: {isAdminOrLaborer},
}) as const;
export type ExportGeometricMeansAction = ReturnType<typeof exportGeometricMeans>;

// ---

export const importMeasurements = (file: File) => ({
    type: 'measurement/IMPORT_MEASUREMENTS',
    payload: {file},
}) as const;
export type ImportMeasurementsAction = ReturnType<typeof importMeasurements>;

// ---

export const searchSamples = (payload: SearchSamplesParams) => ({
    type: 'measurement/SEARCH_SAMPLES',
    payload,
}) as const;
export type SearchSamplesAction = ReturnType<typeof searchSamples>;

export const searchSamplesSuccess = (payload: SampleSearch[]) => ({
    type: 'measurement/SEARCH_SAMPLES_SUCCESS',
    payload,
}) as const;
export type SearchSamplesSuccessAction = ReturnType<typeof searchSamplesSuccess>;

export const searchSamplesError = (err: ErrorsFromBE) => ({
    type: 'measurement/SEARCH_SAMPLES_ERROR',
    payload: err,
}) as const;
export type SearchSamplesErrorAction = ReturnType<typeof searchSamplesError>;

export const resetSearchSamples = () => ({
    type: 'measurement/RESET_SEARCH_SAMPLES',
}) as const;
export type ResetSearchSamplesAction = ReturnType<typeof resetSearchSamples>;

// ---

export const createMeasurements = () => ({
    type: 'measurement/CREATE_MEASUREMENTS',
}) as const;
export type CreateMeasurementsAction = ReturnType<typeof createMeasurements>;

export const createMeasurementsSuccess = () => ({
    type: 'measurement/CREATE_MEASUREMENTS_SUCCESS',
}) as const;
export type CreateMeasurementsSuccessAction = ReturnType<typeof createMeasurementsSuccess>;

export const createMeasurementsError = (err: ErrorsFromBE) => ({
    type: 'measurement/CREATE_MEASUREMENTS_ERROR',
    payload: err,
}) as const;
export type CreateMeasurementsErrorAction = ReturnType<typeof createMeasurementsError>;

export const createMeasurement = () => ({
    type: 'measurement/CREATE_MEASUREMENT',
}) as const;
export type CreateMeasurementAction = ReturnType<typeof createMeasurement>;

export const createMeasurementSuccess = () => ({
    type: 'measurement/CREATE_MEASUREMENT_SUCCESS',
}) as const;
export type CreateMeasurementSuccessAction = ReturnType<typeof createMeasurementSuccess>;

export const createMeasurementError = (err: ErrorsFromBE) => ({
    type: 'measurement/CREATE_MEASUREMENT_ERROR',
    payload: err,
}) as const;
export type CreateMeasurementErrorAction = ReturnType<typeof createMeasurementError>;

export const createDeviceMeasurements = (deviceDataItems: DataItem[]) => ({
    type: 'measurement/CREATE_DEVICE_MEASUREMENTS',
    payload: {deviceDataItems},
}) as const;
export type CreateDeviceMeasurementsAction = ReturnType<typeof createDeviceMeasurements>;

export const createDeviceMeasurementsSuccess = () => ({
    type: 'measurement/CREATE_DEVICE_MEASUREMENTS_SUCCESS',
}) as const;
export type CreateDeviceMeasurementsSuccessAction = ReturnType<typeof createDeviceMeasurementsSuccess>;

export const createDeviceMeasurementsError = (err: ErrorsFromBE) => ({
    type: 'measurement/CREATE_DEVICE_MEASUREMENTS_ERROR',
    payload: err,
}) as const;
export type CreateDeviceMeasurementsErrorAction = ReturnType<typeof createDeviceMeasurementsError>;

// ---

export interface LoadMeasurementsAction {
    type: 'measurement/LOAD_MEASUREMENTS';
    payload: MeasurementsParams;
}

export const loadMeasurements = (params: MeasurementsParams): LoadMeasurementsAction => ({
    type: 'measurement/LOAD_MEASUREMENTS',
    payload: params,
});

export interface LoadMeasurementsSuccessAction {
    payload: MeasurementDetailsPage;
    type: 'measurement/LOAD_MEASUREMENTS_SUCCESS';
}

export const loadMeasurementsSuccess = (payload: MeasurementDetailsPage): LoadMeasurementsSuccessAction => ({
    type: 'measurement/LOAD_MEASUREMENTS_SUCCESS',
    payload,
});

export interface LoadMeasurementsErrorAction {
    type: 'measurement/LOAD_MEASUREMENTS_ERROR';
    payload: ErrorsFromBE;
}

export const loadMeasurementsError = (err: ErrorsFromBE): LoadMeasurementsErrorAction => ({
    type: 'measurement/LOAD_MEASUREMENTS_ERROR',
    payload: err,
});

// ---

export interface SetMeasurementsParamsAction {
    payload: MeasurementsParams;
    type: 'measurement/SET_MEASUREMENTS_PARAMS';
}

export const setMeasurementsParams = (params: MeasurementsParams): SetMeasurementsParamsAction => ({
    type: 'measurement/SET_MEASUREMENTS_PARAMS',
    payload: params,
});

// ---

export interface DeleteMeasurementAction {
    type: 'measurement/DELETE_MEASUREMENT';
    payload: {
        measurementId: number,
    };
}

export const deleteMeasurement = (measurementId: number): DeleteMeasurementAction => ({
    type: 'measurement/DELETE_MEASUREMENT',
    payload: {measurementId},
});

export interface DeleteMeasurementSuccessAction {
    type: 'measurement/DELETE_MEASUREMENT_SUCCESS';
}

export const deleteMeasurementSuccess = (): DeleteMeasurementSuccessAction => ({
    type: 'measurement/DELETE_MEASUREMENT_SUCCESS',
});

export interface DeleteMeasurementErrorAction {
    type: 'measurement/DELETE_MEASUREMENT_ERROR';
    payload: ErrorsFromBE;
}

export const deleteMeasurementError = (error: ErrorsFromBE): DeleteMeasurementErrorAction => ({
    type: 'measurement/DELETE_MEASUREMENT_ERROR',
    payload: error,
});

// ---

export interface ApproveSamplesSelectionAction {
    type: 'measurement/APPROVE_SAMPLES_SELECTION';
    payload: {
        sampleIds: number[],
    };
}

export const approveSamplesSelection = (sampleIds: number[]): ApproveSamplesSelectionAction => ({
    type: 'measurement/APPROVE_SAMPLES_SELECTION',
    payload: {sampleIds},
});

export interface ApproveSamplesByFilterAction {
    type: 'measurement/APPROVE_SAMPLES_BY_FILTER';
    payload: {
        filter: MeasurementsFilter,
    };
}

export const approveSamplesByFilter = (filter: MeasurementsFilter): ApproveSamplesByFilterAction => ({
    type: 'measurement/APPROVE_SAMPLES_BY_FILTER',
    payload: {filter},
});

// ---

export interface LoadMeasurementAction {
    type: 'measurement/LOAD_MEASUREMENT';
    payload: number;
}

export const loadMeasurement = (payload: number): LoadMeasurementAction => ({
    type: 'measurement/LOAD_MEASUREMENT',
    payload,
});

export interface LoadMeasurementSuccessAction {
    payload: MeasurementDetails;
    type: 'measurement/LOAD_MEASUREMENT_SUCCESS';
}

export const loadMeasurementSuccess = (payload: MeasurementDetails): LoadMeasurementSuccessAction => ({
    type: 'measurement/LOAD_MEASUREMENT_SUCCESS',
    payload,
});

export interface LoadMeasurementErrorAction {
    type: 'measurement/LOAD_MEASUREMENT_ERROR';
    payload: ErrorsFromBE;
}

export const loadMeasurementError = (err: ErrorsFromBE): LoadMeasurementErrorAction => ({
    type: 'measurement/LOAD_MEASUREMENT_ERROR',
    payload: err,
});


// ---

export interface UpdateMeasurementAction {
    type: 'measurement/UPDATE_MEASUREMENT';
    payload: number;
}

export const updateMeasurement = (payload: number): UpdateMeasurementAction => ({
    type: 'measurement/UPDATE_MEASUREMENT',
    payload,
});

export interface UpdateMeasurementSuccessAction {
    type: 'measurement/UPDATE_MEASUREMENT_SUCCESS';
}

export const updateMeasurementSuccess = (): UpdateMeasurementSuccessAction => ({
    type: 'measurement/UPDATE_MEASUREMENT_SUCCESS',
});

export interface UpdateMeasurementErrorAction {
    type: 'measurement/UPDATE_MEASUREMENT_ERROR';
    payload: ErrorsFromBE;
}

export const updateMeasurementError = (err: ErrorsFromBE): UpdateMeasurementErrorAction => ({
    type: 'measurement/UPDATE_MEASUREMENT_ERROR',
    payload: err,
});

// ---

export interface ToggleSampleSelectAction {
    payload: number;
    type: 'measurement/TOGGLE_SAMPLE_SELECT';
}

export const toggleSampleSelect = (sampleId: number): ToggleSampleSelectAction => ({
    type: 'measurement/TOGGLE_SAMPLE_SELECT',
    payload: sampleId,
});

export interface ResetSamplesSelectionAction {
    type: 'measurement/RESET_SAMPLES_SELECTION';
}

export const resetSamplesSelection = (): ResetSamplesSelectionAction => ({
    type: 'measurement/RESET_SAMPLES_SELECTION',
});

// ---

export interface SetSamplesSubsidySelectionAction {
    type: 'measurement/SET_SAMPLES_SUBSIDY_SELECTION';
    payload: {
        sampleIds: number[],
        value: boolean,
    };
}

export const setSamplesSubsidySelection = (sampleIds: number[], value: boolean): SetSamplesSubsidySelectionAction => ({
    type: 'measurement/SET_SAMPLES_SUBSIDY_SELECTION',
    payload: {sampleIds, value},
});

export interface SetSamplesSubsidyByFilterAction {
    type: 'measurement/SET_SAMPLES_SUBSIDY_BY_FILTER';
    payload: {
        filter: MeasurementsFilter,
        value: boolean,
    };
}

export const setSamplesSubsidyByFilter = (
    filter: MeasurementsFilter,
    value: boolean,
): SetSamplesSubsidyByFilterAction => ({
    type: 'measurement/SET_SAMPLES_SUBSIDY_BY_FILTER',
    payload: {filter, value},
});

// ---

export interface LoadRequestedDataItemsAction {
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS';
}

export const loadRequestedDataItems = (): LoadRequestedDataItemsAction => ({
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS',
});

export interface LoadRequestedDataItemsSuccessAction {
    payload: number[];
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS_SUCCESS';
}

export const loadRequestedDataItemsSuccess = (payload: number[]): LoadRequestedDataItemsSuccessAction => ({
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS_SUCCESS',
    payload,
});

export interface LoadRequestedDataItemsErrorAction {
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS_ERROR';
    payload: ErrorsFromBE;
}

export const loadRequestedDataItemsError = (error: ErrorsFromBE): LoadRequestedDataItemsErrorAction => ({
    type: 'measurement/LOAD_REQUSTED_DATA_ITEMS_ERROR',
    payload: error,
});

// ---

export interface ExportXlsSelectionAction {
    type: 'measurement/EXPORT_XLS_SELECTION';
    payload: {
        sampleIds: number[],
    };
}

export const exportXlsSelection = (sampleIds: number[]): ExportXlsSelectionAction => ({
    type: 'measurement/EXPORT_XLS_SELECTION',
    payload: {sampleIds},
});

export interface ExportXlsByFilterAction {
    type: 'measurement/EXPORT_XLS_BY_FILTER';
    payload: {
        filter: MeasurementsFilter,
    };
}

export const exportXlsByFilter = (filter: MeasurementsFilter): ExportXlsByFilterAction => ({
    type: 'measurement/EXPORT_XLS_BY_FILTER',
    payload: {filter},
});

// ---

export interface ExportSamplesSubsidySelectionAction {
    type: 'measurement/EXPORT_SAMPLES_SUBSIDY_SELECTION';
    payload: {
        sampleIds: number[],
    };
}

export const exportSamplesSubsidySelection = (sampleIds: number[]): ExportSamplesSubsidySelectionAction => ({
    type: 'measurement/EXPORT_SAMPLES_SUBSIDY_SELECTION',
    payload: {sampleIds},
});

export interface ExportSamplesSubsidyByFilterAction {
    type: 'measurement/EXPORT_SAMPLES_SUBSIDY_BY_FILTER';
    payload: {
        filter: MeasurementsFilter,
    };
}

export const exportSamplesSubsidyByFilter = (filter: MeasurementsFilter): ExportSamplesSubsidyByFilterAction => ({
    type: 'measurement/EXPORT_SAMPLES_SUBSIDY_BY_FILTER',
    payload: {filter},
});

export const importSamplesSubsidy = (file: File) => ({
    type: 'measurement/IMPORT_SAMPLES_SUBSIDY',
    payload: {file},
}) as const;
export type ImportSamplesSubsidyAction = ReturnType<typeof importSamplesSubsidy>;

// ---

export const deleteBySelection = (sampleIds: number[]) => ({
    type: 'measurement/DELETE_BY_SELECTION',
    payload: {sampleIds},
}) as const;
export type DeleteBySelectionAction = ReturnType<typeof deleteBySelection>;

export const deleteByFilter = (filter: MeasurementsFilter) => ({
    type: 'measurement/DELETE_BY_FILTER',
    payload: {filter},
}) as const;
export type DeleteByFilterAction = ReturnType<typeof deleteByFilter>;

// ---

export type MeasurementAction
    = ExportGeometricMeansAction

    | ImportMeasurementsAction

    | SearchSamplesAction
    | SearchSamplesSuccessAction
    | SearchSamplesErrorAction
    | ResetSearchSamplesAction

    | CreateMeasurementsAction
    | CreateMeasurementsSuccessAction
    | CreateMeasurementsErrorAction

    | CreateDeviceMeasurementsAction
    | CreateDeviceMeasurementsSuccessAction
    | CreateDeviceMeasurementsErrorAction

    | LoadMeasurementsAction
    | LoadMeasurementsSuccessAction
    | LoadMeasurementsErrorAction

    | LoadMeasurementAction
    | LoadMeasurementSuccessAction
    | LoadMeasurementErrorAction

    | UpdateMeasurementAction
    | UpdateMeasurementSuccessAction
    | UpdateMeasurementErrorAction

    | DeleteMeasurementAction
    | DeleteMeasurementSuccessAction
    | DeleteMeasurementErrorAction

    | SetMeasurementsParamsAction

    | ApproveSamplesSelectionAction
    | ApproveSamplesByFilterAction

    | SetSamplesSubsidySelectionAction
    | SetSamplesSubsidyByFilterAction

    | ToggleSampleSelectAction
    | ResetSamplesSelectionAction

    | LoadRequestedDataItemsAction
    | LoadRequestedDataItemsSuccessAction
    | LoadRequestedDataItemsErrorAction

    | ExportXlsSelectionAction
    | ExportXlsByFilterAction

    | ExportSamplesSubsidyByFilterAction
    | ExportSamplesSubsidySelectionAction
    | ImportSamplesSubsidyAction

    | DeleteBySelectionAction
    | DeleteByFilterAction
    ;
