import {UpdateMeasurementFormValues} from 'measurement/type/UpdateMeasurementFormValues';
import {DairyValidator} from 'utils/DairyValidator';
import {Errors} from 'utils/Validator';

type Values = UpdateMeasurementFormValues;

export const validate = (values: Values): Errors<Values> => {
    const validator = new DairyValidator(values);

    validator.nonEmpty('measured', 'Změřeno');
    validator.nonEmpty('result', 'Výsledek');
    validator.nonEmpty('dilution', 'Ředění');
    validator.nonEmpty('procedureId', 'Operační postup');

    return validator.generateErrorsObject();
};
