import * as t from 'io-ts';

import {HexColorSchema} from '../common/HexColor';

export const WRITE_PICKUP_LINE_MAX_LENGTH = 255;

const requiredPart = t.interface({
    name: t.string,
    customerIds: t.array(t.number),
    color: HexColorSchema,
});

export const WritePickupLineSchema = t.exact(requiredPart);

export interface WritePickupLine extends t.TypeOf<typeof WritePickupLineSchema> {}
