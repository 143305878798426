import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {ResetPasswordEmailFormValues} from 'user/types/ResetPasswordEmailFormValues';
import {validate} from './validations';

export interface ResetFormOwnProps {
    formValues?: ResetPasswordEmailFormValues;
}

type Props = PropsForForm<ResetPasswordEmailFormValues, ResetFormOwnProps>;

class ResetPasswordEmailForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
        } = this.props;

        return (
            <Layout.Panel>
                <Form onSubmit={handleSubmit}>
                    <Layout.Loader show={submitting} />
                    <div className="row">
                        <div className="col-12">
                            <strong className="mt-3 d-block">
                                Pro obnovení zadejte Váš registrační e-mail a Vaše údaje Vám budou doručeny na e-mail.
                            </strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 pt-2">
                            <Fields.Input
                                name="email"
                                type="text"
                                label="E-mail*"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Obnovit zapomenuté heslo
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}

export default Forms.withForm<ResetPasswordEmailFormValues, ResetFormOwnProps, Props>(ResetPasswordEmailForm, {
    form: 'resetPasswordEmail',
    validate,
    initialValues: {
        email: '',
    },
});
