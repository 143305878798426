import {SagaIterator} from 'redux-saga';
import {call, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {isEmpty} from 'lodash/fp';
import {
    extractFormErrorsFromResponse,
    emptyStringToUndefined,
    takeLatestF,
    UnsafeBeFormErrors,
    ErrorResponse,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {isDicValid} from 'types/generic/DicSchema';
import {isIcoValid} from 'types/generic/IcoSchema';
import {SupplierValidation} from 'types/model/suppliers/SupplierValidation';
import {isEmailValid} from 'types/generic/EmailSchema';

import {ValidateSupplierAction} from '../actions';
import Api from '../api';

export const prepareValues = (x: SupplierValidation, supplierId: number | undefined): SupplierValidation => {
    const ico: string | undefined = isIcoValid(x.ico || '') ? x.ico : undefined;
    const dic = isDicValid(x.dic || '') ? x.dic : undefined;
    const email = isEmailValid(x.email || '') ? x.email : undefined;

    return {
        name: emptyStringToUndefined(x.name),
        email,
        ico,
        dic,
        supplierId,
    };
};

function getSupplierId(x: StoreState): number {
    return opt(x.supplier.current).orCrash('missing supplier').id;
}

function handleErrorResponse(response: ErrorResponse, reject: (_: UnsafeBeFormErrors) => void): void {
    const errors = extractFormErrorsFromResponse(response);

    if (!isEmpty(errors)) {
        reject(errors);
    }
}

function* execute({payload: {reject, resolve, values, isCreate}}: ValidateSupplierAction): SagaIterator {
    const supplierId = isCreate ? undefined : yield* select(getSupplierId);
    const response = yield* call(Api.validateSupplier, prepareValues(values, supplierId));

    yield* response.isSuccess
        ? call(resolve, false)
        : call(handleErrorResponse, response, reject);
}

export function* validateSupplierSaga(): SagaIterator {
    yield takeLatestF('supplier/VALIDATE_SUPPLIER', execute);
}
