import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {showSuccess, ErrorsFromBE, takeLatestF, ErrorResponse} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {handleResponseError} from 'utils/handleResponseError';

import {DeleteSupplierAction, deleteSupplierError, deleteSupplierSuccess, loadSuppliers} from '../actions';
import Api from '../api';

const title = 'Smazání dodavatele';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(deleteSupplierError(response.data as ErrorsFromBE));
}

function* handleSuccess(): SagaIterator {
    const params = yield* select((state: StoreState) => state.supplier.suppliersParams);

    yield* put(showSuccess(title, 'Dodavatel byl úspěšně smazán'));
    yield* put(deleteSupplierSuccess());
    yield* put(loadSuppliers(params));
}

function* execute(action: DeleteSupplierAction): SagaIterator {
    const response = yield* call(Api.deleteSupplier, action.payload);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* deleteSupplierSaga(): SagaIterator {
    yield takeLatestF('supplier/DELETE_SUPPLIER', execute);
}
