import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    takeLatestF,
    showSuccess,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, resetF, startSubmitF, stopSubmitF} from 'utils/formHelpers';
import {PasswordReset} from 'types/model/customers/PasswordReset';

import {ResetPasswordFormValues} from '../types/ResetPasswordFormValues';
import {ResetPasswordAction} from '../actions';
import Api from '../api';

const title = 'Obnovení hesla';

export function prepareResetPasswordData(
    action: ResetPasswordAction,
    formValues: ResetPasswordFormValues
): PasswordReset {
    const {uuid} = action.payload;
    const {password} = formValues;

    return {
        password,
        uuid,
    };
}

function* execute(action: ResetPasswordAction): SagaIterator {
    yield* put(startSubmitF('resetPassword'));

    const formValuesOpt = yield* select(formValuesF('resetPassword'));
    const formValues = formValuesOpt.orCrash('missing form data');
    const response = yield* call(Api.resetPassword, prepareResetPasswordData(action, formValues));

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        return yield* put(stopSubmitF('resetPassword', extractFormErrorsFromResponse(response)));
    }

    yield* put(stopSubmitF('resetPassword'));
    yield* put(showSuccess(title, 'Heslo bylo změněno'));
    yield* put(resetF('resetPassword'));
    yield* put(routerActions.push('/login'));
}

export function* resetPasswordSaga(): SagaIterator {
    yield takeLatestF('user/RESET_PASSWORD', execute);
}
