import {Opt} from 'ts-opt';
import React, {Component, ReactNode, Fragment} from 'react';
import {Form} from 'redux-form';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {OrderCustomerFormValues} from 'order/types/OrderCustomerFormValues';
import {Address} from 'types/model/common/Address';
import {Customer} from 'types/model/customers/Customer';
import {getCustomerNamesOptions} from 'order/utils/selectOptions';

import {validate} from './validations';

export const createEmptyAddress = (): Address => ({street: '', city: '', zip: ''});

export const initialOrderCustomerFormValues: OrderCustomerFormValues = {
    customerDetails: {
        id: 0,
        firstName: '',
        lastName: '',
        invoiceDetails: {
            tag: 'CustomerInvoiceDetails',
            email: '',
        },
        address: createEmptyAddress(),
        billingAddress: createEmptyAddress(),
        customerName: '',
    },
    prefillOrderCustomer: null,
    customerId: null,
    hasSameBillingAddress: true,
    hasNoIco: false,
    prefilled: false,
};

export interface OwnProps {
    customers: Customer[] | null;
    hasSameBillingAddress: boolean;
    hasNoIco: boolean;
    isAresLoading: boolean;
    prefilled?: boolean;

    onIcoSearch(ico: Opt<string>): void;
    handleIcoChange(): void;
}

type Props = PropsForForm<OrderCustomerFormValues, OwnProps>;

class OrderCustomerForm extends Component<Props> {
    render(): ReactNode {
        const {
            submitting,
            customers,
            handleSubmit,
            onIcoSearch,
            autofill,
            autofillUnsafe,
            hasSameBillingAddress,
            hasNoIco,
            prefilled,
            handleIcoChange,
            isAresLoading,
        } = this.props;
        const onHasIcoChange = () => {
            autofillUnsafe('customerDetails.invoiceDetails.ico', '');
            autofillUnsafe('customerDetails.invoiceDetails.dic', '');
            autofill('prefillOrderCustomer', 0);
        };

        const onOrderCustomerSelect = (idOpt: Opt<number>) => {
            idOpt.caseOf(this.fillOrderCustomer, this.resetFilledOrderCustomer);
        };

        const onHasSameBillingAddressChange = () => {
            autofillUnsafe('customerDetails.billingAddress', createEmptyAddress());
        };

        const showDetails = prefilled || hasNoIco;

        return (
            <Layout.Panel>
                <Form
                    className="position-relative"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting || !customers} />
                    <div className="row">
                        <div className="col-md-6">
                            <Fields.Select<number>
                                className="mt-2"
                                name="customerId"
                                label="Zákazník"
                                options={customers ? getCustomerNamesOptions(customers) : []}
                                onFieldChange={onOrderCustomerSelect}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-6">
                            <strong className="mt-3 d-block">Základní údaje</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.firstName"
                                type="text"
                                label="Křestní jméno*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.lastName"
                                type="text"
                                label="Příjmení*"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.invoiceDetails.email"
                                type="text"
                                label="E-mail*"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.invoiceDetails.phone"
                                type="text"
                                label="Telefonní číslo"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.invoiceDetails.ico"
                                type="text"
                                label="IČO"
                                onAction={onIcoSearch}
                                disabled={hasNoIco || isAresLoading}
                                fieldIsLoading={isAresLoading}
                                onFieldBlur={handleIcoChange}
                            />
                            <Fields.Checkbox
                                className="mt-2"
                                name="hasNoIco"
                                label="Nemám ičo"
                                disabled={isAresLoading}
                                onFieldChange={onHasIcoChange}
                            />
                        </div>
                        {showDetails && <div className="col-12 col-md-6">
                            <Fields.Input
                                className="mt-2"
                                name="customerDetails.invoiceDetails.dic"
                                type="text"
                                label="DIČ"
                                disabled
                            />
                        </div>}
                    </div>

                    {showDetails && <Fragment>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="customerDetails.customerName"
                                    type="text"
                                    label="Objednatel*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <strong className="mt-3 d-block">Adresa</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="customerDetails.address.street"
                                    type="text"
                                    label="Ulice*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="customerDetails.address.city"
                                    type="text"
                                    label="Město*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Fields.Input
                                    className="mt-2"
                                    name="customerDetails.address.zip"
                                    type="text"
                                    label="Směrovací číslo*"
                                    disabled={!hasNoIco}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Fields.Checkbox
                                    className="mt-2"
                                    name="hasSameBillingAddress"
                                    label="Fakturační adresa je stejná"
                                    onFieldChange={onHasSameBillingAddressChange}
                                />
                            </div>
                        </div>

                        {!hasSameBillingAddress && <Fragment>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <strong className="mt-3 d-block">Fakturační adresa</strong>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="customerDetails.billingAddress.street"
                                        type="text"
                                        label="Ulice*"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="customerDetails.billingAddress.city"
                                        type="text"
                                        label="Město*"
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Fields.Input
                                        className="mt-2"
                                        name="customerDetails.billingAddress.zip"
                                        type="text"
                                        label="Směrovací číslo*"
                                    />
                                </div>
                            </div>
                        </Fragment>}
                    </Fragment>}
                </Form>
            </Layout.Panel>
        );
    }

    private fillOrderCustomer = (id: number): void => {
        const {autofill, customers} = this.props;
        if (!customers) {
            return;
        }

        const customer = customers.find(c => c.id === id);
        if (!customer) {
            return;
        }

        const orderCustomerDetails: Customer = {
            ...customer,
            billingAddress: createEmptyAddress(),
        };

        autofill('customerDetails', orderCustomerDetails);
        autofill('hasSameBillingAddress', true);
        autofill('hasNoIco', !orderCustomerDetails.invoiceDetails.ico);
        autofill('prefilled', true);
    }

    private resetFilledOrderCustomer = (): void => {
        const {autofill} = this.props;

        autofill('customerDetails', initialOrderCustomerFormValues.customerDetails);
        autofill('hasSameBillingAddress', initialOrderCustomerFormValues.hasSameBillingAddress);
        autofill('hasNoIco', initialOrderCustomerFormValues.hasNoIco);
        autofill('prefilled', initialOrderCustomerFormValues.prefilled);
    }
}

export default Forms.withForm<OrderCustomerFormValues, OwnProps, Props>(OrderCustomerForm, {
    form: 'orderCustomer',
    destroyOnUnmount: false,
    validate,
    initialValues: initialOrderCustomerFormValues,
});
