import React, {FunctionComponent, ReactNode} from 'react';

import {classNames} from 'favorlogic-utils';

import styles from './styles.sass';

export type Importance = 'primary' | 'secondary';
export type ButtonType = 'button' | 'submit' | 'reset';

export interface ButtonProps {
    children?: ReactNode;
    type: ButtonType;
    disabled?: boolean;
    className?: string;
    importance?: Importance;
    small?: boolean;
    title?: string;
    fullWidth?: boolean;

    onClick?(): void;
}

const Button: FunctionComponent<ButtonProps> = props => {
    const {
        children,
        onClick,
        type,
        disabled,
        className,
        importance,
        small,
        title,
        fullWidth,
    } = props;
    const finalClassName = classNames(
        'text-nowrap',
        className,
        importance && styles[importance],
        disabled && styles.disabled,
        small && styles.small,
        fullWidth && 'w-100',
    );

    return (
        <button
            onClick={onClick}
            type={type}
            className={finalClassName}
            disabled={disabled}
            title={title}
        >
            {children}
        </button>
    );
};

export default Button;
