import React, {FunctionComponent, ReactNode} from 'react';

import {BackButton, EditButton} from 'buttons/components';

export interface EditAction {
    to: string;
    tooltip?: string;
    disabled?: boolean;
    disabledTooltip?: string;
}

interface Props {
    title: ReactNode;
    backLabel: string;
    editAction?: EditAction;
}

const ItemPageHeader: FunctionComponent<Props> = props => {
    const {title, backLabel, editAction} = props;

    return (
        <div className="row flex-wrap flex-md-nowrap justify-content-between align-items-center">
            <span className="col-lg-2 col-sm-3 col-12 d-flex justify-content-start align-items-center">
                <BackButton
                    label={backLabel}
                />
            </span>
            <h1 className="text-center col-lg-8 col-sm-6 col-12 my-3 my-sm-2">{title}</h1>
            <span className="col-lg-2 col-sm-3 col-12 d-flex justify-content-end align-items-center">
                {editAction && <EditButton
                    label="Upravit"
                    to={editAction.to}
                    disabled={editAction.disabled}
                    title={editAction.disabled ? editAction.disabledTooltip : editAction.tooltip}
                />}
            </span>
        </div>
    );
};

export default ItemPageHeader;
