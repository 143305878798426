import {SagaIterator} from 'redux-saga';
import {call, select} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {isEmpty} from 'lodash/fp';
import {
    extractFormErrorsFromResponse,
    takeLatestF,
    UnsafeBeFormErrors,
    ErrorResponse,
} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {SupplyChainValidation} from 'types/model/supplyChains/SupplyChainValidation';
import {formValuesF} from 'utils/formHelpers';

import {ValidateSupplyChainAction} from '../actions';
import {SupplyChainFormValues} from '../types/SupplyChainFormValues';
import Api from '../api';

export function createSupplyChainValidation(
    values: SupplyChainFormValues,
    supplyChainId?: number,
): SupplyChainValidation {
    return {
        supplyChainId,
        code: values.code,
        name: values.name,
        milkRoomIds: values.milkRoomIds,
    };
}

function getSupplyChainId(state: StoreState): number {
    return opt(state.supplyChain.supplyChain).orCrash('missing supply chain').id;
}

function getSupplyChainFormValue(state: StoreState): SupplyChainFormValues {
    return formValuesF('supplyChain')(state).orCrash('missing form values');
}

function handleErrorResponse(response: ErrorResponse, reject: (_: UnsafeBeFormErrors) => void): void {
    const errors = extractFormErrorsFromResponse(response);

    if (!isEmpty(errors)) {
        reject(errors);
    }
}

function* execute({payload: {reject, resolve, isCreate}}: ValidateSupplyChainAction): SagaIterator {
    const values = yield* select(getSupplyChainFormValue);
    const supplyChainId = isCreate ? undefined : yield* select(getSupplyChainId);
    const validation = createSupplyChainValidation(values, supplyChainId);
    const response = yield* call(Api.validateSupplyChain, validation);

    yield* response.isSuccess
        ? call(resolve, false)
        : call(handleErrorResponse, response, reject);
}

export function* validateSupplyChainSaga(): SagaIterator {
    yield takeLatestF('supplier/VALIDATE_SUPPLY_CHAIN', execute);
}
