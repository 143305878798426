import {stubTrue} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';

import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {SupplyChainFormValues} from 'supplyChain/types/SupplyChainFormValues';
import {asyncValidate, validate} from './validations';

import styles from './styles.sass';

interface OwnProps {
    isCreate: boolean;
}

export type SupplyChainFormProps = PropsForForm<SupplyChainFormValues, OwnProps>;

class SupplyChainForm extends Component<SupplyChainFormProps> {
    render(): ReactNode {
        const {
            handleSubmit,
            submitting,
        } = this.props;
        return (
            <Form
                className={`position-relative row ${styles.form}`}
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />
                <div className="col-12">
                    <Fields.Input
                        name="code"
                        type="text"
                        label="Identifikace linky*"
                    />
                </div>
                <div className="col-12">
                    <Fields.Input
                        name="name"
                        type="text"
                        label="Název linky"
                    />
                </div>
                <div className="col-12">
                    <Fields.Input
                        type="hidden"
                        name="milkRoomIds"
                        label=""
                    />
                </div>
            </Form>
        );
    }
}

export default Forms.withForm<SupplyChainFormValues, OwnProps, SupplyChainFormProps>(SupplyChainForm, {
    form: 'supplyChain',
    validate,
    asyncValidate,
    shouldAsyncValidate: stubTrue,
    asyncBlurFields: ['code', 'name', 'milkRoomIds'],
    initialValues: {
        code: '',
        name: '',
        milkRoomIds: [],
    },
});
