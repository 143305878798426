import * as t from 'io-ts';
import {includes} from 'lodash/fp';

export const OrderStateSchema = t.keyof({
    NEW: null,
    ACCEPTED: null,
    IN_PROGRESS: null,
    COMPLETED: null,
    CANCELED: null,
});

export const ORDER_STATE_NAMES = ['NEW', 'ACCEPTED', 'IN_PROGRESS', 'COMPLETED', 'CANCELED'] as const;
export type OrderState = typeof ORDER_STATE_NAMES[number];

export const isValidOrderState = (x: string): x is OrderState => includes(x, ORDER_STATE_NAMES);
