import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {SampleTemperatureSchema} from 'types/generic/SampleTemperatureSchema';

import {PriceClassSchema} from '../enumeration/PriceClass';
import {PreservativeSchema} from '../enumeration/Preservative';
import {SampleBaseSchema} from './SampleBase';

const optionalPart = t.partial({
    accepted: DateStringSchema,
    milkRoomName: t.string,
    supplyChainCode: t.string,
    supplyChainName: t.string,
    deliveryTemperature: SampleTemperatureSchema,
    note: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    orderId: IntegerSchema,
    sampleOrder: IntegerSchema,
    customerName: t.string,
    customerId: t.number,
    priceClass: PriceClassSchema,
    disabled: t.boolean,
    canceled: t.boolean,
    canDelete: t.boolean,
    canUpdate: t.boolean,
    preservatives: t.array(PreservativeSchema),
    tag: t.literal('SampleDetails'),
});

export const SampleDetailsSchema = t.exact(t.intersection([
    SampleBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface SampleDetails extends t.TypeOf<typeof SampleDetailsSchema> {}
