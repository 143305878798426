import * as t from 'io-ts';

import {PickupLineAnalysisDaySchema} from './PickupLineAnalysisDay';

const requiredPart = t.interface({
    id: t.number,
    abbr: t.string,
    totalCount: t.number,
    days: t.array(PickupLineAnalysisDaySchema),
});

export const PickupLineAnalysisSchema = t.exact(requiredPart);

export interface PickupLineAnalysis extends t.TypeOf<typeof PickupLineAnalysisSchema> {}
