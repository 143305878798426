import {flow, noop} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt, none} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {UserDetails} from 'types/model/users/UserDetails';
import {loadCurrent} from '../../actions';

export interface WithUserProps {
    currentUser: Opt<UserDetails>;
    currentCustomer: Opt<CustomerDetails>;
    loadingUser: boolean;
    isRoleAdmin: boolean;
    isRoleDairy: boolean;
    isRoleDairyEmployee: boolean;
    isRoleBreeder: boolean;
    isRoleLaborer: boolean;
    isPickupLineAdmin: boolean;

    handleLoadCurrentUser(): void;
}

const withUser = (WrappedComponent: React.ComponentType): React.ComponentType => {
    class WithUser extends Component<WithUserProps> {
        static defaultProps: WithUserProps = {
            currentUser: none,
            currentCustomer: none,
            loadingUser: false,
            isRoleAdmin: false,
            isRoleDairy: false,
            isRoleDairyEmployee: false,
            isRoleBreeder: false,
            isRoleLaborer: false,
            isPickupLineAdmin: false,

            handleLoadCurrentUser: noop,
        };

        componentDidMount(): void {
            const {currentUser, loadingUser, handleLoadCurrentUser} = this.props;
            if (currentUser.isEmpty && !loadingUser) { handleLoadCurrentUser(); }
        }

        render(): ReactNode {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return this.props.currentUser.map(() => <WrappedComponent {...this.props} />).orNull();
        }
    }

    const mapStateToProps = (state: StoreState): Partial<WithUserProps> => {
        const rolename = state.user.currentUser.map(x => x.role).orUndef();
        return {
            currentUser: state.user.currentUser,
            currentCustomer: state.user.customerOfCurrentUser,
            loadingUser: state.user.loadingUser,
            isRoleDairy: rolename === 'DAIRY',
            isRoleDairyEmployee: rolename === 'DAIRY_EMPLOYEE',
            isRoleBreeder: rolename === 'BREEDER',
            isRoleAdmin: rolename === 'ADMIN',
            isRoleLaborer: rolename === 'LABORER',
            isPickupLineAdmin: rolename === 'PICKUP_LINE_ADMIN',
        };
    };

    const mapDispatchToProps = (dispatch: Dispatch): Partial<WithUserProps> => ({
        handleLoadCurrentUser: () => dispatch(loadCurrent()),
    });

    return flow([
        connect(mapStateToProps, mapDispatchToProps),
    ])(WithUser);
};

export default withUser;
