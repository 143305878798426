import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {ErrorsFromBE, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadRequestedDataItemsError, loadRequestedDataItemsSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const errorTitle = 'Načtení vyžádaných datových položek';
    const response = yield* call(Api.getRequestedDataItems);

    if (!response.isSuccess) {
        const error = response.data as ErrorsFromBE;

        yield* call(handleResponseError, response, errorTitle);
        return yield* put(loadRequestedDataItemsError(error));
    }

    yield* put(loadRequestedDataItemsSuccess(response.data));
}

export function* loadRequestedDataItemsSaga(): SagaIterator {
    yield takeLatestF('measurement/LOAD_REQUSTED_DATA_ITEMS', execute);
}
