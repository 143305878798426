import React, {PureComponent, ReactNode, createRef} from 'react';
import {WrappedFieldArrayProps} from 'redux-form';
import {isEmpty} from 'lodash/fp';

import {SelectOptions, RefSelect} from 'forms/components/BasicSelect';
import {RefInput} from 'forms/components/Input';
import {Fields} from 'forms';
import {DataItemMeasurementFormValues} from 'measurement/type/DataItemMeasurementFormValues';
import {DataItemMeasurementsFormValues} from 'measurement/type/DataItemMeasurementsFormValues';
import {DataItem} from 'types/model/dataItems/DataItem';

import {Result} from './Result';
import {DilutionResult} from './DilutionResult';

export interface MeasurementResultsProps {
    name: keyof DataItemMeasurementsFormValues;
    sampleOptions: SelectOptions<number>;
    procedureOptions: SelectOptions<number>;
    selectedDataItem?: DataItem;
    isNP: boolean;
}

type Props = WrappedFieldArrayProps<DataItemMeasurementFormValues> & MeasurementResultsProps;

export class MeasurementResults extends PureComponent<Props> {
    private resultRefs: (RefInput | RefSelect<string>)[] = [];
    private dilutionRefs: RefInput[] = [];

    componentDidUpdate(prevProps: Props): void {
        const {selectedDataItem: prevSelectedDataItem} = prevProps;
        const {selectedDataItem} = this.props;

        if (prevSelectedDataItem !== selectedDataItem) {
            this.focusResult(0);
        }
    }

    render(): ReactNode {
        const {fields, selectedDataItem} = this.props;

        this.setupRefs();

        return <div>
            {isEmpty(fields) && !selectedDataItem && <div className="w-100 text-center text-muted">
                Vyberte datovu položku
            </div>}
            {fields.map((field, index) => this.renderMeasurement(field, index))}
        </div>;
    }

    private setupRefs(): void {
        const {fields} = this.props;

        fields.forEach((_, index) => {
            this.resultRefs[index] = this.resultRefs[index] || createRef();
            this.dilutionRefs[index] = this.dilutionRefs[index] || createRef();
        });
    }

    private focusNextResult = (index: number): void => {
        this.focusResult(index + 1);
    }

    private focusResult(index: number): void {
        this.resultRefs[index]?.current?.focus();
    }

    private focusNextDilution = (index: number): void => {
        this.dilutionRefs[index + 1]?.current?.focus();
    }

    private renderMeasurement(field: string, index: number): ReactNode {
        const {sampleOptions, procedureOptions, isNP} = this.props;
        const buildName = (key: keyof DataItemMeasurementFormValues) => `${field}.${key}`;

        return <div
            key={index}
            className="row mt-2"
        >
            <div className="col-lg-4">
                <Fields.Select<number>
                    name={buildName('sampleId')}
                    label="Vzorek"
                    options={sampleOptions}
                    disabled
                />
            </div>
            <div className="col-lg-2">
                <DilutionResult
                    name={buildName('dilution')}
                    rowIndex={index}
                    inputRef={this.dilutionRefs[index]}
                    onDilutionEnter={this.focusNextDilution}
                />
            </div>
            <div className="col-lg-2">
                <Result
                    name={buildName('result')}
                    rowIndex={index}
                    inputRef={this.resultRefs[index]}
                    onResultEnter={this.focusNextResult}
                    isNP={isNP}
                />
            </div>
            <div className="col-lg-4">
                <Fields.Select<number>
                    name={buildName('procedureId')}
                    label="Operační postup*"
                    options={procedureOptions}
                />
            </div>
        </div>;
    }
}
