import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga/';

import {PickupLineApi} from '../api';
import {DeletePickupLineAction, pickupLineAction} from '../actions';

const title = 'Odstranění svozové linky';

function* execute ({payload: {pickupLineId}}: DeletePickupLineAction): SagaIterator {
    const response = yield* call(PickupLineApi.deletePickupLine, pickupLineId);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLines());
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* deletePickupLineSaga(): SagaIterator {
    yield takeLatestF('pickupLine/DELETE_PICKUP_LINE', execute);
}
