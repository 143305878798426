import {SelectOptions} from 'forms/components/BasicSelect';
import {Opt} from 'ts-opt';

import {UserDetails} from 'types/model/users/UserDetails';

export const buildUserOptions = (users: Opt<UserDetails[]>): SelectOptions<number> =>
    users.orElse([]).map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
    }));
