import {takeLatestF} from 'favorlogic-utils';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';

import {formValuesF, touchF, blurF, resetF} from 'utils/formHelpers';

import {setAddingMilkRoomInProgress} from '../actions';
import {CreateSupplierFormValues} from '../types/CreateSupplierFormValues';

function* execute(): SagaIterator {
    const newMilkRoom = (yield* select(formValuesF('createMilkRoom')))
        .orCrash('missing milk room');
    const milkRooms = (yield* select(formValuesF('createSupplier')))
        .map((values: CreateSupplierFormValues) => values.milkRooms)
        .orElse([]);

    yield* put(blurF('createSupplier', 'milkRooms', milkRooms.concat(newMilkRoom)));
    yield* put(touchF('createSupplier', 'milkRooms'));
    yield* put(setAddingMilkRoomInProgress(false));
    yield* put(resetF('createMilkRoom'));
}

export function* addMilkRoomInSupplierCreationSaga(): SagaIterator {
    yield takeLatestF('supplier/ADD_MILK_ROOM_IN_SUPPLIER_CREATION', execute);
}
