import * as t from 'io-ts';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    name: t.string,
    description: t.string,
});

export const UpdateOperatingProcedureSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UpdateOperatingProcedure extends t.TypeOf<typeof UpdateOperatingProcedureSchema> {}
