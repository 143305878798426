import React, {FunctionComponent, ReactNode} from 'react';

import Page from '../Page';
import ItemPageHeader, {EditAction} from '../ItemPageHeader';

interface Props {
    title: ReactNode;
    backLabel: string;
    editAction?: EditAction;
    fluid?: boolean;
}

const ItemPage: FunctionComponent<Props> = props => {
    const {title, fluid, backLabel, children, editAction} = props;

    return (
        <Page fluid={fluid}>
            <ItemPageHeader
                title={title}
                backLabel={backLabel}
                editAction={editAction}
            />
            {children}
        </Page>
    );
};

export default ItemPage;
