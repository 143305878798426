exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-sample-components-UpdateSampleForm-___styles__btn___12-Q4, .src-modules-sample-components-UpdateSampleForm-___styles__form-control___2juxH {\n  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15); }\n\nbody {\n  overflow: visible;\n  height: 100%; }\n\nhtml, #src-modules-sample-components-UpdateSampleForm-___styles__app___2Q9r4 {\n  height: 100%; }\n\n.src-modules-sample-components-UpdateSampleForm-___styles__customTable___ua1vp, .src-modules-sample-components-UpdateSampleForm-___styles__addInfo___2RF78 {\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }\n\n.src-modules-sample-components-UpdateSampleForm-___styles__undefined___19H7y, #src-modules-sample-components-UpdateSampleForm-___styles__undefined___19H7y {\n  background-color: red;\n  color: lime;\n  border: 0.1em solid cyan; }\n  .src-modules-sample-components-UpdateSampleForm-___styles__undefined___19H7y::before, #src-modules-sample-components-UpdateSampleForm-___styles__undefined___19H7y::before {\n    content: 'UNDEFINED';\n    color: magenta;\n    width: 0; }\n\n.src-modules-sample-components-UpdateSampleForm-___styles__customTable___ua1vp {\n  background-color: white !important;\n  border-radius: 5px;\n  width: fit-content;\n  margin: auto;\n  white-space: nowrap;\n  overflow: auto;\n  max-width: 100%; }\n\n.src-modules-sample-components-UpdateSampleForm-___styles__scrollbarTop___11ZQO {\n  transform: rotateX(180deg) !important; }\n\n.src-modules-sample-components-UpdateSampleForm-___styles__infoIcon___2NF33 {\n  position: absolute;\n  top: 0;\n  right: 40px; }\n", ""]);

// exports
exports.locals = {
	"btn": "src-modules-sample-components-UpdateSampleForm-___styles__btn___12-Q4",
	"form-control": "src-modules-sample-components-UpdateSampleForm-___styles__form-control___2juxH",
	"app": "src-modules-sample-components-UpdateSampleForm-___styles__app___2Q9r4",
	"customTable": "src-modules-sample-components-UpdateSampleForm-___styles__customTable___ua1vp",
	"addInfo": "src-modules-sample-components-UpdateSampleForm-___styles__addInfo___2RF78",
	"undefined": "src-modules-sample-components-UpdateSampleForm-___styles__undefined___19H7y",
	"scrollbarTop": "src-modules-sample-components-UpdateSampleForm-___styles__scrollbarTop___11ZQO",
	"infoIcon": "src-modules-sample-components-UpdateSampleForm-___styles__infoIcon___2NF33"
};