import * as t from 'io-ts';

import {CreateSampleSchema} from '../samples/CreateSample';
import {SampleDeliveryTypeSchema} from '../enumeration/SampleDeliveryType';
import {CustomerSchema} from 'types/model/customers/Customer';

const optionalPart = t.partial({
    note: t.string,
});
const requiredPart = t.interface({
    deliveryType: SampleDeliveryTypeSchema,
    samples: t.array(CreateSampleSchema),
    customerDetails: CustomerSchema,
});

export const CreateOrderSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateOrder extends t.TypeOf<typeof CreateOrderSchema> {}
