import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {loadCustomerListSuccess} from '../actions';
import Api from '../api';

function* execute(): SagaIterator {
    const response = yield* call(Api.getCustomerList);

    if (!response.isSuccess) {
        const errorTitle = 'Načtení zákazníků';
        return yield* call(handleResponseError, response, errorTitle);
    }

    yield* put(loadCustomerListSuccess(response.data));
}

export function* loadCustomerListSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CUSTOMER_LIST', execute);
}
