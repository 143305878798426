import React, {PureComponent, ReactNode} from 'react';

import {OrderStats} from 'types/model/orders/OrderStats';
import {Panel} from 'layout/components';

import styles from './styles.sass';

export interface Props {
    stats: OrderStats;
}

class ShowOrderStats extends PureComponent<Props> {
    render(): ReactNode {
        const {stats} = this.props;

        return (
            <Panel className="mt-0">
                <div className="d-lg-none mt-5" />
                <div className="d-flex justify-content-between">
                    <span className={styles.sectionCaption}>Vzorků ke zpracování</span>
                    <span>{stats.samplesCount}</span>
                </div>
                <div className={styles.separator} />
                <div className="d-flex justify-content-between">
                    <span className={styles.sectionCaption}>Požadovaných rozborů</span>
                    <span>{stats.analysesCount}</span>
                </div>
            </Panel>
        );
    }
}

export default ShowOrderStats;
