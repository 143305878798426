import {SagaIterator} from 'redux-saga';
import {select, put} from 'typed-redux-saga';
import {opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';

import {loadMeasurements} from '../../actions';

export function* reloadMeasurements(): SagaIterator {
    const params = opt(yield* select((state: StoreState) => state.measurement.measurementsParams))
        .orCrash('missing measurements params');

    yield* put(loadMeasurements(params));
}
