import * as t from 'io-ts';

const requiredPart = t.interface({
    pickupLineRequestOptionId: t.number,
    count: t.number,
});

export const UpdatePickupLineDairyPlanDaySchema = t.exact(requiredPart);

export interface UpdatePickupLineDairyPlanDay extends t.TypeOf<typeof UpdatePickupLineDairyPlanDaySchema> {}
