import withForm from 'forms/components/withForm';

import {OwnProps, Props, WritePickupLineForm} from '../WritePickupLineForm';
import {asyncValidate, validate} from '../WritePickupLineForm/validations';
import {
    EDIT_PICKUP_FORM_NAME,
    initialWritePickupLineFormValues,
    WritePickupLineFormValues,
} from '../WritePickupLineForm/writePickupLineFormValues';

export const EditPickupLineForm = withForm<WritePickupLineFormValues, OwnProps, Props>(WritePickupLineForm, {
    form: EDIT_PICKUP_FORM_NAME,
    initialValues: initialWritePickupLineFormValues,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
});
