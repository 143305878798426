import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {CreateProtocolSchema} from 'types/model/protocols/CreateProtocol';

const optionalPart = t.partial({
    approvedBy: t.string,
    fileKey: t.string,
});
const requiredPart = t.interface({
    id: IntegerSchema,
    created: DateStringSchema,
    version: IntegerSchema,
    approved: t.boolean,
    createdBy: t.string,
});

export const ProtocolDetailsSchema = t.exact(t.intersection([CreateProtocolSchema.type, optionalPart, requiredPart]));

export interface ProtocolDetails extends t.TypeOf<typeof ProtocolDetailsSchema> {}
