import {Opt, opt} from 'ts-opt';

type FormName = string;

export type AsyncValidateFn = (name?: string, value?: unknown, trigger?: 'blur') => void;

export interface FormType {props: {asyncValidate: AsyncValidateFn}}

export class FormDatabase {
    private readonly data: Record<FormName, FormType> = {};

    register(name: FormName, formComponent: FormType): void {
        this.data[name] = formComponent;
    }

    get(name: FormName): Opt<FormType> {
        return opt(this.data[name]);
    }
}

export const formDatabase = new FormDatabase();
