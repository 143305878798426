import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {DicSchema} from 'types/generic/DicSchema';
import {EmailSchema} from 'types/generic/EmailSchema';
import {IcoSchema} from 'types/generic/IcoSchema';

const optionalPart = t.partial({
    name: t.string,
    email: EmailSchema,
    ico: IcoSchema,
    dic: DicSchema,
    supplierId: IntegerSchema,
});
const requiredPart = t.interface({});

export const SupplierValidationSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface SupplierValidation extends t.TypeOf<typeof SupplierValidationSchema> {}
