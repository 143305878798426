import React, {Fragment, FunctionComponent} from 'react';
import {find, range} from 'lodash/fp';

import {Components as Icons} from 'icons';
import {opt} from 'ts-opt';
import {PAGE_SIZE_VALUES} from '../../constants';
import {classNames} from 'favorlogic-utils';
import BasicSelect, {SelectOptions} from 'forms/components/BasicSelect';

import chevronLeftSvg from './chevronLeft.svg';
import chevronRightSvg from './chevronRight.svg';
import chevronsLeftSvg from './chevronsLeft.svg';
import chevronsRightSvg from './chevronsRight.svg';

import styles from './styles.sass';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    pagePadding: number;
    pageSize?: number;
    className?: string;

    onPageChange(id: number): void;

    onPageSizeChange(value: number): void;
}

const Pagination: FunctionComponent<PaginationProps> = props => {
    const {
        currentPage,
        totalPages,
        pageSize,
        pagePadding,
        className,
        onPageChange,
        onPageSizeChange,
    } = props;

    const start = currentPage - pagePadding > 0 ? currentPage - pagePadding : 1;
    const end = currentPage + pagePadding < totalPages ? currentPage + pagePadding : totalPages;
    const pages = range(start, end + 1);

    const pageSizeOptions: SelectOptions<number> = PAGE_SIZE_VALUES.map((x: number) =>
        ({label: x.toString(), value: x}));

    const selectedOption = find(x => x.value === pageSize, pageSizeOptions);
    const selectedOptionValue: number | null = opt(selectedOption).chainToOpt(x => x.value).orNull();

    return (
        <Fragment>
            <nav className={styles.pagination}>
                <BasicSelect
                    name="pagination"
                    options={pageSizeOptions}
                    onChange={onPageSizeChange}
                    value={selectedOptionValue}
                    clearable={false}
                    searchable={false}
                    openUp
                />
            </nav>
            {end > 1 &&
            <nav className={styles.pagination}>
                <ul className={classNames(className, 'pagination')}>
                    <li className={classNames('page-item', currentPage === 1 && 'disabled')}>
                        <button
                            className="page-link"
                            onClick={() => onPageChange(0)}
                            type="submit"
                            disabled={currentPage === 1}
                        >
                            <Icons.SvgIcon icon={chevronsLeftSvg} />
                        </button>
                    </li>

                    <li className={classNames('page-item', currentPage === 1 && 'disabled')}>
                        <button
                            className="page-link"
                            onClick={() => onPageChange(currentPage - 2)}
                            type="submit"
                            disabled={currentPage === 1}
                        >
                            <Icons.SvgIcon icon={chevronLeftSvg} />
                        </button>
                    </li>

                    {pages[0] > 1 &&
                    <li className="page-item disabled">
                        <div className="page-link">
                            ...
                        </div>
                    </li>}

                    {pages.map(page =>
                        <li
                            className={classNames('page-item', page === currentPage && styles.active)}
                            key={page}
                        >
                            <button
                                className="page-link"
                                onClick={() => onPageChange(page - 1)}
                                type="submit"
                            >
                                {page}
                            </button>
                        </li>)}

                    {pages[pages.length - 1] < totalPages &&
                    <li className="page-item disabled">
                        <button className="page-link">
                          ...
                        </button>
                    </li>}

                    <li className={classNames('page-item', currentPage === totalPages && 'disabled')}>
                        <button
                            className="page-link"
                            onClick={() => onPageChange(currentPage)}
                            type="submit"
                            disabled={currentPage === totalPages}
                        >
                            <Icons.SvgIcon icon={chevronRightSvg} />
                        </button>
                    </li>

                    <li className={classNames('page-item', currentPage === totalPages && 'disabled')}>
                        <button
                            className="page-link"
                            onClick={() => onPageChange(totalPages - 1)}
                            type="submit"
                            disabled={currentPage === totalPages}
                        >
                            <Icons.SvgIcon icon={chevronsRightSvg} />
                        </button>
                    </li>
                </ul>
            </nav>
            }
        </Fragment>
    );
};

export default Pagination;
