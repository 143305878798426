import {debounce, flow, map, find, isEqual} from 'lodash/fp';
import React, {PureComponent, ReactNode, RefObject, KeyboardEvent} from 'react';
import ReactSelectClass, {
    LoadOptionsLegacyHandler,
    Async as ReactSelect,
    Option,
    Options,
    AutocompleteResult,
} from 'react-select';
import {opt} from 'ts-opt';
import {classNames, isArray, rejectUndefined} from 'favorlogic-utils';

import HelpText from '../HelpText';

import 'react-select/dist/react-select.css';
import styles from './styles.sass';

export type RefSelect<T> = RefObject<ReactSelect<T> & ReactSelectClass<T>>;

export interface SelectOption<T> {
    label: string;
    value: T;
    tagLabel?: string;
    title?: string;
    clearableValue?: boolean;
}

export type SelectOptions<T> = SelectOption<T>[];
export type SelectedValue<T> = T | T[] | null;

type SelectedOption<T> = Option<T> | Options<T>;

export type LoadOptionsCallback<T> =
    (error: string | null, data: {options: Readonly<SelectOptions<T>>, complete: boolean}) => void;

interface Props<OptionT> {
    name?: string;
    className?: string;
    value: SelectedValue<OptionT>;
    async?: boolean;
    debounceTime?: number;
    asyncMinLength?: number;
    options: Readonly<SelectOptions<OptionT>>;
    multi?: boolean;
    label?: string;
    helpText?: string;
    disabled?: boolean;
    searchable?: boolean;
    clearable?: boolean;
    noBorder?: boolean;
    inForm?: boolean;
    openUp?: boolean;
    autoFocus?: boolean;
    selectRef?: RefSelect<OptionT>;
    noMargin?: boolean;

    loadOptions?(filter: string, cb: LoadOptionsCallback<OptionT>): void;

    onChange?(_: SelectedValue<OptionT>): void;

    onBlur?(_: SelectedValue<OptionT>): void;

    onEnter?(): void;

    onFocus?(_: SelectedValue<OptionT>): void;
}

class BasicSelect<OptionT> extends PureComponent<Props<OptionT>> {
    static getValue<T>(option: SelectedOption<T> | null): SelectedValue<T> {
        if (!option) {
            return null;
        } else if (isArray(option)) {
            return flow(
                map((o: Option<T>) => o.value),
                rejectUndefined,
            )(option);
        } else {
            return opt(option.value).orNull();
        }
    }

    handleLoadOptions = (value: string, cb: LoadOptionsCallback<OptionT>): void => {
        const {loadOptions, async, options} = this.props;
        if (async) {
            if (!loadOptions) {
                throw new Error('async without loadOptions');
            }
            loadOptions(value, cb);
        } else {
            setTimeout(() => cb(null, {options, complete: false}));
        }
    }

    render(): ReactNode {
        const {
            className,
            name,
            value,
            label,
            helpText,
            options,
            disabled,
            multi,
            debounceTime,
            asyncMinLength,
            onChange,
            searchable,
            clearable,
            onBlur,
            inForm,
            noBorder,
            openUp,
            autoFocus,
            onEnter,
            selectRef,
            onFocus,
            noMargin,
        } = this.props;

        if (multi && !isArray(value)) {
            const str = JSON.stringify(value);
            const typ = typeof value;
            // eslint-disable-next-line no-console
            console.warn(`Value of a select component in multi mode must always be an array. Was ${typ} - '${str}'.`);
        }

        const openUpClass = openUp ? styles.openUp : '';
        const noBorderClass = noBorder ? styles.noBorder : '';
        const inFormClass = inForm ? `w-100 form-group ${styles.inForm}` : styles.standalone;
        const classes = classNames(
            className,
            styles.select,
            inFormClass,
            openUpClass,
            noBorderClass,
            noMargin && styles.noMargin,
        );

        const debouncedHandleLoadOptions = debounce(debounceTime || 500, this.handleLoadOptions);
        const handleLoadOptionsTyped = (value: string, cb: LoadOptionsCallback<OptionT>) => {
            if (value.length < (asyncMinLength || 3)) {
                setTimeout(() => cb(null, {options, complete: false}));
            } else {
                debouncedHandleLoadOptions(value, cb);
            }
        };
        const handleLoadOptions: LoadOptionsLegacyHandler<OptionT> =
            (input: string, callback: (err: string | null, result: AutocompleteResult<OptionT>) => void) => {
                const cb: LoadOptionsCallback<OptionT> =
                    (error: string | null, data: {options: Readonly<SelectOptions<OptionT>>, complete: boolean}) => {
                        callback(error, {
                            options: [...data.options],
                            complete: data.complete,
                        });
                    };
                handleLoadOptionsTyped(input, cb);
            };

        const handleChange = (option: SelectedOption<OptionT> | null): void => {
            if (onChange) {
                onChange(BasicSelect.getValue(option));
            }
        };

        const handleBlur = () => {
            // react-select doesn't support blur (the handler is called by input or div and event, not value, is passed)
            // there seems to be a bug when input.value is empty it sometimes returns ''
            if (onBlur) {
                onBlur(value as unknown === '' ? null : value);
            }
        };

        const handleFocus = () => {
            if (onFocus) {
                onFocus(value as unknown === '' ? null : value);
            }
        };

        const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && onEnter) {
                onEnter();
            }
        };

        const valueRenderer = (x: Option<OptionT>) => <span title={x.title}>{x.tagLabel ?? x.label}</span>;

        return (
            <div className={classes}>
                <HelpText>
                    {helpText}
                </HelpText>

                <ReactSelect
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    options={[...options]}
                    placeholder={label}
                    disabled={disabled}
                    multi={multi}
                    loadOptions={handleLoadOptions}
                    clearable={clearable}
                    searchable={searchable}
                    value={this.getCurrentOption(value)}
                    autoFocus={autoFocus}
                    onInputKeyDown={handleKeydown}
                    ref={selectRef}
                    valueRenderer={valueRenderer}
                />
            </div>
        );
    }

    getCurrentOption(value: SelectedValue<OptionT>): SelectedOption<OptionT> | undefined {
        const {options} = this.props;
        const valueToOption = (v: OptionT) => find(x => isEqual(x.value, v), options);

        if (!value) {
            return;
        } else {
            if (isArray(value)) {
                return map(valueToOption, value);
            } else {
                return valueToOption(value);
            }
        }
    }
}

export default BasicSelect;
