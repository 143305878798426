import {
    each, flatten, flow, isFunction, isObject, map, tap, toPairs,
} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {all, Effect, put} from 'redux-saga/effects';
import {forkRestartingOnError, showError, Saga} from 'favorlogic-utils';

import {Sagas as AnalysisSagas} from 'analysis';
import {Sagas as FormsSagas} from 'forms';
import {Sagas as OrderSagas} from 'order';
import {Sagas as SampleSagas} from 'sample';
import {Sagas as SupplierSagas} from 'supplier';
import {Sagas as SupplyChainsSagas} from 'supplyChain';
import {Sagas as UserSagas} from 'user';
import {Sagas as DownloadSagas} from 'download';
import {Sagas as ConfirmDialogSagas} from 'confirmDialog';
import {Sagas as MeasurementSagas} from 'measurement';
import {Sagas as OperatingProcedureSagas} from 'operatingProcedure';
import {Sagas as DataItemSagas} from 'dataItem';
import {Sagas as DeviceSagas} from 'device';
import {Sagas as AdministrationSagas} from 'administration';
import {pickupLinesSagas} from 'pickupLine';

import logger from '../model/Logger';

type SagaModule = Record<string, Saga>;

function* handleSagaError(_sagaName: string, error: Error): SagaIterator {
    logger.logError(error);
    yield put(showError('V aplikaci nastala chyba.', 'Omlouváme se za způsobené potíže.'));
}

const validateInput = each((obj?: object) => {
    if (!isObject(obj)) {
        throw new Error(`Expected an array of objects, but found an item of type ${typeof obj}.`);
    }
});
const validateNameSagaPair = ([name, saga]: [string, unknown]) => {
    if (!isFunction(saga)) { throw new Error(`The key ${name} is not a saga.`); }
};

const process: (sagasModules: SagaModule[]) => SagaIterator[] = flow([
    tap(validateInput),
    map(toPairs),
    flatten,
    tap(each(validateNameSagaPair)),
    map(([, saga]: [string, Saga]) => forkRestartingOnError(saga, handleSagaError)),
]);

const sagas: SagaModule[] = [
    UserSagas,
    SupplierSagas,
    OrderSagas,
    SupplyChainsSagas,
    AnalysisSagas,
    SampleSagas,
    FormsSagas,
    DownloadSagas,
    ConfirmDialogSagas,
    MeasurementSagas,
    OperatingProcedureSagas,
    DataItemSagas,
    DeviceSagas,
    AdministrationSagas,
    pickupLinesSagas,
];

export default function* combineSagas(): SagaIterator {
    yield all(process(sagas)) as Effect;
}
