exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-layout-components-LoaderWrapper-___styles__wrapper___1k3lf {\n  width: 100%;\n  min-height: 100px;\n  position: relative; }\n\n.src-modules-layout-components-LoaderWrapper-___styles__blur___1RdN7 {\n  -webkit-filter: blur(5px);\n          filter: blur(5px); }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-modules-layout-components-LoaderWrapper-___styles__wrapper___1k3lf",
	"blur": "src-modules-layout-components-LoaderWrapper-___styles__blur___1RdN7"
};