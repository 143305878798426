import * as t from 'io-ts';

import {PriceClassSchema} from '../enumeration/PriceClass';
import {UpdateCustomerBaseSchema} from './UpdateCustomerBase';
import {CustomerCodeSchema} from 'types/generic/CustomerCodeSchema';

const optionalPart = t.partial({
    code: CustomerCodeSchema,
    note: t.string,
});
const requiredPart = t.interface({
    priceClass: PriceClassSchema,
});

export const UpdateCustomerByLaborerBaseSchema = t.exact(t.intersection([
    UpdateCustomerBaseSchema.type,
    optionalPart,
    requiredPart,
]));

export interface UpdateCustomerByLaborerBase extends t.TypeOf<typeof UpdateCustomerByLaborerBaseSchema> {}
