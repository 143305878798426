import React, {Fragment, FunctionComponent, useCallback, useEffect} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {none, opt, Opt} from 'ts-opt';
import {concat, find, flow, isEmpty, map} from 'lodash/fp';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {PickupLine} from 'types/model/pickupLine/PickupLine';
import {RightIconButton} from 'buttons/components';
import {LoaderWrapper, Modal, NoData} from 'layout/components';
import {toggleModalVisibility} from 'layout/actions';
import {isModalVisibleSelector} from 'layout/reducer';
import {initializeF} from 'utils/formHelpers';
import {WritePickupLine} from 'types/model/pickupLine/WritePickupLine';
import {Customer} from 'types/model/pickupLine/Customer';
import {SelectOptions} from 'forms/components/BasicSelect';
import {withUser} from 'user/components';

import {pickupLineAction} from '../actions';
import {PickupLinesTables} from '../components/PickupLinesTables';
import {CreatePickupLineForm} from '../components/CreatePickupLineForm';
import {EditPickupLineForm} from '../components/EditPickupLineForm';
import {
    CREATE_PICKUP_FORM_NAME,
    initialWritePickupLineFormValues,
    WritePickupLineFormValues,
} from '../components/WritePickupLineForm/writePickupLineFormValues';
import {PickupLineLoading} from '../types/PickupLineLoading';

const renderToolbar = (toggleCreatePickupLineModalVisibility: () => void) =>
    <RightIconButton
        label="Nová svozová linka"
        onClick={toggleCreatePickupLineModalVisibility}
        icon="ADD"
    />
;

const getCustomerOptions = (
    unassignedDairies: Customer[],
    pickupLines: Opt<PickupLine[]> = none,
    pickupLineId?: number,
): SelectOptions<number> => {
    const assignedCustomersToPickupLine: SelectOptions<number> = pickupLines
        .chainToOpt(find(x => x.id === pickupLineId))
        .prop('customers')
        .map(map(
            y => ({value: y.id, label: y.name})
        )).orElse([]);
    const unassignedCustomerOptions: SelectOptions<number> = unassignedDairies.map(
        x => ({value: x.id, label: x.name, clearableValue: true})
    );

    return concat(assignedCustomersToPickupLine, unassignedCustomerOptions);
};

const getEditFormInitialValues = (
    pickupLines: Opt<PickupLine[]>,
    pickupLineId: Opt<number>
): WritePickupLineFormValues =>
    pickupLines
        .map(find((x) => x.id === pickupLineId.orUndef()))
        .map((x) => ({
            name: x?.name || '',
            customerIds: x?.customers.map((x) => x.id) || [],
            color: x?.color || '',
        })).orElse({name: '', customerIds: [], color: ''});

interface StateProps {
    loading: PickupLineLoading[];
    pickupLines: Opt<PickupLine[]>;
    unassignedDairies: Opt<Customer[]>;
    createPickupLineModalVisible: boolean;
    editPickupLineModalVisible: boolean;
    pickupLineIdInEdit: Opt<number>;
}

interface DispatchProps {
    getPickupLines(): void;
    toggleCreatePickupLineModalVisibility(): void;
    postPickupLine(data: WritePickupLine): void;
    getUnassignedDairies(): void;
    deletePickupLine(pickupLineId: number): void;
    toggleEditPickupLineModalVisibility(): void;
    updatePickupLine(pickupLineId: number, data: WritePickupLine): void;
    setPickupLineIdInEdit(pickupLineId?: number): void;
    setPickupLineEditFormValues(values: WritePickupLineFormValues): void;
}

type Props = StateProps & DispatchProps;

const PickupLinesBase: FunctionComponent<Props> = (props) => {
    const {
        loading,
        pickupLines,
        unassignedDairies,
        createPickupLineModalVisible,
        editPickupLineModalVisible,
        pickupLineIdInEdit,
        getPickupLines,
        getUnassignedDairies,
        toggleCreatePickupLineModalVisibility,
        postPickupLine,
        deletePickupLine,
        toggleEditPickupLineModalVisibility,
        updatePickupLine,
        setPickupLineIdInEdit,
        setPickupLineEditFormValues,
    } = props;

    useEffect(() => {
        getPickupLines();
    }, [getPickupLines]);

    useEffect(() => {
        if (pickupLines.nonEmpty) {
            getUnassignedDairies();
        }
    }, [pickupLines, getUnassignedDairies]);

    useEffect(() => {
        if (pickupLineIdInEdit.nonEmpty) {
            setPickupLineEditFormValues(getEditFormInitialValues(pickupLines, pickupLineIdInEdit));
        }
    }, [pickupLines, pickupLineIdInEdit, setPickupLineEditFormValues]);

    const toggleAddCustomerModalVisibility = useCallback((pickupLineId: number) => {
        toggleEditPickupLineModalVisibility();
        setPickupLineEditFormValues(getEditFormInitialValues(pickupLines, opt(pickupLineId)));
        setPickupLineIdInEdit(pickupLineId);
    }, [pickupLines, toggleEditPickupLineModalVisibility, setPickupLineIdInEdit]);

    const onCreateSubmit = useCallback((data: WritePickupLine) => {
        postPickupLine(data);
        toggleCreatePickupLineModalVisibility();
    }, [postPickupLine, toggleCreatePickupLineModalVisibility]);

    const onEditSubmit = useCallback((data: WritePickupLine) => {
        if (pickupLineIdInEdit.nonEmpty) {
            updatePickupLine(pickupLineIdInEdit.orCrash('pickup line id not available'), data);
            toggleEditPickupLineModalVisibility();
            setPickupLineIdInEdit();
        }
    }, [pickupLineIdInEdit, postPickupLine, toggleEditPickupLineModalVisibility, setPickupLineIdInEdit]);

    const onEditFormClickAway = useCallback(() => {
        toggleEditPickupLineModalVisibility();
        setPickupLineIdInEdit();
    }, [toggleEditPickupLineModalVisibility, setPickupLineIdInEdit]);

    return (
        <Fragment>
            <Modal.Blurer>
                <Layout.ListPage
                    title="Svozové linky"
                    toolbar={renderToolbar(toggleCreatePickupLineModalVisibility)}
                >
                    <LoaderWrapper showLoader={loading.includes('administration')}>
                        {pickupLines.map(isEmpty).orTrue()
                            ? <NoData
                                title="Nemáte prozatím žádné svozové linky."
                                text='Chcete vytvořit novou svozovou linku? Klikněte na tlačítko "Nová svozová linka".'
                            />
                            : <PickupLinesTables
                                pickupLines={pickupLines}
                                toggleAddCustomerModalVisibility={toggleAddCustomerModalVisibility}
                                deletePickupLine={deletePickupLine}
                                toggleEditPickupLineModalVisibility={toggleEditPickupLineModalVisibility}
                                setPickupLineIdInEdit={setPickupLineIdInEdit}
                                updatePickupLine={updatePickupLine}
                            />
                        }
                    </LoaderWrapper>
                </Layout.ListPage>
            </Modal.Blurer>
            <Modal.Container
                show={createPickupLineModalVisible}
                onClickAway={toggleCreatePickupLineModalVisibility}
            >
                <CreatePickupLineForm
                    customerOptions={getCustomerOptions(unassignedDairies.orElse([]))}
                    onSubmit={onCreateSubmit}
                />
            </Modal.Container>
            <Modal.Container
                show={editPickupLineModalVisible}
                onClickAway={onEditFormClickAway}
            >
                <EditPickupLineForm
                    customerOptions={getCustomerOptions(
                        unassignedDairies.orElse([]),
                        pickupLines,
                        pickupLineIdInEdit.orUndef(),
                    )}
                    onSubmit={onEditSubmit}
                    pickupLineIdInEdit={pickupLineIdInEdit.orUndef()}
                />
            </Modal.Container>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getPickupLines: () => dispatch(pickupLineAction.getPickupLines()),
    toggleCreatePickupLineModalVisibility: () => {
        dispatch(toggleModalVisibility('createPickupLine'));
        dispatch(initializeF(CREATE_PICKUP_FORM_NAME, initialWritePickupLineFormValues));
    },
    postPickupLine: (data: WritePickupLine) => dispatch(pickupLineAction.postPickupLine(data)),
    getUnassignedDairies: () => dispatch(pickupLineAction.getUnassignedDairies()),
    deletePickupLine: (pickupLineId: number) => dispatch(pickupLineAction.deletePickupLine(pickupLineId)),
    toggleEditPickupLineModalVisibility: () => dispatch(toggleModalVisibility('editPickupLine')),
    updatePickupLine: (pickupLineId: number, data: WritePickupLine) =>
        dispatch(pickupLineAction.updatePickupLine(pickupLineId, data)),
    setPickupLineIdInEdit: (pickupLineId?: number) => dispatch(pickupLineAction.setPickupLineIdInEdit(pickupLineId)),
    setPickupLineEditFormValues: (values: WritePickupLineFormValues) =>
        dispatch(pickupLineAction.setPickupLineEditFormValues(values)),
});

const mapStateToProps = (state: StoreState): StateProps => ({
    loading: state.pickupLine.loading,
    pickupLines: state.pickupLine.pickupLines,
    unassignedDairies: state.pickupLine.unassignedDairies,
    createPickupLineModalVisible: isModalVisibleSelector(state.layout, 'createPickupLine'),
    editPickupLineModalVisible: isModalVisibleSelector(state.layout, 'editPickupLine'),
    pickupLineIdInEdit: state.pickupLine.pickupLineIdInEdit,
});

export const PickupLines = flow(
    connect(mapStateToProps, mapDispatchToProps),
    withUser,
)(PickupLinesBase);
