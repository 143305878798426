import {OperatingProcedure} from 'types/model/operatingProcedures/OperatingProcedure';
import {OperatingProcedureAction} from './actions';

export interface OperatingProcedureState {
    operatingProcedures: OperatingProcedure[] | null;
    operatingProcedure: OperatingProcedure | null;
}

export const initialOperatingProcedureState: OperatingProcedureState = {
    operatingProcedures: null,
    operatingProcedure: null,
};

const reducer = (
    state: OperatingProcedureState | undefined = initialOperatingProcedureState,
    action: OperatingProcedureAction
): OperatingProcedureState => {
    switch (action.type) {
        case 'operatingProcedure/LOAD_OPERATING_PROCEDURES_SUCCESS': {
            return {...state, operatingProcedures: action.payload};
        }

        case 'operatingProcedure/LOAD_OPERATING_PROCEDURE_SUCCESS': {
            return {...state, operatingProcedure: action.payload};
        }

        default:
            return state;
    }
};

export default reducer;
