import {History} from 'history';
import {filter, flatten, flow, map, sum} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';
import {formatCurrency} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {Components as Layout} from 'layout';
import {CreateOrderFormValues} from 'order/types/CreateOrderFormValues';
import {CreateSample} from 'types/model/samples/CreateSample';
import withUser, {WithUserProps} from 'user/components/withUser';
import {createOrder, setLastCreatedSample} from '../actions';
import CustomerDetailsView from '../components/CustomerDetailsView';
import {sampleDeliveryTypeOptions} from '../enums/SampleDeliveryTypeEnum';
import SamplesTable from 'order/components/SamplesTable';
import {formValuesF, destroyF, resetF} from 'utils/formHelpers';
import LeavePage from 'layout/components/LeavePage';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {Analysis} from 'types/model/analyses/Analysis';
import {LEAVE_CREATING_ORDER_MESSAGE} from '../constants';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';

const NON_CONTRACTUAL_FEE = 40;

interface OuterProps {
}

interface InnerProps {
    analyses: Analysis[];
    history: History;
    customerMilkRooms: MilkRoom[] | null;
    milkRooms: MilkRoom[];
    createOrderFormValue: Opt<CreateOrderFormValues>;
    isSubmitting: boolean;
    supplyChains: SupplyChain[];
    customerForOrder: CustomerDetailsForLaborer | null;

    handleCreateOrder(isRoleAdminOrLaborer: boolean): void;

    handleResetCreateOrderForm(): void;
}

type UserProps = Pick<WithUserProps,
'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentCustomer'
>
type Props = InnerProps & OuterProps & UserProps;

class Summary extends Component<Props> {
    private get isNonContractual(): boolean {
        const {currentCustomer, customerForOrder, isRoleAdmin, isRoleLaborer} = this.props;
        const customer = isRoleAdmin || isRoleLaborer ? opt(customerForOrder) : currentCustomer;

        return customer.map(c => c.priceClass === 'NON_CONTRACTUAL').orFalse();
    }

    componentDidMount() {
        const {createOrderFormValue, history, isRoleAdmin, isRoleLaborer} = this.props;
        const order = createOrderFormValue.orNull();

        if (!order || !order.customerDetails) {
            history.push('/orders');
            history.push(isRoleAdmin || isRoleLaborer ? '/orders/new/orderCustomer' : '/orders/new');
        }
    }

    render(): ReactNode {
        const {
            handleCreateOrder,
            analyses,
            customerMilkRooms,
            milkRooms,
            createOrderFormValue,
            isSubmitting,
            supplyChains,
            isRoleAdmin,
            isRoleLaborer,
            isRoleDairy,
            isRoleDairyEmployee,
            handleResetCreateOrderForm,
        } = this.props;

        const isRoleAdminOrLaborer = isRoleAdmin || isRoleLaborer;
        const isDairy = isRoleDairy || isRoleDairyEmployee;
        const availableMilkRooms = isRoleAdminOrLaborer ? customerMilkRooms : milkRooms;
        const order = createOrderFormValue.orNull();

        if (!order || !order.customerDetails) {
            return null;
        }

        return (
            <Layout.Page>
                <h1 className="text-center">Potvrzení objednávky</h1>
                <Layout.Panel className="p-4">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mb-3"><strong>Údaje o objednávce</strong></div>
                            <ShowField
                                label="Způsob doručení"
                                value={order.deliveryType}
                                type={ShowFieldType.SelectOption}
                                selectOptions={sampleDeliveryTypeOptions}
                            />
                            {order.note && <ShowField
                                label="Poznámka"
                                value={order.note}
                                type={ShowFieldType.String}
                            />}
                        </div>
                        <div className="col-md-7">
                            <CustomerDetailsView customer={order.customerDetails} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pb-3">
                            <h5 className="mt-3">Údaje o vzorcích</h5>
                            <SamplesTable
                                samples={order.samples}
                                mode="VIEW"
                                milkRooms={availableMilkRooms}
                                analyses={analyses}
                                supplyChains={supplyChains}
                                showSupplyChainCode={isDairy}
                            />
                        </div>
                    </div>
                    <div className="border-bottom text-right">
                        <h4 className="pt-3 pb-3 text-primary">
                            Předpokládaná cena objednávky: {this.getTotalOrderPrice(order.samples, analyses)}
                        </h4>
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        <span className="mr-3">
                            <Buttons.BackButton
                                importance="secondary"
                                label="Zpět"
                            />
                        </span>
                        <Buttons.Button
                            importance="primary"
                            type="button"
                            onClick={() => handleCreateOrder(isRoleAdminOrLaborer)}
                            disabled={isSubmitting}
                        >
                            Potvrdit objednávku
                        </Buttons.Button>
                    </div>
                </Layout.Panel>
                <LeavePage
                    canLeave={false}
                    except={['/orders/new', '/orders/new/orderInfo', '/orders/new/orderCustomer']}
                    afterLeave={handleResetCreateOrderForm}
                    message={LEAVE_CREATING_ORDER_MESSAGE}
                />
            </Layout.Page>
        );
    }

    private getTotalOrderPrice(samples: CreateSample[], analyses: Analysis[]): string {
        const totalPrice = flow(
            map((x: CreateSample) => this.getSamplePrice(x, analyses)),
            sum,
            (x: number) => this.isNonContractual ? x + NON_CONTRACTUAL_FEE : x,
        )(samples);

        return formatCurrency(totalPrice);
    }

    private getSamplePrice(sample: CreateSample, analyses: Analysis[]): number {
        const price = flow(
            map((a: number) => filter((x: Analysis) => x.id === a, analyses)),
            flatten,
            map(a => a.price),
            sum,
        )(sample.analysisIds);

        return price;
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    createOrderFormValue: formValuesF('createOrder')(state),
    analyses: state.analysis.analyses || [],
    milkRooms: state.supplier.milkRooms || [],
    customerMilkRooms: state.user.customerMilkRooms.orUndef(),
    isSubmitting: state.layout.isLoading,
    supplyChains: state.supplyChain.supplyChains || [],
    customerForOrder: state.order.customer,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleCreateOrder: (isRoleAdminOrLaborer: boolean) => dispatch(createOrder(isRoleAdminOrLaborer)),
    handleResetCreateOrderForm: () => {
        dispatch(destroyF('createOrder'));
        dispatch(resetF('orderCustomer'));
        dispatch(setLastCreatedSample(null));
    },
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Summary) as ComponentClass<OuterProps>;
