import {find} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {Opt, opt} from 'ts-opt';
import {formatTemperatureEn, classNames, formatDate, formatTime} from 'favorlogic-utils';

import {SelectOptions} from '../BasicSelect';

import styles from './styles.sass';

export type Color = 'dangerous';

export enum ShowFieldType {
    String,
    Date,
    Time,
    SelectOption,
    Number,
    Temperature,
}

interface GeneralProps {
    type: ShowFieldType;
    label?: string;
    className?: string;
    tooltip?: string;
    color?: Color;
    bold?: boolean;
}

interface StringProps extends GeneralProps {
    type: ShowFieldType.String;
    value: string | undefined;
}

interface DateProps extends GeneralProps {
    type: ShowFieldType.Date;
    value: string | undefined;
}

interface TimeProps extends GeneralProps {
    type: ShowFieldType.Time;
    value: string | undefined;
}

interface SelectOptionProps extends GeneralProps {
    type: ShowFieldType.SelectOption;
    value: unknown | undefined;
    selectOptions: Readonly<SelectOptions<unknown>>;
}

interface TemperatureProps extends GeneralProps {
    type: ShowFieldType.Temperature;
    value: number | undefined;
}

interface NumberProps extends GeneralProps {
    type: ShowFieldType.Number;
    value: number | undefined;
}

type Props = StringProps | DateProps | TimeProps | SelectOptionProps | NumberProps | TemperatureProps;

class ShowField extends Component<Props> {
    render(): ReactNode {
        const {label, className, tooltip, color, bold} = this.props;
        const classes = classNames('form-group', 'w-100', 'position-relative', className);
        const valueClasses = classNames(color ? styles[color] : undefined, bold && 'font-weight-bold');
        return this.renderValue().map(value =>
            <div
                className={classes}
                title={tooltip}
            >
                <div className={styles.label}>{label}</div>
                <div className={valueClasses}>{value}</div>
            </div>,
        ).orNull();
    }

    private renderValue(): Opt<ReactNode> {
        const {props} = this;
        switch (props.type) {
            case ShowFieldType.String:
            case ShowFieldType.Number: {
                return opt(props.value);
            }
            case ShowFieldType.Date: {
                return opt(props.value).flatMap(formatDate);
            }
            case ShowFieldType.Time: {
                return opt(props.value).flatMap(formatTime);
            }
            case ShowFieldType.SelectOption: {
                return opt(find(x => x.value === props.value, props.selectOptions)).map(x => x.label);
            }
            case ShowFieldType.Temperature: {
                return opt(props.value).chainToOpt(formatTemperatureEn);
            }
            default: { throw new Error(`Unknown type. ${JSON.stringify(props)}`); }
        }
    }
}

export default ShowField;
