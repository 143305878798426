import {last} from 'lodash/fp';
import * as O from 'optics-ts';
import {toggleItem} from 'favorlogic-utils';

import {ModalId} from 'app/types/ModalId';

import {LayoutAction} from './actions';

export interface LayoutState {
    menuOpen: boolean;
    canLeavePage: boolean;
    nextLocation: string;
    isLoading: boolean;
    modalStack: ModalId[]; // ordered list of opened modals, the last is the one currently visible
}

type State = LayoutState;

export const initialLayoutState: State = {
    menuOpen: false,
    canLeavePage: true,
    nextLocation: '',
    isLoading: false,
    modalStack: [],
};

export const isModalVisibleSelector = (state: State, id: ModalId): boolean => last(state.modalStack) === id;

const modalStackO = O.optic<State>().prop('modalStack');

const reducer = (state: State | undefined = initialLayoutState, action: LayoutAction): State => {
    switch (action.type) {
        case 'layout/TOGGLE_MENU': {
            const menuOpen = state ? !state.menuOpen : false;
            return {...state, menuOpen};
        }
        case 'layout/CAN_LEAVE_PAGE': {
            return {...state, canLeavePage: action.payload};
        }
        case 'layout/SET_NEXT_LOCATION': {
            return {...state, nextLocation: action.payload};
        }
        case 'layout/IS_LOADING': {
            return {...state, isLoading: action.payload};
        }
        case 'layout/SET_MODAL_VISIBILITY': {
            const {id, visibility} = action.payload;
            if (visibility && !state.modalStack.includes(id)) {
                return {...state, modalStack: [...state.modalStack, id]};
            } else if (!visibility && state.modalStack.includes(id)) {
                return {...state, modalStack: state.modalStack.filter(x => x !== id)};
            } else {
                return state;
            }
        }
        case 'layout/TOGGLE_MODAL_VISIBILITY': {
            const {id} = action.payload;
            const {modalStack} = state;

            return O.set(modalStackO)(toggleItem(id)(modalStack))(state);
        }

        default:
            return state;
    }
};

export default reducer;
