import {SagaIterator} from 'redux-saga';
import {select, call, put} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {
    takeLatestF,
    showSuccess,
    extractFormErrorsFromResponse,
    ErrorResponse,
} from 'favorlogic-utils';

import {formValuesF, startSubmitF, resetF, stopSubmitF} from 'utils/formHelpers';
import {handleResponseError} from 'utils/handleResponseError';
import {UpdateUser} from 'types/model/users/UpdateUser';
import {StoreState} from 'app/types/StoreState';
import {UserDetailsForCustomer} from 'types/model/users/UserDetailsForCustomer';

import {ChangeUserPasswordAction} from '../actions';
import Api from '../api';

const title = 'Změna hesla';

function* getUser(): SagaIterator<UserDetailsForCustomer> {
    return opt(yield* select((state: StoreState) => state.user.user))
        .orCrash('missing user data');
}

function* getUpdateUser(user: UserDetailsForCustomer): SagaIterator<UpdateUser> {
    const values = (yield* select(formValuesF('resetPassword')))
        .orCrash('missing form data');

    return {
        firstName: user.firstName,
        lastName: user.lastName,
        password: values.password,
    };
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Heslo úspěšně změněno'));
    yield* put(stopSubmitF('resetPassword'));
    yield* put(resetF('resetPassword'));
}

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(stopSubmitF('resetPassword', extractFormErrorsFromResponse(response)));
}

export function* execute(_: ChangeUserPasswordAction): SagaIterator {
    yield* put(startSubmitF('resetPassword'));

    const user = yield* call(getUser);
    const updateUser = yield* call(getUpdateUser, user);
    const response = yield* call(Api.updateUser, user.id, updateUser);

    yield* response.isSuccess
        ? call(handleSuccess)
        : call(handleErrorResponse, response);
}

export function* changeUserPasswordSaga(): SagaIterator {
    yield takeLatestF('user/CHANGE_USER_PASSWORD', execute);
}
