import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {loadMilkRooms} from 'supplier/actions';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import withUser, {WithUserProps} from 'user/components/withUser';
import {loadSupplyChain} from '../actions';
import SupplyChainView from '../components/SupplyChainView';

interface OuterProps {}

interface InnerProps {
    match: Match<{id: string}>;
    supplyChain: SupplyChain | null;
    milkRooms: MilkRoom[] | null;

    handle404(): void;

    handleLoadSupplyChain(id: number): void;

    loadMilkRooms(): void;
}

type UserProps = Pick<WithUserProps, 'isRoleDairy' | 'isRoleDairyEmployee'>;
type Props = InnerProps & OuterProps & UserProps;

class Detail extends Component<Props> {
    componentDidMount(): void {
        const {
            isRoleDairy,
            isRoleDairyEmployee,
            handleLoadSupplyChain,
            loadMilkRooms,
        } = this.props;
        if (isRoleDairy || isRoleDairyEmployee) {
            loadMilkRooms();
            const id = this.getId();
            if (id !== null) {
                handleLoadSupplyChain(id);
            }
        }
    }

    render(): ReactNode {
        const {
            supplyChain,
            milkRooms,
        } = this.props;

        if (!supplyChain || !milkRooms) { return null; }

        return (
            <Layout.ItemPage
                title={`Detail linky ${supplyChain.code}`}
                backLabel="Linky"
                editAction={{to: `/supply-chains/${supplyChain.id}/edit`}}
            >
                <div className="text-center">
                    {supplyChain.name && <h2>({supplyChain.name})</h2>}
                </div>
                <Layout.Panel className="w-50">
                    {this.renderSupplyChain(supplyChain, milkRooms)}
                </Layout.Panel>
            </Layout.ItemPage>
        );
    }

    private getId(): number | null {
        const {match, handle404} = this.props;
        const {id} = match.params;
        const res = Number.parseInt(id, 10);
        if (Number.isNaN(res)) {
            handle404();
            return null;
        } else {
            return res;
        }
    }

    private readonly renderSupplyChain = (sc: SupplyChain, mrs: MilkRoom[]) => {
        const milkRoomIds = sc.milkRooms.map(x => x.id);
        return <SupplyChainView
            milkRoomIds={milkRoomIds}
            milkRooms={mrs}
            mode="VIEW"
        />;
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    milkRooms: state.supplier.milkRooms,
    supplyChain: state.supplyChain.supplyChain,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handle404: () => dispatch(routerActions.push('/404')),
    handleLoadSupplyChain: (id: number) => dispatch(loadSupplyChain(id)),
    loadMilkRooms: () => dispatch(loadMilkRooms()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Detail) as ComponentClass<OuterProps>;
