import React, {Component, ComponentClass, ReactNode, Fragment} from 'react';
import {Dispatch} from 'redux';
import {flow} from 'lodash/fp';
import {connect} from 'react-redux';

import {StoreState} from 'app/types/StoreState';
import {withUser} from 'user/components';
import {Modal, ListPage} from 'layout/components';
import {setModalVisibility} from 'layout/actions';
import {isModalVisibleSelector} from 'layout/reducer';
import {isSubmitingF} from 'utils/formHelpers';

import {
    generateCustomersQCZProtocol,
    generateQCZProtocol,
    syncCmsch,
    massGenerateProtocols,
    generatePreviewProtocol,
} from '../actions';
import SyncCmschForm from '../components/SyncCmschForm';
import GenerateQCZProtocol from '../components/GenerateQCzProtocol';
import GenerateCustomersQCZProtocol from '../components/GenerateCustomersQCzProtocol';
import MassGenerateProtocols from '../components/MassGenerateProtocols';
import GeneratePreviewProtocol from '../components/GeneratePreviewProtocol';
import GenerateQCzProtocolForm from '../components/GenerateQCzProtocolForm';


interface OuterProps {
}

interface StateProps {
    generateQCzProtocolInProgress: boolean;
    generateCustomersQCzProtocolInProgress: boolean;
    massGenerateProtocolsInProgress: boolean;
    generatePreviewProtocolInProgress: boolean;
    generateQCzProtocolModalVisible: boolean;
    generateCustomersQCzProtocolModalVisible: boolean;
}

interface DispatchProps {
    syncCmsch(): void;
    generateQCZProtocol(): void;
    generateCustomersQCZProtocol(): void;
    massGenerateProtocols(): void;
    generatePreviewProtocol(): void;
    openGenerateQCzProtocolModal(): void;
    closeGenerateQCzProtocolModal(): void;
    openGenerateCustomersQCzProtocolModal(): void;
    closeGenerateCustomersQCzProtocolModal(): void;
}

type Props = OuterProps & DispatchProps & StateProps;

class Others extends Component<Props> {
    render(): ReactNode {
        const {
            syncCmsch,
            generateQCZProtocol,
            generateCustomersQCZProtocol,
            massGenerateProtocols,
            massGenerateProtocolsInProgress,
            generatePreviewProtocol,
            generatePreviewProtocolInProgress,
            openGenerateQCzProtocolModal,
            generateQCzProtocolModalVisible,
            openGenerateCustomersQCzProtocolModal,
            generateCustomersQCzProtocolModalVisible,
        } = this.props;

        return (
            <Fragment>
                <Modal.Blurer>
                    <ListPage title="Ostatní">
                        <SyncCmschForm
                            onSubmit={syncCmsch}
                        />

                        <GenerateQCZProtocol
                            onGenerateProtocol={openGenerateQCzProtocolModal}
                        />

                        <GenerateCustomersQCZProtocol
                            onGenerateProtocol={openGenerateCustomersQCzProtocolModal}
                        />

                        <GeneratePreviewProtocol
                            onGeneratePreviewProtocol={generatePreviewProtocol}
                            generateProtocolInProgress={generatePreviewProtocolInProgress}
                        />

                        <MassGenerateProtocols
                            onMassGenerateProtocols={massGenerateProtocols}
                            massGenerateInProgress={massGenerateProtocolsInProgress}
                        />
                    </ListPage>
                </Modal.Blurer>
                <Modal.Container
                    show={generateQCzProtocolModalVisible}
                    onClickAway={this.tryCloseGenerateQCzProtocolModal}
                >
                    <GenerateQCzProtocolForm
                        title="Generování Q CZ protokolu"
                        onSubmit={generateQCZProtocol}
                    />
                </Modal.Container>
                <Modal.Container
                    show={generateCustomersQCzProtocolModalVisible}
                    onClickAway={this.tryCloseGenerateCustomersQCzProtocolModal}
                >
                    <GenerateQCzProtocolForm
                        title="Generování Q CZ protokolu pro jednotlivé mlékárny"
                        onSubmit={generateCustomersQCZProtocol}
                    />
                </Modal.Container>
            </Fragment>
        );
    }

    private tryCloseGenerateQCzProtocolModal = (): void => {
        const {generateQCzProtocolInProgress, closeGenerateQCzProtocolModal} = this.props;

        if (!generateQCzProtocolInProgress) {
            closeGenerateQCzProtocolModal();
        }
    }

    private tryCloseGenerateCustomersQCzProtocolModal = (): void => {
        const {generateCustomersQCzProtocolInProgress, closeGenerateCustomersQCzProtocolModal} = this.props;

        if (!generateCustomersQCzProtocolInProgress) {
            closeGenerateCustomersQCzProtocolModal();
        }
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    generateQCzProtocolInProgress: isSubmitingF('generateQCzProtocol')(state),
    generateQCzProtocolModalVisible: isModalVisibleSelector(state.layout, 'generateQCzProtocol'),
    generateCustomersQCzProtocolInProgress: isSubmitingF('generateQCzProtocol')(state),
    generateCustomersQCzProtocolModalVisible: isModalVisibleSelector(state.layout, 'generateCustomersQCzProtocol'),
    massGenerateProtocolsInProgress: state.administration.massGenerateProtocolsInProgress,
    generatePreviewProtocolInProgress: state.administration.generatePreviewProtocolInProgress,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    syncCmsch: () => dispatch(syncCmsch()),
    generateQCZProtocol: () => dispatch(generateQCZProtocol()),
    generateCustomersQCZProtocol: () => dispatch(generateCustomersQCZProtocol()),
    massGenerateProtocols: () => dispatch(massGenerateProtocols()),
    generatePreviewProtocol: () => dispatch(generatePreviewProtocol()),
    openGenerateQCzProtocolModal: () => dispatch(setModalVisibility('generateQCzProtocol', true)),
    closeGenerateQCzProtocolModal: () => dispatch(setModalVisibility('generateQCzProtocol', false)),
    openGenerateCustomersQCzProtocolModal: () => dispatch(setModalVisibility('generateCustomersQCzProtocol', true)),
    closeGenerateCustomersQCzProtocolModal: () => dispatch(setModalVisibility('generateCustomersQCzProtocol', false)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Others) as ComponentClass<OuterProps>;
