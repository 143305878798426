import React, {FunctionComponent} from 'react';

import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {FilterPart} from '../../types/FilterPart';
import DateTime from 'forms/components/BasicDateTime';

interface Props {
    filter?: FilterPart;
    accessor: string;
    placeholder?: string;
    onChange: FilterChangeHandler;
}

const DateFilter: FunctionComponent<Props> = props => {
    const {filter, accessor, placeholder, onChange} = props;

    const onDateChange = (value: string | null) => {
        onChange(accessor, 'date', value ? [value] : []);
    };

    const getValue = () => {
        if (!filter || !filter.values) {
            return '';
        }

        return filter.values[0] || '';
    };

    return <DateTime
        value={getValue()}
        placeholder={placeholder}
        onChange={onDateChange}
        mode="DATE"
        clearable
        adjustDateOnChange={false}
    />;
};

export default DateFilter;
