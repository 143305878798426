import * as t from 'io-ts';

import {PageSchema} from '../common/Page';
import {MeasurementViewSchema} from './MeasurementView';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    content: t.array(MeasurementViewSchema),
});

export const MeasurementDetailsPageSchema = t.exact(t.intersection([PageSchema.type, optionalPart, requiredPart]));

export interface MeasurementDetailsPage extends t.TypeOf<typeof MeasurementDetailsPageSchema> {}
