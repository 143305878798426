import {opt} from 'ts-opt';
import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {map, flow, find, sortBy, some} from 'lodash/fp';
import {filter, intercalateElem, classNames} from 'favorlogic-utils';

import {Components as Tables} from 'tables';
import {Column, ColumnType, DynamicNodeColumn} from 'tables/types/Column';
import {Row} from 'tables/types/Row';
import {MeasurementDetailsPage} from 'types/model/measurements/MeasurementDetailsPage';
import {MeasurementView} from 'types/model/measurements/MeasurementView';
import {SortDir} from 'tables/components/withSort';
import {DataItem} from 'types/model/dataItems/DataItem';
import {FilterDefinition} from 'tables/types/FilterDefinition';
import {Filter} from 'tables/types/Filter';
import {Analysis} from 'types/model/analyses/Analysis';
import {ColorAnnotation} from 'tables/types/ColorAnnotation';
import checkSvg from 'forms/components/Field/check.svg';
import {SvgIcon} from 'icons/components';
import {LoaderWrapper} from 'layout/components';

import {measurementUnitNP} from '../../constants';
import {npUnits} from '../../type/npUnits';
import shortenDataItemName from '../../utils/shortenDataItemName';
import {getAnalysesOptions} from '../../utils/getAnalysesOptions';
import {flagsTooltip, flagsOptions, FlagDefinition, flags, updateFlagValues} from '../../utils/flags';
import RowActions from './RowActions';

const TABLE_ANNOTATIONS: ColorAnnotation[] = [
    {color: 'info', label: 'Nahrána všechna měření'},
    {color: 'lowlight', label: 'Nepoužitelné a zrušené vzorky'},
];

export interface MeasurementRow extends Omit<MeasurementView, 'analysisIds'> {
    icons: ReactNode;
    flags: ReactNode;
    actions: ReactNode;
    analysisIds: ReactNode;
}

export const definedFilters: FilterDefinition<keyof MeasurementRow>[] = [
    {key: 'orderId', type: 'equals'},
    {key: 'barcode', type: 'equals'},
    {key: 'samplingDate', type: 'date'},
    {key: 'milkRoomName', type: 'equals'},
    {key: 'milkRoomCode', type: 'equals'},
    {key: 'flags', type: 'select'},
    {key: 'subsidy', type: 'select'},
    {key: 'analysisIds', type: 'select'},
];

const renderLink = (value: string, measurementId: number): ReactNode =>
    <Link
        onClick={event => event.stopPropagation()}
        to={`/measurements/${measurementId}`}
    >
        {value}
    </Link>
    ;

const getNPResult = (value: number, measurementId: number): ReactNode => {
    const label = npUnits[value];

    return label ? renderLink(label, measurementId) : undefined;
};

const getResult = (measurement: MeasurementView, dataItem: DataItem): ReactNode => {
    const result = opt(measurement.results.find(x => x.dataItemId === dataItem.id));

    if (dataItem.measurementUnit === measurementUnitNP) {
        return result.caseOf(x => getNPResult(x.result, x.measurementId), () => '-');
    }

    return result.caseOf(x => renderLink(x.result.toString(), x.measurementId), () => '-');
};

export const prepareDataItemsData = (
    dataItems: DataItem[],
    measurement: MeasurementView
): Record<string, ReactNode> => {
    const dataItemsData: Record<string, ReactNode> = {};
    dataItems.forEach(x => {
        dataItemsData[x.id.toString()] = getResult(measurement, x);
    });
    return dataItemsData;
};

export const dataItemToColumn = (x: DataItem): DynamicNodeColumn => ({
    accessor: x.id.toString(),
    header: shortenDataItemName(x.name),
    tooltip: x.name,
    subHeader: `(${x.measurementUnit})`,
    type: ColumnType.DynamicNode,
});

export const isDataItemUsed = (measurements: MeasurementView[]) => (di: DataItem): boolean =>
    some(m => some(res => res.dataItemId === di.id, m.results), measurements);

interface OuterProps {
    measurementsPage: MeasurementDetailsPage | null;
    dataItems: DataItem[];
    sortBy?: string;
    sortDir?: SortDir;
    filter: Filter;
    selectedSamples: number[];
    analyses: Analysis[];
    showCustomerNameColumn: boolean;

    handlePageChange(page: number): void;
    handlePageSizeChange(size: number): void;
    handleSortChange(property: string): void;
    changeFilter: (accessor: string, type: string, values: string[]) => void;
    resetFilter: () => void;
    filterMeasurements: () => void;
    toggleSelect(sampleId: number): void;
}

interface InnerProps {
}

type Props = InnerProps & OuterProps;

class MeasurementsTable extends Component<Props> {
    render(): React.ReactNode {
        const {
            measurementsPage,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            sortBy,
            sortDir,
            resetFilter,
            filterMeasurements,
            filter,
            toggleSelect,
        } = this.props;

        return (
            <LoaderWrapper showLoader={!measurementsPage}>
                <Tables.Table
                    columns={this.getColumns()}
                    rows={this.getData()}
                    page={measurementsPage?.pageable.pageNumber}
                    pageSize={measurementsPage?.pageable.pageSize}
                    total={measurementsPage?.totalElements}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                    handleSortChange={handleSortChange}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    hasFooter
                    handleFilterChange={this.onFilterChange}
                    handleFilterClear={resetFilter}
                    handleFilterSubmit={filterMeasurements}
                    filter={filter}
                    hasHeader
                    rowSelect
                    handleSelectRow={toggleSelect}
                />
                <Tables.Legend annotations={TABLE_ANNOTATIONS} />
            </LoaderWrapper>
        );
    }

    private getColumns(): Column<MeasurementRow>[] {
        const {analyses, showCustomerNameColumn} = this.props;

        return [
            {
                accessor: 'icons',
                header: '',
                type: ColumnType.Node,
                size: 'narrow',
            },
            {
                accessor: 'orderId',
                header: 'Č. obj.',
                tooltip: 'Číslo objednávky',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
                filterInputType: 'integer',
            },
            ...showCustomerNameColumn ? [this.getCustomerNameColumn()] : [],
            {
                accessor: 'accepted',
                header: 'Dat. příjmu',
                tooltip: 'Datum příjmu',
                type: ColumnType.Date,
                dateFormat: 'DATE',
            },
            {
                accessor: 'barcode',
                header: 'Čárový kód',
                tooltip: 'Unikátní identifikace vzorku',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'samplingDate',
                header: 'Dat. odběru',
                tooltip: 'Datum odběru',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                filterable: true,
                filterType: 'betweenDates',
                sortable: true,
                size: 'wide',
            },
            {
                accessor: 'measuredFrom',
                header: 'Změřeno od',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable: true,
            },
            {
                accessor: 'measuredTo',
                header: 'Změřeno do',
                type: ColumnType.Date,
                dateFormat: 'DATE',
                sortable: true,
            },
            {
                accessor: 'milkRoomName',
                header: 'Mléčnice',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'milkRoomCode',
                header: 'Kód mléčnice',
                type: ColumnType.Node,
                sortable: true,
                filterable: true,
                filterType: 'equals',
            },
            {
                accessor: 'flags',
                header: 'Příznaky',
                type: ColumnType.Node,
                tooltip: flagsTooltip,
                filterable: true,
                filterType: 'select',
                filterMulti: true,
                filterOptions: flagsOptions,
                size: 'medium',
            },
            {
                accessor: 'analysisIds',
                header: 'Rozbory',
                type: ColumnType.Node,
                filterable: true,
                filterType: 'select',
                filterMulti: true,
                filterOptions: getAnalysesOptions(analyses),
                size: 'medium',
            },
            {
                accessor: 'subsidy',
                header: 'Q CZ',
                type: ColumnType.Bool,
                format: (value) => value ? 'Q' : '',
                filterable: true,
                filterType: 'check',
            },
            ...this.getDataItemsColumns(),
            {
                accessor: 'actions',
                header: 'Akce',
                type: ColumnType.Node,
            },
        ];
    }

    private getCustomerNameColumn(): Column<MeasurementRow> {
        return {
            accessor: 'customerName',
            header: 'Zákazník',
            type: ColumnType.Node,
            sortable: true,
            filterable: true,
            filterType: 'equals',
            maxChars: 24,
        };
    }

    private getDataItemsColumns(): DynamicNodeColumn[] {
        const {
            dataItems,
            measurementsPage,
        } = this.props;

        if (!measurementsPage) {
            return [];
        }
        const {content: measurements} = measurementsPage;

        return flow(
            filter(isDataItemUsed(measurements)),
            sortBy((x: DataItem) => x.columnIndex),
            map(dataItemToColumn),
        )(dataItems);
    }

    private renderIcons = (measurement: MeasurementView) =>
        measurement.showResultsOnWeb
            ? <SvgIcon
                icon={checkSvg}
                title="Odesláno na data.cmsch.cz"
            />
            : '';

    private getData(): Row<MeasurementRow>[] {
        const {
            dataItems,
            toggleSelect,
            selectedSamples,
            analyses,
            measurementsPage,
        } = this.props;

        if (!measurementsPage) {
            return [];
        }
        const {content: measurements} = measurementsPage;


        return measurements.map((measurement) => {
            const dataItemsData = prepareDataItemsData(dataItems, measurement);
            const {sampleId, analysisIds} = measurement;
            const selected = selectedSamples.includes(sampleId);
            const lowlighted = measurement.disabled || measurement.canceled;

            return {
                id: sampleId,
                flags: this.prepareFlagsNode(measurement),
                selected,
                ...measurement,
                ...dataItemsData,
                analysisIds: this.genAnalysesCell(analysisIds, analyses),
                lowlighted,
                showResultsOnWeb: true,
                informed: measurement.hasAllMeasurements && !lowlighted,
                icons: this.renderIcons(measurement),
                actions: <RowActions
                    measurement={measurement}
                    selected={selected}
                    toggleSelect={toggleSelect}
                />,
            };
        });
    }

    private prepareFlagsNode(measurement: MeasurementView): ReactNode {
        const createFlagElement = ({shortcut, key}: FlagDefinition) =>
            <span
                className={classNames(!measurement[key] && 'invisible')}
                key={key}
            >
                {shortcut}
            </span>;

        return (
            <span>
                {flags.map(createFlagElement)}
            </span>
        );
    }

    private genAnalysesCell(ids: number[], analyses: Analysis[]): ReactNode {
        const mkNode = ([abbr, name]: [string, string]) => <span title={name}>{abbr}</span>;
        const genOne = flow(
            (id: number) => opt(find(x => x.id === id, analyses))
                .caseOf<[string, string]>(a => [a.abbr, a.name], () => [`?${id}`, `Rozbor ${id} nenalezen.`]),
            mkNode,
        );
        return intercalateElem(', ')(map(genOne, ids));
    }

    private onFilterChange = (accessor: string, type: string, values: string[]): void => {
        const {changeFilter} = this.props;

        changeFilter(accessor, type, accessor === 'flags' ? updateFlagValues(values) : values);
    };
}

export default MeasurementsTable;
