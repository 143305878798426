import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {takeLatestF, ErrorsFromBE, ErrorResponse, SuccessResponse} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {UserDetails} from 'types/model/users/UserDetails';

import {loadUsersError, loadUsersSuccess, LoadUsersAction} from '../actions';
import Api from '../api';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    const error = response.data as ErrorsFromBE;

    yield* call(handleResponseError, response, 'Načtení uživatelů');
    yield* put(loadUsersError(error));
}

function* handleSuccessResponse(response: SuccessResponse<UserDetails[]>): SagaIterator {
    yield* put(loadUsersSuccess(response.data));
}

export function* execute(_: LoadUsersAction): SagaIterator {
    const response = yield* call(Api.getUsers);

    yield* response.isSuccess ? call(handleSuccessResponse, response) : call(handleErrorResponse, response);
}

export function* loadUsersSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_USERS', execute);
}
