import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {pickupLineAction, UpdatePickupLineAction} from '../actions';

const title = 'Aktualizace svozové linky';

function* execute ({payload: {data, pickupLineId}}: UpdatePickupLineAction): SagaIterator {
    const response = yield* call(PickupLineApi.updatePickupLine, pickupLineId, data);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLines());
    } else {
        yield putAll(showBeError(response, title));
    }
}

export function* updatePickupLineSaga(): SagaIterator {
    yield takeLatestF('pickupLine/UPDATE_PICKUP_LINE', execute);
}
