import React, {FunctionComponent} from 'react';
import {classNames} from 'favorlogic-utils';

import {Components as Icons} from 'icons';

import Button, {ButtonType} from '../Button';

import styles from './styles.sass';

interface Props {
    icon: string;
    className?: string;
    disabled?: boolean;
    type?: ButtonType;

    onClick?(): void;
}

const IconButton: FunctionComponent<Props> = props => {
    const {
        icon,
        className,
        type,
        disabled,
        onClick,
    } = props;

    return (
        <Button
            className={classNames(className, styles.iconButton)}
            type={type || 'button'}
            onClick={onClick}
            disabled={disabled}
        >
            <Icons.SvgIcon icon={icon} />
        </Button>
    );
};

export default IconButton;
