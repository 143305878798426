import {SagaIterator} from 'redux-saga';
import {putAll, showBeError, takeLatestF} from 'favorlogic-utils';
import {call, put} from 'typed-redux-saga';

import {PickupLineApi} from '../api';
import {pickupLineAction} from '../actions';

const title = 'Načtení seznamu svozových linek';

function* execute (): SagaIterator {
    yield* put(pickupLineAction.setLoading('administration', true));

    const response = yield* call(PickupLineApi.getPickupLines);

    if (response.isSuccess) {
        yield* put(pickupLineAction.getPickupLinesSuccess(response.data));
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(pickupLineAction.setLoading('administration', false));
}

export function* getPickupLinesSaga(): SagaIterator {
    yield takeLatestF('pickupLine/GET_PICKUP_LINES', execute);
}
