import React from 'react';
import {Form} from 'redux-form';

import {Components as Layout} from 'layout';
import {Components as Forms, Fields} from 'forms';
import {Components as Buttons} from 'buttons';
import {PropsForForm} from 'forms/components/withForm';
import {SyncCmschFormValues} from 'administration/types/SyncCmschFormValues';

import {validate} from './validation';

interface OwnProps {}

type Props = PropsForForm<SyncCmschFormValues, OwnProps>;

class SyncCmschForm extends React.Component<Props> {
    render(): React.ReactNode {
        const {
            handleSubmit,
            submitting,
            valid,
        } = this.props;

        return (
            <Layout.Panel>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <strong className="mt-2 d-block">
                            Odeslání dat do aplikace
                            <a
                                className="ml-1"
                                href="https://data.cmsch.cz/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Přístup k datům
                            </a>
                        </strong>
                    </div>
                </div>

                <Form
                    className="position-relative row"
                    onSubmit={handleSubmit}
                >
                    <Layout.Loader show={submitting} />

                    <div className="col-md-6">
                        <Fields.DateTime
                            name="from"
                            label="Od"
                            mode="DATE"
                        />
                    </div>

                    <div className="col-md-6">
                        <Fields.DateTime
                            name="to"
                            label="Do"
                            mode="DATE"
                        />
                    </div>

                    <div className="col-12">
                        <div className="d-flex justify-content-end mt-4">
                            <Buttons.Button
                                className="w-100"
                                importance="primary"
                                disabled={!valid || submitting}
                                type="submit"
                            >
                                Odeslat data
                            </Buttons.Button>
                        </div>
                    </div>
                </Form>
            </Layout.Panel>
        );
    }
}


export default Forms.withForm<SyncCmschFormValues, OwnProps, Props>(SyncCmschForm, {
    form: 'syncCmsch',
    validate,
    initialValues: {
        from: '',
        to: '',
    },
});
