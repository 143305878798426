import {Dictionary} from 'favorlogic-utils';
import {forEach} from 'lodash/fp';
import {Opt} from 'ts-opt';

import {DateString} from 'types/generic/DateString';
import {PickupLineOptions} from 'types/model/pickupLine/PickupLineOptions';
import {PickupLineOptionState} from 'types/model/pickupLine/PickupLineOptionState';

import {PickupLinePlanningFormValues} from '../components/PickupLinePlanningForm/pickupLinePlanningFormValues';

export const getPlanningFormInitialValues = (
    pickupLinesOptions: Opt<PickupLineOptions[]>
): PickupLinePlanningFormValues => {
    const initialValues: PickupLinePlanningFormValues = {states: {}};

    pickupLinesOptions.onSome(
        forEach((x: PickupLineOptions) => {
            const pickupLineValues: Dictionary<DateString, PickupLineOptionState> = {};
            x.options.forEach((y) => {
                pickupLineValues[y.id] = y.state;
            });
            initialValues.states[x.id] = pickupLineValues;
        })
    );

    return initialValues;
};
