import * as t from 'io-ts';
import {DateStringSchema} from 'favorlogic-utils';

const optionalPart = t.partial({
});
const requiredPart = t.interface({
    processedFrom: DateStringSchema,
    processedTo: DateStringSchema,
});

export const CreateProtocolSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface CreateProtocol extends t.TypeOf<typeof CreateProtocolSchema> {}
