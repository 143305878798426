import * as t from 'io-ts';
import {ArrayBufferSchema, EmptyObject, EmptyObjectSchema, PResponse} from 'favorlogic-utils';
import {StatusCodes} from 'http-status-codes';

import {apiRequester} from 'app/api';
import {PickupLine, PickupLineSchema} from 'types/model/pickupLine/PickupLine';
import {WritePickupLine, WritePickupLineSchema} from 'types/model/pickupLine/WritePickupLine';
import {PickupLineValidation, PickupLineValidationSchema} from 'types/model/pickupLine/PickupLineValidation';
import {Customer, CustomerSchema} from '../types/model/pickupLine/Customer';
import {PickupLineOptions, PickupLineOptionsSchema} from 'types/model/pickupLine/PickupLineOptions';
import {DateString} from 'types/generic/DateString';
import {UpdatePickupLineOption, UpdatePickupLineOptionSchema} from 'types/model/pickupLine/UpdatePickupLineOption';
import {PickupLineRequests, PickupLineRequestsSchema} from 'types/model/pickupLine/PickupLineRequests';
import {PickupLineDairyPlan, PickupLineDairyPlanSchema} from 'types/model/pickupLine/PickupLineDairyPlan';
import {
    UpdatePickupLineDairyPlanDay,
    UpdatePickupLineDairyPlanDaySchema,
} from 'types/model/pickupLine/UpdatePickupLineDairyPlanDay';

export class PickupLineApi {
    static async getPickupLines(): PResponse<PickupLine[]> {
        return apiRequester.makeRequest({
            url: '/pickup-lines',
            requestSchema: t.void,
            responseSchema: t.array(PickupLineSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    static async createPickupLine(data: WritePickupLine): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/pickup-lines',
            method: 'post',
            requestSchema: WritePickupLineSchema,
            responseSchema: EmptyObjectSchema,
            data,
            expectedStatus: StatusCodes.CREATED,
        });
    }

    static async validatePickupLineName(data: PickupLineValidation): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: './pickup-lines/validate',
            method: 'post',
            requestSchema: PickupLineValidationSchema,
            responseSchema: EmptyObjectSchema,
            data,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    static getUnassignedDairies(): PResponse<Customer[]> {
        return apiRequester.makeRequest({
            url: '/pickup-lines/unassigned-dairies',
            requestSchema: t.void,
            responseSchema: t.array(CustomerSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    static async deletePickupLine(pickupLineId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/pickup-lines/${pickupLineId}`,
            method: 'delete',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    static async updatePickupLine(pickupLineId: number, data: WritePickupLine): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/pickup-lines/${pickupLineId}`,
            method: 'put',
            requestSchema: WritePickupLineSchema,
            responseSchema: EmptyObjectSchema,
            data,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    static async getPickupLinesOptions(yearMonth: DateString): PResponse<PickupLineOptions[]> {
        return apiRequester.makeRequest({
            url: `/pickup-lines-options/${yearMonth}`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: t.array(PickupLineOptionsSchema),
            expectedStatus: StatusCodes.OK,
        });
    }

    static async updatePickupLinesOptions(
        data: UpdatePickupLineOption[],
    ): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/pickup-lines-options',
            method: 'put',
            requestSchema: t.array(UpdatePickupLineOptionSchema),
            responseSchema: EmptyObjectSchema,
            data,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    static async approvePickupLinePlan(yearMonth: DateString, pickupLineId: number): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: `/pickup-lines-options/${yearMonth}/approve/${pickupLineId}`,
            method: 'post',
            requestSchema: t.void,
            responseSchema: EmptyObjectSchema,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }

    static async getPickupLineRequests(yearMonth: DateString, pickupLineId: number): PResponse<PickupLineRequests> {
        return apiRequester.makeRequest({
            url: `/pickup-lines/${pickupLineId}/pickup-line-requests/${yearMonth}`,
            requestSchema: t.void,
            responseSchema: PickupLineRequestsSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    static async getPickupLineRequestsExport(yearMonth: DateString, pickupLineId: number): PResponse<ArrayBuffer> {
        return apiRequester.makeRequest({
            url: `/pickup-lines/${pickupLineId}/pickup-line-requests/${yearMonth}/export`,
            requestSchema: t.void,
            responseSchema: ArrayBufferSchema,
            responseType: 'arraybuffer',
            expectedStatus: StatusCodes.OK,
        });
    }

    static async getPickupLineDairyPlan(): PResponse<PickupLineDairyPlan> {
        return apiRequester.makeRequest({
            url: '/pickup-lines/customer',
            method: 'post',
            requestSchema: t.void,
            responseSchema: PickupLineDairyPlanSchema,
            expectedStatus: StatusCodes.OK,
        });
    }

    static async updatePickupLineDairyPlan(data: UpdatePickupLineDairyPlanDay[]): PResponse<EmptyObject> {
        return apiRequester.makeRequest({
            url: '/pickup-lines/customer',
            method: 'put',
            requestSchema: t.array(UpdatePickupLineDairyPlanDaySchema),
            responseSchema: EmptyObjectSchema,
            data,
            expectedStatus: StatusCodes.NO_CONTENT,
        });
    }
}
