import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import UpdateSamplesForm from 'sample/components/UpdateSamplesForm';
import {UpdateSamplesBy} from 'sample/screens/List';
import {SamplesFilterParams, SamplesParams} from 'sample/types/SampleQueryParams';
import withUser from 'user/components/withUser';
import {updateSamplesByFilter, updateSamplesSelection} from '../actions';

interface OuterProps {}

interface InnerProps {
    selectedSamples: number[];
    match: Match<{id: string}>;
    samplesFilterParams: SamplesFilterParams;
    updateSamplesBy: UpdateSamplesBy;

    handleUpdateSamplesByFilter(filter: SamplesParams): void;

    handleUpdateSamplesSelection(ids: number[]): void;
}

type Props = InnerProps & OuterProps;

class UpdateSamples extends Component<Props> {
    render(): ReactNode {
        const {
            selectedSamples,
            handleUpdateSamplesSelection,
            handleUpdateSamplesByFilter,
            samplesFilterParams,
            updateSamplesBy,
        } = this.props;


        const handleSubmit = () =>
            updateSamplesBy === 'SELECTION'
                ? handleUpdateSamplesSelection(selectedSamples)
                : handleUpdateSamplesByFilter(samplesFilterParams);

        return (
            <Layout.ItemPage
                title="Úprava vzorků"
                backLabel="Vzorky"
            >
                <UpdateSamplesForm
                    onSubmit={handleSubmit}
                />
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    selectedSamples: state.sample.selectedSamples,
    samplesFilterParams: state.sample.samplesFilterParams,
    updateSamplesBy: state.sample.updateSamplesBy,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleUpdateSamplesByFilter: (filter: SamplesFilterParams) => dispatch(updateSamplesByFilter(filter)),
    handleUpdateSamplesSelection: (sampleIds: number[]) => dispatch(updateSamplesSelection(sampleIds)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(UpdateSamples) as ComponentClass<OuterProps>;
