import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    name: t.string,
    description: t.string,
});

export const OperatingProcedureSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface OperatingProcedure extends t.TypeOf<typeof OperatingProcedureSchema> {}
