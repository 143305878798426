import {opt} from 'ts-opt';

const shortenedDataItemNames: Readonly<Record<string, string>> = {
    'Bílkovina': 'Bílk.',
    'Laktóza': 'Lakt.',
    'Močovina': 'Moč.',
};

const shortenDataItemName = (name: string): string => opt(shortenedDataItemNames[name]).orElse(name);
export default shortenDataItemName;
