import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {extractFormErrorsFromResponse, showSuccess, ErrorsFromBE, ErrorResponse, takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';

import {
    UpdateSupplyChainAction,
    updateSupplyChainError,
    updateSupplyChainSuccess,
} from '../actions';
import Api from '../api';
import {stopSubmitF, startSubmitF, formValuesF} from 'utils/formHelpers';

const title = 'Upravení linky';

function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateSupplyChainError(response.data as ErrorsFromBE));
    yield* put(stopSubmitF('supplyChain', extractFormErrorsFromResponse(response)));
}

function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Změny uloženy.'));
    yield* put(updateSupplyChainSuccess());
    yield* put(stopSubmitF('supplyChain'));
    yield* put(routerActions.goBack());
}

function* execute({payload: {supplyChainId}}: UpdateSupplyChainAction): SagaIterator {
    yield* put(startSubmitF('supplyChain'));

    const values = (yield* select(formValuesF('supplyChain'))).orCrash('missing form values');
    const response = yield* call(Api.updateSupplyChain, values, supplyChainId);

    yield* response.isSuccess ? call(handleSuccess) : call(handleErrorResponse, response);
}

export function* updateSupplyChainSaga(): SagaIterator {
    yield takeLatestF('supplyChain/UPDATE_SUPPLY_CHAIN', execute);
}
