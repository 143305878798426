import {includes, find, forEach, isArray, isString, isEqual} from 'lodash/fp';
import {opt} from 'ts-opt';
import {decapitalizeWord, KeysOfType} from 'favorlogic-utils';

type ErrorsRecord = Partial<Record<string, string>>

export type Errors<Values> = {
    [K in keyof Values]?: string | ErrorsRecord | ErrorsRecord[]
};

export type Warnings<Values> = Errors<Values>;

export const validationRegularExpressions = Object.freeze({
    floatNumber: /^-?(\d| )+([,.](\d| )+)?$/,
    positiveOrZeroNumber: /^(\d| )+([,.](\d| )+)?$/,
    positiveNumber: /^(?!0*(\.0+)?$)([\d]| )+([,.](\d| )+)?$/,
    integerNumber: /^-?(\d| )+$/,
    oneDecimalPlaceNumber: /^\d+([,.]?\d{0,1})?$/,
});

/**
 * Validates form values.
 */
export class Validator<Values> {
    static genIsRequiredError(x: string): string { return `${x} je povinná položka.`; }

    /**
     * Characters word translation.
     * @param count
     */
    static charsT(count: number): string {
        if (count === 1) { return 'znak'; } else if (count <= 4) { return 'znaky'; } else { return 'znaků'; }
    }

    static genMinLenError(label: string, minLen: number): string {
        return `${label} musí mít délku alespoň ${minLen} ${this.charsT(minLen)}.`;
    }

    static genMaxLenError(label: string, maxLen: number): string {
        return `${label} musí mít délku nejvýše ${maxLen} ${this.charsT(maxLen)}.`;
    }

    static genPatternError(label: string): string {
        return `${label} má nesprávný formát.`;
    }

    static genFloatNumberError(label: string): string {
        return `${label} musí být desetinné číslo.`;
    }

    static genIntegerNumberError(label: string): string {
        return `${label} musí být celé číslo.`;
    }

    static genPositiveOrZeroNumberError(label: string): string {
        return `${label} nesmí být menší než nula.`;
    }

    static genPositiveNumberError(label: string): string {
        return `${label} nesmí být 0 a menší.`;
    }

    static genMaxNumberError(label: string, max: number): string {
        return `${label} může být maximálně ${max}`;
    }

    static genNumberMaxOneDecimalPlaceError(label: string): string {
        return `${label} musí být číslo, max. 1 desetinné místo.`;
    }

    static genArrayLengthError(label: string): string {
        return `Všechny ${decapitalizeWord(label)} musí být vyplněny.`;
    }

    static genUniqueError(label: string): string {
        return `Hodnota pro ${label} už existuje.`;
    }

    static getEqualOrHigherDateError(label: string, higherLabel: string): string {
        return `${higherLabel} musí být stejné nebo větší než ${label}.`;
    }

    static getFieldsEqualError(label: string, secondLabel: string): string {
        return `${secondLabel} musí mít stejnou hodnotu jako ${label}`;
    }

    protected readonly errors: Errors<Values>;

    constructor(protected readonly values: Values) {
        this.errors = {};
        opt(values).orCrash('values are missing');
    }

    /**
     * Is field empty?
     * String is considered also empty if it contains only whitespace characters.
     * @param fieldName
     */
    checkIsEmpty(fieldName: keyof Values): boolean {
        const value = this.values[fieldName];
        if (!value) {
            return true;
        } else if (isString(value) && !value.trim()) {
            return true;
        } else if (isArray(value) && value.length === 0) {
            return true;
        }
        return false;
    }

    /**
     * Validates field to have a filled value.
     * @param fieldName
     * @param label
     */
    nonEmpty(fieldName: keyof Values, label: string): void {
        const errStr = Validator.genIsRequiredError(label);
        if (this.checkIsEmpty(fieldName)) {
            this.setErrorForField(fieldName, errStr);
        }
    }

    /**
     * Validates field value equal to other input value
     * @param fieldName
     * @param secondFieldName
     * @param label
     * @param secondLabel
     * @param fieldsRequired - are fields required or not? Validation works depending on
     * fieldsRequired a bit differently. Function equal not validate if required parameters are filled !!! For validate
     * if required fields are filled please use nonEmpty function.
     */
    equal(
        fieldName: keyof Values,
        secondFieldName: keyof Values,
        label: string,
        secondLabel: string,
        fieldsRequired: boolean
    ): void {
        const errStr = Validator.getFieldsEqualError(label, secondLabel);

        const value = this.values[fieldName];
        const secondValue = this.values[secondFieldName];

        if (fieldsRequired && (!value || !secondValue)) {
            return;
        }

        if (!isEqual(value, secondValue)) {
            this.setErrorForField(secondFieldName, errStr);
        }
    }

    /**
     * Validates fields to have a filled value one of them.
     * @param fieldNames
     * @param label
     */
    oneIsFilled<K extends keyof Values>(fieldNames: K[], label: string): void {
        const errStr = Validator.genIsRequiredError(label);
        const isFilled = find((x: keyof Values) => !this.checkIsEmpty(x), fieldNames);
        if (!isFilled) {
            forEach((x: keyof Values) => this.setErrorForField(x, errStr), fieldNames);
        }
    }

    /**
     * Validates string field to have at least {@param minLen} characters.
     * Does not fail when a field is empty. Use {@link nonEmpty} as well, if you don't want to allow empty strings.
     * @param fieldName
     * @param minLen
     * @param label
     */
    minStringLength(fieldName: KeysOfType<Values, string | undefined>, minLen: number, label: string): void {
        if (minLen <= 0) {
            throw new Error(`Invalid minLen = ${minLen}.`);
        }
        const value = this.values[fieldName];
        if (value) {
            if (!isString(value)) {
                throw new Error(`Field ${fieldName} is not a string. Cannot validate its length.`);
            }
            if (value.length < minLen) {
                this.setErrorForField(fieldName, Validator.genMinLenError(label, minLen));
            }
        }
    }

    /**
     * @param fieldName
     * @param fieldNameHigher
     * @param label
     * @param higherLabel
     */
    dateEqualOrHigher(
        fieldName: KeysOfType<Values, string>,
        fieldNameHigher: KeysOfType<Values, string>,
        label: string,
        higherLabel: string
    ): void {
        const value = this.values[fieldName];
        const higherValue = this.values[fieldNameHigher];

        if (!value || !higherValue) {
            return;
        }

        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string.`);
        }

        if (!isString(higherValue)) {
            throw new Error(`Field ${fieldName} is not a string..`);
        }

        const valueDate = new Date(value);
        const valueDateHigher = new Date(higherValue);

        if (valueDate > valueDateHigher) {
            const error = Validator.getEqualOrHigherDateError(label, higherLabel);
            this.setErrorForField(fieldNameHigher, error);
        }
    }

    /**
     * Validates string field to have at most {@param maxLen} characters.
     * @param fieldName
     * @param maxLen
     * @param label
     */
    maxStringLength(fieldName: KeysOfType<Values, string | undefined | null>, maxLen: number, label: string): void {
        if (maxLen <= 0) {
            throw new Error(`Invalid maxLen = ${maxLen}.`);
        }
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate its length.`);
        }
        if (value.length > maxLen) {
            this.setErrorForField(fieldName, Validator.genMaxLenError(label, maxLen));
        }
    }

    private patternBase(fieldName: keyof Values, regex: RegExp, error: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate pattern.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, error);
        }
    }

    /**
     * Validates a string field to match a regular expression.
     * Uses a custom error message given as {@param error}.
     * @param fieldName
     * @param regex
     * @param error
     */
    patternCustomError(fieldName: keyof Values, regex: RegExp, error: string): void {
        this.patternBase(fieldName, regex, error);
    }

    /**
     * Validates a string field to match a regular expression.
     * Uses generic error message generated from {@param label}.
     * @param fieldName
     * @param regex
     * @param label
     */
    pattern(fieldName: keyof Values, regex: RegExp, label: string): void {
        this.patternBase(fieldName, regex, Validator.genPatternError(label));
    }

    /**
     * Validates string field to be a number (integer or float).
     * @param fieldName
     * @param label
     */
    floatNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate float number.`);
        }
        if (!validationRegularExpressions.floatNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genFloatNumberError(label));
        }
    }

    /**
     * Validates string field to be a number (integer or float).
     * @param fieldName
     * @param label
     */
    integerNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate integer number.`);
        }
        if (!validationRegularExpressions.integerNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genIntegerNumberError(label));
        }
    }

    /**
     * Validates string field to be a number (integer or float).
     * @param fieldName
     * @param label
     */
    positiveOrZeroNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate positive or zero number.`);
        }
        if (!validationRegularExpressions.positiveOrZeroNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genPositiveOrZeroNumberError(label));
        }
    }

    /**
     * Validates string field to be a number (integer or float).
     * @param fieldName
     * @param label
     */
    positiveNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate positive number.`);
        }
        if (!validationRegularExpressions.positiveNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genPositiveNumberError(label));
        }
    }

    maxNumberBase(fieldName: keyof Values, error: string, max: number): void {
        const value = this.values[fieldName];
        if (value === null || value === undefined) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate max number.`);
        }

        if (Number(value) > max) {
            this.setErrorForField(fieldName, error);
        }
    }

    /**
     * Validates number field to be a number lower than max.
     * @param {string}fieldName
     * @param label
     * @param {number} max
     */
    maxNumber(fieldName: keyof Values, label: string, max: number): void {
        this.maxNumberBase(fieldName, Validator.genMaxNumberError(label, max), max);
    }

    /**
     * Validates number field to be a number lower than max. Supports custom error message.
     * @param {string}fieldName
     * @param error
     * @param {number} max
     */
    maxNumberCustomError(fieldName: keyof Values, error: string, max: number): void {
        this.maxNumberBase(fieldName, error, max);
    }

    /**
     * Validates string field to be a number rounded to one decimal place.
     * @param fieldName
     * @param label
     */
    oneDecimalPlaceNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isString(value)) {
            throw new Error(`Field ${fieldName} is not a string. Cannot validate number.`);
        }
        if (!validationRegularExpressions.oneDecimalPlaceNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genNumberMaxOneDecimalPlaceError(label));
        }
    }

    /**
     * Validates array field to be of exact length.
     * @param fieldName
     * @param length
     * @param label
     */
    arrayLength(fieldName: keyof Values, length: number, label: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        if (!isArray(value)) {
            throw new Error(`Field ${fieldName} is not an array. Cannot validate array length.`);
        }
        if (value.length !== length) {
            this.setErrorForField(fieldName, Validator.genArrayLengthError(label));
        }
    }

    private uniqueBase(fieldName: keyof Values, collection: Values[keyof Values][], error: string): void {
        const value = this.values[fieldName];
        if (!value) { return; }
        const duplicity = includes(value, collection);
        if (duplicity) {
            this.setErrorForField(fieldName, error);
        }
    }

    /**
     * Validates field to have a unique value.
     * @param fieldName
     * @param collection
     * @param label
     */
    unique(fieldName: keyof Values, collection: Values[keyof Values][], label: string): void {
        this.uniqueBase(fieldName, collection, Validator.genUniqueError(label));
    }

    /**
     * Validates field to have a unique value. Supports custom error message.
     * @param fieldName
     * @param collection
     * @param error
     */
    uniqueCustomError(fieldName: keyof Values, collection: Values[keyof Values][], error: string): void {
        this.uniqueBase(fieldName, collection, error);
    }

    /**
     * Return accumulated errors.
     */
    generateErrorsObject(): Errors<Values> {
        return this.errors;
    }

    protected setErrorForField(fieldName: keyof Values, error: string | Errors<unknown>): void {
        this.errors[fieldName] = error;
    }
}
