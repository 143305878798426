import {concat, flow, pullAt} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {routerActions} from 'connected-react-router';
import {Dispatch} from 'redux';
import {opt} from 'ts-opt';

import {StoreFormState} from 'app/types/StoreFormState';
import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {forceAsyncValidation} from 'forms/actions';
import {Components as Layout} from 'layout';
import {loadMilkRooms} from 'supplier/actions';
import {SupplyChain} from 'types/model/supplyChains/SupplyChain';
import {UpdateSupplyChain} from 'types/model/supplyChains/UpdateSupplyChain';
import withUser from 'user/components/withUser';
import {formValuesF, isDirtyF, isValidF, isSubmitingF, resetF, touchF, blurF} from 'utils/formHelpers';
import {swapIndices} from 'favorlogic-utils';
import {createSupplyChain, loadSupplyChain, updateSupplyChain} from '../actions';
import SupplyChainForm from '../components/SupplyChainForm';
import SupplyChainItemForm from '../components/SupplyChainItemForm';
import SupplyChainView from '../components/SupplyChainView';
import {SupplyChainFormValues} from '../types/SupplyChainFormValues';
import {SupplyChainItemFormValues} from '../types/SupplyChainItemFormValues';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';

type Mode = 'CREATE' | 'UPDATE';

interface OuterProps {
    mode: Mode;
}

interface InnerProps {
    milkRooms: MilkRoom[] | null;
    formValue?: SupplyChainFormValues;
    chainItemFormValue?: SupplyChainItemFormValues;
    formValid: boolean;
    match: Match<{id?: string}>;
    supplyChain: SupplyChain | null;
    formSubmitting: boolean;

    loadMilkRooms(): void;

    resetForm(): void;

    updateChain(milkRoomIds: number[]): void;

    resetChainForm(): void;

    handleCreate(): void;

    handleUpdate(id: number): void;

    handle404(): void;

    handleLoadSupplyChain(id: number): void;
}

type Props = InnerProps & OuterProps;

class NewEdit extends Component<Props> {
    componentDidMount(): void {
        const {
            resetForm,
            loadMilkRooms,
            handleLoadSupplyChain,
            mode,
            handle404,
        } = this.props;
        loadMilkRooms();
        switch (mode) {
            case 'CREATE': {
                resetForm();
                break;
            }
            case 'UPDATE': {
                const id = this.getId();
                if (id === null) {
                    handle404();
                } else {
                    handleLoadSupplyChain(id);
                }
                break;
            }
            default: { throw new Error('invalid mode'); }
        }
    }

    render(): ReactNode {
        const {
            formValue,
            formValid,
            handleCreate,
            handleUpdate,
            mode,
            milkRooms,
            formSubmitting,
        } = this.props;
        const onSubmitClick = () => {
            switch (mode) {
                case 'CREATE': {
                    handleCreate();
                    break;
                }
                case 'UPDATE': {
                    const id = this.getId();
                    if (id) { handleUpdate(id); }
                    break;
                }
                default: { throw new Error('invalid mode'); }
            }
        };
        const formsPart = ({visible, milkRoomsInChainIds}: {visible: boolean, milkRoomsInChainIds: number[]}) =>
            <div style={{display: visible ? 'block' : 'none'}}>
                <SupplyChainForm
                    isCreate={mode === 'CREATE'}
                />

                <div className="p-2" />

                <h2>Mléčnice</h2>
                {milkRooms && milkRoomsInChainIds &&
                 <SupplyChainView
                     milkRoomIds={milkRoomsInChainIds}
                     milkRooms={milkRooms}
                     mode="EDIT"
                     onDelete={this.handleChainDelete}
                     onMove={this.handleChainMove}
                 />
                }

                <div className="p-4" />

                <Layout.Panel
                    caption=""
                    className="mt-0"
                >
                    <h2>Nová položka</h2>
                    {milkRooms &&
                     <SupplyChainItemForm
                         onSubmit={this.handleCreateSupplyChainItem}
                         milkRooms={milkRooms}
                         milkRoomsInChainIds={milkRoomsInChainIds}
                     />
                    }
                </Layout.Panel>

                <div className="p-2" />

                <Buttons.Button
                    importance="primary"
                    disabled={!formValid || formSubmitting}
                    type="button"
                    onClick={onSubmitClick}
                    fullWidth
                >
                    {mode === 'UPDATE' ? 'Upravit linku' : 'Vytvořit linku'}
                </Buttons.Button>
            </div>
        ;

        return (
            <Layout.ItemPage
                title={this.renderCaption()}
                backLabel="Linky"
            >
                <Layout.Panel>
                    {formsPart({
                        visible: Boolean(milkRooms?.length),
                        milkRoomsInChainIds: opt(formValue).map(x => x.milkRoomIds).orElse([]),
                    })}
                </Layout.Panel>
            </Layout.ItemPage>
        );
    }

    private renderCaption(): string {
        const {mode, supplyChain} = this.props;
        switch (mode) {
            case 'CREATE': { return 'Nová linka'; }
            case 'UPDATE': { return !supplyChain ? '' : `Úprava linky ${supplyChain.code}`; }
            default: { throw new Error('invalid mode'); }
        }
    }

    private getId(): number | null {
        const {match} = this.props;
        const id = match.params.id || '';
        const res = Number.parseInt(id, 10);

        return Number.isNaN(res) ? null : res;
    }

    private readonly getFormValue = (): UpdateSupplyChain =>
        opt(this.props.formValue).orCrash('Form value is missing.')

    private readonly handleChainMove = (idx: number, shift: 1 | -1): void => {
        const {updateChain} = this.props;
        updateChain(swapIndices([idx, idx + shift])(this.getFormValue().milkRoomIds));
    }

    private readonly handleChainDelete = (idx: number): void => {
        const {updateChain} = this.props;
        updateChain(pullAt([idx], this.getFormValue().milkRoomIds));
    }

    private readonly handleCreateSupplyChainItem = () => {
        const {chainItemFormValue, updateChain, resetChainForm} = this.props;
        if (!chainItemFormValue) { throw new Error('Missing chain item form values.'); }
        flow(
            (x: number[]) => concat(x, opt(chainItemFormValue.milkRoom).orCrash('missing milk room').id),
            updateChain,
        )(this.getFormValue().milkRoomIds);
        resetChainForm();
    }
}

const createFormName: keyof StoreFormState = 'supplyChain';
const chainItemFormName: keyof StoreFormState = 'supplyChainItem';

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    milkRooms: state.supplier.milkRooms,
    supplyChain: state.supplyChain.supplyChain,
    formValue: formValuesF(createFormName)(state).orUndef(),
    chainItemFormValue: formValuesF(chainItemFormName)(state).orUndef(),
    formValid: isValidF(createFormName)(state) && isDirtyF(createFormName)(state),
    formSubmitting: isSubmitingF(createFormName)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    loadMilkRooms: () => dispatch(loadMilkRooms()),
    handle404: () => dispatch(routerActions.push('/404')),
    handleLoadSupplyChain: (id: number) => dispatch(loadSupplyChain(id)),
    resetForm: () => dispatch(resetF(createFormName)),
    updateChain: (milkRoomIds: number[]) => {
        const field: keyof SupplyChainFormValues = 'milkRoomIds';
        dispatch(touchF(createFormName, field));
        dispatch(blurF(createFormName, field, milkRoomIds));
        dispatch(forceAsyncValidation({formName: createFormName, fieldName: field, newValue: milkRoomIds}));
    },
    resetChainForm: () => dispatch(resetF(chainItemFormName)),
    handleCreate: () => dispatch(createSupplyChain()),
    handleUpdate: (supplyChainId: number) => dispatch(updateSupplyChain(supplyChainId)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(NewEdit) as ComponentClass<OuterProps>;
