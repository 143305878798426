import React, {ReactNode, PureComponent} from 'react';

import {Fields} from 'forms';
import {RefInput} from 'forms/components/Input';

interface DilutionResultProps {
    name: string;
    rowIndex: number;
    inputRef: RefInput;

    onDilutionEnter(rowIndex: number): void;
}

export class DilutionResult extends PureComponent<DilutionResultProps> {
    render(): ReactNode {
        const {name, inputRef} = this.props;

        return (
            <Fields.Input
                name={name}
                type="number"
                label="Ředění*"
                inputRef={inputRef}
                onEnter={this.onDilutionEnter}
            />
        );
    }

    private onDilutionEnter = (): void => {
        const {rowIndex, onDilutionEnter} = this.props;

        onDilutionEnter(rowIndex);
    }
}
