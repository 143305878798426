import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';
import {routerActions} from 'connected-react-router';
import {takeLatestF} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {isLoading} from 'layout/actions';

import {ValidateResetPasswordEmailAction} from '../actions';
import Api from '../api';

function* execute({payload: {uuid}}: ValidateResetPasswordEmailAction): SagaIterator {
    yield* put(isLoading(true));

    const response = yield* call(Api.validateResetPassword, uuid);

    if (response.isSuccess) {
        yield* put(isLoading(false));
    } else {
        yield* call(handleResponseError, response, 'Obnovení hesla');
        yield* put(routerActions.push('/login'));
    }
}

export function* validateResetPasswordSaga(): SagaIterator {
    yield takeLatestF('user/VALIDATE_RESET_PASSWORD', execute);
}
