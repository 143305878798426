import * as t from 'io-ts';

import {CustomerSchema} from './Customer';
import {HexColorSchema} from '../common/HexColor';

const requiredPart = t.interface({
    id: t.number,
    name: t.string,
    customers: t.array(CustomerSchema),
    canDelete: t.boolean,
    color: HexColorSchema,
});

export const PickupLineSchema = t.exact(requiredPart);

export interface PickupLine extends t.TypeOf<typeof PickupLineSchema> {}
