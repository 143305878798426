exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-modules-sample-components-SamplesTable-___styles__notes___32gdi > :not(:first-child) {\n  margin-left: 7px; }\n", ""]);

// exports
exports.locals = {
	"notes": "src-modules-sample-components-SamplesTable-___styles__notes___32gdi"
};