import {SagaIterator} from 'redux-saga';
import {put, select, call} from 'typed-redux-saga';
import {opt} from 'ts-opt';
import {emptyStringToUndefined, showSuccess, ErrorsFromBE, ErrorResponse} from 'favorlogic-utils';

import {MassUpdateSample} from 'types/model/samples/MassUpdateSample';
import {handleResponseError} from 'utils/handleResponseError';
import {UpdateSamplesFormValues} from 'sample/types/UpdateSamplesFormValues';
import {formValuesF} from 'utils/formHelpers';

import {updateSamplesSuccess, updateSamplesError} from '../actions';

const title = 'Úprava vzorků';

export function* getFormValues(): SagaIterator<UpdateSamplesFormValues> {
    return (yield* select(formValuesF('updateSamples'))).orCrash('Missing form values.');
}

export function updateSamplesRequestData(formValues: UpdateSamplesFormValues): MassUpdateSample {
    return {
        samplingDate: opt(formValues.samplingDate).map(emptyStringToUndefined).orUndef(),
        samplingTime: opt(formValues.samplingTime).map(emptyStringToUndefined).orUndef(),
    };
}

export function* handleErrorResponse(response: ErrorResponse): SagaIterator {
    yield* call(handleResponseError, response, title);
    yield* put(updateSamplesError(response.data as ErrorsFromBE));
}

export function* handleSuccess(): SagaIterator {
    yield* put(showSuccess(title, 'Vzorky byly úspěšně upraveny'));
    yield* put(updateSamplesSuccess());
}
