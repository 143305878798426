import * as t from 'io-ts';

import {PickupLineDairyPlanAnalysisDaySchema} from './PickupLineDairyPlanAnalysisDay ';

const requiredPart = t.interface({
    id: t.number,
    abbr: t.string,
    days: t.array(PickupLineDairyPlanAnalysisDaySchema),
});

export const PickupLineDairyPlanAnalysisSchema = t.exact(requiredPart);

export interface PickupLineDairyPlanAnalysis extends t.TypeOf<typeof PickupLineDairyPlanAnalysisSchema> {}
