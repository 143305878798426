import * as t from 'io-ts';
import {IntegerSchema, DateStringSchema} from 'favorlogic-utils';

import {BarcodeSchema} from 'types/generic/BarcodeSchema';
import {SampleTemperatureSchema} from 'types/generic/SampleTemperatureSchema';

const optionalPart = t.partial({
    milkRoomId: IntegerSchema,
    supplyChainId: IntegerSchema,
    samplingTemperature: SampleTemperatureSchema,
    sampleSourceAmount: IntegerSchema,
    traitNote: t.string,
});
const requiredPart = t.interface({
    barcode: BarcodeSchema,
    samplingDate: DateStringSchema,
});

export const ParsedSampleSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface ParsedSample extends t.TypeOf<typeof ParsedSampleSchema> {}
