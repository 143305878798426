import React, {Component, Fragment, ReactNode} from 'react';

import ShowField, {ShowFieldType} from 'forms/components/ShowField';
import {Components as Layout} from 'layout';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import {Customer} from 'types/model/customers/Customer';
import {priceClassOptions} from 'user/types/PriceClassOptions';

export interface Props {
    customer: Customer;
    priceClass?: PriceClass;
}

class CustomerDetailsView extends Component<Props> {
    render(): ReactNode {
        const {customer, priceClass} = this.props;
        if (!customer) { return null; }
        return (
            <Fragment>
                <Layout.Loader show={!customer} />
                <div className="row">
                    <div className="col-md-12 border-left">
                        <div className="mb-3">
                            <strong className="d-block">Údaje o zákazníkovi </strong>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.firstName}
                                    label="Jméno"
                                    type={ShowFieldType.String}
                                />
                            </div>
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.lastName}
                                    label="Příjmení"
                                    type={ShowFieldType.String}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.invoiceDetails.email}
                                    label="E-mail"
                                    type={ShowFieldType.String}
                                />
                            </div>
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.invoiceDetails.phone}
                                    label="Telefonní číslo"
                                    type={ShowFieldType.String}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.invoiceDetails.ico}
                                    label="IČO"
                                    type={ShowFieldType.String}
                                />
                            </div>
                            <div className="col-md-6">
                                <ShowField
                                    value={customer.invoiceDetails.dic}
                                    label="DIČ"
                                    type={ShowFieldType.String}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ShowField
                                    value={`${customer.firstName} ${customer.lastName}`}
                                    label="Objednatel"
                                    type={ShowFieldType.String}
                                />
                            </div>
                            {priceClass &&
                             <div className="col-md-6">
                                 <ShowField
                                     value={priceClass}
                                     label="Cenová třída"
                                     type={ShowFieldType.SelectOption}
                                     selectOptions={priceClassOptions}
                                 />
                             </div>
                            }
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <div className="mb-3"><strong>Adresa</strong></div>
                                <ShowField
                                    value={customer.address.street}
                                    label="Ulice"
                                    type={ShowFieldType.String}
                                />
                                <ShowField
                                    value={customer.address.city}
                                    label="Město"
                                    type={ShowFieldType.String}
                                />
                                <ShowField
                                    value={customer.address.zip}
                                    label="Směrovací číslo"
                                    type={ShowFieldType.String}
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3"><strong>Fakturační adresa</strong></div>
                                <ShowField
                                    value={customer.billingAddress.street}
                                    label="Ulice"
                                    type={ShowFieldType.String}
                                />
                                <ShowField
                                    value={customer.billingAddress.city}
                                    label="Město"
                                    type={ShowFieldType.String}
                                />
                                <ShowField
                                    value={customer.billingAddress.zip}
                                    label="Směrovací číslo"
                                    type={ShowFieldType.String}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CustomerDetailsView;
