import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {SampleView} from 'types/model/samples/SampleView';

interface Props {
    sample: SampleView;
    selected: boolean;

    toggleSelect(sampleId: number): void;

    handleDelete(sampleId: number, orderId: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {sample, handleDelete, selected, toggleSelect} = this.props;
        const {sampleId, orderId} = sample;
        return (
            <div>
                <Buttons.RowActionButton
                    type="UPDATE"
                    to={`/samples/${sampleId}`}
                    disabled={!sample.canUpdate}
                />
                <Buttons.RowActionButton
                    type="DELETE"
                    onClick={() => handleDelete(sampleId, orderId)}
                    disabled={!sample.canDelete}
                />
                <Buttons.RowActionButton
                    type={selected ? 'UNSELECT' : 'SELECT'}
                    onClick={() => toggleSelect(sampleId)}
                    disabled={!sample.canDelete}
                />
            </div>
        );
    }
}

export default RowActions;
