import {routerActions} from 'connected-react-router';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';
import {
    extractFormErrorsFromResponse,
    takeLatestF,
    showSuccess,
} from 'favorlogic-utils';

import {handleResponseError} from 'utils/handleResponseError';
import {formValuesF, resetF, startSubmitF, stopSubmitF} from 'utils/formHelpers';

import Api from '../api';

const title = 'Obnovení hesla';

function* execute(): SagaIterator {
    yield* put(startSubmitF('resetPasswordEmail'));

    const formValuesOpt = yield* select(formValuesF('resetPasswordEmail'));
    const formValues = formValuesOpt.orCrash('missing form data');
    const {email} = formValues;
    const response = yield* call(Api.resetPasswordEmail, email);

    if (!response.isSuccess) {
        yield* call(handleResponseError, response, title);
        return yield* put(stopSubmitF('resetPasswordEmail', extractFormErrorsFromResponse(response)));
    }

    yield* put(stopSubmitF('resetPasswordEmail'));
    yield* put(routerActions.push('/login'));
    yield* put(showSuccess(title, 'Na vaši emailovou adresu byl zaslán email pro změnu hesla'));
    yield* put(resetF('resetPasswordEmail'));
}

export function* resetPasswordEmailSaga(): SagaIterator {
    yield takeLatestF('user/RESET_PASSWORD_EMAIL', execute);
}
