import React, {ReactElement} from 'react';

import {Components as Icons} from 'icons';
import {Column, ColumnType} from '../../types/Column';
import {Filter} from '../../types/Filter';
import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import SimpleFilter from '../SimpleFilter';
import SelectFilter from '../SelectFilter';
import DateFilter from '../DateFilter';
import DateRangeFilter from '../DateRangeFilter';
import CheckFilter from '../CheckFilter';
import {SelectOptions} from 'forms/components/BasicSelect';
import {classNames} from 'favorlogic-utils';

import filterSvg from './filter.svg';
import xCircleSvg from './xCircle.svg';

import styles from './styles.sass';
import sharedStyles from '../styles.sass';

const filterPlaceholder = 'Filtrovat...';

const boolOptions: SelectOptions<string> = [
    {label: 'Ano', value: 'true'},
    {label: 'Ne', value: 'false'},
];

interface Props<RowT> {
    columns: Column<RowT>[];
    filter: Filter;
    hasTwoMainColumns?: boolean;

    onChange: FilterChangeHandler;

    onSubmit(): void;

    onClear(): void;
}

/**
 * Iterate over table column objects and render appropriate filter type for filterable columns.
 */
const Filters = <RowT extends object>(props: Props<RowT>): ReactElement => {
    const {
        columns,
        filter,
        onChange,
        onSubmit,
        onClear,
        hasTwoMainColumns,
    } = props;

    const isAnyFilterActive = () => Object.keys(filter).length > 0;
    const isLastColumn = (index: number) => index === columns.length - 1;

    const getOptions = (column: Column<RowT>) => {
        if (column.type === ColumnType.SelectOption) {
            return column.selectOptions;
        }

        if (column.type === ColumnType.MultiSelectOption) {
            return column.selectOptions;
        }

        if (column.type === ColumnType.Bool) {
            return boolOptions;
        }

        return column.filterOptions || [];
    };

    return (
        <tr className={classNames(styles.filter, hasTwoMainColumns && styles.twoMainColumns)}>
            {
                columns.map((column, index) => {
                    const {accessor, filterable, filterType, filterInputType} = column;
                    const currentFilter = filter[accessor];

                    if (accessor === 'actions' && isLastColumn(index)) {
                        return null;
                    }

                    return (
                        <td
                            key={accessor}
                            className={classNames(styles.td, column.size !== undefined && sharedStyles[column.size])}
                        >
                            {filterable && filterType === 'equals' &&
                             <SimpleFilter
                                 filter={currentFilter}
                                 accessor={accessor}
                                 placeholder={filterPlaceholder}
                                 inputType={filterInputType}
                                 onChange={onChange}
                                 onSubmit={onSubmit}
                             />
                            }

                            {filterable && filterType === 'betweenDates' &&
                                <DateRangeFilter
                                    fromFilter={filter[`${accessor}From`]}
                                    toFilter={filter[`${accessor}To`]}
                                    accessor={accessor}
                                    placeholder={filterPlaceholder}
                                    onChange={onChange}
                                />
                            }

                            {filterable && filterType && ['select', 'multiselect'].includes(filterType) &&
                                <SelectFilter
                                    filter={currentFilter}
                                    accessor={accessor}
                                    onChange={onChange}
                                    selectOptions={getOptions(column)}
                                    multi={column.filterMulti || filterType === 'multiselect'}
                                    placeholder={filterPlaceholder}
                                />
                            }

                            {filterable && filterType === 'date' &&
                                <DateFilter
                                    filter={currentFilter}
                                    accessor={accessor}
                                    onChange={onChange}
                                    placeholder={filterPlaceholder}
                                />
                            }

                            {filterable && filterType === 'check' &&
                                <CheckFilter
                                    filter={currentFilter}
                                    accessor={accessor}
                                    onChange={onChange}
                                />
                            }
                        </td>
                    );
                })
            }

            <td
                className={styles.td}
            >
                <div className="d-flex justify-content-end align-items-center">
                    <button
                        className={classNames('btn btn-outline-primary', styles.filterButton)}
                        title="Filtrovat"
                        onClick={onSubmit}
                        type="submit"
                    >
                        <Icons.SvgIcon icon={filterSvg} />
                        <b className="ml-1">
                        Filtrovat
                        </b>
                    </button>

                    {isAnyFilterActive() &&
                <button
                    className={classNames('btn btn-outline-primary btn-basic', styles.clearButton)}
                    onClick={onClear}
                    title="Zrušit filtr"
                    type="submit"
                >
                    <Icons.SvgIcon icon={xCircleSvg} />
                </button>}
                </div>
            </td>
        </tr>
    );
};

export default Filters;
