import {flow, isEmpty} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteComponentProps} from 'react-router';
import {parseSearch} from 'favorlogic-utils';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import withUser from 'user/components/withUser';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {SuppliersPage} from 'types/model/suppliers/SuppliersPage';
import {SortProps} from 'tables/components/withSort';
import {withPagination, withFilter, withSort} from 'tables/components';
import {Components as Layout} from 'layout';
import {FilterProps} from 'tables/components/withFilter';
import {PaginationProps} from 'tables/components/withPagination';

import {
    deleteSupplier,
    loadSuppliers,
    setSuppliersParams,
    resetSuppliers,
} from '../actions';
import SuppliersTable, {definedFilters} from '../components/SuppliersTable';
import {SuppliersParams} from '../types/SuppliersParams';

interface OuterProps {
}

interface StateProps {
    suppliersPage: SuppliersPage | null;
}

interface DispatchProps {
    handleLoadSuppliers(params: SuppliersParams): void;
    handleDelete(id: number): void;
    handleSetSuppliersParams(params: SuppliersParams): void;
    handleResetSuppliers(): void;
}

type RouterProps = Pick<RouteComponentProps, 'location'>;
type ListProps = FilterProps & PaginationProps & SortProps;
type Props =  OuterProps & StateProps & DispatchProps & RouterProps & ListProps;

class List extends Component<Props> {
    componentDidMount(): void {
        const {location: {search}} = this.props;

        if (search) { this.loadSuppliersByQuery(search); }
    }

    componentDidUpdate(prevProps: Props) {
        const {location: {search: prevSearch}, filter: prevFilter} = prevProps;
        const {location: {search}, filter, handleResetSuppliers} = this.props;

        if (prevFilter !== filter && isEmpty(filter)) {
            handleResetSuppliers();
            this.loadSuppliersByQuery(search);
        } else if (prevSearch !== search) {
            this.loadSuppliersByQuery(search);
        }
    }

    render(): ReactNode {
        const {
            suppliersPage,
            handleDelete,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            sortDir,
            sortBy,
            handleFilterChange,
            handleFilterSubmit,
            handleFilterClear,
            filter,
        } = this.props;

        if (suppliersPage && isEmpty(suppliersPage.content) && isEmpty(filter)) {
            return this.renderNoSuppliers();
        }

        return (
            <Layout.ListPage
                title="Seznam dodavatelů"
                toolbar={this.renderToolbar()}
                fluid
            >
                <SuppliersTable
                    suppliersPage={suppliersPage}
                    handleDelete={handleDelete}
                    changePage={handlePageChange}
                    changePageSize={handlePageSizeChange}
                    changeSort={handleSortChange}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    changeFilter={handleFilterChange}
                    resetFilter={handleFilterClear}
                    filterSuppliers={handleFilterSubmit}
                    filter={filter}
                />
            </Layout.ListPage>
        );
    }

    private renderToolbar(): ReactNode {
        return (
            <Buttons.Group>
                <Buttons.RightIconButton
                    label="Nový"
                    to="/suppliers/new"
                    icon="ADD"
                />
            </Buttons.Group>
        );
    }

    private renderNoSuppliers(): ReactNode {
        return (
            <Layout.EmptyPage>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Nemáte prozatím žádné dodavatele.
                    </div>
                    <div>
                        Chcete vytvořit nového dodavatele? Klikněte na
                        <Buttons.RightIconButton
                            label="Nový"
                            to="/suppliers/new"
                            className="m-2"
                            icon="ADD"
                        />
                    </div>
                </div>
            </Layout.EmptyPage>
        );
    }

    private loadSuppliersByQuery(search: string): void {
        const {handleLoadSuppliers, handleSetSuppliersParams} = this.props;
        const params = parseSearch(search);
        handleSetSuppliersParams(params);
        handleLoadSuppliers(params);
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    suppliersPage: state.supplier.suppliersPage,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadSuppliers: (params: SuppliersParams) => dispatch(loadSuppliers(params)),
    handleDelete: (id: number) => dispatch(showConfirmDialog({
        title: 'Smazat dodavatele',
        text: 'Opravdu chcete dodavatele smazat?',
        confirmAction: deleteSupplier(id),
    })),
    handleSetSuppliersParams: (params: SuppliersParams) => dispatch(setSuppliersParams(params)),
    handleResetSuppliers: () => dispatch(resetSuppliers()),
});

export default flow([
    withUser,
    withSort(),
    withPagination,
    withFilter(definedFilters),
    connect(mapStateToProps, mapDispatchToProps),
])(List) as ComponentClass<OuterProps>;
