import React, {ReactNode} from 'react';
import {hot} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {detect} from 'detect-browser';
import {classNames, showAppInfo, Notifications} from 'favorlogic-utils';

import {history, store} from 'app/store';
import {Components as Layout} from 'layout';
import ConfirmDialog from 'confirmDialog/components/ConfirmDialog';
import PageContent from '../PageContent';

import 'styles/styles.sass';
import styles from './styles.sass';

class Main extends React.Component<void> {
    componentDidMount() {
        const browser = detect();

        if (browser && browser.name === 'ie') {
            showAppInfo(
                'Váš prohlížeč není podporovaný',
                'Použijte prosím moderní prohlížeč'
            );
        }
    }

    render(): ReactNode {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <div
                        id="mainWrapper"
                        className={classNames('position-relative', styles.mainWrapper)}
                    >
                        <Notifications />
                        <ConfirmDialog />
                        <Switch>
                            <Layout.MainMenu />
                        </Switch>
                        <PageContent />
                    </div>
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default hot(module)(Main);
