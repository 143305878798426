import React, {FunctionComponent} from 'react';
import {opt} from 'ts-opt';
import {toArray, classNames} from 'favorlogic-utils';

import {FilterChangeHandler} from '../../types/FilterChangeHandler';
import {FilterPart} from '../../types/FilterPart';
import BasicSelect, {SelectOptions, SelectedValue} from 'forms/components/BasicSelect';

import styles from './styles.sass';

interface Props {
    filter?: FilterPart;
    accessor: string;
    selectOptions: SelectOptions<string>;
    multi?: boolean;
    placeholder?: string;
    onChange: FilterChangeHandler;
}

const SelectFilter: FunctionComponent<Props> = props => {
    const {filter, accessor, onChange, selectOptions, multi, placeholder} = props;

    const selectOnChange = (value: SelectedValue<string>) => {
        const values: string[] = opt(value).map(toArray).orElse([]);

        onChange(accessor, 'select', values);
    };

    const getValue = () => {
        if (!filter || !filter.values) {
            return multi ? [] : null;
        }

        return multi ? filter.values : filter.values[0] || '';
    };

    return <BasicSelect
        options={selectOptions}
        className={classNames(styles.selectFilter, 'form-control')}
        value={getValue()}
        label={placeholder}
        onChange={selectOnChange}
        multi={multi}
        clearable
        noBorder
    />;
};

export default SelectFilter;
