import {Action} from 'app/actions';
import {CFormReducer, GenericFormState} from 'app/types/StoreFormState';
import {DataItemFormValues} from './types/DataItemFormValues';

type DataItemFormState = GenericFormState<DataItemFormValues>;

const formReducer: CFormReducer = {
    dataItem: (state: DataItemFormState, action: Action): DataItemFormState => {
        switch (action.type) {
            case 'dataItem/PREFILL_DATA_ITEM_FORM': {
                return {...state, values: action.payload};
            }

            default:
                return state;
        }
    },
};

export default formReducer;
